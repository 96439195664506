import { Component, Input } from '@angular/core';

@Component({
  selector: 'uc-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent {
  @Input() tileLink: string;

  get externalLink() {
    return this.tileLink && this.tileLink.match(/^(http|\/\/)/);
  }

  get localLink() {
    return this.tileLink && !this.externalLink;
  }
}
