import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { ONLINE_PROCESS_PREFIX } from '@shared/constants/app-names.constants';
import { Application } from '@shared/models/application';
import { EnrolmentSummary, ArchivedEnrolmentSummary } from '@shared/models/enrolment-summary';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

export interface IAppTileQueryParams {
  year: string;
  state: string;
}

const isValidOverrideState = (state) => {
  return !![Application.STATE.AGREEMENT_ACCEPTED, Application.STATE.AGREEMENT_DECLINED].find(
    (validState) => state === validState,
  );
};

@Component({
  selector: 'uc-application-tile',
  templateUrl: './application-tile.component.html',
  styleUrls: ['./application-tile.component.scss'],
})
export class ApplicationTileComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
  private log: Logger;
  strings = strings.components.molecules.applicationTile;

  enrolmentSummary: EnrolmentSummary;
  archivedEnrolments: ArchivedEnrolmentSummary[] = [];
  uniqueYearsInSummary: string[] = [];
  canStartNewApplication = false;
  displaySpinner = false;
  loaded = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public enrolmentSummaryService: EnrolmentSummaryService,
    private loggingService: LoggingService,
    private zone: NgZone,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  // eslint-disable-next-line max-lines-per-function
  ngOnInit() {
    observableCombineLatest([
      this.enrolmentSummaryService.getSummary().pipe(filter((s) => !!s)),
      this.route.queryParams,
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        ([summary, queryParams]: [EnrolmentSummary, IAppTileQueryParams]) => {
          this.enrolmentSummary = summary;
          this.excludeUCOnlineClassicApplication(summary);

          const { year, state } = queryParams;
          // eslint-disable-next-line complexity
          const updatedSummaries = summary.academicYears.map((yearSummary) => {
            yearSummary.archived = true;

            if (year && state && year === yearSummary.academicYear && isValidOverrideState(state)) {
              yearSummary.state = state;
            }
            return yearSummary;
          });
          this.loaded = true;

          // NOTE P.W.: Was having issues with the UI not updating so force UI update immediately
          // TODO fix - check if this is still necessary after the enrolment refactor
          this.zone.run(() => {
            this.enrolmentSummary.academicYears = updatedSummaries;
          });
        },
        (err) => {
          this.log.error(err);
        },
      );
  }

  excludeUCOnlineClassicApplication(summary: EnrolmentSummary) {
    this.uniqueYearsInSummary = summary.uniqueYearsInSummary.filter(
      (uniqueYear) =>
        !summary.academicYears
          .filter((item) => item.academicYear === uniqueYear)
          .pop()
          ?.processName.startsWith(ONLINE_PROCESS_PREFIX),
    );
  }

  ngOnDestroy() {
    this.displaySpinner = false;
  }

  transition(url: string) {
    this.displaySpinner = true;
    this.router.navigate(internalUrls[url]);
  }
}
