<div [formGroup]="fitnessToTeachForm">
  <uc-form-input>
    <uc-yes-no
      formControlName="hasDisability"
      [questionString]="strings.disabilityQuestion"
      [questionDescription]="strings.disabilityMinorText"
      required="true"
      [task]="task"
      path="/applicant/declaration_disability"
      testSelector="disability"
    >
      <uc-yes-content>
        <uc-subsection>
          <uc-task-hint [task]="task" path="/applicant/disability/0"></uc-task-hint>
          <uc-checkbox-input-group
            label="{{ strings.selectDisabilityLabel }}"
            formControlName="disability"
            type="disability"
            groupName="disability"
            isRequired="true"
          >
          </uc-checkbox-input-group>
        </uc-subsection>

        <uc-subsection *ngIf="disabilityDetailRequired; else optionalDetails">
          <uc-task-hint [task]="task" path="/applicant/disability_detail"></uc-task-hint>
          <uc-form-field-label
            labelText="{{ strings.furtherDetail }}"
            required="true"
            labelTooltipContent="{{ strings.furtherDetailTooltip }}"
          >
            <uc-text-area testSelector="disability-detail" formControlName="disabilityDetail"></uc-text-area>
          </uc-form-field-label>
          <uc-subsection-text-block>{{ strings.medicalCertificateMinorText }}</uc-subsection-text-block>
        </uc-subsection>

        <ng-template #optionalDetails>
          <uc-subsection>
            <uc-form-field-label labelText="{{ strings.optionalFurtherDetail }}">
              <uc-text-area testSelector="disability-detail-extra" formControlName="disabilityDetail"></uc-text-area>
            </uc-form-field-label>
          </uc-subsection>
        </ng-template>
      </uc-yes-content>
    </uc-yes-no>
  </uc-form-input>
</div>
