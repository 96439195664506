<div class="application-summary-row">
  <uc-confirmation-modal
    (accepted)="doEnrolmentCancel()"
    #withdrawConfirmationModal
    [title]="strings.withdrawApplication"
    [message]="modalMessage"
    [acceptText]="strings.withdraw"
    [declineText]="strings.close"
  ></uc-confirmation-modal>
  <uc-confirmation-modal
    (accepted)="triggerReload()"
    #errorModal
    [title]="errorTitle"
    [message]="errorMessage"
  ></uc-confirmation-modal>

  <div class="application-summary-row-inner">
    <div class="application-summary-row-left">
      <div style="display: inline-block">
        <span class="application-year">
          <img
            class="application-summary__chevron"
            src="/favicon.ico"
          />{{ courseName }}</span
        >
        <div
          *ngIf="enrolment.isInActionableState() && enrolmentsClosedDate !== ''"
          class="application-summary__enrolments__close"
        >
          {{ strings.enrolmentsClose }} {{ enrolmentsClosedDate }}
        </div>
      </div>
      <div
        class=""
        style="padding-top: 5px"
      >
        <uc-online-status-pill
          [attr.data-testid]="enrolment.getPill().title"
          [color]="enrolment.getPill().css"
          [value]="enrolment.getPill().title"
        />
        <uc-online-status-pill
          color="pill-grey"
          [value]="courseType"
          [attr.data-testid]="courseType.replace(' ', '-').toUpperCase()"
        />
      </div>
    </div>
    <div class="application-summary-row-right">
      <div
        class="section section-primary-action"
        *ngIf="this.enrolment.state === 'created'"
      >
        <a
          (click)="cancelEnrolment()"
          class="link"
        >
          <span class="cancel-link">{{ strings.cancel }}</span>
        </a>
      </div>
      <div
        class="section section-primary-action"
        *ngIf="this.enrolment.state !== 'cancelled'"
      >
        <a
          [href]="actionUrl"
          class="link"
        >
          <span>{{ actionUrlTitle }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
