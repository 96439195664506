<uc-form-template>
  <uc-form-header title="{{task?.title}}">
      <uc-task-hints [task]="task"></uc-task-hints>
    <p [innerHTML]="strings.description"></p>
  </uc-form-header>
  <uc-section>
    <uc-insurance-provider [task]="task" [insuranceProviderFormGroup]="insuranceProviderGroup">
    </uc-insurance-provider>
  </uc-section>
</uc-form-template>
