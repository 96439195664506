import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';

import { UCValidators } from '@shared/models/validators/validators';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export interface MonthOption {
  value: string;
  text: string;
}

export interface RestrictedDateValues {
  day?: string[];
  month?: MonthOption[];
  year?: string[];
}

@Component({
  selector: 'uc-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateSelectorComponent),
      multi: true,
    },
  ],
})
export class DateSelectorComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() allowFutureDates = true;
  @Input() baseYear: number;
  @Input() range: number;
  @Input() order: string;
  @Input() noIcon = false;
  @Input() restrictedValues: RestrictedDateValues = {};
  @Input() testSelector = '';
  @Input() showDisabledExplanation = true;

  hasValue = false;
  yearOptions: string[];
  currentYear = new Date().getFullYear();
  zeroIndexCurrentMonth = new Date().getMonth();
  currentCalendarDay = new Date().getDate();

  dateForm: UntypedFormGroup = this.fb.group({
    monthControl: '',
    dayControl: '',
    yearControl: '',
  });

  months = [
    { value: '01', text: 'January' },
    { value: '02', text: 'February' },
    { value: '03', text: 'March' },
    { value: '04', text: 'April' },
    { value: '05', text: 'May' },
    { value: '06', text: 'June' },
    { value: '07', text: 'July' },
    { value: '08', text: 'August' },
    { value: '09', text: 'September' },
    { value: '10', text: 'October' },
    { value: '11', text: 'November' },
    { value: '12', text: 'December' },
  ];

  // defaults are all days and all months
  dayOptions: string[] = this.getNumberOfDaysFromMonth('', null);
  monthOptions = this.getMonthsForYear('');

  log: Logger;
  isDisabled: boolean;

  private propagateChange: (_: string) => void;
  private propagateTouch: (_: boolean) => void;

  constructor(
    private fb: UntypedFormBuilder,
    loggingService: LoggingService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get testSelectorId() {
    return `${this.testSelector}-date`;
  }

  get stringValue(): string {
    const { value } = this.dateForm;
    const formattingRule = this.getFormattingRule(value);

    if (formattingRule) {
      return DateTime.fromObject({
        year: value.yearControl,
        month: value.monthControl,
        day: value.dayControl,
      }).toFormat(formattingRule);
    }
  }

  getFormattingRule(value: typeof this.dateForm.value): string {
    const rules: [Record<string, boolean>, string][] = [
      [{ day: false, month: false, year: true }, 'yyyy'],
      [{ day: true, month: true, year: false }, 'd LLLL'],
      [{ day: false, month: true, year: true }, 'LLLL yyyy'],
      [{ day: true, month: true, year: true }, 'd LLLL yyyy'],
    ];

    for (const [rule, format] of rules) {
      if (this.valueMatchesRule(value, rule)) {
        return format;
      }
    }
  }

  valueMatchesRule(value: typeof this.dateForm.value, rule: Record<string, boolean>): boolean {
    return Object.keys(rule).every((field) => rule[field] === Boolean(value[`${field}Control`]));
  }

  getMonthsForYear(year: string) {
    if (!year || this.allowFutureDates || year !== String(this.currentYear)) {
      return this.months;
    } else {
      return this.months.filter(({ value }) => parseInt(value, 10) <= this.zeroIndexCurrentMonth + 1);
    }
  }

  getNumberOfDaysFromMonth(oneIndexMonth: string, year: string): string[] {
    const chosenYear = Number(year ? year : 2016);
    const zeroIndexMonth = Number(oneIndexMonth) - 1;
    let days = DateTime.local(chosenYear, Number(oneIndexMonth)).daysInMonth;

    if (!oneIndexMonth) {
      days = 31;
    }
    const daysArray: string[] = [];
    for (let i = 1; i <= days; i++) {
      if (i < 10) {
        daysArray.push(`0${i}`);
      } else {
        daysArray.push(i.toString());
      }
    }
    if (
      !year ||
      !oneIndexMonth ||
      this.allowFutureDates ||
      !(zeroIndexMonth === this.zeroIndexCurrentMonth && chosenYear === this.currentYear)
    ) {
      return daysArray;
    } else {
      return daysArray.filter((i) => parseInt(i, 10) <= this.currentCalendarDay);
    }
  }

  createYearOptions(): string[] {
    const newArr = [];
    this.range = this.range ? this.range : 85;
    // If you want options ranging from current year up then don't set baseYear
    // If you want options ranging from current year down then set baseYear as new Date().getFullYear()
    this.baseYear = this.baseYear || this.currentYear;
    if (this.order === 'ascending') {
      for (let i = this.baseYear; i < this.baseYear + Number(this.range); i++) {
        newArr.push(i.toString());
      }
    } else {
      // This is default case, since this.order is not set by default.
      for (let i = this.baseYear; i > this.baseYear - Number(this.range); i--) {
        newArr.push(i.toString());
      }
    }
    return newArr;
  }

  setRestrictedValues() {
    Object.keys(this.restrictedValues).forEach((key) => {
      this[`${key}Options`] = this.restrictedValues[key];
    });
  }

  handleDateChanges() {
    // propogate valid dates to the parent
    this.dateForm.valueChanges.subscribe((value: { yearControl: string; monthControl: string; dayControl: string }) => {
      const { yearControl } = value;
      let { dayControl, monthControl } = value;

      // If the year changed and the selected month doesn't exist in the valid options, set to latest available month
      this.monthOptions = this.getMonthsForYear(yearControl);
      let newMonth: string;
      if (monthControl && this.monthOptions.map((monthOpt) => monthOpt.value).indexOf(monthControl) < 0) {
        newMonth = this.monthOptions[this.monthOptions.length - 1].value;
        monthControl = newMonth;
      }

      // If the month or year changed and the selected day doesn't exist in that month, set to the latest available day
      this.dayOptions = this.restrictedValues.day || this.getNumberOfDaysFromMonth(monthControl, yearControl);
      let newDay: string;
      if (dayControl && this.dayOptions.indexOf(dayControl) < 0) {
        newDay = this.dayOptions[this.dayOptions.length - 1];
        dayControl = newDay;
      }

      // Only update once
      let toPatch;
      if (newDay) {
        toPatch = {};
        toPatch.dayControl = newDay;
      }
      if (newMonth) {
        toPatch = toPatch || {};
        toPatch.monthControl = newMonth;
      }
      if (toPatch) {
        this.dateForm.patchValue(toPatch);
      }

      const date = [yearControl, monthControl, dayControl].join('-');
      if (yearControl && monthControl && dayControl) {
        this.hasValue = true;
      } else {
        this.hasValue = false;
      }
      this.propagateChange?.(date);
      this.propagateTouch?.(true);

      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.restrictedValues) {
      this.setRestrictedValues();
    }
    if (changes.order) {
      this.yearOptions = this.createYearOptions();
    }
  }

  ngOnInit() {
    this.log.info('ngOnInit');
    this.yearOptions = this.createYearOptions();
    this.handleDateChanges();
  }

  /**
   * Write a new value to the element.
   */
  writeValue(date: string): void {
    if (date == null) {
      this.dateForm.setValue(
        {
          dayControl: null,
          monthControl: null,
          yearControl: null,
        },
        { emitEvent: false },
      );
    }
    if (UCValidators.checkDateFormat(date)) {
      this.hasValue = true;
      const [year, month, day] = date.split('-');
      this.dayOptions = this.restrictedValues.day || this.getNumberOfDaysFromMonth(month, year);
      this.dateForm.setValue(
        {
          dayControl: day,
          monthControl: month,
          yearControl: year,
        },
        { emitEvent: false },
      );
    } else {
      this.log.warn('should be a valid date but is: ', date);
      // throw an error!
    }
  }

  registerOnChange(fn: (_: string) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: boolean) => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
