import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { DateTime } from 'luxon';

import { SubjectQuestion } from '@shared/models/subject-question';

@Component({
  selector: 'uc-subject-option-question',
  templateUrl: './subject-option-question.component.html',
})
export class SubjectOptionQuestionComponent implements OnInit {
  @Input() subjectQuestions: SubjectQuestion[];
  @Input() label: string;
  @Input() controls: UntypedFormArray;
  @Input() formGroup: UntypedFormGroup;
  @Input() isStaffTask = false;
  filt: string[] = [];

  countStr = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth'];

  ngOnInit() {
    this.subjectQuestions = this.subjectQuestions?.map((sq) => {
      sq.options = sq.options.filter((opt) => {
        return DateTime.now() < DateTime.fromISO(opt.effectiveToDate);
      });
      return sq;
    });
    this.formGroup.valueChanges.subscribe((data) => {
      this.getFilter(data.subjectOptions);
    });

    if (this.formGroup.get('subjectOptions')) {
      const subjectFormArrays = this.formGroup.get('subjectOptions').value;
      Object.keys(subjectFormArrays).forEach((key) => {
        this.getSelectedValue(key);
      });
    }
  }

  getTestSelector(i) {
    return this.getLabelText(i).toLowerCase().replace(/ /g, '-');
  }

  getLabelText(i) {
    return this.controls.controls.length === 1 ? `${this.label}` : `${this.countStr[i]} ${this.label}`;
  }

  getFilter(data: string[]) {
    this.filt = [];
    Object.keys(data).forEach((key) => {
      this.getSelectedValue(key);
    });
  }

  getSelectedValue(opt) {
    const optionsForm = this.formGroup.get('subjectOptions') as UntypedFormGroup;
    optionsForm
      .get(opt.toString())
      .value.filter((a) => !!a)
      .forEach((x) => this.filt.push(x));
  }
}
