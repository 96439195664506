import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { Modal } from 'ngx-modialog-11/plugins/bootstrap';

import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { environment } from '@environment';
import { internalUrls } from '@shared/constants/internalUrls';
import { Applicant } from '@shared/models/applicant';
import { UserTypes } from '@shared/models/user';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { DataService, DSHttpError, UCErrorCodes } from '@shared/services/data-service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-sso-template',
  templateUrl: './sso-template.component.html',
  styleUrls: ['./sso-template.component.css'],
})
export class SsoTemplateComponent implements AfterContentInit {
  @ViewChild('samlForm') samlForm: ElementRef;

  externalUrls = externalUrls;
  form = new UntypedFormGroup({
    samlResponse: new UntypedFormControl(''),
  });
  log: Logger;
  showError = false;
  samlUrl = environment.samlUrl || `${environment.apiRoot}/saml`;
  scholarshipsUrl = environment.scholarshipsUrl || externalUrls.SAMLProvider;
  strings = strings.components.template.ssoTemplate;
  currentApplicant: Applicant;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private userService: UserService,
    private applicantService: ApplicantService,
    private router: Router,
    private modal: Modal,
    logger: LoggingService,
  ) {
    this.log = logger.createLogger(this);
  }

  ngAfterContentInit() {
    this.getUserEmail(environment.scope);
  }

  getUserEmail(scope: string) {
    if (scope === UserTypes.student) {
      this.getStudentEmail();
    } else {
      this.getStaffEmail();
    }
  }

  getStaffEmail() {
    // Staff don't have an applicant model, use currentUser to get the email.
    // Assumption is that staff will always have an email address.
    this.userService.currentUser.subscribe((user) => {
      this.getSamlResponse(user.email);
    });
  }

  getStudentEmail() {
    this.applicantService.getApplicant().subscribe(
      (applicant) => {
        this.currentApplicant = applicant;
        const emailAddress = get(applicant, 'contactDetail.emailAddress');
        if (!emailAddress) {
          this.askForEmail();
        } else {
          this.getSamlResponse(emailAddress);
        }
      },
      (err) => this.handleErrorMessage(err),
    );
  }

  validateEmailAddress(emailAddress: string) {
    // The only way to use built-in email validation in Angular is to assign
    // a value you want to test to a form control it seems.
    const control = new UntypedFormControl(emailAddress, [Validators.required, Validators.email]);
    return control.valid;
  }

  askForEmail(valid = true) {
    let formConfig = { ...this.strings.emailForm };
    if (!valid) {
      formConfig = Object.assign(formConfig, this.strings.invalidEmailForm);
    }

    this.modal
      .prompt()
      .size('lg')
      .isBlocking(true)
      .showClose(false)
      .title(formConfig.title)
      .body(formConfig.body)
      .placeholder(formConfig.placeholder)
      .okBtn(formConfig.ok)
      .cancelBtn(formConfig.cancel)
      .cancelBtnClass('cancel-btn')
      .open()
      .result.then((emailAddress) => this.updateApplicantEmail(emailAddress))
      .catch(() => {
        this.router.navigate(internalUrls.dashboard);
      })
      .then(() => {
        document.body.classList.remove('modal-open');
      });
  }

  updateApplicantEmail(emailAddress: string) {
    if (!this.validateEmailAddress(emailAddress)) {
      this.askForEmail(false);
      return;
    }

    this.currentApplicant.contactDetail.emailAddress = emailAddress;
    this.applicantService.updateApplicant(this.currentApplicant).subscribe(
      () => this.getSamlResponse(emailAddress),
      (err) => this.handleErrorMessage(err),
    );
  }

  getSamlResponse(emailAddress: string) {
    this.http
      .get(this.samlUrl, {
        responseType: 'text',
        headers: { 'uc-email': emailAddress },
      })
      .subscribe(
        (res) => this.setAndSubmit(res),
        (err) => this.handleErrorMessage(err),
      );
  }

  setAndSubmit(saml) {
    // unescape/encodeURIComponent supports utf-8 chars which btoa can't handle
    const base64SAML = btoa(unescape(encodeURIComponent(saml)));
    this.form.get('samlResponse').setValue(base64SAML);
    this.samlForm.nativeElement.submit();
  }

  handleErrorMessage(err) {
    this.log.error(err);
    this.router.navigate(internalUrls.dashboard);
    this.dataService.allErrors$.next(new DSHttpError(err, { code: UCErrorCodes.E500, data: err }));
  }
}
