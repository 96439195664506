/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Modal, BSModalFooter } from 'ngx-modialog-11/plugins/bootstrap';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BehaviorSubject } from 'rxjs';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { UserTypes } from '@shared/models/user';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export const showModalLoadingState = (classname = 'btn') => {
  const button = document.querySelector(`.modal-dialog .${classname}`) as HTMLElement;
  if (!button) {
    return;
  }
  const originalText = button.innerText;
  button.innerHTML = `<span style="opacity: 0;">${originalText}</span>`;
  button.style.position = 'relative';

  const wrappingDiv = document.createElement('div');

  wrappingDiv.innerHTML = `
  <div class="loader show">
    <span class="loader-blip"></span>
    <span class="loader-blip"></span>
    <span class="loader-blip"></span>
  </div>`;
  button.appendChild(wrappingDiv);
};

export interface IModalOptions {
  handler?;
  closeHandler?;
  header: string;
  body: string;
  ok: string;
  cancel?: string;
}

export interface IModalButtonOptions {
  handler;
  label: string;
}

@Injectable()
export class ModalService {
  log: Logger;
  _shouldNavigate = true;
  _showThirdButton = true;
  form: UntypedFormGroup;
  modalDismissed = false;
  _buttonState = false;
  _overrideNavigationModalOpen = false;

  cardDirtyStatus: BehaviorSubject<string[]> = new BehaviorSubject([]);

  defaultStrings = strings.components.molecules.unsavedModal;
  _customStrings = {};

  set customStrings(obj) {
    this._customStrings = obj;
  }

  get strings() {
    return { ...this.defaultStrings, ...this._customStrings };
  }

  get buttonState(): boolean {
    return this._buttonState;
  }

  setButtonState(state: boolean) {
    this._buttonState = state;
  }

  saveHandler = () => Promise.resolve();

  preDismissHandler = () => Promise.resolve();

  cancelHandler = () => {};

  dismissHandler = () => {};

  closeModal = (context: BSModalFooter) => {
    this.modalDismissed = true;
    context.dialog.close();
  };

  constructor(
    private modal: Modal,
    public ngxSmartModalService: NgxSmartModalService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get shouldNavigate(): boolean {
    return this._shouldNavigate;
  }

  set shouldNavigate(value) {
    this._shouldNavigate = value;
  }

  get showThirdButton(): boolean {
    return this._showThirdButton;
  }

  set showThirdButton(value) {
    this._showThirdButton = value;
  }

  public unsavedWarningModal() {
    this.ngxSmartModalService.getModal('unsavedWarningModal').open();
  }

  public navigationWarning(completionHandler = () => Promise.resolve()) {
    // Some modals require an additional third button. This isn't easy to do with 3rd party modal library so we have this workaround.

    if (environment.scope === UserTypes.staff) {
      this.ngxSmartModalService.getModal('navigationWarningModal').open();
      return;
    }

    // If a third button is required, we show the default ok button:
    const okButtonClasses = this.showThirdButton
      ? 'third-btn btn btn-primary pos-3'
      : 'third-btn btn btn-primary hidden';

    const modal = this.modal
      .confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(false)
      .title(this.strings.header)
      .body(this.strings.question)
      .okBtn(this.strings.saveText)
      .okBtnClass(okButtonClasses)
      .cancelBtn(this.strings.closeText)
      .cancelBtnClass('cancel-btn pos-1')
      // this adds a new button to the modal footer, specifying the button class, text, and handler function
      .addButton('btn btn-primary continue-btn pos-2', this.strings.customButtonText, this.closeModal)
      .open();

    modal.result
      .then(() => {
        this.modalDismissed ? showModalLoadingState('continue-btn') : showModalLoadingState('third-btn');
      })
      .catch(() => {});

    modal.onDestroy.subscribe(() => {
      modal.result
        .then(() => {
          if (this.modalDismissed) {
            this.preDismissHandler().then(() => this.dismissHandler());
            completionHandler().catch(() => {});
          } else {
            this.saveHandler();
          }
          this.customStrings = {};
        })
        .catch(() => {
          this.cancelHandler();
        })
        .then(() => {
          this.shouldNavigate = this.cardDirtyStatus.value.length === 0;
          this.modalDismissed = false;
          document.body.classList.remove('modal-open');
        });
    });
  }

  /**
   * This section of the service is used to centralise the way we show modals across a few components
   * Over time we may need to make these functions more flexible
   */
  setupConfirmModal(config: IModalOptions) {
    const cancelLabel = config.cancel || 'Cancel';
    return this.modal
      .confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(false)
      .title(config.header)
      .body(config.body)
      .okBtn(config.ok)
      .cancelBtn(cancelLabel)
      .cancelBtnClass('cancel-btn');
  }

  private onModalClose(config: IModalOptions, dialog?) {
    if (config.closeHandler) {
      config.closeHandler(dialog);
    }
    document.body.classList.remove('modal-open');
  }

  showModal(config: IModalOptions): void {
    const dialog = this.setupConfirmModal(config).open();

    dialog.setCloseGuard({
      beforeClose: () => {
        showModalLoadingState();
        return config.handler(dialog);
      },
    });

    dialog.result.catch(() => this.log.info('modal dismissed')).then(() => this.onModalClose(config, dialog));
  }

  showAlertModal(config: IModalOptions) {
    const alert = this.modal
      .alert()
      .size('lg')
      .isBlocking(false)
      .showClose(true)
      .title(config.header)
      .body(config.body)
      .okBtn(config.ok);

    const dialog = alert.open();
    dialog.result.catch(() => this.log.info('modal dismissed')).then(() => this.onModalClose(config));
  }

  showThreeButtonModal(config: IModalOptions, otherBtnOptions: IModalButtonOptions): void {
    let otherButtonClicked = false;

    const otherBtnHandler = (context: BSModalFooter) => {
      otherButtonClicked = true;
      otherBtnOptions.handler();
      context.dialog.close();
    };

    const dialog = this.setupConfirmModal(config)
      .okBtnClass('third-btn btn btn-primary pos-3')
      .addButton('btn btn-primary second-btn pos-2', otherBtnOptions.label, otherBtnHandler)
      .open();

    dialog.result
      .then(() => {
        otherButtonClicked ? showModalLoadingState('second-btn') : showModalLoadingState('third-btn');
      })
      .catch(() => {});

    dialog.onDestroy.subscribe(() => {
      dialog.result
        .then(() => {
          if (!otherButtonClicked) {
            config.handler();
          }
        })
        .catch(() => this.log.info('modal dismissed'))
        .then(() => this.onModalClose(config));
    });
  }
}
