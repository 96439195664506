<uc-subsection [formGroup]="sslForm">
  <uc-task-hint [task]="task" path="/application/rebate_category"></uc-task-hint>
  <uc-subsection-question required="true">{{ strings.radioStudyQuestion }}</uc-subsection-question>
  <uc-radio-input-group
    [additionalText]="extraDescriptions"
    formControlName="rebateCategory"
    type="SSL_rebate_category"
    groupName="ssl-rebate"
  >
  </uc-radio-input-group>
  <div *ngIf="rebateCategory" [ngSwitch]="rebateCategory">
    <div *ngSwitchCase="'DISTANCE'">
      <uc-task-hint [task]="task" path="/application/declaration_rebate_category_distance"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.distanceSection.header }}
      </uc-subsection-question>

      <uc-subsection-text-block [innerHTML]="strings.distanceSection.context"> </uc-subsection-text-block>

      <uc-form-input>
        <uc-checkbox-input
          required="true"
          label="{{ strings.distanceSection.inputLabel }}"
          formControlName="declarationRebateCategoryDistance"
          groupName="rebate-dec"
        >
        </uc-checkbox-input>
      </uc-form-input>
    </div>

    <div *ngSwitchCase="'EDUCATION'">
      <uc-subsection-question>
        {{ strings.educationSection.header }}
      </uc-subsection-question>

      <uc-subsection-text-block [innerHTML]="strings.educationSection.context"> </uc-subsection-text-block>

      <uc-form-input>
        <uc-file-upload
          [year]="applicationYear"
          required="yes"
          title="{{ strings.educationSection.inputLabel }}"
          categoryCode="SSLEVY"
          usedInTask="true"
        ></uc-file-upload>
      </uc-form-input>
    </div>

    <div *ngSwitchCase="'RESEARCH'">
      <uc-subsection-question>
        {{ strings.researchSection.header }}
      </uc-subsection-question>

      <uc-subsection-text-block [innerHTML]="strings.researchSection.context"> </uc-subsection-text-block>

      <uc-form-input>
        <uc-file-upload
          required="yes"
          title="{{ strings.researchSection.inputLabel }}"
          categoryCode="SSLEVY"
          [year]="applicationYear"
          usedInTask="true"
        ></uc-file-upload>
      </uc-form-input>
    </div>

    <div *ngSwitchCase="'OTHER REBATE'">
      <uc-task-hint [task]="task" path="/application/rebate_category_other"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.otherRebateSection.header }} <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-text-block [innerHTML]="strings.otherRebateSection.context"> </uc-subsection-text-block>

      <uc-form-input>
        <uc-text-area testSelector="ssl-other" formControlName="rebateCategoryOther"> </uc-text-area>
      </uc-form-input>
    </div>
  </div>
</uc-subsection>
