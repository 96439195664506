const loadExternalScript = (script, baseHref) => {
  const el = document.createElement('script');
  const location = script.location || 'head';
  const position = script.position || 'beforeend';

  el.src = !script.src ? `${baseHref}assets/scripts/${script.fileName}.js` : script.src;
  if (script.options) {
    Object.keys(script.options).forEach((optKey) => {
      el[optKey] = script.options[optKey];
    });
  }

  document[location].insertAdjacentElement(position, el);
};

export const scriptLoader = (envConfig) => {
  if (envConfig.externalScripts) {
    const baseUrl = window.document.querySelector('base').getAttribute('href');
    const basePath = !!baseUrl.match(/\/$/) ? baseUrl : `${baseUrl}/`;

    Object.values(envConfig.externalScripts).forEach((scriptConfig) => loadExternalScript(scriptConfig, basePath));
  }
};
