/* eslint-disable import/no-restricted-paths */
import { Component, Input } from '@angular/core';

import svgs from '@constants/svg-data';
import colors from '@shared/../global-styles/1-variables/_colors.json';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() iconType: string;
  @Input() iconWidth = 24;
  @Input() iconHeight = 24;
  @Input() iconFill = 'currentColor';
  @Input() color = 'currentColor';
  @Input() backgroundColor: string;
  @Input() iconTitle: string;
  @Input() lineStyle = '';
  @Input() fillRule = '';

  private errors = {};
  colors = colors;

  private log: Logger;
  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get iconSVG() {
    if (!svgs[this.iconType] && !this.errors[this.iconType]) {
      this.errors[this.iconType] = true;
      this.log.error(`No svg found for iconType ${this.iconType}`);
    }
    return svgs[this.iconType];
  }

  get classes() {
    const classes = [`icon-${this.iconType}`];
    if (this.backgroundColor) {
      classes.push('has-background');
    }
    return classes.join(' ');
  }
}
