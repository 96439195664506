import { Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';
import { get } from 'lodash-es';
import { of, BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { CourseSearchParams } from '@shared/components/molecules/course-search/course-search.component';
import { snakeifyKeys } from '@shared/helpers/serialization';
import { CourseOccurrence, CourseGroup, CourseBucket, SubjectGroup } from '@shared/models/course';
import { CourseSearchResult } from '@shared/models/course-result';
import { UCError } from '@shared/models/errors';
import { TeachingPeriod } from '@shared/models/teaching-period';
import { DataService } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

import { courseSearch, coursesByURI, courseOccurrences, mockTeachingPeriods } from './mock-course-response';

export enum DisplayType {
  GRID = 'Grid',
  TABLE = 'Table',
}

export interface CourseSearchOptions {
  includeAll?: boolean;
}

@Injectable()
export class CourseService {
  protected serviceUrl = `${environment.apiRoot}/course`;
  protected log: Logger;
  protected courseError$ = new Subject<UCError>();
  protected searchResult$ = new BehaviorSubject<CourseSearchResult>(null);
  protected searchResultError$ = new Subject<UCError>();
  protected courseFilter$ = new BehaviorSubject<Record<string, string>>({});
  protected selectedCourses$ = new BehaviorSubject<CourseOccurrence[]>(null);
  protected teachingPeriods$: Record<string, BehaviorSubject<TeachingPeriod[]>> = {};
  public courseResults: Observable<CourseOccurrence[]>;
  public searchDisplayType$ = new BehaviorSubject<string>(DisplayType.GRID);

  constructor(
    protected dataService: DataService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
    this.configureFiltering();
  }

  get courseError(): Observable<UCError> {
    return this.courseError$.asObservable();
  }

  get selectedCourses(): Observable<CourseOccurrence[]> {
    return this.selectedCourses$.asObservable();
  }

  set currentCourses(value: CourseOccurrence[]) {
    this.selectedCourses$.next(value);
  }

  get currentCourses() {
    return this.selectedCourses$.value;
  }

  get searchResultError(): Observable<UCError> {
    return this.searchResultError$.asObservable();
  }

  get searchResult(): Observable<CourseSearchResult> {
    return this.searchResult$.asObservable();
  }

  get searchValue(): CourseSearchResult {
    return this.searchResult$.value;
  }

  set searchValue(value) {
    this.searchResult$.next(value);
  }

  // eslint-disable-next-line complexity, max-lines-per-function
  static bucketize(courses: CourseOccurrence[], teachingPeriods: TeachingPeriod[]): CourseBucket[] {
    const bucketsByCode = new Map();
    if (!teachingPeriods) {
      return;
    }
    teachingPeriods.forEach((tp) => bucketsByCode.set(tp.code, new CourseBucket(tp)));

    if (courses && courses.length) {
      courses.sort((a, b) => {
        if (a.courseOccurrenceCode < b.courseOccurrenceCode) {
          return -1;
        } else if (a.courseOccurrenceCode > b.courseOccurrenceCode) {
          return 1;
        } else {
          return 0;
        }
      });

      courses.forEach((c) => {
        const teachingPeriodCode = get(c, 'teachingPeriodCode') as string;
        if (!teachingPeriodCode) {
          return;
        }
        const bucket = bucketsByCode.get(teachingPeriodCode);
        if (bucket) {
          bucket.addCourse(c);
        } else {
          captureException(new Error(`Unknown teaching period code: ${teachingPeriodCode}`));
        }
      });
    }

    const hasCourses = (bucket) => get(bucket, 'courses.length');
    const allBuckets = Array.from(bucketsByCode.values());
    return allBuckets.filter(hasCourses);
  }

  teachingPeriods(year: string): Observable<TeachingPeriod[]> {
    if (this.teachingPeriods$[year] && this.teachingPeriods$[year].value) {
      return this.teachingPeriods$[year].asObservable();
    }
    return this.getTeachingPeriods(year);
  }

  clearSearch() {
    this.searchResult$.next(null);
  }

  clearCache() {
    this.searchResult$.next(null);
    this.selectedCourses$.next(null);
  }

  private configureFiltering() {
    this.courseResults = combineLatest([this.searchResult$.asObservable(), this.courseFilter$.asObservable()]).pipe(
      map(([searchResults, terms]) => {
        if (searchResults?.course) {
          return this.applyFilter(searchResults.course, terms);
        }
        return null;
      }),
    );
  }

  private applyFilter(courses: CourseOccurrence[], terms: Record<string, string>): CourseOccurrence[] {
    return courses.filter((course) => {
      return Object.entries(terms).every(([key, selection]) => {
        if (Array.isArray(selection)) {
          return (selection as string[]).includes(course[key]);
        } else if (selection !== '') {
          return course[key] === selection;
        } else {
          return true;
        }
      });
    });
  }

  filterCourses(terms) {
    this.courseFilter$.next(terms);
  }

  /**
   * Makes a request to the Course microservice to retrieve a
   * list of Courses for the given academic year matching the provided search query.
   * The request requires the currentUser's token to be in the Auth header
   * of the request.
   *
   * @memberOf CourseService
   */
  // eslint-disable-next-line max-lines-per-function
  queryCourses(
    year: string,
    searchParams: CourseSearchParams,
    options: CourseSearchOptions = {},
  ): Observable<CourseSearchResult> {
    const urlParts = [this.serviceUrl, year];
    if (options?.includeAll) {
      urlParts.splice(1, 0, 'all');
    }
    const url = urlParts.join('/');
    Object.keys(searchParams).forEach((el) => {
      if (!searchParams[el]) {
        delete searchParams[el];
      }
    });
    const serverSearchParams = snakeifyKeys(searchParams);

    return this.dataService.fetch(url, {
      requestOptions: { params: serverSearchParams },
      success$: this.searchResult$,
      error$: this.searchResultError$,
      deserialize: CourseSearchResult.deserialize,
    });
  }

  getAllCourses(year: string): Observable<CourseOccurrence[]> {
    const url = [this.serviceUrl, year].join('/');
    return this.dataService.fetch(url, {
      success$: this.searchResult$,
      error$: this.searchResultError$,
      deserialize: CourseOccurrence.deserializeAll,
    });
  }

  /**
   * Makes a request to the Course microservice to retrieve a
   * list of Courses for the given academic year based on the provided URIs.
   * The request requires the currentUser's token to be in the Auth header
   * of the request.
   *
   * @memberOf CourseService
   */
  getCoursesByURIs(year: string, codes: string[]): Observable<CourseOccurrence[]> {
    const url = this.serviceUrl;
    const uris = codes.map((c) => `/course/${year}/${c}`);
    return this.dataService.post(
      url,
      { uris },
      {
        error$: this.courseError$,
        deserialize: CourseOccurrence.deserializeAll,
      },
    );
  }

  getCourseGroups(qualCode: string, year: string, groupType?: string): Observable<CourseGroup[]> {
    const params = new URLSearchParams({ qualification: qualCode });
    if (groupType) {
      params.set('group_type', groupType);
    }
    const url = `${this.serviceUrl}/curriculum-group/qualification/${year}?${params}`;

    return this.dataService.fetch(url, {
      error$: this.courseError$,
      deserialize: CourseGroup.deserialize,
    });
  }

  getSubjectGroups(): Observable<SubjectGroup[]> {
    const url = `${this.serviceUrl}/curriculum-group/subject`;
    return this.dataService.fetch(url, {
      error$: this.courseError$,
      deserialize: SubjectGroup.deserialize,
    });
  }

  getTeachingPeriods(year: string) {
    const url = `${this.serviceUrl}/${year}/teaching-period`;
    return this.dataService
      .fetch(url, {
        deserialize: TeachingPeriod.deserialize,
      })
      .pipe(
        tap((teachingPeriods) => {
          if (!this.teachingPeriods$[year]) {
            this.teachingPeriods$[year] = new BehaviorSubject<TeachingPeriod[]>(teachingPeriods);
          } else {
            this.teachingPeriods$[year].next(teachingPeriods);
          }
        }),
      );
  }
}

@Injectable()
export class MockCourseService extends CourseService {
  public selectedCourses$ = new BehaviorSubject<CourseOccurrence[]>(null);

  constructor() {
    super(null, {
      createLogger: () => new Logger('MockCourseService'),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAllCourses(year: string): Observable<CourseOccurrence[]> {
    const results = new CourseSearchResult({
      course: courseOccurrences(),
      meta: {
        resultCount: courseOccurrences().length,
      },
    });
    return of(courseOccurrences()).pipe(tap(() => this.searchResult$.next(results)));
  }

  queryCourses(year: string, searchParams: { [key: string]: string }): Observable<CourseSearchResult> {
    const result = courseSearch(searchParams.query);
    const selectedCourses = CourseSearchResult.deserialize(result);
    return of(selectedCourses).pipe(tap(() => this.searchResult$.next(selectedCourses)));
  }

  getCoursesByURIs(year: string, uris: string[]): Observable<CourseOccurrence[]> {
    const result = CourseOccurrence.deserializeAll(coursesByURI(uris));

    return of(result).pipe(tap(() => this.selectedCourses$.next(result)));
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  getCourseGroups(qualCode: string, year: string): Observable<CourseGroup[]> {
    return of([
      { name: 'All courses for the BA', guidanceText: 'some BA guidanceText', code: { code: '1' } },
      { name: 'All bachelor degree level courses', guidanceText: 'some other guidanceText', code: { code: '2' } },
      { name: 'All bachelor degree level courses', guidanceText: 'some guidanceText', code: { code: '3' } },
    ]);
  }

  // eslint-disable-next-line class-methods-use-this
  getSubjectGroups(): Observable<SubjectGroup[]> {
    return of([
      {
        name: 'Subject group 1 Subject group 1 Subject group 1 Subject group 1 Subject group 1 Subject group 1 Subject group 1',
        guidanceText: 'some other guidanceText',
        code: {
          code: 'subjectCode',
        },
      },
    ]);
  }

  getTeachingPeriods(year: string): Observable<TeachingPeriod[]> {
    this.teachingPeriods$[year] = new BehaviorSubject(mockTeachingPeriods());
    return this.teachingPeriods(year);
  }
}

export const courseServiceFactory = (dataService, loggingService) => {
  if (environment.useFakeBackend.course) {
    return new MockCourseService();
  } else {
    return new CourseService(dataService, loggingService);
  }
};

export const courseServiceProvider = {
  provide: CourseService,
  useFactory: courseServiceFactory,
  deps: [DataService, LoggingService],
};
