import { Component, Input, OnInit } from '@angular/core';
import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { WindowService } from '@shared/services/window/window.service';
import { UserService } from '@shared/services/user/user.service';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { ReferenceData } from '@shared/models/reference-data';

@Component({
  selector: 'uc-ucsw-handoff',
  templateUrl: './ucsw-handoff.component.html',
  styleUrls: ['./ucsw-handoff.component.scss'],
})
export class UcswHandoffComponent extends AbstractBaseTask {
  @Input() task: Task;
  @Input() processFilter: string;
  @Input() applicationYear: string;
  isLoading = false;
  strings = strings.components.tasks.ucswHandoff;

  constructor() {
    super();
  }

  public updateFormValidity(err: UCError) {}
}
