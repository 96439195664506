<uc-form-template>
  <uc-emergency-contact
    [task]="task"
    [emergencyDetails]="reviewForm.get('emergencyDetails')"
    copyAddressButtonText="Copy My Permanent Address"
    (copyAddress)="copyPermanentToEmergencyAddress()"
    [includeUpToDateDeclaration]="true"
  >
  </uc-emergency-contact>
</uc-form-template>
