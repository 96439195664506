import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, HostBinding, Input } from '@angular/core';
import {
  FlashMessage,
  FlashMessagePosition,
  FlashMessageService,
} from '@shared/services/flash-message/flash-message.service';
import { Router, NavigationEnd } from '@angular/router';

import { trigger, query, style, stagger, keyframes, animate, transition } from '@angular/animations';
import { WindowService } from '@shared/services/window/window.service';

const leaveAnimation = query(
  ':leave',
  stagger('300ms', [
    animate(
      '500ms ease-in',
      keyframes([
        style({
          opacity: 1,
          offset: 0,
        }),
        style({
          opacity: 0,
          offset: 1.0,
        }),
      ]),
    ),
  ]),
  {
    optional: true,
  },
);

@Component({
  selector: 'uc-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss'],
  animations: [
    trigger('topAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
        query(
          ':enter',
          stagger('300ms', [
            animate(
              '300ms ease-in',
              keyframes([
                style({
                  opacity: 0,
                  transform: '',
                  offset: 0,
                }),
                style({
                  opacity: 1,
                  transform: '',
                  offset: 1.0,
                }),
              ]),
            ),
          ]),
          {
            optional: true,
          },
        ),
        leaveAnimation,
      ]),
    ]),
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
        query(
          ':enter',
          stagger('300ms', [
            animate(
              '300ms ease-in',
              keyframes([
                style({
                  opacity: 0,
                  transform: 'translateY(100%)',
                  offset: 0,
                }),
                style({
                  opacity: 1,
                  transform: 'translateY(0)',
                  offset: 1.0,
                }),
              ]),
            ),
          ]),
          {
            optional: true,
          },
        ),
        leaveAnimation,
      ]),
    ]),
  ],
})
export class FlashMessageComponent implements OnInit, OnDestroy {
  private flashSubscription: Subscription;
  public flashMessages: { [key: string]: FlashMessage[] } = {};
  public systemMessages: FlashMessage[];
  public validRoute = true;
  private invalidRoutes = ['login', 'auth-action'];

  @Input() show: boolean;

  constructor(private flashService: FlashMessageService, private router: Router, private ws: WindowService) {}

  ngOnInit() {
    this.flashSubscription = this.flashService.messages.subscribe((messages) => {
      Object.keys(FlashMessagePosition).forEach((key) => {
        this.flashMessages[key] = [];
      });

      const flashMessages = messages.filter((el) => el.type !== 'system');
      flashMessages.forEach((message) => {
        this.flashMessages[message.position].push(message);
      });

      this.systemMessages = messages.filter((el) => el.type === 'system');
    });
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.validRoute = !this.invalidRoutes.find((el) => !!e.url.match(el));
      }
    });
  }

  ngOnDestroy() {
    if (this.flashSubscription) {
      this.flashSubscription.unsubscribe();
    }
    this.flashService.clear();
  }

  closeMessage(message: FlashMessage) {
    this.flashService.removeMessage(message);
  }

  hasValidLink(message: FlashMessage) {
    return Array.isArray(message.linkUrl) ? !!message.linkUrl.length : !!message.linkUrl;
  }

  goToLink(message: FlashMessage) {
    if (Array.isArray(message.linkUrl)) {
      this.router.navigate(message.linkUrl);
    } else {
      this.ws.nativeWindow.open(message.linkUrl, '_blank');
    }
    this.flashService.removeMessage(message);
  }
}
