import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { QUALIFICATION_LEVELS } from '@shared/constants/academic-history';
import { Task } from '@shared/models/task';
import { UserTypes } from '@shared/models/user';
import { countryList } from '@shared/services/country-list';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-tertiary-education',
  templateUrl: './tertiary-education.component.html',
  styleUrls: ['./tertiary-education.component.scss'],
})
export class TertiaryEducationComponent {
  @Input() task: Task;
  @Input() tertiaryType: string;
  @Input() tertiaryEducationForm: UntypedFormGroup;
  @Input() applicationYear: string;
  @Output() addQual = new EventEmitter();
  @Output() removeQual = new EventEmitter();

  log: Logger;
  strings = strings.components.organisms.tertiaryEducation;
  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get qualificationLevels() {
    return QUALIFICATION_LEVELS;
  }

  get educations(): UntypedFormArray {
    return this.tertiaryEducationForm.get('educations') as UntypedFormArray;
  }

  get taskTitle() {
    const isFoundation = this.tertiaryType === QUALIFICATION_LEVELS.FOUNDATION;
    return isFoundation ? this.strings.foundationTitle : this.strings.tertiaryTitle;
  }

  addQualification() {
    this.addQual.emit({ type: 'tertiaryEducation' });
  }

  removeQualification(indexToBeRemoved) {
    this.removeQual.emit(indexToBeRemoved);
  }

  showOtherQualInput(qualNum) {
    const type = this.educations.controls[qualNum].get('type');
    const qualType = get(type, 'value.code');
    return qualType === 'OTHER';
  }

  hasCompletedQual(qualNum) {
    const attained = this.educations.controls[qualNum].get('attained');
    return !!get(attained, 'value');
  }

  showTertiaryResults() {
    const foundationStudy = this.tertiaryType === QUALIFICATION_LEVELS.FOUNDATION;
    let otherFoundationStudied = false;
    this.educations.controls.forEach((formGroup) => {
      if (get(formGroup.get('type').value, 'code') === 'OTHER') {
        otherFoundationStudied = true;
      }
    });
    return !foundationStudy || (foundationStudy && otherFoundationStudied);
  }

  showGraduateCertificateUpload() {
    const foundationStudy = this.tertiaryType === QUALIFICATION_LEVELS.FOUNDATION;
    let hasAttainedTertiaryQual = false;
    let otherFoundationComplete = false;
    this.educations.controls.forEach((formGroup) => {
      const otherFoundation = foundationStudy && get(formGroup.get('type').value, 'code') === 'OTHER';
      otherFoundationComplete = otherFoundationComplete ? true : otherFoundation && formGroup.get('attained').value;
      hasAttainedTertiaryQual = hasAttainedTertiaryQual ? true : !foundationStudy && formGroup.get('attained').value;
    });
    return otherFoundationComplete || hasAttainedTertiaryQual;
  }

  showGpaReportUpload() {
    const relevantTertiaryTypes = [QUALIFICATION_LEVELS.POST_GRADUATE, QUALIFICATION_LEVELS.UNDER_GRADUATE];
    const exemptCountries = [countryList.NZ, countryList.US];

    const isRelevantTertiaryType = relevantTertiaryTypes.indexOf(this.tertiaryType) >= 0;
    const selectedTertiaryCountries = this.educations.controls.map((educationForm: UntypedFormGroup) => {
      return get(educationForm.get('country').value, 'code');
    });

    const hasCountrySelected = selectedTertiaryCountries.filter((el) => !!el).length > 0;
    const isRelevantTertiaryCountry = selectedTertiaryCountries.some((country) => {
      return exemptCountries.indexOf(country) === -1;
    });
    const isAgent = environment.scope === UserTypes.agent;

    return isRelevantTertiaryType && hasCountrySelected && isRelevantTertiaryCountry && !isAgent;
  }
}
