import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { environment } from '@environment';
import { UCError } from '@shared/models/errors';
import { Sanction, SanctionType } from '@shared/models/sanction';

import { mockData as sanctionsMockData } from './sanctions.data.mock';
import { DataService } from '../data-service';

@Injectable()
export class SanctionsService {
  private serviceUrl = `${environment.apiRoot}/sanctions/`;
  private staffUrl = `${this.serviceUrl}staff/`;
  private sanctions$ = new BehaviorSubject<Sanction[]>(null);
  private error$ = new Subject<UCError>();
  sanctionsTypes$ = new BehaviorSubject<SanctionType[]>(null);

  constructor(private dataService: DataService) {}

  get sanctions(): Observable<Sanction[]> {
    return this.sanctions$.asObservable();
  }

  getSanctionsTypes(): Observable<SanctionType[]> {
    if (this.sanctionsTypes$.value) {
      return of(this.sanctionsTypes$.value);
    }

    const url = `${this.serviceUrl}types`;
    return this.dataService.fetch(url, {
      success$: this.sanctionsTypes$,
      deserialize: SanctionType.deserialize,
    });
  }

  getSanctionsForStaff(canonicalId: string): Observable<Sanction[]> {
    const url = this.staffUrl + canonicalId;
    return this.dataService.fetch(url, {
      success$: this.sanctions$,
      deserialize: Sanction.deserialize,
      error$: this.error$,
      ignoredErrorStatuses: [404],
    });
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars,class-methods-use-this */
export class MockSanctionsService {
  mockSanctionsResponse: ReturnType<typeof sanctionsMockData>;
  sanctionsTypes$ = new BehaviorSubject<SanctionType[]>(null);

  constructor() {
    this.mockSanctionsResponse = sanctionsMockData();
  }

  getSanctionsTypes() {
    const mockTypes: SanctionType[] = [
      {
        code: 'DISC_FULL',
        description: 'Discipline - Full',
        mandatoryActions: [
          {
            code: 'ENROLMENT',
            description: 'Refuse enrolment',
            validFrom: '1873-01-01',
            validTo: null,
          },
        ],
        validFrom: '1873-01-01',
        validTo: null,
        displayRemovedOnTranscript: false,
      },
    ];
    return of(mockTypes);
  }

  getSanctionsForStaff(canonicalId?: string): Observable<Sanction[]> {
    const mockSanctions = Sanction.deserialize(this.mockSanctionsResponse);
    return of(mockSanctions);
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars,class-methods-use-this */

export const sanctionServiceFactory = (dataService): SanctionsService | MockSanctionsService => {
  if (environment.useFakeBackend.lead) {
    return new MockSanctionsService();
  } else {
    return new SanctionsService(dataService);
  }
};

export const sanctionsServiceProvider = {
  provide: SanctionsService,
  useFactory: sanctionServiceFactory,
  deps: [DataService],
};
