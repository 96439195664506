import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { HeaderBarComponent } from '@shared/components/organisms/header-bar/header-bar.component';

@Component({
  selector: 'uc-online-header-bar',
  templateUrl: './online-header-bar.component.html',
  styleUrls: ['./online-header-bar.component.scss'],
})
export class OnlineHeaderBarComponent extends HeaderBarComponent implements OnInit {
  @Input() hasSideNav: boolean;
  @Input() hideNavItems: boolean;
  @Input() hideToolbarItems: boolean;
  @Input() hideLogo = false;
  @Input() showTotaraUrl = false;
  @Output() openSidenav = new EventEmitter();

  ngOnInit() {
    if (!this.hideToolbarItems) {
      super.ngOnInit();
    }
  }

  hamburgerClick() {
    this.openSidenav.emit();
  }
}
