import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { externalUrls } from '@shared/constants/urls.constants';
import { User, UserDetail, UserTypes } from '@shared/models/user';
import { DocumentService } from '@shared/services/document/document.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit {
  @Input() hasSideNav: boolean;
  @Input() showBackLink: boolean;
  @Input() showApplicationStatus: boolean;
  @Input() showUCOnlineLearningPlatform = false;

  @Output() openSidenav = new EventEmitter();

  open = false;
  documentCount = 0;
  internalUrls = internalUrls;
  externalUrls = externalUrls;
  feedbackUrl;
  importantDocumentCount$ = new BehaviorSubject<number>(0);
  strings = strings.components.organisms;
  log: Logger;
  impersonated: User;
  environment = environment;

  constructor(
    public router: Router,
    private documentService: DocumentService,
    public modalService: ModalService,
    public userService: UserService,
    public flashMessageService: FlashMessageService,
    public route: ActivatedRoute,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get showDocLibrary() {
    return this.environment.scope !== UserTypes.agent;
  }

  get importantDocumentCount() {
    return this.importantDocumentCount$.value;
  }

  get goBackLabel() {
    return this.environment.scope === UserTypes.student
      ? this.strings.headerBar.backToManageMyStudy
      : this.strings.headerBar.backToDashboard;
  }

  get showFeedbackIcon() {
    return this.environment.scope === UserTypes.student;
  }

  ngOnInit() {
    this.monitorImportantDocumentCount();
    this.updateFeedbackURLFromUser();
    this.monitorUserImpersonation();
  }

  monitorImportantDocumentCount() {
    this.documentService.importantDocumentCount.subscribe((count) => this.importantDocumentCount$.next(count));
  }

  updateFeedbackURLFromUser() {
    this.userService.getUser().subscribe((user: User) => {
      this.feedbackUrl = externalUrls.feedbackLink(user.canonicalId);
    });
  }

  monitorUserImpersonation() {
    this.userService.userDetail.subscribe((detail: UserDetail) => {
      if (detail && this.environment.scope === UserTypes.staff) {
        this.impersonated = detail.impersonated;
      }
    });
  }

  unimpersonate() {
    this.userService.unimpersonate().subscribe(() => {
      this.modalService.shouldNavigate = true;
      this.open = false;
    });
  }

  hamburgerClick() {
    this.openSidenav.emit();
  }

  goBackHandler() {
    if (this.environment.scope === UserTypes.agent) {
      return this.router.navigate(internalUrls.dashboard);
    }

    const year = this.route.snapshot.paramMap.get('year');
    const queryParams = year ? { year } : {};
    this.router.navigate(internalUrls.manageMyStudy, { queryParams });
  }
}
