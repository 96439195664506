import { EventEmitter, Output, Directive } from '@angular/core';

import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { INextTaskOptions } from '@shared/components/templates/process-page/process-page.component';
import { MODEL_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';

/**
 * Base class to be used for all process task components in /src/app/applicant/tasks/
 */

@Directive()
export abstract class AbstractBaseTask extends UnsubscribeOnDestroy {
  @Output() next: EventEmitter<INextTaskOptions> = new EventEmitter();
  @Output() errors: EventEmitter<UCError> = new EventEmitter();
  public strings: unknown;
  public dontTriggerTaskIsUpdating = false;
  public stopTaskSubmit = false;
  public dataModel: string;
  public isCOE = false;
  public hintPath: string;
  public currentApplication: Application | ChangeOfEnrolment;
  public service: ApplicationService | ChangeOfEnrolmentService;
  public task: Task;

  /**
   * Used by the process-page component to syncronize 422 errors down to the formGroup.
   *
   * For backwards compatibility with older components that don't use the FormGroup DSL,
   * just implement a no-op function. A no-op function is deliberately not implemented
   * here to ensure that new components actually implement this function
   *
   * Usage:
   *
   *   updateFormValidity (err: UCError) {
   *     this.formModelMapperService.updateFormValidity(err.data, <UCFormElement>this.taskForm);
   *   }
   *
   * @param {UCError} err
   */
  public abstract updateFormValidity(err: UCError): void;

  public update() {
    this.next.emit();
  }

  public setProcessCode(code: string) {
    this.dataModel = code === STAGE_NAMES.CHANGE_OF_ENROLMENT ? MODEL_NAMES.CHANGEOFENROLMENT : MODEL_NAMES.APPLICATION;
    this.isCOE = code === STAGE_NAMES.CHANGE_OF_ENROLMENT ? true : false;
  }

  public getService(applicationService: ApplicationService, coeService: ChangeOfEnrolmentService) {
    if (this.isCOE) {
      this.service = coeService;
      this.hintPath = '/change_of_enrolments';
      this.currentApplication = this.currentApplication as ChangeOfEnrolment;
    } else {
      this.service = applicationService;
      this.hintPath = '/application';
      this.currentApplication = this.currentApplication as Application;
    }
  }
}
