import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'uc-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
})
export class FormFieldLabelComponent implements OnInit {
  @Input() required: string;
  @Input() labelText: string;
  @Input() labelTooltipContent: string;

  constructor() {}

  ngOnInit() {}

  labelClicked(event) {
    event.preventDefault();
  }
}
