<uc-section [formGroup]="emergencyDetails">
  <uc-section-header title="{{ strings.sectionTitle }}">
    <p
      class="description"
      [innerHTML]="strings.description"
    ></p>
  </uc-section-header>
  <uc-form-input>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/emergency_contact_name/first_name"
      ></uc-task-hint>
      <uc-string-input
        testSelector="emergency-first-name"
        formControlName="firstName"
        label="{{ strings.firstnameLabel }}"
        required="yes"
      >
      </uc-string-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/emergency_contact_name/surname"
      ></uc-task-hint>
      <uc-string-input
        testSelector="emergency-last-name"
        formControlName="lastName"
        label="{{ strings.lastnameLabel }}"
        required="yes"
      >
      </uc-string-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/emergency_contact_detail/email_address"
      ></uc-task-hint>
      <uc-string-input
        testSelector="emergency-email"
        formControlName="email"
        label="{{ strings.emailLabel }}"
      >
      </uc-string-input>
    </uc-subsection>

    <uc-subsection>
      <uc-form-input>
        <uc-task-hint
          [task]="task"
          path="/applicant/emergency_contact_detail/contact_number"
        ></uc-task-hint>
        <uc-subsection-question [required]="true">{{ strings.phoneNumberQuestion }}</uc-subsection-question>
        <uc-subsection-minor-text>{{ phoneStrings.mobileHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          [task]="task"
          path="/applicant/emergency_contact_detail/mobile_number"
          formControlName="mobileNum"
          labelText="{{ phoneStrings.mobileLabel }}"
          testSelector="emergency-mobile"
        >
        </uc-phone-selector>
      </uc-form-input>
      <uc-form-input>
        <uc-subsection-minor-text>{{ phoneStrings.alternatePhoneHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          [task]="task"
          path="/applicant/emergency_contact_detail/alternate_phone"
          formControlName="alternatePhoneNum"
          labelText="{{ phoneStrings.alternatePhoneLabel }}"
          testSelector="emergency-alternate-phone"
        >
        </uc-phone-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/emergency_contact_relationship"
      ></uc-task-hint>
      <uc-radio-input-group
        groupName="relationship"
        type="relationship"
        formControlName="relationship"
        label="{{ strings.relationshipLabel }}"
        required="yes"
      >
      </uc-radio-input-group>
    </uc-subsection>

    <uc-subsection>
      <uc-button
        testSelector="copy-address"
        *ngIf="copyAddressButtonText"
        class="copy-address"
        (click)="triggerCopyAddress()"
      >
        {{ copyAddressButtonText }}
      </uc-button>
      <uc-address-selector
        testSelector="emergency-address"
        [task]="task"
        basePath="/applicant/emergency_contact_detail/current_address"
        formControlName="address"
      >
      </uc-address-selector>
    </uc-subsection>

    <uc-subsection *ngIf="includeUpToDateDeclaration">
      <uc-task-hint
        [task]="task"
        path="/application/declaration_emergency_contact_information_correct"
      ></uc-task-hint>
      <uc-checkbox-input-group
        groupName="declaration"
        [label]="strings.declaration.description"
        [options]="declarationOptions"
        formControlName="declarationEmergencyContactInformationCorrect"
        [isRequired]="true"
      >
      </uc-checkbox-input-group>
    </uc-subsection>
  </uc-form-input>
</uc-section>
