import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import strings from '@constants/strings.constants';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { ETHNICITIES } from '@shared/constants/reference.constants';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import { group, control, array, UCElementGroup, UCElementArray } from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-online-additional-details',
  templateUrl: './online-additional-details.component.html',
  styleUrls: ['./online-additional-details.component.scss'],
})
export class OnlineAdditionalDetailsComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() processName: string;

  onlineAdditionalDetailsPage: UntypedFormGroup;
  onlineAdditionalDetailsForm: UCElementGroup;
  currentApplicant: Applicant;
  currentApplication: Application;

  learningNeedsStrings = strings.components.organisms.learningNeeds;
  strings = strings.components.tasks.onlineAdditionalDetails;

  processRequiringId = [PROCESS_NAMES.STAR, PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL];

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private onlineProcessService: OnlineProcessService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get isStarProcess(): boolean {
    return this.processName === PROCESS_NAMES.STAR;
  }

  get isMCEDProcess(): boolean {
    return [PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL, PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL].includes(
      this.processName,
    );
  }

  get citizenship() {
    return this.onlineAdditionalDetailsForm.controls.citizenship.controls;
  }

  get demographicElements(): UCElementArray {
    return this.onlineAdditionalDetailsForm.controls.citizenship;
  }

  get identityDocumentRequired(): boolean {
    return this.processRequiringId.includes(this.processName);
  }

  get fitnessToTeachGroup() {
    return this.onlineAdditionalDetailsForm.controls.fitnessToTeachGroup.controls;
  }

  get hasDisability(): boolean {
    return this.fitnessToTeachGroup.hasDisability?.control?.value?.code === 'true';
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  private createForm(): UCElementGroup {
    return group({
      // TODO: when fixtures data is fixed, will uncomment this
      // fitnessToTeachGroup: group({
      //   disability: control({
      //     defaultState: [],
      //     model: 'applicant',
      //     path: '/disability',
      //     inMap: refDatasToCodeArray,
      //     outMap: controlToRefDataArray,
      //     validators: [],
      //   }),
      //   hasDisability: control({
      //     model: 'applicant',
      //     path: '/declarationDisability',
      //     inMap: booleanToYesNo(),
      //     outMap: yesNoToBoolean(),
      //     validators: [Validators.required],
      //   }),
      //   disabilityDetail: control({
      //     model: 'applicant',
      //     path: '/disabilityDetail',
      //   }),
      // }),
      citizenship: group({
        ethnicities: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/ethnicity',
        }),
        iwi: array({
          defaultState: [],
          validators: [],
          model: 'applicant',
          path: '/demographic/iwi',
        }),
      }),
      contactDetails: group({
        studyAddress: control({
          defaultState: AddressModel.createFrom({}),
          validators: [],
          model: 'applicant',
          path: '/studyContactDetail/currentAddress',
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressWithoutWhitespaceValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
      }),
    });
  }

  ngOnInit() {
    this.onlineAdditionalDetailsForm = this.createForm();
    this.onlineAdditionalDetailsPage = this.onlineAdditionalDetailsForm.asControl() as UntypedFormGroup;

    // TODO: when fixtures data is fixed, will uncomment this
    // this.setValidatorForDisablitityDetail();

    combineLatest(this.applicantService.getApplicant(), this.applicationService.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.formModel.updateFormFromModel(this.onlineAdditionalDetailsForm, applicant, application);

        this.setValidatorForIwi();
      });
  }

  private setValidatorForIwi() {
    this.onlineAdditionalDetailsPage
      .get('citizenship.ethnicities')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        const iwiControl = this.onlineAdditionalDetailsPage.get('citizenship.iwi');
        if (val[0]?.code === ETHNICITIES.NEW_ZEALAND_MAORI) {
          iwiControl.setValidators([Validators.required, UCValidators.iwiValidator]);
          iwiControl.updateValueAndValidity();
        } else {
          iwiControl.setValidators([]);
          iwiControl.updateValueAndValidity();
        }
        iwiControl.updateValueAndValidity();
      });
  }

  private setValidatorForDisablitityDetail() {
    this.fitnessToTeachGroup.hasDisability.control.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        if (val === 'show') {
          this.fitnessToTeachGroup.disabilityDetail.control.setValidators([
            Validators.required,
            Validators.minLength(1),
          ]);
        } else {
          this.fitnessToTeachGroup.disabilityDetail.control.setValidators([]);
        }
        this.fitnessToTeachGroup.disabilityDetail.control.updateValueAndValidity();
      });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.onlineAdditionalDetailsForm);
  }

  public checkFormValidity(): string[] {
    this.onlineAdditionalDetailsPage.updateValueAndValidity();
    if (this.onlineAdditionalDetailsPage.invalid) {
      this.log.info(this.onlineAdditionalDetailsPage);
      return this.formModel.getInvalidPathesInElementGroup(this.onlineAdditionalDetailsForm);
    } else {
      return [];
    }
  }

  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    }

    this.prepareCurrentApplicantAndApplication();

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    combineLatest(updateApplicant, updateApplication).subscribe({
      next: this.onUpdateSuccess,
      error: this.onUpdateFailed,
    });
  }

  private onUpdateSuccess = ([applicant, application]: [Applicant, Application]) => {
    if (applicant && application) {
      this.log.info('updated applicant and application successfully');
      this.next.emit();
    }
  };

  private onUpdateFailed = (err: DSHttpError) => {
    this.errors.emit();
    this.log.error('error thrown while updating applica(nt|tion):', err);
  };

  private prepareCurrentApplicantAndApplication() {
    const firstCode = this.getFirstCode();
    const studyFirst = this.currentApplication.studyInNz;

    this.formModel.updateModelFromForm(
      this.onlineAdditionalDetailsForm,
      this.currentApplicant,
      this.currentApplication,
    );

    this.prepareCurrentApplication(firstCode, studyFirst);
    this.prepareCurrentApplicant();
  }

  private prepareCurrentApplicant() {
    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);
    this.currentApplicant.demographic.iwi = this.currentApplicant.demographic.iwi.filter((n) => !!n);
    this.resetIwiForNotMaori();
    this.updatePassportCountry();
  }

  private getFirstCode() {
    let firstCode;
    if (this.currentApplicant.demographic && this.currentApplicant.demographic.citizenship) {
      firstCode = this.currentApplicant.demographic.citizenship.code;
    }
    return firstCode;
  }

  private resetIwiForNotMaori() {
    if (
      this.currentApplicant.demographic &&
      !this.currentApplicant.demographic.ethnicity.find((val) => val.code === ETHNICITIES.NEW_ZEALAND_MAORI)
    ) {
      this.currentApplicant.demographic.iwi = [];
    }
  }

  private prepareCurrentApplication(firstCode, studyFirst) {
    if (this.shouldResetStudyFullTimeAndLocation(firstCode, studyFirst)) {
      this.currentApplication.studyFullTime = null;
      this.currentApplication.studyLocation = null;
    }
  }

  private shouldResetStudyFullTimeAndLocation(firstCode, studyFirst) {
    return (
      (firstCode && this.currentApplicant.demographic.citizenship.code !== firstCode) ||
      studyFirst !== this.currentApplication.studyInNz
    );
  }

  private updatePassportCountry() {
    if (this.currentApplicant.demographic.citizenship) {
      this.updateOnCitizenship();
    }
    if (this.shouldResetPassportCountry()) {
      this.currentApplicant.demographic.passportCountry = null;
    }
  }

  private updateOnCitizenship() {
    if (this.currentApplicant.demographic.citizenship.code === 'NZCZ') {
      this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'NZL' });
    } else if (this.currentApplicant.demographic.citizenship.code === 'AUS') {
      this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'AUS' });
    }
  }

  private shouldResetPassportCountry() {
    return (
      !this.currentApplicant.demographic.passportCountry || !this.currentApplicant.demographic.passportCountry.code
    );
  }

  public copyPermanentToStudyAddress() {
    const permanentAddress = this.onlineAdditionalDetailsPage.get('contactDetails.address').value;
    const addressCopy = cloneDeep(permanentAddress);
    this.onlineAdditionalDetailsPage.get('contactDetails.studyAddress').patchValue(addressCopy);
  }
}
