import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ASSOCIATED_PERSON_TYPE, MCED_QUAL_TYPES } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { EnrolledQualification, QualificationSummary } from '@shared/models/qualification';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

@Component({
  selector: 'uc-qualification-mc',
  templateUrl: './qualification-mc.component.html',
  styleUrls: ['./qualification-mc.component.scss'],
})
export class QualificationMcComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() process: string;
  @Input() applicationYear: string;

  strings = strings.components.tasks.qualification;
  log: Logger;
  isLoading = true;
  taskForm: UntypedFormGroup;
  formGroup: UntypedFormGroup;
  options: { labelText: string; value: string }[];
  applicationEnrolments: ApplicationEnrolment[] = [];

  constructor(
    loggingService: LoggingService,
    private qualificationService: QualificationService,
    private applicationService: ApplicationService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  ngOnInit(): void {
    this.taskForm = this.fb.group({
      qualificationCode: [null],
    });

    combineLatest([
      this.applicationService.getApplicationEnrolment(this.applicationYear),
      this.applicationService.getApplication(this.applicationYear),
      this.qualificationService.getQualifications(this.applicationYear, this.process).pipe(filter((q) => !!q)),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        ([aes, application, qualifications]: [ApplicationEnrolment[], Application, QualificationSummary[]]) => {
          this.applicationEnrolments = aes;
          this.currentApplication = application;
          const selectedQual = get(aes[0], 'enrolledQualifications[0].code', null);
          this.taskForm.get('qualificationCode').setValue(selectedQual);

          this.options = qualifications.map((q) => {
            return {
              labelText: q.title,
              value: q.code,
            };
          });

          this.isLoading = false;
        },
      );
  }

  updateFormValidity() {
    // no-op
  }

  createMCEnrolment(selectedQual) {
    const mcQual = new EnrolledQualification({ code: selectedQual, priority: 1 });
    return new ApplicationEnrolment({
      priority: 1,
      displayOrder: 1,
      enrolledQualifications: [mcQual],
      subjectOptions: {},
      enroledCourses: [],
    });
  }

  cleanUpMcedAssociatedPerson(): Observable<any> {
    const selectedQual = this.taskForm.get('qualificationCode').value;
    const hasChangedMCEDQual = !!Object.keys(MCED_QUAL_TYPES).find((k) => MCED_QUAL_TYPES[k] === selectedQual);
    const mcedAssociatedPersonTypes = [
      ASSOCIATED_PERSON_TYPE.MCED,
      ASSOCIATED_PERSON_TYPE.MCED_FACILITATOR,
      ASSOCIATED_PERSON_TYPE.MCED_TEACHER,
      ASSOCIATED_PERSON_TYPE.MCED_TEACHER_AIDE,
    ];

    const mcedTeacherAssociatedPerson = !!Object.keys(this.currentApplication?.associatedPerson).find((key) =>
      mcedAssociatedPersonTypes.includes(key),
    );

    if (hasChangedMCEDQual && mcedTeacherAssociatedPerson) {
      mcedAssociatedPersonTypes.forEach((key) => delete this.currentApplication.associatedPerson[key]);
      return this.applicationService.update(this.currentApplication);
    }

    return of(null);
  }

  update() {
    const currentAe = this.applicationEnrolments[0];
    const aeInternalReference = get(currentAe, 'internalReference');
    const selectedQual = this.taskForm.get('qualificationCode').value;
    const currentQualCode = get(currentAe, 'enrolledQualifications[0].code');

    const hasChangedQual = selectedQual !== currentQualCode;
    // Continue if user hasn't selected a qual or hasn't changed qual
    if (typeof selectedQual !== 'string' || !hasChangedQual) {
      return this.next.emit();
    }

    const newAe = this.createMCEnrolment(selectedQual);
    let ob: Observable<any>;
    if (!!aeInternalReference) {
      ob = this.applicationService.updateApplicationEnrolment(this.applicationYear, aeInternalReference, newAe);
    } else {
      ob = this.applicationService.createApplicationEnrolment(this.applicationYear, newAe);
    }
    combineLatest([ob, this.cleanUpMcedAssociatedPerson()]).subscribe(() => {
      this.next.emit();
    });
  }
}
