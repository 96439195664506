<div *ngIf="show && validRoute">
  <div class="system-message-wrapper">
    <uc-message-banner [@listAnimation] *ngFor="let flash of systemMessages" class="system-message" [showCloseButton]="flash.showClose" [message]="flash" type="{{flash.type}}" (closeMessage)="closeMessage($event)">
      <span innerHTML="{{flash.text}}"></span>
    </uc-message-banner>
  </div>
  <div class="flash-message-wrapper">
    <uc-message-banner
      [@listAnimation]
      *ngFor="let flash of flashMessages.bottomLeft"
      [showCloseButton]="flash.showClose"
      [message]="flash"
      type="{{flash.type}}"
      (closeMessage)="closeMessage($event)"
      [class.flash-message-link]="hasValidLink(flash)"
      (click)="hasValidLink(flash) && goToLink(flash)">
      <span innerHTML="{{flash.text}}"></span>
    </uc-message-banner>
  </div>
  <div class="flash-message-wrapper__top">
    <uc-message-banner
      [@topAnimation]
      *ngFor="let flash of flashMessages.top"
      [showCloseButton]="flash.showClose"
      [message]="flash"
      type="{{flash.type}}"
      (closeMessage)="closeMessage($event)"
      [class.flash-message-link]="hasValidLink(flash)"
      (click)="hasValidLink(flash) && goToLink(flash)">
      <span innerHTML="{{flash.text}}"></span>
    </uc-message-banner>
  </div>
</div>
