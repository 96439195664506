import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';

import strings, { IStatusModal } from '@constants/strings.constants';
import { COURSE_STATE_COLOURS } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment, ENROLMENT_STATE_COLOURS } from '@shared/models/change-of-enrolment';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'uc-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss'],
})
export class StatusPillComponent implements OnChanges, OnInit {
  @Input() summaryState: string;
  @Input() academicYear: string;
  @Input() showInfoModal = true;
  @Input() isImpersonated = false;
  @Input() courseStatus = false;
  @Input() isInternational = false;

  @Output() triggerAction = new EventEmitter();

  @ViewChild(ConfirmationModalComponent) infoModal: ConfirmationModalComponent;

  showTag = true;
  log: Logger;
  isLoading = false;
  baseStrings = strings.components.atoms.statusPill;
  enrolmentChange: ChangeOfEnrolment;

  stateHandlerMap = {
    [Application.STATE.OFFER_OF_PLACE_ACCEPT_DECLINE]: () => this.triggerAction.emit(),
  };

  constructor(
    loggingService: LoggingService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get isRelevantEnrolmentChange() {
    return this.enrolmentChange && this.enrolmentChange.academicYear.code === this.academicYear;
  }

  get state() {
    const isEnrolmentChange = !this.courseStatus && this.isRelevantEnrolmentChange;
    return isEnrolmentChange ? this.enrolmentChange.state.code : this.summaryState;
  }

  get modalStrings() {
    let { state } = this;
    if (this.courseStatus) {
      return this.baseStrings.infoModal.courseModal[state] as IStatusModal;
    }

    if (state === Application.STATE.UNREADY || state === Application.STATE.NOT_SUBMITTED) {
      state = Application.STATE.PROGRESS;
    }
    return this.baseStrings.infoModal[state] as IStatusModal;
  }

  get statusLabel() {
    return this.courseStatus ? this.baseStrings.courseStates[this.state] : this.baseStrings[this.state];
  }

  get tagColor(): string {
    const defaultFills = 'yellow';
    const colors = Object.assign(Application.STATE_COLOURS, ENROLMENT_STATE_COLOURS, COURSE_STATE_COLOURS);
    return colors[this.state] || defaultFills;
  }

  get classes(): string {
    const colour = this.tagColor;
    return this.showInfoModal ? `${colour} info-modal` : colour;
  }

  ngOnInit() {
    if (!this.courseStatus) {
      this.changeOfEnrolmentService.enrolmentChange.subscribe((coe) => {
        if (coe && coe.academicYear.code === this.academicYear) {
          this.enrolmentChange = coe;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.courseStatus && changes.summaryState) {
      let { state } = this;
      if (state === Application.STATE.REJECTED) {
        state = Application.STATE.SUBMITTED;
      }
    }
  }

  showStatusInfoModal() {
    if (!this.showInfoModal) {
      return;
    }

    this.infoModal.open();
  }

  infoModalAcceptHandler() {
    if (this.stateHandlerMap[this.state]) {
      this.infoModal.loading = true;
      this.stateHandlerMap[this.state]();
    }
  }
}
