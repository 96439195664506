import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { PostgradIntentions } from '@shared/models/postgrad-intentions';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  group,
  control,
  UCElementGroup,
  booleanToYesNo,
  yesNoToBoolean,
  refDataToValue,
  valueToRefData,
  booleanToSingleCheckboxValue,
  singleCheckboxValueToBoolean,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-doctoral-study',
  templateUrl: './doctoral-study.component.html',
  styleUrls: ['./doctoral-study.component.scss'],
})
export class DoctoralStudyComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() isInternational: boolean;
  @Input() process: string;
  @Input() stage: string;

  doctoralStudyPage: UntypedFormGroup;
  doctoralStudyForm: UCElementGroup;
  strings = strings.components.tasks.doctoralStudy;
  postgradStrings = strings.components.tasks.postgradIntentions;
  aesFundingStrings = strings.components.tasks.aesAdmission.aesFunding;
  udsDeclarationOptions = [{ labelText: this.strings.declarationLabel, value: 'declarationUdsInformationRead' }];

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicationService: ApplicationService,
    logger: LoggingService,
    private coeService: ChangeOfEnrolmentService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get applyingForScholarship() {
    const fundingCode = get(this.doctoralStudyForm.controls.fundingMethod.value, 'code');
    return fundingCode === 'UC_DOCTORAL';
  }

  get scholarshipOffered() {
    const fundingCode = get(this.doctoralStudyForm.controls.fundingMethod.value, 'code');
    return fundingCode === 'SCHOLARSHIP_OFFERED';
  }

  get isPaymentMethodOther() {
    const fundingCode = get(this.doctoralStudyForm.controls.fundingMethod.value, 'code');
    return fundingCode === 'PAYMENT_OTHER';
  }

  private createForm(): UCElementGroup {
    const locationToShowHide = (v) => {
      const location = get(v, 'code');
      switch (location) {
        case 'Distance':
          return 'show';
        case 'Christchurch':
          return 'hide';
        default:
          return null;
      }
    };
    const showHideToLocation = (c: UntypedFormControl) => {
      switch (c.value) {
        case 'show':
          return new ReferenceData({ code: 'Distance' });
        case 'hide':
          return new ReferenceData({ code: 'Christchurch' });
      }
    };

    return group({
      whyThisQualification: control({
        defaultState: '',
        model: this.dataModel,
        path: '/postgradIntentions/whyQualificationChosen',
        validators: [UCValidators.createWordCountValidator(500)],
      }),
      whatNext: control({
        defaultState: '',
        model: this.dataModel,
        path: '/postgradIntentions/whatAreYourGoals',
        validators: [UCValidators.createWordCountValidator(500)],
      }),
      areaOfResearch: control({
        model: this.dataModel,
        path: '/postgradIntentions/areaOfResearch',
      }),
      studyDepartment: control({
        model: this.dataModel,
        path: '/postgradIntentions/studyDepartment',
        validators: [Validators.required],
      }),
      hasContactedSupervisor: control({
        model: this.dataModel,
        path: '/postgradIntentions/hasContactedSupervisor',
        validators: [Validators.required],
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
      supervisorName: control({
        model: this.dataModel,
        path: '/postgradIntentions/supervisorName',
        validators: [Validators.required],
      }),
      studyLocation: control({
        model: this.dataModel,
        path: '/studyLocation',
        validators: [Validators.required],
        inMap: locationToShowHide,
        outMap: showHideToLocation,
      }),
      beginStudyAway: control({
        model: this.dataModel,
        path: '/postgradIntentions/beginStudyAway',
        validators: [Validators.required],
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
      moveToChristchurchAfter: control({
        model: this.dataModel,
        path: '/postgradIntentions/moveToChristchurchAfter',
        validators: [Validators.required],
      }),
      requireStudyAway: control({
        model: this.dataModel,
        path: '/postgradIntentions/requireStudyAway',
        validators: [Validators.required],
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
      fundingMethod: control({
        model: this.dataModel,
        path: '/paymentMethod',
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      scholarshipName: control({
        model: this.dataModel,
        path: '/scholarshipName',
      }),
      scholarshipProvider: control({
        model: this.dataModel,
        path: '/scholarshipProvider',
      }),
      paymentMethodOther: control({
        model: this.dataModel,
        path: '/paymentMethodOther',
      }),
      udsDeclarationCheck: control({
        model: this.dataModel,
        path: '/declarationUdsInformationRead',
        defaultState: false,
        inMap: booleanToSingleCheckboxValue('declarationUdsInformationRead'),
        outMap: singleCheckboxValueToBoolean('declarationUdsInformationRead'),
        validators: [UCValidators.validateAllChecked(this.udsDeclarationOptions)],
      }),
    });
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);
    this.doctoralStudyForm = this.createForm();
    this.doctoralStudyPage = this.doctoralStudyForm.asControl() as UntypedFormGroup;

    this.service.application.pipe(takeUntil(this.componentDestroyed)).subscribe((application) => {
      this.currentApplication = application;
      if (!application.postgradIntentions) {
        application.postgradIntentions = new PostgradIntentions();
      }
      this.formModel.updateFormFromModel(this.doctoralStudyForm, null, this.currentApplication);
    });
  }

  cleanUpCurrentApplication() {
    if (this.doctoralStudyPage.get('hasContactedSupervisor').value === 'hide') {
      this.currentApplication.postgradIntentions.supervisorName = null;
      this.currentApplication.postgradIntentions.supervisorDepartment = null;
    }
    if (!this.scholarshipOffered) {
      this.currentApplication.scholarshipName = null;
      this.currentApplication.scholarshipProvider = null;
    }
    if (!this.isPaymentMethodOther) {
      this.currentApplication.paymentMethodOther = null;
    }
    if (!this.applyingForScholarship) {
      this.currentApplication.declarationUdsInformationRead = false;
    }
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.doctoralStudyForm);
  }

  updateChangeOfEnrolmentModel() {
    return new ChangeOfEnrolment({
      postgradIntentions: this.currentApplication.postgradIntentions,
      studyLocation: this.currentApplication.studyLocation,
      paymentMethod: this.currentApplication.paymentMethod,
      scholarshipProvider: this.currentApplication.scholarshipProvider,
      scholarshipName: this.currentApplication.scholarshipName,
      paymentMethodOther: this.currentApplication.paymentMethodOther,
      declarationUdsInformationRead: this.currentApplication.declarationUdsInformationRead,
    });
  }

  public update() {
    if (this.doctoralStudyPage.get('studyLocation').value === 'hide') {
      this.doctoralStudyPage.get('beginStudyAway').setValue('hide');
      this.doctoralStudyPage.get('moveToChristchurchAfter').setValue('');
    } else if (this.doctoralStudyPage.get('beginStudyAway').value === 'hide') {
      this.doctoralStudyPage.get('moveToChristchurchAfter').setValue('');
    }
    this.cleanUpCurrentApplication();
    this.formModel.updateModelFromForm(this.doctoralStudyForm, null, this.currentApplication);
    const application = this.isCOE ? this.updateChangeOfEnrolmentModel() : this.currentApplication;

    this.service.update(application).subscribe({
      next: (updatedApplication: Application) => {
        if (updatedApplication) {
          this.log.info('updated application');
          this.next.emit();
        }
      },
      error: (error: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating application:', error);
      },
    });
  }
}
