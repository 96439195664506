import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { COUNTRY_OPTIONS, SCHOOLS, SECONDARY_QUAL_CODES } from '@shared/constants/academic-history';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { AttainedQualification } from '@shared/models/attained-qualification';
import { UCError } from '@shared/models/errors';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  UCElementGroup,
  control,
  array,
  group,
  UCElementArray,
  numberToString,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-academic-history-mc',
  templateUrl: './academic-history-mc.component.html',
  styleUrls: ['./academic-history-mc.component.scss'],
})
export class AcademicHistoryMcComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() process: string;

  strings = strings.components.tasks.academicHistory;
  log: Logger;

  secondaryEducationForm: UCElementGroup;
  secondaryEducation: UntypedFormGroup;
  secondaryLocationForm = new UntypedFormArray([]);
  loading = true;

  private currentApplicant: Applicant;
  currentApplication: Application;

  constructor(
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private formModel: FormModelMapperService,
    ls: LoggingService,
  ) {
    super();
    this.log = ls.createLogger(this);
  }

  get secondaryEducations() {
    return this.secondaryEducationForm.get('educations') as UCElementArray;
  }

  private setupSecondaryStudyLocation(secondaryQual: AttainedQualification): void {
    let locationValue = '';
    if (secondaryQual.country?.code) {
      locationValue =
        secondaryQual.country.code === COUNTRY_OPTIONS.NZL ? secondaryQual.country.code : COUNTRY_OPTIONS.OTHER;
    }
    this.secondaryLocationForm.controls.push(new UntypedFormControl(locationValue, Validators.required));
  }

  // Form relies on there being at least one of these, even if they are dummy objects
  // They get cleaned up before persisting to API if no values set on them
  private setupAttainedQualifications(applicant: Applicant) {
    if (!applicant.secondaryQualification.length) {
      applicant.secondaryQualification.push(new AttainedQualification());
    }
  }

  ngOnInit(): void {
    this.secondaryEducationForm = this.createUcElementGroup();
    this.secondaryEducation = this.secondaryEducationForm.asControl() as UntypedFormGroup;

    combineLatest([
      this.applicantService.applicant.pipe(filter((a) => !!a)),
      this.applicationService.application.pipe(filter((a) => !!a)),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.setupAttainedQualifications(applicant);
        this.setupSecondaryStudyLocation(applicant.secondaryQualification[0]);
        this.formModel.updateFormFromModel(this.secondaryEducationForm, this.currentApplicant, this.currentApplication);
        this.loading = false;
      });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.secondaryEducationForm);
  }

  private createUcElementGroup(): UCElementGroup {
    return group({
      nsnNumber: control({ model: 'applicant', path: '/identity/nsnNumber' }),
      lastSchoolAttended: control({
        model: 'applicant',
        path: '/demographic/moeLastSchoolAttended',
        validators: [Validators.required],
      }),
      lastYearAttended: control({
        model: 'applicant',
        path: '/demographic/moeYearLastAttendedSchool',
        validators: [Validators.required],
        inMap: numberToString,
      }),
      wasLastYearAtHighSchool: control({
        model: 'application',
        path: '/wasLastYearAtHighSchool',
        validators: [Validators.required],
      }),
      educations: array({
        model: 'applicant',
        path: '/secondaryQualification',
        group: group({
          type: control({ validators: [Validators.required], model: 'applicant', path: '/type' }),
          typeOther: control({ validators: [Validators.required], model: 'applicant', path: '/typeOther' }),
          source: control({
            validators: [Validators.required],
            model: 'applicant',
            path: '/source',
          }),
          dateAttained: control({ validators: [Validators.required], model: 'applicant', path: '/dateAttained' }),
          attained: control({ validators: [Validators.required], model: 'applicant', path: '/attained' }),
          sourceOther: control({ validators: [Validators.required], model: 'applicant', path: '/sourceOther' }),
          country: control({
            validators: [Validators.required],
            model: 'applicant',
            path: '/country',
          }),
        }),
      }),
    });
  }

  update() {
    const location = this.secondaryLocationForm.at(0).value;
    const secondaryEducations = this.secondaryEducation.get('educations') as UntypedFormArray;
    const secondary = secondaryEducations.controls[0] as UntypedFormGroup;
    if (location === COUNTRY_OPTIONS.OTHER) {
      secondary.get('attained').reset();
      secondary.get('source').reset();
      secondary.get('type').setValue({ code: SECONDARY_QUAL_CODES.OVERSEAS });
    }
    if (location === COUNTRY_OPTIONS.NZL) {
      if (get(secondary.get('type').value, 'code') === SECONDARY_QUAL_CODES.OVERSEAS) {
        secondary.get('type').reset();
      }
      if (get(secondary.get('type').value, 'code') !== SECONDARY_QUAL_CODES.OTHER) {
        secondary.get('typeOther').reset();
      }
      secondary.get('country').setValue({ code: COUNTRY_OPTIONS.NZL });
    }

    if (this.secondaryEducation.get('wasLastYearAtHighSchool').value) {
      const firstSecondaryEdDateAttained = secondary.get('dateAttained').value;
      this.secondaryEducation.get('lastYearAttended').setValue(firstSecondaryEdDateAttained);
    }

    const isNZQual = location === COUNTRY_OPTIONS.NZL;
    const lastSchoolAttended = isNZQual ? secondary.get('source').value : new ReferenceData({ code: SCHOOLS.OVERSEAS });
    this.secondaryEducation.get('lastSchoolAttended').setValue(lastSchoolAttended);

    this.formModel.updateModelFromForm(this.secondaryEducationForm, this.currentApplicant, this.currentApplication);

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    combineLatest([updateApplicant, updateApplication]).subscribe({
      next: ([applicant, application]: [Applicant, Application]) => {
        if (applicant && application) {
          this.log.info('updated applicant and application successfully');
          this.next.emit();
        }
      },
      error: (error: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating applica(nt|tion):', error);
      },
    });
  }
}
