import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-content-search-bar',
  templateUrl: './content-search-bar.component.html',
  styleUrls: ['./content-search-bar.component.scss'],
})
export class ContentSearchBarComponent implements OnInit, OnChanges {
  @Input() options: {
    formControlName: string;
    options: { code: { code: string }; name: string; guidanceText?: string }[];
    disabled?: boolean;
  }[];
  @Input() showBlips: boolean;
  @Input() defaultCurriculumGroup: string;
  @Input() defaultSubjectGroup: string;
  @Output() searchEvent = new EventEmitter();
  @Output() resetResults = new EventEmitter();
  // Async EventEmitter to get around change detection issues
  @Output() courseGroupGuidanceText = new EventEmitter(true);

  strings = strings.components.molecules.contentSearchBar;
  formGroup = this.formBuilder.group({
    query: '',
    qualGroup: '',
    subjectGroup: '',
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && !changes.options.previousValue && this.options) {
      this.disableDropdowns();
    }

    if (this.formGroup) {
      if (changes.defaultCurriculumGroup) {
        this.formGroup.get('qualGroup').setValue(changes.defaultCurriculumGroup.currentValue || '');
      }

      if (changes.defaultSubjectGroup) {
        this.formGroup.get('subjectGroup').setValue(changes.defaultSubjectGroup.currentValue || '');
      }
    }
  }

  ngOnInit() {
    this.formGroup.get('qualGroup').valueChanges.subscribe((value) => {
      const guidanceText = this.findCourseGroupGuidanceTextFromValue(value);
      this.courseGroupGuidanceText.emit(guidanceText);
    });

    if (this.defaultCurriculumGroup) {
      this.formGroup.get('qualGroup').setValue(this.defaultCurriculumGroup);
    }
  }

  findCourseGroupGuidanceTextFromValue(value) {
    if (!(value && this.options)) {
      return '';
    }

    const courseGroupOptions = this.options.find((o) => o.formControlName === 'qualGroup').options || [];
    const courseGroup = courseGroupOptions.find((option) => option.code.code === value) || { guidanceText: false };
    return courseGroup.guidanceText || '';
  }

  clearSearch() {
    this.formGroup.get('query').setValue('');
    this.formGroup.get('qualGroup').setValue(this.defaultCurriculumGroup || '');
    this.formGroup.get('subjectGroup').setValue(this.defaultSubjectGroup || '');
  }

  reset() {
    this.clearSearch();
    this.resetResults.emit();
  }

  runSearch() {
    this.searchEvent.emit(this.formGroup.value);
  }

  disableDropdowns() {
    this.options.forEach((el) => {
      if (el.disabled) {
        this.formGroup.get(el.formControlName).disable();
      }
    });
  }
}
