<div
  class="form-field"
  [class.hasValue]="hasValue"
  [class.has-icon]="!noIcon"
  [class.no-icon]="noIcon"
>
  <uc-form-field-label
    [required]="required"
    [labelText]="label"
  >
    <span
      *ngIf="!isDisabled"
      class="input-content"
    >
      <input
        (blur)="blurred.emit($event)"
        (blur)="stripInvalidCharacters()"
        (keyup)="limitText()"
        (paste)="stripInvalidCharacters()"
        (keypress)="stripInvalidCharacters()"
        [attr.type]="isNumeric ? 'number' : 'text'"
        [attr.step]="isNumeric ? step : null"
        [attr.min]="isNumeric ? min : null"
        [attr.max]="isNumeric ? max : null"
        [formControl]="inputControl"
        [placeholder]="placeholder || ''"
        [class.field-error]="errors"
        [attr.data-cy]="testSelectorId"
      />
      <uc-icon
        class="valid-icon"
        iconTitle="Valid"
        iconType="tick"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"
      ></uc-icon>
      <uc-icon
        class="invalid-icon"
        iconTitle="Invalid"
        iconType="close"
        iconWidth="11"
        iconHeight="11"
        iconFill="$colorWhite"
      ></uc-icon>
    </span>
    <uc-disabled-atom-state
      *ngIf="isDisabled"
      [value]="inputControl.value"
      [enableModal]="showDisabledExplanation"
    ></uc-disabled-atom-state>
    <span
      *ngIf="maxLengthLimit"
      class="validation-error"
      >{{ maxLengthError }}</span
    >
  </uc-form-field-label>
</div>
