import { deepClone } from '@shared/helpers/general';
import { snakeifyKeys, camelizeKeys } from '@shared/helpers/serialization';

import { ContinuingEnrolledQualification } from './enrolment';
import { OfferDecision } from './offer-decision';
import { EnrolledQualification } from './qualification';
import { ReferenceData } from './reference-data';

export class ApplicationEnrolment {
  priority: number;
  displayOrder: number;
  internalReference: string;
  externalReference: string;
  state: ReferenceData;
  studentProvidedExemptionReason: string;
  didContinueAward: boolean;
  enrolledQualifications: EnrolledQualification[];
  canWithdraw: boolean;
  active: boolean;
  offerDecision: OfferDecision;

  constructor(obj: unknown) {
    Object.assign(this, obj);
  }

  public static deserialize(payload): ApplicationEnrolment {
    const newPayload = camelizeKeys(payload.application_enrolment || payload.enrolment || payload);
    newPayload.state = ReferenceData.deserialize(newPayload.state);
    newPayload.enrolledQualifications = newPayload.enrolledQualifications
      ? newPayload.enrolledQualifications.map(EnrolledQualification.deserialize)
      : [];
    newPayload.enrolledQualifications = newPayload.enrolledQualifications.sort((a, b) => a.priority - b.priority);
    newPayload.offerDecision = OfferDecision.deserialize(newPayload.offerDecision);

    return new ApplicationEnrolment(newPayload);
  }

  public static deserializeAll(payload): ApplicationEnrolment[] {
    if (payload === null) {
      return null;
    }
    const newPayload = payload.application_enrolments || payload;

    const deserializedAEs: ApplicationEnrolment[] = !!newPayload.length
      ? newPayload.map(ApplicationEnrolment.deserialize)
      : [];
    const sortedAEs = deserializedAEs.sort((a, b) => a.displayOrder - b.displayOrder);

    return sortedAEs;
  }

  public static serialize(instance: ApplicationEnrolment) {
    const cloned = deepClone(instance);
    cloned.state = ReferenceData.deserialize(cloned.state);
    cloned.enrolledQualifications =
      (cloned.enrolledQualifications && cloned.enrolledQualifications.map(EnrolledQualification.serialize)) || [];
    cloned.offerDecision = OfferDecision.serialize(cloned.offerDecision);

    return { application_enrolments: snakeifyKeys(cloned) };
  }

  public static createFrom(continuingEnrolments: ContinuingEnrolledQualification[]): ApplicationEnrolment {
    const enrolledQualifications = continuingEnrolments.map((ce, i) => {
      const { code, subjectOptions } = ce;
      const priority = i;
      return { code, subjectOptions, priority };
    });
    return new ApplicationEnrolment({
      displayOrder: 1,
      enrolledQualifications,
    });
  }
}
