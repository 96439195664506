import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash-es';
import { combineLatest, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IndependentCourseEnrolmentService } from '@app/services/independent-course-enrolment/independent-course-enrolment.service';
import { CourseType, OnlineCourseService } from '@app/services/online-course/online-course.service';
import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { BaseProcessPage } from '@shared/components/templates/process-page/process-page.component';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { APPLICATION_CREATION_SOURCE, INDEPENDENT_ENROLMENT_STATES } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { UCProcess } from '@shared/models/process';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { DataService } from '@shared/services/data-service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { LoggingService } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { IPreProcessRouteParams } from '@shared/services/resolvers/pre-process-resolver/pre-process-resolver';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-online-pre-process-page',
  templateUrl: './online-pre-process-page.component.html',
  styleUrls: ['./online-pre-process-page.component.scss'],
})
export class OnlinePreProcessPageComponent extends BaseProcessPage implements OnInit, AfterViewInit {
  public process: UCProcess;
  public task: Task;
  public stage: Stage;
  public availableApplicationYears: string[];
  public strings = strings.components.preProcess;
  public errorMessage = '';
  public stageComplete = false;
  public isFirstTask = false;
  public taskIsUpdating = false;
  public actionLabel: string;
  public applicationYear: string;
  public newProcessName: string;
  public params;
  public queryParams;

  firstTimeToThisCourseType = false;
  needCourseToEnrol = false;
  loading = false;
  disableNavigation = false;
  isImpersonating = false;
  duplicateProcess = false;
  resumingApplication = false;
  firstIncompleteStageNumber = -1;
  welcomeSectionHeader: string = this.strings.welcomeSectionHeader;
  welcomeSectionParagraph: string = this.strings.welcomeSectionParagraph;
  informationTitle: string = this.strings.informationTitle;
  informationParagraph: string = this.strings.informationSCParagraph;
  timeTakeTitle: string = this.strings.timeTakeTitle;
  timeTakeParagraph: string = this.strings.timeTakeSCParagraph;
  needCourseToEnrolParagraph: string[] = this.strings.needCourseToEnrolParagraph;

  existingApplicationYears = [];
  enrolmentList = [];
  enrolledCourseCodes = [];
  enrolledCourseTypes = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loggingService: LoggingService,
    private applicationService: ApplicationService,
    private onlineCourseService: OnlineCourseService,
    private enrolmentService: EnrolmentService,
    private refDataService: ReferenceDataService,
    private ws: WindowService,
    private dataService: DataService,
    private iceService: IndependentCourseEnrolmentService,
  ) {
    super();
    this.log = this.loggingService.createLogger(this);
  }

  ngAfterViewInit() {
    if (!this.errorMessage) {
      this.hideErrorBanner();
    }
  }

  ngOnInit() {
    combineLatest([this.route.queryParams, this.route.params])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(async ([queryParams, params]: [unknown, IPreProcessRouteParams]) => {
        this.params = params;
        this.queryParams = queryParams;
        await this.onlineCourseService.buildOnlineCourseEnrolmentContext(queryParams);
        this.actionBasedOnCourseInfo();
      });
  }

  private actionBasedOnCourseInfo() {
    if (
      this.onlineCourseService.onlineCourseSelectedCourse !== null &&
      this.onlineCourseService.isCourseValid(this.onlineCourseService.onlineCourseSelectedCourse)
    ) {
      this.setRelatedProcess();
    } else {
      this.flowForNotCourseInfo();
    }
  }

  flowForNotCourseInfo() {
    // check if user full enrolled before, if it is, then sso to totara, otherwise logic for popup warning message
    this.enrolmentService
      .getIndependentEnrolmentsByStatus(
        this.onlineCourseService.getCourseYear(),
        INDEPENDENT_ENROLMENT_STATES.ENROLMENT_STATE_ENROLLED,
      )
      .subscribe((enrolments) => {
        this.log.info(enrolments);
        if (!!enrolments && enrolments.length > 0) {
          this.log.info('Full enrolled before.');
          this.needCourseToEnrol = true;
        } else {
          this.log.info('Not Full enrolled before.');
          this.needCourseToEnrol = true;
        }
      });
  }

  prepareAndOpenProcessPage() {
    this.loading = true;

    if (this.applicationYear && this.newProcessName) {
      const newApplication = new Application({
        academicYear: { code: this.applicationYear },
        processName: { code: this.newProcessName },
        creationSource: APPLICATION_CREATION_SOURCE.CREATION_SOURCE_UC_ONLINE,
      });
      this.findOrCreateApplication(newApplication);
    }
  }

  private findOrCreateApplication(newApplication: Application) {
    this.applicationService.findOrCreateApplication(newApplication).subscribe(
      () => {
        this.findOrCreateIndependentEnrolment();
        this.router.navigate(internalUrls.onlineProcessPage(this.newProcessName, this.applicationYear), {
          queryParams: this.queryParams,
        });
        this.loading = false;
      },
      (err) => {
        if (err.status === 409) {
          this.router.navigate(internalUrls.profile, { queryParams: this.queryParams });
          this.loading = false;
          return;
        }
      },
    );
  }

  private findOrCreateIndependentEnrolment() {
    this.enrolmentService
      .getIndependentEnrolment(
        this.applicationYear,
        this.onlineCourseService.onlineCourseCode,
        this.onlineCourseService.onlineCourseOccur || '',
      )
      .subscribe((enrolment) => {
        if (this.isNewIndependentEnrolmentNeeded(enrolment)) {
          this.submitIndependentEnrolment();
        } else {
          this.enrolmentService.independentCourseEnrolment$?.next(enrolment);
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  isNewIndependentEnrolmentNeeded(enrolment): boolean {
    return (
      enrolment === null ||
      enrolment === undefined ||
      enrolment.state.code === INDEPENDENT_ENROLMENT_STATES.ENROLMENT_STATE_CANCELLED
    );
  }

  private submitIndependentEnrolment() {
    this.enrolmentService
      .submitIndependentEnrolment(
        this.applicationYear,
        this.onlineCourseService.onlineCourseCode,
        this.onlineCourseService.onlineCourseOccur || '',
      )
      .subscribe(
        (response) => {
          this.log.info(response);
        },
        (err) => throwError(err),
      );
  }

  isMCEnrolledBefore() {
    return this.onlineCourseService.onlineCourseIsMCEnrolledBefore;
  }

  /**
   * Perform a check to see if there is an independent enrolment in an active state
   * for this academic year, and set duplicateProcess to true if there is.
   *
   * resumingApplication being set to true bypasses this
   *
   * @param academicYear
   */
  private performDuplicateIndependentEnrolmentCheck(academicYear: string) {
    this.iceService.getEnrolments(academicYear).subscribe((enrolments) => {
      enrolments.forEach((enrolment) => {
        if (!enrolment.isInFinalisedState() && !this.resumingApplication) {
          this.duplicateProcess = true;
        }
      });
    });
  }

  /**
   * Perform a check to see if there is a active process
   * for this academic year, and set duplicateProcess to true if there is.
   *
   * resumingApplication being set to true bypasses this
   *
   * @param academicYear
   * @param process
   */
  private performDuplicateProcessCheck(academicYear: string, process: string) {
    const serviceUrl = `${environment.apiRoot}/process/${process}/${academicYear}`;

    if (localStorage.getItem('ENABLE_PROCESS_CHECK') !== 'TRUE') {
      return;
    }

    this.dataService
      .fetch(serviceUrl, {
        expectStatus: 200,
        ignoredErrorStatuses: [404],
      })
      .subscribe((result) => {
        const hasProcessResult = result && result.process && result.process.code;

        if (hasProcessResult && !this.resumingApplication && result.process.code === process) {
          this.duplicateProcess = true;
        }
      });
  }

  /**
   * Parts of our system currently only support having one independent course enrolment
   * in progress at a time. We need to check:
   *     a) That there are no active processes for this academic year
   *     b) That there are no independent enrolments no in a final cancelled / enrolled state
   */
  performDuplicateApplicationCheck() {
    const uconlineProcesses = [PROCESS_NAMES.UCONLINE_SHORT_COURSE, PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL];
    const academicYear = this.applicationYear;

    // Check for open enrs
    this.performDuplicateIndependentEnrolmentCheck(academicYear);

    // Check for open processes
    uconlineProcesses.forEach((process) => {
      this.performDuplicateProcessCheck(academicYear, process);
    });
  }

  setRelatedProcess() {
    this.contextSetup();

    // Check if student has a duplicate application
    this.performDuplicateApplicationCheck();

    // No showing up welcome page when it is not the first time to this course
    // Specifically for first time enrolling a SC course, if a MC enrolled before already will not have welcome page as well.
    if (!this.firstTimeToThisCourseType || this.isMCEnrolledBefore()) {
      this.prepareAndOpenProcessPage();
    }
  }

  private contextSetup() {
    this.newProcessName =
      this.onlineCourseService.onlineCourseType === CourseType.SC
        ? PROCESS_NAMES.UCONLINE_SHORT_COURSE
        : PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL;
    if (this.onlineCourseService.onlineCourseType === CourseType.MC) {
      this.informationParagraph = this.strings.informationMCParagraph;
      this.timeTakeParagraph = this.strings.timeTakeMCParagraph;
    }

    this.resumingApplication = this.onlineCourseService.isResumingApplication;
    this.applicationYear = this.onlineCourseService.getCourseYear();
    this.firstTimeToThisCourseType = this.onlineCourseService.onlineCourseIsFirstTimeToThisCourseType;
  }

  showTaskError(error?: UCError) {
    this.taskIsUpdating = false;
    if (error) {
      this.errorMessage = error.data;
      if (error.code) {
        this.errorMessage = get(strings, error.code) as string;
      }
      this.showErrorBanner();
    }
  }

  previousTask() {
    this.router.navigate(internalUrls.dashboard);
  }

  // eslint-disable-next-line class-methods-use-this
  cancelTask() {
    window.location.assign(environment.hubSpotCoursesUrl);
  }

  triggerTaskUpdate() {
    this.taskIsUpdating = true;
    this.hideErrorBanner();
    if (this.currentTask && this.currentTask.update) {
      this.currentTask.update();
    } else {
      this.log.warn('could not update task because no reference to current task exists');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  goToNextTask() {
    // no-op
  }

  jumpToContent(jumpToElement: string): void {
    return super.jumpToContent(jumpToElement);
  }

  backToHubspot() {
    this.log.info('backToHubspot');
    window.location.assign(environment.hubSpotCoursesUrl);
  }
}
