import { UntypedFormControl } from '@angular/forms';
import { flatten } from 'lodash-es';

import { deepClone } from '@shared/helpers/general';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { EnrolmentCourse } from '@shared/models/course';
import { ContinuingEnrolledQualification } from '@shared/models/enrolment';
import { Qualification, EnrolledQualification, SubjectOptionsList } from '@shared/models/qualification';
import { SubjectOption } from '@shared/models/subject-option';
import { SubjectQuestion } from '@shared/models/subject-question';

import { mockData as qualSummaryMockData } from './qual-summary.data.mock';

// private util to find a code in an optionList, can return null
const findOption = (option: { code: string; changeAction?: string }, optionLists: SubjectQuestion[]): SubjectOption => {
  if (!option || !optionLists) {
    return null;
  }
  const opts = flatten(optionLists.map((o) => o.options));
  return {
    ...opts.find((o) => o.code === option.code),
    ...(option.changeAction ? { changeAction: option.changeAction } : {}),
  };
};

/**
 * returns true if this qualification instance is the same as the qualification answer
 *
 * @param answer QualificationAnswer
 */
export const matchAnswerToQual = (
  answer: QualificationAnswer | ContinuingEnrolledQualification | EnrolledQualification,
  qual: Qualification,
): boolean => {
  return qual.code === answer.code;
};

export interface QualEnrolmentSelectorContext {
  firstQualificationEnrolment: QualificationEnrolment;
  secondQualificationEnrolment: QualificationEnrolment;
  applicationEnrolment: ApplicationEnrolment | ChangeOfEnrolment;
  isConcurrent?: UntypedFormControl;
}

export interface QualificationEnrolmentOptions {
  subjectAnswer?: SubjectOptionsList;
  qualificationAnswer?: Qualification;
  priority?: number;
  changeAction?: string;
  internalReference?: string;
}

/**
 * Represents the event emitted from the EnrolmentSelector to the QualificationTask.
 * Captures current state of concurrent toggle so that we can clean up removed concurrents at save time.
 */
export interface EnrolmentUpdateEvent {
  applicationEnrolment: ApplicationEnrolment | ChangeOfEnrolment;
  isConcurrent: boolean;
}

/**
 * Represents the datastructure exposed from the QualificationOption question form
 */
export class QualificationAnswer {
  code: string;
  priority: number;
  subjectOptions?: SubjectOptionsList;
  enroledCourses?: EnrolmentCourse[];
  qualificationOccurrence?: string;
  newInAward?: boolean;

  constructor(data) {
    Object.assign(this, data);
  }

  public static deserialize(obj): QualificationAnswer {
    if (obj === null) {
      return null;
    }
    const data = camelizeKeys(obj);
    data.enroledCourses = EnrolmentCourse.deserialize(data.enroledCourses);
    return new QualificationAnswer(data);
  }

  public static serialize(qa: QualificationAnswer): Record<string, unknown> {
    const cloned = deepClone(qa);
    cloned.enroledCourses = EnrolmentCourse.serialize(cloned.enroledCourses).course;
    const output = snakeifyKeys(cloned);
    return output;
  }

  /**
   * is it equal? as in value, not reference
   */
  public equals(q: QualificationAnswer): boolean {
    const joinOrEmpty = (xs) => {
      if (xs && xs.length) {
        return xs
          .filter((x) => !!x)
          .map((x) => x.code)
          .join('');
      }
      return '';
    };
    // eslint-disable-next-line max-len
    const toUniqueCode = (o: QualificationAnswer) => `${o.code},${joinOrEmpty(o.subjectOptions)}`;
    return toUniqueCode(this) === toUniqueCode(q);
  }
}

/**
 * A qualification enrolment is an enrolment model with all the qualification and SubjectOption context loaded.
 */
export class QualificationEnrolment {
  qualificationAnswer: Qualification;
  subjectAnswer?: SubjectOptionsList;
  priority: number;
  changeAction?: string;
  internalReference?: string;

  constructor(opts: QualificationEnrolmentOptions) {
    Object.assign(this, opts);
  }

  static createFrom(
    enrolledQual: ContinuingEnrolledQualification | EnrolledQualification,
    qual: Qualification,
  ): QualificationEnrolment {
    if (!matchAnswerToQual(enrolledQual, qual)) {
      // e.b NOTE: if you're seeing this in test, you probably need to check
      // that the mock enrolment is consistent with the mock qualification that you are using
      const msg = `tried to create from QualificationAnswer ${enrolledQual.code}, And ${qual.code}, but the qual didn't have all answers`;
      throw new Error(msg);
    }
    const getSubjectFor = (): SubjectOptionsList => {
      const options = {};
      if (!enrolledQual.subjectOptions || !qual.subjectOptions) {
        return {};
      }
      Object.keys(enrolledQual.subjectOptions).forEach((key) => {
        const item = qual.subjectOptions.find((option) => option.level?.toString() === key);
        options[key] = enrolledQual.subjectOptions[key]
          .map((val) => findOption(val, item?.optionLists))
          .filter((v) => !!v);
      });
      return options;
    };

    return new QualificationEnrolment({
      subjectAnswer: getSubjectFor(),
      qualificationAnswer: qual,
      priority: enrolledQual.priority,
      changeAction: enrolledQual.changeAction,
      internalReference: enrolledQual.internalReference,
    });
  }
  asQualificationAnswer(): QualificationAnswer {
    // make an array of subjectOptions an array of codes or an empty array if undefined
    return new QualificationAnswer({
      subjectOptions: this.subjectAnswer,
      code: this.qualificationAnswer.code,
      priority: this.priority,
    });
  }
}

export interface QualificationAnswerOpts {
  code: string;
  priority?: number;
  subjectOptions?: SubjectOptionsList;
  enroledCourses?: { courseOccurrenceCode: string }[];
  qualificationOccurrence?: string;
  newInAward?: boolean;
}

export const mockQuals: Qualification[] = qualSummaryMockData().qualification.map((q) => Qualification.deserialize(q));
