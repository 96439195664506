<div [formGroup]="agentDetails">
  <uc-yes-no
    [task]="task"
    path="/application/declaration_has_agent"
    questionString="{{ strings.applyingViaAgent }}"
    required="yes"
    formControlName="hasAgent"
    testSelector="has-agent"
  >
    <uc-yes-content>
      <uc-subsection>
        <uc-task-hint *ngIf="task" [task]="task" path="/application/agent_agency"></uc-task-hint>
        <uc-form-field-label labelText="{{ strings.agencyLabel }}" required="true">
          <uc-subsection-minor-text>{{ strings.agencyDescription }}</uc-subsection-minor-text>
          <uc-string-input testSelector="agency" formControlName="agency"></uc-string-input>
        </uc-form-field-label>
      </uc-subsection>

      <uc-subsection>
        <uc-form-field-label labelText="{{ strings.agentLabel }}">
          <uc-subsection-minor-text>{{ strings.agentDescription }}</uc-subsection-minor-text>
          <uc-string-input testSelector="agent" formControlName="agent"></uc-string-input>
        </uc-form-field-label>
      </uc-subsection>

      <uc-subsection>
        <uc-form-field-label labelText="{{ strings.emailLabel }}">
          <uc-string-input testSelector="agent-email" formControlName="email"></uc-string-input>
        </uc-form-field-label>
      </uc-subsection>
    </uc-yes-content>
  </uc-yes-no>
</div>
