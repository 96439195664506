import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';

import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import { environment } from '@environment';
import { UCProcess } from '@shared/models/process';

@Component({
  selector: 'uc-online-stages-sidebar',
  templateUrl: './online-stages-sidebar.component.html',
  styleUrls: ['./online-stages-sidebar.component.scss'],
})
export class OnlineStagesSidebarComponent implements OnInit {
  @Input() isImpersonating = false;
  @Input() firstIncompleteStageNumber = 0;
  @Input() currentProcess;

  showSidebar = false;
  allowStageNavigation = get(environment, 'features.unlockStages');
  selectedStageNumber: number;
  process: UCProcess;
  queryParams;
  fragment;

  constructor(private onlineProcessService: OnlineProcessService, private route: ActivatedRoute) {}

  toggleNav() {
    this.showSidebar = !this.showSidebar;
  }

  // eslint-disable-next-line complexity
  preventNavigation(event: MouseEvent) {
    if (this.isImpersonating && this.selectedStageNumber <= this.firstIncompleteStageNumber) {
      return;
    }
    if (!this.allowStageNavigation) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onTaskCardClick() {
    if (window.outerWidth < 960) {
      this.toggleNav();
    }
  }

  setStageNumber(num) {
    this.selectedStageNumber = num;
  }

  ngOnInit() {
    combineLatest([this.route.queryParams, this.route.fragment]).subscribe(([queryParams, fragment]) => {
      this.queryParams = queryParams;
      this.fragment = fragment;
    });
    if (!this.currentProcess) {
      this.onlineProcessService.currentProcess.pipe(filter((el) => !!el)).subscribe((process) => {
        this.process = process;
      });
    }
  }

  // TODO: need to check if is the last task of the last stage
  isLastStageComplete() {
    return !this.process.firstIncompleteStage;
  }
}
