import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DisplayType } from '@shared/services/course/course.service';
import strings from '@shared/constants/strings.constants';

@Component({
  selector: 'uc-display-toggle',
  templateUrl: './display-toggle.component.html',
  styleUrls: ['./display-toggle.component.scss'],
})
export class DisplayToggleComponent implements OnInit {
  displayTypes = DisplayType;
  icon = DisplayType.GRID;
  customStrings = strings.components.atoms.toggleView;
  colourMap = {
    selected: '$colorMineShaft',
    other: '$colorSilverSand',
  };

  @Input() displayType: BehaviorSubject<string>;

  ngOnInit() {
    this.displayType.subscribe((displayType: DisplayType) => {
      this.icon = displayType;
    });
  }

  toggleView(type) {
    this.displayType.next(type);
  }

  getColour(type) {
    return this.icon === type ? this.colourMap.selected : this.colourMap.other;
  }
}
