import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from './reference-data';

export class OfferDecision {
  acceptOffer?: boolean;
  declineReason?: ReferenceData;
  declineReasonOther?: string;
  declarationFundingAcceptance?: boolean;
  deferredQualOccurrence?: string;
  deferredQualOccurrenceYear?: ReferenceData;

  static deserialize(payload: any): OfferDecision {
    if (payload === null) {
      return null;
    }

    payload = camelizeKeys(payload);

    payload.declineReason = ReferenceData.deserialize(payload.declineReason);
    payload.deferredQualOccurrenceYear = ReferenceData.deserialize(payload.deferredQualOccurrenceYear);

    return payload;
  }

  static serialize(instance: OfferDecision): any {
    const clone = Object.assign({}, instance);
    clone.declineReason = ReferenceData.serialize(clone.declineReason);
    clone.deferredQualOccurrenceYear = ReferenceData.serialize(clone.deferredQualOccurrenceYear);

    return { offer_decision: snakeifyKeys(clone) };
  }

  constructor(rawName = {}) {
    Object.assign(this, rawName);
  }
}
