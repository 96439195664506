import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData, SerializedReferenceData } from '@shared/models/reference-data';

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedDemographic {
  language: SerializedReferenceData;
  language_other: string;
  citizenship: SerializedReferenceData;
  ethnicity: SerializedReferenceData[];
  iwi: SerializedReferenceData[];
  passport_country: SerializedReferenceData;
  validated_citizenship: boolean;
  city_of_birth: string;
  country_of_birth: SerializedReferenceData;
  moe_main_activity: SerializedReferenceData;
  moe_last_school_attended: SerializedReferenceData;
  moe_year_last_attended_school: number;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class Demographic {
  public language: ReferenceData;
  public languageOther: string;
  public citizenship: ReferenceData;
  public ethnicity: ReferenceData[];
  public iwi: ReferenceData[];
  public passportCountry: ReferenceData;
  public validatedCitizenship: boolean;
  public cityOfBirth: string;
  public countryOfBirth: ReferenceData;
  public moeMainActivity: ReferenceData;
  public moeLastSchoolAttended: ReferenceData;
  public moeYearLastAttendedSchool: number;

  constructor(rawDemographic) {
    Object.assign(this, rawDemographic);
  }

  static deserialize(payload: Partial<SerializedDemographic>): Demographic {
    if (payload === null) {
      return null;
    }

    const demographic: Demographic = camelizeKeys(payload);

    const deserializeCollection = (collection: SerializedReferenceData[]): ReferenceData[] => {
      return collection.map((item): ReferenceData => {
        return ReferenceData.deserialize(item);
      });
    };

    demographic.language = ReferenceData.deserialize(payload.language);
    demographic.citizenship = ReferenceData.deserialize(payload.citizenship);
    demographic.ethnicity = deserializeCollection(payload.ethnicity);
    demographic.iwi = deserializeCollection(payload.iwi);
    demographic.passportCountry = ReferenceData.deserialize(payload.passport_country);
    demographic.countryOfBirth = ReferenceData.deserialize(payload.country_of_birth);
    demographic.moeMainActivity = ReferenceData.deserialize(payload.moe_main_activity);
    demographic.moeLastSchoolAttended = ReferenceData.deserialize(payload.moe_last_school_attended);

    return new Demographic(demographic);
  }

  static serialize(instance: Demographic): Partial<SerializedDemographic> {
    const serialized: SerializedDemographic = snakeifyKeys(instance);

    const serializeCollection = (collection: ReferenceData[]): SerializedReferenceData[] => {
      return collection.map((item): SerializedReferenceData => {
        return ReferenceData.serialize(item);
      });
    };

    /* eslint-disable @typescript-eslint/naming-convention */
    const serializers = {
      language: () => ReferenceData.serialize(instance.language),
      citizenship: () => ReferenceData.serialize(instance.citizenship),
      ethnicity: () => serializeCollection(instance.ethnicity),
      iwi: () => serializeCollection(instance.iwi),
      passport_country: () => ReferenceData.serialize(instance.passportCountry),
      country_of_birth: () => ReferenceData.serialize(instance.countryOfBirth),
      moe_main_activity: () => ReferenceData.serialize(instance.moeMainActivity),
      moe_last_school_attended: () => ReferenceData.serialize(instance.moeLastSchoolAttended),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    for (const [key, serializer] of Object.entries(serializers)) {
      if (serialized[key] !== undefined) {
        serialized[key] = serializer();
      }
    }

    return snakeifyKeys(serialized);
  }
}
