import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ClaimCheckDirective } from '@shared/components/atoms/claim-check/claim-check.directive';
import { DatePickerComponent } from '@shared/components/atoms/date-picker/date-picker.component';
import { DisplayToggleComponent } from '@shared/components/atoms/display-toggle/display-toggle.component';
import { LoginFooterComponent } from '@shared/components/atoms/login-footer/login-footer.component';
import { StarRatingComponent } from '@shared/components/atoms/star-rating/star-rating.component';
import { StatusPillComponent } from '@shared/components/atoms/status-pill/status-pill.component';
import { SafeHtml } from '@shared/pipes/safe-html.pipe';
import { WindowService } from '@shared/services/window/window.service';
import { UcMaterialModule } from '@shared/uc-material/uc-material.module';

import { AddNewButtonComponent } from './add-new-button/add-new-button.component';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { ButtonComponent } from './button/button.component';
import { ButtonAttrComponent } from './button-attr/button.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { CodeBlockComponent } from './code-block/code-block.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CourseLevelPillComponent } from './course-level-pill/course-level-pill.component';
import { CrashOverlayComponent } from './crash-overlay/crash-overlay.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { DisabledAtomStateComponent } from './disabled-atom-state/disabled-atom-state.component';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { FormControlErrorComponent } from './form-control-error/form-control-error.component';
import { FormFieldLabelComponent } from './form-field-label/form-field-label.component';
import {
  FormSubsectionComponent,
  FormSectionHeaderComponent,
  FormInputComponent,
  FormSectionComponent,
  FormSubsectionMinorTextComponent,
  FormSubsectionTextBlockComponent,
  FormSubsectionQuestionComponent,
} from './form-subsection/form-subsection.component';
import { GoBackLinkComponent } from './go-back-link/go-back-link.component';
import { IconComponent } from './icon/icon.component';
import { LoaderBlipComponent } from './loader-blip/loader-blip.component';
import { LoginImageComponent } from './login-image/login-image.component';
import { MessageBannerComponent } from './message-banner/message-banner.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { QualificationLinkComponent } from './qualification-link/qualification-link.component';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { RequiredFieldComponent } from './required-field/required-field.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StringInputComponent } from './string-input/string-input.component';
import { TagComponent } from './tag/tag.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { PositionService } from './tooltip/position.service';
import { TooltipContainerComponent } from './tooltip/tooltip-container/tooltip-container.component';
import { TooltipContentComponent } from './tooltip/tooltip-content.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { TooltipService } from './tooltip/tooltip.service';

@NgModule({
  declarations: [
    AddNewButtonComponent,
    BaseModalComponent,
    ButtonAttrComponent,
    ButtonComponent,
    CheckboxInputComponent,
    ClaimCheckDirective,
    CodeBlockComponent,
    ConfirmationModalComponent,
    CourseLevelPillComponent,
    CrashOverlayComponent,
    DatePickerComponent,
    DateSelectorComponent,
    DisabledAtomStateComponent,
    DisplayToggleComponent,
    DownloadButtonComponent,
    FormControlErrorComponent,
    FormFieldLabelComponent,
    FormInputComponent,
    FormSectionComponent,
    FormSectionHeaderComponent,
    FormSubsectionComponent,
    FormSubsectionMinorTextComponent,
    FormSubsectionQuestionComponent,
    FormSubsectionTextBlockComponent,
    GoBackLinkComponent,
    IconComponent,
    LoaderBlipComponent,
    LoginFooterComponent,
    LoginImageComponent,
    MessageBannerComponent,
    PasswordInputComponent,
    ProgressBarComponent,
    QualificationLinkComponent,
    RadioInputComponent,
    RequiredFieldComponent,
    SafeHtml,
    SideNavComponent,
    SpinnerComponent,
    StarRatingComponent,
    StatusPillComponent,
    StringInputComponent,
    TagComponent,
    TagListComponent,
    TextAreaComponent,
    TooltipContainerComponent,
    TooltipContentComponent,
    TooltipDirective,
  ],
  imports: [CommonModule, ReactiveFormsModule, RouterModule, UcMaterialModule],
  exports: [
    AddNewButtonComponent,
    BaseModalComponent,
    ButtonAttrComponent,
    ButtonComponent,
    CheckboxInputComponent,
    ClaimCheckDirective,
    CodeBlockComponent,
    ConfirmationModalComponent,
    CourseLevelPillComponent,
    CrashOverlayComponent,
    DatePickerComponent,
    DateSelectorComponent,
    DisabledAtomStateComponent,
    DisplayToggleComponent,
    DownloadButtonComponent,
    FormControlErrorComponent,
    FormFieldLabelComponent,
    FormInputComponent,
    FormSectionComponent,
    FormSectionHeaderComponent,
    FormSubsectionComponent,
    FormSubsectionMinorTextComponent,
    FormSubsectionQuestionComponent,
    FormSubsectionTextBlockComponent,
    GoBackLinkComponent,
    IconComponent,
    LoaderBlipComponent,
    LoginFooterComponent,
    LoginImageComponent,
    MessageBannerComponent,
    PasswordInputComponent,
    ProgressBarComponent,
    QualificationLinkComponent,
    RadioInputComponent,
    RequiredFieldComponent,
    SafeHtml,
    SideNavComponent,
    SpinnerComponent,
    StarRatingComponent,
    StatusPillComponent,
    StringInputComponent,
    TagComponent,
    TagListComponent,
    TextAreaComponent,
    TooltipContainerComponent,
    TooltipContentComponent,
    TooltipDirective,
  ],
  providers: [TooltipService, PositionService, WindowService],
})
export class AtomsModule {}
