import { Component, OnInit, Input } from '@angular/core';

import strings from '@constants/strings.constants';
import { AutoResolveTask } from '@shared/classes/auto-resolve/auto-resolve';
import { Task } from '@shared/models/task';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { ProcessService } from '@shared/services/process/process.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-online-resolve-enrolment-provisioning',
  templateUrl: './online-resolve-enrolment-provisioning.component.html',
  styleUrls: ['./online-resolve-enrolment-provisioning.component.scss'],
})
export class OnlineResolveEnrolmentProvisioningComponent extends AutoResolveTask implements OnInit {
  @Input() task: Task;
  @Input() process: string;
  @Input() applicationYear: string;
  @Input() courseCode: string;
  @Input() occurrence: string;

  log: Logger;
  strings = strings.components.tasks.onlineResolveEnrolmentProvisioning.awaitingEnrolmentProvisioning;

  constructor(
    logService: LoggingService,
    processService: ProcessService,
    enrolmentService: EnrolmentService,
    paymentService: PaymentService,
    windowService: WindowService,
  ) {
    super(processService, enrolmentService, paymentService, windowService, logService);
    this.log = logService.createLogger(this);
  }

  ngOnInit() {
    this.pollIndependentEnrolmentStatus(this.applicationYear, this.courseCode, this.occurrence).subscribe();
  }
}
