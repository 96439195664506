<uc-online-minimal-header></uc-online-minimal-header>

<div class="outage-container">
  <h1>{{ templateStrings.heading }}</h1>

  <div
    [innerHtml]="templateStrings.description"
    class="outage-description"
  ></div>

  <uc-button
    class="online-ui-button"
    [externalLink]="templateStrings.browseCoursesUrl"
  >
    {{ templateStrings.browseCourses }}
  </uc-button>
</div>
