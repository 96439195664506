import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { CourseOccurrence } from '@shared/models/course';

@Component({
  selector: 'uc-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnChanges {
  @Input() course: CourseOccurrence;
  @Input() compact: boolean;
  @Input() selectedCourses: Record<string, unknown> = {};
  @Input() errorTriggered: boolean;
  @Input() changedCourses = [];

  @Output() courseAdd = new EventEmitter();
  @Output() undoChange = new EventEmitter();

  strings = strings.components.molecules.courseSummaryCard;
  courseJustChanged = false;
  cancelSpinner = false;

  get changeActionClass() {
    if (!!this.hasChangeAction) {
      return ` ${this.changeAction}-course`;
    }
    return '';
  }

  get courseCardClasses() {
    return `course-card${this.changeActionClass}`;
  }

  get hasChangeAction() {
    return !!this.changeAction && this.changeAction !== CHANGE_ACTIONS.NONE;
  }

  get changeAction() {
    return get(this.selectedCourses[this.course.courseOccurrenceCode], 'changeAction');
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cancelSpinner = false;
    if (changes.errorTriggered && this.courseJustChanged) {
      this.cancelSpinner = true;
      this.courseJustChanged = false;
    }
  }

  addACourse() {
    this.courseJustChanged = true;
    this.course.activeCourse = true;
    this.courseAdd.emit(this.course);
  }

  undoChangeAction(changeAction) {
    this.courseJustChanged = true;

    const course = { ...this.course };
    course.changeAction = changeAction;
    course.selectedCourse = false;
    course.activeCourse = this.course.changeAction === CHANGE_ACTIONS.DROPPED;
    this.undoChange.emit(course);
  }
}
