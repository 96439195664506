<div class="course-view__wrapper">
  <div class="course-view__course-title-wrapper">
    <div class="course-view__course-detail course-view__course-code minimal">
      <a
        [attr.href]="course.href"
        target="_blank"
        >{{ course.courseOccurrenceCode }}</a
      >
    </div>
    <div class="course-view__course-detail course-view__course-title">{{ course.courseTitle }}</div>
  </div>
  <div class="course-view__course-details-wrapper">
    <div
      class="course-view__date-group"
      *ngIf="showDates"
    >
      <div
        class="course-view__course-detail course-view__start-date"
        *ngIf="course.startDate"
      >
        {{ course.startDate | date: 'dd/MM/yy' }}
      </div>
      <div
        class="course-view__course-detail"
        *ngIf="course.endDate"
      >
        - {{ course.endDate | date: 'dd/MM/yy' }}
      </div>
    </div>
    <div class="course-view__state-group">
      <div *ngIf="courseState">
        <uc-tag
          *ngIf="(course.declineReason && isDeclined) || isWaitlisted; else notDeclined"
          class="info-modal course-view-state"
          (click)="showStatusInfoModal()"
        >
          {{ courseState }}
          <div class="tag-icon">
            <uc-icon
              iconType="info-task"
              iconWidth="10"
              iconHeight="10"
            ></uc-icon>
          </div>
        </uc-tag>
        <ng-template #notDeclined>
          <div class="courseStateWrapper">
            <span>{{ courseState }}</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<uc-confirmation-modal
  #declinedModal
  [title]="strings.declineModal.header(this.courseOccurrenceCode)"
  [acceptText]="strings.declineModal.close"
></uc-confirmation-modal>
<uc-confirmation-modal
  #waitlistedModal
  [title]="strings.waitModal.header(this.courseOccurrenceCode)"
  [message]="strings.waitlistDescription"
  [acceptText]="strings.waitModal.close"
></uc-confirmation-modal>
