import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import strings from '@constants/strings.constants';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicationService } from '@shared/services/application/application.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Application } from '@shared/models/application';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-study-intentions',
  templateUrl: './study-intentions.component.html',
})
export class StudyIntentionsComponent extends AbstractBaseTask implements OnInit {
  siForm: UCElementGroup;
  siPage: UntypedFormGroup;
  currentApplication: Application;
  log: Logger;
  @Input() task: Task;
  strings = strings.components.tasks.applicationToEnrol.studyIntentions;
  extraDescriptions: { code: string; description: string }[] = Object.keys(this.strings.descriptions).map((code) => {
    const description = this.strings.descriptions[code];
    return { code, description };
  });
  shouldShowDateSelector = false;

  private createForm(): UCElementGroup {
    return group({
      studyStart: control({
        model: 'application',
        path: '/studyStart',
        validators: [Validators.required],
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      studyStartOther: control({
        defaultState: '',
        validators: [UCValidators.validateDate],
        model: 'application',
        path: '/studyStartOther',
      }),
    });
  }

  constructor(
    private loggingService: LoggingService,
    private applicationService: ApplicationService,
    private formMapper: FormModelMapperService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  public updateFormValidity(err: UCError) {
    this.formMapper.updateFormValidity(err.data, this.siForm);
  }

  ngOnInit() {
    this.siForm = this.createForm();
    this.siPage = this.siForm.asControl() as UntypedFormGroup;

    this.updateShowDateSelector(this.siPage.controls.studyStart.value);
    this.siPage.controls.studyStart.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((val) => this.updateShowDateSelector(val));

    this.applicationService.application.pipe(takeUntil(this.componentDestroyed)).subscribe((app: Application) => {
      this.currentApplication = app;

      this.formMapper.updateFormFromModel(this.siForm, null, this.currentApplication);
    });
  }

  private updateShowDateSelector(val) {
    if (val) {
      const semestersThatNeedDates = ['Summer school', 'Thesis', 'Block', 'Other'];
      this.shouldShowDateSelector = !!semestersThatNeedDates.find((code) => val === code);

      if (!this.shouldShowDateSelector) {
        this.siPage.controls.studyStartOther.reset();
      }
    }
  }

  update() {
    if (!this.currentApplication) {
      this.errors.emit();
      return this.log.error('tried to update application, but no application has been loaded');
    }

    this.formMapper.updateModelFromForm(this.siForm, null, this.currentApplication);

    this.applicationService.updateApplication(this.currentApplication).subscribe(
      (app) => {
        this.log.info('successfully update your application');
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('there was an error updating your application');
      },
    );
  }
}
