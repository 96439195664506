/* eslint-disable complexity */
import { clone } from 'lodash-es';

import strings from '@constants/strings.constants';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { deepClone } from '@shared/helpers/general';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';
import { TeachingPeriod } from '@shared/models/teaching-period';

import { EnrolmentError } from './enrolment-error';

const courseLevelStrings = strings.components.atoms.courseLevelPill;

const decimalIfNonZero = (points) => {
  const decimalPart = points - Math.floor(points);
  return decimalPart > 0 ? points : Math.floor(points);
};

export const totalCoursePoints = (courses: CourseOccurrence[]) => {
  const points = courses.reduce((total: number, currentCourse: CourseOccurrence) => {
    if (!currentCourse.changeAction || currentCourse.changeAction !== CHANGE_ACTIONS.DROPPED) {
      return total + Number(currentCourse.coursePoints);
    }
    return total;
  }, 0);
  return decimalIfNonZero(points);
};

export class CourseBucket {
  title: string;
  code: string;
  courses: CourseOccurrence[] = [];

  constructor(teachingPeriod: TeachingPeriod) {
    this.title = teachingPeriod.description;
    this.code = teachingPeriod.code;
  }

  get points() {
    return totalCoursePoints(this.courses);
  }

  addCourse(course: CourseOccurrence) {
    this.courses.push(course);
  }
}

export class CourseOccurrence {
  public courseOccurrenceCode: string;
  public courseType: string;
  public occurrenceCode: string;
  public teachingPeriodCode: string;
  public site: string;
  public academicYear: string;
  public status: string;
  public substatus: string;
  public startDate: string;
  public endDate: string;
  public href: string;
  public grossLength: number;
  public isSelfPaced: boolean;
  public efts: string;
  public courseCode: string;
  public courseTitle: string;
  public coursePoints: number;
  private courseLevel: number;
  public courseStudentSelectable: boolean;
  public activeCourse?: boolean;
  public selectedCourse?: boolean;
  public changeAction?: string;
  public internalReference?: string;
  public state?: string;
  public active?: boolean;
  public declineReason?: string;
  public pendingReason?: string;

  public calendarStatements: {
    coRequisitesCalendarStatement: string;
    preRequisitesCalendarStatement: string;
    equivalentsCalendarStatement: string;
    restrictionsCalendarStatement: string;
  };

  constructor(data: unknown) {
    Object.assign(this, data);
  }

  get level(): number {
    return this.courseLevel * 100;
  }

  get displayLevel(): string {
    if (!this.level) {
      return courseLevelStrings.noLevel;
    }

    return this.level > 400 ? courseLevelStrings.postgrad : `${this.level} ${courseLevelStrings.level}`;
  }

  static deserializeAll(payload): CourseOccurrence[] {
    if (payload == null) {
      return null;
    }

    const data = payload.course || payload;

    return data.map(CourseOccurrence.deserialize);
  }

  static deserialize(payload): CourseOccurrence {
    if (payload == null) {
      return null;
    }

    const camelizedData: CourseOccurrence = camelizeKeys(payload);
    const formattedData: CourseOccurrence = camelizedData;
    formattedData.calendarStatements = camelizeKeys(camelizedData.calendarStatements);
    formattedData.coursePoints = decimalIfNonZero(camelizedData.coursePoints);

    return new CourseOccurrence(formattedData);
  }
}

export class CourseGroup {
  public name: string;
  public code: ReferenceData;
  public guidanceText: string;

  constructor(obj: unknown) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): CourseGroup[] {
    if (obj == null) {
      return null;
    }

    const data = obj.curriculum_group || obj;

    return data.map((co) => {
      co.code = ReferenceData.deserialize(co.code);
      return new CourseGroup(camelizeKeys(co));
    });
  }
}

export class SubjectGroup {
  public name: string;
  public code: ReferenceData;
  public guidanceText: string;

  constructor(obj: unknown) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): SubjectGroup[] {
    if (obj == null) {
      return null;
    }

    const data = obj.curriculum_group || obj;

    return data.map((co) => {
      co.code = ReferenceData.deserialize(co.code);
      return new SubjectGroup(camelizeKeys(co));
    });
  }
}

export class EnrolmentCourse {
  courseOccurrenceCode: string;
  qualPriority?: string;
  state?: string;
  startDate?: string;
  endDate?: string;
  duration?: string;

  constructor(obj: unknown) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): EnrolmentCourse[] {
    if (obj == null) {
      return null;
    }

    const data = obj.courses || obj;

    return data.map((co) => new CourseOccurrence(camelizeKeys(co)));
  }

  public static serialize(instance: EnrolmentCourse[]) {
    let payload = clone(instance);

    if (payload && payload.length) {
      payload = payload.map((c) => snakeifyKeys(c));
    }

    return { course: payload };
  }

  public static serializeOne(instance: EnrolmentCourse) {
    const course = snakeifyKeys({ ...instance });
    return { course };
  }
}

export class Subject {
  public subject: Subject;
  public startDate: string;
  public endDate: string;
}

export class EnrolledCourse {
  code: string;
  startDate: string;
  endDate: string;
  duration: string;
  points: number;
  state: ReferenceData;
  internalReference: string;
  changeAction?: string;
  active: boolean;
  declineReason?: string;
  pendingReason?: string;
  errors?: EnrolmentError[];

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(payload): EnrolledCourse {
    const data = camelizeKeys(payload.courses || payload);
    data.state = ReferenceData.deserialize(data.state);
    data.errors = data.errors ? data?.errors?.map(EnrolmentError.deserialize) : [];

    return new EnrolledCourse(data);
  }

  public static serialize(instance: EnrolledCourse): unknown {
    const cloned = deepClone(instance);

    if (!cloned.state) {
      cloned.state = { code: 'pending' };
      // }
      cloned.state = ReferenceData.serialize(cloned.state);
      cloned.errors = cloned.errors ? cloned.errors.map(EnrolmentError.serialize) : [];

      return { courses: snakeifyKeys(cloned) };
    }
  }
}
