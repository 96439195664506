import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { Observable, BehaviorSubject, Subject, zip, of, Subscription } from 'rxjs';
import { map, filter, switchMap, tap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { internalUrls } from '@shared/constants/internalUrls';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { Condition } from '@shared/models/condition';
import { UCError } from '@shared/models/errors';
import { ReferenceData } from '@shared/models/reference-data';
import { UserDetail, UserTypes } from '@shared/models/user';
import { ApplicationService } from '@shared/services/application/application.service';
import { CacheManagementService, CacheObjects } from '@shared/services/cache-management/cache-management.service';
import { DataService, IDSRequestOpts } from '@shared/services/data-service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { mockData as mockReferenceData } from '@shared/services/reference-data/reference-data.mock';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { UserService, MockUserService } from '@shared/services/user/user.service';

import { mockData as conditionMockData } from './condition.data.mock';
import { ChangeOfEnrolmentService } from '../change-of-enrolment/change-of-enrolment.service';

const REFERENCE_DATA_TYPES = {
  CONDITION_ITEM: 'condition_item',
};

export interface ConditionsByYear {
  [key: string]: Condition[];
}

export abstract class AbstractConditionService {
  protected serviceUrl = `${environment.apiRoot}/application/`;
  public readonly allConditions$ = new BehaviorSubject<ConditionsByYear>({});
  protected error$ = new Subject<UCError>();
  protected lastUser: UserDetail;
  protected hasNotified = false;
  protected notificationSub: Subscription;
  protected strings = strings.services.condition;

  /**
   * This observable provides all ConditionsByYear returned by getAllConditions
   */
  get allConditions(): Observable<ConditionsByYear> {
    return this.allConditions$.asObservable();
  }

  /**
   * This observable provides only the ConditionsByYear that should display
   * in the `Information required` page.
   */
  get requiredConditions(): Observable<ConditionsByYear> {
    return this.allConditions.pipe(map(this.filterConditions('shouldDisplay')));
  }
  /**
   * This observable provides only the ConditionsByYear that should
   * trigger a notification to appear
   */
  get notifiableConditions(): Observable<ConditionsByYear> {
    return this.requiredConditions.pipe(map(this.filterConditions('shouldTriggerNotification')));
  }

  get conditionError(): Observable<UCError> {
    return this.error$.asObservable();
  }

  protected clearCache(): void {
    this.allConditions$.next({});
  }

  protected buildUrl(year: string): string {
    return `${this.serviceUrl}${year}/conditions/`;
  }

  public abstract getConditionsForYear(year: string): Observable<Condition[]>;
  public abstract getAllConditions(data?: {
    coe?: ChangeOfEnrolment;
    refData?: ReferenceData[];
  }): Observable<ConditionsByYear>;
  public abstract getCoe(): Observable<{ coe?: ChangeOfEnrolment; refData?: ReferenceData[] }>;
  public abstract refreshConditionsForYear(year: string);

  public setupUserDetail(userService: UserService | MockUserService, messageService: FlashMessageService): void {
    const isAgent = environment.scope === UserTypes.agent;

    userService.userDetail
      .pipe(
        filter((userDetail) => !!userDetail),
        filter((userDetail) => !!userDetail.student && !!userDetail.student.studentId),
        filter((userDetail) => {
          const firstLogin = !this.lastUser;
          const userChanged = !!this.lastUser && userDetail.student.identifier !== this.lastUser.student.identifier;
          return firstLogin || userChanged || isAgent;
        }),
      )
      .subscribe((userDetail) => {
        this.lastUser = userDetail;
        this.getCoe().subscribe((data) => {
          this.getAllConditions(data).subscribe();
        });
        this.hasNotified = false;
        if (!isAgent) {
          this.notifyRequiredConditions(messageService);
        }
      });
  }

  private filterConditions(attributeName: string) {
    return (allConds: ConditionsByYear) => {
      if (!allConds) {
        return {};
      }

      const filteredConditions: ConditionsByYear = {};
      Object.keys(allConds).forEach((year) => {
        filteredConditions[year] = allConds[year].filter((cond) => cond[attributeName]);
      });

      return filteredConditions;
    };
  }

  protected notifyRequiredConditions(messageService: FlashMessageService) {
    this.notifiableConditions.subscribe((conditionsByYear) => {
      if (this.hasNotified) {
        return;
      }

      Object.keys(conditionsByYear).forEach((year) => {
        const conditions = conditionsByYear[year];
        if (conditions && conditions.length) {
          const message = this.strings.notification(year);
          const linkUrl = internalUrls.informationRequired(year);
          messageService.pushInfo(message, { linkUrl });
          this.hasNotified = true;
        }
      });
    });
  }

  protected setDocumentTypeOnConditions(conditions: Condition[], conditionItemRefData: ReferenceData[]): Condition[] {
    return conditions.map((condition) => {
      const itemRefDatum = conditionItemRefData.find((refDatum) => refDatum.code === condition.item.code);
      condition.documentTypeCode = get(itemRefDatum, 'metadata.documentTypeCode') as string;
      return condition;
    });
  }

  protected associateConditionsToYears(years: string[], conditionsList: Condition[][]): ConditionsByYear {
    return years.reduce((conditionsByYear, year, index) => {
      conditionsByYear[year] = conditionsList[index];
      return conditionsByYear;
    }, {});
  }

  protected getRequestOptions(options?: Record<string, unknown>): IDSRequestOpts {
    return {
      error$: this.error$,
      deserialize: Condition.deserializeAll,
      ...options,
    };
  }
}

@Injectable()
export class ConditionService extends AbstractConditionService {
  private log: Logger;

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
    private cacheService: CacheManagementService,
    private applicationService: ApplicationService,
    private referenceDataService: ReferenceDataService,
    private flashMessageService: FlashMessageService,
    private userService: UserService,
    private coeService: ChangeOfEnrolmentService,
  ) {
    super();
    this.log = loggingService.createLogger(this);

    this.cacheService.shouldClearCache
      .pipe(filter((options) => options.target === CacheObjects.ALL))
      .subscribe(() => this.clearCache());

    this.setupUserDetail(this.userService, this.flashMessageService);
  }

  /**
   * Update the conditions for a year
   * Will fire the observables for required and notifiable conditions
   *
   * @param year string
   */
  refreshConditionsForYear(year: string) {
    this.getCoe().subscribe((data) => {
      if (data?.coe?.academicYear?.code === year) {
        const currentConditions = this.allConditions$.value;
        const updatedConditionsByYear: ConditionsByYear = Object.keys(currentConditions).reduce(
          (conditionsByYear, currentYear) => {
            let conds = currentYear === year ? data.coe.conditions : currentConditions[year];
            conds = this.setDocumentTypeOnConditions(conds, data.refData);
            conditionsByYear[year] = conds;
            return conditionsByYear;
          },
          {},
        );
        this.allConditions$.next(updatedConditionsByYear);
      } else {
        this.getConditionsForYear(year).subscribe((conditions) => {
          const currentConditions = this.allConditions$.value;
          // Build a new object otherwise the observable won't fire
          const updatedConditionsByYear: ConditionsByYear = Object.keys(currentConditions).reduce(
            (conditionsByYear, currentYear) => {
              const conds = currentYear === year ? conditions : currentConditions[year];
              conditionsByYear[year] = conds;
              return conditionsByYear;
            },
            {},
          );
          this.allConditions$.next(updatedConditionsByYear);
        });
      }
    });
  }

  /**
   * Gets all conditions for a given application for a year.
   * Also gets the condition item reference data in order to determine
   * if the condition has a document category associated with it.
   *
   * @param year string
   */
  getConditionsForYear(year): Observable<Condition[]> {
    const conditionsRequest: Observable<Condition[]> = this.dataService.fetch(
      this.buildUrl(year),
      this.getRequestOptions(),
    );
    const conditionItemRefDataRequest = this.referenceDataService.getByType(REFERENCE_DATA_TYPES.CONDITION_ITEM);

    return zip(conditionsRequest, conditionItemRefDataRequest).pipe(
      map(([conditions, refData]) => this.setDocumentTypeOnConditions(conditions, refData)),
    );
  }

  getCoe() {
    return this.coeService.getChangeOfEnrolment().pipe(
      switchMap((coe) => {
        if (coe && coe.conditions?.length > 0) {
          return this.referenceDataService.getByType(REFERENCE_DATA_TYPES.CONDITION_ITEM).pipe(
            switchMap((refData) => {
              return of({ coe, refData });
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  /**
   * Gets list of all applications for the user, then gets the conditions
   * for each application.
   */
  getAllConditions(data?: { coe: ChangeOfEnrolment }) {
    return this.applicationService.getApplications().pipe(
      switchMap((applications) => {
        const coeYear = data?.coe?.academicYear?.code;
        const years = [];
        const requests = [];

        if (!applications.length && !coeYear) {
          return of({});
        }

        let selectedApplications = applications;

        if (coeYear) {
          // if coeYear, don't build up request to look up application conditions for that year.
          selectedApplications = applications.filter((a) => a.academicYear !== coeYear);

          const conditionItemRefDataRequest = this.referenceDataService.getByType(REFERENCE_DATA_TYPES.CONDITION_ITEM);

          years.push(coeYear);
          requests.push(
            zip(of(data.coe.conditions), conditionItemRefDataRequest).pipe(
              map(([conditions, refData]) => this.setDocumentTypeOnConditions(conditions, refData)),
            ),
          );
        }

        years.push(...selectedApplications.map((app) => app.academicYear));
        requests.push(...years.map((year) => this.getConditionsForYear(year)));

        return zip(...requests).pipe(
          map((conditionsList: Condition[][]) => {
            return this.associateConditionsToYears(years, conditionsList);
          }),
          tap((conditionsByYear: ConditionsByYear) => this.allConditions$.next(conditionsByYear)),
        );
      }),
    );
  }
}

export class MockConditionService extends AbstractConditionService {
  public mockState: ConditionsByYear;
  public applicationYears: string[];
  public userService: UserService | MockUserService;
  public flashMessageService: FlashMessageService;

  constructor(
    mockData?: ConditionsByYear,
    applicationYears?: string[],
    userService?: UserService,
    public cacheService?: CacheManagementService,
    flashMessageService?: FlashMessageService,
  ) {
    super();

    this.applicationYears = applicationYears || ['2020', '2021'];
    this.mockState = mockData || { '2020': Condition.deserializeAll(conditionMockData()) };

    if (cacheService) {
      this.cacheService.shouldClearCache
        .pipe(filter((options) => options.target === CacheObjects.ALL))
        .subscribe(() => this.clearCache());
    }

    if (flashMessageService) {
      this.flashMessageService = flashMessageService;
    }

    if (userService && flashMessageService) {
      this.userService = userService;
      this.setupUserDetail(this.userService, this.flashMessageService);
    }
  }

  getConditionsForYear(year: string): Observable<Condition[]> {
    const conditions = this.mockState[year] || [];
    const conditionItemRefData = mockReferenceData().condition_item.map(ReferenceData.deserialize);
    return of(this.setDocumentTypeOnConditions(conditions, conditionItemRefData));
  }

  /* eslint-disable-next-line class-methods-use-this */
  getCoe() {
    return of(null);
  }

  getAllConditions(): Observable<ConditionsByYear> {
    if (!this.applicationYears.length) {
      return of({});
    }

    const years = this.applicationYears;
    const requests = years.map((year) => this.getConditionsForYear(year));
    return zip(...requests).pipe(
      map((conditionsList: Condition[][]) => {
        return this.associateConditionsToYears(years, conditionsList);
      }),
      tap((conditionsByYear: ConditionsByYear) => this.allConditions$.next(conditionsByYear)),
    );
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars,class-methods-use-this */
  refreshConditionsForYear(year: string) {
    // No-op
  }
}

// provides the mock reference data when useFakeBackend is true
export const conditionServiceFactory = (
  dataService,
  loggingService,
  cacheService,
  applicationService,
  referenceDataService,
  flashMessageService,
  userService,
  changeOfEnrolmentService,
): ConditionService | MockConditionService => {
  if (environment.useFakeBackend.condition) {
    return new MockConditionService(null, null, userService, cacheService, flashMessageService);
  } else {
    return new ConditionService(
      dataService,
      loggingService,
      cacheService,
      applicationService,
      referenceDataService,
      flashMessageService,
      userService,
      changeOfEnrolmentService,
    );
  }
};

export const conditionServiceProvider = {
  provide: ConditionService,
  useFactory: conditionServiceFactory,
  deps: [
    DataService,
    LoggingService,
    CacheManagementService,
    ApplicationService,
    ReferenceDataService,
    FlashMessageService,
    UserService,
    ChangeOfEnrolmentService,
  ],
};
