import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, Observer } from 'rxjs';

import { environment } from '@environment';
import { UCFile } from '@shared/models/uc-file';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export interface FileUploadServiceEvent {
  id: string;
  progress: number;
  complete: boolean;
  file: UCFile;
  xhr: XMLHttpRequest;
}

@Injectable()
export class FileUploadService {
  private serviceURL = `${environment.apiRoot}/document`;
  private uploadCache = {};
  log: Logger;

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  public abortUpload(file: FileUploadServiceEvent) {
    file.xhr.abort();
  }

  // eslint-disable-next-line max-lines-per-function
  public doXHR(
    file: File,
    category: string,
    year: string,
    isByStaff: boolean = false,
    userId?: string,
  ): Observable<FileUploadServiceEvent> {
    // eslint-disable-next-line max-lines-per-function
    return new Observable((stateObserver: Observer<FileUploadServiceEvent>) => {
      const xhr = new XMLHttpRequest();

      const uploadID = Math.random().toString(36).substring(7);
      const state = {
        id: uploadID,
        progress: 0,
        complete: false,
        file: UCFile.serialize({
          name: file.name,
          category,
          type: file.type,
          size: file.size,
          created: new Date().toISOString(),
          id: uploadID,
          year,
        }),
        xhr,
      };
      this.uploadCache[state.id] = state;
      stateObserver.next(state);

      const form = new FormData();
      form.append('category', category);
      form.append('file', file, file.name);
      form.append('year', year);

      xhr.upload.onprogress = (e: ProgressEvent) => {
        this.log.info('upload progress', file.name, e);
        state.progress = e.loaded / e.total;
        stateObserver.next(state);
      };

      xhr.upload.onabort = () => {
        delete this.uploadCache[state.id];
        this.log.info('aborting upload', file.name, category, year);
        stateObserver.next(state);
        stateObserver.complete();
      };

      xhr.onerror = (err) => {
        delete this.uploadCache[state.id];
        this.log.error('error when uploading file', file.name, category, year, err);
        stateObserver.error({ code: 'errors.fileUpload', data: state });
      };

      // eslint-disable-next-line max-lines-per-function, complexity
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          delete this.uploadCache[state.id];
          state.complete = true;
          state.progress = 100;
          if (xhr.status !== 201) {
            if (xhr.status === 415) {
              stateObserver.error({ code: 'errors.fileTypeInvalid', data: state });
            } else {
              stateObserver.error({ code: 'errors.fileUpload', data: state });
            }

            return;
          }
          let response;
          try {
            response = JSON.parse(xhr.responseText);
            if (typeof response === 'string') {
              response = JSON.parse(response);
            }
          } catch (e) {
            stateObserver.error(e);
          }
          response.created = response.created ? DateTime.fromISO(response.created).toISO() : null;
          state.file = UCFile.deserialize(response);

          stateObserver.next(state);
          stateObserver.complete();
        }
      };

      if (isByStaff) {
        xhr.open('POST', `${this.serviceURL}/staff/${userId}/${year}/${category}`, true);
      } else {
        xhr.open('POST', `${this.serviceURL}/${year}/${category}`, true);
      }

      xhr.send(form);
    });
  }
}
