/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-checkbox-input-group',
  templateUrl: './checkbox-input-group.component.html',
  styleUrls: ['./checkbox-input-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputGroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxInputGroupComponent implements OnInit, ControlValueAccessor {
  @Input() groupName: string;
  @Input() options: { labelText: string; value: string; control?: UntypedFormControl }[];
  @Input() type: string;
  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() showValidator = true;
  @Input() disableCheckbox = false;
  @Input() innerInputRequired: boolean;

  checkValue: string[];
  checkboxForm: UntypedFormGroup;
  hasValue = false;
  log: Logger;

  constructor(
    loggingService: LoggingService,
    public refData: ReferenceDataService,
    public fb: UntypedFormBuilder,
  ) {
    this.log = loggingService.createLogger(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get checkboxes(): UntypedFormArray {
    return this.checkboxForm.get('checkboxes') as UntypedFormArray;
  }

  ngOnInit() {
    this.log.info('ngOnInit');

    this.checkboxForm = this.fb.group({
      checkboxes: this.fb.array([]),
    });

    if (this.type) {
      this.refData.getByType(this.type).subscribe((refs) => {
        this.options = refs.map((ref) => {
          return {
            labelText: ref.description,
            value: ref.code,
            control: new UntypedFormControl(''),
          };
        });
        if (this.hasValue && this.checkValue.length) {
          this.setControls();
        }
      });
    }

    if (this.options) {
      this.options = this.options.map((opt) => {
        return Object.assign(opt, { control: new UntypedFormControl('') });
      });
    }

    this.checkboxForm.valueChanges.subscribe((val) => {
      this.propagateChange(val.checkboxes);
      if (val.checkboxes.length) {
        this.hasValue = true;
        this.propagateTouch(true);
      } else {
        this.hasValue = false;
      }
    });
  }

  onCheckChange(event) {
    if (event.target.checked) {
      if (!this.checkboxes) {
        this.checkboxForm.setControl('checkboxes', this.fb.array([this.fb.control(event.target.value)]));
      } else {
        this.checkboxes.push(new UntypedFormControl(event.target.value));
      }
    } else if (this.checkboxes && this.checkboxes.length) {
      this.checkboxes.controls.forEach((ctrl: UntypedFormControl, i: number) => {
        if (ctrl.value === event.target.value) {
          this.checkboxes.removeAt(i);
          return;
        }
      });
    }
  }

  setControls() {
    this.options.map((o) => {
      const isSet = this.checkValue.find((v) => v === o.value);
      if (isSet) {
        o.control.setValue(o.value);
      }
    });
  }

  /**
   * Write a new value to the element.
   */
  writeValue(val: any): void {
    if (!val.length) {
      this.checkboxForm.reset({});
      return;
    }

    this.checkValue = val;
    this.hasValue = true;

    if (val && !this.checkboxes.length) {
      this.checkboxForm.setControl('checkboxes', this.fb.array(val.map((v) => this.fb.control(v))));
    }

    if (this.options) {
      this.setControls();
    }

    this.checkboxes.patchValue(val);
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  /**
   * Set the function to be called'
   * when the control receives a touch event.
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
}
