import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedIdentity {
  staff_number: string;
  nsn_number: string;
  student_number: string;
  nz_drivers_license: string;
  nz_passport_number: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class Identity {
  public staffNumber: string;
  public nsnNumber: string;
  public studentNumber: string;
  public nzDriversLicense: string;
  public nzPassportNumber: string;

  constructor(rawIdentity) {
    Object.assign(this, rawIdentity);
  }

  static deserialize(payload: SerializedIdentity): Identity {
    if (payload === null) {
      return null;
    }

    return new Identity(camelizeKeys(payload));
  }

  static serialize(instance: Identity): SerializedIdentity {
    return snakeifyKeys(instance);
  }
}
