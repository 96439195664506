<label>
  <span *ngIf="labelText" class="label-text">
    {{ labelText }}
    <uc-required-field *ngIf="required"></uc-required-field>
    <button
      [attr.data-cy]="'tooltip'"
      *ngIf="labelTooltipContent"
      ucTooltip
      tooltipContent="{{ labelTooltipContent }}"
      (click)="labelClicked($event)"
      class="label-tooltip tooltip-btn"
    >
      <uc-icon
        iconTitle="More info"
        iconType="info"
        iconWidth="16"
        iconHeight="16"
        iconFill="$colorMineShaft"
      ></uc-icon>
    </button>
  </span>
  <ng-content></ng-content>
</label>
