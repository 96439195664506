<a *ngIf="localLink" [routerLink]="tileLink" class="tile-link">
  <ng-container *ngTemplateOutlet="staticTile"></ng-container>
</a>
<a *ngIf="externalLink" [href]="tileLink" class="tile-link">
  <ng-container *ngTemplateOutlet="staticTile"></ng-container>
</a>
<ng-container *ngIf="!tileLink">
  <ng-container *ngTemplateOutlet="staticTile"></ng-container>
</ng-container>

<ng-template #staticTile>
  <span class="tile-content">
    <span *ngIf="tileLink" class="tile-link-corner">
      <uc-icon class="tile-link-corner-icon" iconType="corner" iconWidth="20" iconHeight="12"></uc-icon>
    </span>
    <ng-content></ng-content>
  </span>
</ng-template>
