<uc-form-template [formGroup]="uePage">
  <uc-form-header title="{{strings.formTitle}}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header title="{{strings.header}}">
      <p>{{strings.description}}</p>
    </uc-section-header>
    <uc-university-entrance [task]="task" [ueForm]="uePage"></uc-university-entrance>
  </uc-section>

</uc-form-template>
