import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { Name } from '@shared/models/name';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-legal-name-declaration',
  templateUrl: './legal-name-declaration.component.html',
  styleUrls: ['./legal-name-declaration.component.scss'],
})
export class LegalNameDeclarationComponent implements OnInit, OnChanges {
  @Input() namesModel: UntypedFormGroup;
  @Input() hideDocUpload = false;
  @Input() applicationYear: string;
  @Input() otherNamesLength: number;
  @Input() task: Task;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() nameAdd = new EventEmitter<any>();
  @Output() nameRemove = new EventEmitter<number>();

  strings = strings.components.organisms.legalName;
  tabHeadings = strings.components.organisms.legalName.otherNameTabHeadings;
  showPreferredOption = true;
  private log: Logger;

  constructor(loggingService: LoggingService, private changeDetectorRef: ChangeDetectorRef) {
    this.log = loggingService.createLogger(this);
  }

  get name(): UntypedFormArray {
    return this.namesModel.get('name') as UntypedFormArray;
  }

  get allNameControls(): UntypedFormControl[] {
    const { legalName } = this.namesModel.controls;
    return [legalName as UntypedFormControl].concat(this.name.controls as UntypedFormControl[]);
  }

  ngOnInit() {
    this.log.info('ngOnInit');
    this.namesModel.get('legalName').setValidators(UCValidators.validateNameWithoutWhitespace);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.otherNamesLength) {
      (this.name.controls as UntypedFormControl[]).forEach((control) => {
        control.setValidators(UCValidators.validateNameWithoutWhitespace);
        this.changeDetectorRef.detectChanges();
      });

      this.setDefaultPreferred();
    }
  }

  private updatePreferred(preferredIndex) {
    const clearIfPreferred = (control) => {
      const name = control.value;
      if (name && get(name, 'type.code') === Name.PREFERRED_CODE) {
        name.type = null;
        control.setValue(name);
      }
    };

    this.allNameControls.filter((_, index) => preferredIndex !== index).forEach(clearIfPreferred);
    this.allNameControls[preferredIndex].value.type = new ReferenceData({ code: Name.PREFERRED_CODE });
  }

  private setDefaultPreferred() {
    const { legalName } = this.namesModel.controls;
    const preferredName = this.allNameControls.find((c) => get(c, 'value.type.code') === Name.PREFERRED_CODE);

    if (!preferredName) {
      const name = legalName.value;
      name.type = new ReferenceData({ code: Name.PREFERRED_CODE });
      legalName.setValue(name, { emitEvent: false });
    }

    this.showPreferredOption = this.name.controls.length > 0;
  }

  setPreferred(nameIndex) {
    if (isNaN(Number(nameIndex))) {
      this.log.error('unexpected value for nameIndex:', nameIndex);
      return;
    }

    this.updatePreferred(Number(nameIndex));
    this.setDefaultPreferred();
  }

  removeOtherName(indexToBeRemoved) {
    this.nameRemove.emit(indexToBeRemoved);
    this.setDefaultPreferred();
  }

  addName() {
    this.nameAdd.emit(null);
    this.setDefaultPreferred();
  }
}
