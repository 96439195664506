import { Component, ViewChild } from '@angular/core';

import { MyucSearchFiltersComponent } from '@app/components/organisms/myuc-search-filters/myuc-search-filters.component';
import { CoursePlannerComponent } from '@shared/components/templates/course-planner/course-planner.component';

@Component({
  selector: 'uc-myuc-course-planner',
  templateUrl: './myuc-course-planner.component.html',
  styleUrls: ['./myuc-course-planner.component.scss'],
})
export class MyucCoursePlannerComponent extends CoursePlannerComponent {
  @ViewChild(MyucSearchFiltersComponent) searchFilter;
}
