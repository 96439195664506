import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { QUAL_CATEGORIES } from '@shared/constants/categories';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { OfferDecision } from '@shared/models/offer-decision';
import { Qualification, QualificationCategory, QualificationSummary } from '@shared/models/qualification';
import { QualificationOccurrence } from '@shared/models/qualification-occurrence';
import { QualificationResult } from '@shared/models/qualification-result';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

export interface OccurrenceOptionType {
  value: string;
  labelText: string;
}

@Component({
  selector: 'uc-defer-offer',
  templateUrl: './defer-offer.component.html',
  styleUrls: ['./defer-offer.component.scss'],
})
export class DeferOfferComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() isInternational: boolean;
  strings = strings.components.tasks.deferOffer;
  applicationEnrolment: ApplicationEnrolment;
  log: Logger;
  occurrenceOptions = [];
  noQualOccurrences = false;
  offerDecisionQual: QualificationSummary;
  formLoaded = false;
  previousApplicationYears: string[] = [];
  deferOfferForm = new UntypedFormGroup({
    deferredQualOccurrenceYear: new UntypedFormControl(null, Validators.required),
    deferredQualOccurrence: new UntypedFormControl(null, Validators.required),
  });
  hasDoctoralCategory = false;

  private qualificationCategory$ = new BehaviorSubject<QualificationCategory[]>([]);

  constructor(
    private loggingService: LoggingService,
    private applicationService: ApplicationService,
    private qualificationService: QualificationService,
    private refdataService: ReferenceDataService,
  ) {
    super();
    this.log = this.loggingService.createLogger(this);
  }

  get hasYearSelected() {
    return !!this.deferOfferForm.get('deferredQualOccurrenceYear').value;
  }

  public updateFormValidity() {}

  ngOnInit() {
    // Fetch application to set application year in cache
    this.applicationService.application.subscribe();
    this.checkForDoctoralCategory();
    this.qualificationCategory$.subscribe((qualCategory) => {
      this.hasDoctoralCategory = qualCategory.some((category) => category.category === QUAL_CATEGORIES.DOCTORAL);
    });

    // Set available years for year selector
    this.refdataService.getByType('application_year').subscribe((years) => {
      const orderedYears = years.map((year) => year.code).sort();
      const currentYearIndex = orderedYears.indexOf(this.applicationYear);
      this.previousApplicationYears = orderedYears.splice(0, currentYearIndex);
    });

    this.fetchApplicationEnrolment();

    this.handleDeferredQualOccurrenceYearChange();
  }

  checkForDoctoralCategory() {
    // checks
    this.qualificationService.qualification
      .pipe(
        filter((qualification) => !!qualification),
        takeUntil(this.componentDestroyed),
      )
      .subscribe((qualification: Qualification) => {
        this.qualificationCategory$.next([...this.qualificationCategory$.value, ...qualification.categories]);
      });
  }

  fetchApplicationEnrolment = () => {
    // Fetch highest priority active application enrolment
    this.applicationService
      .getActiveApplicationEnrolment(this.applicationYear)
      .pipe(
        filter((appEnrolment: ApplicationEnrolment) => !!appEnrolment),
        switchMap((appEnrolment: ApplicationEnrolment) => {
          this.applicationEnrolment = appEnrolment;
          // For enrolled quals, get one with offer decision category
          const enrolledQuals = this.applicationEnrolment.enrolledQualifications.map((qual) => qual.href);
          return this.qualificationService.getQualificationsByURIs(this.applicationYear, enrolledQuals);
        }),
        switchMap(this.processQualificationResult),
        tap(() => {
          this.mapFormValues();

          this.formLoaded = true;
        }),
      )
      .subscribe(this.setOccurrenceOptions);
  };

  processQualificationResult = (quals: QualificationResult) => {
    // If more than one qualification, cannot handle defer offer for now.
    if (quals.qualification.length > 1) {
      return of([]);
    } else {
      if (this.isInternational) {
        // For international students.
        this.offerDecisionQual = quals.qualification[0];
      } else {
        // For domestic students with "offer of decision" which currently only applicable to "Doctoral".
        this.offerDecisionQual = quals.qualification.find((q) =>
          q.categories.find((c) => c.category === QUAL_CATEGORIES.OFFER_DECISION_MYUC),
        );
      }

      if (this.offerDecisionQual) {
        const occYear =
          this.applicationEnrolment.offerDecision?.deferredQualOccurrenceYear?.code || this.applicationYear;

        return this.fetchQualOccurrences(occYear, this.offerDecisionQual.code);
      } else {
        return of([]);
      }
    }
  };

  handleDeferredQualOccurrenceYearChange = () => {
    this.deferOfferForm
      .get('deferredQualOccurrenceYear')
      .valueChanges.pipe(
        filter((formControlValue) => !!formControlValue),
        tap(() => {
          this.deferOfferForm.get('deferredQualOccurrence').reset('');
          this.applicationEnrolment.offerDecision.deferredQualOccurrence = '';
        }),
        switchMap((year: { code: string }) => {
          if (year.code && this.offerDecisionQual.code) {
            return this.fetchQualOccurrences(year.code, this.offerDecisionQual.code);
          } else {
            return of([]);
          }
        }),
      )
      .subscribe(this.setOccurrenceOptions);
  };

  setOccurrenceOptions = (occs: OccurrenceOptionType[]) => {
    if (occs.length) {
      this.noQualOccurrences = false;

      this.occurrenceOptions = occs;
    } else {
      this.noQualOccurrences = true;
    }
  };

  fetchQualOccurrences(year: string, qual: string): Observable<OccurrenceOptionType[]> {
    const citizenshipCategory = this.isInternational ? this.strings.international : this.strings.domestic;
    return this.qualificationService.getQualificationOccurrences(year, qual, { citizenshipCategory }).pipe(
      map((occs: QualificationOccurrence[]) =>
        occs.map((o) => ({
          value: o.internalReference,
          labelText: o.intakeName,
        })),
      ),
    );
  }

  mapFormValues() {
    const { deferredQualOccurrence, deferredQualOccurrenceYear } = this.applicationEnrolment.offerDecision;

    this.deferOfferForm.setValue({
      deferredQualOccurrence,
      deferredQualOccurrenceYear,
    });
  }

  public update() {
    const offerData: OfferDecision = {
      deferredQualOccurrence: this.deferOfferForm.get('deferredQualOccurrence').value,
      deferredQualOccurrenceYear: this.deferOfferForm.get('deferredQualOccurrenceYear').value,
    };

    this.applicationService
      .updateOfferDecision(this.applicationYear, this.applicationEnrolment.internalReference, offerData)
      .subscribe({
        next: () => this.next.emit(),
        error: (error: DSHttpError) => {
          this.errors.emit();
          this.log.error('error thrown while updating application enrolment:', error);
        },
      });
  }
}
