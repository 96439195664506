import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@shared/constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-identity-groups',
  templateUrl: './identity-groups.component.html',
  styleUrls: ['./identity-groups.component.scss'],
})
export class IdentityGroupsComponent {
  @Input() identityGroups: UntypedFormGroup;
  @Input() task?: Task;

  strings = strings.components.organisms.identityGroups;

  get religionIsSelected() {
    return this.identityGroups.controls.religionCheckbox.value;
  }

  get showReligionTextField() {
    return this.identityGroups.controls.religionDropdown.value?.code === 'other';
  }

  clearReligionTextField() {
    this.identityGroups.controls.religionTextField.setValue(null, { emitEvent: false });
  }
}
