import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@shared/services/user/user.service';
import { internalUrls } from '@constants/internalUrls';
import { UserDetail } from '@shared/models/user';
import { environment } from '@environment';
import { map } from 'rxjs/operators';

@Injectable()
export class UserAuthenticatedGuardService implements CanActivate, CanActivateChild {
  attemptedRoute: string;
  lastAttemptedRoute: string;

  constructor(private userService: UserService, private router: Router) {}

  isCorrectScope(userDetail): boolean {
    const scopeUser = userDetail[environment.scope];
    return !!scopeUser && scopeUser.identifier === userDetail.mainUser.identifier;
  }

  isAllowedAccess(userDetail: UserDetail, targetUrl: string, queryParams = {}): boolean {
    if (userDetail && !this.isCorrectScope(userDetail)) {
      this.userService.logout().catch((err) => {});
      return false;
    }

    if (this.attemptedRoute === null) {
      this.lastAttemptedRoute = targetUrl;
    }

    const currentUser = this.userService.user;
    if (currentUser) {
      if (currentUser.partiallyHydrated) {
        const navigatingToHydratePage = internalUrls.hydrateOverlay[0] === targetUrl;
        if (navigatingToHydratePage) {
          return true;
        } else {
          // store route state then navigate to the hydrate overlay route
          this.attemptedRoute = targetUrl;
          this.router.navigate(internalUrls.hydrateOverlay);
          return false;
        }
      }
      if (this.attemptedRoute) {
        this.router.navigateByUrl(
          this.attemptedRoute,
          /* Removed unsupported properties by Angular migration: queryParams. */ {},
        );
        this.attemptedRoute = null;
        return false;
      }
    } else {
      this.attemptedRoute = targetUrl;
      this.lastAttemptedRoute = targetUrl;
      this.router.navigate(internalUrls.login, { queryParams });
    }
    return !!currentUser;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.userDetail.pipe(
      map((userDetail) => this.isAllowedAccess(userDetail, state.url, route.queryParams)),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
