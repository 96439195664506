<div [attr.class]="courseCardClasses">
  <h3 class="course-card__heading"><a [attr.href]="course.href" target="_blank">{{course.courseCode}}</a>-{{course.occurrenceCode}}</h3>
  <div class="course-card__site">{{course.site}}</div>
  <div class="course-card__desc">{{course.courseTitle}}</div>
  <div class="course-card__points">{{course.coursePoints}} {{strings.points}}</div>
  <uc-course-level-pill [course]="course"></uc-course-level-pill>
  <uc-add-course
    (addCourse)="addACourse()"
    (undoChange)="undoChangeAction($event)"
    [courseCode]="course.courseOccurrenceCode"
    [selectedCourses]="selectedCourses"
    [cancelSpinner]="cancelSpinner"
    [changeAction]="changeAction"
  ></uc-add-course>
</div>