export const QUALIFICATION_LEVELS = {
  SECONDARY: 'SECONDARY',
  FOUNDATION: 'FOUNDATION',
  POST_GRADUATE: 'POSTGRADUATE',
  UNDER_GRADUATE: 'UNDERGRADUATE',
};

export const SECONDARY_QUAL_CODES = {
  NCEA3: '01',
  NCEA2: '03',
  IB: '07',
  CIE: '08',
  NO_FORMAL: '09',
  OTHER: '10',
  UNKNOWN: '11',
  OVERSEAS: '06',
};

export const COUNTRY_OPTIONS = {
  NZL: 'NZL',
  OTHER: 'OTHER',
};

export const SCHOOLS = {
  NZ_SCHOOL_NOT_ON_LIST: '998',
  OVERSEAS: '999',
};
