<uc-form-template [formGroup]="questionForm">
  <uc-form-header [title]="task?.title">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-section-header [title]="strings.goalsAspirationsTitle">
      <p>
        {{ strings.goalsAspirationsDescription }}
      </p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        path="/postgrad_intentions/why_qualification_chosen"
      ></uc-task-hint>
      <uc-subsection-question
        >{{ strings.whyIsItInteresting }}<uc-required-field></uc-required-field
      ></uc-subsection-question>
      <uc-form-input>
        <uc-text-area
          testSelector="post-grad-why"
          class="why-this-qual-question"
          formControlName="whyThisQualification"
        ></uc-text-area>
      </uc-form-input>
    </uc-subsection>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        path="/postgrad_intentions/what_are_your_goals"
      ></uc-task-hint>
      <uc-subsection-question
        >{{ strings.whatWillYouDoNext }}<uc-required-field></uc-required-field
      ></uc-subsection-question>
      <uc-form-input>
        <uc-text-area
          testSelector="post-grad-next"
          class="what-next-question"
          formControlName="whatNext"
        ></uc-text-area>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section>
    <uc-section-header [title]="strings.cvTitle">
      <p>
        {{ strings.cvRequestText }}
      </p>
      <p>
        {{ strings.cvProofExplain }}
      </p>
    </uc-section-header>
    <uc-subsection>
      <uc-file-upload
        [title]="strings.uploadQuestion"
        categoryCode="CV"
        [year]="applicationYear"
        usedInTask="true"
      ></uc-file-upload>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section>
    <uc-section-header [title]="strings.thesisHeader">
      <p [innerHTML]="strings.doYouHaveASupervisorInfo"></p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        path="/postgrad_intentions/supervisor_name"
      ></uc-task-hint>
      <uc-subsection-question>{{ strings.doYouHaveASupervisor }}</uc-subsection-question>
      <uc-form-input>
        <uc-string-input
          testSelector="supervisor-name"
          [formControl]="questionForm.get('supervisorName')"
          class="input-medium"
        >
        </uc-string-input>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>
