import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData, SerializedReferenceData } from '@shared/models/reference-data';

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedPhone {
  country: SerializedReferenceData;
  prefix: string;
  /* eslint-disable-next-line id-blacklist */
  number: string;
  extension: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class Phone {
  public country: ReferenceData;
  public prefix: string;
  /* eslint-disable-next-line id-blacklist */
  public number: string;
  public extension: string;

  constructor(rawPhone) {
    Object.assign(this, rawPhone);
  }

  get fullNumber() {
    return this.country && this.country.code + this.number;
  }

  static deserialize(payload: SerializedPhone): Phone {
    if (payload === null) {
      return null;
    }

    const phone: Phone = camelizeKeys(payload);
    phone.country = ReferenceData.deserialize(payload.country);
    return new Phone(phone);
  }

  static serialize(instance: Phone): SerializedPhone {
    const clone = { ...instance };
    clone.country = ReferenceData.serialize(clone.country);
    return snakeifyKeys(clone);
  }
}
