<ngx-smart-modal
  [identifier]="identifier"
  [hideDelay]="0"
  (onClose)="closeHandler()"
  (onDismiss)="dismissHandler()"
  [dismissable]="dismissable"
  [closable]="closable"
  [customClass]="largeModal ? 'large' : ''"
>
  <ng-content></ng-content>
</ngx-smart-modal>
