import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { Applicant } from '@shared/models/applicant';
import { ApplicantStaff, UniversityAdmission } from '@shared/models/applicant-staff';
import { AttainedQualification } from '@shared/models/attained-qualification';
import { UCError } from '@shared/models/errors';
import { UserDetail } from '@shared/models/user';
import { mockData as mockApplicantResponse } from '@shared/services/applicant/applicant.data.mock';
import { CacheManagementService, CacheObjects } from '@shared/services/cache-management/cache-management.service';
import { DataService, IDSRequestOpts } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

import { ProcessService } from '../process/process.service';

@Injectable()
export class ApplicantService {
  private serviceUrl = `${environment.apiRoot}/applicant/`;
  private log: Logger;
  private applicant$ = new BehaviorSubject<Applicant>(null);
  private applicantStaff$ = new BehaviorSubject<ApplicantStaff>(null);
  private applicantError$ = new Subject<UCError>();
  private cachedUserID: string;

  constructor(
    private userService: UserService,
    private dataService: DataService,
    loggingService: LoggingService,
    cacheService: CacheManagementService,
  ) {
    this.log = loggingService.createLogger(this);

    // Ensure we update the applicant when the logged in student changes
    this.userService.userDetail.subscribe((detail: UserDetail) => {
      const student = detail && detail.student;

      if (!student) {
        this.cachedUserID = null;
        return this.applicant$.next(null);
      }

      if (!this.cachedUserID || this.cachedUserID !== student.identifier) {
        this.cachedUserID = student.identifier;
        this.getApplicant().subscribe();
      }
    });

    cacheService.shouldClearCache
      .pipe(filter((options) => options.target === CacheObjects.ALL))
      .subscribe(() => this.clearCache());
  }

  get applicant(): Observable<Applicant> {
    return this.applicant$.asObservable();
  }

  get applicantStaff(): Observable<ApplicantStaff> {
    return this.applicantStaff$.asObservable();
  }

  get applicantError(): Observable<UCError> {
    return this.applicantError$.asObservable();
  }

  private clearCache(): void {
    this.applicant$.next(null);
    this.cachedUserID = null;
  }

  private getRequestOptions(options?: Record<string, unknown>): IDSRequestOpts {
    return {
      success$: this.applicant$,
      error$: this.applicantError$,
      deserialize: Applicant.deserialize,
      ...options,
    };
  }

  private getRequestOptionsStaff(options?: Record<string, unknown>): IDSRequestOpts {
    return {
      success$: this.applicantStaff$,
      error$: this.applicantError$,
      deserialize: ApplicantStaff.deserialize,
      ...options,
    };
  }

  /**
   * Makes a request to the applicant microservice to retrieve an applicant
   * The request requires the currentUser's token to be in the Auth header
   * of the request. The Applicant microservice will use that token to retrieve
   * the associated applicant from a user.
   *
   * @returns
   *
   * @memberOf ApplicantService
   */
  getApplicant(): Observable<Applicant> {
    return this.dataService.fetch(this.serviceUrl, this.getRequestOptions());
  }

  getApplicantForStaff(canonicalId): Observable<ApplicantStaff> {
    const url = `${this.serviceUrl}staff/${canonicalId}`;
    return this.dataService.fetch(url, {
      success$: this.applicantStaff$,
      error$: this.applicantError$,
      deserialize: ApplicantStaff.deserialize,
    });
  }

  updateApplicantForStaff(canonicalId, applicantStaff: ApplicantStaff): Observable<ApplicantStaff> {
    const url = `${this.serviceUrl}staff/${canonicalId}`;
    const applicantData = ApplicantStaff.serialize(applicantStaff);
    return this.dataService.patch(url, applicantData, this.getRequestOptionsStaff({ emitErrors: false }));
  }

  /**
   * Makes a request to the applicant microservice to update an applicant
   * The request requires the currentUser's token to be in the Auth header
   * of the request. The Applicant microservice will use the applicant object
   * in the body of the request to update it.
   *
   * @returns
   *
   * @memberOf ApplicantService
   */
  updateApplicant(applicant: Applicant): Observable<Applicant> {
    return this.dataService.put(this.serviceUrl, Applicant.serialize(applicant), this.getRequestOptions()).pipe(
      tap((application) => {
        this.userService.maybeUpdateFirebaseName(application);
        this.userService.getUser().subscribe();
      }),
    );
  }

  validateApplicant(applicantStaff: ApplicantStaff, canonicalId: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/validate`;
    return this.dataService.post(
      url,
      ApplicantStaff.serialize(applicantStaff),
      this.getRequestOptionsStaff({ ignoredErrorStatuses: [422], emitErrors: false }),
    );
  }

  addAttainedQualification(attainedQualification: AttainedQualification, canonicalId: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/${attainedQualification.qualificationType}`;
    const body = { attained_qualification: AttainedQualification.serialize(attainedQualification) };
    return this.dataService.post(url, body, this.getRequestOptions({ deserialize: AttainedQualification.deserialize }));
  }

  updateAttainedQualification(attainedQualification: AttainedQualification, canonicalId: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/${attainedQualification.qualificationType}/${attainedQualification.internalReference}`;
    const body = { attained_qualification: AttainedQualification.serialize(attainedQualification) };
    return this.dataService.put(url, body, this.getRequestOptions({ deserialize: AttainedQualification.deserialize }));
  }

  deleteAttainedQualification(attainedQualification: AttainedQualification, canonicalId: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/${attainedQualification.qualificationType}/${attainedQualification.internalReference}`;
    return this.dataService.del(url, this.getRequestOptions({ error$: this.applicantError$ }));
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export class MockApplicantService {
  // Make these subjects public for test implementations
  public applicant$ = new BehaviorSubject<Applicant>(null);
  public applicantStaff$ = new BehaviorSubject<ApplicantStaff>(null);
  public applicantError$ = new Subject<UCError>();
  mockApplicantResponse = mockApplicantResponse();

  constructor(mockdata?) {
    if (mockdata) {
      this.applicant$.next(mockdata);
    }
  }

  get applicant(): Observable<Applicant> {
    return this.applicant$.asObservable();
  }

  get applicantStaff(): Observable<ApplicantStaff> {
    return this.applicantStaff$.asObservable();
  }

  get applicantError(): Observable<UCError> {
    return this.applicantError$.asObservable();
  }

  getApplicant() {
    return this.applicant;
  }

  updateApplicant(applicant: Applicant): Observable<Applicant> {
    this.applicant$.next(applicant);
    return of(applicant);
  }

  updateApplicantForStaff(canonicalId, applicantStaff: ApplicantStaff) {
    const mockApplicantStaff = ApplicantStaff.deserialize(this.mockApplicantResponse);
    this.applicantStaff$.next(mockApplicantStaff);
    return of(mockApplicantStaff);
  }

  getApplicantForStaff(canonicalId): Observable<ApplicantStaff> {
    const mockApplicantStaff: ApplicantStaff = ApplicantStaff.deserialize(this.mockApplicantResponse);
    mockApplicantStaff.universityAdmission = new UniversityAdmission({ assessment: 'Assessment data' });
    this.applicantStaff$.next(mockApplicantStaff);
    return of(mockApplicantStaff);
  }

  validateApplicant(applicantStaff: ApplicantStaff, canonicalId: string) {
    return this.applicantStaff$.asObservable();
  }

  addAttainedQualification(attainedQualification: AttainedQualification, canonicalId: string) {
    const mockApplicantStaff: ApplicantStaff = ApplicantStaff.deserialize(this.mockApplicantResponse);
    return of(mockApplicantStaff.attainedQualification);
  }

  updateAttainedQualification(attainedQualification: AttainedQualification, canonicalId: string) {
    const mockApplicantStaff: ApplicantStaff = ApplicantStaff.deserialize(this.mockApplicantResponse);
    return of(mockApplicantStaff.attainedQualification);
  }

  deleteAttainedQualification(attainedQualification: AttainedQualification, canonicalId: string) {
    const mockApplicantStaff: ApplicantStaff = ApplicantStaff.deserialize(this.mockApplicantResponse);
    return of(mockApplicantStaff.attainedQualification);
  }
}

// provides the mock reference data when useFakeBackend is true
export const applicantServiceFactory = (
  userService,
  dataService,
  loggingService,
  cacheService,
): ApplicantService | MockApplicantService => {
  if (environment.useFakeBackend.applicant) {
    return new MockApplicantService(null);
  } else {
    return new ApplicantService(userService, dataService, loggingService, cacheService);
  }
};

export const applicantServiceProvider = {
  provide: ApplicantService,
  useFactory: applicantServiceFactory,
  deps: [UserService, DataService, LoggingService, CacheManagementService, ProcessService],
};
