import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

import { ReferenceData, SerializedReferenceData } from './reference-data';

export interface SerializedPersonalIdentifier {
  type?: { code: string };
  selected: boolean;
  source: SerializedReferenceData;
  custom: string;
}

export class PersonalIdentifier {
  public type?: { code: string };
  public selected: boolean;
  public source: ReferenceData;
  public custom: string;

  constructor(attrs: Partial<PersonalIdentifier> = {}) {
    const defaults = {
      selected: null,
      source: null,
      custom: null,
    };
    Object.assign(this, defaults, attrs);
  }

  static deserialize(payload: Partial<PersonalIdentifier>): PersonalIdentifier {
    return camelizeKeys(payload);
  }

  static serialize(instance: Partial<PersonalIdentifier>): SerializedPersonalIdentifier {
    const updatedInstance = this.clearDependentFields(instance);
    if (updatedInstance.custom) {
      updatedInstance.custom = instance.custom.trim() || null;
    }
    return snakeifyKeys(updatedInstance);
  }

  static clearDependentFields(instance: Partial<PersonalIdentifier>) {
    if (!instance.selected) {
      instance.source = null;
      instance.custom = null;
    }
    if (!instance.source) {
      instance.custom = null;
    }
    return instance;
  }
}

export class PersonalIdentifiers {
  rainbowCommunity: PersonalIdentifier;
  refugeeBackground: PersonalIdentifier;
  religion: PersonalIdentifier;

  constructor(attrs: Partial<PersonalIdentifiers> = {}) {
    const defaults = {
      rainbowCommunity: new PersonalIdentifier({ type: { code: 'rainbow_community' } }),
      refugeeBackground: new PersonalIdentifier({ type: { code: 'refugee_background' } }),
      religion: new PersonalIdentifier({ type: { code: 'religion' } }),
    };
    Object.assign(this, defaults, attrs);
  }

  static deserializeAll(payload: Partial<SerializedPersonalIdentifier[]>): PersonalIdentifiers {
    if (!payload) return null;
    const deserializedPayload = {};
    payload.forEach((personalIdentifier) => {
      deserializedPayload[personalIdentifier.type.code] = new PersonalIdentifier(personalIdentifier);
    });

    return camelizeKeys(deserializedPayload);
  }

  static serializeAll(instance: PersonalIdentifiers): SerializedPersonalIdentifier[] {
    const serializedPayload = [];
    Object.keys(instance).forEach((key) => {
      serializedPayload.push(PersonalIdentifier.serialize(instance[key]));
    });
    return serializedPayload;
  }
}
