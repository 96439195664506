import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalService } from '@shared/services/modal/modal.service';
import { internalUrls } from '@shared/constants/internalUrls';

@Injectable()
export class UnsavedDataGuard implements CanDeactivate<any> {
  constructor(private modalService: ModalService, private router: Router) {}

  willNavigate(currentUrl, nextUrl, currentRoute) {
    if (currentRoute.data && currentRoute.data.unsaveModalRoutes) {
      if (!currentRoute.data.unsaveModalRoutes.some((el) => !!currentUrl.match(new RegExp(`${el}$`)))) {
        return true;
      } else if (this.modalService.shouldNavigate) {
        return true;
      }

      // whitelisted routes take the form 'courses/:param1/:param2'
      if (currentRoute.data.whitelistedRoutes) {
        const whitelistedRoutes = currentRoute.data.whitelistedRoutes.map((route) => {
          const params = route.split('/');
          return params.map((el) => (el.charAt(0) === ':' ? '/(\\w*)' : el)).join('');
        });
        if (whitelistedRoutes.some((el) => !!nextUrl.match(new RegExp(`${el}$`)))) {
          this.modalService.shouldNavigate = true;
          return true;
        }
      }
    }
    this.modalService.dismissHandler = () => {
      this.modalService.dismissHandler = () => this.modalService.cardDirtyStatus.next([]);
      this.modalService.shouldNavigate = true;
      this.router.navigateByUrl(nextUrl);
    };

    if (!this.modalService.shouldNavigate && nextUrl !== internalUrls.login[0]) {
      this.modalService.navigationWarning();
    }
    return !!this.modalService.shouldNavigate;
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.willNavigate(currentState.url, nextState.url, currentRoute);
  }
}
