<div
  class="minimal-footer"
  [class.dashboard]="fullPageView"
>
  <div class="footer-help-text">{{ footerStrings.needHelpText }}</div>
  <uc-process-help-modal-button *ngIf="showHelp"></uc-process-help-modal-button>
  <div class="minimal-footer-btm">
    <div class="minimal-footer-block minimal-footer-logo-block">
      <a
        [href]="footerStrings.homepageLink"
        target="_blank"
        rel="noopener"
        class="minimal-footer-logo"
      >
        <img
          src="assets/img/uc-logo-minimal-red.png"
          alt="University of Canterbury"
        />
      </a>
    </div>
    <div class="minimal-footer-block minimal-footer-text-block">
      <h4 class="minimal-footer-heading">{{ footerStrings.generalEnquiry }}</h4>
      <a
        href="tel:{{ footerStrings.infoPhoneLink }}"
        class="minimal-footer-tel-link"
        >{{ footerStrings.infoPhone }}</a
      >
      <a
        href="mailto:{{ footerStrings.infoEmail }}"
        class="minimal-footer-link"
        >{{ footerStrings.infoEmail }}</a
      >
    </div>
    <div class="minimal-footer-block minimal-footer-text-block">
      <h4 class="minimal-footer-heading">{{ footerStrings.enrolmentEnquiries }}</h4>
      <a
        href="tel:{{ footerStrings.enrolmentPhoneLink }}"
        class="minimal-footer-tel-link"
        >{{ footerStrings.enrolmentPhone }}</a
      >
      <a
        href="mailto:{{ footerStrings.enrolEmail }}"
        class="minimal-footer-link"
        >{{ footerStrings.enrolEmail }}</a
      >
    </div>
    <div class="minimal-footer-block minimal-footer-text-block">
      <h4 class="minimal-footer-heading">{{ footerStrings.internationalEnquiries }}</h4>
      <a
        href="tel:{{ footerStrings.internationalEnquiriesPhoneLink }}"
        class="minimal-footer-tel-link"
        >{{ footerStrings.internationalEnquiriesPhone }}</a
      >
      <a
        [href]="footerStrings.askAQuestionLink"
        target="_blank"
        rel="noopener"
        class="minimal-footer-link"
        >{{ footerStrings.askAQuestion }}</a
      >
    </div>
  </div>
</div>
