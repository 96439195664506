<div class="reference-data-selector">
  <label *ngIf="labelName">{{ labelName }} <uc-required-field *ngIf="required"></uc-required-field></label>
  <div
    class="select-content"
    [class.hasValue]="hasValue"
  >
    <ng-multiselect-dropdown
      [placeholder]="placeholder"
      [settings]="dropdownSettings"
      [data]="options"
      [formControl]="innerControl"
      (onSelectAll)="selectAll(true)"
      (onSelect)="selectAll(false)"
      (onDeSelect)="selectAll(false)"
    >
    </ng-multiselect-dropdown>
    <uc-icon
      *ngIf="!noIcon"
      class="valid-icon"
      iconType="tick"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"
    ></uc-icon>
    <uc-icon
      *ngIf="!noIcon"
      class="invalid-icon"
      iconType="close"
      iconWidth="11"
      iconHeight="11"
      iconFill="$colorWhite"
    ></uc-icon>
  </div>
</div>
