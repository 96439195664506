import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

export class PostgradIntentions {
  whyQualificationChosen: string = null;
  whatAreYourGoals: string = null;
  areaOfResearch: string = null;
  studyDepartment: string = null;
  supervisorName: string = null;
  supervisorDepartment: string = null;
  hasContactedSupervisor: boolean = null;
  beginStudyAway: boolean = null;
  moveToChristchurchAfter: string = null;
  requireStudyAway: boolean = null;

  constructor(attrs: Partial<PostgradIntentions> = {}) {
    Object.assign(this, attrs);
  }

  static deserialize(payload: any): PostgradIntentions {
    return new PostgradIntentions(camelizeKeys(payload));
  }

  static serialize(instance: any): any {
    const clone = Object.assign(new PostgradIntentions({}), instance);
    return snakeifyKeys(clone);
  }
}
