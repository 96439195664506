<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-section-header title="{{ a2eStrings.identificationDetailsSection.header }}">
      <p>{{ a2eStrings.identificationDetailsSection.description[0] }}</p>
      <p>{{ a2eStrings.identificationDetailsSection.description[1] }}</p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/birth_date"
      ></uc-task-hint>
      <uc-subsection-question>
        {{ a2eStrings.identificationDetailsSection.birthDateQuestion }} <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-date-selector
          [baseYear]="dateOfBirthYear"
          [range]="85"
          [formControl]="reviewForm.get('birthDate')"
          required="yes"
          testSelector="dob"
        ></uc-date-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-legal-name-declaration
      [task]="task"
      [otherNamesLength]="otherNamesLength"
      (nameAdd)="this.otherNames.push()"
      (nameRemove)="this.otherNames.removeAt($event)"
      [namesModel]="reviewForm.get('names')"
    ></uc-legal-name-declaration>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/gender"
      ></uc-task-hint>
      <uc-gender-selector [genderForm]="reviewForm.get('genderGroup')"></uc-gender-selector>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section [formGroup]="reviewForm.get('citizenship')">
    <uc-section-header title="{{ a2eStrings.citizenshipSection.header }}">
      <p>{{ strings.citizenshipSection.howToChange }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/demographic/citizenship"
      ></uc-task-hint>
      <uc-subsection-question
        >{{ a2eStrings.citizenshipSection.categoryQuestion }}<uc-required-field></uc-required-field
      ></uc-subsection-question>
      <uc-form-input>
        <uc-reference-data-selector
          type="citizenship_category"
          formControlName="citizenCategory"
          required="yes"
        ></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection *ngIf="!isNzOrAus(this.reviewForm.get('citizenship.citizenCategory').value)">
      <uc-form-input>
        <uc-task-hint
          [task]="task"
          path="/applicant/demographic/passport_country"
        ></uc-task-hint>
        <uc-subsection-question>
          {{ a2eStrings.citizenshipSection.countryQuestion }}
          <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-reference-data-selector
          type="country"
          [filter]="['NZL', 'AUS']"
          formControlName="citizenCountry"
          required="yes"
        ></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/application/study_in_nz"
      ></uc-task-hint>
      <uc-subsection-question
        >{{ a2eStrings.citizenshipSection.liveInNzQuestion }}<uc-required-field></uc-required-field
      ></uc-subsection-question>
      <uc-subsection-minor-text>
        {{ a2eStrings.citizenshipSection.liveInNzDescription }}
      </uc-subsection-minor-text>

      <uc-form-input>
        <uc-radio-input-group
          formControlName="studyInNz"
          [options]="radioOptions"
          [displayHorizontally]="true"
          groupName="study-nz"
        ></uc-radio-input-group>
      </uc-form-input>

      <uc-message-banner
        type="info"
        *ngIf="showDesc"
      >
        <p><span innerHTML="{{ a2eStrings.citizenshipSection.rulingDesc }}"></span></p>
      </uc-message-banner>
    </uc-subsection>

    <uc-subsection *ngIf="!currentApplicant?.demographic?.validatedCitizenship">
      <uc-subsection-question>
        {{ a2eStrings.citizenshipSection.passportInfo }}
      </uc-subsection-question>
      <uc-file-upload
        [year]="applicationYear"
        title="{{ strings.identityLabel }}"
        categoryCode="IDENTITY"
        usedInTask="true"
      >
      </uc-file-upload>
    </uc-subsection>

    <!-- TODO hide this when user is hydrated -->
    <uc-subsection *ngIf="isNzOrAus(reviewForm.get('citizenship.citizenCategory').value, 'pr')">
      <uc-subsection-question>{{ a2eStrings.citizenshipSection.residentsVisa }}</uc-subsection-question>
      <uc-file-upload
        [year]="applicationYear"
        title="Residents visa"
        categoryCode="VISA"
        usedInTask="true"
      ></uc-file-upload>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section [formGroup]="reviewForm.get('contactDetails')">
    <uc-section-header title="{{ a2eStrings.contactDetailsSection.header }}"> </uc-section-header>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/contact_detail/email_address"
      ></uc-task-hint>
      <uc-form-input>
        <uc-string-input
          testSelector="contact-email"
          class="input-medium"
          label="{{ a2eStrings.contactDetailsSection.emailLabel }}"
          formControlName="email"
          required="yes"
        ></uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-form-input>
        <uc-task-hint
          [task]="task"
          path="/applicant/contact_detail/contact_number"
        ></uc-task-hint>
        <uc-subsection-question [required]="true">{{ phoneStrings.phoneNumberQuestion }}</uc-subsection-question>
        <uc-subsection-minor-text>{{ phoneStrings.mobileHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          formControlName="mobileNum"
          labelText="{{ phoneStrings.mobileLabel }}"
          path="/applicant/contact_detail/mobile_number"
          [task]="task"
          testSelector="contact-mobile"
        >
        </uc-phone-selector>
      </uc-form-input>
      <uc-form-input>
        <uc-subsection-minor-text>{{ phoneStrings.landlineHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          formControlName="landlineNum"
          labelText="{{ phoneStrings.landlineLabel }}"
          path="/applicant/contact_detail/landline_number"
          [task]="task"
          testSelector="contact-landline"
        >
        </uc-phone-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section [formGroup]="reviewForm.get('contactDetails')">
    <uc-section-header title="{{ strings.permanentAddressHeader }}"> </uc-section-header>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/contact_detail/current_address"
      ></uc-task-hint>
      <uc-subsection-question>{{ a2eStrings.contactDetailsSection.addressQuestion }}</uc-subsection-question>
      <uc-form-input>
        <uc-address-selector
          testSelector="contact"
          [task]="task"
          formControlName="address"
        ></uc-address-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>
<uc-form-template>
  <uc-section [formGroup]="reviewForm.get('contactDetails')">
    <uc-section-header title="{{ strings.studyAddressHeader }}">
      <p>
        {{ strings.studyAddressQuestion }}
      </p>
      <uc-button
        testSelector="copy-address"
        class="copy-address"
        (click)="copyPermanentToStudyAddress()"
      >
        {{ strings.copyAddressButton }}
      </uc-button>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/study_contact_detail/current_address"
      ></uc-task-hint>
      <uc-form-input>
        <uc-address-selector
          testSelector="contact-current"
          [required]="false"
          formControlName="studyAddress"
        ></uc-address-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-emergency-contact
    [task]="task"
    [emergencyDetails]="reviewForm.get('emergencyDetails')"
    [copyAddressButtonText]="strings.copyAddressButton"
    (copyAddress)="copyPermanentToEmergencyAddress()"
    [includeUpToDateDeclaration]="true"
  >
  </uc-emergency-contact>
</uc-form-template>

<uc-form-template>
  <uc-section>
    <uc-section-header title="{{ strings.learningNeedsHeader }}"> </uc-section-header>
    <uc-fitness-to-teach
      [task]="task"
      [fitnessToTeachForm]="reviewForm.get('learningNeeds')"
    >
    </uc-fitness-to-teach>
  </uc-section>
</uc-form-template>
