/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import { environment } from '@environment';
import { UserDetail } from '@shared/models/user';
import { UserService } from '@shared/services/user/user.service';

@Injectable()
export class OnlineUserAuthenticatedGuard implements CanActivate, CanActivateChild {
  attemptedRoute: string;
  lastAttemptedRoute: string;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  // Check active B2C user, only when is login through B2C
  isB2CAllowedAccess(): boolean {
    if (this.userService.isB2CLogin) {
      return !!this.userService.getActiveB2CUser();
    } else {
      return true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isCorrectScope(userDetail): boolean {
    const scopeUser = userDetail[environment.scope];
    return !!scopeUser && scopeUser.identifier === userDetail.mainUser.identifier;
  }

  // Follow existing shared user authenticated guard check.
  // Only perform additional B2C related allowed access check when existing shared user authenticated guard return true.
  isAllowedAccess(userDetail: UserDetail, targetUrl: string, queryParams = {}): boolean {
    if (userDetail && !this.isCorrectScope(userDetail)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
      this.userService.logout().catch((err) => {});
      return false;
    }

    if (this.attemptedRoute === null) {
      this.lastAttemptedRoute = targetUrl;
    }

    const currentUser = this.userService.user;
    if (currentUser) {
      if (currentUser.partiallyHydrated) {
        const navigatingToHydratePage = targetUrl.includes(internalUrls.hydrateOverlay[0]);
        if (navigatingToHydratePage) {
          return true && this.isB2CAllowedAccess();
        } else {
          // store route state then navigate to the hydrate overlay route
          this.attemptedRoute = targetUrl;
          this.router.navigate(internalUrls.hydrateOverlay, { queryParams });
          return false;
        }
      }
      if (this.attemptedRoute) {
        this.router.navigateByUrl(
          this.attemptedRoute,
          // eslint-disable-next-line no-inline-comments
          /* Removed unsupported properties by Angular migration: queryParams. */ {},
        );
        this.attemptedRoute = null;
        return false;
      }
    } else {
      this.attemptedRoute = targetUrl;
      this.lastAttemptedRoute = targetUrl;
      this.router.navigate(internalUrls.login, { queryParams });
    }
    return !!currentUser && this.isB2CAllowedAccess();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.userDetail.pipe(
      map((userDetail) => this.isAllowedAccess(userDetail, state.url, route.queryParams)),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
