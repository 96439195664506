import { Injectable } from '@angular/core';
import { combineLatest, filter, firstValueFrom, Observable, Subscription } from 'rxjs';

import { environment } from '@environment';
import { IPLocationService } from '@shared/services/iplocation/iplocation.service';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';

import { UserService } from '../user/user.service';
import { CookieUserActivityStrategyService } from './cookie-user-activity-strategy.service';
import { FirebaseUserActivityStrategyService } from './firebase-user-activity-strategy.service';
import { LocalStorageUserActivityStrategyService } from './localstorage-user-activity-strategy.service';
import { MockUserActivityService } from './user-activity.service.mock';

export interface UserActivity {
  taskSubmissions: IProcessRouteParams[];
  viewedDocuments: string[];
}

export interface ActivityStrategy {
  hasTask(p: IProcessRouteParams): Observable<boolean>;
  addTask(p: IProcessRouteParams): Promise<void>;
  removeAllTasksFor(process: string, year: string): Promise<void>;
  addViewedDocument(id: string): Promise<void>;
  getViewedDocuments(): Observable<string[]>;
  replaceActivity(activity: UserActivity): Promise<void>;
}

@Injectable()
export class UserActivityService implements ActivityStrategy {
  cloudToLocalSyncSubscription: Subscription;

  constructor(
    public cloud: FirebaseUserActivityStrategyService,
    public local: LocalStorageUserActivityStrategyService,
    private cookie: CookieUserActivityStrategyService,
    ipLocationService: IPLocationService,
    userService: UserService,
  ) {
    combineLatest([ipLocationService.isFirebaseRestricted, userService.userDetail]).subscribe(([isRestricted]) => {
      if (!isRestricted) {
        this.cloud = cloud;
        this.startSyncingCloudChangesToLocal();
      } else {
        this.cloud = null;
        this.stopSyncingCloudChangesToLocal();
        this.syncCookieChangesToLocalOnceIfLocalIsEmptyAndCookieIsNot();
      }
    });
  }

  private startSyncingCloudChangesToLocal() {
    this.stopSyncingCloudChangesToLocal();
    this.cloudToLocalSyncSubscription = this.cloud.activity
      .pipe(filter((activity) => !!activity))
      .subscribe((ua) => this.local.replaceActivity(ua));
  }

  private stopSyncingCloudChangesToLocal() {
    this.cloudToLocalSyncSubscription?.unsubscribe();
  }

  private async syncCookieChangesToLocalOnceIfLocalIsEmptyAndCookieIsNot(): Promise<void> {
    const localData = await firstValueFrom(this.local.activity);
    const cookieData = await firstValueFrom(this.cookie.activity);
    if (UserActivityService.activityIsEmpty(localData) && !UserActivityService.activityIsEmpty(cookieData)) {
      await this.local.replaceActivity(cookieData);
    }
  }

  private static activityIsEmpty(activity: UserActivity) {
    return !(activity?.taskSubmissions?.length || activity?.viewedDocuments?.length);
  }

  public hasTask(p: IProcessRouteParams): Observable<boolean> {
    return this.local.hasTask(p);
  }

  public addTask(p: IProcessRouteParams): Promise<void> {
    if (this.cloud) {
      return this.cloud.addTask(p);
    } else {
      return this.local.addTask(p);
    }
  }

  public removeAllTasksFor(process, year): Promise<void> {
    if (this.cloud) {
      return this.cloud.removeAllTasksFor(process, year);
    } else {
      return this.local.removeAllTasksFor(process, year);
    }
  }

  public addViewedDocument(id: string): Promise<void> {
    if (this.cloud) {
      return this.cloud.addViewedDocument(id);
    } else {
      return this.local.addViewedDocument(id);
    }
  }

  public getViewedDocuments(): Observable<string[]> {
    return this.local.getViewedDocuments();
  }

  public replaceActivity(activity: UserActivity): Promise<void> {
    if (this.cloud) {
      return this.cloud.replaceActivity(activity);
    } else {
      return this.local.replaceActivity(activity);
    }
  }
}

export const userActivityFactory = (cloud, local, cookie, ipLocationService, userService) => {
  if (environment.useFakeBackend.userActivity) {
    return new MockUserActivityService();
  }
  return new UserActivityService(cloud, local, cookie, ipLocationService, userService);
};

export const mockBackendUserActivityProvider = {
  provide: UserActivityService,
  useFactory: userActivityFactory,
  deps: [
    FirebaseUserActivityStrategyService,
    LocalStorageUserActivityStrategyService,
    CookieUserActivityStrategyService,
    IPLocationService,
    UserService,
  ],
};
