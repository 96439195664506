import { Component, HostListener, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { TooltipService } from './tooltip.service';
import { PositionService } from './position.service';

function isChildOfElement(child: HTMLElement, target: HTMLElement): boolean {
  if (!child.parentElement) {
    return false;
  }
  if (child === target) {
    return true;
  }
  return isChildOfElement(child.parentElement, target);
}

@Component({
  selector: 'uc-tooltip-content',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipContentComponent implements AfterViewInit {
  @Input() tooltipContent: any;
  @Input() id: number;
  @Input() tooltipRef: ElementRef;
  @Input() tooltipTime: number;

  @ViewChild('templateContent') contentElement: any;

  top: number;
  left: number;
  isVisible = false;
  showBelow = false;

  constructor(private tooltipService: TooltipService, private position: PositionService) {}

  calculateTop(): number {
    const tooltipTop = this.position.getBoundingRect(this.tooltipRef.nativeElement).top;
    const tooltipContentHeight = this.position.getOffsets(this.contentElement.nativeElement).height;

    // if content will display above the fold, display below the element
    // the +85 is so that it can account for the header bar
    if (tooltipContentHeight + 85 > tooltipTop) {
      this.showBelow = true;
      return tooltipTop + window.scrollY;
    }
    return tooltipTop + window.scrollY - tooltipContentHeight;
  }

  calculateLeft(): number {
    const tooltipLeft = this.position.getBoundingRect(this.tooltipRef.nativeElement).left;
    const contentOffsetWidth = this.position.getOffsets(this.contentElement.nativeElement).width;
    const tooltipOffsetWidth = this.position.getOffsets(this.tooltipRef.nativeElement).width;
    return tooltipLeft - contentOffsetWidth / 2 + tooltipOffsetWidth / 2;
  }

  ngAfterViewInit(): void {
    // position based on `ref`
    setTimeout(() => {
      this.left = this.calculateLeft();
      this.top = this.calculateTop();
      this.isVisible = true;
    }, 0);
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(e: MouseEvent): void {
    if (!this.tooltipTime) {
      const isClickOnTriggerElement = isChildOfElement(e.target as HTMLElement, this.tooltipRef.nativeElement);
      const isClickOnContentElement = isChildOfElement(e.target as HTMLElement, this.contentElement.nativeElement);
      if (!isClickOnContentElement && !isClickOnTriggerElement) {
        this.tooltipService.destroyTooltip(this.id);
      }
    }
  }
}
