/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { get, set } from 'lodash-es';
import { combineLatest, of as observableOf, zip as observableZip, Observable, BehaviorSubject } from 'rxjs';
import { map, switchMap, takeUntil, filter, take } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ContinuingEnrolledQualification } from '@shared/models/enrolment';
import { QualificationSummary, EnrolledQualification } from '@shared/models/qualification';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { UCErrorCodes } from '@shared/services/data-service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import {
  UCElementGroup,
  group,
  control,
  booleanToYesNo,
  yesNoToBoolean,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

import { QualificationEnrolment, QualEnrolmentSelectorContext, EnrolmentUpdateEvent } from '../../qualification/model';

/*
 * The qualification task allows a user to create or update an application enrolment.
 * If they have an alternate qual selected, they can also remove this.
 * This component has an EnrolmentSelector component for the user's main and alternate choice.
 * Inside each EnrolmentSelector component is a QualificationEnrolmentSelector. This contains the qual/ subject options form inputs
 * and is displayed once by default, and a second time if the user chooses a concurrent qual.
 */
@Component({
  selector: 'uc-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.scss'],
})
export class QualificationComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() processFilter: string;

  strings = strings.components.tasks.qualification;
  altQualString = strings.components.organisms.enrolmentSelector;
  qualificationData: QualificationSummary[];
  qualificationData$ = new BehaviorSubject<QualificationSummary[]>([]);
  log: Logger;

  enrolments: QualEnrolmentSelectorContext[] = [];
  newEnrolments: ApplicationEnrolment[] = [];
  continuingEnrolments: ContinuingEnrolledQualification[] = [];
  awardEnrolmentEnrolments: QualEnrolmentSelectorContext[] = [];
  fullAwardEnrolments: QualificationEnrolment[] = [];
  taskForm: UntypedFormGroup;
  continuingEnrolmentError = false;
  forceContinue = false;
  totalContinuingEnrolmentCount = 0;
  firstEnrolment: ApplicationEnrolment;
  secondEnrolment: ApplicationEnrolment;

  public isLoading = true;
  applicationYear: string;
  deaggregated = false;
  showAlternateQual = false;

  currentApplication: Application;
  elementGroup: UCElementGroup;
  formGroup: UntypedFormGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _latestEnrolmentState: EnrolmentUpdateEvent[];
  private maxContinuingEnrolments = 2;
  continueAwardOptions = [
    {
      labelText: 'Continue study options',
      value: true,
    },
    {
      labelText: 'Change study options',
      value: false,
    },
  ];

  constructor(
    loggingService: LoggingService,
    private qualificationService: QualificationService,
    private enrolmentService: EnrolmentService,
    private applicationService: ApplicationService,
    private formModel: FormModelMapperService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.log = loggingService.createLogger(this);
    this.applicationYear = applicationService.currentApplicationYear;
  }

  get latestEnrolmentState() {
    return this._latestEnrolmentState;
  }

  set latestEnrolmentState(value) {
    this._latestEnrolmentState = value;
  }

  get headerText() {
    return this.continuingEnrolmentError ? this.strings.header : this.strings.returningHeader;
  }

  get helpText() {
    if (this.processFilter && this.processFilter === 'teaching') {
      return this.strings.teachingDesc;
    } else {
      return '';
    }
  }

  get maxEnrolmentCountError() {
    return this.totalContinuingEnrolmentCount > this.maxContinuingEnrolments;
  }

  get showAlternateQualSelector() {
    return this.showAlternateQual || this.hasSelectedAlternateQual;
  }

  get showAlternateQualBtn() {
    return !this.showAlternateQual && !this.hasSelectedAlternateQual;
  }

  get hasSelectedAlternateQual() {
    return !!this.currentApplication?.alternateQualification;
  }

  createEnrolmentState(applicationEnrolments: ApplicationEnrolment[]): EnrolmentUpdateEvent[] {
    return applicationEnrolments.map((ae) => {
      return { applicationEnrolment: ae, isConcurrent: ae.enrolledQualifications.length > 1 };
    });
  }

  private createForm(): UCElementGroup {
    return group({
      willCompleteAward: control({
        validators: [Validators.required],
        model: 'application',
        path: '/willCompleteAward',
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
    });
  }

  public updateFormValidity() {
    // looks like we're using the form from another class here, so no form updates?
  }

  private getEnrolments(): Observable<QualEnrolmentSelectorContext[]> {
    return this.applicationService.getApplicationEnrolment(this.applicationService.currentApplicationYear).pipe(
      switchMap((aes: ApplicationEnrolment[]) => {
        this.newEnrolments = aes.sort((a, b) => a.priority - b.priority);
        const firstEnrolment = this.newEnrolments[0];
        this.firstEnrolment = firstEnrolment;
        this.secondEnrolment = this.newEnrolments[1];
        if (firstEnrolment && firstEnrolment.didContinueAward != null) {
          this.taskForm.get('didContinueAward').setValue(firstEnrolment.didContinueAward, { emitEvent: false });
        }
        const qualRequests = aes.map((e) => this.getQualEnrolments(e));
        return qualRequests && qualRequests.length ? observableZip(...qualRequests) : observableOf([]);
      }),
    );
  }

  private getAwardEnrolmentsAsQualificationEnrolments(): Observable<QualificationEnrolment[]> {
    return this.enrolmentService.getContinuingEnrolments().pipe(
      switchMap((continuingEnrolments) => {
        if (continuingEnrolments && continuingEnrolments.length) {
          this.continuingEnrolments = continuingEnrolments;
          this.forceContinue = continuingEnrolments.some((ae) => !!ae.forceContinue);
          const qualURIs = continuingEnrolments.map(
            (enrolment) => `qualification/${this.applicationYear}/${enrolment.code}`,
          );

          return this.qualificationService
            .getQualificationsByURIs(this.applicationYear, qualURIs)
            .pipe(map((qualifications) => ({ qualifications: qualifications.qualification, continuingEnrolments })));
        } else {
          return observableOf({ qualifications: [], continuingEnrolments });
        }
      }),
      map(({ qualifications, continuingEnrolments }) => {
        const matchingEnrolQual = continuingEnrolments.map((continuingEnrolment) => {
          return {
            continuingEnrolment,
            qualification: qualifications.find((qual) => qual.code === continuingEnrolment.code),
          };
        });
        return matchingEnrolQual.map((eq) =>
          QualificationEnrolment.createFrom(eq.continuingEnrolment, eq.qualification),
        );
      }),
    );
  }

  private getQualEnrolments(
    e: ApplicationEnrolment,
    awardEnrolments?: QualificationEnrolment[],
  ): Observable<QualEnrolmentSelectorContext> {
    let qualEnrolments;
    if (!awardEnrolments) {
      qualEnrolments = e.enrolledQualifications.map((qualEnrol) => {
        return this.qualificationService
          .getQualification(this.applicationService.currentApplicationYear, qualEnrol.code)
          .pipe(
            map((qual) => {
              return QualificationEnrolment.createFrom(qualEnrol, qual);
            }),
          );
      });
    } else {
      qualEnrolments = awardEnrolments.map((aw) => observableOf(aw));
    }
    if (qualEnrolments.length === 0) {
      return observableOf({
        applicationEnrolment: e,
        firstQualificationEnrolment: null,
        secondQualificationEnrolment: null,
      });
    }
    return combineLatest([...qualEnrolments]).pipe(
      map((qes: QualificationEnrolment[]) => {
        return {
          applicationEnrolment: e,
          firstQualificationEnrolment: qes[0],
          secondQualificationEnrolment: qes[1],
        };
      }),
    );
  }

  ngOnInit() {
    this.elementGroup = this.createForm();
    this.formGroup = this.elementGroup.asControl() as UntypedFormGroup;

    this.taskForm = this.fb.group({
      didContinueAward: [null, Validators.required],
    });

    combineLatest([
      this.getEnrolments(),
      this.getAwardEnrolmentsAsQualificationEnrolments().pipe(filter((x) => !!x)),
      this.qualificationService
        .getQualifications(this.applicationService.currentApplicationYear, this.processFilter)
        .pipe(filter((x) => !!x)),
    ]).subscribe(
      ([enrolments, continuingEnrolments, qualData]) => {
        this.enrolments = enrolments;
        this.qualificationData = qualData;
        this.qualificationData$.next(this.qualificationData);
        this.getQualEnrolments(
          ApplicationEnrolment.createFrom(this.continuingEnrolments),
          continuingEnrolments,
        ).subscribe((aws) => {
          if (!this.enrolments || !this.enrolments.length) {
            this.enrolments = [aws];
          }
        });

        this.totalContinuingEnrolmentCount = continuingEnrolments.length;
        this.fullAwardEnrolments = continuingEnrolments.slice(0, 2);
        this.isLoading = false;

        // don't start watching changes until both enrolment and award enrolments are present
        this.taskForm
          .get('didContinueAward')
          .valueChanges.pipe(filter((x) => x != null))
          .subscribe((val) => {
            const hasEnrolmentsNotContinuing = this.newEnrolments && this.newEnrolments.length && !val;
            if (hasEnrolmentsNotContinuing) {
              this.latestEnrolmentState = this.createEnrolmentState(this.newEnrolments);
            } else if ((this.continuingEnrolments && this.continuingEnrolments.length) || this.forceContinue) {
              this.latestEnrolmentState = this.createEnrolmentState([
                ApplicationEnrolment.createFrom(this.continuingEnrolments),
              ]);
            }
          });

        if (this.forceContinue) {
          this.taskForm.get('didContinueAward').setValue(true);
        }
      },
      (err) => {
        this.log.error('could not get enrolment data', err);
        if (err.code === UCErrorCodes.E404) {
          this.continuingEnrolmentError = true;
          this.taskForm.get('didContinueAward').setValue(false);
        }
        this.isLoading = false;
      },
    );

    this.applicationService.application
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;
        this.formModel.updateFormFromModel(this.elementGroup, null, application);
      });
  }

  onNewQualificationAnswer(data: EnrolmentUpdateEvent[]) {
    this.newEnrolments = data.map((e) => e.applicationEnrolment as ApplicationEnrolment);
    this.latestEnrolmentState = data;
  }

  /**
   * Clean up any concurrent quals that the user has toggled off just before persisting
   *
   * @param enrolmentState
   */
  private removeDisabledConcurrentQuals(enrolmentState: EnrolmentUpdateEvent[]): EnrolmentUpdateEvent[] {
    return enrolmentState.map((enrolmentEvent) => {
      const qualifications = get(enrolmentEvent, 'applicationEnrolment.enrolledQualifications', []);
      if (!enrolmentEvent.isConcurrent && qualifications.length > 1) {
        set(enrolmentEvent, 'applicationEnrolment.enrolledQualifications', qualifications.slice(0, 1));
      }

      return enrolmentEvent;
    });
  }

  addOne() {
    this.showAlternateQual = true;
  }

  alternateQualUpdated(qual: ReferenceData) {
    this.currentApplication.alternateQualification = qual;
  }

  deleteAlternativeQual() {
    this.showAlternateQual = false;
    this.currentApplication.alternateQualification = null;
  }

  update() {
    this.formModel.updateModelFromForm(this.elementGroup, null, this.currentApplication);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);
    if (this.latestEnrolmentState) {
      this.latestEnrolmentState = this.removeDisabledConcurrentQuals(this.latestEnrolmentState);

      // Patch data for any existing application enrolments
      const enrolments = this.latestEnrolmentState.map((e) => e.applicationEnrolment as ApplicationEnrolment);
      const firstEnrolment = enrolments.sort((a, b) => a.displayOrder - b.displayOrder)[0];
      if (firstEnrolment) {
        firstEnrolment.didContinueAward = this.taskForm.get('didContinueAward').value;
      }
      const obs = [updateApplication];

      // if an alt AE was loaded but that has now been removed, call delete on in (cleans up alt choice as you can't remove main choice)
      const hasRemovedAltAE = enrolments.length === 1 && !!get(this.secondEnrolment, 'internalReference');
      const hasReaddedAltAE =
        enrolments.length === 2 && get(this.secondEnrolment, 'internalReference') !== enrolments[1].internalReference;
      if (hasRemovedAltAE || hasReaddedAltAE) {
        obs.push(
          this.applicationService.deleteApplicationEnrolment(
            this.applicationYear,
            this.secondEnrolment.internalReference,
          ),
        );
      }

      if (firstEnrolment && firstEnrolment.didContinueAward && !this.forceContinue) {
        // if continuing an enrolment, clear other, changed AEs and save the continuing one again
        this.newEnrolments.forEach((enr) => {
          if (!!enr.internalReference) {
            obs.push(this.applicationService.deleteApplicationEnrolment(this.applicationYear, enr.internalReference));
          }
        });

        const continuingEnrolledQuals = this.continuingEnrolments.map((ce, i) => {
          const ae = new EnrolledQualification(ce);
          ae.priority = i + 1;
          return ae;
        });

        const continuingApplicationEnrolment = new ApplicationEnrolment({
          year: this.applicationYear,
          enrolledQualifications: continuingEnrolledQuals,
          didContinueAward: true,
          priority: 1,
          displayOrder: 1,
          subjectOptions: {},
          enroledCourses: [],
        });
        obs.push(
          this.applicationService.createApplicationEnrolment(this.applicationYear, continuingApplicationEnrolment),
        );
      } else {
        // for each new application enrolment selection, either create if new or update if choice exists
        enrolments.forEach((en, i) => {
          if (!en.internalReference) {
            obs.push(
              this.applicationService.createApplicationEnrolment(this.applicationYear, en as ApplicationEnrolment),
            );
          } else {
            const newAE = { ...enrolments[i], ...en };
            obs.push(
              this.applicationService.updateApplicationEnrolment(
                this.applicationYear,
                en.internalReference,
                newAE as ApplicationEnrolment,
              ),
            );
          }
        });
      }

      this.sequentialSubscribe(obs);
    } else {
      updateApplication.subscribe(
        () => {
          this.log.info('persisted application');
          this.next.emit();
        },
        (err) => {
          this.errors.emit();
          this.log.error('error updating application state', err);
        },
      );
    }
  }

  sequentialSubscribe(obs: Observable<unknown>[], index = 0) {
    obs[index].pipe(take(1)).subscribe(
      () => {
        if (obs.length > index + 1) {
          this.sequentialSubscribe(obs, index + 1);
        } else {
          this.next.emit();
        }
      },
      (err) => {
        this.errors.emit();
        this.log.error('error updating application state', err);
      },
    );
  }

  getQualTitle(code: string) {
    return this.qualificationData.find((el) => el.code === code).title;
  }
}
