import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'uc-year-selector',
  template: `
    <uc-reference-data-selector
      [testSelector]="testSelector"
      [options]="options"
      [formControl]="control"
    ></uc-reference-data-selector>
  `,
})
export class YearSelectorComponent implements OnInit, OnChanges {
  @Input() baseYear: number;
  @Input() range: number;
  @Input() order: string;
  @Input() control: UntypedFormControl;
  @Input() testSelector = '';

  currentYear = new Date().getFullYear();
  options: { labelText: string; value: string }[];

  ngOnInit() {
    this.createYearOptions();
  }

  ngOnChanges(changes) {
    const hasChangeWeCareAbout = ['baseYear', 'range', 'order'].some((inputName) => changes.hasOwnProperty(inputName));

    if (hasChangeWeCareAbout) {
      this.createYearOptions();
    }
  }

  createYearOptions() {
    const newArr = [];
    this.range = this.range ? this.range : 85;
    this.baseYear = this.baseYear || this.currentYear;
    if (this.order === 'ascending') {
      for (let i = this.baseYear; i < this.baseYear + Number(this.range); i++) {
        const yearObj = { value: i.toString(), labelText: i.toString() };
        newArr.push(yearObj);
      }
    } else {
      for (let i = this.baseYear; i > this.baseYear - Number(this.range); i--) {
        const yearObj = { value: i.toString(), labelText: i.toString() };
        newArr.push(yearObj);
      }
    }
    this.options = newArr;
  }
}
