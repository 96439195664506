import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, AbstractControl, Validators } from '@angular/forms';
import { get } from 'lodash-es';

import { SECONDARY_QUAL_CODES, COUNTRY_OPTIONS, SCHOOLS } from '@constants/academic-history';
import strings from '@constants/strings.constants';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { Task } from '@shared/models/task';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-online-secondary-education',
  templateUrl: './online-secondary-education.component.html',
  styleUrls: ['./online-secondary-education.component.scss'],
})
export class OnlineSecondaryEducationComponent implements OnInit {
  @Input() task: Task;
  @Input() secondaryEducationForm: UntypedFormGroup;
  @Input() secondaryLocationForm: UntypedFormArray;
  @Input() applicationYear: string;
  @Input() highestStudyLevel: string;
  @Input() process: string;
  @Output() addQual = new EventEmitter();
  @Output() removeQual = new EventEmitter();

  log: Logger;
  strings = strings.components.organisms.onlineSecondaryEducation;
  hasInternationalStudy = false;
  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  waitingForResultsOptions = [
    {
      labelText: 'Yes',
      value: false,
    },
    {
      labelText: 'No',
      value: true,
    },
  ];

  countryOptions = [
    {
      labelText: 'Yes',
      value: COUNTRY_OPTIONS.NZL,
    },
    {
      labelText: 'No',
      value: COUNTRY_OPTIONS.OTHER,
    },
  ];

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get educations(): UntypedFormArray {
    return this.secondaryEducationForm.get('educations') as UntypedFormArray;
  }

  get nsnNumber(): AbstractControl {
    return this.secondaryEducationForm.get('nsnNumber');
  }

  get isMicroCredentialProcess(): boolean {
    return this.process === PROCESS_NAMES.MCED || this.process === PROCESS_NAMES.MICRO_CREDENTIAL;
  }

  ngOnInit() {
    this.secondaryLocationForm.controls.forEach((control) => {
      control.valueChanges.subscribe((data) => {
        if (data !== COUNTRY_OPTIONS.NZL) {
          const index = this.secondaryLocationForm.controls.indexOf(control);
          this.educations.at(index).get('country').setValue({ code: undefined });
        }
      });
    });

    this.educations.controls.forEach((control) => {
      this.setValidatorForSecondaryDateAttained(control);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  hasSelectedLocation(index): boolean {
    return index || true;
  }

  isNCEA2(qualNum) {
    const type = this.educations.controls[qualNum].get('type');
    const qualType = get(type, 'value.code');
    return qualType === SECONDARY_QUAL_CODES.NCEA2;
  }

  // eslint-disable-next-line class-methods-use-this
  private setValidatorForSecondaryDateAttained(control) {
    const qualTypeControl = control.get('type');
    const dateAttainedControl = control.get('dateAttained');

    qualTypeControl.valueChanges.subscribe((data) => {
      if (!(data.code === SECONDARY_QUAL_CODES.NO_FORMAL || data.code === SECONDARY_QUAL_CODES.UNKNOWN)) {
        dateAttainedControl.setValidators([Validators.required]);
      } else {
        dateAttainedControl.setValidators([]);
        dateAttainedControl.setValue(undefined);
      }
      control.updateValueAndValidity();
    });
  }

  shouldSecondaryDateAttained(qualNum) {
    const type = this.educations.controls[qualNum].get('type');
    const qualType = get(type, 'value.code');
    return qualType !== SECONDARY_QUAL_CODES.NO_FORMAL && qualType !== SECONDARY_QUAL_CODES.UNKNOWN;
  }

  shouldOverseasSchoolWarning(qualNum) {
    const studiedInNZ = this.isDomestic(qualNum);
    const school = this.educations.controls[qualNum].get('source').value;
    const overseasSchool = get(school, 'code') === SCHOOLS.OVERSEAS;
    return studiedInNZ && school && overseasSchool;
  }

  addQualification() {
    this.addQual.emit({ type: 'secondaryEducation' });
  }

  removeQualification(indexToBeRemoved) {
    this.removeQual.emit(indexToBeRemoved);
  }

  // eslint-disable-next-line class-methods-use-this
  isDomestic(qualNum) {
    return qualNum > 1 || true;
  }

  isSchoolOnList(qualNum) {
    const school = this.educations.controls[qualNum].get('source').value;
    return get(school, 'code') !== SCHOOLS.NZ_SCHOOL_NOT_ON_LIST;
  }
}
