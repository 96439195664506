import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContentSearchBarComponent } from '@shared/components/molecules/content-search-bar/content-search-bar.component';
import { CourseService } from '@shared/services/course/course.service';

export interface CourseSearchParams {
  query?: string;
  qualGroup?: string;
  subjectGroup?: string;
}

@Component({
  selector: 'uc-course-search',
  templateUrl: './course-search.component.html',
  styleUrls: ['./course-search.component.scss'],
})
export class CourseSearchComponent implements OnDestroy {
  @Input() year: string;
  @Input() options: {
    formControlName: string;
    options: { code: { code: string }; name: string; guidanceText?: string }[];
    disabled?: boolean;
  }[];
  @Input() showBlips: boolean;
  @Input() defaultCurriculumGroup: string;
  @Input() defaultSubjectGroup: string;
  @Output() searchResultCount = new EventEmitter<number>();
  @Output() resetSearchResults = new EventEmitter();
  @Output() courseGroupGuidanceText = new EventEmitter();

  @ViewChild(ContentSearchBarComponent) searchBar;

  hasSearched = true;
  private subscriptions: Subscription[] = [];

  constructor(private courseService: CourseService) {}

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => sub && sub.unsubscribe());
    }
  }

  getAllCourses() {
    if (this.hasSearched) {
      this.hasSearched = false;
      this.courseService.clearSearch();
      this.courseService.getAllCourses(this.year).subscribe();
    }
  }

  resetSearch() {
    this.resetSearchResults.emit();
  }

  onSearch(searchParams: CourseSearchParams) {
    if (this.showBlips) {
      return;
    }
    this.hasSearched = true;
    this.showBlips = true;
    this.subscriptions.push(
      this.courseService.queryCourses(this.year, searchParams).subscribe((searchResults) => {
        if (searchResults && searchResults.meta) {
          this.searchResultCount.emit(searchResults.meta.resultCount);
        }
        this.showBlips = false;
      }),
    );
  }

  clearSearchBar() {
    this.searchBar.clearSearch();
  }
}
