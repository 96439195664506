import { inject } from '@angular/core';

import { ModalCoordinatorService } from './modal-coordinator.service';

export const interceptBackButtonForModalsGuard = () => {
  const coordinator = inject(ModalCoordinatorService);
  try {
    coordinator.dismissCurrent();
    return false;
  } catch (e) {
    return true;
  }
};
