import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';
import { of, Observable } from 'rxjs';
import { switchMap, takeUntil, filter } from 'rxjs/operators';

import { environment } from '@environment';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ACTION_LABELS } from '@shared/constants/actions.constants';
import { PROCESS_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import { QUAL_CATEGORIES } from '@shared/constants/categories';
import strings from '@shared/constants/strings.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { Qualification } from '@shared/models/qualification';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { UserTypes } from '@shared/models/user';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';

@Component({
  selector: 'uc-complete-stage',
  templateUrl: './complete-stage.component.html',
  styleUrls: ['./complete-stage.component.scss'],
})
export class CompleteStageComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() stage: Stage;
  @Input() isInternational = false;
  @Input() params: IProcessRouteParams;
  @Input() process: string;
  @Input() stageNumber: number;

  strings = strings.components.tasks.completeStage;
  enrolmentChange: ChangeOfEnrolment;
  ratingForm = new UntypedFormGroup({
    npsRating: new UntypedFormControl(0),
  });
  infoText: { complete: string; completeDoctoral: string; incomplete: string };
  messageBanner: string;
  log: Logger;
  currentApplication: Application;
  hasDoctoralCategory = false;

  constructor(
    private processService: ProcessService,
    private applicationService: ApplicationService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    private qualificationService: QualificationService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get stageComplete(): boolean {
    return this.task && this.task.actionLabel === ACTION_LABELS.SAVE_AND_SUBMIT;
  }

  get infoTextBody(): string {
    return this.stageComplete ? this.getInfoTextType('complete') : this.getInfoTextType('incomplete');
  }

  get messageBannerText(): string {
    return this.messageBanner;
  }

  get showStarRating(): boolean {
    const hideStarProcesses = [PROCESS_NAMES.OFFER_DECISION, PROCESS_NAMES.DEFER_OFFER];
    const hideForCurrentProcess = !!hideStarProcesses.find((el) => el === this.process);
    const isAgent = environment.scope === UserTypes.agent;
    const showForCurrentStage = this.stageNumber === 1 && this.stageComplete;

    return !isAgent && !hideForCurrentProcess && showForCurrentStage && !this.hasEnrolmentChange;
  }

  get hasEnrolmentChange(): boolean {
    return this.enrolmentChange && this.enrolmentChange?.academicYear?.code === this.params.year;
  }

  ngOnInit() {
    const infoStrings = this.getTextConfig();
    this.infoText = infoStrings.infoText({ isInternational: this.isInternational, process: this.process });
    this.messageBanner = infoStrings.messageBanner(this.task.title);

    this.updateNpsRatingForm();
    this.checkForDoctoralCategory();

    this.changeOfEnrolmentService.getChangeOfEnrolment().subscribe((coe) => {
      this.enrolmentChange = coe;
    });
  }

  checkForDoctoralCategory() {
    this.qualificationService.qualification
      .pipe(filter((qualification) => !!qualification))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((qualification: Qualification) => {
        this.hasDoctoralCategory = qualification.categories.some(
          (category: { category: string }) => category.category === QUAL_CATEGORIES.DOCTORAL,
        );
      });
  }

  updateNpsRatingForm() {
    this.applicationService.application
      .pipe(filter((a) => !!a))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;
        this.ratingForm.get('npsRating').setValue(application.npsRating);
      });
  }

  getInfoTextType(stageState: string) {
    const validProcesses = [PROCESS_NAMES.OFFER_DECISION, PROCESS_NAMES.DEFER_OFFER];
    let suffix = '';
    if (this.hasDoctoralCategory && validProcesses.includes(this.process)) {
      suffix = 'Doctoral';
    }
    const infoTextType = `${stageState}${suffix}`;
    return this.infoText[infoTextType];
  }

  getTextConfig() {
    const textForProcess = this.strings.processStrings[this.process] || this.strings.processStrings.default;
    return { ...textForProcess[`stage${this.stageNumber}`] };
  }

  updateFormValidity() {}

  submitEnrolmentsIfRequired(): Observable<void> {
    if (this.params && this.params.stage === STAGE_NAMES.SELECT_COURSES) {
      return this.processService.submitEnrolment(this.params.year);
    } else {
      return of(null);
    }
  }

  submitStageAndApplicationIfRequired(stageComplete: boolean): Observable<Application> {
    if (stageComplete && this.stage.requiresExplicitSubmit && this.params) {
      return this.processService
        .submitStage(this.params.process, this.params.year, this.params.stage)
        .pipe(switchMap(() => this.applicationService.getApplication(this.params.year)));
    } else {
      return of(null);
    }
  }

  submitEnrolmentOnUpdate() {
    const stageComplete = get(this.task, 'actionLabel') === ACTION_LABELS.SAVE_AND_SUBMIT;

    this.submitEnrolmentsIfRequired()
      .pipe(switchMap(() => this.submitStageAndApplicationIfRequired(stageComplete)))
      .subscribe(
        (app) => {
          // WARNING: Horrible hack to prevent the UI's cached object from overwriting
          // the declaration value if it gets changed on the serverside.
          // See US-2108
          if (app) {
            app.declarationAgree = null;
            this.applicationService.application$.next(app);
          }
          this.next.emit();
        },
        (err) => {
          this.log.error(new Error('Error submitting stage'));
          this.errors.emit(err);
        },
      );
  }

  update() {
    if (this.showStarRating) {
      this.currentApplication.npsRating = this.ratingForm.get('npsRating').value;
      this.applicationService.updateApplication(this.currentApplication).subscribe(
        () => this.submitEnrolmentOnUpdate(),
        (err) => {
          this.log.error('Error updating npsRating on application model', err);
        },
      );
    } else {
      this.submitEnrolmentOnUpdate();
    }
  }
}
