import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { Subscription } from 'rxjs';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { UCError } from '@shared/models/errors';
import { UCValidators } from '@shared/models/validators/validators';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss'],
})
export class CreateAccountFormComponent implements OnInit, OnDestroy {
  @Output() goToLogin = new EventEmitter();

  log: Logger;
  createAccountStrings = strings.components.organisms.createAccountForm;
  labelStrings = strings.labels.user;
  nameStrings = strings.components.molecules.fullName;
  createAccountForm: UntypedFormGroup;
  lastNameLabel = this.nameStrings.surnameLabel;
  errorMessages = [];
  privacyStatementLabelText;
  displaySpinner = false;
  duplicateEmailError = false;
  private userSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private logger: LoggingService,
    private userService: UserService,
  ) {
    this.log = logger.createLogger(this);

    // Combine label string and privacy policy link for checkbox label:
    this.privacyStatementLabelText = `${this.createAccountStrings.termsAndConditionsPrompt}
    <a href="${externalUrls.ucPrivacyAndCopyright}" target="_blank" rel="noopener">
    ${this.createAccountStrings.termsAndConditionsLinkText}</a>`;
  }

  goToLoginClick() {
    const email = get(this.createAccountForm.get('email'), 'value');
    this.goToLogin.emit({ email });
  }

  ngOnInit() {
    this.log.info('ngOnInit');

    const addError = (control: AbstractControl, error: Record<string, UCError>) => {
      let errors = control.errors || {};
      errors = Object.assign(errors, error);
      control.setErrors(errors);
    };

    const removeError = (control: AbstractControl, errorCode: string) => {
      let errors = control.errors || {};
      delete errors[errorCode];
      if (Object.keys(errors).length === 0) {
        errors = null;
      }
      control.setErrors(errors);
    };

    this.createAccountForm = this.fb.group({
      email: ['', Validators.required],
      hasAcceptedAgreement: [false, UCValidators.validateTrue],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required, UCValidators.passwordValidator]],
    });

    this.createAccountForm.setValidators((c: UntypedFormGroup) => {
      const passwordControl = c.get('password');
      const passwordConfirmControl = c.get('passwordConfirm');
      if (!passwordControl.value && !passwordConfirmControl.value) {
        return null;
      }

      if (passwordConfirmControl.value && passwordControl.value !== passwordConfirmControl.value) {
        const error = {
          passwordMatchError: this.createAccountStrings.noPasswordMatch,
        };
        addError(passwordConfirmControl, error);
        return error;
      } else {
        removeError(passwordConfirmControl, 'passwordMatchError');
      }

      return null;
    });

    this.userService.validationError.subscribe((err) => {
      this.displaySpinner = false;
      if (err.code === 'auth.emailUnverified') {
        return this.goToLogin.emit();
      }

      if (err.code === 'createAccount.duplicateEmail') {
        this.duplicateEmailError = true;
        const duplicateError = new Error('Duplicate email');
        return this.createAccountForm.get('email').setErrors(duplicateError);
      }

      this.errorMessages = [err];
      if (err.code === 'auth.invalidEmail') {
        addError(this.createAccountForm.get('email'), {
          ucError: err,
        });
      } else if (err.code === 'createAccount.weakPassword') {
        addError(this.createAccountForm.get('password'), {
          ucError: err,
        });
      }
    });

    this.userSubscription = this.userService.currentUser.subscribe((user) => {
      if (user) {
        this.router.navigate(internalUrls.dashboard);
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    this.displaySpinner = false;
  }

  createAccount() {
    this.errorMessages = [];
    this.displaySpinner = true;
    this.userService
      .createAccount(this.createAccountForm.value)
      .catch((err) => this.log.debug('create account failed:', err));
  }
}
