<uc-form-template [formGroup]="cupPage">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/application/cup_how_did_you_hear_about"
      ></uc-task-hint>
      <uc-subsection-question required="yes">{{ strings.howDidYouHear }}</uc-subsection-question>
      <uc-radio-input-group
        formControlName="cupHowDidYouHearAbout"
        type="cup"
        groupName="cup-hear-about"
      ></uc-radio-input-group>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template [formGroup]="cupPage">
  <uc-section>
    <uc-section-header title="{{ strings.personalStatementHeader }}"> </uc-section-header>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/application/rationale"
      ></uc-task-hint>
      <uc-subsection-question>{{ strings.personalStatementDescription }}</uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.maxWords }}</uc-subsection-minor-text>
      <uc-text-area
        testSelector="cup-rationale"
        formControlName="rationale"
      ></uc-text-area>
    </uc-subsection>
  </uc-section>
</uc-form-template>
