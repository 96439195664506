import { isEqual } from 'lodash-es';

import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';

export class Result {
  public type: string;
  public score: string;

  constructor(data: Partial<Result>) {
    Object.assign(this, data);
  }

  static deserialize(payload: Partial<Result>): Result {
    return new Result(payload);
  }

  static serialize(instance: Result): Partial<Result> {
    const clone = { ...instance };
    return snakeifyKeys(clone);
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedAttainedQualification {
  qualification_type: string;
  internal_reference: string;
  type: ReferenceData;
  type_other: string;
  date_attained: string;
  date_started: string;
  source: ReferenceData;
  source_other: string;
  country: ReferenceData;
  attained: boolean;
  verified: boolean;
  result: Result[];
}
/* eslint-enable @typescript-eslint/naming-convention */

export class AttainedQualification {
  public qualificationType: string;
  public internalReference: string;
  public type: ReferenceData;
  public typeOther: string;
  public dateAttained: string;
  public dateStarted: string;
  public source: ReferenceData;
  public sourceOther: string;
  public country: ReferenceData;
  public attained: boolean;
  public verified: boolean;
  public result: Result[];

  constructor(attrs: Partial<AttainedQualification> = {}) {
    const defaults = {
      qualificationType: null,
      internalReference: null,
      type: null,
      typeOther: null,
      dateAttained: null,
      dateStarted: null,
      source: null,
      sourceOther: null,
      country: null,
      attained: null,
      result: [],
      verified: false,
    };
    Object.assign(this, defaults, attrs);
  }

  static deserialize(payload: Partial<SerializedAttainedQualification>): AttainedQualification {
    if (payload === null) {
      return null;
    }
    const aq = camelizeKeys(payload);

    if (payload?.result) {
      aq.result = ['LST', 'RED', 'WRT', 'SPK', 'TOT', 'OVR'].map((type) => ({
        type,
        score: payload.result.find((i) => i.type === type)?.score,
      }));
    }

    if (aq.type) {
      aq.type = ReferenceData.deserialize(aq.type);
    }

    if (aq.country) {
      aq.country = ReferenceData.deserialize(aq.country);
    }
    if (aq.source) {
      aq.source = ReferenceData.deserialize(aq.source);
    }
    return new AttainedQualification(aq);
  }

  static serialize(instance: AttainedQualification): SerializedAttainedQualification {
    if (!instance) {
      return null;
    }

    const hasNoChanges = (qual): boolean => {
      const emptyObj = { ...new AttainedQualification() };
      const qualObj = { ...qual };
      return isEqual(qualObj, emptyObj);
    };

    const clone: SerializedAttainedQualification = snakeifyKeys(instance);

    if (instance.type) {
      clone.type = instance.type.code ? ReferenceData.serialize(instance.type) : null;
    }
    if (instance.result) {
      clone.result = ['LST', 'RED', 'WRT', 'SPK', 'TOT', 'OVR'].map((type) => ({
        type,
        score: instance.result.find((i) => i?.type === type)?.score || null,
      }));
    }
    if (instance.country) {
      clone.country = instance.country.code ? ReferenceData.serialize(instance.country) : null;
    }
    if (instance.source) {
      clone.source = instance.source.code ? ReferenceData.serialize(instance.source) : null;
    }

    if (hasNoChanges(clone)) {
      return null;
    }
    return clone;
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedEnglishQualification extends SerializedAttainedQualification {
  expiry_date: string;
}
/* eslint-disable @typescript-eslint/naming-convention */

export class EnglishQualification extends AttainedQualification {
  expiryDate: string;

  constructor(attrs: Partial<EnglishQualification> = {}) {
    super(attrs);
  }

  static deserialize(payload: Partial<SerializedEnglishQualification>): EnglishQualification {
    return AttainedQualification.deserialize(payload) as EnglishQualification;
  }

  static serialize(payload: EnglishQualification): SerializedEnglishQualification {
    return AttainedQualification.serialize(payload) as SerializedEnglishQualification;
  }
}
