export const mockAwardEnrolments = [
  {
    code: 'BCOM',
    organisation_unit: { code: 'Business and Law, College of' },
    enrolment_award: '5450.346128',
    deaggregated: false,

    primary_options: [
      {
        code: 'Accounting',
        type: 'MAJOR',
      },
    ],
    secondary_options: [
      {
        code: 'Accounting',
        type: 'MINOR',
      },
    ],
    subject_options: {
      '1': [
        {
          code: 'Art History and Theory',
        },
        {
          code: 'Accounting',
        },
      ],
      '2': [
        {
          code: 'Economics1',
        },
        {
          code: 'Economics2',
        },
      ],
    },
  },
  {
    code: 'MTCHGLN',
    organisation_unit: { code: 'Business and Law, College of' },
    enrolment_award: '5450.346128',
    deaggregated: false,
  },
];
