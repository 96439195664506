import { camelizeKeys } from '@shared/helpers/serialization';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';

export class UCProcess {
  public title: string;
  public code: string;
  public description: string;
  public href?: string;
  public stages?: Stage[];
  // Note P.W.: 2017-10-19 current values for fundingType are "International" and "Domestic" and is the canonical way
  // of determining if the student is international. Not sure why it's the "funding" type though /shrug
  public fundingType: string;
  public displayOnSplash: boolean;

  get isInternational() {
    return this.fundingType === 'International';
  }

  static deserializeAll(payload: any, applicationYear?: string): UCProcess[] {
    if (!payload) {
      return [];
    }
    const collection = payload.processes || payload;
    return collection.map((process) => UCProcess.deserialize(process, applicationYear));
  }
  /**
   * NOTE: This accepts an applicationYear parameter, so you can't just do `arr.map(UCProcess.deserialize)`
   *  because `Array.prototype.map` will also pass an `index` value as the applicationYear.
   *
   *  For a collection, use UCProcess.deserializeAll instead for safety.
   */
  static deserialize(payload: any, applicationYear?: string): UCProcess {
    if (payload === null) {
      return null;
    }
    payload = payload.process || payload;
    const process = camelizeKeys(payload);
    let processPath = '/apply/' + process.code;
    if (applicationYear) {
      processPath += '/' + applicationYear;
    }
    if (process.stages) {
      process.stages = payload.stages.map((stage) => {
        return Stage.deserialize(stage, processPath);
      });
    }
    return new UCProcess(process);
  }

  get firstIncompleteStage(): Stage {
    return this.stages.find((s) => s.submitted !== true);
  }

  /**
   * get the stage if it exists,
   * @param stageCode
   * @return Stage or null if not the stage isn't on this process
   */
  public getStage(stageCode: string): Stage {
    return this.stages.find((s) => s.code === stageCode);
  }

  /**
   * get the child task from the process given a stage and task code
   * @param stageCode string
   * @param taskCode string
   * @return Task or null if not found
   */
  public getTask(stageCode: string, taskCode: string): Task {
    const stage = this.getStage(stageCode);
    if (stage) {
      return stage.getTask(taskCode);
    }
    return null;
  }

  get link() {
    return this.href || '/apply/' + this.code;
  }

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class UCProcessSummary {
  public canDelete: boolean;

  static deserialize(payload: any): UCProcessSummary {
    if (payload == null) {
      return null;
    }

    const processSummary = payload.process || payload;
    return new UCProcessSummary(camelizeKeys(processSummary));
  }

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class UCProcessStageSummary {
  public code: string;
  public completeStages: { title: string; code: string }[];
  public title: string;

  static deserialize(payload: any): UCProcessStageSummary {
    if (payload == null) {
      return null;
    }

    const processStageSummary = payload.process || payload;
    return new UCProcessStageSummary(camelizeKeys(processStageSummary));
  }

  get currentStage(): number {
    const completeVisibleStages = this.completeStages.filter((st) => !st.title.match('hidden'));
    return completeVisibleStages.length + 1;
  }

  constructor(data: any) {
    Object.assign(this, data);
  }
}
