import { Component } from '@angular/core';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-online-footer',
  templateUrl: './online-footer.component.html',
  styleUrls: ['./online-footer.component.scss'],
})
export class OnlineFooterComponent {
  footerStrings = strings.components.molecules.footer;
}
