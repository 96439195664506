import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';

export class IncomingExchangeStudyAbroad {
  public studyType: string = null;
  public homeUniversity: string = null;
  public majorSubject: string = null;
  public lengthOfStudy: string = null;
  public studyPlan: string = null;

  static deserialize(payload: any): IncomingExchangeStudyAbroad {
    const data: IncomingExchangeStudyAbroad = camelizeKeys(payload);
    return new IncomingExchangeStudyAbroad(data);
  }

  static serialize(instance: IncomingExchangeStudyAbroad): any {
    const clone = Object.assign(new IncomingExchangeStudyAbroad({}), instance);
    return snakeifyKeys(clone);
  }

  constructor(attrs: any) {
    Object.assign(this, attrs);
  }
}
