import { AbstractControl, Validators, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';

import { ReferenceData } from '../reference-data';

export class UCValidators {
  static checkDateFormat(date: string) {
    return date && DateTime.fromISO(date).isValid;
  }

  static checkTimeFormat(time: string) {
    return time && DateTime.fromFormat(time, 'HH:mm').isValid;
  }

  static validateTrue(control: AbstractControl) {
    if (!control.value) {
      return {
        didFailValidateTrue: true,
      };
    }
    return null;
  }

  static passwordValidator(control: AbstractControl) {
    return Validators.minLength(6)(control);
  }

  static notNullString(control: AbstractControl) {
    if (!!control.value && control.value !== 'null') {
      return null;
    }

    return {
      didFailNotNullValidation: true,
    };
  }

  static validateDate(control: AbstractControl) {
    const { value } = control;
    if (UCValidators.checkDateFormat(value) && DateTime.fromISO(control.value).isValid) {
      return null;
    }
    return {
      didFailValidateDate: {
        code: 'dates.invalid',
      },
    };
  }

  static validateTime(control: AbstractControl) {
    const { value } = control;
    if (UCValidators.checkTimeFormat(value)) {
      return null;
    }
    return {
      didFailValidateDateTime: {
        code: 'time.invalid',
      },
    };
  }

  static createDateRangeValidator(startDate: Date, endDate: Date): ValidatorFn {
    return (c: AbstractControl) => {
      const currentDate = DateTime.fromISO(c.value);
      if (DateTime.fromJSDate(startDate) <= currentDate && currentDate <= DateTime.fromJSDate(endDate)) {
        return null;
      }
      return {
        didFailValidateDateRange: {
          code: 'dates.range',
        },
      };
    };
  }

  /**
   * Validate that all controls in a group have a value
   *
   * @param group
   */
  static validateGroupRequired(group: UntypedFormGroup) {
    const emptyControls = Object.values(group.controls).filter((control) => !control.value);
    if (emptyControls.length === 0) {
      return null;
    }
    return {
      didFailGroupRequired: {
        code: 'group',
      },
    };
  }

  // usage: new FormControl('', UCValidators.validateAllChecked(options))
  static validateAllChecked(checkboxGroupOptions) {
    return (formControl) => {
      const val = formControl.value;
      if (val && val.length === checkboxGroupOptions.length) {
        return null;
      } else {
        return {
          didFailCheckboxGroupValidation: true,
        };
      }
    };
  }

  static validateMax(max: number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length > max) {
        return { minLengthArray: { valid: false } };
      }
      return null;
    };
  }

  static phonePresenceValidator(control: AbstractControl) {
    if (get(control, 'value.country.code') && get(control, 'value.number')) {
      return null;
    }
    return {
      didFailValidatePhonePresence: true,
    };
  }

  // eslint-disable-next-line complexity
  static addressValidator(group: UntypedFormGroup) {
    const val = group.value;
    if (val.line1 && val.country && val.town && val.postcode) {
      return null;
    }
    return {
      didFailAddressValidation: true,
    };
  }

  static validateName(control: AbstractControl) {
    const name = control.value;
    if (get(name, 'firstName') && get(name, 'surname')) {
      return null;
    }

    return {
      didFailValidateNamePresence: true,
    };
  }

  static createWordCountValidator(maxWordCount: number) {
    return (control: AbstractControl) => {
      const val = control.value;
      if (!val) {
        return null;
      }

      const words = val.split(' ').length;
      if (words < maxWordCount) {
        return null;
      }

      return {
        didFailWordCount: {
          maxWordCount,
          wordCount: words,
        },
      };
    };
  }

  static validateYear(control: AbstractControl) {
    const valid = !!String(control.value).match(/^\d{4}$/);
    return valid ? null : { didFailYearValidation: true };
  }

  static nzAlternatePhoneValidator(control: AbstractControl) {
    const mobileValidation = UCValidators.nzMobileValidator(control);
    const landlineValidation = UCValidators.nzLandlineValidator(control);

    if (mobileValidation && landlineValidation) {
      return {
        didFailNZPhoneValidation: true,
      };
    }
    return null;
  }

  // eslint-disable-next-line complexity
  static nzMobileValidator(control: AbstractControl) {
    const reg = /^(02|2)/;
    const countryCode = get(control, 'value.country.code');
    const numberValue = get(control, 'value.number');

    if (countryCode !== '+64' || !numberValue || (countryCode === '+64' && numberValue.match(reg))) {
      return null;
    }
    return {
      didFailNZMobileValidation: true,
    };
  }

  // eslint-disable-next-line complexity
  static nzLandlineValidator(control: AbstractControl) {
    const reg = /^0?[346-9]/;
    const countryCode = get(control, 'value.country.code');
    const numberValue = get(control, 'value.number');
    if (countryCode !== '+64' || !numberValue || (countryCode === '+64' && numberValue.match(reg))) {
      return null;
    }
    return {
      didFailNZLandlineValidation: true,
    };
  }

  static completePhoneValidator(control: AbstractControl) {
    const country = get(control, 'value.country.code');
    const numberValue = get(control, 'value.number');
    const err = { didFailCompletePhone: true };

    if (numberValue) {
      return country ? null : err;
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static uniqueInSet(set: Set<any>) {
    return (control: AbstractControl) => {
      if (set.has(control.value)) {
        return {
          didFailUniqueInSetValidation: true,
        };
      }
      return null;
    };
  }

  // eslint-disable-next-line complexity
  static alphanumericString(control: AbstractControl) {
    const val = control.value;
    if (!val) {
      return null;
    }

    const valid = typeof val === 'string' && !!val.match(/^\w*$/);
    if (valid) {
      return null;
    } else {
      return {
        didFailAlphanumericString: true,
      };
    }
  }

  static decimalValidator(numLength = 3, decimalLength = 2) {
    // eslint-disable-next-line complexity
    return (control: AbstractControl) => {
      const val = control.value;
      if (!val) {
        return null;
      }

      const reg = new RegExp(`^[0-9]{1,${numLength}}(\\.[0-9]{1,${decimalLength}})?$`);
      const valid = typeof val === 'string' && !!val.match(reg);
      if (valid) {
        return null;
      } else {
        return {
          didFailDecimalString: true,
        };
      }
    };
  }

  static refdataCodeValidator(control: AbstractControl) {
    const valid = !!get(control, 'value.code');
    return valid ? null : { didFailRefdataRequired: true };
  }

  // eslint-disable-next-line complexity
  static nzDriversLicenseValidator(control: AbstractControl) {
    const val = control.value;
    const regex = /\b[a-zA-Z]{2}\d{6}\b/;
    if (!val) {
      return null;
    }

    const valid = typeof val === 'string' && !!val.match(regex);
    if (valid) {
      return null;
    } else {
      return {
        didFailNzDriversLicenseNumber: true,
      };
    }
  }

  // eslint-disable-next-line complexity
  static nzPassportValidator(control: AbstractControl) {
    const val = control.value;
    const regex = /\b[a-zA-Z][a-zA-Z]?\d{6}\b/;
    if (!val) {
      return null;
    }

    const valid = typeof val === 'string' && !!val.match(regex);
    if (valid) {
      return null;
    } else {
      return {
        didFailNzPassportNumber: true,
      };
    }
  }

  static datePickerValidator(control: AbstractControl) {
    if (!!control.value) {
      return null;
    } else {
      return {
        didFailDatePickerValidator: true,
      };
    }
  }

  static iwiValidator(control: AbstractControl) {
    const val = control.value;
    let resultFailed = false;
    if (val?.length > 0) {
      val.forEach((itemValue) => {
        if (!(itemValue instanceof ReferenceData)) {
          resultFailed = true;
        }
      });
    }
    if (resultFailed) {
      return {
        didFailIwiValidation: true,
      };
    } else {
      return null;
    }
  }

  static whitespaceValidator(control: AbstractControl) {
    if (control.value?.trim().length) {
      return null;
    } else {
      return {
        didFailWhitespaceValidation: true,
      };
    }
  }

  static validateNameWithoutWhitespace(control: AbstractControl) {
    const name = control.value;
    if (get(name, 'firstName')?.trim().length && get(name, 'surname')?.trim().length) {
      return null;
    } else {
      return {
        didFailNameValidation: true,
      };
    }
  }

  // eslint-disable-next-line complexity
  static addressWithoutWhitespaceValidator(group: UntypedFormGroup) {
    const val = group.value;
    if (val.line1?.trim().length && val.country && val.town?.trim().length && val.postcode?.trim().length) {
      return null;
    }
    return {
      didFailAddressValidation: true,
    };
  }

  static maxCharactersExcludingFormattingValidator(maxLength: number) {
    return (control: AbstractControl) => {
      const element = document.createElement('div');
      element.innerHTML = control.value;
      const plainTextValue = element.innerText;
      if (plainTextValue.trim().length > maxLength) {
        return {
          didFailMaxCharacterExcludingFormattingValidation: true,
        };
      }
      return null;
    };
  }

  static requiredFieldExcludingFormattingValidator(control: AbstractControl) {
    const element = document.createElement('div');
    element.innerHTML = control.value;
    const plainTextValue = element.innerText;
    if (plainTextValue.trim().length) {
      return null;
    } else {
      return {
        didFailRequiredFieldExcludingFormattingValidation: true,
      };
    }
  }

  static fileNameValidator(control: AbstractControl) {
    const invalidSubstrings = ['..', '/', '\\'];
    const fileName = control.value;

    for (const invalidSubstring of invalidSubstrings) {
      if (fileName?.includes(invalidSubstring)) {
        return {
          didFailFileNameValidation: true,
        };
      }
    }

    return null;
  }

  static validateEndDateGreaterThanStartDate(comparedControlName: string) {
    return (control: AbstractControl) => {
      if (!control.parent) {
        return null;
      }

      const comparedControl = control.parent.get(comparedControlName);
      const controlValue = control.value;
      const comparedControlValue = comparedControl?.value;

      if (!controlValue || !comparedControlValue) {
        return null;
      }

      if (!control.dirty && !comparedControl.dirty) {
        return null;
      }

      const startDate = new Date(comparedControlValue);
      const endDate = new Date(controlValue);

      if (startDate > endDate) {
        return { invalidStartEndDate: true };
      }
      return null;
    };
  }
}
