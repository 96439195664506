/* eslint-disable complexity */
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { Address, AddressModel, SerializedAddress } from '@shared/models/address';
import { Phone, SerializedPhone } from '@shared/models/phone';

export enum ContactType {
  emergencyContactDetail = 'emergencyContactDetail',
  contactDetail = 'contactDetail',
  studyContactDetail = 'studyContactDetail',
}

export const contactDetailsData = {
  [ContactType.contactDetail]: {
    path: 'contactDetail',
    actionButtons: [ContactType.emergencyContactDetail],
  },
  [ContactType.emergencyContactDetail]: {
    path: 'emergencyContactDetail',
    actionButtons: [ContactType.contactDetail],
  },
  [ContactType.studyContactDetail]: {
    path: 'studyContactDetail',
    actionButtons: [ContactType.contactDetail, ContactType.emergencyContactDetail],
  },
};

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedContact {
  email_address: string;
  mobile_number: SerializedPhone;
  landline_number: SerializedPhone;
  current_address: SerializedAddress;
}
/* eslint-enable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/naming-convention */
export interface EmergencySerializedContact {
  email_address: string;
  mobile_number: SerializedPhone;
  alternate_phone: SerializedPhone;
  current_address: SerializedAddress;
}
/* eslint-enable @typescript-eslint/naming-convention */
export class Contact {
  public emailAddress: string;
  public mobileNumber: Phone;
  public landlineNumber: Phone;
  public currentAddress: Address;

  constructor(rawContact) {
    Object.assign(this, rawContact);
  }

  static deserialize(payload: Partial<SerializedContact>): Contact {
    if (!payload) {
      return null;
    }

    const contact: Contact = camelizeKeys(payload);
    if (payload.mobile_number) {
      contact.mobileNumber = Phone.deserialize(payload.mobile_number);
    }
    if (payload.landline_number) {
      contact.landlineNumber = Phone.deserialize(payload.landline_number);
    }
    if (payload.current_address) {
      contact.currentAddress = AddressModel.deserialize(payload.current_address);
    }

    return new Contact(contact);
  }

  static serialize(instance: Contact): SerializedContact {
    if (!instance) {
      return null;
    }

    const clone: SerializedContact = snakeifyKeys(instance);

    if (instance.mobileNumber) {
      clone.mobile_number = Phone.serialize(instance.mobileNumber);
    }
    if (instance.landlineNumber) {
      clone.landline_number = Phone.serialize(instance.landlineNumber);
    }
    if (instance.currentAddress) {
      clone.current_address = AddressModel.serialize(instance.currentAddress);
    }

    return clone;
  }
}

export class EmergencyContact {
  public emailAddress: string;
  public mobileNumber: Phone;
  public alternatePhone: Phone;
  public currentAddress: Address;

  constructor(rawContact) {
    Object.assign(this, rawContact);
  }

  static deserialize(payload: Partial<EmergencySerializedContact>): EmergencyContact {
    if (!payload) {
      return null;
    }

    const contact: EmergencyContact = camelizeKeys(payload);
    if (payload.mobile_number) {
      contact.mobileNumber = Phone.deserialize(payload.mobile_number);
    }
    if (payload.alternate_phone) {
      contact.alternatePhone = Phone.deserialize(payload.alternate_phone);
    }
    if (payload.current_address) {
      contact.currentAddress = AddressModel.deserialize(payload.current_address);
    }

    return new EmergencyContact(contact);
  }

  static serialize(instance: EmergencyContact): EmergencySerializedContact {
    if (!instance) {
      return null;
    }

    const clone: EmergencySerializedContact = snakeifyKeys(instance);

    if (instance.mobileNumber) {
      clone.mobile_number = Phone.serialize(instance.mobileNumber);
    }
    if (instance.alternatePhone) {
      clone.alternate_phone = Phone.serialize(instance.alternatePhone);
    }
    if (instance.currentAddress) {
      clone.current_address = AddressModel.serialize(instance.currentAddress);
    }

    return clone;
  }
}
