<ng-container *ngFor="let option of subjectOptions">
  <ng-container *ngIf="getSubjectOptions(option.level)">
    <div class="enrolment__answers-wrapper">
      <div class="tag-list">
        <div class="major-title" [class.crop-long-text]="cropLongText">
          <strong>{{ option.label }}: </strong>
          <span title="{{ getSubjectOptions(option.level) }}">{{ getSubjectOptions(option.level) }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
