import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { UcMaterialModule } from '@shared/uc-material/uc-material.module';

import { OnlineButtonComponent } from './online-button/online-button.component';
import { OnlineDisabledAtomStateComponent } from './online-disabled-atom-state/online-disabled-atom-state.component';
import { OnlineStatusPillComponent } from './online-status-pill/online-status-pill.component';
import { OnlineTabComponent } from './online-tab/online-tab.component';
import { OnlineTabsComponent } from './online-tabs/online-tabs.component';

@NgModule({
  declarations: [
    OnlineButtonComponent,
    OnlineDisabledAtomStateComponent,
    OnlineTabsComponent,
    OnlineTabComponent,
    OnlineStatusPillComponent,
  ],
  imports: [AtomsModule, ReactiveFormsModule, RouterModule, CommonModule, UcMaterialModule],
  exports: [
    OnlineButtonComponent,
    OnlineDisabledAtomStateComponent,
    OnlineTabsComponent,
    OnlineTabComponent,
    OnlineStatusPillComponent,
  ],
  providers: [],
})
export class OnlineAtomsModule {}
