import { Component, Input, Output, EventEmitter } from '@angular/core';

import { FlashMessage } from '@shared/services/flash-message/flash-message.service';

const SUPPORTED_TYPES = ['subtle', 'error', 'info', 'warning', 'success', 'system'];

@Component({
  selector: 'uc-message-banner',
  template: `
    <div
      [class]="['banner', 'banner-' + type, showCloseButton ? 'closable' : '']"
      *ngIf="showMessage"
    >
      <div class="banner-content body-font-semi">
        <ng-content></ng-content>
      </div>
      <uc-icon
        *ngIf="showCloseButton"
        iconFill="$colorWhite"
        class="dismiss"
        (click)="close()"
        iconWidth="12"
        iconHeight="12"
        iconType="close"
      ></uc-icon>
    </div>
  `,
  styleUrls: ['./message-banner.component.scss'],
})
export class MessageBannerComponent {
  @Input() showCloseButton = false;
  @Input() message?: FlashMessage;
  @Output() closeMessage = new EventEmitter();

  hide = false;
  private _type = 'subtle';

  @Input()
  set type(value) {
    const doesSupportValue = SUPPORTED_TYPES.find((t) => t === value);
    if (!value) {
      this._type = 'subtle';
    } else if (!doesSupportValue) {
      throw new Error(`Message banner component only supports ${JSON.stringify(SUPPORTED_TYPES)}`);
    } else {
      this._type = value;
    }
  }

  get type() {
    return this._type;
  }

  get showMessage() {
    return !this.hide;
  }

  close() {
    if (this.message) {
      this.closeMessage.emit(this.message);
    } else {
      this.hide = true;
    }
  }
}
