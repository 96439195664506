import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { internalUrls } from '@shared/constants/internalUrls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OutageGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  isInOutage = () => this.userService.isInOutage;

  isAllowedAccess(url: string): Observable<boolean> {
    const navigatingToOutage = url === '/outage';
    return this.isInOutage().pipe(
      map((inOutage) => {
        if (inOutage && navigatingToOutage) {
          return true;
        } else {
          this.router.navigate(internalUrls.dashboard);
        }
      }),
    );
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAllowedAccess(state.url);
  }
}
