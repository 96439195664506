import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ASSOCIATED_PERSON_TYPE } from '@shared/constants/states.constants';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { UCError } from '@shared/models/errors';
import { EnrolledQualification } from '@shared/models/qualification';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  group,
  control,
  UCElementGroup,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  starPage: UntypedFormGroup;
  starForm: UCElementGroup;
  strings = strings.components.tasks.star;

  private currentApplicant: Applicant;
  currentApplication: Application;
  private applicationEnrolment: ApplicationEnrolment[];
  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  private createForm(): UCElementGroup {
    const locationToShowHide = (v) => {
      const location = get(v, 'code');
      switch (location) {
        case 'Distance':
          return 'show';
        case 'Christchurch':
          return 'hide';
        default:
          return null;
      }
    };
    const showHideToLocation = (c: UntypedFormControl) => {
      switch (c.value) {
        case 'show':
          return new ReferenceData({ code: 'Distance' });
        case 'hide':
          return new ReferenceData({ code: 'Christchurch' });
      }
    };

    return group({
      lastSchoolAttended: control({
        model: 'applicant',
        path: '/demographic/moeLastSchoolAttended',
        validators: [Validators.required],
      }),
      nsnNumber: control({ model: 'applicant', path: '/identity/nsnNumber' }),
      starCoordinator: group({
        type: control({
          model: 'application',
          path: '/associatedPerson/star_coordinator/type',
          defaultState: new ReferenceData({ code: 'star_coordinator' }),
        }),
        name: control({
          model: 'application',
          validators: [Validators.required],
          path: '/associatedPerson/star_coordinator/name',
        }),
        email: control({
          model: 'application',
          validators: [Validators.required, Validators.email],
          path: '/associatedPerson/star_coordinator/email',
        }),
      }),
      starSupport: group({
        type: control({
          model: 'application',
          path: '/associatedPerson/star_course_support/type',
          defaultState: new ReferenceData({ code: 'star_course_support' }),
        }),
        name: control({
          model: 'application',
          validators: [Validators.required],
          path: '/associatedPerson/star_course_support/name',
        }),
        email: control({
          model: 'application',
          validators: [Validators.required, Validators.email],
          path: '/associatedPerson/star_course_support/email',
        }),
      }),
      studyLocation: control({
        model: 'application',
        path: '/studyLocation',
        validators: [Validators.required],
        inMap: locationToShowHide,
        outMap: showHideToLocation,
      }),
      studyStart: control({
        model: 'application',
        path: '/studyStart',
        validators: [Validators.required],
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
    });
  }

  ngOnInit() {
    this.starForm = this.createForm();
    this.starPage = this.starForm.asControl() as UntypedFormGroup;

    observableCombineLatest([
      this.applicantService.applicant.pipe(filter((a) => !!a)),
      this.applicationService.application.pipe(filter((a) => !!a)),
      this.applicationService.getApplicationEnrolment(this.applicationYear),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application, ae]: [Applicant, Application, ApplicationEnrolment[]]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.applicationEnrolment = ae;
        this.formModel.updateFormFromModel(this.starForm, this.currentApplicant, this.currentApplication);
      });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.starForm);
  }

  public createStarEnrolment() {
    const starQual = new EnrolledQualification({ code: 'STAR', priority: 1 });
    return new ApplicationEnrolment({
      priority: 1,
      displayOrder: 1,
      enrolledQualifications: [starQual],
      subjectOptions: {},
      enroledCourses: [],
    });
  }

  public update() {
    this.formModel.updateModelFromForm(this.starForm, this.currentApplicant, this.currentApplication);

    if (this.starForm.controls.studyLocation.control.value === 'hide') {
      delete this.currentApplication.associatedPerson[ASSOCIATED_PERSON_TYPE.STAR_SUPPORT];
    }

    const starEnrolment = this.createStarEnrolment();

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    let applicationEnrolmentOb;
    const starEnrolledQual = this.applicationEnrolment.find((ae) => {
      return ae.enrolledQualifications.find((eq) => eq.code === 'STAR');
    });
    if (!!starEnrolledQual) {
      applicationEnrolmentOb = this.applicationService.updateApplicationEnrolment(
        this.applicationYear,
        starEnrolledQual.internalReference,
        starEnrolledQual,
      );
    } else {
      applicationEnrolmentOb = this.applicationService.createApplicationEnrolment(this.applicationYear, starEnrolment);
    }

    observableCombineLatest([updateApplicant, updateApplication]).subscribe({
      next: ([applicant, application]: [Applicant, Application]) => {
        if (applicant && application) {
          this.log.info('updated applicant and application');
          applicationEnrolmentOb.subscribe(() => {
            this.log.info('updated STAR enrolment');
            this.next.emit();
          });
        }
      },
      error: (error: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating applica(nt|tion) or creating STAR enrolment:', error);
      },
    });
  }
}
