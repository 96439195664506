import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { Address, AddressModel } from '@shared/models/address';
import { countryList } from '@shared/services/country-list';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export interface MockPlacesData {
  predictionInputs: Record<string, google.maps.places.AutocompletePrediction[]>;
  getDetailsInputs: Record<string, Address>;
}

@Injectable()
export class PlacesService {
  private _autoCompleteService: google.maps.places.AutocompleteService;
  private _googlePlacesService: google.maps.places.PlacesService;
  log: Logger;

  constructor(private loggingService: LoggingService) {
    this.log = this.loggingService.createLogger(this);
  }

  get autoCompleteService() {
    if (!this._autoCompleteService && !this.disabled) {
      this._autoCompleteService = new google.maps.places.AutocompleteService();
    }
    return this._autoCompleteService;
  }

  get googlePlacesService() {
    if (!this._googlePlacesService && !this.disabled) {
      this._googlePlacesService = new google.maps.places.PlacesService(document.createElement('div'));
    }
    return this._googlePlacesService;
  }

  get disabled(): boolean {
    return !window.google;
  }

  public getPlacePredictions(input: string): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable<google.maps.places.AutocompletePrediction[]>((observer) => {
      if (input === '' || this.disabled) {
        observer.next([]);
        observer.complete();
        return;
      }

      const centerOfNZ = new google.maps.LatLng({ lat: -41, lng: 174 });
      this.autoCompleteService.getPlacePredictions(
        {
          input,
          locationBias: new google.maps.Circle({
            center: centerOfNZ,
            radius: 900,
          }),
        },
        (predictions, status) => {
          if (status === 'OK') {
            observer.next(predictions);
          } else if (status === 'ZERO_RESULTS') {
            observer.next([]);
          } else {
            observer.error(status);
          }
          observer.complete();
        },
      );
    });
  }

  public getDetails(placeId: string): Observable<Address> {
    return new Observable<google.maps.places.PlaceResult>((observer) => {
      if (this.disabled) {
        observer.next(null);
        observer.complete();
        return;
      }
      this.googlePlacesService.getDetails({ placeId }, (place, status) => {
        if (status === 'OK') {
          observer.next(place);
        } else {
          observer.error(place);
        }
        observer.complete();
      });
    }).pipe(
      map((place: google.maps.places.PlaceResult) => {
        return this.createAddressFromPlacesApi(place);
      }),
    );
  }

  private createAddressFromPlacesApi(place: google.maps.places.PlaceResult): Address {
    const findAddressField = (type: string): google.maps.GeocoderAddressComponent => {
      const options = place.address_components.filter((c) => c.types.find((t) => t === type));
      if (options.length > 1) {
        this.log.warn('ambiguous address component found when looking for ', type, ' in ', place.address_components);
      }
      if (options[0]) {
        return options[0];
      }
      return { long_name: '', short_name: '', types: [''] };
    };

    const postcode = findAddressField('postal_code').long_name;
    let country = findAddressField('country').short_name;
    const line2 = country === 'NZ' ? findAddressField('sublocality').long_name || null : null;
    const town = findAddressField('locality').long_name || findAddressField('sublocality_level_1').long_name;
    const region =
      findAddressField('postal_town').long_name ||
      findAddressField('administrative_area_level_2').long_name ||
      findAddressField('administrative_area_level_1').long_name;
    const line1 = place.name || `${findAddressField('street_number').long_name} ${findAddressField('route').long_name}`;

    const latitude = place.geometry?.location?.lat();
    const longitude = place.geometry?.location?.lng();
    country = countryList[country];

    return AddressModel.deserialize({
      line_1: line1,
      line_2: line2,
      city: '',
      town,
      region,
      postcode,
      country: { code: country },
      latitude,
      longitude,
    });
  }
}

export class MockPlacesService {
  /**
   * Construct a MockPlacesService by passing in an object that maps input strings to output, and placeID's to places
   */
  constructor(private mockData?: MockPlacesData) {
    if (!mockData) {
      this.mockData = {
        predictionInputs: {},
        getDetailsInputs: {},
      };
    }
  }

  public getPlacePredictions(input: string): Observable<google.maps.places.AutocompletePrediction[]> {
    const predicitons = this.mockData.predictionInputs[input];
    return observableOf(predicitons);
  }

  public getDetails(placeId: string): Observable<Address> {
    const addy = this.mockData.getDetailsInputs[placeId];
    if (!addy) {
      throw new Error(`Get details function should be able to lookup details for value: ${placeId}`);
    }
    return observableOf(addy);
  }
}

export const placesServiceFactory = (loggingService) => {
  if (environment.useFakeBackend.places) {
    return new MockPlacesService();
  } else {
    return new PlacesService(loggingService);
  }
};

export const placesServiceProvider = {
  provide: PlacesService,
  useFactory: placesServiceFactory,
  deps: [LoggingService],
};
