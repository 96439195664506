import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { QualificationModule } from '@shared/applicant/qualification/qualification.module';
import { AcademicHistoryComponent } from '@shared/applicant/tasks/academic-history/academic-history.component';
import { AesAdmissionComponent } from '@shared/applicant/tasks/aes-admission/aes-admission.component';
import { ApplicationToEnrolComponent } from '@shared/applicant/tasks/application-to-enrol/application-to-enrol.component';
import { CAComplianceComponent } from '@shared/applicant/tasks/ca-compliance/ca-compliance.component';
import { CieAdmissionComponent } from '@shared/applicant/tasks/cie-admission/cie-admission.component';
import { CourseSelectionOverviewComponent } from '@shared/applicant/tasks/course-selection-overview/course-selection-overview.component';
import { CupComponent } from '@shared/applicant/tasks/cup/cup.component';
import { DeferOfferComponent } from '@shared/applicant/tasks/defer-offer/defer-offer.component';
import { EnglishProficiencyComponent } from '@shared/applicant/tasks/english-proficiency/english-proficiency.component';
import { EnrolmentChangeDeclarationComponent } from '@shared/applicant/tasks/enrolment-change-declaration/enrolment-change-declaration.component';
import { EnrolmentDeclarationComponent } from '@shared/applicant/tasks/enrolment-declaration/enrolment-declaration.component';
import { ExchangeStudyAbroadComponent } from '@shared/applicant/tasks/exchange-study-abroad/exchange-study-abroad.component';
import { FinaliseEnrolmentComponent } from '@shared/applicant/tasks/finalise-enrolment/finalise-enrolment.component';
import { FinancialInformationComponent } from '@shared/applicant/tasks/financial-information/financial-information.component';
import { FutureYearApplyComponent } from '@shared/applicant/tasks/future-year-apply/future-year-apply.component';
import { IbAdmissionComponent } from '@shared/applicant/tasks/ib-admission/ib-admission.component';
import { InternationalStudentInsuranceComponent } from '@shared/applicant/tasks/international-student-insurance/international-student-insurance.component';
import { PoliceCheckComponent } from '@shared/applicant/tasks/police-check/police-check.component';
import { PostgradIntentionsComponent } from '@shared/applicant/tasks/postgrad-intentions/postgrad-intentions.component';
import { QualificationComponent } from '@shared/applicant/tasks/qualification/qualification.component';
import { ResolveAccountComponent } from '@shared/applicant/tasks/resolve-account/resolve-account.component';
import { ResolveOfferComponent } from '@shared/applicant/tasks/resolve-offer/resolve-offer.component';
import { ReviewDetailsComponent } from '@shared/applicant/tasks/review-details/review-details.component';
import { StudentDeclarationComponent } from '@shared/applicant/tasks/student-declaration/student-declaration.component';
import { StudyIntentionsComponent } from '@shared/applicant/tasks/study-intentions/study-intentions.component';
import { TeachingApplicationComponent } from '@shared/applicant/tasks/teaching-application/teaching-application.component';
import { TeachingQualificationComponent } from '@shared/applicant/tasks/teaching-qualification/teaching-qualification.component';
import { UcswHandoffComponent } from '@shared/applicant/tasks/ucsw-handoff/ucsw-handoff.component';
import { UeAdmissionComponent } from '@shared/applicant/tasks/ue-admission/ue-admission.component';
import { UniversityAdmissionComponent } from '@shared/applicant/tasks/university-admission/university-admission.component';
import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { AcademicHistoryMcComponent } from '@shared/components/templates/academic-history-mc/academic-history-mc.component';
import { PreProcessPageComponent } from '@shared/components/templates/pre-process-page/pre-process-page.component';
import { ProcessPageComponent } from '@shared/components/templates/process-page/process-page.component';
import { SharedTemplatesModule } from '@shared/components/templates/templates.module';
import { PreProcessResolver } from '@shared/services/resolvers/pre-process-resolver/pre-process-resolver';
import { ProcessResolver } from '@shared/services/resolvers/process-resolver/process-resolver.service';
import { UcMaterialModule } from '@shared/uc-material/uc-material.module';

import { AdditionalInformationComponent } from './tasks/additional-information/additional-information.component';
import { CompleteStageComponent } from './tasks/complete-stage/complete-stage.component';
import { ConfirmChangesComponent } from './tasks/confirm-changes/confirm-changes.component';
import { ConfirmCoaChangesComponent } from './tasks/confirm-coa-changes/confirm-coa-changes.component';
import { CoursesClosedComponent } from './tasks/courses-closed/courses-closed.component';
import { DoctoralStudyComponent } from './tasks/doctoral-study/doctoral-study.component';
import { EvidenceOfFundsComponent } from './tasks/evidence-of-funds/evidence-of-funds.component';
import { MicroCredentialsDeclarationComponent } from './tasks/micro-credentials-declaration/micro-credentials-declaration';
import { OfferDecisionComponent } from './tasks/offer-decision/offer-decision.component';
import { PhdAcceptDeclineComponent } from './tasks/phd-accept-decline/phd-accept-decline.component';
import { QualificationCOAComponent } from './tasks/qualification-coa/qualification-coa.component';
import { QualificationCOEComponent } from './tasks/qualification-coe/qualification-coe.component';
import { QualificationMcComponent } from './tasks/qualification-mc/qualification-mc.component';
import { StarComponent } from './tasks/star/star.component';
import { StarAcceptDeclineComponent } from './tasks/star-accept-decline/star-accept-decline.component';
import { StarDeclarationComponent } from './tasks/star-declaration/star-declaration.component';
import { StudyOptionsComponent } from './tasks/study-options/study-options.component';
import { TeachingMicrocredentialsComponent } from './tasks/teaching-microcredentials/teaching-microcredentials.component';

const baseApplicantModule = [
  ProcessPageComponent,
  PreProcessPageComponent,
  ApplicationToEnrolComponent,
  QualificationComponent,
  AcademicHistoryComponent,
  StudentDeclarationComponent,
  CieAdmissionComponent,
  IbAdmissionComponent,
  AesAdmissionComponent,
  EnglishProficiencyComponent,
  TeachingApplicationComponent,
  PoliceCheckComponent,
  TeachingQualificationComponent,
  PostgradIntentionsComponent,
  UcswHandoffComponent,
  ResolveAccountComponent,
  FinancialInformationComponent,
  CupComponent,
  StudyIntentionsComponent,
  FinaliseEnrolmentComponent,
  InternationalStudentInsuranceComponent,
  ResolveOfferComponent,
  ReviewDetailsComponent,
  UeAdmissionComponent,
  FutureYearApplyComponent,
  CourseSelectionOverviewComponent,
  UniversityAdmissionComponent,
  EnrolmentDeclarationComponent,
  CoursesClosedComponent,
  ConfirmChangesComponent,
  ConfirmCoaChangesComponent,
  EnrolmentChangeDeclarationComponent,
  CompleteStageComponent,
  StarDeclarationComponent,
  StarAcceptDeclineComponent,
  StarComponent,
  ExchangeStudyAbroadComponent,
  DoctoralStudyComponent,
  PhdAcceptDeclineComponent,
  StudyOptionsComponent,
  EvidenceOfFundsComponent,
  QualificationCOAComponent,
  QualificationCOEComponent,
  TeachingMicrocredentialsComponent,
  MicroCredentialsDeclarationComponent,
  QualificationMcComponent,
  AcademicHistoryMcComponent,
  CAComplianceComponent,
  AdditionalInformationComponent,
  OfferDecisionComponent,
  DeferOfferComponent,
];
@NgModule({
  declarations: baseApplicantModule,
  imports: [
    QualificationModule,
    UcMaterialModule,
    AtomsModule,
    MoleculesModule,
    OrganismsModule,
    SharedTemplatesModule,
    ReactiveFormsModule,
    CommonModule,
    NgxDatatableModule,
  ],
  providers: [ProcessResolver, PreProcessResolver],
  exports: baseApplicantModule,
})
export class BaseApplicantModule {}
