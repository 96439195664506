import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-ssl-rebate',
  templateUrl: './ssl-rebate.component.html',
  styleUrls: ['./ssl-rebate.component.scss'],
})
export class SslRebateComponent implements OnInit {
  strings = strings.components.tasks.financialInformation.sslRebate;

  extraDescriptions: { code: string; description: string }[] = Object.keys(this.strings.extraDescriptions).map(
    (code) => {
      const description = this.strings.extraDescriptions[code];
      return { code, description };
    },
  );

  @Input() sslForm: UntypedFormGroup;
  @Input() applicationYear: string;
  @Input() task: Task;

  constructor() {}

  get rebateCategory(): string | null {
    return this.sslForm.get('rebateCategory').value;
  }

  ngOnInit() {}
}
