import { Component, OnInit, Input } from '@angular/core';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import strings from '@constants/strings.constants';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';

@Component({
  selector: 'uc-courses-closed',
  templateUrl: './courses-closed.component.html',
  styleUrls: ['./courses-closed.component.scss'],
})
export class CoursesClosedComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() isInternational = false;
  @Input() process;
  strings = strings.components.tasks.coursesClosed;

  get previousYear(): number {
    return Number(this.applicationYear) - 1;
  }

  get isMicroCredentialProcess(): boolean {
    return this.process === PROCESS_NAMES.MCED || this.process === PROCESS_NAMES.MICRO_CREDENTIAL;
  }

  constructor() {
    super();
  }

  ngOnInit() {}

  updateFormValidity(err: UCError): void {}
}
