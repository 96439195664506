<div *ngIf="applicationHints.length > 0" class="hints-wrapper">
  <h4 class="banner__heading">{{strings.stillToComplete}}</h4>
  <div class="validation-message" *ngFor="let hint of applicationHints">
    <uc-icon
      class="validation-message__icon"
      iconType="alert"
      iconWidth="18"
      iconHeight="18"
      iconFill="$colorAmber">
    </uc-icon>
    <span class="validation-message__text">{{hint.title}} ({{hint.percentComplete}}% complete)</span>
    <a class="validation-message__action" [routerLink]="hint.path">{{strings.showMe}}</a>
  </div>
</div>
