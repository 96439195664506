import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { NEVER, throwError, Observable, Observer, BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { tap, catchError, filter, map, switchMap, finalize } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { FLASH_MESSAGE_REF } from '@shared/constants/states.constants';
import { UCError } from '@shared/models/errors';
import { UCFile, Category, importantCategories } from '@shared/models/uc-file';
import { CacheManagementService, CacheObjects } from '@shared/services/cache-management/cache-management.service';
import { DataService, DSHttpError, UCErrorCodes } from '@shared/services/data-service';
import { FileUploadService, FileUploadServiceEvent } from '@shared/services/file-upload/file-upload.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

import { FlashMessageService } from '../flash-message/flash-message.service';
import { UserActivityService } from '../user-activity/user-activity.service';

export const allDocs: FileUploadServiceEvent[] = [
  {
    id: 'deadbeez',
    progress: 1.0,
    complete: true,
    file: UCFile.deserialize({
      id: '1339',
      name: 'wub a lub a dub dub.jpg',
      category: 'identity',
      type: 'pdf',
      size: 1929829,
      academicYear: '2018',
      created: DateTime.fromISO('2016-03-26').toISO(),
    }),
    xhr: null,
  },
  {
    id: 'deadbeezy',
    progress: 1.0,
    complete: true,
    file: UCFile.deserialize({
      id: '1337',
      name: 'wreckitywreckedson.jpg',
      category: 'identity',
      type: 'pdf',
      size: 1929829,
      academicYear: '2019',
      created: DateTime.fromISO('2016-03-26').toISO(),
    }),
    xhr: null,
  },
  {
    id: 'dead',
    progress: 1.0,
    complete: true,
    file: UCFile.deserialize({
      id: '133a',
      name: 'wa dub dub.jpg',
      category: 'OFFER_LETTER',
      type: 'pdf',
      size: 1929829,
      academicYear: '2018',
      created: DateTime.fromISO('2016-03-26').toISO(),
      viewed: false,
      studentViewable: true,
    }),
    xhr: null,
  },
  {
    id: 'deadbeez2',
    progress: 1.0,
    complete: true,
    file: UCFile.deserialize({
      id: 'viewedDoc',
      name: 'wa dub dub.jpg',
      category: 'test_category',
      type: 'pdf',
      academicYear: '2018',
      size: 1929829,
      created: DateTime.fromISO('2016-03-26').toISO(),
      studentViewable: true,
    }),
    xhr: null,
  },
  {
    id: 'deadbech',
    progress: 1.0,
    complete: true,
    file: UCFile.deserialize({
      id: '1338',
      name: 'getschwifty.jpg',
      category: 'test_category',
      academicYear: '2017',
      type: 'pdf',
      size: 1929829,
      created: DateTime.fromISO('2016-03-26').toISO(),
      viewed: false,
      studentViewable: true,
    }),
    xhr: null,
  },
];

export const mockFiles = allDocs.map((doc) => doc.file);

@Injectable()
export class DocumentService implements IDocumentService {
  log: Logger;
  private serviceUrl = `${environment.apiRoot}/document`;
  private documents: Record<string, BehaviorSubject<FileUploadServiceEvent[]>> = {};
  private errors$ = new Subject<{ category: string; error: UCError }>();
  private documentError$ = new Subject<UCError>();
  private categories$ = new BehaviorSubject<Category[]>([null]);
  private importantDocumentCount$ = new BehaviorSubject<number>(0);
  importantDocumentIds$ = new BehaviorSubject<string[]>([]);
  importantCategories = importantCategories;
  documentLibraryStrings = strings.components.organisms.documentLibrary;

  constructor(
    private dataService: DataService,
    private fileUploadService: FileUploadService,
    loggingService: LoggingService,
    private cacheService: CacheManagementService,
    private userActivityService: UserActivityService,
    private flashService: FlashMessageService,
  ) {
    this.log = loggingService.createLogger(this);

    this.cacheService.shouldClearCache
      .pipe(filter((options) => options.target === CacheObjects.ALL))
      .subscribe(() => this.clearCache());
  }

  get categories(): Observable<Category[]> {
    return this.categories$.asObservable();
  }

  get importantDocuments() {
    return this.importantDocumentIds$.asObservable();
  }

  get importantDocumentCount() {
    return this.importantDocumentCount$.asObservable();
  }

  get error(): Observable<{ category: string; error: UCError }> {
    return this.errors$.asObservable();
  }

  get documentError(): Observable<UCError> {
    return this.documentError$.asObservable();
  }

  private clearCache() {
    this.documents = {};
  }

  private removeFileFromCache(file: UCFile) {
    if (!this.documents[file.category]) {
      this.documents[file.category] = new BehaviorSubject<FileUploadServiceEvent[]>([]);
    } else {
      const categoryCollection = this.documents[file.category] as BehaviorSubject<FileUploadServiceEvent[]>;
      const newCollection = categoryCollection.value.filter((fileState) => fileState.file.id !== file.id);
      categoryCollection.next(newCollection);
    }
  }

  private updateWithNewFiles(category: string, files: FileUploadServiceEvent[]): Observable<FileUploadServiceEvent[]> {
    if (!this.documents[category]) {
      this.documents[category] = new BehaviorSubject<FileUploadServiceEvent[]>(files);
    } else {
      const categoryCollection = this.documents[category] as BehaviorSubject<FileUploadServiceEvent[]>;
      const incompleteFiles = categoryCollection.value.filter((fileState) => !fileState.complete);
      categoryCollection.next(files.concat(incompleteFiles));
    }
    return this.documents[category].asObservable();
  }

  public getImportantDocuments() {
    this.importantCategories.forEach((cat) => {
      this.getDocumentsForCategory(cat)
        .pipe(filter((docs) => !!docs && !!docs.length))
        .subscribe((docs) => {
          const currentImportantDocs = this.importantDocumentIds$.value;
          const docIds = docs.filter((e) => currentImportantDocs.indexOf(e.file.id) === -1).map((doc) => doc.file.id);
          this.importantDocumentIds$.next(currentImportantDocs.concat(docIds));
        });
    });
  }

  public errorsForCategory(category: string): Observable<UCError> {
    return this.errors$.asObservable().pipe(
      filter((e) => e.category === category),
      map((e) => e.error),
    );
  }

  /**
   * Fetch all documents as UCFile instances
   *
   * @returns
   */
  public getDocuments(): Observable<UCFile[]> {
    return this.dataService.fetch(this.serviceUrl, {
      error$: this.documentError$,
      deserialize: (payload: { documents: Record<string, unknown>[] }) => {
        return payload.documents.map(UCFile.deserialize);
      },
    });
  }

  public getCategories(): Observable<Category[]> {
    if (get(this.categories$.value, '[0]')) {
      return this.categories;
    }

    return this.dataService.fetch(`${this.serviceUrl}/category`, {
      error$: this.documentError$,
      success$: this.categories$,
      deserialize: (payload: { categories: Record<string, unknown>[] }) => payload.categories.map(Category.deserialize),
    });
  }

  /**
   * directly query the backend for documents
   *
   * @param category document category (e.g identity)
   */
  // eslint-disable-next-line max-lines-per-function
  public requestDocuments(category: string): Observable<FileUploadServiceEvent[]> {
    return this.dataService
      .fetch(`${this.serviceUrl}/${category}`, {
        deserialize: (payload): FileUploadServiceEvent[] => {
          const uploadID = Math.random().toString(36).substring(7);
          return payload.documents.map(
            (file): FileUploadServiceEvent => ({
              id: uploadID,
              progress: 1,
              complete: true,
              file: UCFile.deserialize(file),
              xhr: null,
            }),
          );
        },
      })
      .pipe(
        switchMap((files: FileUploadServiceEvent[]): Observable<FileUploadServiceEvent[]> => {
          return this.updateWithNewFiles(category, files);
        }),
        catchError((error: DSHttpError): Observable<null> => {
          this.errors$.next({ category, error });
          // Not so sure about this, but it preserves the original api...
          // IMO should be returning Observable.empty()
          return of(null);
        }),
      );
  }

  /**
   * request documents, if a cache hit happens, it will return the observable for that cache.
   *
   * @param category
   */
  public getDocumentsForCategory(category: string): Observable<FileUploadServiceEvent[]> {
    if (!category) {
      throw new Error('getDocuments should specify a category/year string');
    }
    if (this.documents[category]) {
      return this.documents[category].asObservable();
    }
    return this.requestDocuments(category);
  }

  /**
   * delete a file
   *
   * @param file UCFile object
   */
  public deleteFile(file: UCFile, year): Observable<unknown> {
    const url = `${this.serviceUrl}/${year}/${file.category}/${file.id}`;
    return this.dataService.del(url, {}).pipe(
      tap(() => {
        this.removeFileFromCache(file);
      }),
    );
  }
  /**
   * cancel a file upload
   *
   * @param fs a fileuploadservice event
   */
  public abortUpload(fs: FileUploadServiceEvent) {
    this.removeFileFromCache(fs.file);
    return this.fileUploadService.abortUpload(fs);
  }

  private doFileUploadForCategory(file: File, category, year, isByStaff, userId): Observable<FileUploadServiceEvent> {
    return Observable.create((observer: Observer<FileUploadServiceEvent>) => {
      this.fileUploadService
        .doXHR(file, category, year, isByStaff, userId)
        .pipe(
          finalize(() => {
            observer.complete();
          }),
        )
        .subscribe(
          (progress) => {
            observer.next(progress);
          },
          (err) => {
            observer.error(err);
          },
        );
    });
  }

  // eslint-disable-next-line max-lines-per-function
  public uploadFileForCategory(
    file: File,
    category: string,
    year: string,
    isByStaff: boolean = false,
    userId?: string,
  ): Observable<FileUploadServiceEvent> {
    return this.doFileUploadForCategory(file, category, year, isByStaff, userId).pipe(
      catchError((err) => {
        this.documents[category].next(this.documents[category].value.filter((doc) => doc.file.id !== err.data.file.id));
        return throwError(err);
      }),
      // eslint-disable-next-line max-lines-per-function
      tap((fEvent) => {
        if (!this.documents[category]) {
          // if we are uploading into a category without any existing data, add the new file upload to the cache
          this.documents[category] = new BehaviorSubject<FileUploadServiceEvent[]>([fEvent]);
        } else {
          const categoryCollection = this.documents[category] as BehaviorSubject<FileUploadServiceEvent[]>;
          const doesExist = categoryCollection.value.find((f) => f.id === fEvent.id);
          let newCollection = categoryCollection.value;
          if (!doesExist) {
            // push the new fileevent into the cache if it does not exist
            newCollection = [fEvent].concat(categoryCollection.value);
          } else {
            // update the file upload progress
            newCollection = categoryCollection.value.map((f) => {
              if (f.id === fEvent.id) {
                return fEvent;
              }
              return f;
            });
          }
          categoryCollection.next(newCollection);
        }
      }),
    );
  }

  // eslint-disable-next-line max-lines-per-function
  public softDelete(file: UCFile): Observable<UCFile> {
    const clone = { ...file };
    clone.deleted = true;
    const url = `${this.serviceUrl}/${file.id}`;
    const body = { documents: UCFile.serialize(clone) };
    const deserialize = (payload) => {
      return payload.documents.id ? UCFile.deserialize(payload.documents) : clone;
    };
    return this.dataService
      .put(url, body, {
        error$: this.documentError$,
        deserialize,
      })
      .pipe(
        map((ucfile) => {
          if (this.documents && this.documents[file.category]) {
            this.documents[file.category].next(
              this.documents[file.category].value.filter((f) => f.file.id !== file.id),
            );
          }
          return ucfile;
        }),
      );
  }

  // eslint-disable-next-line max-lines-per-function
  public checkForUnreadDocuments() {
    combineLatest(
      this.importantDocuments.pipe(filter((el) => !!el && !!el.length)),
      this.userActivityService.getViewedDocuments(),
      // eslint-disable-next-line complexity
    ).subscribe(([libraryDocIds, viewedDocIds]) => {
      if (viewedDocIds && viewedDocIds.length) {
        const unviewedDocs = libraryDocIds.filter((el) => viewedDocIds.indexOf(el) === -1);
        this.importantDocumentCount$.next(unviewedDocs.length);
        const importandDocMessages = this.flashService.messagesByRef(FLASH_MESSAGE_REF.IMPORTANT_DOCS);
        if (unviewedDocs.length && importandDocMessages.length === 0) {
          this.flashService.pushInfo(this.documentLibraryStrings.unreadDocuments, {
            ref: FLASH_MESSAGE_REF.IMPORTANT_DOCS,
          });
        } else if (!unviewedDocs.length) {
          importandDocMessages.forEach((message) => this.flashService.removeMessage(message));
        }
      } else {
        this.importantDocumentCount$.next(libraryDocIds.length);
        this.flashService.pushInfo(this.documentLibraryStrings.unreadDocuments, {
          ref: FLASH_MESSAGE_REF.IMPORTANT_DOCS,
        });
      }
    });
  }

  public getDocumentsForStaff(canonicalIdentifier: string): Observable<UCFile[]> {
    const url = `${this.serviceUrl}/staff/${canonicalIdentifier}`;
    return this.dataService.fetch(url, {
      error$: this.documentError$,
      deserialize: (payload: { documents: Record<string, unknown>[] }) => {
        return payload.documents.map(UCFile.deserialize);
      },
    });
  }

  // eslint-disable-next-line max-lines-per-function
  public retrieveDocumentForStaff(docId: string) {
    const url = `${this.serviceUrl}/staff/detail/${docId}`;
    return this.dataService
      .fetch(url, {
        emitErrors: false,
        unexpectedStatusCode: 'auth.generic',
        defaultErrorCode: 'auth.generic',
        errorCodes: {
          '403': UCErrorCodes.E401,
        },
        ignoredErrorStatuses: [403],
      })
      .pipe(
        map((file: UCFile) => {
          return file;
        }),
        catchError((err: DSHttpError) => {
          return throwError(err);
        }),
      );
  }

  public deleteDocumentForStaff(fileId: string): Observable<UCFile[]> {
    const url = `${this.serviceUrl}/staff/detail/${fileId}`;
    return this.dataService.del(url, {
      error$: this.documentError$,
    });
  }

  public renameDocumentForStaff(file: UCFile): Observable<UCFile> {
    const url = `${this.serviceUrl}/staff/detail/${file.id}`;
    return this.dataService.put(
      url,
      {
        documents: {
          name: file.name,
        },
      },
      {
        error$: this.documentError$,
      },
    );
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export class MockDocumentService implements IDocumentService {
  flashService: FlashMessageService;
  userActivityService: UserActivityService;
  documentLibraryStrings = strings.components.organisms.documentLibrary;

  public documentError$ = new Subject<UCError>();
  public docs = allDocs;
  public mockCategories = [
    Category.deserialize({
      code: 'test_category',
      description: 'somedescription',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: false,
    }),
    Category.deserialize({
      code: 'IDENTITY',
      description: 'otherdescription',
      documentType: 'applicant',
      studentUploadable: true,
      studentViewable: false,
    }),
    Category.deserialize({
      code: 'identity',
      description: 'otherdescription',
      documentType: 'applicant',
      studentUploadable: true,
      studentViewable: false,
    }),
    Category.deserialize({
      code: 'OFFER_LETTER',
      description: 'oneotherdescription',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: false,
    }),
    Category.deserialize({
      code: 'NAMECHANGE',
      description: 'oneotherdescription',
      documentType: 'application',
      studentUploadable: false,
      studentViewable: false,
    }),
    Category.deserialize({
      code: 'STAR_CONSENT',
      description: 'STAR consent form',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'PG SUPERVISOR',
      description: 'PhD evidence',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'RESULTS_SEC',
      description: 'Secondary results',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'RESULTS_TERT',
      description: 'Tertiary results',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'TERT_GRAD_CERT',
      description: 'Tertiary graduation certificate',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'EVIDENCE_OF_FUNDS',
      description: 'Evidence of funds',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'GPA_VALIDATION_REP',
      description: 'GPA validation report',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'SUPERVISION_EVIDENCE',
      description: 'Evidence of supervision',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'THESIS_RESEARCHPAPER',
      description: 'Past thesis/ research paper',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'DIPLOMA_SUPPLEMENT',
      description: 'European Diploma Supplement',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'MCED_SUPPORT',
      description: 'Micro-credential supporting documentation',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'ADDITIONAL_INFO_CAC',
      description: 'Additional Information',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'DEFERRAL_APPROVAL',
      description: 'Deferral Approval',
      documentType: 'application',
      studentUploadable: true,
      studentViewable: true,
    }),
    Category.deserialize({
      code: 'CV',
      description: 'Curriculum Vitae (CV)',
      studentUploadable: true,
      studentViewable: false,
      documentType: 'applicant',
    }),
  ];
  importantDocumentIds$ = new BehaviorSubject<string[]>([]);
  importantCategories = ['OFFER_LETTER', 'test_category'];
  importantDocumentCount$ = new BehaviorSubject<number>(0);

  constructor(userActivityService?: UserActivityService, flashService?: FlashMessageService) {
    if (flashService) {
      this.flashService = flashService;
    }

    if (userActivityService) {
      this.userActivityService = userActivityService;
    }
  }

  get documentError(): Observable<UCError> {
    return this.documentError$.asObservable();
  }

  get importantDocuments() {
    return this.importantDocumentIds$.asObservable();
  }

  get importantDocumentCount() {
    return this.importantDocumentCount$.asObservable();
  }

  abortUpload() {
    // no-op
  }

  deleteFile(file: UCFile, year: string): Observable<unknown> {
    return of(null);
  }

  getDocumentsForCategory(category: string): Observable<FileUploadServiceEvent[]> {
    const filteredDocs = this.docs.filter((doc) => {
      return doc.file.category === category;
    });
    return of(filteredDocs);
  }

  getDocuments(): Observable<UCFile[]> {
    return of(mockFiles);
  }

  getCategories(): Observable<Category[]> {
    return of(this.mockCategories);
  }

  // eslint-disable-next-line max-lines-per-function
  uploadFileForCategory(
    file: File,
    category: string,
    year: string,
    isByStaff: boolean = false,
    userId?: string,
  ): Observable<FileUploadServiceEvent> {
    const event: FileUploadServiceEvent = {
      id: 'deadbeef',
      progress: 100,
      complete: true,
      file: UCFile.deserialize({
        id: '1337',
        name: 'myFile',
        category,
        type: 'my_type',
        size: 1929829,
        created: DateTime.fromISO('2016-03-26').toISO(),
        academicYear: year,
      }),
      xhr: null,
    };
    return of(event);
  }

  public errorsForCategory(category: string) {
    return NEVER;
  }

  public softDelete(file: UCFile): Observable<UCFile> {
    const clone = new UCFile(file);
    clone.deleted = true;
    return of(clone);
  }

  public getImportantDocuments() {
    this.importantCategories.forEach((cat) => {
      this.getDocumentsForCategory(cat)
        .pipe(filter((docs) => !!docs && !!docs.length))
        .subscribe((docs) => {
          if (docs && docs.length) {
            const currentImportantDocs = this.importantDocumentIds$.value;
            const docIds = docs.filter((e) => currentImportantDocs.indexOf(e.file.id) === -1).map((doc) => doc.file.id);
            this.importantDocumentIds$.next(currentImportantDocs.concat(docIds));
          }
        });
    });
  }

  public checkForUnreadDocuments() {
    combineLatest(this.userActivityService.getViewedDocuments(), this.importantDocuments).subscribe(
      // eslint-disable-next-line complexity
      ([viewedDocIds, libraryDocIds]) => {
        if (viewedDocIds && viewedDocIds.length) {
          const unviewedDocs = libraryDocIds.filter((el) => viewedDocIds.indexOf(el) === -1);
          this.importantDocumentCount$.next(unviewedDocs.length);
          const importandDocMessages = this.flashService.messagesByRef(FLASH_MESSAGE_REF.IMPORTANT_DOCS);
          if (unviewedDocs.length && importandDocMessages.length === 0) {
            this.flashService.pushInfo(this.documentLibraryStrings.unreadDocuments, {
              ref: FLASH_MESSAGE_REF.IMPORTANT_DOCS,
            });
          } else if (!unviewedDocs.length) {
            importandDocMessages.forEach((message) => this.flashService.removeMessage(message));
          }
        }
      },
    );
  }

  getDocumentsForStaff(canonicalId: string): Observable<UCFile[]> {
    return of(mockFiles);
  }

  retrieveDocumentForStaff(docId: string) {
    return of(mockFiles[0]);
  }

  deleteDocumentForStaff(fileId: string) {
    return of({});
  }

  renameDocumentForStaff(file: UCFile) {
    return of({});
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */

export interface IDocumentService {
  deleteFile(file: UCFile, year: string): Observable<unknown>;
  getDocumentsForCategory(category: string): Observable<FileUploadServiceEvent[]>;
  getDocuments(): Observable<UCFile[]>;
  getCategories(): Observable<Category[]>;
  uploadFileForCategory(
    file: File,
    category: string,
    year: string,
    isByStaff: boolean,
    userId?: string,
  ): Observable<FileUploadServiceEvent>;
}

// eslint-disable-next-line max-lines-per-function
export const documentServiceFactory = (
  dataService,
  fileUploadService,
  loggingService,
  cacheService,
  userActivityService,
  flashService,
): IDocumentService => {
  if (environment.useFakeBackend.document) {
    return new MockDocumentService(userActivityService, flashService);
  } else {
    return new DocumentService(
      dataService,
      fileUploadService,
      loggingService,
      cacheService,
      userActivityService,
      flashService,
    );
  }
};

export const documentServiceProvider = {
  provide: DocumentService,
  useFactory: documentServiceFactory,
  deps: [
    DataService,
    FileUploadService,
    LoggingService,
    CacheManagementService,
    UserActivityService,
    FlashMessageService,
  ],
};
