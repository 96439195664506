import { MODEL_NAMES } from '@shared/constants/app-names.constants';
import { ASSOCIATED_PERSON_TYPE } from '@shared/constants/states.constants';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';

import { Application } from './application';
import { ChangeOfEnrolment } from './change-of-enrolment';

export interface IRefereesByApplication {
  applicationType: MODEL_NAMES.APPLICATION | MODEL_NAMES.CHANGEOFENROLMENT;
  referees: { [key: string]: AssociatedPerson };
  applicationYear: string;
  coeInternalReference?: string;
}

const mcedAssociatedPersonTypes = [
  ASSOCIATED_PERSON_TYPE.MCED_FACILITATOR,
  ASSOCIATED_PERSON_TYPE.MCED_TEACHER,
  ASSOCIATED_PERSON_TYPE.MCED_TEACHER_AIDE,
];

export class AssociatedPerson {
  public type: ReferenceData = null;
  public name: string = null;
  public relationship: string = null;
  public email: string = null;
  public report: string = null;
  public reportRequired?: boolean;
  public reportReceived?: boolean;
  public refereeSuitability?: ReferenceData;
  public likelySuccess?: ReferenceData;
  public internalReference?: string;

  constructor(rawName = {}) {
    Object.assign(this, rawName);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static deserialize(payload: any): AssociatedPerson {
    if (payload === null) {
      return null;
    }

    if (payload.type) {
      payload.type = ReferenceData.deserialize(payload.type);
    }

    return new AssociatedPerson(camelizeKeys(payload));
  }

  /* eslint-disable max-lines-per-function */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static deserializeAll(payload: any) {
    if (payload == null) {
      return null;
    }

    const obj = {};
    payload.forEach((ap) => {
      const associatedPerson = this.deserialize(ap);
      if (!associatedPerson.type?.code) {
        return;
      }

      let key = '';

      if (mcedAssociatedPersonTypes.find((type) => type === associatedPerson.type?.code)) {
        // on the Micro credentials teaching task, the user can select a type. This lets us map the form values correctly
        key = ASSOCIATED_PERSON_TYPE.MCED;
      } else {
        key = associatedPerson.type?.code.replace(' ', '_');
      }

      obj[key] = associatedPerson;
    });
    return obj;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static serialize(instance: AssociatedPerson): any {
    const clone = { ...instance };
    if (clone.type) {
      clone.type = ReferenceData.serialize(clone.type);
    }
    if (!clone.internalReference) {
      clone.internalReference = null;
    }
    return snakeifyKeys(clone);
  }

  /* eslint-disable max-lines-per-function */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static serializeAll(instance: { [key: string]: AssociatedPerson }[]): any {
    if (instance == null) {
      return null;
    }

    const typesRequiringAllFields = [ASSOCIATED_PERSON_TYPE.SCHOOL];
    const arr = [];
    // eslint-disable-next-line complexity
    Object.keys(instance).forEach((key: string) => {
      // Ensure there is always a type code before saving the data to the api
      if (!instance[key].type?.code) {
        return;
      }

      if (typesRequiringAllFields.find((type) => type === key)) {
        const keys = ['name', 'relationship', 'email', 'report'];

        const validPersonData = !!keys.find((k) => !!instance[key]?.[k]);
        if (validPersonData) {
          arr.push(this.serialize(instance[key]));
        }
      } else {
        arr.push(this.serialize(instance[key]));
      }
    });
    return arr;
  }

  static listifyAllForStaff(instance) {
    if (instance == null) {
      return null;
    }

    const arr = [];
    Object.keys(instance).forEach((key: string) => {
      arr.push(instance[key]);
    });
    return arr;
  }

  /* eslint-disable max-lines-per-function */
  static getRefereesByApplication(applications: Application[] = [], changeOfEnrolment: ChangeOfEnrolment[] = []) {
    const refereesByApplication: IRefereesByApplication[] = [];

    applications?.forEach((app) => {
      if (Object.keys(app.associatedPerson).length > 0) {
        refereesByApplication.push({
          applicationType: MODEL_NAMES.APPLICATION,
          referees: app.associatedPerson,
          applicationYear: app.academicYear.code,
        });
      }
    });
    changeOfEnrolment?.forEach((coe) => {
      refereesByApplication.push({
        applicationType: MODEL_NAMES.CHANGEOFENROLMENT,
        referees: coe?.associatedPerson,
        applicationYear: coe.academicYear.code,
        coeInternalReference: coe.internalReference,
      });
    });
    return refereesByApplication;
  }
}
