import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { IAdditionalText } from '@shared/components/atoms/radio-input/radio-input.component';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
  numberToString,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

enum ExchangeStudyTypes {
  studyAbroad = 'ABROAD',
  incomingStudentExchange = 'INC_EXCH',
}

@Component({
  selector: 'uc-exchange-study-abroad',
  templateUrl: './exchange-study-abroad.component.html',
})
export class ExchangeStudyAbroadComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  strings = strings.components.tasks.exchangeStudyAbroad;
  basePath = '/application/incoming_student_exchange_study_abroad_details';
  exchangeStudyAbroadForm: UCElementGroup;
  exchangeStudyAbroadPage: UntypedFormGroup;
  currentApplication: Application;
  currentApplicant: Applicant;
  log: Logger;

  private studyTypes = this.strings.studyTypeOptions;
  studyTypeOptions = [
    {
      labelText: this.studyTypes.studyAbroad.title,
      value: ExchangeStudyTypes.studyAbroad,
    },
    {
      labelText: this.studyTypes.incomingStudentExchange.title,
      value: ExchangeStudyTypes.incomingStudentExchange,
    },
  ];

  studyTypeGuidance: IAdditionalText[] = [
    {
      code: ExchangeStudyTypes.studyAbroad,
      inlineGuidance: this.studyTypes.studyAbroad.guidance,
    },
    {
      code: ExchangeStudyTypes.incomingStudentExchange,
      inlineGuidance: this.studyTypes.incomingStudentExchange.guidance,
    },
  ];

  lengthOfStudyOptions = [
    { labelText: this.strings.lengthOfStudyOptions.oneSemester, value: 'One semester' },
    { labelText: this.strings.lengthOfStudyOptions.twoSemesters, value: 'Two semesters' },
  ];

  constructor(
    loggingService: LoggingService,
    private formModel: FormModelMapperService,
    private applicationService: ApplicationService,
    private applicantService: ApplicantService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  private createForm(): UCElementGroup {
    return group({
      studyType: control({
        model: 'application',
        path: '/incomingStudentExchangeStudyAbroadDetails/studyType',
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      lastYearAttended: control({
        model: 'applicant',
        path: '/demographic/moeYearLastAttendedSchool',
        inMap: numberToString,
      }),
      homeUniversity: control({
        model: 'application',
        path: '/incomingStudentExchangeStudyAbroadDetails/homeUniversity',
      }),
      majorSubject: control({
        model: 'application',
        path: '/incomingStudentExchangeStudyAbroadDetails/majorSubject',
      }),
      studyStart: control({
        model: 'application',
        path: '/studyStart',
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      lengthOfStudy: control({
        model: 'application',
        path: '/incomingStudentExchangeStudyAbroadDetails/lengthOfStudy',
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      proposedStudyPlan: control({
        model: 'application',
        path: '/incomingStudentExchangeStudyAbroadDetails/proposedStudyPlan',
      }),
    });
  }

  ngOnInit() {
    this.exchangeStudyAbroadForm = this.createForm();
    this.exchangeStudyAbroadPage = this.exchangeStudyAbroadForm.asControl() as UntypedFormGroup;
    combineLatest([
      this.applicantService.applicant.pipe(filter((a) => !!a)),
      this.applicationService.application.pipe(filter((a) => !!a)),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplication = application;
        this.currentApplicant = applicant;
        this.formModel.updateFormFromModel(
          this.exchangeStudyAbroadForm,
          this.currentApplicant,
          this.currentApplication,
        );
      });
  }

  updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.exchangeStudyAbroadForm);
  }

  update() {
    this.formModel.updateModelFromForm(this.exchangeStudyAbroadForm, this.currentApplicant, this.currentApplication);

    const lastSecondaryYear = get(this.exchangeStudyAbroadForm.controls.lastYearAttended.control, 'value');
    this.currentApplicant.secondaryQualification[0].dateAttained = lastSecondaryYear || null;
    combineLatest(
      this.applicantService.updateApplicant(this.currentApplicant),
      this.applicationService.updateApplication(this.currentApplication),
    ).subscribe(
      ([applicant, application]: [Applicant, Application]) => {
        if (application && applicant) {
          this.next.emit();
        }
      },
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating application/applicant:', err);
      },
    );
  }
}
