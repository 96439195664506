<div class="study-plan-summary clearfix">
  <div class="study-plan-summary__cart cart-symbol">
    <uc-icon class="cart-symbol__icon" iconType="satchel" iconWidth="38" iconHeight="38"></uc-icon>
    <div class="cart-symbol__count">{{numberOfCourses}}</div>
  </div>
  <h3 class="study-plan-summary__heading">{{strings.courseSelection}}</h3>
  <div class="study-plan-summary__points-indicator points-indicator">
    <div class="points-indicator__current-points-text">
      {{strings.points}} {{points}}
    </div>
  </div>
</div>
