<div class="form-field isDisabled clearfix">
  {{ value }}
  <uc-icon
    class="lock-icon"
    iconTitle="Lock"
    iconType="lock"
    iconHeight="14"
    iconWidth="14"
    iconFill="$colorMineShaft"
  ></uc-icon>
</div>

<button
  [attr.data-cy]="'disabled-state'"
  *ngIf="enableModal"
  class="field-explanation-link"
  (click)="openModal()"
>
  {{ strings.whatIsThis }}
</button>

<uc-confirmation-modal [message]="strings.modalMsg"></uc-confirmation-modal>
