import { RouterModule, Routes } from '@angular/router';

import { LoginTemplateComponent } from '@app/components/templates/login-template/login-template.component';
import { OnlineProcessPageComponent } from '@app/components/templates/online-process-page/online-process-page.component';
import { ProcessHydrateOverlayComponent } from '@shared/components/atoms/process-hydrate-overlay/process-hydrate-overlay.component';
import { DocumentTemplateComponent } from '@shared/components/templates/document-template/document-template.component';
import { InformationRequiredComponent } from '@shared/components/templates/information-required-template/information-required-template.component';
import { PageNotFoundTemplateComponent } from '@shared/components/templates/page-not-found-template/page-not-found-template.component';
import { SsoTemplateComponent } from '@shared/components/templates/sso-template/sso-template.component';
import { FeatureGuard } from '@shared/services/guards/feature.guard';
import { OutageGuard } from '@shared/services/guards/outage.guard';
import { StudentIdGuard } from '@shared/services/guards/student-id.guard';
import { UnsavedDataGuard } from '@shared/services/guards/unsaved-data.guard';
import { interceptBackButtonForModalsGuard } from '@shared/services/modal/intercept-back-button-for-modals.guard';

import { AuthActionComponent } from './components/templates/auth-action/auth-action.component';
import { MyucCoursePlannerComponent } from './components/templates/myuc-course-planner/myuc-course-planner.component';
import { MyucManageMyStudyTemplateComponent } from './components/templates/myuc-manage-my-study-template/myuc-manage-my-study-template.component';
import { OnlineAccountSettingsComponent } from './components/templates/online-account-settings/online-account-settings.component';
import { OnlineOutageTemplateComponent } from './components/templates/online-outage-template/online-outage-template.component';
import { OnlinePreProcessPageComponent } from './components/templates/online-pre-process-page/online-pre-process-page.component';
import { OnlineProfileTemplateComponent } from './components/templates/online-profile-template/online-profile-template.component';
import { OnlineAuthOnloadGuard } from './services/guards/online-auth-onload.guard';
import { OnlineUserAuthenticatedGuard } from './services/guards/online-user-authenticated.guard';
import { OnlineProcessResolver } from './services/resolvers/online-process-resolver/online-process-resolver.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginTemplateComponent,
    canActivate: [OnlineAuthOnloadGuard],
    data: { title: 'Login | Application to Enrol | UC' },
  },
  {
    path: 'sso',
    component: SsoTemplateComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
  },
  {
    path: 'b2c',
    component: LoginTemplateComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
  },
  {
    path: 'online-apply/:process',
    component: OnlinePreProcessPageComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
  },
  {
    path: 'online-apply/:process/:year',
    component: OnlineProcessPageComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
    resolve: {
      processContext: OnlineProcessResolver,
    },
  },
  {
    path: 'online-apply/:process/:year/:stage',
    component: OnlineProcessPageComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
    resolve: {
      processContext: OnlineProcessResolver,
    },
  },
  {
    path: 'online-apply/:process/:year/:stage/:task',
    component: OnlineProcessPageComponent,
    resolve: {
      processContext: OnlineProcessResolver,
    },
    canActivate: [OnlineUserAuthenticatedGuard, StudentIdGuard],
    canDeactivate: [UnsavedDataGuard],
    data: {
      unsaveModalRoutes: ['course-selection-overview'],
      whitelistedRoutes: [
        'courses/:applicationYear/:enrolmentPriority/:qualPriority',
        'course-declaration',
        'confirm-coe',
      ],
    },
  },
  {
    path: 'online-apply',
    component: OnlinePreProcessPageComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
  },
  {
    path: 'apply',
    loadChildren: () => import('./online-applicant.module').then((m) => m.OnlineApplicantModule),
    canActivate: [OnlineUserAuthenticatedGuard],
    data: { title: 'Apply | Application to Enrol | UC', lockedRoutes: ['apply'] },
  },
  {
    path: 'documents',
    component: DocumentTemplateComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
    data: { title: 'Document Library | Application to Enrol | UC' },
  },
  {
    path: 'information-required/:year',
    component: InformationRequiredComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
    data: { title: 'Information Required | Application to Enrol | UC' },
  },
  {
    path: '',
    component: MyucManageMyStudyTemplateComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
    data: { title: 'Dashboard | Application to Enrol | UC' },
  },
  {
    path: 'manage-my-study',
    component: MyucManageMyStudyTemplateComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
    data: { title: 'Manage my study | Application to Enrol | UC' },
  },
  {
    path: 'profile',
    component: OnlineProfileTemplateComponent,
    canActivate: [OnlineUserAuthenticatedGuard, FeatureGuard],
    canDeactivate: [UnsavedDataGuard],
    data: { title: 'My Profile | Application to Enrol | UC' },
  },
  {
    path: 'hydrate',
    component: ProcessHydrateOverlayComponent,
    canActivate: [OnlineUserAuthenticatedGuard],
    data: { title: 'Preparing your account | Application to enrol | UC' },
  },
  {
    path: 'account-settings',
    component: OnlineAccountSettingsComponent,
    canActivate: [OnlineUserAuthenticatedGuard, FeatureGuard],
    data: { title: 'Account Settings | Application to enrol | UC' },
  },
  {
    path: 'auth-action',
    component: AuthActionComponent,
    data: { title: 'Authentication actions | Application to enrol | UC' },
  },
  {
    path: 'courses/:applicationYear/:enrolmentPriority/:qualPriority',
    component: MyucCoursePlannerComponent,
    canActivate: [OnlineUserAuthenticatedGuard, StudentIdGuard],
  },
  {
    path: 'outage',
    component: OnlineOutageTemplateComponent,
    data: { title: 'UC Outage | Application to Enrol | UC' },
    canActivate: [OutageGuard],
  },
  {
    path: '**',
    component: PageNotFoundTemplateComponent,
    data: { title: '404 | Application to Enrol | UC' },
  },
];

routes.forEach((route) => {
  route.canDeactivate = route.canDeactivate || [];
  route.canDeactivate.push(interceptBackButtonForModalsGuard);
});

export const appRoutesModule = RouterModule.forRoot(routes, {});
