import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { of, throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, publishReplay, refCount, map } from 'rxjs/operators';

import { environment } from '@environment';
import { snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from '@shared/models/reference-data';
import { DataService, DSHttpError } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

import { mockData as mockReferenceData } from './reference-data.mock';

export interface IGetByTypeOptions {
  ignoreCache?: boolean;
  params?: { [key: string]: string };
}
@Injectable()
export class ReferenceDataService {
  private serviceUrl = `${environment.apiRoot}/reference_data/`;
  protected log: Logger;
  protected cache: Record<string, Observable<ReferenceData[]>> = {};

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  public getByType(
    type: string,
    options: IGetByTypeOptions = {},
    filterOutNonValidItems?: boolean,
  ): Observable<ReferenceData[]> {
    if (!type) {
      throw new Error('getByType must have a defined type parameter');
    }

    this.primeCacheForType(type, options);

    if (filterOutNonValidItems) {
      return this.cache[type].pipe(map((a) => this.filterOutInactiveRefData(a)));
    } else {
      return this.cache[type];
    }
  }

  filterOutInactiveRefData(cache: ReferenceData[]): ReferenceData[] {
    const currentDate = DateTime.now();
    const validToOrFromFilteredData = cache.filter(
      (item) =>
        (!item.validTo || DateTime.fromISO(item.validTo) > currentDate) &&
        (!item.validFrom || DateTime.fromISO(item.validFrom) < currentDate),
    );
    return validToOrFromFilteredData;
  }

  public getByCode(type: string, code: string): Observable<ReferenceData> {
    return this.getByType(type).pipe(
      map((datums) => {
        const refData = datums.find((ref) => ref.code === code);
        if (!refData) {
          this.log.warn('Missing reference code: ', code, 'on reference type: ', type);
        }
        return refData ? refData : null;
      }),
    );
  }

  protected primeCacheForType(type: string, options: IGetByTypeOptions): void {
    if (this.cache[type] && !options.ignoreCache) {
      this.log.info('cache hit!');
    } else {
      this.log.info('cache miss for: ', type);
      const url = `${this.serviceUrl}${type}`;
      this.cache[type] = this.dataService
        .fetch(url, {
          requestOptions: { params: snakeifyKeys(options.params) },
          deserialize: (payload) => payload.reference_data.map((r) => ReferenceData.deserialize(r)),
        })
        .pipe(
          catchError((err: DSHttpError) => {
            if (err.status === 404) {
              err.code = 'errors.referenceData.typeMissing';
            }
            return observableThrowError(err);
          }),
          publishReplay(1),
          refCount(),
        );
    }
  }
}

export class MockReferenceDataService extends ReferenceDataService {
  refData: { [key: string]: ReferenceData[] };

  constructor(suppliedRefData?: { [key: string]: ReferenceData[] }) {
    super(null, { createLogger: (instance) => new Logger(instance) });

    if (suppliedRefData) {
      this.refData = suppliedRefData;
    } else {
      const referenceData = mockReferenceData();
      this.refData = Object.keys(referenceData).reduce((dataObj, key) => {
        dataObj[key] = referenceData[key].map(ReferenceData.deserialize);
        return dataObj;
      }, {});
    }
  }

  protected primeCacheForType(type: string): void {
    this.cache[type] = of(this.refData[type]);
  }
}

// provides the mock reference data when useFakeBackend is true
export const referenceDataServiceFactory = (dataService, loggingService): ReferenceDataService => {
  if (environment.useFakeBackend.referenceData) {
    return new MockReferenceDataService();
  } else {
    return new ReferenceDataService(dataService, loggingService);
  }
};

export const referenceDataServiceProvider = {
  provide: ReferenceDataService,
  useFactory: referenceDataServiceFactory,
  deps: [DataService, LoggingService],
};
