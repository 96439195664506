import { Component, OnInit, forwardRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { touchAll } from '@shared/helpers/general';
import { Name } from '@shared/models/name';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-full-name',
  templateUrl: './full-name.component.html',
  styleUrls: ['./full-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FullNameComponent),
      multi: true,
    },
  ],
})
export class FullNameComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() nameLabel: string;
  @Input() shouldShowPreferred = true;
  @Input() markDirty: boolean;
  @Input() task: Task;
  @Input() testSelector = '';
  @Input() alternatePath = 'legal_name';
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() setPreferred: EventEmitter<any> = new EventEmitter();

  disabledState: boolean;
  log: Logger;
  nameForm: UntypedFormGroup;
  strings = strings.components.molecules.fullName;
  preferredNameTooltipString = strings.components.organisms.legalName.preferredNameTooltip;
  private currentName: Name;

  constructor(loggingService: LoggingService, private fb: UntypedFormBuilder) {
    this.log = loggingService.createLogger(this);
    this.setupForm();
  }

  get pathPrefix() {
    return `/applicant/${this.alternatePath}/`;
  }

  get testSelectorId() {
    return `${this.testSelector}-full-name`;
  }

  ngOnChanges(changes) {
    if (changes.markDirty && changes.markDirty.currentValue) {
      touchAll(this.nameForm);
    }
  }

  ngOnInit() {
    this.log.info('ngOnInit');
    this.currentName = new Name({});
    this.nameForm.valueChanges.subscribe((val) => {
      if (val) {
        val.type = val.preferred ? new ReferenceData({ code: Name.PREFERRED_CODE }) : null;
        delete val.preferred;
        const name = Object.assign(this.currentName, val);

        this.propagateChange(name);
      }
    });
  }

  deleteThisName() {
    this.delete.emit();
  }

  setNameAsPreferred() {
    this.setPreferred.emit();
  }

  private setupForm() {
    this.nameForm = this.fb.group({
      preferred: [''],
      firstName: ['', Validators.required],
      middleName: [''],
      surname: ['', Validators.required],
    });
  }

  /**
   * Write a new value to the element.
   */
  writeValue(obj: Name): void {
    if (!obj) {
      return this.nameForm.reset({});
    }

    this.currentName = obj;
    const val: any = { ...obj };
    val.preferred = get(obj, 'type.code') === Name.PREFERRED_CODE;
    this.nameForm.patchValue(val);
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private propagateChange = (_: any) => {
    // No-op
  };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Set the function to be called'
   * when the control receives a touch event.
   */
  registerOnTouched(): void {
    // no-op
  }

  setDisabledState(b: boolean) {
    this.disabledState = b;
    if (b) {
      this.nameForm.disable();
    } else {
      this.nameForm.enable();
    }
  }
}
