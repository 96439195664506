import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import onlineStrings from '@constants/online-strings.constants';
import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { environment } from '@environment';
import { APP_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { UserSubTypes } from '@shared/models/user';
import { IPLocationService } from '@shared/services/iplocation/iplocation.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-login-template',
  templateUrl: './login-template.component.html',
  styleUrls: ['./login-template.component.scss'],
})
export class LoginTemplateComponent implements OnInit, OnDestroy {
  strings = strings.components.organisms;
  templateStrings = onlineStrings.components.organisms.socialLogin;
  appName = APP_NAMES[UserSubTypes.ucOnlineStudent];
  loginFormStrings = strings.components.organisms.loginForm;
  isViewingLogin = true;
  urls = externalUrls;
  isRestricted = true;
  duplicateEmail = '';
  appNameEnv = APP_NAMES.student;

  // MSAL active account object shortcut.
  private msalAccount?: AccountInfo;

  constructor(
    private userService: UserService,
    private onlineCourseService: OnlineCourseService,
    private refDataService: ReferenceDataService,
    private router: Router,
    public ipLocationService: IPLocationService,
    private activatedRoute: ActivatedRoute,
  ) {}

  // eslint-disable-next-line max-lines-per-function, complexity
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.onlineCourseService.onlineCourseCode = queryParams.enrol;
      this.onlineCourseService.onlineCourseOccur = queryParams.occur;
      this.onlineCourseService.programme = queryParams.programme;
    });

    if (UserService.hasB2CConfig() && this.userService.MSAL_INSTANCE) {
      // Observe on B2C redirect authentication result
      if (this.userService.azureB2CRedirectSubscription) {
        this.userService.azureB2CRedirectSubscription.unsubscribe();
      }
      this.userService.handleRedirectObservable();

      // Subscribe B2C login status and redirect properly
      // eslint-disable-next-line complexity
      this.userService.isB2CLogin$.subscribe((isB2CLogin) => {
        if (isB2CLogin) {
          if (this.onlineCourseService.onlineCourseCode && this.onlineCourseService.onlineCourseOccur) {
            this.router.navigate(internalUrls.onlinePreProcessPage(''), {
              queryParams: {
                enrol: this.onlineCourseService.onlineCourseCode,
                occur: this.onlineCourseService.onlineCourseOccur,
              },
            });
          } else {
            // Land user to dashborad if not SC or MC selected from hubspot
            // From dashboard, user could either check their application or start a programme application
            this.router.navigate(internalUrls.dashboard);
          }
        }
      });
      // Fetch active account, if any. Returns null if inactive.
      this.msalAccount = this.userService.MSAL_INSTANCE.getActiveAccount();
    }

    if (this.msalAccount) {
      const accessTokenRequest = {
        scopes: environment.azureB2C.loginRequest.scopes,
        account: this.msalAccount,
      };

      this.userService.MSAL_INSTANCE.acquireTokenSilent(accessTokenRequest)
        .then((response: AuthenticationResult) => {
          const localId = this.msalAccount.localAccountId;

          // Force fresh login if returned ID isn't what we're expecting
          if (response.uniqueId !== localId) {
            console.error('MSAL returned a different ID than expected, forcing fresh login');
            return this.userService.loginB2C();
          }

          this.userService.isB2CLogin$.next(true);
          this.userService.saveB2CToken(response?.idToken);
        })
        .catch((error) => {
          // Failed to refresh token silently, perform interactive refresh instead
          if (error instanceof InteractionRequiredAuthError) {
            return this.userService.MSAL_INSTANCE.acquireTokenRedirect(accessTokenRequest);
          }
          // For other errors, force fresh B2C login
          return this.userService.loginB2C();
        });
    } else {
      // Force to redirect to Azure login page.
      return this.userService.loginB2C();
    }
  }

  ngOnDestroy() {
    if (this.userService.azureB2CRedirectSubscription) {
      this.userService.azureB2CRedirectSubscription.unsubscribe();
    }
  }

  populateEmailField(event?) {
    const email = event?.email;
    this.duplicateEmail = email;
  }

  loginSocial(providerName?) {
    if (providerName === 'RealMe') {
      // Log in, return to logged in route.
      if (this.userService.getActiveB2CUser()) {
        // TEMP Development: log out if already logged in.
        this.userService.logoutB2C();
      } else {
        this.userService.loginB2C();
      }
    }
  }
}
