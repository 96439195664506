<!-- Show a spinner instead of a blank page so we know it hasn't crashed -->
<div class="spinner-container">
  <uc-spinner></uc-spinner>
</div>

<!-- Temporarily hide firebase login page, and redirect to Azure B2C login page -->
<div
  class="login-wrapper"
  *ngIf="false"
>
  <div class="container-fluid">
    <div class="content-panels row">
      <div class="content-panel">
        <div class="uc-banner">
          <a
            class="banner-logo"
            [href]="urls.ucHomepage"
          >
            <img
              src="assets/img/UC-online-logo-simple-White.svg"
              alt="UC-online-logo-simple-White"
              style="max-width: 280px; max-height: 50px; margin-left: 32px"
            />
          </a>
        </div>
        <ul class="tabs max-width-320">
          <li class="tabs__tab-col">
            <a
              class="tabs__tab-link create-link"
              href="javascript:void(0);"
              (click)="isViewingLogin = false"
              [class.active]="isViewingLogin === false"
              >{{ strings.createAccountForm.createAccountButton }}</a
            >
          </li>
          <li class="tabs__tab-col">
            <a
              class="tabs__tab-link login-link"
              href="javascript:void(0);"
              (click)="isViewingLogin = true"
              [class.active]="isViewingLogin"
              >{{ strings.loginForm.loginButton }}</a
            >
          </li>
        </ul>
        <div class="max-width-320">
          <span *ngIf="!isRestricted">
            <uc-create-account-form
              (goToLogin)="populateEmailField($event); isViewingLogin = true"
              *ngIf="isViewingLogin === false"
            >
            </uc-create-account-form>
            <uc-login-form
              *ngIf="isViewingLogin"
              [isRestricted]="isRestricted"
              (goToCreate)="isViewingLogin = false"
              [duplicateEmail]="duplicateEmail"
              [appName]="appNameEnv"
            >
            </uc-login-form>
            <div class="divider">
              <span class="divider__text">or</span>
            </div>

            <div class="social-btns">
              <uc-button
                (click)="loginSocial('RealMe')"
                class="large rounded has-icon realme"
              >
                <uc-icon
                  iconType="realme"
                  iconWidth="120"
                  iconHeight="120"
                  iconFill="$colorWhite"
                ></uc-icon>
                {{ templateStrings.connect }} {{ templateStrings.realme }}
              </uc-button>
            </div>
            <uc-social-login></uc-social-login>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
