import { Injectable } from '@angular/core';
import { filter, firstValueFrom, map, ReplaySubject } from 'rxjs';

import { UserActivityStrategyBase } from './user-activity-strategy-base.service';
import { UserActivity } from './user-activity.service';
import { Logger, LoggingService } from '../logging/logging.service';
import { UserService } from '../user/user.service';
import { WindowService } from '../window/window.service';

const STORAGE_KEY_PREFIX = '_uc_activity_';

@Injectable()
export class LocalStorageUserActivityStrategyService extends UserActivityStrategyBase {
  private log: Logger;
  private storageKey = new ReplaySubject<string>(1);
  private serializedData = new ReplaySubject<string>(1);

  constructor(logger: LoggingService, private userService: UserService, private windowService: WindowService) {
    super(userService);

    this.log = logger.createLogger(this);
    this.log.info('Initializing LocalStorageUserActivityStrategyService');

    this.clearDataWhenInstructedTo();
    this.generateStorageKeys();
    this.resetDataWhenNoCurrentUserExists();
    this.loadInitialDataWhenUserChanges();
    this.monitorDataChangesForCurrentUser();
    this.parseDataChanges();
  }

  private clearDataWhenInstructedTo() {
    this.userService.cookieIdToClear.subscribe((cid) => {
      try {
        localStorage.removeItem(`${STORAGE_KEY_PREFIX}${cid}`);
      } catch (e) {
        // localStorage unavailable, no action required
      }
    });
  }

  private generateStorageKeys() {
    this.userIdentifier.subscribe((identifier) =>
      this.storageKey.next(identifier ? `${STORAGE_KEY_PREFIX}${identifier}` : null),
    );
  }

  private resetDataWhenNoCurrentUserExists() {
    this.userIdentifier.pipe(filter((identifier) => !identifier)).subscribe(() => this.serializedData.next(null));
  }

  private loadInitialDataWhenUserChanges() {
    this.storageKey
      .pipe(
        filter((key) => !!key),
        map((key) => {
          try {
            return localStorage.getItem(key) || undefined;
          } catch (e) {
            return undefined;
          }
        }),
      )
      .subscribe((data) => this.serializedData.next(data));
  }

  private monitorDataChangesForCurrentUser() {
    this.windowService.nativeWindow.addEventListener('storage', async (e) => {
      const latestStorageKey = await firstValueFrom(this.storageKey);
      if (e.key === latestStorageKey && e.storageArea === localStorage) {
        this.serializedData.next(e.newValue);
      }
    });
  }

  private parseDataChanges() {
    this.serializedData.subscribe((data) => {
      try {
        this.userActivity.next(JSON.parse(data));
      } catch (e) {
        this.userActivity.next({ taskSubmissions: [], viewedDocuments: [] });
      }
    });
  }

  protected async store(data: UserActivity): Promise<void> {
    Object.keys(localStorage)
      .filter((key) => !!key.match(STORAGE_KEY_PREFIX))
      .forEach((match) => localStorage.removeItem(match));

    const storageKey = await firstValueFrom(this.storageKey);
    if (storageKey) {
      try {
        localStorage.setItem(storageKey, JSON.stringify(data));
      } catch (e) {
        // localStorage unavailable; no action required as data is still in memory
      }
    }

    this.userActivity.next(data);
  }
}
