import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-overseas-police-check-country',
  templateUrl: './overseas-police-check-country.component.html',
  styleUrls: ['./overseas-police-check-country.component.scss'],
})
export class OverseasPoliceCheckCountryComponent {
  @Input() checksFormGroup: UntypedFormGroup;
  @Input() countryLabel: string;
  @Input() numOfChecks;
  @Input() task: Task;
  @Input() index: number;
  @Input() hintPath: string;
  @Output() delete: EventEmitter<any> = new EventEmitter();

  strings = strings.components.tasks.policeCheck.overseasCheck;

  radioOptions = [
    {
      labelText: 'I already have a police check report',
      value: 'HAVE',
    },
    {
      labelText: 'I have applied for a police check report',
      value: 'APPLIED',
    },
    {
      labelText: 'None of the above',
      value: 'NONE',
    },
  ];

  deleteThisCountry() {
    this.delete.emit();
  }
}
