import { DateTime } from 'luxon';

import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

export class EnrolmentError {
  public dateCreated: Date;
  public dateModified: Date;
  public internalReference: string;
  public ruleType: string;
  public ruleCategory: string;
  public message: string;
  public waivable: boolean;
  public declinable: boolean;
  public owner: string;
  public targets: { code: string };

  constructor(obj: Partial<EnrolmentError>) {
    Object.assign(this, obj);
  }

  public static deserialize(payload: Partial<EnrolmentError>): EnrolmentError {
    if (payload === null) {
      return null;
    }

    const clone = camelizeKeys(payload);
    clone.dateCreated = DateTime.fromISO(clone.dateCreated).toJSDate();
    clone.dateModified = DateTime.fromISO(clone.dateModified).toJSDate();

    return new EnrolmentError(clone);
  }

  public static serialize(instance: Partial<EnrolmentError>) {
    const clone = { ...instance };
    return { enrolment_error: snakeifyKeys(clone) };
  }
}
