<uc-form-template [formGroup]="caApplicationForm">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header [title]="strings.cv.title">
      <p>{{ strings.cv.description[0] }}</p>
      <p>{{ strings.cv.description[1] }}</p>
      <p>{{ strings.cv.description[2] }}</p>
      <p>{{ strings.cv.description[3] }}</p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint [task]="task" path="/documents/cv"></uc-task-hint>
      <uc-file-upload
        [title]="strings.uploadQuestion"
        categoryCode="CV"
        [year]="applicationYear"
        usedInTask="true"
        required="true"
      ></uc-file-upload>
    </uc-subsection>
  </uc-section>

  <uc-form-template>
    <uc-section>
      <uc-section-header
        title="{{ strings.confidentialRefereeReport.title }}"
        titleTooltipContent="{{ strings.confidentialRefereeReport.tooltip }}"
      >
        <p [innerHTML]="strings.confidentialRefereeReport.description"></p>
      </uc-section-header>
      <uc-confidential-referee-report
        [firstRefereeReport]="caApplicationForm.get('firstRefereeReport')"
        [secondRefereeReport]="caApplicationForm.get('secondRefereeReport')"
        [thirdRefereeReport]="refereeThree ? caApplicationForm.get('thirdRefereeReport') : null"
        [task]="task"
        [hintPath]="hintPath"
      ></uc-confidential-referee-report>
    </uc-section>
  </uc-form-template>
  <uc-form-template>
    <uc-section>
      <uc-section-header title="{{ strings.caAdditionalInformation.title }}"> </uc-section-header>
      <uc-yes-no
        yesOptionLabel="Yes"
        noOptionLabel="No"
        formControlName="hasChildRemoved"
        required="true"
        questionString="{{ strings.caAdditionalInformation.hasChildRemoved }}"
        conditionalDescription="Yes"
        [task]="task"
        path="/applicant/ca_compliance/has_child_removed"
        testSelector="has-child-removed"
      >
        <uc-yes-content>
          <uc-subsection>
            <uc-task-hint [task]="task" path="/applicant/ca_compliance/child_removed"></uc-task-hint>
            <uc-subsection-question>
              {{ strings.caAdditionalInformation.childRemovedGuidanceText }}
              <uc-required-field></uc-required-field>
            </uc-subsection-question>
            <uc-text-area testSelector="child-removed" formControlName="childRemoved"></uc-text-area>
          </uc-subsection>
        </uc-yes-content>
      </uc-yes-no>

      <uc-yes-no
        yesOptionLabel="Yes"
        noOptionLabel="No"
        formControlName="hasDisciplinaryProceeding"
        required="true"
        questionString="{{ strings.caAdditionalInformation.hasDisciplinaryProceeding }}"
        conditionalDescription="Yes"
        [task]="task"
        path="/applicant/ca_compliance/has_disciplinary_proceeding"
        testSelector="has-disciplinary-proceedings"
      >
        <uc-yes-content>
          <uc-subsection>
            <uc-task-hint [task]="task" path="/applicant/ca_compliance/disciplinary_proceeding"></uc-task-hint>
            <uc-subsection-question>
              {{ strings.caAdditionalInformation.childRemovedGuidanceText }}
              <uc-required-field></uc-required-field>
            </uc-subsection-question>
            <uc-text-area
              testSelector="disciplinary-proceeding"
              formControlName="disciplinaryProceeding"
            ></uc-text-area>
          </uc-subsection>
        </uc-yes-content>
      </uc-yes-no>

      <uc-yes-no
        yesOptionLabel="Yes"
        noOptionLabel="No"
        formControlName="hasInvestigationProtectionOrder"
        required="true"
        questionString="{{ strings.caAdditionalInformation.hasInvestigationProtectionOrder }}"
        conditionalDescription="Yes"
        [task]="task"
        path="/applicant/ca_compliance/has_investigation_protection_order"
        testSelector="has-investigation-order"
      >
        <uc-yes-content>
          <uc-subsection>
            <uc-task-hint [task]="task" path="/applicant/ca_compliance/investigation_protection_order"></uc-task-hint>
            <uc-subsection-question>
              {{ strings.caAdditionalInformation.childRemovedGuidanceText }}
              <uc-required-field></uc-required-field>
            </uc-subsection-question>
            <uc-text-area
              testSelector="investigation-protection-order"
              formControlName="investigationProtectionOrder"
            ></uc-text-area>
          </uc-subsection>
        </uc-yes-content>
      </uc-yes-no>

      <uc-yes-no
        yesOptionLabel="Yes"
        noOptionLabel="No"
        formControlName="hasDeniedEntry"
        required="true"
        questionString="{{ strings.caAdditionalInformation.hasDeniedEntry }}"
        conditionalDescription="Yes"
        [task]="task"
        path="/applicant/ca_compliance/has_denied_entry"
        testSelector="has-denied-entry"
      >
        <uc-yes-content>
          <uc-subsection>
            <uc-task-hint [task]="task" path="/applicant/ca_compliance/denied_entry"></uc-task-hint>
            <uc-subsection-question>
              {{ strings.caAdditionalInformation.childRemovedGuidanceText }}
              <uc-required-field></uc-required-field>
            </uc-subsection-question>
            <uc-text-area testSelector="denied-entry" formControlName="deniedEntry"></uc-text-area>
          </uc-subsection>
        </uc-yes-content>
      </uc-yes-no>
    </uc-section>
  </uc-form-template>
  <h4>{{ declarationStrings.cacDeclaration.header }}</h4>
  <div class="declaration text-content" [innerHTML]="declarationStrings.cacDeclaration.html"></div>
  <uc-task-hint [task]="task" [model]="hintPath" path="/declaration_cac_agree"></uc-task-hint>

  <uc-form-field-label>
    {{ declarationStrings.agreeToDeclaration }}<uc-required-field></uc-required-field>
  </uc-form-field-label>
  <uc-checkbox-input-group
    groupName="dec-check"
    [options]="declarationOptions"
    [formControl]="caApplicationForm.get('declarationCheck')"
  >
  </uc-checkbox-input-group>
</uc-form-template>
