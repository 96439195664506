import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { SelectOption } from '@shared/components/organisms/search-filters/search-filters.component';
import { ExtraOption } from '@shared/models/extra-option';
import { ReferenceData } from '@shared/models/reference-data';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-reference-data-multi-selector',
  templateUrl: './reference-data-multi-selector.component.html',
  styleUrls: ['./reference-data-multi-selector.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferenceDataMultiSelectorComponent),
      multi: true,
    },
  ],
})
export class ReferenceDataMultiSelectorComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() labelName: string;
  @Input() type: string;
  @Input() options: { labelText: string; value: string }[];
  @Input() filter: string[];
  @Input() noIcon = false;
  @Input() required = false;
  @Input() params: { [key: string]: string } = {};
  @Input() ignoreCache = false;
  @Input() extraOptions: ExtraOption[];
  @Input() filterFn;
  @Input() searchFilter = true;
  @Input() innerControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Output() selectAllEmitter = new EventEmitter<boolean>();
  @Output() selectedOptionEmitter = new EventEmitter<SelectOption[]>();

  log: Logger;
  strings = strings.components.molecules.multiSelect;
  hasValue;

  totalOptions: { labelText: string; value: string }[];
  isDisabled: boolean;

  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'labelText',
    selectAllText: this.strings.selectAll,
    unSelectAllText: this.strings.clearAll,
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  private propagateChange: (value: unknown) => void;

  constructor(
    loggingService: LoggingService,
    private referenceData: ReferenceDataService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get stringValue(): string {
    if (this.options && this.options.length) {
      const stringVal = this.options.find((x) => this.innerControl.value === x.value);
      return stringVal?.labelText;
    } else {
      return this.innerControl.value;
    }
  }

  ngOnChanges(change) {
    if (change.filter && this.options) {
      this.updateTotalOptions();
    }
  }

  // updates the totalOptions array so that filtered values are removed.
  // if the current value is filtered it can still be displayed.
  private updateTotalOptions() {
    this.totalOptions = this.options.filter((opt) => {
      if (opt.value === this.innerControl.value) {
        return true;
      } else {
        return !this.filter.find((filterCode) => filterCode === opt.value);
      }
    });
  }

  ngOnInit() {
    this.dropdownSettings.allowSearchFilter = this.searchFilter;
    if (this.type) {
      this.referenceData
        .getByType(this.type, {
          params: this.params,
          ignoreCache: this.ignoreCache,
        })
        .subscribe((refs) => {
          this.subscribeRefs(refs);
        });
    }
    if (!this.type) {
      this.setInerConrolWithNoType();
    } else {
      this.setInerConrolWithType();
    }
  }

  private subscribeRefs(refs: ReferenceData[]) {
    let reference = refs;
    if (this.filterFn) {
      reference = refs.filter(this.filterFn);
    }
    this.options = reference.map((ref) => {
      return {
        labelText: ref.description,
        value: ref.code,
      };
    });
    if (this.extraOptions) {
      this.addExtraOptions();
    }
    this.setFilterOptions();
  }

  private setFilterOptions() {
    if (this.filter) {
      this.updateTotalOptions();
    } else {
      this.totalOptions = this.options;
    }
  }

  private setInerConrolWithType() {
    this.innerControl.valueChanges
      .pipe(
        filter((a) => {
          if (a == null || a === '') {
            this.propagateChange?.('');
            this.hasValue = false;
          }
          return !!a;
        }),
      )
      .subscribe((refData: ReferenceData[]) => {
        this.hasValue = !!refData && refData.length > 0;
        this.propagateChange?.(refData);
      });
  }

  private setInerConrolWithNoType() {
    this.innerControl.valueChanges.subscribe((val) => {
      this.selectedOptionEmitter.emit([...this.innerControl.value]);
      this.hasValue = !!(val && val.length);
      this.propagateChange?.(val);
    });
  }

  private addExtraOptions() {
    this.extraOptions.map((opt) => {
      const { value, labelText } = opt;
      this.options.splice(opt.position, 0, { value, labelText });
    });
  }

  /**
   * Write a new value to the element.
   */
  writeValue(obj: ReferenceData): void {
    if (!obj) {
      this.hasValue = false;
      return;
    }
    this.setHasValue(obj);
    this.setInnerControl(obj);
    this.shouldUpdateOptions();
  }

  private setHasValue(obj) {
    this.hasValue = !!(obj.code || obj.length);
    if (typeof obj === 'number') {
      this.hasValue = !!obj;
    }
  }

  private setInnerControl(obj) {
    if (this.type) {
      this.innerControl.setValue(obj.code, { emitEvent: false });
    } else {
      this.innerControl.setValue(obj, { emitEvent: false });
    }
  }

  private shouldUpdateOptions() {
    if (this.filter && this.options) {
      this.updateTotalOptions();
    }
  }

  registerOnChange(fn: (unknown) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  selectAll(status: boolean) {
    this.selectAllEmitter.emit(status);
  }
}
