import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-university-entrance',
  templateUrl: './university-entrance.component.html',
})
export class UniversityEntranceComponent {
  @Input() task: Task;
  @Input() ueForm: UntypedFormGroup;
  maximumAttained: number = new Date().getFullYear() + 3;
  // 3 years in the future, 70 in the past
  attainedRange: 73;

  strings = strings.components.tasks.universityAdmission;
}
