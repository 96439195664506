<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section *ngIf="isLoading">
    <div class="loading-placeholder">
      <uc-spinner></uc-spinner>
    </div>
  </uc-section>

  <uc-section [formGroup]="taskForm">
    <uc-section-header [title]="headerText"></uc-section-header>
    <uc-subsection class="award__subsection" *ngIf="hasPreviousEnrolledQuals">
      <p class="award__description">
        {{ strings.changeGuidance }}
      </p>

      <div class="award__container" *ngFor="let qualification of fullAwardEnrolments">
        <h3 class="qualification-heading">
          {{ qualification.qualificationAnswer.title }} ({{ qualification.qualificationAnswer.code }})
        </h3>

        <div class="award__qual-tag--wrapper">
          <uc-tag-list
            *ngIf="qualification.subjectAnswer"
            [subjectAnswer]="qualification.subjectAnswer"
            [cropLongText]="false"
            [subjectOptions]="qualification.qualificationAnswer?.subjectOptions"
          ></uc-tag-list>
        </div>
      </div>

      <uc-radio-input-group
        *ngIf="showChangeOptions"
        [label]=""
        formControlName="changingQualification"
        [options]="changeQualOptions"
        [displayHorizontally]="true"
        groupName="change-options"
        ngDefaultControl
      >
      </uc-radio-input-group>
    </uc-subsection>

    <uc-enrolment-selector
      *ngIf="showEnrolmentSelector"
      [process]="applicationProcess"
      [year]="applicationYear"
      [qualificationEnrolments]="loadedQualSelectorContext"
      [qualificationOptions]="qualificationData"
      (qualificationAnswered)="onNewQualificationAnswer($event)"
      [showQualificationSelector]="!hasPreviousEnrolledQuals || taskForm.get('changingQualification').value === true"
    >
    </uc-enrolment-selector>

    <uc-section *ngIf="!enrolmentSelectorLoaded">
      <div class="loading-placeholder">
        <uc-spinner></uc-spinner>
      </div>
    </uc-section>
  </uc-section>

  <uc-section
    [formGroup]="formGroup"
    *ngIf="taskForm.get('changingQualification').value === true && showEnrolmentSelector"
  >
    <uc-yes-no
      [required]="true"
      [task]="task"
      path="/application/will_complete_award"
      [questionString]="strings.willCompleteAward"
      formControlName="willCompleteAward"
      testSelector="will-complete"
    >
    </uc-yes-no>
  </uc-section>
</uc-form-template>
