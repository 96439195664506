import { environment } from '@environment';
import { UserTypes } from '@shared/models/user';

import { externalUrls } from './urls.constants';

export const links = {
  studentWebLink: externalUrls.UCSW.COEHOME,
  resolveOfferLink: externalUrls.UCSW.ENROFF,
  profilePageLink: externalUrls.UCSW.CONTALL,
};

export interface ISidenavOptions {
  isEmbargoed?: boolean;
}

export const sideNavLinks = (options: ISidenavOptions = {}) => {
  if (environment.scope !== UserTypes.agent) {
    const accountLinks = [
      {
        href: externalUrls.UCSW.REFDUE,
        title: 'REFUNDS DUE',
      },
      {
        href: externalUrls.UCSW.PAYPEND,
        title: 'SCHOLARSHIP DUE',
      },
      {
        href: externalUrls.UCSW.UCCARD,
        title: 'CANTERBURY CARD',
      },
    ];

    const toolsLinks = [
      {
        newTab: true,
        href: externalUrls.ucExams,
        title: 'EXAMS, AEGROTATS AND APPEALS AGAINST RESULTS',
      },
      {
        href: externalUrls.UCSW.APPGRAD,
        title: 'APPLY TO GRADUATE',
      },
      {
        href: externalUrls.UCSW.ENGPRAC,
        title: 'PRACTICAL REQUIREMENTS FOR BE(HONS)',
      },
      {
        href: externalUrls.UCSW.THESIS,
        title: 'THESIS INFORMATION',
      },
      {
        newTab: true,
        href: externalUrls.timetable,
        title: 'TIMETABLE',
      },
      {
        newTab: true,
        href: externalUrls.ucAPlusStudent,
        title: 'LEARN (MOODLE)',
      },
      {
        newTab: true,
        href: externalUrls.ucITAccount,
        title: 'MY IT ACCOUNT',
      },
      {
        newTab: true,
        href: externalUrls.ucLibrary,
        title: 'LIBRARY',
      },
      {
        newTab: true,
        href: externalUrls.ucOneDrive,
        title: 'MY ONEDRIVE',
      },
      {
        newTab: true,
        href: externalUrls.studentEmailLogin,
        title: 'MY STUDENT EMAIL LOGIN',
      },
      {
        newTab: true,
        href: externalUrls.ucCareers,
        title: 'Career Advice, Jobs & Internships',
      },
    ];

    if (!options.isEmbargoed) {
      accountLinks.unshift({
        href: externalUrls.UCSW.PAYDUE,
        title: 'PAYMENTS DUE',
      });
      toolsLinks.unshift({
        href: externalUrls.UCSW.INTTRNS,
        title: 'INTERNAL TRANSCRIPT AND RESULTS',
      });
    }

    return [
      {
        title: 'MY ACCOUNT',
        links: accountLinks,
      },
      {
        title: 'TOOLS',
        links: toolsLinks,
      },
    ];
  } else if (environment.scope === UserTypes.agent) {
    return [
      {
        title: 'USEFUL LINKS',
        links: [
          {
            newTab: true,
            title: 'Agent Toolkit',
            href: externalUrls.internationalToolkit,
          },
          {
            newTab: true,
            title: 'GPA Requirements',
            href: externalUrls.gpaRequirements,
          },
          {
            newTab: true,
            title: 'Check admission requirements',
            href: externalUrls.programmeRelatedInformation,
          },
          {
            newTab: true,
            title: 'English language requirements',
            href: externalUrls.englishLanguageRequirements,
          },
          {
            newTab: true,
            title: 'Qualifications at UC',
            href: externalUrls.qualificationsAtUC,
          },
        ],
      },
    ];
  }
};
