import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import strings from '@constants/strings.constants';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
  UCFormElement,
} from '@shared/services/form-model-mapper/form';
import { ApplicationService } from '@shared/services/application/application.service';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { Application } from '@shared/models/application';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'uc-financial-information',
  templateUrl: './financial-information.component.html',
})
export class FinancialInformationComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  strings = strings.components.tasks.financialInformation;
  financialForm: UCElementGroup;
  log: Logger;
  financialPage: UntypedFormGroup;
  currentApplication: Application;

  private createForm(): UCElementGroup {
    return group({
      sslGroup: group({
        rebateCategory: control({
          model: 'application',
          path: '/rebateCategory',
          defaultState: { code: 'NOT APPLICABLE' },
          validators: [Validators.required],
          inMap: refDataToValue,
          outMap: valueToRefData,
        }),
        declarationRebateCategoryDistance: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'application',
          path: '/declarationRebateCategoryDistance',
        }),
        rebateCategoryOther: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'application',
          path: '/rebateCategoryOther',
        }),
      }),
    });
  }
  constructor(
    private formMapper: FormModelMapperService,
    private applicationService: ApplicationService,
    private logService: LoggingService,
  ) {
    super();
    this.log = logService.createLogger(this);
  }

  public updateFormValidity(err: UCError) {
    this.formMapper.updateFormValidity(err.data, this.financialForm);
  }

  get rebateCategory(): string {
    return this.financialPage.get('sslGroup.rebateCategory').value;
  }

  set rebateCategory(value: string) {
    this.financialPage.get('sslGroup.rebateCategory').setValue(value);
  }

  get declarationRebateCategoryDistance(): UCFormElement {
    return this.financialForm.controls['sslGroup'].controls['declarationRebateCategoryDistance'];
  }

  get rebateCategoryOtherForm(): UCFormElement {
    return this.financialForm.controls['sslGroup'].controls['rebateCategoryOther'];
  }

  ngOnInit() {
    this.financialForm = this.createForm();
    this.financialPage = this.financialForm.asControl() as UntypedFormGroup;

    this.applicationService.application
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;

        this.formMapper.updateFormFromModel(this.financialForm, null, application);
      });
  }

  public update() {
    if (this.rebateCategory !== 'DISTANCE') {
      this.declarationRebateCategoryDistance.control.reset();
    }
    if (this.rebateCategory !== 'OTHER REBATE') {
      this.rebateCategoryOtherForm.control.reset();
    }

    this.formMapper.updateModelFromForm(this.financialForm, null, this.currentApplication);

    this.applicationService.updateApplication(this.currentApplication).subscribe(
      (a) => {
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('error updating the application');
      },
    );
  }
}
