import { get } from 'lodash-es';

import { internalUrls } from '@shared/constants/internalUrls';
import { SUMMARY_TYPES } from '@shared/constants/states.constants';
import { externalUrls } from '@shared/constants/urls.constants';
import { isUrlFunction } from '@shared/helpers/isUrlFunction';
import { camelizeKeys } from '@shared/helpers/serialization';

export class ApplicationEnrolmentSummaryState {
  href: string;
  state: string;
  canWithdraw: string;

  constructor(data) {
    Object.assign(this, data);
  }

  public static deserialize(payload): ApplicationEnrolmentSummaryState {
    if (payload == null) {
      return null;
    }
    return new ApplicationEnrolmentSummaryState(camelizeKeys(payload));
  }
}

export class SummaryState {
  href: string;
  state: string;
}

export class SummaryError {
  status: string;
  detail: string;
  source: { pointer: string };

  constructor(data) {
    Object.assign(this, data);
  }
}

export class SummaryAction {
  label: string;
  action: string;
  href?: string;
  academicYear?: string;

  constructor(options) {
    this.href = null;
    Object.assign(this, options);
  }

  get urlPath(): string {
    const url = get(externalUrls, this.href, internalUrls.dashboard.join('/'));

    if (this.href === 'UCSW.COEHOME' && url && this.academicYear && isUrlFunction(url)) {
      return `${url(this.academicYear)}`;
    } else {
      return url;
    }
  }

  static deserialize(payload): SummaryAction {
    if (!payload) {
      return null;
    }
    return new SummaryAction(camelizeKeys(payload));
  }
}

export class AcademicYearEnrolmentSummary {
  academicYear: string;
  state: string;
  processName: string;
  actions: {
    primary: SummaryAction;
    secondary: SummaryAction[];
  };
  application?: SummaryState;
  applicationEnrolments?: ApplicationEnrolmentSummaryState[];
  enrolment?: SummaryState;
  changeOfEnrolment?: SummaryState;
  changeOfApplication?: SummaryState;
  offerDecision?: SummaryState;
  latestSummaryType: string;
  errors?: SummaryError[];
  archived?: boolean;
  courseSelectionState?: string;

  constructor(data) {
    Object.assign(this, data);
  }

  public static deserialize(payload) {
    const summaryTypeOrder = [
      SUMMARY_TYPES.CHANGE_OF_ENROLMENT,
      SUMMARY_TYPES.ENROLMENT,
      SUMMARY_TYPES.CHANGE_OF_QUALIFICATION,
      SUMMARY_TYPES.APPLICATION_ENROLMENTS,
      SUMMARY_TYPES.APPLICATION,
    ];

    if (payload == null) {
      return null;
    }

    payload.latestSummaryType = summaryTypeOrder.find((el) => !!payload[el] && payload[el].length !== 0);

    if (payload.applicationEnrolments != null) {
      payload.applicationEnrolments = payload.applicationEnrolments.map((ae) => {
        return ApplicationEnrolmentSummaryState.deserialize(ae);
      });
    }

    if (payload.errors != null) {
      payload.errors = payload.errors.map((err) => new SummaryError(err));
    }

    const secondaryActions = get(payload, 'actions.secondary');

    if (secondaryActions && secondaryActions.length > 0) {
      payload.actions.secondary = secondaryActions.map((action) => {
        action.academicYear = payload.academicYear;
        return new SummaryAction(action);
      });
    }

    const primaryAction = get(payload, 'actions.primary');

    if (primaryAction) {
      payload.actions.primary.academicYear = payload.academicYear;
    }
    return new AcademicYearEnrolmentSummary(camelizeKeys(payload));
  }
}

export class ArchivedEnrolmentSummary {
  href: string;
  state: string;
  academicYear: string;
  enrolledQualifications: { code: string }[];

  constructor(data) {
    Object.assign(this, data);
  }

  public static deserialize(payload): ArchivedEnrolmentSummary {
    if (payload == null) {
      return null;
    }
    return new ArchivedEnrolmentSummary(camelizeKeys(payload));
  }
}

export class EnrolmentSummary {
  academicYears: AcademicYearEnrolmentSummary[];
  archivedEnrolments: ArchivedEnrolmentSummary[];

  constructor(data) {
    Object.assign(this, data);
  }

  get uniqueYearsInSummary(): string[] {
    const academicYears = this.academicYears.map((el) => el.academicYear);
    const archivedYears = this.archivedEnrolments.map((el) => el.academicYear);

    const allYearsInSummary = academicYears.concat(archivedYears).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    return allYearsInSummary.filter((el, i) => allYearsInSummary.indexOf(el) === i);
  }

  public static deserialize(payload): EnrolmentSummary {
    if (payload == null) {
      return null;
    }

    const data = payload.enrolment_summary || payload;
    const camelized = camelizeKeys(data);

    camelized.archivedEnrolments = camelized.archivedEnrolments.map((ae: ArchivedEnrolmentSummary) => {
      return ArchivedEnrolmentSummary.deserialize(camelizeKeys(ae));
    });
    camelized.academicYears = camelized.academicYears.map((ay: AcademicYearEnrolmentSummary) => {
      return AcademicYearEnrolmentSummary.deserialize(camelizeKeys(ay));
    });

    return new EnrolmentSummary(camelized);
  }
}
