<uc-message-banner
  [showCloseButton]="true"
  *ngIf="showAgentReminder"
  type="info"
>
  <span [innerHTML]="agentReminderString"></span>
</uc-message-banner>
<uc-form-template [formGroup]="enrolPage">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header [title]="strings.usingMyUc.header">
      <p [innerHTML]="strings.usingMyUc.description"></p>
      <div class="accented-character-warning">
        <p><strong [innerHTML]="strings.usingMyUc.accentedCharacterWarning.header"></strong></p>
        <p [innerHTML]="strings.usingMyUc.accentedCharacterWarning.description"></p>
      </div>
      <uc-process-help-modal-button></uc-process-help-modal-button>
    </uc-section-header>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ strings.identificationDetailsSection.header }}">
      <p>{{ strings.identificationDetailsSection.description[0] }}</p>
      <p>{{ strings.identificationDetailsSection.description[1] }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/birth_date"
      ></uc-task-hint>
      <uc-subsection-question>
        {{ strings.identificationDetailsSection.birthDateQuestion }} <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-date-selector
          [baseYear]="dateOfBirthYear"
          [range]="85"
          formControlName="birthDate"
          required="yes"
          testSelector="dob"
        ></uc-date-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-task-hint
      class="sub-section"
      [task]="task"
      path="/applicant/legal_name"
    ></uc-task-hint>

    <uc-legal-name-declaration
      [task]="task"
      [applicationYear]="applicationYear"
      (nameAdd)="this.otherNames.push()"
      (nameRemove)="this.otherNames.removeAt($event)"
      [otherNamesLength]="otherNamesLength"
      [namesModel]="enrolPage.get('names')"
    ></uc-legal-name-declaration>

    <uc-subsection>
      <uc-gender-selector
        [task]="task"
        [genderForm]="enrolPage.get('genderGroup')"
      ></uc-gender-selector>
    </uc-subsection>

    <uc-subsection>
      <uc-personal-pronouns-selector [pronounsGroup]="enrolPage.get('pronounsGroup')"></uc-personal-pronouns-selector>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="showAgentSection">
    <uc-section-header title="{{ strings.agentDetails.header }}"></uc-section-header>

    <uc-agent-details
      [task]="task"
      [agentDetails]="enrolPage.get('agentDetails')"
    ></uc-agent-details>
  </uc-section>

  <uc-section [formGroup]="enrolPage.get('citizenship')">
    <uc-section-header title="{{ strings.citizenshipSection.header }}"></uc-section-header>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/applicant/demographic/citizenship"
      ></uc-task-hint>
      <uc-subsection-question
        >{{ strings.citizenshipSection.categoryQuestion }}<uc-required-field></uc-required-field
      ></uc-subsection-question>
      <uc-form-input>
        <uc-reference-data-selector
          type="citizenship_category"
          formControlName="citizenCategory"
          required="yes"
          [showBlankOption]="false"
        ></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection *ngIf="!isNzOrAus(this.enrolPage.get('citizenship.citizenCategory').value)">
      <uc-form-input>
        <uc-task-hint
          [task]="task"
          path="/applicant/demographic/passport_country"
        ></uc-task-hint>
        <uc-subsection-question>
          {{ strings.citizenshipSection.countryQuestion }} <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-reference-data-selector
          type="country"
          [filter]="['NZL', 'AUS']"
          formControlName="citizenCountry"
          required="yes"
          [showBlankOption]="false"
        ></uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/application/study_in_nz"
      ></uc-task-hint>
      <uc-subsection-question
        >{{ strings.citizenshipSection.liveInNzQuestion }}<uc-required-field></uc-required-field
      ></uc-subsection-question>
      <uc-subsection-minor-text>
        {{ strings.citizenshipSection.liveInNzDescription }}
      </uc-subsection-minor-text>

      <uc-form-input>
        <uc-radio-input-group
          formControlName="studyInNz"
          [options]="radioOptions"
          label=""
          [displayHorizontally]="true"
          groupName="study-nz"
        ></uc-radio-input-group>
      </uc-form-input>

      <uc-message-banner
        type="info"
        *ngIf="showStudyAwayFromNZInfo"
      >
        <p><span innerHTML="{{ studyAwayFromNZInfo }}"></span></p>
      </uc-message-banner>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/documents/identity"
      ></uc-task-hint>
      <uc-subsection-question [required]="identityDocumentRequired">
        {{ strings.citizenshipSection.passportInfo }}
      </uc-subsection-question>
      <uc-message-banner
        type="info"
        *ngIf="isMCEDProcess"
        class="identity-guidance"
      >
        <span [innerHTML]="strings.citizenshipSection.mcedGuidance"></span>
      </uc-message-banner>
      <p
        class="upload-guidance"
        *ngIf="isStarProcess"
        [innerHTML]="strings.citizenshipSection.starGuidance"
      ></p>
      <uc-file-upload
        [year]="applicationYear"
        title="Identity document"
        categoryCode="IDENTITY"
        usedInTask="true"
      >
      </uc-file-upload>
    </uc-subsection>

    <uc-subsection *ngIf="isNzOrAus(enrolPage.get('citizenship.citizenCategory').value, 'pr')">
      <uc-subsection-question>{{ strings.citizenshipSection.residentsVisa }}</uc-subsection-question>
      <uc-file-upload
        [year]="applicationYear"
        title="Residents visa"
        categoryCode="VISA"
        usedInTask="true"
      ></uc-file-upload>
    </uc-subsection>
  </uc-section>

  <uc-section [formGroup]="enrolPage.get('contactDetails')">
    <uc-section-header title="{{ strings.contactDetailsSection.header }}">
      <p>{{ strings.contactDetailsSection.description }}</p>
    </uc-section-header>

    <uc-subsection>
      <uc-form-input>
        <uc-subsection-question>{{ strings.contactDetailsSection.emailSubsectionQuestion }}</uc-subsection-question>
        <uc-task-hint
          [task]="task"
          path="/applicant/contact_detail/email_address"
        ></uc-task-hint>
        <uc-string-input
          testSelector="email"
          class="input-medium"
          label="{{ strings.contactDetailsSection.emailLabel }}"
          formControlName="email"
          required="yes"
        ></uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-form-input>
        <uc-task-hint
          [task]="task"
          path="/applicant/contact_detail/contact_number"
        ></uc-task-hint>
        <uc-subsection-question [required]="true">{{ phoneStrings.phoneNumberQuestion }}</uc-subsection-question>
        <uc-subsection-minor-text>{{ phoneStrings.mobileHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          formControlName="mobileNum"
          labelText="{{ phoneStrings.mobileLabel }}"
          path="/applicant/contact_detail/mobile_number"
          [task]="task"
          testSelector="contact-mobile"
        >
        </uc-phone-selector>
      </uc-form-input>
      <uc-form-input>
        <uc-subsection-minor-text>{{ phoneStrings.landlineHint }}</uc-subsection-minor-text>
        <uc-phone-selector
          formControlName="landlineNum"
          labelText="{{ phoneStrings.landlineLabel }}"
          path="/applicant/contact_detail/landline_number"
          [task]="task"
          testSelector="contact-landline"
        >
        </uc-phone-selector>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-subsection-question>{{ strings.contactDetailsSection.addressQuestion }}</uc-subsection-question>
      <uc-form-input>
        <uc-address-selector
          testSelector="contact"
          [task]="task"
          formControlName="address"
        ></uc-address-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="showStudentLoan">
    <uc-student-loan></uc-student-loan>
  </uc-section>
</uc-form-template>
