import { deepClone } from '@shared/helpers/general';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

import { EnrolledCourse } from './course';
import { EnrolmentError } from './enrolment-error';
import { QualificationOccurrence } from './qualification-occurrence';
import { ReferenceData } from './reference-data';
import { SubjectQuestion } from './subject-question';

export interface QualificationCategory {
  category: string;
}

export class QualificationSummary {
  public title?: string;
  public code: string;
  public description?: string;
  public organisationUnit: ReferenceData;
  public level: string;
  public courseSelectionGuidanceText: string;
  public paperFormUrl: string;
  public guidance: string;
  public isPostgraduate: boolean;
  public includesThesis: boolean;
  public defaultCurriculumGroup: string;
  public qualificationOccurrences: QualificationOccurrence[] = [];
  public categories: QualificationCategory[] = [];

  constructor(data) {
    if (!data.code) {
      throw new Error(`Can't create a QualificationSummary without the code`);
    }
    Object.assign(this, data);
  }

  // eslint-disable-next-line complexity
  static deserialize(payload) {
    if (payload === null) {
      return null;
    }
    const deserialized = camelizeKeys(payload.qualification || payload);
    if (deserialized.qualificationOccurrences) {
      deserialized.qualificationOccurrences = QualificationOccurrence.deserialize(
        deserialized.qualificationOccurrences,
      );
    } else {
      deserialized.qualificationOccurrences = [];
    }
    return new QualificationSummary(deserialized);
  }
}

export class SubjectOptions {
  label: string;
  level: number;
  optionLists: SubjectQuestion[];

  constructor(data) {
    Object.assign(this, data);
  }

  static deserialize(payload): SubjectOptions {
    if (payload === null) {
      return null;
    }
    const subjectOption = camelizeKeys(payload);
    if (subjectOption?.optionLists) {
      subjectOption.optionLists = subjectOption.optionLists.map((opt) => SubjectQuestion.deserialize(opt));
      return new SubjectOptions(subjectOption);
    }
  }
}

export class Qualification extends QualificationSummary {
  public subjectOptions: SubjectOptions[];

  constructor(data) {
    super(data);
    Object.assign(this, data);
  }

  // eslint-disable-next-line complexity
  static deserialize(payload): Qualification {
    if (payload === null) {
      return null;
    }
    const deserialized = camelizeKeys(payload.qualification || payload);
    deserialized.subjectOptions = deserialized.subjectOptions
      ? deserialized.subjectOptions.map(SubjectOptions.deserialize)
      : [];
    deserialized.subjectOptions = deserialized.subjectOptions?.sort((a, b) => a.level - b.level);
    return new Qualification(deserialized);
  }
}

export class SubjectOptionsMetaData extends ReferenceData {
  changeAction?: string;
}

export class SubjectOptionsList {
  [key: string]: SubjectOptionsMetaData[];

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload): SubjectOptionsList {
    if (!payload) {
      return null;
    }
    Object.keys(payload).forEach((key) => {
      payload[key] = payload[key].map(ReferenceData.deserialize);
      return new SubjectOptionsList(payload);
    });
    return payload;
  }

  public static serialize(instance: EnrolledQualification) {
    const serializeCollection = (collection): ReferenceData[] => {
      return collection.map((item) => {
        return ReferenceData.serialize(item);
      });
    };
    const cloned = deepClone(instance);
    if (Object.keys(cloned).length !== 0) {
      Object.keys(cloned).forEach((key) => {
        cloned[key] = cloned[key] ? serializeCollection(cloned[key]) : [];
      });
      return snakeifyKeys(cloned);
    }
    return {};
  }
}

export class EnrolledQualification {
  code: string;
  priority: number;
  newInAward?: boolean;
  firstYearInAward?: string;
  enrolledCourses?: EnrolledCourse[];
  href?: string;
  awardId?: string;
  state?: ReferenceData;
  internalReference?: string;
  qualificationOccurrence?: string;
  subjectOptions?: SubjectOptionsList;
  changeAction?: string;
  errors?: EnrolmentError[];

  constructor(obj) {
    Object.assign(this, obj);
  }

  // eslint-disable-next-line complexity
  public static deserialize(payload): EnrolledQualification {
    const deserialized = camelizeKeys(payload.enrolledQualification || payload);
    deserialized.subjectOptions = deserialized.subjectOptions
      ? SubjectOptionsList.deserialize(deserialized.subjectOptions)
      : {};
    deserialized.enrolledCourses = deserialized.enrolledCourses
      ? deserialized.enrolledCourses.map(EnrolledCourse.deserialize)
      : [];
    deserialized.errors = deserialized.errors ? deserialized.errors.map(EnrolmentError.deserialize) : [];
    return new EnrolledQualification(deserialized);
  }

  // eslint-disable-next-line complexity
  public static serialize(instance: EnrolledQualification) {
    const cloned = deepClone(instance);
    cloned.subjectOptions = cloned.subjectOptions ? SubjectOptionsList.serialize(cloned.subjectOptions) : {};
    cloned.enrolledCourses = cloned.enrolledCourses ? cloned.enrolledCourses.map(EnrolledCourse.serialize) : [];
    cloned.errors = cloned.errors ? cloned.errors.map(EnrolmentError.serialize) : [];
    cloned.awardId = cloned.awardId || null;
    return snakeifyKeys(cloned);
  }
}
