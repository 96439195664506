import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-online-terms-and-conditions',
  templateUrl: './online-terms-and-conditions.component.html',
  styleUrls: ['./online-terms-and-conditions.component.scss'],
})
export class OnlineTermsAndConditionsComponent {
  @Input() task: Task;
  @Input() termsAndConditionsForm: UntypedFormGroup;
  @Input() showOptionalItem = true;

  strings = strings.components.molecules.onlineTermsAndConditions;
}
