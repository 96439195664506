<div class="app-tile-container">
  <uc-icon iconType="cap" iconHeight="55" iconWidth="55" color="$colorMonza"></uc-icon>
  <h2 class="body-font">{{ strings.applicationHeader }}</h2>
  <div
    *ngIf="loaded && !uniqueYearsInSummary?.length"
    [innerHTML]="this.strings.appPara"
    class="application-para"
  ></div>
  <uc-button
    testSelector="apply"
    *ngIf="loaded && !uniqueYearsInSummary?.length"
    [isLoading]="displaySpinner"
    (click)="transition('apply')"
  >
    {{ strings.newApplicationButtonText }}
  </uc-button>
  <div class="year-wrapper">
    <uc-spinner *ngIf="!loaded" [useStaticPositioning]="true"></uc-spinner>
    <div *ngFor="let year of uniqueYearsInSummary; let index = index" class="year-list">
      <uc-application-year-list-item [year]="year"></uc-application-year-list-item>
    </div>
  </div>
  <uc-button
    testSelector="manage-study"
    *ngIf="loaded && uniqueYearsInSummary.length > 0"
    class="manage-study-btn"
    [isLoading]="displaySpinner"
    (click)="transition('manageMyStudy')"
  >
    {{ strings.manageMyStudyButtonText }}
  </uc-button>
</div>
