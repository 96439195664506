import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Modal } from 'ngx-modialog-11/plugins/bootstrap';
import { throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';
import { internalUrls } from '@shared/constants/internalUrls';
import {
  ChangeOfEnrolment,
  FullEnrolmentChange,
  ChangedEnrolledQualification,
} from '@shared/models/change-of-enrolment';
import { Task } from '@shared/models/task';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import {
  UCElementGroup,
  control,
  booleanToYesNo,
  yesNoToBoolean,
  group,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { FullEnrolmentService, FullEnrolmentErrorCodes } from '@shared/services/full-enrolment/full-enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-confirm-changes',
  templateUrl: './confirm-changes.component.html',
  styleUrls: ['./confirm-changes.component.scss'],
})
export class ConfirmChangesComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  @ViewChild(ConfirmationModalComponent) coeErrorModal;

  fullEnrolmentChange: FullEnrolmentChange;
  enrolmentChange: ChangeOfEnrolment;
  strings = strings.components.tasks.confirmChanges;
  qualStrings = strings.components.tasks.qualification;
  loading = true;
  log: Logger;
  elementGroup: UCElementGroup;
  formGroup: UntypedFormGroup;

  constructor(
    private processService: ProcessService,
    private fullEnrolmentService: FullEnrolmentService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    private router: Router,
    private modal: Modal,
    loggingService: LoggingService,
    private formModel: FormModelMapperService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get changesPresent() {
    const hasQualChanges =
      !!this.fullEnrolmentChange && this.fullEnrolmentChange.qualifications.some((enrol) => enrol.hasQualChanges);
    const hasCourses =
      !!this.fullEnrolmentChange && this.fullEnrolmentChange.qualifications.some((course) => course.hasCourses);
    return hasQualChanges || hasCourses;
  }

  get messageBanner() {
    return this.changesPresent ? this.strings.messageBanner : this.strings.noChangesBanner;
  }

  ngOnInit() {
    this.elementGroup = this.createForm();
    this.formGroup = this.elementGroup.asControl() as UntypedFormGroup;
    this.refreshAndGetCoe();
  }

  private createForm(): UCElementGroup {
    return group({
      willCompleteAward: control({
        validators: [Validators.required],
        model: 'changeOfEnrolments',
        path: '/willCompleteAward',
        inMap: booleanToYesNo(),
        outMap: yesNoToBoolean(),
      }),
    });
  }

  refreshAndGetCoe() {
    this.processService
      .refreshCoe()
      .pipe(
        switchMap(() => this.changeOfEnrolmentService.getChangeOfEnrolment()),
        switchMap((coe: ChangeOfEnrolment) => {
          if (!coe || coe.tainted) {
            const error = !coe ? FullEnrolmentErrorCodes.noCoe : FullEnrolmentErrorCodes.taintedCoe;
            return throwError({ code: error });
          }
          this.enrolmentChange = coe;
          this.formModel.updateFormFromModel(this.elementGroup, null, this.enrolmentChange);
          return this.fullEnrolmentService.getEnrolledQualData(this.applicationYear, coe.enrolledQualifications);
        }),
      )
      .subscribe({
        next: ([quals, courses]) => {
          const qualifications = this.enrolmentChange.enrolledQualifications.map((eq) => {
            const qualification = quals.qualification.find((q) => q.code === eq.code);
            return new ChangedEnrolledQualification({
              enrolledQualification: eq,
              qualificationData: qualification,
              courses,
            });
          });
          this.fullEnrolmentChange = new FullEnrolmentChange({
            hasChanges: this.enrolmentChange.isDirty,
            qualifications,
            courses,
            year: this.applicationYear,
          });
          this.loading = false;
        },
        error: () => {
          this.showCoeErrorModal();
          this.loading = false;
          this.fullEnrolmentChange = null;
        },
      });
  }

  public showCoeErrorModal() {
    this.coeErrorModal.open();
  }

  public goToManageMyStudy() {
    this.router.navigate(internalUrls.manageMyStudy, { queryParams: { year: this.applicationYear } });
  }

  update() {
    this.formModel.updateModelFromForm(this.elementGroup, null, this.enrolmentChange);
    this.changeOfEnrolmentService
      .updateChangeOfEnrolment(new ChangeOfEnrolment({ willCompleteAward: this.enrolmentChange.willCompleteAward }))
      .subscribe({
        next: () => {
          this.log.info('persisted change of enrolment');
          this.next.emit();
        },
        error: (error) => {
          this.errors.emit();
          this.log.error('error updating change of enrolment', error);
        },
      });
  }

  updateFormValidity(): void {
    // no-op
  }
}
