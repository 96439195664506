import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { IndependentCourseEnrolmentSummary } from '@app/models/IndependentCourseEnrolmentSummary';
import { IndependentCourseEnrolmentService } from '@app/services/independent-course-enrolment/independent-course-enrolment.service';
import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { ONLINE_PROCESS_PREFIX } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import {
  AcademicYearEnrolmentSummary,
  ArchivedEnrolmentSummary,
  EnrolmentSummary,
} from '@shared/models/enrolment-summary';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-myuc-manage-my-study',
  templateUrl: './myuc-manage-my-study-template.component.html',
  styleUrls: ['./myuc-manage-my-study-template.component.scss'],
})
export class MyucManageMyStudyTemplateComponent extends UnsubscribeOnDestroy implements OnInit {
  private log: Logger;
  strings = strings.components.template.manageMyStudy;
  isLoading = false;
  displaySpinner = false;
  applicationSummaries: AcademicYearEnrolmentSummary[] = [];
  archivedEnrolments: ArchivedEnrolmentSummary[] = [];
  paramYears: string[] = [];
  canStartNewApplication = false;
  enrolmentChange: ChangeOfEnrolment;
  dashboardPath: string = internalUrls.dashboard.join('/');
  applicationYears: unknown[] = [];
  uniqueYearsInSummary: string[] = [];
  independentCourses = [];
  showTotaraLink = false;

  constructor(
    public router: Router,
    public location: Location,
    public enrolmentSummaryService: EnrolmentSummaryService,
    loggingService: LoggingService,
    private activatedRoute: ActivatedRoute,
    private refDataService: ReferenceDataService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    private onlineCourseService: OnlineCourseService,
    private courseEnrolmentService: IndependentCourseEnrolmentService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    this.displaySpinner = true;
    this.fetchViewData();
    this.courseEnrolmentService.fetchIndependentCourseEnrolmentSummary();
    this.independentCourses = [];

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.onlineCourseService.onlineCourseCode = queryParams.enrol;
      this.onlineCourseService.onlineCourseOccur = queryParams.occur;

      if (this.onlineCourseService.onlineCourseCode && this.onlineCourseService.onlineCourseOccur) {
        this.router.navigate(internalUrls.onlinePreProcessPage(''), {
          queryParams: {
            enrol: this.onlineCourseService.onlineCourseCode,
            occur: this.onlineCourseService.onlineCourseOccur,
          },
        });
      }
    });

    this.courseEnrolmentService.enrolments$.subscribe((courses) => {
      this.updateTotaraLink(courses);
      this.independentCourses.push(...courses);
    });
  }

  /**
   * Set the totara link to visible if any courses are in the enrolled state
   *
   * @param courses
   */
  updateTotaraLink(courses: IndependentCourseEnrolmentSummary[]) {
    courses.forEach((course) => {
      if (course.state === course.STATE_ENROLLED) {
        this.showTotaraLink = true;
      }
    });
  }

  enrolmentUpdated() {
    this.independentCourses = [];
    this.courseEnrolmentService.fetchIndependentCourseEnrolmentSummary();
  }

  // eslint-disable-next-line max-lines-per-function
  fetchViewData() {
    observableCombineLatest([
      this.enrolmentSummaryService.getSummary().pipe(filter((s) => !!s)),
      this.activatedRoute.queryParams.pipe(filter((p) => !!p)),
      this.refDataService.getByType('application_year'),
      this.changeOfEnrolmentService.getChangeOfEnrolment(),
    ])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        ([summary, params, availableAppYears, coe]) => {
          this.applicationSummaries = summary.academicYears;
          const appSummaryYears = this.applicationSummaries.map((app) => app.academicYear);
          this.archivedEnrolments = summary.archivedEnrolments;

          this.canStartNewApplication = !!availableAppYears.find(
            (appYear) => appSummaryYears.indexOf(appYear.code) === -1,
          );

          this.displaySpinner = false;
          if (params.year) {
            this.paramYears = params.year.split(',');
          }

          this.excludeUCOnlineClassicApplication(summary);

          this.applicationYears = this.uniqueYearsInSummary.map((year) => {
            const expanded = this.paramYears.indexOf(year) !== -1;
            return { year, expanded };
          });

          this.enrolmentChange = coe;
        },
        (err) => {
          this.log.error(err);
          this.displaySpinner = false;
        },
      );
  }

  excludeUCOnlineClassicApplication(summary: EnrolmentSummary) {
    this.uniqueYearsInSummary = summary.uniqueYearsInSummary.filter(
      (uniqueYear) =>
        !summary.academicYears
          .filter((item) => item.academicYear === uniqueYear)
          .pop()
          ?.processName.startsWith(ONLINE_PROCESS_PREFIX),
    );
  }

  transitionToNewApplication() {
    this.isLoading = true;
    this.router.navigate(internalUrls.apply);
  }

  // eslint-disable-next-line class-methods-use-this
  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }

  // eslint-disable-next-line complexity
  updateParams(data) {
    const { action, year } = data;
    if (!action || !year) {
      return;
    }

    if (action === 'remove') {
      this.paramYears = this.paramYears.filter((el) => el !== year);
    } else {
      this.paramYears.push(year);
    }
    const params = this.paramYears.length ? `?year=${this.paramYears.join(',')}` : '';
    this.location.replaceState(`/manage-my-study${params}`);
  }
}
