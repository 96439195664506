<uc-subsection>
  <p>{{ strings.confidentialRefereeReport.refereeNumber[0] }}</p>
  <uc-form-input>
    <uc-associated-person
      basePath="/associated_person/referee1"
      [task]="task"
      [hintPath]="hintPath"
      [associatedPersonForm]="firstRefereeReport"
      [strings]="strings.associatedPerson"
      testSelector="first-referee"
    ></uc-associated-person>
  </uc-form-input>
</uc-subsection>

<uc-subsection>
  <p>{{ strings.confidentialRefereeReport.refereeNumber[1] }}</p>
  <uc-form-input>
    <uc-associated-person
      basePath="/associated_person/referee2"
      [task]="task"
      [hintPath]="hintPath"
      [associatedPersonForm]="secondRefereeReport"
      [strings]="strings.associatedPerson"
      testSelector="second-referee"
    ></uc-associated-person>
  </uc-form-input>
</uc-subsection>

<uc-subsection *ngIf="thirdRefereeReport">
  <p>{{ strings.confidentialRefereeReport.refereeNumber[2] }}</p>
  <uc-form-input>
    <uc-associated-person
      basePath="/associated_person/referee3"
      [task]="task"
      [hintPath]="hintPath"
      [associatedPersonForm]="thirdRefereeReport"
      [strings]="strings.associatedPerson"
      testSelector="third-referee"
    ></uc-associated-person>
  </uc-form-input>
</uc-subsection>
