<div class="enrolment-choice" *ngFor="let enrolment of qualificationEnrolments; let i = index">
  <div *ngIf="i <= 1">
    <div *ngIf="i === 1" class="choice-controls">
      <div class="choice-tab body-font-semi">
        <span>{{ strings.altChoiceTitle }}</span>
      </div>
      <button [attr.data-cy]="'delete'" class="delete-btn" (click)="delete(i)">
        <uc-icon
          class="delete-btn-icon"
          iconType="trash"
          iconWidth="16"
          iconHeight="16"
          iconFill="$colorWhite"
        ></uc-icon>
      </button>
    </div>
    <div class="enrolment-choice-wrapper">
      <uc-subsection class="alt-guidance-text" *ngIf="i === 1">
        <p [innerHTML]="strings.altChoiceGuidance"></p>
      </uc-subsection>
      <uc-subsection>
        <uc-qualification-enrolment-selector
          (selectedQualification)="removeSelectedQualFromSecondaryOptions($event)"
          (firstQualification)="getConcurrentOptions($event, i)"
          (saveQualification)="onSaveFirst($event, i)"
          (qualForm)="qualSelectorFormEmitter($event)"
          [qualificationEnrolment]="enrolment.firstQualificationEnrolment"
          [qualificationOptions]="qualificationOptions"
          [showQualificationSelector]="showQualificationSelector"
        >
        </uc-qualification-enrolment-selector>
      </uc-subsection>

      <!-- Conditionally disable concurrent selection in the UI should be fixed or updated if
      https://ucdigitalsms.atlassian.net/browse/US-835?filter=10320 ever gets done
      -->
      <uc-subsection *ngIf="concurrentQualificationOptions[i]?.length">
        <div class="additional-qual-trigger" *ngIf="showQualificationSelector">
          <uc-form-input>
            <uc-checkbox-input
              [label]="strings.concurrentQual"
              [formControl]="enrolment.isConcurrent"
              isToggleSwitch="true"
              groupName="concurrent"
            ></uc-checkbox-input>
            <span class="clearfix explain-text">{{ strings.concurrentQualExplain }}</span>
          </uc-form-input>
        </div>
        <uc-qualification-enrolment-selector
          class="concurrent-selector"
          *ngIf="enrolment.isConcurrent?.value"
          (selectedQualification)="removeSelectedQualFromPrimaryOptions($event)"
          (saveQualification)="onSaveSecond($event, i)"
          [qualificationEnrolment]="enrolment.secondQualificationEnrolment"
          (qualForm)="qualSelectorFormEmitter($event)"
          [qualificationOptions]="concurrentQualificationOptions[i]"
          [showQualificationSelector]="showQualificationSelector"
        >
        </uc-qualification-enrolment-selector>
      </uc-subsection>
    </div>
  </div>
</div>
