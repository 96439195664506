import { Component, Input, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';

import { EnrolledQualification } from '@shared/models/qualification';
import { Task } from '@shared/models/task';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { QUAL_CATEGORIES } from '@shared/constants/categories';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Qualification } from '@shared/models/qualification';

import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

import strings from '@constants/strings.constants';
import { UCError } from '@shared/models/errors';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';
@Component({
  selector: 'uc-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() process: string;
  @Input() stage: string;

  filteredQualifications: Qualification[];
  strings = strings.components.tasks.additionalInformation;

  constructor(
    private applicationService: ApplicationService,
    private coeService: ChangeOfEnrolmentService,
    private qualificationService: QualificationService,
  ) {
    super();
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);
    if (!this.isCOE) {
      this.applicationService
        .getApplicationEnrolment(this.applicationYear)
        .pipe(take(1))
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe((enrolment: ApplicationEnrolment[]) => {
          if (enrolment && enrolment.length !== 0) {
            this.retrieveQualCategory(enrolment[enrolment.length - 1].enrolledQualifications, this.applicationYear);
          }
        });
    } else {
      this.service.application.subscribe((application: ChangeOfEnrolment) => {
        this.currentApplication = application;
        this.currentApplication.enrolledQualifications = this.currentApplication.enrolledQualifications.filter(
          (qual) => qual.changeAction === CHANGE_ACTIONS.ADDED,
        );
        this.retrieveQualCategory(
          (this.currentApplication as ChangeOfEnrolment).enrolledQualifications,
          (this.currentApplication as ChangeOfEnrolment).academicYear.code,
        );
      });
    }
  }
  retrieveQualCategory(enrolledQualifications: EnrolledQualification[], year: string) {
    const qualCodeURIs = enrolledQualifications.map((qual) => qual.href);
    this.qualificationService
      .getQualificationsByURIs(year, qualCodeURIs)
      .pipe(take(1))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((qualResult) => {
        if (qualResult && qualResult.qualification) {
          this.filteredQualifications = qualResult.qualification.filter(
            (qual) => !!qual?.categories.find((cat) => cat.category === QUAL_CATEGORIES.ADDITIONAL_INFORMATION_CAC),
          );
        }
      });
  }
  public updateFormValidity(err: UCError): void {}
}
