<uc-form-template [formGroup]="offerDecisionForm">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-form-template>
    <uc-section>
      <uc-subsection>
        <p>{{ strings.description }}</p>
        <uc-qualification-detail-card
          *ngIf="priorityApplicationEnrolment?.enrolledQualifications"
          [enrolledQuals]="priorityApplicationEnrolment?.enrolledQualifications"
          [applicationYear]="applicationYear"
        ></uc-qualification-detail-card>
      </uc-subsection>

      <uc-subsection>
        <uc-subsection-question>{{ strings.guidanceText }}</uc-subsection-question>
        <uc-task-hint
          [task]="task"
          path="/offer_decision/accept_offer"
        ></uc-task-hint>
        <uc-radio-input-group
          formControlName="acceptOffer"
          [options]="acceptDeclineOptions"
          groupName="offer-decision"
        >
        </uc-radio-input-group>

        <div *ngIf="showAcceptSection">
          <h5 class="decision-heading">{{ strings.accept.title }}</h5>
          <span
            class="decision-description"
            [innerHTML]="strings.accept.description"
          ></span>
          <uc-form-field-label class="declaration-agree"
            >{{ strings.declarationAgree }}<uc-required-field></uc-required-field
          ></uc-form-field-label>
          <uc-task-hint
            [task]="task"
            path="/offer_decision/declaration_funding_acceptance"
          ></uc-task-hint>
          <uc-checkbox-input-group
            groupName="dec-check"
            [options]="declarationOptions"
            [formControl]="offerDecisionForm.get('declarationFundingAcceptance')"
          >
          </uc-checkbox-input-group>
        </div>

        <div *ngIf="showDeclineSection">
          <h5 class="decision-heading">{{ strings.decline.title }}</h5>
          <p>{{ strings.decline.description }}</p>
          <div class="validation-messages">
            <uc-message-banner type="warning">
              <span>
                {{ strings.decline.warningMsg }}
              </span>
            </uc-message-banner>
          </div>

          <uc-form-input>
            <uc-task-hint
              [task]="task"
              path="/offer_decision/decline_reason"
            ></uc-task-hint>
            <uc-form-field-label
              [labelText]="strings.decline.declineReasonLabel"
              [required]="true"
            >
              <uc-reference-data-selector
                formControlName="declineReason"
                type="offer_of_place_decline_reason"
              ></uc-reference-data-selector>
            </uc-form-field-label>
          </uc-form-input>

          <uc-form-input *ngIf="isDeclineReasonOther">
            <uc-task-hint
              [task]="task"
              path="/offer_decision/decline_reason_other"
            ></uc-task-hint>
            <uc-form-field-label
              [labelText]="strings.decline.otherReason"
              [required]="true"
            >
              <uc-text-area
                testSelector="other-decline-reason"
                formControlName="declineReasonOther"
              ></uc-text-area>
            </uc-form-field-label>
          </uc-form-input>
        </div>
      </uc-subsection>
      <div
        class="align-warning-msg"
        *ngIf="!showAcceptSection && !showDeclineSection"
      >
        <uc-message-banner type="warning">
          <span
            class="warning-description"
            *ngIf="!hasDoctoralCategory"
            [innerHTML]="strings.warningText"
          >
          </span>
          <span
            class="warning-description"
            *ngIf="hasDoctoralCategory"
            [innerHTML]="strings.warningTextDoctoral"
          >
          </span>
        </uc-message-banner>
      </div>
    </uc-section>
  </uc-form-template>
</uc-form-template>
