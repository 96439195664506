<uc-section [formGroup]="uploadForm">
  <uc-section-header [title]="strings.sectionHeader"> </uc-section-header>
  <uc-subsection>
    <div
      class="file-upload-form"
      *ngIf="showUploadButton"
    >
      <uc-form-field-label
        >{{ strings.documentCategory }}
        <uc-required-field></uc-required-field>
      </uc-form-field-label>
      <uc-reference-data-selector
        class="category"
        formControlName="categoryCode"
        [options]="categoryOptions"
        testSelector="category"
      ></uc-reference-data-selector>

      <uc-button
        testSelector="upload"
        class="rounded has-icon upload-button {{ disabled ? 'disabled' : '' }}"
        type="submit"
        (click)="addFile()"
        [disabled]="!uploadForm.valid"
      >
        <uc-icon
          iconType="upload"
          iconWidth="22"
          iconHeight="22"
        ></uc-icon>
        {{ strings.uploadFile }}
      </uc-button>
      <input
        style="display: none"
        #uploadEl
        type="file"
        (click)="setNull()"
        (change)="onFileChange()"
      />

      <uc-message-banner
        class="feedback-message"
        [class.show-message]="showMessage"
        [showCloseButton]="true"
        [type]="messageType"
      >
        {{ message }}
      </uc-message-banner>

      <div
        *ngIf="uploadedFiles.length"
        class="uploaded-file-wrapper"
      >
        <div class="body-font-semi">{{ strings.uploadedDocuments }}</div>
        <hr class="uploaded-file-hr" />
        <uc-file-upload-list
          *ngFor="let category of uniqueUploadedCategories; trackBy: uploadedFileUniqueProperty"
          [year]="2018"
          (deleteFile)="uploadedFiles = $event"
          [uploadedFiles]="uploadedFiles"
          [categoryCode]="category.categoryCode"
          (removeFileError)="setErrorString($event)"
        ></uc-file-upload-list>
      </div>
    </div>
  </uc-subsection>
</uc-section>
