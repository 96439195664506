<div
  class="env-tag"
  *ngIf="environment.envName !== 'prod'"
  [style.background-color]="environment.envColour"
>
  {{ envInitials }}
</div>
<div class="needs-js">chat loading...</div>
<router-outlet [class.system-message]="systemMessageSet"></router-outlet>
<uc-flash-message [show]="showFlashMessages"></uc-flash-message>
<uc-tooltip-container></uc-tooltip-container>
<uc-crash-overlay></uc-crash-overlay>
