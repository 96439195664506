import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of as observableOf, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import { UserService } from '@shared/services/user/user.service';

@Injectable()
export class OnlineAuthOnloadGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  requestUser(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.getUser().pipe(
      map(() => {
        // Azure AD B2C already login, navigate to preProcssPage with all the query parameters
        if (route.queryParamMap.get('enrol')) {
          this.router.navigate(internalUrls.onlinePreProcessPage(''), { queryParams: route.queryParams });
        } else {
          this.router.navigate(internalUrls.dashboard);
        }

        return false;
      }),
      catchError(() => {
        return observableOf(true);
      }),
    );
  }

  // This guard is designed to disallow access to the login route if the /auth/me call succeeds
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.requestUser(route, state);
  }
}
