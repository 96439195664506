import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

import { ReferenceData } from './reference-data';

export class PoliceCheck {
  public hasChargesPending: boolean = null;
  public hasConvictions: boolean = null;
  public chargesPendingDescription: string = null;
  public convictionsDescription: string = null;
  public hasBackgroundCheck: boolean = null;
  public declarationPoliceAgree: boolean = null;
  public comment: string = null;
  public idChecks: { type: ReferenceData; value: string }[];
  public nzPoliceCheckState: ReferenceData;
  public vettingFormReceivedDate: string;
  public nzPoliceCheckReturnedDate: string;
  public caSafetyCheckDate: string;
  public hasCriminalRecord: boolean;
  public criminalRecordDetails: string;
  public overseasPoliceCheckState: ReferenceData;

  constructor(attrs: Partial<PoliceCheck> = {}) {
    Object.assign(this, attrs);
  }

  static deserialize(payload: any): PoliceCheck {
    const camelized = camelizeKeys(payload);

    if (camelized?.idChecks?.length > 0) {
      camelized.idChecks = camelized.idChecks.map((el) => {
        return {
          type: ReferenceData.deserialize(el.type),
          value: el.value,
        };
      });
    }

    if (camelized?.nzPoliceCheckState) {
      camelized.nzPoliceCheckState = ReferenceData.deserialize(camelized.nzPoliceCheckState);
    }

    if (camelized?.overseasPoliceCheckState) {
      camelized.overseasPoliceCheckState = ReferenceData.deserialize(camelized.overseasPoliceCheckState);
    }

    return new PoliceCheck(camelized);
  }

  static serialize(instance: PoliceCheck): any {
    const clone = Object.assign(new PoliceCheck({}), instance);

    if (clone.idChecks?.length > 0) {
      clone.idChecks = clone.idChecks.map((el) => {
        return {
          type: ReferenceData.serialize(el.type),
          value: el.value,
        };
      });
    }

    if (clone.nzPoliceCheckState) {
      clone.nzPoliceCheckState = ReferenceData.serialize(clone.nzPoliceCheckState);
    }

    if (clone.overseasPoliceCheckState) {
      clone.overseasPoliceCheckState = ReferenceData.serialize(clone.overseasPoliceCheckState);
    }

    return snakeifyKeys(clone);
  }
}
