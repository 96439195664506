<div class="nav nav-tabs">
  <div
    *ngFor="let tab of tabs"
    (click)="selectTab(tab)"
    [class.active]="tab.active"
    class="tab-container"
  >
    {{ tab.title }}
  </div>
</div>
<ng-content></ng-content>
