import { Component, OnInit, Input } from '@angular/core';
import strings from '@constants/strings.constants';
import { FullEnrolmentChange } from '@shared/models/change-of-enrolment';

@Component({
  selector: 'uc-coe-diff',
  templateUrl: './coe-diff.component.html',
  styleUrls: ['./coe-diff.component.scss'],
})
export class CoeDiffComponent {
  @Input() enrolmentChange: FullEnrolmentChange;
  @Input() minimalView = false;
  @Input() showErrorMsg = true;
  strings = strings.components.molecules.coeDiff;
}
