import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';

import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements OnInit, ControlValueAccessor {
  @Input() isLargeTextarea: boolean;
  @Input() hideValidationIcon: boolean;
  @Input() showDisabledExplanation = true;
  @Input() testSelector: string;

  isFocused: boolean;
  isDisabled = false;
  hasValue = false;

  innerInputControl = new UntypedFormControl('');
  log: Logger;

  private propagateTouch: (_: boolean) => void;
  private propagateChange: (_: string) => void;

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get testSelectorId() {
    return `${this.testSelector}-textarea`;
  }

  ngOnInit() {
    this.innerInputControl.valueChanges.subscribe((text) => {
      this.hasValue = !!text.length;
      this.propagateChange?.(text);
      this.propagateTouch?.(true);
    });
  }

  public stripInvalidCharacters() {
    setTimeout(() => {
      const val = this.innerInputControl.value;
      if (val && val.match(/[^ \f\r\n-~]/g)) {
        this.innerInputControl.setValue(val.replace(/[^ \f\r\n-~]/g, ''));
      }
    });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(obj: string): void {
    if (obj) {
      this.hasValue = true;
    }
    this.innerInputControl.setValue(obj, { emitEvent: false });
  }

  public registerOnChange(fn: (_: string) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: (_: boolean) => void): void {
    this.propagateTouch = fn;
  }
}
