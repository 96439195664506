import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { uniqBy, get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { ONLINE_LOCATION_FILTER } from '@shared/constants/online-qualification-options.constants';
import { CourseOccurrence } from '@shared/models/course';
import { CourseService } from '@shared/services/course/course.service';

@Component({
  selector: 'uc-myuc-search-filters',
  templateUrl: './myuc-search-filters.component.html',
  styleUrls: ['./myuc-search-filters.component.scss'],
})
export class MyucSearchFiltersComponent implements OnInit {
  @Input() filterOrder: Record<string, { labelText: string; value: string }[]> = {};

  strings = strings.components.organisms.searchFilters;
  defaultOptions = {
    displayLevel: [{ labelText: this.strings.defaultLabels.level, value: '' }],
    teachingPeriodCode: [{ labelText: this.strings.defaultLabels.teachingPeriodCode, value: '' }],
    site: [
      { labelText: this.strings.defaultLabels.site, value: '' },
      // Hard code for UCO programme Location filter Option
      { labelText: ONLINE_LOCATION_FILTER, value: ONLINE_LOCATION_FILTER },
    ],
  };
  options = this.initialOptions;
  showFilters = false;

  private initialFormValue = {
    displayLevel: '',
    teachingPeriodCode: '',
    site: '',
  };
  searchFiltersForm = this.fb.group(this.initialFormValue);

  constructor(
    private fb: UntypedFormBuilder,
    private courseService: CourseService,
  ) {}

  get filterNames(): string[] {
    return Object.keys(this.options);
  }

  private get initialOptions() {
    const options = { displayLevel: [], teachingPeriodCode: [], site: [] };
    Object.keys(this.defaultOptions).forEach((key) => {
      options[key] = this.defaultOptions[key].slice();
    });

    return options;
  }

  ngOnInit() {
    this.searchFiltersForm.valueChanges.subscribe((value) => {
      this.courseService.filterCourses(value);
    });

    this.courseService.searchResult.subscribe((searchResults) => {
      if (searchResults?.meta.resultCount > 0 && !searchResults?.course.length) {
        this.hideFilters();
      } else {
        this.createOptions(searchResults?.course);
      }
      // Hard code for setting UCO programme Location filter as 'Online' by default
      setTimeout(() => {
        this.searchFiltersForm.get('site').setValue(ONLINE_LOCATION_FILTER);
        this.searchFiltersForm.updateValueAndValidity();
      }, 100);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private getLabel(filter, option) {
    return option.labelText || option.value;
  }

  private addOption(filter, option) {
    const labelText = this.getLabel(filter, option);
    if (this.options[filter]) {
      if (option.value !== ONLINE_LOCATION_FILTER) {
        this.options[filter].push({ labelText, value: option.value });
      }
    }
  }

  private addOrderedOptions(filter, courses) {
    const uniqueValueLookup = {};
    uniqBy(courses, filter).forEach((c) => (uniqueValueLookup[c[filter]] = true));
    this.filterOrder[filter].forEach((option: { labelText: string; value: string }) => {
      if (uniqueValueLookup[option.value]) {
        this.addOption(filter, option);
      }
    });
  }

  private addAlphaSortedOptions(filter, courses) {
    const sortedUniqueValues = uniqBy(courses, filter)
      .map((c) => c[filter])
      .sort();
    sortedUniqueValues.forEach((value) => this.addOption(filter, { labelText: '', value }));
  }

  createOptions(courses: CourseOccurrence[]) {
    this.resetFilters();
    this.showFilters = !!get(courses, 'length');
    if (!this.showFilters) {
      return;
    }

    Object.keys(this.initialFormValue).forEach((key) => {
      const hasDefinedOrder = Array.isArray(this.filterOrder[key]);
      if (hasDefinedOrder) {
        this.addOrderedOptions(key, courses);
      } else {
        this.addAlphaSortedOptions(key, courses);
      }
    });
  }

  hideFilters() {
    this.resetFilters();
    this.showFilters = false;
  }

  resetFilters() {
    this.options = this.initialOptions;
    this.searchFiltersForm.setValue(this.initialFormValue);
  }
}
