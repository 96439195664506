import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { get, uniqBy } from 'lodash-es';
import { zip, throwError } from 'rxjs';

import strings from '@constants/strings.constants';
import { Category, UCDocumentType } from '@shared/models/uc-file';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicationService } from '@shared/services/application/application.service';
import { DocumentService } from '@shared/services/document/document.service';
import { FileUploadServiceEvent } from '@shared/services/file-upload/file-upload.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-category-file-upload',
  templateUrl: './category-file-upload.component.html',
  styleUrls: ['./category-file-upload.component.scss'],
})
export class CategoryFileUploadComponent implements OnInit {
  @ViewChild('uploadEl') uploadEl: ElementRef;

  yearOptions: { labelText: string; value: string }[];
  categoryOptions: { labelText: string; value: string }[];
  uploadedFiles: { id: string; categoryCode: string }[] = [];
  categories: Category[];
  uploadForm: UntypedFormGroup;
  currentYear = String(new Date().getFullYear());

  showUploadButton = true;
  required = true;
  private categoryIsApplicant;

  strings = strings.components.organisms.categoryFileUpload;
  fileUploadStrings = strings.components.organisms.fileUpload;

  showMessage = false;
  messageType: string;
  message: string;

  displaySpinner = false;

  log: Logger;

  constructor(
    private documentService: DocumentService,
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private processService: ProcessService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get academicYearControl(): UntypedFormControl {
    return this.uploadForm.get('academicYear') as UntypedFormControl;
  }

  get categoryCodeControl(): UntypedFormControl {
    return this.uploadForm.get('categoryCode') as UntypedFormControl;
  }

  get uniqueUploadedCategories(): { id: string; categoryCode: string }[] {
    return uniqBy(this.uploadedFiles, 'categoryCode');
  }

  private createForm() {
    this.uploadForm = this.formBuilder.group({
      categoryCode: ['', Validators.required],
      academicYear: ['', UCValidators.validateYear],
    });
  }

  buildCategoryOptions(categories, applications) {
    const studentUploadable = categories.filter((el) => !!el.studentUploadable);
    const catOptions = [];
    studentUploadable.forEach((cat: Category) => {
      const option = { labelText: cat.description, value: cat.code };
      if (applications.length) {
        catOptions.push(option);
        return;
      }
      if (cat.documentType === UCDocumentType.applicant) {
        catOptions.push(option);
      }
    });
    this.categoryOptions = catOptions;
  }

  buildYearOptions(applications) {
    this.yearOptions = applications.map((app) => ({ labelText: app.academicYear, value: app.academicYear }));
    this.updateYearDisabledState();
  }

  updateYearDisabledState() {
    const shouldDisable = this.yearOptions.length === 0 || this.categoryIsApplicant;

    if (shouldDisable) {
      this.academicYearControl.reset({});
      this.academicYearControl.disable();
    } else {
      this.academicYearControl.enable();
    }
  }

  uploadFile(file: File) {
    const year: string = this.academicYearControl.disabled ? this.currentYear : this.academicYearControl.value;
    let fileId;

    return this.documentService.uploadFileForCategory(file, this.categoryCodeControl.value, year).subscribe(
      (f) => {
        fileId = f.id;
        this.showMessage = false;
        if (!this.uploadedFiles.find((uf) => uf.id === f.file.id)) {
          this.uploadedFiles = this.uploadedFiles.filter((el) => el.id !== fileId);
          this.uploadedFiles.push({ id: f.file.id, categoryCode: f.file.category });
        }
        if (f.progress === 100) {
          this.setMessage(this.strings.successfulUpload, 'success');
          this.processService.notifyDocumentUpload(f.file.id.toString(), year).subscribe(
            () => {},
            (err) => throwError(err),
          );
        }
      },
      (err) => {
        const message = err && get(strings, err.code, this.fileUploadStrings.otherError);
        this.setMessage(message, 'error');
        this.log.error('File upload error:', err);
        if (fileId) {
          this.uploadedFiles = this.uploadedFiles.filter((el) => el.id !== fileId);
        }
      },
    );
  }

  abortUpload(doc: FileUploadServiceEvent) {
    this.documentService.abortUpload(doc);
  }

  onFileChange() {
    const input: HTMLInputElement = this.uploadEl.nativeElement;
    if (!input.files[0]) {
      this.log.warn('onFileChange called without file');
    }
    this.uploadFile(input.files[0]);
  }

  setNull() {
    const input: HTMLInputElement = this.uploadEl.nativeElement;
    input.value = null;
  }

  addFile() {
    this.uploadEl.nativeElement.click();
  }

  setMessage(message: string, type: string) {
    this.messageType = type;
    this.message = message;
    this.showMessage = true;
    if (type === 'success') {
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    }
  }

  ngOnInit() {
    this.createForm();
    this.categoryCodeControl.valueChanges.subscribe((categoryCode: string) => {
      if (categoryCode) {
        const category = this.categories.find((cat) => cat.code === categoryCode);
        this.categoryIsApplicant = category && category.documentType === UCDocumentType.applicant;
      } else {
        this.categoryIsApplicant = false;
      }
      this.updateYearDisabledState();
    });

    zip(this.documentService.getCategories(), this.applicationService.getApplications()).subscribe(
      ([categories, applications]) => {
        this.categories = categories;
        this.buildCategoryOptions(categories, applications);
        this.buildYearOptions(applications);
      },
    );
  }
}
