import { Component, AfterContentInit, ContentChildren, QueryList } from '@angular/core';

import { OnlineTabComponent } from '../online-tab/online-tab.component';

@Component({
  selector: 'uc-online-tabs',
  templateUrl: './online-tabs.component.html',
  styleUrls: ['./online-tabs.component.scss'],
})
export class OnlineTabsComponent implements AfterContentInit {
  @ContentChildren(OnlineTabComponent) tabs: QueryList<OnlineTabComponent>;
  currentTab: OnlineTabComponent;

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tabActive: OnlineTabComponent) {
    this.deactivateAllTabs();
    tabActive.active = true;
    this.currentTab = tabActive;
  }
  deactivateAllTabs() {
    this.tabs.toArray().forEach((tabItem) => (tabItem.active = false));
  }
}
