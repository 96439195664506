export const SECTION_TO_PATH = {
  uconlineSection: {
    identificationDetails: ['/birthDate', '/legalName', '/name', '/gender'],
    citizenshipAndResidency: ['/demographic/citizenship', '/demographic/passportCountry', '/studyInNz'],
    contactDetails: ['/contactDetail/emailAddress', '/contactDetail/mobileNumber', '/preferredContactMethod'],
    learningNeeds: ['/disability', '/declarationDisability', '/disabilityDetail'],
    termsAndConditions: [
      '/acceptedTermsAndPrivacy',
      '/declaredMetRequirements',
      '/acceptedProvideIdentity',
      '/uconlineMarketingEmails',
    ],
    ethnicity: ['/demographic/ethnicity', '/demographic/iwi'],
    permanentAddress: ['/contactDetail/currentAddress'],
    studyAddress: ['/studyContactDetail/currentAddress'],
    secondaryOrHighSchoolEducation: [
      '/ueQualification/type',
      '/demographic/moeLastSchoolAttended',
      '/demographic/moeYearLastAttendedSchool',
      '/wasLastYearAtHighSchool',
      '/secondaryQualification',
      '/source',
      '/dateAttained',
      '/attained',
      '/sourceOther',
    ],
    moreAboutYourStudies: ['/firstYearTertiary', '/demographic/moeMainActivity'],
  },
  uconlineProfile: {
    personalDetails: ['/birthDate', '/legalName', '/name', '/gender', '/demographic/ethnicity', '/demographic/iwi'],
    contactDetails: [
      '/contactDetail/emailAddress',
      '/contactDetail/mobileNumber',
      '/contactDetail/currentAddress',
      '/preferredContactMethod',
    ],
  },
};

export const PATH_TO_SECTION = {
  uconlineSection: {
    '/birthDate': 'Identification Details',
    '/legalName': 'Identification Details',
    '/name': 'Identification Details',
    '/gender': 'Identification Details',
    '/demographic/citizenship': 'Citizenship And Residency',
    '/demographic/passportCountry': 'Citizenship And Residency',
    '/studyInNz': 'Citizenship And Residency',
    '/contactDetail/emailAddress': 'Contact Details',
    '/contactDetail/mobileNumber': 'Contact Details',
    '/preferredContactMethod': 'Contact Details',
    '/disability': 'Learning Needs',
    '/declarationDisability': 'Learning Needs',
    '/disabilityDetail': 'Learning Needs',
    '/acceptedTermsAndPrivacy': 'Terms And Conditions',
    '/declaredMetRequirements': 'Terms And Conditions',
    '/acceptedProvideIdentity': 'Terms And Conditions',
    '/uconlineMarketingEmails': 'Terms And Conditions',
    '/demographic/ethnicity': 'Ethnicity',
    '/demographic/iwi': 'Ethnicity',
    '/contactDetail/currentAddress': 'Permanent Address',
    '/studyContactDetail/currentAddress': 'Study Address',
    '/ueQualification/type': 'Secondary Or HighSchool Education',
    '/demographic/moeLastSchoolAttended': 'Secondary Or HighSchool Education',
    '/demographic/moeYearLastAttendedSchool': 'Secondary Or HighSchool Education',
    '/wasLastYearAtHighSchool': 'Secondary Or HighSchool Education',
    '/secondaryQualification': 'Secondary Or HighSchool Education',
    '/source': 'Secondary Or HighSchool Education',
    '/dateAttained': 'Secondary Or HighSchool Education',
    '/attained': 'Secondary Or HighSchool Education',
    '/sourceOther': 'Secondary Or HighSchool Education',
    '/firstYearTertiary': 'More About Your Studies',
    '/demographic/moeMainActivity': 'More About Your Studies',
  },
  uconlineProfile: {
    '/birthDate': 'Personal Details',
    '/legalName': 'Personal Details',
    '/name': 'Personal Details',
    '/gender': 'Personal Details',
    '/demographic/ethnicity': 'Personal Details',
    '/demographic/iwi': 'Personal Details',
    '/contactDetail/emailAddress': 'Contact Details',
    '/contactDetail/mobileNumber': 'Contact Details',
    '/contactDetail/currentAddress': 'Contact Details',
    '/preferredContactMethod': 'Contact Details',
  },
};
