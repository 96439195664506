import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '@environment';

export class SentryErrorHandler implements ErrorHandler {
  handleError(err: Error): void {
    if (err && !!err.message.match('Error: Loading chunk')) {
      // If user's cached copy of minified js chunk is out of date, this error will be thrown.
      // Reloading should clear the cached js.
      window.location.reload();
    } else {
      if (!environment.production) {
        console.error(err);
      }
      Sentry.captureException(err);
    }
  }
}

const ERRORS_TO_IGNORE = [
  // Google Translate plugin for Chrome throws this error *all the time*
  'a[b].target.className.indexOf',
  // Caused by a bug in firebase when requesting an auth token
  "Failed to execute 'transaction' on 'IDBDatabase'",
  // Recommended to ignore by sentry. Chrome triggers this when the resize
  // observer api takes more than one animation frame to complete
  'ResizeObserver loop limit exceeded',
  // Related to conditionally show/hiding elements but squashed anyway in production
  'Expression has changed after it was checked',
];

const setupSentry = () => {
  let sentryConfig: Sentry.BrowserOptions = {
    dsn: environment.sentryUrl,
    ignoreErrors: ERRORS_TO_IGNORE,
    release: environment.release,
    autoSessionTracking: true,
  };

  if (environment.envName === 'prod' || environment.envName === 'uat') {
    sentryConfig = { ...sentryConfig, integrations: [new Sentry.BrowserTracing()], tracesSampleRate: 0.01 };
  }

  Sentry.init(sentryConfig);
};

export const sentryErrorFactory = () => {
  if (environment.sentryUrl && environment.envName) {
    setupSentry();
    return new SentryErrorHandler();
  } else {
    return new ErrorHandler();
  }
};

export const sentryErrorProvider = {
  provide: ErrorHandler,
  useFactory: sentryErrorFactory,
  deps: [],
};

@Injectable()
export class SentryService {
  constructor() {
    setupSentry();
  }
}

export class MockSentryService {}

export const sentryServiceFactory = () => {
  if (environment.sentryUrl && environment.envName) {
    setupSentry();
    return () => new SentryService();
  } else {
    return () => new MockSentryService();
  }
};
