import { externalUrls } from '@constants/urls.constants';

import { QUALIFICATION_LEVELS } from './academic-history';

/* eslint-disable max-len */
const appName = 'MyUCOnline';

const contact = {
  enrolmentEmailAddress: 'enrol@canterbury.ac.nz',
  admissionsEmailAddress: 'admission@canterbury.ac.nz',
  starEmailAddress: 'star@canterbury.ac.nz',
  scholarshipEmailAddress: 'scholarships@canterbury.ac.nz',
  phone: {
    local: {
      display: '0800 VARSITY (827 748)',
      linkable: '+64800827748',
    },
    international: {
      display: '+64 3 369 4999',
      linkable: '006433694999',
    },
  },
};

const baseContactText = (email) => {
  return `
    Contact Centre on <a href="tel:${contact.phone.local.linkable}">${contact.phone.local.display}</a>,
    international <a href="tel:${contact.phone.international.linkable}">${contact.phone.international.display}</a>,
    or email <a href="mailto:${email}">${email}</a>
  `;
};

const contactCentre = baseContactText(contact.enrolmentEmailAddress);

const termsAndConditionsLink = 'https://uconline.ac.nz/terms-and-conditions';
const privacyPolicyLink =
  'https://www.canterbury.ac.nz/privacy/?__hstc=84757190.2a4691b6ba749e070b0986ddc6fe1c7f.1681422798322.1681422798322.1681422798322.1&__hssc=84757190.4.1681422798322&__hsfp=3815883021';

const baseTermsAndConditionsLinkText = (termsAndConditions: string, privacyPolicy: string) => {
  return `
    <a href="${termsAndConditions}" target="_blank">terms and conditions</a> and <a href="${privacyPolicy}" target="_blank">privacy policy</a>
  `;
};

const termsAndConditionsLinkText = baseTermsAndConditionsLinkText(termsAndConditionsLink, privacyPolicyLink);

const convertProcessName = (process) => `${process === 'uconline_short_course' ? 'short course' : 'micro-credential'}`;
const cancelAreYouSure = (courseName, processName, academicYear) => {
  return `Are you sure you want to cancel your ${academicYear} ${convertProcessName(processName)} application for <b>${courseName}</b>? You may be unable to cancel your application if you have paid for this course, or if you have progressed your application. For assistance contact <a href='enrolments@uconline.ac.nz'>enrolments@uconline.ac.nz</a>.`;
};

export default {
  labels: {
    user: {
      emailOrUsername: 'Agent Username',
    },
    appName,
  },
  services: {
    independentCourseEnrolment: {
      withdrawSuccessTitle: 'Enrolment cancelled',
      withdrawSuccessMessage: 'Thank you – we have cancelled your enrolment. You can now enrol in a new course.',
      errorTitle: 'Please call or email our team',
      errorMessageProcess:
        'Sorry, but we can’t cancel your application. This may be because your enrolment was already complete, or you have already paid for this course. Please contact our Tuihono UC | UC Online team to cancel <a href="mailto:support@uconline.ac.nz">support@uconline.ac.nz</a> or phone <a href="tel:6433690600">03 369 0600</a>',
      errorMessage:
        'Sorry, but we can’t cancel your application. This may be because your enrolment was already complete, or you have already paid for this course. Please contact our Tuihono UC | UC Online team to cancel <a href="mailto:support@uconline.ac.nz">support@uconline.ac.nz</a> or phone <a href="tel:6433690600">03 369 0600</a>',
    },
  },

  components: {
    molecules: {
      independentCourseEnrolmentSummaryCard: {
        enrolmentsClose: 'Enrolments close',
        testCourse: 'Test Course',
        learnNowUrl: 'https://my.uconline.ac.nz',
        learnNow: 'Learn Now',
        resume: 'Resume',
        cancelAreYouSure,
        withdraw: 'Cancel Application',
        close: 'Close',
        cancel: 'Cancel',
        withdrawApplication: 'Cancel Application',
      },
      footer: {
        generalEnquiry: 'General Enquiries',
        homepageLink: externalUrls.ucOnlineHomepage,

        // General Info
        infoPhoneLink: '006433690600',
        infoPhone: '+64 3 369 0600',
        infoEmail: 'info@uconline.ac.nz',

        // Enrolments
        enrolmentEnquiries: 'Enrolment Enquiries',
        enrolmentPhone: '+64 3 369 0600',
        enrolmentPhoneLink: '006433690600',
        enrolEmail: 'enrolments@uconline.ac.nz',
      },
      onlineTermsAndConditions: {
        header: 'Terms and conditions',
        termsAndConditionsDescription: `I confirm that I have read, understood and agree to the UC Online ${termsAndConditionsLinkText}.`,
        declareDescription: `I declare that I have met all requirements for this course and will supply evidence of this if requested.`,
        understandDescription: `I understand that I may be asked to provide proof of my identity, and may not receive a credential if this is not provided.`,
        optionalDescription: `Please keep me up to date with the latest news and offers from UC Online (you can unsubscribe at any time).`,
      },
      onlineFooter: {
        needHelpText: 'Need help with your application?',
        infoPhone: '+ 64 3 369 0600',
        infoEmail: 'enrolments@uconline.ac.nz',
      },
      onlinePhoneSelector: {
        whatsMyCountryCode: "What's my country code?",
        mobileLabel: 'Mobile Number',
        mobileHint: 'Please enter a complete mobile number, including country code (e.g. +64 23 123 4567)',
        landlineLabel: 'Landline Number',
        alternatePhoneHint: 'Please enter an alternate contact number including country code e.g. +64 9876 5432',
        alternatePhoneLabel: 'Alternate Phone Number',
        landlineHint: 'Please enter a complete landline number, including country code (e.g. +64 9876 5432)',
        phoneNumberQuestion: 'What’s your mobile number?',
      },
    },
    organisms: {
      sidenav: {
        ucOnlineLinkText: 'UC Online Home',
      },
      socialLogin: {
        connect: 'Connect with',
        realme: 'RealMe',
      },
      headerBar: {
        onlineDashboard: 'MyUCOnline Dashboard',
        profile: 'My Account',
        learningPlatform: 'Learning Platform',
        totaraUrl: 'https://my.uconline.ac.nz',
      },
      onlineProfilePage: {
        profileTemplate: {
          myAccount: 'My Account',
          mandatoryBanner: 'Mandatory fields are marked with',
          editButton: 'Edit Your Profile',
          updateButton: 'Update Profile',
          emailLabel: 'Email Address',
          studentId: 'Student ID: ',
          studyEmail: 'Study email: ',
          usercode: 'Usercode: ',
          contactDetails: 'Contact Details',
          mobileLabel: 'Mobile number',
          mobilePlaceholder: '123456789',
          preferredContactMethodLabel: 'Preferred method of contact',
          studyAddressHeader: 'Study Address',
          studyAddressQuestion: `Complete this section if you know where you're staying whilst studying at UC.`,
          copyAddressButton: 'Copy Permanent Address',
          studyDetailsHeader: 'Study Details',
          homeAddressQuestion: 'What is your current address?',
          addressPredictionHelp: 'Start typing and then select your address',
          unsavedModal: {
            invalidFormQuestion: 'Are you sure you wish to navigate away? Any unsaved changes will be&nbsp;lost.',
            validFormQuestion:
              'You have unsaved changes on the page. Please complete all required information before&nbsp;saving.',
          },
        },
        permanentContactDetails: {
          sectionTitle: 'Permanent Contact Details',
          description: 'Words describing what we want for current contact details.',
        },
        currentContactDetails: {
          sectionTitle: 'Current Contact Details',
          description: 'Words describing what we want for the current contact details.',
          emailLabel: 'Email',
          mobileLabel: 'Mobile Number',
        },
        personalDetails: {
          sectionTitle: 'Personal Details',
          description: 'Words describing what we want for current personal details.',
          firstnameLabel: 'First Name',
          lastnameLabel: 'Last Name',
          fullnameLabel: 'Full Name',
          middlenameLabel: 'Middle Name',
          previousnameLabel: 'Previous Name/Family Name',
          preferrednameLabel: 'Preferred Name',
          preferrednameTooltip: `What first name do you want to be known as if different from above.
            We will use this name in correspondence with you.`,
          ethnicityLabel: 'Ethnicity',
          dateOfBirthQuestion: 'What is your birth date?',
          genderLabel: 'TODO: needs updating',
        },
        emergencyContactDetails: {
          sectionTitle: 'Emergency Contact Details',
          description: `<p>Please provide as much contact information as possible for your emergency contact person. We need at least one phone&nbsp;number.</p>
                        <p>UC will contact this person if we are concerned about your wellbeing and safety. Make sure this contact is an adult, knows you well and agrees to act as your emergency support person if&nbsp;required.</p>`,
          firstnameLabel: 'First Name',
          lastnameLabel: 'Last Name',
          emailLabel: 'Email',
          mobileLabel: 'Mobile Number',
          relationshipLabel: 'Relationship to you',
          phoneNumberQuestion: 'What is your phone number?',
        },
      },
      onlineMoreAboutYourStudies: {
        header: 'More about your studies',
        firstYearQuestion: (year) => `Will ${year} be your first year at the University of Canterbury?`,
        otherFirstYearQuestion: 'What was your first year at UC?',
        firstTertiaryYear: 'What year did you first start tertiary level study?',
        firstTertiaryYearSubText: 'This can be either in New Zealand or overseas.',
        firstTertiaryYearSubText2: (year) =>
          `If ${year} is your first year in tertiary study then please enter this as your answer.`,
        lastYearQ: (year) => `What were you doing in October ${Number(year) - 1}?`,
      },
      onlineSecondaryEducation: {
        description: [
          'We require this information in line with Ministry of Education requirements. If you’re still completing this qualification, please tell us about what you expect to achieve and when you expect to achieve it.',
        ],
        nsnLabel: 'Do you know your National Student Number (NSN)?',
        nsnDescription: `This is a National Student Number or New Zealand Qualifications Authority (NZQA) number given to those who have previously studied in New Zealand.
          Your NSN is usually a 9 digit number. Do not include any zeroes at the start of the number.`,
        highSchoolInNewZealand: (qualNum) => {
          return qualNum === 0
            ? 'Did you attend secondary/high school in New Zealand?'
            : 'Was this secondary/high school in New Zealand?';
        },
        qualLabel: ['First Qualification', 'Second Qualification'],
        NCEA3MessageBanner:
          'If you are currently studying NCEA Level 3 please change your answer above to NCEA Level 3. Otherwise, answer the questions below for your NCEA Level 2 qualification.',
        highestSecondaryQual: 'What is your highest secondary/high school qualification?',
        additionalSecondaryQual: 'Secondary/high school qualification',
        yearCompletedQualification: 'What year did you or will you complete this qualification?',
        uploadResults: 'Upload a copy of your secondary/high school results.',
        uploadResultsTitle: 'Secondary results',
        uploadResultsHelp: [
          'Upload your secondary school results. This could be a results notice or academic record from the authority administering the qualification.',
          'CIE and IB students are required to upload predicted or AS or O Level results if final results are not yet available.',
        ],
        lastYearAttended: 'What year did you last attend secondary/high school?',
        wasXLastYearAttended: (val) => `Was ${val} your last year of secondary/high school?`,
        whichSchoolAttended: 'Which secondary/high school were you attending when you got this qualification?',
        waitingForResults: 'Are you still waiting for your results?',
        qualification: 'Qualification',
        otherQualification: 'Qualification name',
        otherCountry: 'In which country did you complete this qualification or expect to complete this qualification?',
        addQualQuestion: 'Do you have another secondary/high school qualification to tell us about?',
        addQualButtonLabel: 'Add qualification',
        addQualButtonText: `Use this section to tell us about any secondary/high school study in another country.<br>If you've told us about NCEA Level 3 you don't need to add NCEA Level 1 or 2.`,
        studiedOutsideNZGuidance: `Please select No to the 'Did you attend secondary/high school in New Zealand?' question above if you studied this qualification overseas.`,
        schoolNotOnList: 'Secondary/high school name',
      },
    },
    preProcess: {
      welcomeSectionHeader: 'Welcome to Tuihono UC | UC Online',
      welcomeSectionParagraph:
        'It’s time to learn anywhere, anytime, at your pace. Fill in your profile to begin your learning journey!',
      informationTitle: 'What information will I need to provide?',
      informationSCParagraph:
        'In line with New Zealand education requirements, we need some general information about you, your study background.',
      informationMCParagraph:
        'In line with New Zealand education requirements, we need some general information about you, your study background and a valid form of identification. (Don’t have any ID on you? Don’t worry, you can begin enrolment and come back to this section at a later date.)',
      timeTakeTitle: 'How long does it take?',
      timeTakeSCParagraph:
        'Completion of your profile should take around 5 minutes. When you’re done, we’ll welcome you to our UC Online learning platform!',
      timeTakeMCParagraph:
        'Completion of your profile should take around 5 - 10 minutes. When you’re done, we’ll welcome you to our UC Online learning platform!',
      needCourseToEnrolParagraph: [
        "Apologies, we can't seem to find an enrolment for you.",
        'Please browse our courses and enrol to resolve this issue.',
      ],
      holdOn: 'Hold on – you’ve already started an enrolment',
      duplicateEnrolmentParagraph:
        'It looks as though you’ve already started enrolling for another course. Please either complete or cancel this enrolment for your first course before enrolling in a new one – go to “My applications” to find your partially complete enrolment.',
    },
    template: {
      manageMyStudy: {
        title: 'Manage my study',
        description: 'Update your current programme applications.',
        newApplicationButtonText: 'START NEW APPLICATION',
        noActiveApplications: 'To start an application for a qualification, use the "Start New Application" button',
        goToDashboard: `Back to my dashboard`,
        qualificationsTitle: 'Qualifications',
        qualificationsSubHeading: "Undergraduate, Postgraduate, and Master's degrees.",
        independentEnrolmentsTitle: 'Short courses & micro-credentials',
        independentEnrolmentsSubHeading: 'Short course and micro-credential enrolments.',
        ctaHeading: 'Not sure what you want to study?',
        ctaSubHeading: 'Find your next step - check out our full range of online courses and qualifications.',
        ctaButton: 'View all courses',
        ctaUrl: 'https://uconline.ac.nz/online-courses',
        noIndependentCourses: 'To enrol in a short course or micro-credential, view our courses available below.',
      },
      onlineProcessPage: {
        skipTask: 'Skip',
        skipVerificationModalTitle: 'Are you sure want to skip?',
        skipVerificationModalBodyFirst:
          'This can be provided at a later date; you can find this through your account page.',
        skipVerificationModalBodySecond:
          'Not providing acceptable proof of identity will mean you may not recieve credential.',
        skipVerificationModalFooterButton: 'Yes, skip for now',
        cancelProcessModalTitle: 'Are you sure you want to cancel?',
        cancelProcessModalBody:
          'If you cancel now, you’ll need to restart the enrolment process from the beginning. We recommend reaching the end of this section, where you can save your progress and return later.',
        cancelProcessModalFooterButton: 'Cancel enrolment',
        continueProcessModalFooterButton: 'Continue enrolment',
        validationWarningModalTitle: 'Validation Warning',
        validationWarningModalBody: 'Please double check below section(s):',
        closeValidationWarningModalButton: 'Close',
        continueButton: 'Continue',
        tryAgainButton: 'Try Again',
      },
      outage: {
        heading: 'System outage',
        description: `
          <p>
            Thanks for visiting Tuihono UC | UC Online - unfortunately our system is currently unavailable.
          </p>
          <p>
            We apologise for the inconvenience and ask that you please try again later. Need help right away?
            Please get in touch with us at <a href="mailto:info@uconline.ac.nz">info@uconline.ac.nz</a>.
          </p>`,
        browseCourses: 'Browse Our Courses',
        browseCoursesUrl: 'https://uconline.ac.nz',
      },
    },
    tasks: {
      // TODO: need to clean up some of the data setup here
      completeJudsStage: {
        processStrings: {
          default: {
            stage3: {
              messageBanner: (btn) =>
                `By selecting <strong>${btn}</strong> you will not be able to go back and make any changes to this stage.`,
              infoText: () => ({
                complete: '',
                incomplete: '',
              }),
            },
          },
        },
      },
      onlineShortCourseDetails: {
        usingUcOnline: {
          header: 'Using UC Online application to enrol',
          description: `To view a guide that shows you how to use UC Online to complete your application, select the <strong>Application to enrol user guide</strong> button below.`,
        },
        reviewYourProfile: 'Review your profile',
        yourDetails: 'Your details',
        editYourProfile: 'Edit your profile',
        formItemHint: {
          leaveBlankIfNotKnow: 'Leave blank if not known',
        },
        identificationDetailsSection: {
          header: 'Identification Details',
          description: [
            `Enter your legal details as stated on your identification document such as passport,
            birth certificate or citizenship certificate.`,
            `We are also asking for other names that you might have used to help us identify you.`,
          ],
          birthDateQuestion: 'What is your birth date?',
          labels: {
            nsnNumber: 'Do you know your National Student Number (NSN)?',
            nsnDescription: `This is a National Student Number or New Zealand Qualifications Authority (NZQA) number given to those who have previously studied in New Zealand.
          Your NSN is usually a 9 digit number. Do not include any zeroes at the start of the number.`,
            studentNumber: 'UC ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            mobile: 'Mobile',
            dateOfBirth: 'Date of birth',
            gender: 'Gender',
            citizenship: 'Citizenship',
            iwi: 'Iwi',
            ethnicity: 'Ethnicity',
            postCode: 'Post code',
          },
        },
        citizenshipSection: {
          header: 'Citizenship and Residency',
          categoryQuestion: 'What is your citizenship or residency category?',
          categoryLabel: 'Citizenship Category',
          countryQuestion: 'What is your country of citizenship?',
          countryLabel: 'Citizenship Country',
          liveInNzQuestion: 'Will you be living in New Zealand during your study?',
          liveInNzDescription: `Answer 'yes' if you'll be spending most of your time in New Zealand, with only short
            overseas vacations. Answer 'no' if you'll be spending most of your time out of New Zealand.`,
          ministryRuling: 'NZ Ministry of Education ruling:',
          rulingDesc: `NZ Ministry of Education classifies you as an <strong>International fee-paying</strong> student if you're not
                      residing in NZ during your study and you're either:
                      <ul>
                        <li>A permanent NZ resident </li>
                        <li>An Australian citizen, or </li>
                        <li>A permanent Australian resident. </li>
                      </ul>`,
          internationalRulingDesc: `If you intend to spend most of your time out of New Zealand you may not be eligible for the programme you choose.
            To confirm your eligibility, contact the ${contactCentre}`,
          passportInfo: `Upload a copy of the information page from your current passport, birth certificate or
                        citizenship certificate.`,
          birthCertificate: 'If you do not have a passport then please upload a copy of your birth certificate.',
          residentsVisa: 'Upload a copy of your residents visa.',
          starGuidance: `<p>Once you are enrolled you need to either send a verified copy of your citizenship document certified by your Principal or a JP in the mail to us, or bring in the original so we can sight it.
            Grades will not be released until we have verified the citizenship status.</p>
            <p>For more information see the STAR <a href="${externalUrls.star}" target="_blank" rel="noopener">How to Apply</a> tab on the UC&nbsp;website.</p>`,
          mcedGuidance: `Please note that while you are supplying an electronic version in this application, you may be required to provide a certified copy in person
            due to name changes, your secondary qualification, etc. More information on this is available <a href="${externalUrls.ucDocumentCertification}" target="_blank">here</a>`,
        },
        contactDetailsSection: {
          header: 'Contact details',
          description: 'Please confirm your contact details below so we can confirm your enrolment.',
          emailSubsectionQuestion: 'What’s your email address?',
          emailLabel: 'Email',
          mobilePlaceholder: '12 345 6789',
          addressQuestion: 'What is your current address?',
          addressPredictionHelp: 'Start typing and then select your address',
          preferredContactMethodLabel: 'Preferred contact method',
        },
      },
      // TODO: need to clean up some of the data setup here
      onlineMicroCredentialDetails: {
        usingUcOnline: {
          header: 'Using UC Online application to enrol',
          description: `To view a guide that shows you how to use UC Online to complete your application, select the <strong>Application to enrol user guide</strong> button below.`,
        },
        reviewYourProfile: 'Review your profile',
        yourDetails: 'Your details',
        editYourProfile: 'Edit your profile',
        formItemHint: {
          leaveBlankIfNotKnow: 'Leave blank if not known',
        },
        identificationDetailsSection: {
          header: 'Identification Details',
          description: [
            `Enter your legal details as stated on your identification document such as passport,
            birth certificate or citizenship certificate.`,
            `We are also asking for other names that you might have used to help us identify you.`,
          ],
          birthDateQuestion: 'What is your birth date?',
          labels: {
            nsnNumber: 'Do you know your National Student Number (NSN)?',
            nsnDescription: `This is a National Student Number or New Zealand Qualifications Authority (NZQA) number given to those who have previously studied in New Zealand.
          Your NSN is usually a 9 digit number. Do not include any zeroes at the start of the number.`,
            studentNumber: 'UC ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            mobile: 'Mobile',
            dateOfBirth: 'Date of birth',
            gender: 'Gender',
            citizenship: 'Citizenship',
            iwi: 'Iwi',
            ethnicity: 'Ethnicity',
            postCode: 'Post code',
          },
        },
        citizenshipSection: {
          header: 'Citizenship and Residency',
          categoryQuestion: 'What is your citizenship or residency category?',
          categoryLabel: 'Citizenship Category',
          countryQuestion: 'What is your country of citizenship?',
          countryLabel: 'Citizenship Country',
          liveInNzQuestion: 'Will you be living in New Zealand during your study?',
          liveInNzDescription: `Answer 'yes' if you'll be spending most of your time in New Zealand, with only short
            overseas vacations. Answer 'no' if you'll be spending most of your time out of New Zealand.`,
          ministryRuling: 'NZ Ministry of Education ruling:',
          rulingDesc: `NZ Ministry of Education classifies you as an <strong>International fee-paying</strong> student if you're not
                      residing in NZ during your study and you're either:
                      <ul>
                        <li>A permanent NZ resident </li>
                        <li>An Australian citizen, or </li>
                        <li>A permanent Australian resident. </li>
                      </ul>`,
          internationalRulingDesc: `If you intend to spend most of your time out of New Zealand you may not be eligible for the programme you choose.
            To confirm your eligibility, contact the ${contactCentre}`,
          passportInfo: `Upload a copy of the information page from your current passport, birth certificate or
                        citizenship certificate.`,
          birthCertificate: 'If you do not have a passport then please upload a copy of your birth certificate.',
          residentsVisa: 'Upload a copy of your residents visa.',
          starGuidance: `<p>Once you are enrolled you need to either send a verified copy of your citizenship document certified by your Principal or a JP in the mail to us, or bring in the original so we can sight it.
            Grades will not be released until we have verified the citizenship status.</p>
            <p>For more information see the STAR <a href="${externalUrls.star}" target="_blank" rel="noopener">How to Apply</a> tab on the UC&nbsp;website.</p>`,
          mcedGuidance: `Please note that while you are supplying an electronic version in this application, you may be required to provide a certified copy in person
            due to name changes, your secondary qualification, etc. More information on this is available <a href="${externalUrls.ucDocumentCertification}" target="_blank">here</a>`,
        },
        contactDetailsSection: {
          header: 'Contact details',
          description: 'Please confirm your contact details below so we can confirm your enrolment.',
          emailSubsectionQuestion: 'What’s your email address?',
          emailLabel: 'Email',
          mobilePlaceholder: '12 345 6789',
          addressQuestion: 'What is your current address?',
          addressPredictionHelp: 'Start typing and then select your address',
          preferredContactMethodLabel: 'Preferred contact method',
        },
      },
      // TODO: need to clean up some of the data setup here
      onlineReviewMicroCredentialDetails: {
        usingUcOnline: {
          header: 'Using UC Online application to enrol',
          description: `To view a guide that shows you how to use UC Online to complete your application, select the <strong>Application to enrol user guide</strong> button below.`,
        },
        reviewYourProfile: 'Review your profile',
        yourDetails: 'Your details',
        editYourProfile: 'Edit your profile',
        formItemHint: {
          leaveBlankIfNotKnow: 'Leave blank if not known',
        },
        identificationDetailsSection: {
          header: 'Identification Details',
          description: [
            `Enter your legal details as stated on your identification document such as passport,
            birth certificate or citizenship certificate.`,
            `We are also asking for other names that you might have used to help us identify you.`,
          ],
          birthDateQuestion: 'What is your birth date?',
          labels: {
            nsnNumber: 'Do you know your National Student Number (NSN)?',
            nsnDescription: `This is a National Student Number or New Zealand Qualifications Authority (NZQA) number given to those who have previously studied in New Zealand.
          Your NSN is usually a 9 digit number. Do not include any zeroes at the start of the number.`,
            studentNumber: 'UC ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            mobile: 'Mobile',
            dateOfBirth: 'Date of birth',
            gender: 'Gender',
            citizenship: 'Citizenship',
            iwi: 'Iwi',
            ethnicity: 'Ethnicity',
            postCode: 'Post code',
          },
        },
        citizenshipSection: {
          header: 'Citizenship and Residency',
          categoryQuestion: 'What is your citizenship or residency category?',
          categoryLabel: 'Citizenship Category',
          countryQuestion: 'What is your country of citizenship?',
          countryLabel: 'Citizenship Country',
          liveInNzQuestion: 'Will you be living in New Zealand during your study?',
          liveInNzDescription: `Answer 'yes' if you'll be spending most of your time in New Zealand, with only short
            overseas vacations. Answer 'no' if you'll be spending most of your time out of New Zealand.`,
          ministryRuling: 'NZ Ministry of Education ruling:',
          rulingDesc: `NZ Ministry of Education classifies you as an <strong>International fee-paying</strong> student if you're not
                      residing in NZ during your study and you're either:
                      <ul>
                        <li>A permanent NZ resident </li>
                        <li>An Australian citizen, or </li>
                        <li>A permanent Australian resident. </li>
                      </ul>`,
          internationalRulingDesc: `If you intend to spend most of your time out of New Zealand you may not be eligible for the programme you choose.
            To confirm your eligibility, contact the ${contactCentre}`,
          passportInfo: `Upload a copy of the information page from your current passport, birth certificate or
                        citizenship certificate.`,
          birthCertificate: 'If you do not have a passport then please upload a copy of your birth certificate.',
          residentsVisa: 'Upload a copy of your residents visa.',
          starGuidance: `<p>Once you are enrolled you need to either send a verified copy of your citizenship document certified by your Principal or a JP in the mail to us, or bring in the original so we can sight it.
            Grades will not be released until we have verified the citizenship status.</p>
            <p>For more information see the STAR <a href="${externalUrls.star}" target="_blank" rel="noopener">How to Apply</a> tab on the UC&nbsp;website.</p>`,
          mcedGuidance: `Please note that while you are supplying an electronic version in this application, you may be required to provide a certified copy in person
            due to name changes, your secondary qualification, etc. More information on this is available <a href="${externalUrls.ucDocumentCertification}" target="_blank">here</a>`,
        },
        contactDetailsSection: {
          header: 'Contact details',
          description: 'Please confirm your contact details below so we can confirm your enrolment.',
          emailSubsectionQuestion: 'What’s your email address?',
          emailLabel: 'Email',
          mobilePlaceholder: '12 345 6789',
          addressQuestion: 'What is your current address?',
          addressPredictionHelp: 'Start typing and then select your address',
          preferredContactMethodLabel: 'Preferred contact method',
        },
      },
      // TODO: need to clean up some of the data setup here
      onlineStudyLocation: {
        citizenshipSection: {
          header: 'Citizenship and Residency',
          categoryQuestion: 'What is your citizenship or residency category?',
          categoryLabel: 'Citizenship Category',
          countryQuestion: 'What is your country of citizenship?',
          countryLabel: 'Citizenship Country',
          liveInNzQuestion: 'Will you be living in New Zealand during your study?',
          liveInNzDescription: `Answer 'yes' if you'll be spending most of your time in New Zealand, with only short
            overseas vacations. Answer 'no' if you'll be spending most of your time out of New Zealand.`,
          ministryRuling: 'NZ Ministry of Education ruling:',
          rulingDesc: `NZ Ministry of Education classifies you as an <strong>International fee-paying</strong> student if you're not
                      residing in NZ during your study and you're either:
                      <ul>
                        <li>A permanent NZ resident </li>
                        <li>An Australian citizen, or </li>
                        <li>A permanent Australian resident. </li>
                      </ul>`,
          internationalRulingDesc: `If you intend to spend most of your time out of New Zealand you may not be eligible for the programme you choose.
            To confirm your eligibility, contact the ${contactCentre}`,
          passportInfo: `Upload a copy of the information page from your current passport, birth certificate or
                        citizenship certificate.`,
          birthCertificate: 'If you do not have a passport then please upload a copy of your birth certificate.',
          residentsVisa: 'Upload a copy of your residents visa.',
          starGuidance: `<p>Once you are enrolled you need to either send a verified copy of your citizenship document certified by your Principal or a JP in the mail to us, or bring in the original so we can sight it.
            Grades will not be released until we have verified the citizenship status.</p>
            <p>For more information see the STAR <a href="${externalUrls.star}" target="_blank" rel="noopener">How to Apply</a> tab on the UC&nbsp;website.</p>`,
          mcedGuidance: `Please note that while you are supplying an electronic version in this application, you may be required to provide a certified copy in person
            due to name changes, your secondary qualification, etc. More information on this is available <a href="${externalUrls.ucDocumentCertification}" target="_blank">here</a>`,
        },
      },
      // TODO: need to clean up some of the data setup here
      onlineAdditionalDetails: {
        usingUcOnline: {
          header: 'Using UC Online application to enrol',
          description: `To view a guide that shows you how to use UC Online to complete your application, select the <strong>Application to enrol user guide</strong> button below.`,
        },
        reviewYourProfile: 'Review your profile',
        yourDetails: 'Your details',
        editYourProfile: 'Edit your profile',
        formItemHint: {
          leaveBlankIfNotKnow: 'Leave blank if not known',
        },
        identificationDetailsSection: {
          header: 'Identification Details',
          description: [
            `Enter your legal details as stated on your identification document such as passport,
            birth certificate or citizenship certificate.`,
            `We are also asking for other names that you might have used to help us identify you.`,
          ],
          birthDateQuestion: 'What is your birth date?',
          labels: {
            nsnNumber: 'Do you know your National Student Number (NSN)?',
            nsnDescription: `This is a National Student Number or New Zealand Qualifications Authority (NZQA) number given to those who have previously studied in New Zealand.
          Your NSN is usually a 9 digit number. Do not include any zeroes at the start of the number.`,
            studentNumber: 'UC ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            mobile: 'Mobile',
            dateOfBirth: 'Date of birth',
            gender: 'Gender',
            citizenship: 'Citizenship',
            iwi: 'Iwi',
            ethnicity: 'Ethnicity',
            postCode: 'Post code',
          },
        },
        citizenshipSection: {
          header: 'Citizenship and Residency',
          categoryQuestion: 'What is your citizenship or residency category?',
          categoryLabel: 'Citizenship Category',
          countryQuestion: 'What is your country of citizenship?',
          countryLabel: 'Citizenship Country',
          liveInNzQuestion: 'Will you be living in New Zealand during your study?',
          liveInNzDescription: `Answer 'yes' if you'll be spending most of your time in New Zealand, with only short
            overseas vacations. Answer 'no' if you'll be spending most of your time out of New Zealand.`,
          ministryRuling: 'NZ Ministry of Education ruling:',
          rulingDesc: `NZ Ministry of Education classifies you as an <strong>International fee-paying</strong> student if you're not
                      residing in NZ during your study and you're either:
                      <ul>
                        <li>A permanent NZ resident </li>
                        <li>An Australian citizen, or </li>
                        <li>A permanent Australian resident. </li>
                      </ul>`,
          internationalRulingDesc: `If you intend to spend most of your time out of New Zealand you may not be eligible for the programme you choose.
            To confirm your eligibility, contact the ${contactCentre}`,
          passportInfo: `Upload a copy of the information page from your current passport, birth certificate or
                        citizenship certificate.`,
          birthCertificate: 'If you do not have a passport then please upload a copy of your birth certificate.',
          residentsVisa: 'Upload a copy of your residents visa.',
          starGuidance: `<p>Once you are enrolled you need to either send a verified copy of your citizenship document certified by your Principal or a JP in the mail to us, or bring in the original so we can sight it.
            Grades will not be released until we have verified the citizenship status.</p>
            <p>For more information see the STAR <a href="${externalUrls.star}" target="_blank" rel="noopener">How to Apply</a> tab on the UC&nbsp;website.</p>`,
          mcedGuidance: `Please note that while you are supplying an electronic version in this application, you may be required to provide a certified copy in person
            due to name changes, your secondary qualification, etc. More information on this is available <a href="${externalUrls.ucDocumentCertification}" target="_blank">here</a>`,
        },
        contactDetailsSection: {
          header: 'Permanent Address',
          description: 'How can we contact you regarding your application?',
          emailSubsectionQuestion: 'What is your email address?',
          emailLabel: 'Email',
          mobilePlaceholder: '12 345 6789',
          addressQuestion: 'What is your current address?',
          addressPredictionHelp: 'Start typing and then select your address',
          studyAddressHeader: 'Study Address',
          studyAddressQuestion: `Complete this section if you know where you're staying whilst studying at UC.`,
          copyAddressButton: 'Copy Permanent Address',
        },
      },
      onlineStudyHistory: {
        description: `NZ law requires applicants to be of a certain standard  before they can study at a NZ University.
                      There are a number of ways to gain admission to a NZ University and this section assists us in determining
                        if you meet the University admission standard. Your eligibility is based on your qualifications,
                      results, and English language proficiency.`,
        studyLevelAdditionalText: {
          [QUALIFICATION_LEVELS.SECONDARY]:
            'e.g. NCEA, Bursary, International Baccalaureate Diploma, Cambridge International Examinations',
          [QUALIFICATION_LEVELS.FOUNDATION]: 'e.g. UC CUP, UCIC',
          [QUALIFICATION_LEVELS.UNDER_GRADUATE]: 'e.g. Diploma, Certificate, Bachelors degree up to level 7',
          [QUALIFICATION_LEVELS.POST_GRADUATE]: 'e.g. Honours, Masters, PhD level 8 and above',
        },
        highestStudyLevelQuestion:
          'What is your highest level of study, or the qualification you are currently working towards?',
      },
      onlineVerification: {
        title: 'Thanks for providing your details',
        description: [
          'To meet New Zealand government requirements for you to be awarded the full micro-credential and points associated; we will need valid proof of identity.',
          "If you don't have identity documents on hand, you can return to this at a later date.",
        ],

        optionDescription: 'Please indicate which option you will use.',
        documentOption: 'Identity document upload',
        documentOptionDesc:
          'This option will prompt you to upload a certified document such as birth certificate, passport or citizenship document.',
        realmeOption: 'Connect my RealMe account',
        realmeOptionDesc:
          'This option will redirect you to log in via RealMe and returns you back to this application after permiting your data to be shared with the University of Canterbury.',
        documentUploadTitle: 'Identity document upload',
        passportInfo: `Upload a copy of the information page from your current passport, birth certificate or
                        citizenship certificate.`,
        realMeConnectTitle: 'Connecting your RealMe',
        realMeConnectHelp:
          'RealMe is an initiative from the New Zealand government and New Zeanland Post to make doing things online easier and more secure. This enables you to provide your details of your RealMe identity and safely and securely share these details with the University of Canterbury to prove who you are.',
        realMeVerificationSuccessDesc: 'RealMe connected: Your account is verified',
        realMeVerificationFailedDesc:
          'Your RealMe account still requires validation for proof of identity. As such the Univeristy will require identity documents to be uploaded via the document uploader.',
        realMeVerificationConnectionIssueDesc:
          'We cant’ connect to RealMe at the moment; either retry again or provide us documentation via our document uploader. ',
      },
      onlineEnrolmentSuccess: {
        title: 'Woohoo! You’re enrolled with UC Online!',
        description: [
          "You're almost ready to start learning",
          "You'll receive an email within an hour with instructions for how to access your learning. Our Learner Experience team will also be in touch closer to your course start date.",
          'In the meantime, please send us any questions to <a href="mailto:support@uconline.ac.nz">support@uconline.ac.nz</a>',
          'We look forward to seeing you online!',
        ],
      },
      onlinePaymentSuccess: {
        title: 'Payment successful',
        description: ['Your payment has been processed successfully!', "Please select 'Continue' to proceed."],
      },
      onlinePaymentFailed: {
        title: 'Your payment was unsuccessful',
        description: [
          'There was an error processing this transaction. Check your connection and card details.',
          'You can try this purchase again or return you to the homepage.',
        ],
      },
      onlineResolveCheckoutSession: {
        awaitingCheckoutSession: {
          sectionHeader: 'One moment please',
          content: `<p>Please wait while we take you to our secure payment portal.</p>
            <p>This shouldn't take long - please don't refresh your browser or close this page.</p>`,
        },
      },
      onlineResolveEnrolmentProvisioning: {
        awaitingEnrolmentProvisioning: {
          sectionHeader: 'One moment please',
          content: `<p>Please wait while we finalise your enrolment.</p>`,
        },
      },
    },
  },
};
