import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Address, AddressModel } from '@shared/models/address';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSelectorComponent),
      multi: true,
    },
  ],
})
export class AddressSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() required = true;
  @Input() task: Task;
  @Input() basePath = '/applicant/contact_detail/current_address';
  @Input() testSelector = '';

  strings = strings.components.molecules.addressSelector;
  addressModel: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.setupAddressGroup();
  }

  get testSelectorId() {
    return `${this.testSelector}-address`;
  }

  getHintPath(prop) {
    return `${this.basePath}/${prop}`;
  }

  // the method set in registerOnChange, it is just
  // a placeholder for a method that takes one parameter,
  // we use it to emit changes back to the form
  private propagateChange: (...args) => void = () => {
    // No-op
  };
  private propagateTouch: (...args) => void = () => {
    // No-op
  };

  ngOnInit() {
    this.addressModel.valueChanges.subscribe((address) => {
      this.propagateChange(AddressModel.createFrom(this.stripSpecialChars(address, true)));
    });
  }

  stripSpecialChars(address, updateInput?: boolean) {
    const shouldStripSpecialChars = (val) => val && typeof val === 'string' && val.match(/[^ \f\r\n-~]/gi);

    Object.keys(address)
      .filter((line) => shouldStripSpecialChars(address[line]))
      .forEach((line) => {
        const replaceMacron = address[line].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        address[line] = replaceMacron.replace(/[^ \f\r\n-~]/gi, '');
        if (updateInput) {
          this.addressModel.get(line).setValue(address[line], { emitEvent: false });
        }
      });

    return address;
  }

  private setupAddressGroup(): void {
    this.addressModel = this.formBuilder.group({
      line1: '',
      line2: '',
      country: '',
      town: '',
      region: '',
      postcode: '',
      latitude: '',
      longitude: '',
    });
  }

  /**
   * Write a new value to the element.
   */
  writeValue(obj: Address): void {
    this.addressModel.patchValue(obj, { emitEvent: false });
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  /**
   * Set the function to be called'
   * when the control receives a touch event.
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
}
