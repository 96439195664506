import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';

import {
  FormTemplateComponent,
  FormHeaderComponent,
  FormActionComponent,
} from '@shared/components/templates/form-template/form-template.component';

@NgModule({
  declarations: [FormTemplateComponent, FormHeaderComponent, FormActionComponent],
  imports: [CommonModule, RouterModule, AtomsModule, MoleculesModule, OrganismsModule],
  exports: [FormTemplateComponent, FormHeaderComponent, FormActionComponent],
})
export class SharedTemplatesModule {}
