import { Component, Input, OnInit } from '@angular/core';
import { get } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import { sideNavLinks } from '@constants/links.constants';
import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { environment } from '@environment';
import { APP_NAMES } from '@shared/constants/app-names.constants';
import { APPLICATION_STATE } from '@shared/constants/states.constants';
import { UserSubTypes, UserTypes } from '@shared/models/user';
import { ConditionService, ConditionsByYear } from '@shared/services/condition/condition.service';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() isOpen: boolean;
  @Input() defaultOpen = true;

  internalUrls = internalUrls;
  strings = strings.components.organisms.sidenav;
  externalUrls = externalUrls;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  env: Record<string, any> = environment.features || {};
  sections = [];
  isEnroled = false;
  appSub: Subscription;
  agentApp = environment.scope === UserTypes.agent;
  showLinks = false;
  useSmallTitle = environment.scope === 'agent';
  impersonating = false;
  infoRequiredLinks = [];

  constructor(
    private userService: UserService,
    private conditionService: ConditionService,
    private enrolmentSummary: EnrolmentSummaryService,
  ) {}

  // eslint-disable-next-line complexity
  get titleString() {
    if (environment.scope === UserTypes.staff && this.impersonating) {
      return APP_NAMES[UserTypes.student];
    }
    if (environment.subScope === UserSubTypes.ucOnlineStudent) {
      return APP_NAMES[UserSubTypes.ucOnlineStudent];
    }
    return APP_NAMES[environment.scope];
  }

  get isUcOnlineStudent() {
    return environment.scope === UserTypes.student && environment.subScope === UserSubTypes.ucOnlineStudent;
  }

  get studentOrImpersonating() {
    return environment.scope === UserTypes.student || this.impersonating;
  }

  ngOnInit() {
    this.enrolmentSummary.summary$.subscribe((es) => {
      const isEmbargoed = es && !!es.academicYears.find((ay) => ay.state === APPLICATION_STATE.NCEA_EMBARGO);
      this.sections = sideNavLinks({ isEmbargoed });
    });

    this.userService.userDetail.pipe(filter((x) => !!x)).subscribe((user) => {
      this.impersonating = !!user.impersonated;
      this.showLinks = this.agentApp || !!get(user, 'student.studentId');
    });

    this.conditionService.requiredConditions.subscribe((conditionsByYear: ConditionsByYear) => {
      const years = Object.keys(conditionsByYear).filter((year) => !!conditionsByYear[year].length);
      this.infoRequiredLinks = years.map((year) => {
        return {
          href: this.internalUrls.informationRequired(year),
          title: `${this.strings.informationRequiredText} ${year}`,
        };
      });
    });
  }
}
