import { Component, EventEmitter, Input, Output, HostBinding, OnChanges, SimpleChanges } from '@angular/core';

import { CourseOccurrence } from '@shared/models/course';
import strings from '@constants/strings.constants';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';

@Component({
  selector: 'uc-course-summary-card',
  templateUrl: './course-summary-card.component.html',
  styleUrls: ['./course-summary-card.component.scss'],
})
export class CourseSummaryCardComponent implements OnChanges {
  strings = strings.components.molecules.courseSummaryCard;

  @HostBinding('class.card--expanded') isCardExpanded = false;

  @Input() course: CourseOccurrence;
  @Input() compact: boolean;
  @Input() otherQualCode: string;
  @Input() errorTriggered = false;

  @Output() removeCourse = new EventEmitter();
  @Output() undoChangeAction = new EventEmitter();
  working = false;

  get changeActionClass() {
    if (!this.hasChangeAction) {
      return '';
    }
    return this.course.changeAction === CHANGE_ACTIONS.ADDED ? 'added-course' : 'dropped-course';
  }

  get changeActionLabel() {
    return this.strings.states[this.course.changeAction];
  }

  get hasChangeAction() {
    return !!this.course.changeAction && this.course.changeAction !== CHANGE_ACTIONS.NONE;
  }

  removeACourse() {
    if (this.working) {
      return;
    }
    this.working = true;
    const course = Object.assign({}, this.course);
    course.selectedCourse = false;
    course.activeCourse = false;
    this.removeCourse.emit(course);
  }

  undoChange() {
    if (this.working) {
      return;
    }
    this.working = true;
    const course = Object.assign({}, this.course);
    course.selectedCourse = false;
    course.activeCourse = this.course.changeAction === CHANGE_ACTIONS.DROPPED;
    this.undoChangeAction.emit(course);
  }

  toggleExpandableContent() {
    this.isCardExpanded = !this.isCardExpanded;
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errorTriggered && changes.errorTriggered.currentValue && this.working) {
      this.working = false;
    }
  }
}
