import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'uc-required-field',
  template: ` <span class="required-asterisk">*</span> `,
  styleUrls: ['./required-field.component.scss'],
})
export class RequiredFieldComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
