import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export enum RealMeVerificationStatus {
  SUCCESS = 'Success',
  FAILED = 'Failed',
  CONNECTION_ISSUE = 'Connection Issue',
}

@Component({
  selector: 'uc-online-verification',
  templateUrl: './online-verification.component.html',
  styleUrls: ['./online-verification.component.scss'],
})
export class OnlineVerificationComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  strings = strings.components.tasks.onlineVerification;
  log: Logger;

  onlineVerificationPage: UntypedFormGroup;
  onlineVerificationForm: UCElementGroup;
  verificationAdditionalText = [
    {
      code: 'REALME',
      description: this.strings.realmeOptionDesc,
    },
    {
      code: 'DOCUMENT',
      description: this.strings.documentOptionDesc,
    },
  ];

  verificationOptions = [
    {
      labelText: this.strings.realmeOption,
      value: 'REALME',
    },
    {
      labelText: this.strings.documentOption,
      value: 'DOCUMENT',
    },
  ];

  isRealMeVerification = false;
  realMeVerificationStatus: RealMeVerificationStatus;

  private currentApplicant: Applicant;
  currentApplication: Application;

  constructor(
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private formModel: FormModelMapperService,
    ls: LoggingService,
  ) {
    super();
    this.log = ls.createLogger(this);
  }

  // eslint-disable-next-line class-methods-use-this
  get verification() {
    return 'DOCUMENT';
  }

  get isConnectedToRealMe(): boolean {
    return this.verification === 'REALME';
  }

  get isIdentityDocumentUpload(): boolean {
    return this.verification === 'DOCUMENT';
  }

  get isRealMeVerificationSuccessful(): boolean {
    return this.realMeVerificationStatus === RealMeVerificationStatus.SUCCESS;
  }

  get isRealMeVerificationFailed(): boolean {
    return this.realMeVerificationStatus === RealMeVerificationStatus.FAILED;
  }

  get isRealMeVerificationDisconnected(): boolean {
    return this.realMeVerificationStatus === RealMeVerificationStatus.CONNECTION_ISSUE;
  }

  // eslint-disable-next-line class-methods-use-this
  private createUcElementGroup(): UCElementGroup {
    return group({
      verification: control({
        model: 'applicant',
        path: '/ueVerification/type',
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
    });
  }

  ngOnInit() {
    this.onlineVerificationForm = this.createUcElementGroup();
    this.onlineVerificationPage = this.onlineVerificationForm.asControl() as UntypedFormGroup;
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.onlineVerificationForm);
  }

  public checkFormValidity(): string[] {
    this.onlineVerificationPage.updateValueAndValidity();
    if (this.onlineVerificationPage.invalid) {
      return this.formModel.getInvalidPathesInElementGroup(this.onlineVerificationForm);
    } else {
      return [];
    }
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  update() {}

  connectToRealMe() {
    this.isRealMeVerification = true;
    this.realMeVerificationStatus = RealMeVerificationStatus.SUCCESS;
  }
}
