// Online available qualification codes for online learner.
export const ONLINE_QUALIFICATION_OPTIONS = [
  'MORGPSYC',
  'CERTCJ',
  'PGCERTCYSEC',
  'MORGPSYC',
  'PGCERTCOUNST',
  'MHEALSC',
  'PGDIPNURS',
  'MADVNURS',
  'MEM',
  'PGDIPEM',
  'MADS',
  'PGDIPADS',
  'PGCERTDIGIMKTG',
  'PGCERTSTRATLDRSHIP',
  'MADS',
  'PGDIPEM',
  'MEM',
];
export const ONLINE_LOCATION_FILTER = 'UC Online';
