import { Component, Input } from '@angular/core';

import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-online-disabled-atom-state',
  templateUrl: './online-disabled-atom-state.component.html',
  styleUrls: ['./online-disabled-atom-state.component.scss'],
})
export class OnlineDisabledAtomStateComponent {
  @Input() value: string;

  strings = strings.components.atoms.disabledStateComponent;
}
