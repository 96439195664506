import { Injectable } from '@angular/core';

@Injectable()
export class TooltipService {
  components: any[] = [];

  destroyTooltip(id: number) {
    const idx = this.components.findIndex((t) => {
      return t.id === id;
    });
    this.components.splice(idx, 1);
  }
}
