import { Component, OnInit, Input } from '@angular/core';
import { Validators, UntypedFormGroup } from '@angular/forms';
import { get, set } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Applicant } from '@shared/models/applicant';
import { AttainedQualification } from '@shared/models/attained-qualification';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import {
  UCElementGroup,
  group,
  control,
  valueToRefData,
  refDataToValue,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-ue-admission',
  templateUrl: './ue-admission.component.html',
})
export class UeAdmissionComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;

  strings = strings.components.tasks.universityAdmission;
  ueForm: UCElementGroup;
  uePage: UntypedFormGroup;
  currentApplicant: Applicant;
  log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  private createForm(): UCElementGroup {
    return group({
      type: control({
        model: 'applicant',
        path: '/ueQualification/type',
        validators: [Validators.required],
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      dateAttained: control({ model: 'applicant', path: '/ueQualification/dateAttained' }),
    });
  }

  ngOnInit() {
    this.ueForm = this.createForm();
    this.uePage = this.ueForm.asControl() as UntypedFormGroup;

    this.applicantService.applicant.pipe(takeUntil(this.componentDestroyed)).subscribe((applicant: Applicant) => {
      this.formModel.updateFormFromModel(this.ueForm, applicant);
      this.currentApplicant = applicant;
    });
  }

  public updateFormValidity() {
    // no-op
  }

  update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    }

    if (get(this.currentApplicant, 'ueQualification.type.code') === 'NONE') {
      set(this.currentApplicant, 'ueQualification.dateAttained', null);
    }

    if (!this.currentApplicant.ueQualification) {
      this.currentApplicant.ueQualification = new AttainedQualification();
    }

    this.formModel.updateModelFromForm(this.ueForm, this.currentApplicant);

    this.applicantService.updateApplicant(this.currentApplicant).subscribe({
      next: (applicant) => {
        if (applicant) {
          this.log.info('update the applicant successfully');
          this.next.emit();
        }
      },
      error: () => {
        this.errors.emit();
        this.log.error('error throw while applicant the application');
      },
    });
  }
}
