export default {
  hints: {
    requiredField: 'Please fill in this required field.',
    requiredFields: 'Please fill in all required fields.',
    requiredOption: 'Please select an option.',
    yearCompletedDateOrder: 'Year completed must be later than the year started.',
    mustHaveChanges: `You must make changes to your enrolment before submitting.`,
    mustHaveApplicationChanges: `You must make changes to your qualification selection before submitting.`,
    requiredEmergencyContactNumber: 'Please provide a contact number.',
    missingNumber: 'Please provide a valid contact phone number.',
    missingCountry: 'Please provide a valid country code.',
    requiredPhoneNumber: 'Please provide at least one contact phone number.',
    requiredFile: 'Please upload this required file.',
    mustContactSupervisor: 'You must have confirmation of a supervisor before you can submit this application.',
    invalidCountrySchool: 'You cannot select Overseas Secondary School if you studied in New Zealand.',
    mustHaveContactedSupervisor: 'You must contact a supervisor at UC before you can progress your application.',
    invalidGradeMark: 'Invalid mark / grade combination.',
    invalidMark: 'A mark must be from 0 - 100 with up to 2 decimal places.',
    maxCharacters: (limit: number) => `You have entered the maximum ${limit} characters`,
    errors: {
      validation: {
        default: 'Please enter a valid input',
        invalid_NSN: 'You have entered an invalid NSN. Please enter your correct NSN or leave blank if unsure.',
        email: 'Please provide a valid email address.',
        required: 'Please fill in this required field.',
        date: {
          in_range: 'This date is outside of the allowed range for this field.',
          past: 'Please enter a date in the past.',
        },
        max_value: 'You must enter a number from 0 - 120',
        min_value: 'You must enter a number from 0 - 120',
        format: {
          code: 'Please enter a valid option.',
          email: 'Please provide a valid email address.',
          nsn_number: 'Please enter a valid NSN number.',
          already_validated: 'This data has already been validated.',
          read_only: 'You are unable to update this read-only field.',
          integer: 'Please enter a valid number.',
        },
        didFailDatePickerValidator: 'Please enter a date in the format dd/mm/yyyy.',
      },
    },
  },
};
