<uc-form-template [formGroup]="universityAdmissionPage">
  <uc-form-header title="{{task?.title}}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header title="{{strings.header}}">
      <p>{{strings.description}}</p>
    </uc-section-header>
    <uc-university-entrance [task]="task" [ueForm]="universityAdmissionPage.get('ueQualification')"></uc-university-entrance>
  </uc-section>
</uc-form-template>