import { Injectable } from '@angular/core';

import { BaseModalComponent } from '@shared/components/atoms/base-modal/base-modal.component';

const MODAL_ZINDEX_START = 1000;

@Injectable()
export class ModalCoordinatorService {
  private modalsAndBackdrops: Map<BaseModalComponent, HTMLElement> = new Map();

  private escHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.dismissCurrent();
    }
  };

  zIndexForNext(type: 'modal' | 'backdrop') {
    const offset = type === 'modal' ? 1 : 0;
    return MODAL_ZINDEX_START + this.modalsAndBackdrops.size * 2 + offset;
  }

  push(modal: BaseModalComponent) {
    if (!this.modalsAndBackdrops.size) {
      this.bindEscHandler();
    }

    if (!this.modalsAndBackdrops.has(modal)) {
      const backdrop = this.addBackdropToDocument();
      modal.zIndex = this.zIndexForNext('modal');
      this.modalsAndBackdrops.set(modal, backdrop);
    }
  }

  addBackdropToDocument() {
    const backdrop = this.createBackdrop();

    this.dismissModalOnBackdropClick(backdrop);
    this.animateBackdropOpening(backdrop);

    document.body.appendChild(backdrop);
    return backdrop;
  }

  createBackdrop() {
    const backdrop = document.createElement('div');
    backdrop.style.zIndex = String(this.zIndexForNext('backdrop'));
    return backdrop;
  }

  dismissModalOnBackdropClick(backdrop) {
    backdrop.addEventListener('click', () => this.dismissCurrent());
  }

  animateBackdropOpening(backdrop: HTMLElement) {
    backdrop.classList.add('modal-backdrop', 'opening');
    this.afterAnimation(backdrop, () => backdrop.classList.remove('opening'));
  }

  remove(modal: BaseModalComponent) {
    if (this.modalsAndBackdrops.has(modal)) {
      this.removeBackdropFromDocument(this.modalsAndBackdrops.get(modal));
      this.modalsAndBackdrops.delete(modal);
    }

    if (!this.modalsAndBackdrops.size) {
      this.unbindEscHandler();
    }
  }

  removeBackdropFromDocument(backdrop: HTMLElement) {
    this.animateBackdropClosing(backdrop);
    this.afterAnimation(backdrop, () => backdrop.remove());
  }

  animateBackdropClosing(backdrop: HTMLElement) {
    backdrop.classList.add('closing');
  }

  afterAnimation(element: HTMLElement, action: () => void) {
    const handler = () => {
      element.removeEventListener('animationend', handler);
      action();
    };
    element.addEventListener('animationend', handler);
  }

  dismissCurrent() {
    const currentModal = [...this.modalsAndBackdrops.keys()][this.modalsAndBackdrops.size - 1];
    if (!currentModal) {
      throw new Error('No modals are currently open.');
    } else if (currentModal.dismissable) {
      currentModal.dismiss();
    }
  }

  bindEscHandler() {
    document.addEventListener('keyup', this.escHandler);
  }

  unbindEscHandler() {
    document.removeEventListener('keyup', this.escHandler);
  }
}
