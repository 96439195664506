import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Modal } from 'ngx-modialog-11/plugins/bootstrap';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { UCError } from '@shared/models/errors';
import { User } from '@shared/models/user';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { showModalLoadingState } from '@shared/services/modal/modal.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-online-account-settings',
  templateUrl: './online-account-settings.component.html',
  styleUrls: ['./online-account-settings.component.scss'],
})
export class OnlineAccountSettingsComponent implements OnInit {
  checkboxOptions: { labelText: string; value: string }[] = [];
  log: Logger;
  accountPage: UntypedFormGroup;
  currentUser: User;
  passwordWaiting = false;
  emailLoading = false;

  passwordErrorMessages: UCError[] = [];
  emailErrorMessages: UCError[] = [];
  successMessage: string[] = [];
  strings = strings.components.template.accountSettings;

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private modal: Modal,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  /* Disable ESLint rule for getter - can't be static.
   * See: https://github.com/eslint/eslint/issues/7911
   */
  // eslint-disable-next-line class-methods-use-this
  get emailSendingEnabled(): boolean {
    return !!environment.features.sendEmail;
  }

  get changeEmailForm(): UntypedFormGroup {
    return this.accountPage.get('changeEmailForm') as UntypedFormGroup;
  }

  get changePasswordForm(): UntypedFormGroup {
    return this.accountPage.get('changePasswordForm') as UntypedFormGroup;
  }

  ngOnInit() {
    this.userService.currentUser.subscribe((u) => {
      this.currentUser = u;
    });

    this.accountPage = this.fb.group({
      changeEmailForm: this.fb.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
      }),
      changePasswordForm: this.fb.group({
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.minLength(User.MIN_PASSWORD_LENGTH)],
        confirmPassword: ['', Validators.minLength(User.MIN_PASSWORD_LENGTH)],
      }),
    });
  }

  // eslint-disable-next-line max-lines-per-function
  openEmailModal() {
    this.emailErrorMessages = [];
    this.emailLoading = true;
    const newEmail = this.changeEmailForm.get('email').value;
    const password = this.changeEmailForm.get('password').value;
    if (!newEmail) {
      this.emailLoading = false;
      return;
    }

    if (!this.userService.isFirebaseEmailLogin) {
      this.emailErrorMessages = [{ code: 'auth.invalidAccountType', data: strings.errors.auth.invalidAccountType }];
      this.emailLoading = false;
      return;
    }

    this.userService
      .revalidate(password)
      // eslint-disable-next-line max-lines-per-function
      .then(() => {
        this.modal
          .confirm()
          .size('lg')
          .isBlocking(true)
          .showClose(false)
          .title('Are you sure you want to update your email address?')
          .body(
            `You will be logged out and you will receive a verification email shortly after you click 'SEND VERIFICATION EMAIL'.
      Once you have accepted the verification email you will have to login using your new email address.`,
          )
          .okBtn('SEND VERIFICATION EMAIL')
          .cancelBtn('Cancel')
          .cancelBtnClass('cancel-btn')
          .open()
          .result.then(() => {
            this.emailLoading = false;
            this.updateEmail(newEmail, password);
          })
          .catch(() => {
            document.body.classList.remove('modal-open');
          });
      })
      .catch((err) => {
        this.emailLoading = false;
        this.displayErrors(err);
      });
  }

  updateEmail(newEmail, password) {
    showModalLoadingState();
    this.userService
      .revalidate(password)
      .then(() => this.userService.updateEmail(newEmail))
      .then(() => this.userService.verifyEmail())
      .then(() => this.userService.logout())
      .catch((err) => {
        this.displayErrors(err);
      });
  }

  displayErrors(err) {
    const ucError = this.userService.formatFirebaseError(err);

    if (ucError.code === 'auth.userNotFound') {
      ucError.code = 'auth.wrongPassword';
      ucError.data = strings.errors.auth.wrongPassword;
    }
    this.emailErrorMessages = [ucError];
    this.log.error(err);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  updatePassword() {
    this.passwordErrorMessages = [];
    this.successMessage = [];
    if (this.passwordWaiting) {
      return;
    }
    const oldPassword = this.changePasswordForm.get('oldPassword').value;
    const newPassword = this.changePasswordForm.get('newPassword').value;
    const confirmPassword = this.changePasswordForm.get('confirmPassword').value;

    if (this.changePasswordForm.status !== 'VALID') {
      this.passwordErrorMessages = [{ code: 'invalidForm', data: strings.errors.invalidForm }];
      return;
    }

    if (confirmPassword !== newPassword) {
      this.passwordErrorMessages = [{ code: 'auth.passwordsDontMatch', data: strings.errors.auth.passwordsDontMatch }];
      return;
    }
    this.passwordWaiting = true;

    this.userService
      .revalidate(oldPassword)
      .then(() => this.userService.updatePassword(newPassword))
      .then(() => {
        this.successMessage = [this.strings.changePassword.successMessage];
        this.passwordWaiting = false;
        this.changePasswordForm.reset();
      })
      .catch((err) => {
        const ucError = this.userService.formatFirebaseError(err);

        if (ucError.code === 'auth.userNotFound') {
          ucError.code = 'auth.wrongPassword';
          ucError.data = strings.errors.auth.wrongPassword;
        }
        this.passwordWaiting = false;
        this.passwordErrorMessages = [ucError];
        this.log.error(err);
      });
  }
}
