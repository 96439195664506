import { firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';

import { IProcessRouteParams } from '../resolvers/process-resolver/process-resolver.service';
import { UserService } from '../user/user.service';
import { ActivityStrategy, UserActivity } from './user-activity.service';

export abstract class UserActivityStrategyBase implements ActivityStrategy {
  protected userActivity = new ReplaySubject<UserActivity>(1);
  protected userIdentifier = new ReplaySubject<string>(1);

  constructor(userService: UserService) {
    userService.userDetail.subscribe((userDetail) => this.userIdentifier.next(userDetail?.student?.identifier));
  }

  get activity(): Observable<UserActivity> {
    return this.userActivity.asObservable();
  }

  hasTask(task: IProcessRouteParams): Observable<boolean> {
    return this.activity.pipe(
      map((activity) => {
        if (activity?.taskSubmissions) {
          return UserActivityStrategyBase.anyTasksMatch(task, activity.taskSubmissions);
        } else {
          return false;
        }
      }),
    );
  }

  private static anyTasksMatch(task: IProcessRouteParams, list: IProcessRouteParams[]): boolean {
    return list.some((submission) => UserActivityStrategyBase.tasksMatch(task, submission));
  }

  private static tasksMatch(a: IProcessRouteParams, b: IProcessRouteParams): boolean {
    return ['process', 'year', 'stage', 'task'].every((key) => a[key] === b[key]);
  }

  async addTask(task: IProcessRouteParams): Promise<void> {
    const activity = await this.getLatestActivity();

    if (!UserActivityStrategyBase.anyTasksMatch(task, activity.taskSubmissions)) {
      activity.taskSubmissions.push(task);
    }

    await this.set('taskSubmissions', activity.taskSubmissions);
  }

  async removeAllTasksFor(process: string, year: string): Promise<void> {
    const activity = await this.getLatestActivity();
    const filtered = activity.taskSubmissions.filter((ts) => ts.process !== process || ts.year !== year);
    await this.set('taskSubmissions', filtered);
  }

  getViewedDocuments(): Observable<string[]> {
    return this.activity.pipe(map((activity) => activity?.viewedDocuments));
  }

  async addViewedDocument(id: string): Promise<void> {
    const activity = await this.getLatestActivity();

    if (!activity.viewedDocuments.includes(id)) {
      activity.viewedDocuments.push(id);
    }

    await this.set('viewedDocuments', activity.viewedDocuments);
  }

  async replaceActivity(activity: UserActivity): Promise<void> {
    await this.store(activity);
  }

  protected async getLatestActivity(): Promise<UserActivity> {
    const activity = await firstValueFrom(this.activity);

    if (!activity) {
      throw new Error('No activity found; possibly no user is logged in.');
    }

    return activity;
  }

  protected async set<K extends keyof UserActivity>(key: K, value: UserActivity[K]): Promise<void> {
    const activity = await firstValueFrom(this.activity);
    const newData = { ...activity, [key]: value };
    await this.store(newData);
  }

  protected abstract store(data: UserActivity): Promise<void>;
}
