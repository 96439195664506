import { Component, NgZone, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { FileUploadComponent } from '@shared/components/organisms/file-upload/file-upload.component';
import { DocumentService } from '@shared/services/document/document.service';
import { FileUploadServiceEvent } from '@shared/services/file-upload/file-upload.service';
import { LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['../organisms.scss'],
})
export class FileUploadListComponent extends FileUploadComponent implements OnInit {
  @Input() uploadedFiles: { id: string; categoryCode: string }[];
  @Output() deleteFile = new EventEmitter<any[]>();
  @Output() removeFileError = new EventEmitter<string>();
  showSpinner: boolean[] = [];

  constructor(docService: DocumentService, logService: LoggingService, zone: NgZone) {
    super(docService, logService, zone);
    this.logger = logService.createLogger(this);
  }

  get currentFiles(): FileUploadServiceEvent[] {
    return this.files.filter((f) => this.uploadedFiles.find((fid) => fid.id === f.file.id) && !f.file.deleted);
  }

  setSpinner(index, bool) {
    this.showSpinner[index] = bool;
    return this.showSpinner;
  }

  showYear(file) {
    return this.category.documentType === 'application' ? `${file.academicYear} | ` : null;
  }

  removeFile(doc: FileUploadServiceEvent, index?: number) {
    this.setSpinner(index, true);
    this.docService.softDelete(doc.file).subscribe(
      (file) => {
        doc.file = file;
        const updatedUploadedFiles = this.uploadedFiles.filter((uf) => uf.id !== file.id);
        this.deleteFile.emit(updatedUploadedFiles);
        this.setSpinner(index, false);

        this.displaySpinner = false;
        this.files = this.files.filter((el) => {
          return el.file.id !== doc.file.id;
        });
      },
      (err) => {
        this.logger.error('Removing uploaded file error', err);
        this.removeFileError.emit(this.strings.removeFileUploadError);
      },
    );
  }
}
