import { Component, OnInit } from '@angular/core';
import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerStrings = strings.components.molecules.footer;
  constructor() {}

  ngOnInit() {}
}
