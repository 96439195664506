import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { cloneDeep, get, set } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import {
  EnrolmentUpdateEvent,
  QualEnrolmentSelectorContext,
  QualificationEnrolment,
} from '@shared/applicant/qualification/model';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { EnrolledQualification, QualificationSummary, SubjectOptionsList } from '@shared/models/qualification';
import { Task } from '@shared/models/task';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

@Component({
  selector: 'uc-qualification-coe',
  templateUrl: './qualification-coe.component.html',
  styleUrls: ['./qualification-coe.component.scss'],
})
export class QualificationCOEComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  strings = strings.components.molecules.changeofEnrolmentQualification;
  qualificationData: QualificationSummary[];
  fullAwardEnrolments: QualificationEnrolment[] = [];
  qualStrings = strings.components.tasks.qualification;
  log: Logger;
  isLoading = false;
  isQualLoading = false;
  isSubjectOptionLoading = false;
  currentApplication: Application;
  editMode = false;
  showQualEnrolmentSelector: Array<boolean>;
  changeOfEnrolment: ChangeOfEnrolment;
  addQualSelector = false;
  academicYear: string;
  enrolments: QualEnrolmentSelectorContext[] = [];
  newCOEQual: ChangeOfEnrolment;
  process: string;
  qualSelectorForm: UntypedFormGroup;
  fullAwardEnrolmentCopy: QualificationEnrolment[];
  selectedQual: string[];
  enableSave = false;
  enableQualSave = false;
  undoLoading = false;
  dataLoading = true;

  constructor(
    loggingService: LoggingService,
    private qualificationService: QualificationService,
    private changeEnrolmentService: ChangeOfEnrolmentService,
    private processService: ProcessService,
    private router: Router,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get headerText() {
    return this.strings.coeHeader;
  }

  ngOnInit(): void {
    this.stopTaskSubmit = true;
    this.process = 'new_student';
    this.setupQualData();
  }

  setupQualData() {
    this.changeEnrolmentService
      .getChangeOfEnrolment()
      .pipe(
        switchMap((coe: ChangeOfEnrolment) => {
          return this.buildQualEnrolment(coe);
        }),
      )
      .subscribe((qualEnrolments: QualificationEnrolment[]) => {
        this.fullAwardEnrolments = qualEnrolments;
        this.showQualEnrolmentSelector = new Array<boolean>(this.fullAwardEnrolments.length);
        this.dataLoading = false;
      });
  }

  buildQualEnrolment(coe: ChangeOfEnrolment) {
    this.changeOfEnrolment = coe;
    this.academicYear = this.changeOfEnrolment.academicYear.code;
    let obs: Observable<QualificationEnrolment>[] = [];
    obs = coe.enrolledQualifications.map((qualEnrol) => {
      return this.qualificationService.getQualification(coe.academicYear.code, qualEnrol.code).pipe(
        map((qual) => {
          return QualificationEnrolment.createFrom(qualEnrol, qual);
        }),
      );
    });
    return forkJoin(obs);
  }

  hasChangeAction(qual: EnrolledQualification) {
    return qual.changeAction === CHANGE_ACTIONS.ADDED || qual.changeAction === CHANGE_ACTIONS.CHANGED;
  }

  changeActionLabel(qual: EnrolledQualification) {
    return qual.changeAction === CHANGE_ACTIONS.ADDED ? this.strings.added : this.strings.changed;
  }

  isQualAlreadyAdded() {
    return this.fullAwardEnrolments.some((qual) => qual.changeAction === CHANGE_ACTIONS.ADDED);
  }

  addNewQualification() {
    this.addQualSelector = true;
    this.enableQualSave = false;
    this.qualificationService
      .getQualifications(this.changeOfEnrolment.academicYear.code, this.process)
      .subscribe((res) => {
        this.qualificationData = res.filter((qual) =>
          this.fullAwardEnrolments.some((enrol) => enrol.qualificationAnswer.code !== qual.code),
        );
      });
    this.selectedQual = this.fullAwardEnrolments.map((enrol) => {
      return enrol.qualificationAnswer.code;
    });
    this.enrolments = [
      {
        applicationEnrolment: {
          displayOrder: 1,
          enrolledQualifications: [],
        } as ChangeOfEnrolment,
        firstQualificationEnrolment: null,
        secondQualificationEnrolment: null,
      },
    ];
  }

  changeSubject(index: number) {
    this.editMode = true;
    this.enableSave = false;
    this.showQualEnrolmentSelector.fill(false);
    this.showQualEnrolmentSelector[index] = true;
    this.fullAwardEnrolmentCopy = cloneDeep(this.fullAwardEnrolments);
  }

  undoChange(index: number) {
    this.undoLoading = true;
    this.changeEnrolmentService
      .undoCOEQualification(this.fullAwardEnrolments[index].internalReference)
      .pipe(
        switchMap((coe: ChangeOfEnrolment) => {
          return this.buildQualEnrolment(coe);
        }),
      )
      .subscribe((qualEnrolments: QualificationEnrolment[]) => {
        this.processService.evaluateProcess(PROCESS_NAMES.COE, this.academicYear).subscribe(() => {
          this.fullAwardEnrolments = qualEnrolments;
          this.showQualEnrolmentSelector = new Array<boolean>(this.fullAwardEnrolments.length);
          this.undoLoading = false;
        });
      });
  }

  onSaveFirst(event: QualificationEnrolment, i: number) {
    this.enableSave = true;
    this.fullAwardEnrolmentCopy[i].priority = 1;
    this.fullAwardEnrolmentCopy[i].qualificationAnswer = event.qualificationAnswer;
    this.fullAwardEnrolmentCopy[i].subjectAnswer = event.subjectAnswer;
  }

  cancelChanges() {
    this.editMode = false;
  }

  cancelQualChanges() {
    this.addQualSelector = false;
    this.qualSelectorForm.reset();
  }

  saveChanges(index: number) {
    this.isSubjectOptionLoading = true;
    this.changeOfEnrolment.enrolledQualifications = [
      {
        code: this.fullAwardEnrolmentCopy[index].qualificationAnswer.code,
        priority: this.fullAwardEnrolmentCopy[index].priority,
        subjectOptions: this.filterEmptySubjectOptions(this.fullAwardEnrolmentCopy[index].subjectAnswer),
      },
    ];

    this.changeEnrolmentService
      .updateChangeOfEnrolmentQualification(
        this.fullAwardEnrolmentCopy[index].internalReference,
        this.changeOfEnrolment,
      )
      .pipe(
        switchMap((coe: ChangeOfEnrolment) => {
          return this.buildQualEnrolment(coe);
        }),
      )
      .subscribe((qualEnrolments: QualificationEnrolment[]) => {
        this.fullAwardEnrolments = qualEnrolments;
        this.showQualEnrolmentSelector = new Array<boolean>(this.fullAwardEnrolments.length);
        this.isSubjectOptionLoading = false;
        this.editMode = false;
      });
  }

  onNewQualificationAnswer(data: EnrolmentUpdateEvent[]) {
    if (data && data.length) {
      const qualifications = get(data[0], 'applicationEnrolment.enrolledQualifications', []);
      this.enableQualSave = data[0].isConcurrent && qualifications.length === 1 ? false : true;
      if (!data[0].isConcurrent && qualifications.length > 1) {
        set(data[0], 'applicationEnrolment.enrolledQualifications', qualifications.slice(0, 1));
      }
      this.newCOEQual = data[0].applicationEnrolment as ChangeOfEnrolment;
    }
  }

  saveQualChanges() {
    this.isQualLoading = true;
    this.changeEnrolmentService
      .addCOEQualification(this.newCOEQual)
      .pipe(
        switchMap((coe: ChangeOfEnrolment) => {
          return this.buildQualEnrolment(coe);
        }),
      )
      .subscribe((qualEnrolments: QualificationEnrolment[]) => {
        this.processService.evaluateProcess(PROCESS_NAMES.COE, this.academicYear).subscribe(() => {
          this.fullAwardEnrolments = qualEnrolments;
          this.addQualSelector = false;
          this.isQualLoading = false;
        });
      });
  }

  public updateFormValidity(): void {
    // no-op
  }

  qualSelectorFormEmitter(qualForm: UntypedFormGroup) {
    this.qualSelectorForm = qualForm;
  }

  filterEmptySubjectOptions(subjectAnswer: SubjectOptionsList) {
    Object.keys(subjectAnswer).forEach((key) => {
      subjectAnswer[key] = subjectAnswer[key].filter((option) => option.code !== 'null' && option.code !== null);
    });
    return subjectAnswer;
  }
}
