/**
 * Checks if the value is a function
 *
 *
 * @param value
 * @returns boolean
 */
export const isUrlFunction = (value: unknown): value is Function => {
  return typeof value === 'function';
};
