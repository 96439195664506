import { Injectable } from '@angular/core';

import { internalUrls } from '@shared/constants/internalUrls';

@Injectable()
export class OnlineDefaultLandingUrlService {
  // eslint-disable-next-line class-methods-use-this
  get landingUrl() {
    return internalUrls.onlinePreProcessPage('');
  }
}
