import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AtomsModule } from '@shared/components/atoms/atoms.module';

import { IndependentCourseEnrolmentSummaryCardComponent } from './independent-course-enrolment-summary-card/independent-course-enrolment-summary-card.component';
import { OnlineFooterComponent } from './online-footer/online-footer.component';
import { OnlineFooterMinimalComponent } from './online-footer-minimal/online-footer-minimal.component';
import { OnlineHeaderNavDropdownComponent } from './online-header-nav-dropdown/online-header-nav-dropdown.component';
import { OnlineMinimalHeaderComponent } from './online-minimal-header/online-minimal-header.component';
import { OnlineTaskComponent } from './online-task/online-task.component';
import { OnlineTermsAndConditionsComponent } from './online-terms-and-conditions/online-terms-and-conditions.component';
import { StudentHubCTAComponent } from './studenthub-cta/studenthub-cta.component';
import { OnlineAtomsModule } from '../atoms/online-atoms.module';

const molecules = [
  OnlineHeaderNavDropdownComponent,
  OnlineFooterMinimalComponent,
  OnlineTermsAndConditionsComponent,
  OnlineTaskComponent,
  OnlineFooterComponent,
  OnlineMinimalHeaderComponent,
  IndependentCourseEnrolmentSummaryCardComponent,
  StudentHubCTAComponent,
];

@NgModule({
  declarations: molecules,
  imports: [
    AtomsModule,
    OnlineAtomsModule,
    CommonModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: molecules,
})
export class OnlineMoleculesModule {}
