import { camelizeKeys } from '@shared/helpers/serialization';

export const PAYMENT_SERVICE_PATH = '/process/payment';

export class CourseInfo {
  courseCode: string;
  occurrence: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): CourseInfo {
    const payload = obj;
    return new CourseInfo(camelizeKeys(payload));
  }

  public static deserializeAll(obj): CourseInfo[] {
    const payload = obj.manifest || obj;
    return payload.map((e) => {
      return new CourseInfo(camelizeKeys(e));
    });
  }
}

export class Payment {
  link: string;
  manifest: CourseInfo[];

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): Payment {
    const payload = obj.payment || obj;
    const camelized = camelizeKeys(payload);
    camelized.manifest = camelized?.manifest ? CourseInfo.deserializeAll(camelized.manifest) : [];
    return new Payment(camelizeKeys(camelized));
  }
}

export class CustomerId {
  customerId: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): CustomerId {
    const payload = obj;
    return new CustomerId(camelizeKeys(payload));
  }
}
