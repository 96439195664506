import { get } from 'lodash-es';

import strings from '@constants/strings.constants';

export interface UCError {
  code: string;
  data: any;
}

export const getUIMessage = (err: UCError): string => {
  if (!err.code) {
    throw new Error(`Error passed to pipe has no code: ${JSON.stringify(err)}`);
  }
  const splitCode = err.code.split('.');
  const lookupCode = splitCode[0] === 'errors' ? splitCode[1] : err.code;
  const message = get(strings.errors, lookupCode) as string;
  if (!message) {
    throw new Error(`UI message not found for error ${JSON.stringify(err)}`);
  }
  return message;
};
