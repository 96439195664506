import { timer as observableTimer, of as observableOf, Observable } from 'rxjs';

import { map, switchMap, share, tap, mapTo, filter, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ProcessService } from '@shared/services/process/process.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { environment } from '@environment';
import { UserService } from '@shared/services/user/user.service';

@Injectable()
export class JadeHydrateService {
  private log: Logger;
  private hydratePending = false;

  public didHydrate: Observable<boolean>;
  public hydrateStarted: Observable<boolean>;

  constructor(
    private userService: UserService,
    private processService: ProcessService,
    private loggingService: LoggingService,
  ) {
    this.log = this.loggingService.createLogger(this);

    this.hydrateStarted = this.userService.currentUser.pipe(
      filter((v) => v && v.partiallyHydrated),
      tap((u) => (this.hydratePending = true)),
      mapTo(true),
      first(),
    );

    this.didHydrate = this.hydrateStarted.pipe(
      switchMap((v) => this.processService.hydrate()),
      switchMap((v) => this.userService.refreshTokenBeforeNavigate()),
      map((user) => user && !user.partiallyHydrated),
      tap(() => {
        this.hydratePending = false;
        this.log.info('hydrate was successful');
      }),
      share(),
    );
  }

  get isHydratePending(): boolean {
    return this.hydratePending;
  }
}

export interface IJadeHydrateService {
  isHydratePending: boolean;
  didHydrate: Observable<boolean>;
  hydrateStarted: Observable<boolean>;
}

export class MockJadeHydrateService implements IJadeHydrateService {
  isHydratePending = true;
  didHydrate: Observable<boolean>;
  hydrateStarted: Observable<boolean>;
  constructor(private pendingInterval = 0) {
    this.hydrateStarted = observableOf(true);

    this.didHydrate = this.hydrateStarted.pipe(
      switchMap(() => {
        this.isHydratePending = true;
        return observableTimer(this.pendingInterval);
      }),
      mapTo(true),
      tap((v) => {
        this.isHydratePending = false;
      }),
      share(),
    );
  }
}

export function jadeHydrateFactory(userService, processService, loggingService) {
  if (environment.useFakeBackend.jadeHydrate) {
    return new MockJadeHydrateService(15000);
  } else {
    return new JadeHydrateService(userService, processService, loggingService);
  }
}

export const jadeHydrateProvider = {
  provide: JadeHydrateService,
  useFactory: jadeHydrateFactory,
  deps: [UserService, ProcessService, LoggingService],
};
