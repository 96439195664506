export const PREFERRED_CONTACT_METHOD_OPTIONS = [
  {
    labelText: 'Email',
    value: 'email',
  },
  {
    labelText: 'Mobile',
    value: 'mobile',
  },
];

export const PREFERRED_CONTACT_METHOD_MAP = {
  email: 'Email',
  mobile: 'Mobile',
};
