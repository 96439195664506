import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { OnlineStagesSidebarComponent } from '@app/components/organisms/online-stages-sidebar/online-stages-sidebar.component';
import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { UcMaterialModule } from '@shared/uc-material/uc-material.module';

import { OnlineCategoryFileUploadComponent } from './online-category-file-upload/online-category-file-upload.component';
import { OnlineDashboardTilesComponent } from './online-dashboard-tiles/online-dashboard-tiles.component';
import { OnlineHeaderBarComponent } from './online-header-bar/online-header-bar.component';
import { OnlineLegalNameDeclarationComponent } from './online-legal-name-declaration/online-legal-name-declaration.component';
import { OnlineMoreAboutYourStudiesComponent } from './online-more-about-your-studies/online-more-about-your-studies.component';
import { OnlinePersonalDetailsComponent } from './online-personal-details/online-personal-details.component';
import { OnlineSecondaryEducationComponent } from './online-secondary-education/online-secondary-education.component';
import { OnlineMoleculesModule } from '../molecules/online-molecules.module';

@NgModule({
  declarations: [
    OnlineHeaderBarComponent,
    OnlineDashboardTilesComponent,
    OnlineStagesSidebarComponent,
    OnlinePersonalDetailsComponent,
    OnlineMoreAboutYourStudiesComponent,
    OnlineSecondaryEducationComponent,
    OnlineLegalNameDeclarationComponent,
    OnlineCategoryFileUploadComponent,
  ],
  imports: [
    AtomsModule,
    MoleculesModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    OrganismsModule,
    UcMaterialModule,
    OnlineMoleculesModule,
  ],
  exports: [
    OnlineHeaderBarComponent,
    OnlineDashboardTilesComponent,
    OnlineStagesSidebarComponent,
    OnlinePersonalDetailsComponent,
    OnlineMoreAboutYourStudiesComponent,
    OnlineSecondaryEducationComponent,
    OnlineLegalNameDeclarationComponent,
    OnlineCategoryFileUploadComponent,
  ],
  providers: [],
})
export class OnlineOrganismsModule {}
