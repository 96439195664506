import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-fitness-to-teach',
  templateUrl: './fitness-to-teach.component.html',
  styleUrls: ['./fitness-to-teach.component.scss'],
})
export class FitnessToTeachComponent implements OnInit {
  strings = strings.components.tasks.teachingApplication.fitnessToTeach;
  @Input() task: Task;
  @Input() fitnessToTeachForm: UntypedFormGroup;
  @Input() disabilityDetailRequired: boolean;

  constructor() {}

  ngOnInit() {}
}
