import { get } from 'lodash-es';

import { camelizeKeys } from '@shared/helpers/serialization';

export class TeachingPeriod {
  code: string;
  year: string;
  semesterIndicatorCode: string;
  description: string;
  teachingStartDate: string;
  teachingEndDate: string;

  constructor(data: any) {
    Object.assign(this, data);
  }

  static deserialize(payload: any): TeachingPeriod[] {
    if (payload == null) {
      return null;
    }
    const data = payload.teaching_period || payload;
    return data.map((tp) => {
      tp.code = get(tp, 'code.code');
      return new TeachingPeriod(camelizeKeys(tp));
    });
  }
}
