import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BaseApplicantModule } from '@shared/applicant/base-applicant.module';
import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { CoursePlannerComponent } from '@shared/components/templates/course-planner/course-planner.component';
import { SharedTemplatesModule } from '@shared/components/templates/templates.module';

import { OnlineOutageTemplateComponent } from './online-outage-template/online-outage-template.component';
import { OnlineAtomsModule } from '../atoms/online-atoms.module';
import { OnlineMoleculesModule } from '../molecules/online-molecules.module';
import { OnlineOrganismsModule } from '../organisms/online-organisms.module';

@NgModule({
  declarations: [CoursePlannerComponent, OnlineOutageTemplateComponent],
  imports: [
    CommonModule,
    RouterModule,
    AtomsModule,
    MoleculesModule,
    OrganismsModule,
    SharedTemplatesModule,
    BaseApplicantModule,
    OnlineOrganismsModule,
    OnlineAtomsModule,
    OnlineMoleculesModule,
  ],
  exports: [CoursePlannerComponent],
})
export class TemplatesModule {}
