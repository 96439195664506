<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints
      *ngIf="!loading"
      [task]="task"
    ></uc-task-hints>
  </uc-form-header>

  <uc-spinner
    *ngIf="loading"
    size="40"
    [useStaticPositioning]="true"
  ></uc-spinner>

  <div *ngIf="!loading">
    <div [formGroup]="enrolmentFormGroup">
      <uc-form-input formArrayName="qualOccurrences">
        <uc-section *ngFor="let occurrence of occurrenceControls.controls; let i = index">
          <uc-section-header title="{{ strings.selectStudyOption(qualsWithOccurrences[i].title) }}">
            <p><span [innerHTML]="strings.intakesAvailableDescription"></span></p>
          </uc-section-header>
          <uc-subsection>
            <uc-task-hint
              [task]="task"
              [path]="generateHintPath(qualsWithOccurrences[i].meta)"
            >
            </uc-task-hint>
            <uc-subsection-question required="yes">
              {{ strings.whereHowWillYouStudy }}
            </uc-subsection-question>
            <uc-reference-data-selector
              [testSelector]="'occurrence-' + i"
              [formControl]="occurrence"
              [options]="qualsWithOccurrences[i].options"
              [showBlankOption]="false"
            >
            </uc-reference-data-selector>
          </uc-subsection>
        </uc-section>
      </uc-form-input>
    </div>

    <uc-section *ngFor="let qual of noActiveOccurrences">
      <uc-section-header title="{{ strings.selectStudyOption(qual.title) }}"> </uc-section-header>
      <uc-subsection>
        <uc-subsection-question required="yes">
          {{ strings.whereHowWillYouStudy }}
        </uc-subsection-question>
        <uc-message-banner type="info">
          <span [innerHTML]="strings.noIntakesAvailable"></span>
        </uc-message-banner>
      </uc-subsection>
    </uc-section>

    <uc-section *ngIf="noOccurrences">
      <uc-section-header title="{{ strings.selectOtherStudyOption(qualOccurrenceOptions.length) }}">
      </uc-section-header>
      <uc-subsection [formGroup]="studyOptionsPage">
        <uc-subsection-question required="yes">
          {{ studyIntentionsStrings.question }}
        </uc-subsection-question>
        <uc-subsection-minor-text>
          {{ studyIntentionsStrings.studyDescription }}
        </uc-subsection-minor-text>
        <uc-task-hint
          [task]="task"
          [model]="hintPath"
          path="/studyStart"
        ></uc-task-hint>
        <uc-radio-input-group
          [additionalText]="extraDescriptions"
          type="begin_studies"
          groupName="study-intentions"
          formControlName="studyStart"
        >
        </uc-radio-input-group>
        <div *ngIf="shouldShowDateSelector">
          <uc-task-hint
            [task]="task"
            [model]="hintPath"
            path="/study_start_other"
          ></uc-task-hint>
          <uc-subsection-question required="yes">
            {{ studyIntentionsStrings.dateQuestion }}
          </uc-subsection-question>
          <uc-form-input>
            <uc-date-selector
              formControlName="studyStartOther"
              order="ascending"
              range="4"
              testSelector="study-start-other"
            >
            </uc-date-selector>
          </uc-form-input>
        </div>
      </uc-subsection>
    </uc-section>
  </div>
</uc-form-template>
