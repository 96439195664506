import { Component, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';
import { PlacesService } from '@shared/services/places.service';

@Component({
  selector: 'uc-address-prediction',
  templateUrl: './address-prediction.component.html',
  styleUrls: ['./address-prediction.component.scss'],
})
export class AddressPredictionComponent implements OnInit {
  @Input() task: Task;
  @Input() basePath = '/applicant/contact_detail/current_address';
  @Input() testSelector = '';
  @Input() addressModel: UntypedFormGroup;
  @Input() required = true;
  @Input() displayLabel = true;
  // Determines whether address selector emits on clicking typehead selection
  // and is used where prediction component used within custom form control
  // so value can be propagated to parent context
  @Input() emitOnPredictionSelection = true;

  predictions: google.maps.places.AutocompletePrediction[] = [];
  predictionIndex = -1;
  strings = strings.components.molecules.addressSelector;
  selectedPrediction = false;
  label = null;
  patchingForm = false;

  constructor(private placesService: PlacesService, private zone: NgZone) {}

  get testSelectorId() {
    return `${this.testSelector}-address`;
  }

  getHintPath(prop) {
    return `${this.basePath}/${prop}`;
  }

  @HostListener('window:click') windowClicked() {
    this.predictions = [];
  }

  ngOnInit() {
    this.addressModel
      .get('line1')
      .valueChanges.pipe(filter((val) => val.length < 3))
      .subscribe(() => {
        this.predictions = [];
      });

    this.addressModel.valueChanges
      .pipe(
        switchMap((v) => {
          return of(v);
        }),
        filter(() => {
          return !this.patchingForm;
        }),
      )
      .subscribe(() => {
        if (!!this.addressModel.get('latitude').value && !!this.addressModel.get('longitude').value) {
          this.addressModel.patchValue({ latitude: null, longitude: null }, { emitEvent: false });
          this.addressModel.get('latitude').markAsDirty();
          this.addressModel.get('longitude').markAsDirty();
        }
      });

    this.getPredictions(this.addressModel.get('line1').valueChanges).subscribe(
      (predictions) => {
        if (this.selectedPrediction) {
          this.selectedPrediction = false;
          return;
        }
        this.zone.run(() => {
          this.predictions = predictions;
        });
      },
      (err) => {
        console.error('error happened getting query predictions', err);
      },
    );
    this.label = this.displayLabel ? strings.addressLine1 : null;
  }

  toggleFocus(event, isStringInput = false) {
    // Mobile browsers will not have an event.key.match method
    if (!(this.predictions.length && get(event, 'key.match'))) {
      return;
    }
    this.predictionIndex = isStringInput ? -1 : this.predictionIndex;

    // arrow down
    if (event.key.match(/^(Arrow)?Down$/)) {
      event.preventDefault();
      if (this.predictionIndex !== 4) {
        this.predictionIndex += 1;
        const item = document.getElementsByClassName('address-item')[this.predictionIndex] as HTMLElement;
        item.focus();
      }
    }

    // arrow up
    if (event.key.match(/^(Arrow)?Up$/) && this.predictionIndex > -1) {
      event.preventDefault();
      if (this.predictionIndex === 0) {
        const item = document.querySelector('#line1 input') as HTMLElement;
        item.focus();
      } else {
        this.predictionIndex -= 1;
        const item = document.getElementsByClassName('address-item')[this.predictionIndex] as HTMLElement;
        item.focus();
      }
    }
  }

  stripSpecialChars(address, updateInput?: boolean) {
    const shouldStripSpecialChars = (val) => val && typeof val === 'string' && val.match(/[^ \f\r\n-~]/gi);

    Object.keys(address)
      .filter((line) => shouldStripSpecialChars(address[line]))
      .forEach((line) => {
        const replaceMacron = address[line].normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        address[line] = replaceMacron.replace(/[^ \f\r\n-~]/gi, '');
        if (updateInput) {
          this.addressModel.get(line).setValue(address[line], { emitEvent: false });
        }
      });

    return address;
  }

  /**
   * get autocomplete predictions from an observable of query changes
   *
   * @param changes Observable
   */
  private getPredictions(changes: Observable<string>): Observable<google.maps.places.AutocompletePrediction[]> {
    return changes.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      // only lookup a value that is longer than 3
      filter((value) => value.length > 3),
      switchMap((val: string) => {
        return this.placesService.getPlacePredictions(val);
      }),
    );
  }

  selectPrediction(prediction) {
    this.placesService.getDetails(prediction.place_id).subscribe(
      (result) => {
        this.zone.run(() => {
          this.patchingForm = true;
          this.selectedPrediction = true;
          this.addressModel.patchValue(this.stripSpecialChars(result), { emitEvent: this.emitOnPredictionSelection });
          Object.keys(result).forEach((line) => {
            if (this.addressModel.get(line)) {
              this.addressModel.get(line).markAsDirty();
            }
          });
          this.addressModel.markAsDirty();
          this.patchingForm = false;
          this.predictions = [];
        });
      },
      (err) => {
        console.error('error getting a place', err);
      },
    );
  }
}
