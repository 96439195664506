<div *ngIf="!hideText" class="progress-percentage">{{progressPercentage}}%</div>
<div class="progress-bar-wrap" [class.over-maximum]="isOverMaximum" [class.show-excess]="excessLevel">
  <div
    class="progress-bar-complete"
    [style.width]="barWidth"
    [style.max-width]="barMaxWidth">
  </div>
  <div
    *ngIf="excessLevel && isOverMaximum"
    class="progress-bar-excess"
    [style.width]="excessWidth">
  </div>
</div>
