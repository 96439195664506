import { Component, Input, OnInit } from '@angular/core';
import { get } from 'lodash-es';
import { filter } from 'rxjs/internal/operators/filter';

import { environment } from '@environment';
import { UCProcess } from '@shared/models/process';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-stages-sidebar',
  templateUrl: './stages-sidebar.component.html',
  styleUrls: ['./stages-sidebar.component.scss'],
})
export class StagesSidebarComponent implements OnInit {
  @Input() isImpersonating = false;
  @Input() firstIncompleteStageNumber = 0;
  @Input() currentProcess;

  showSidebar = false;
  allowStageNavigation = get(environment, 'features.unlockStages');
  selectedStageNumber: number;
  process: UCProcess;

  constructor(private processService: ProcessService) {}

  toggleNav() {
    this.showSidebar = !this.showSidebar;
  }

  preventNavigation(event: MouseEvent) {
    if (this.isImpersonating && this.selectedStageNumber <= this.firstIncompleteStageNumber) {
      return;
    }
    if (!this.allowStageNavigation) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onTaskCardClick() {
    if (window.outerWidth < 960) {
      this.toggleNav();
    }
  }

  setStageNumber(num) {
    this.selectedStageNumber = num;
  }

  ngOnInit() {
    if (this.currentProcess) {
      this.process = this.currentProcess;
    } else {
      this.processService.currentProcess.pipe(filter((el) => !!el)).subscribe((process) => {
        this.process = process;
      });
    }
  }
}
