import { Component, OnInit, Input } from '@angular/core';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { AutoResolveTask } from '@shared/classes/auto-resolve/auto-resolve';
import { Task } from '@shared/models/task';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { ProcessService } from '@shared/services/process/process.service';
import { UserService } from '@shared/services/user/user.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-resolve-account',
  templateUrl: './resolve-account.component.html',
  styleUrls: ['./resolve-account.component.scss'],
})
export class ResolveAccountComponent extends AutoResolveTask implements OnInit {
  @Input() task: Task;
  @Input() process: string;
  @Input() applicationYear: string;

  log: Logger;
  baseStrings = strings.components.tasks.resolveAccount;
  strings = this.baseStrings.awaitingId;

  constructor(
    logService: LoggingService,
    processService: ProcessService,
    enrolmentService: EnrolmentService,
    paymentService: PaymentService,
    windowService: WindowService,
    private userService: UserService,
  ) {
    super(processService, enrolmentService, paymentService, windowService, logService);
    this.log = logService.createLogger(this);
  }

  ngOnInit() {
    this.setStrings();
    this.pollProcess(this.task.code, this.process, this.applicationYear).subscribe();
  }

  setStrings() {
    if (this.task.code === 'resolve-application-deferred') {
      this.strings = this.baseStrings.awaitingApplication;
    } else {
      const hasStudentId = !!get(this.userService, 'user.studentId');
      this.strings = hasStudentId ? this.baseStrings.updatingDetails : this.baseStrings.awaitingId;
    }
  }
}
