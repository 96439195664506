/* eslint-disable @typescript-eslint/naming-convention */
// export const OURUC_CONSTANTS = {
export const OURUC_OS_UPDATE_TYPE = {
  CREATE_UCUSER_HISTORY_ITEM: 'createUCUserHistoryItem',
  CREATE_REVIEW_REQUIRED_SUBTASK: 'createReviewRequiredSubtask',
  EDIT_REVIEW_REQUIRED_SUBTASK: 'editReviewRequiredSubtask',
  TASK_ACTION: 'taskAction',
  SAVE_CUP_INTERVIEW_COMMENTS: 'saveCUPInterviewComments',
  PDF_PREVIEW: 'pdfPreview',
  SEND_REFEREE_EMAIL: 'sendRefereeEmail',
  LAUNCH_PROCESS: 'launchProcess',
};

export const OURUC_PROCESS_TYPE = {
  CONVICTIONS_REVIEW: 'convictions_review',
};

export const OURUC_OS_RETRIEVE_TYPE = {
  TASK: 'task',
  QUAL_ENTRY_ASSESS_TYPE: 'qualEntryAssessType',
  STATIC_ENTITY: 'staticEntity',
  USER_HISTORY: 'userHistory',
  PDF_CONFIG: 'pdfConfig',
  FETCH_UC_USERS: 'fetchUCUsers',
};

export const OURUC_OS_TASK_ACTION = {
  ASSIGN: 'assign',
  PLACE_ON_HOLD: 'placeOnHold',
  REMOVE_ON_HOLD: 'removeOnHold',
  DECISION: 'decision',
  LAUNCH_PROCESS: 'launchProcess',
  TASK_ACTION: 'taskAction',
  START_REVIEW: 'startReview',
};

export const OURUC_OS_TASK_DECISION = {
  CONDITIONAL: 'conditionally_approved',
  APPROVED: 'approved',
  DECLINED: 'declined',
  READY_FOR_INTERVIEW: 'ready_for_interview',
  INTERVIEW_COMPLETE: 'interview_complete',
  INTERVIEW_NOT_REQUIRED: 'interview_not_required',
  INVITE_TO_INTERVIEW: 'invite_to_interview',
  INTERVIEW: 'interview',
  ACTIONS: 'actions',
  COMPLETE: 'complete',
  REASSESS_CONDITIONS: 'reassessConditions',
  GENERATE_OFFER_OF_PLACE: 'generateOfferOfPlace',
  OFFER_OF_PLACE_NOT_REQUIRED: 'offerOfPlaceNotRequired',
  REQUEST_QUALIFICATION_EQUIVALENCE_CHECK: 'requestQualificationEquivalenceCheck',
  QUALIFICATION_EQUIVALENCE_NOT_REQUIRED: 'assessment_not_required',
  SWITCH_TO_RETURNING_OFFER_OF_PLACE: 'switchToOfferOfPlaceReturning',
  SWITCH_TO_NEW_OFFER_OF_PLACE: 'switchToOfferOfPlaceNew',
};

export const OURUC_TASK_STATUS = {
  ON_HOLD: 'On hold',
  COMPLETE: 'Complete',
  ABANDONED: 'Abandoned',
  FOLLOW_UP: 'Follow up',
  IN_PROGRESS: 'In progress',
  UNASSIGNED: 'Unassigned',
  CANCELLED: 'Cancelled',
  REVIEW_REQUIRED: 'Review required',
  UNDER_REVIEW: 'Under review',
};

export const OURUC_ACTIVE_TASK_STATES = [
  OURUC_TASK_STATUS.IN_PROGRESS,
  OURUC_TASK_STATUS.FOLLOW_UP,
  OURUC_TASK_STATUS.ON_HOLD,
  OURUC_TASK_STATUS.REVIEW_REQUIRED,
  OURUC_TASK_STATUS.UNASSIGNED,
];

export const OURUC_INACTIVE_TASK_STATES = [
  OURUC_TASK_STATUS.ABANDONED,
  OURUC_TASK_STATUS.COMPLETE,
  OURUC_TASK_STATUS.CANCELLED,
];

export const OURUC_TASK_ON_HOLD_REASON = {
  UNDER_REVIEW: 'Under review',
};

export const PDF_TYPE = {
  CONDITIONAL_OFFER: 'conditionalOfferOfPlaceCOE',
  DECLINE_COE: 'declinedCOE',
  PREVIEW_OFFER: 'previewOffer',
};

export const COE_ACTION = {
  REASSESS_CONDITIONS: 'reassess_conditions',
  REGENERATE_OFFER_OF_PLACE: 'regenerate_offer_of_place',
  FORCE_OFFER_OF_PLACE: 'force_offer_of_place',
};

export const CITIZEN_SHIP_CATEGORY = {
  INTERNATIONAL: 'International',
  DOMESTIC: 'Domestic',
};

export const OURUC_OS_TASK_DECISION_ACTION = {
  APPROVE: 'approve',
  CONDITIONALLY_APPROVE: 'conditionally approve',
  DECLINE: 'decline',
  COMPLETE: 'complete',
  REQUEST_EQUIVALENCE_CHECK: 'request equivalence check',
  NOT_REQUIRED: 'not required',
};
