import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { BaseProcessPage } from '@shared/components/templates/process-page/process-page.component';
import { UCError } from '@shared/models/errors';
import { UCProcess } from '@shared/models/process';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { LoggingService } from '@shared/services/logging/logging.service';
import {
  IPreProcessRouteData,
  IPreProcessRouteParams,
} from '@shared/services/resolvers/pre-process-resolver/pre-process-resolver';

@Component({
  selector: 'uc-pre-process-page',
  templateUrl: '../process-page/process-page.component.html',
  styleUrls: ['../process-page/process-page.component.scss'],
})
export class PreProcessPageComponent extends BaseProcessPage implements OnInit, AfterViewInit {
  public process: UCProcess;
  public task: Task;
  public stage: Stage;
  public availableApplicationYears: string[];
  public strings = strings.components.template.processPage;
  public errorMessage = '';
  public stageComplete = false;
  public isFirstTask = false;
  public taskIsUpdating = false;
  public actionLabel: string;
  public applicationYear: string;
  public newProcessName: string;
  disableNavigation = false;
  isImpersonating = false;
  firstIncompleteStageNumber = -1;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private loggingService: LoggingService,
  ) {
    super();
    this.log = this.loggingService.createLogger(this);
  }

  ngAfterViewInit() {
    if (!this.errorMessage) {
      this.hideErrorBanner();
    }
  }

  ngOnInit() {
    this.actionLabel = 'Save and Continue';

    combineLatest([this.route.data, this.route.params])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([data, params]: [IPreProcessRouteData, IPreProcessRouteParams]) => {
        const { process, availableApplicationYears } = data.preProcessContext;
        this.process = process;
        this.availableApplicationYears = availableApplicationYears;
        this.stage = this.process.stages[0];
        this.task = this.stage.tasks[0];
        this.newProcessName = params.process;

        const taskIndex = this.getTaskIndex(this.stage, this.task.code);
        this.isFirstTask = taskIndex === this.firstVisibleTaskIndex();
      });
  }

  showTaskError(error?: UCError) {
    this.taskIsUpdating = false;
    if (error) {
      this.errorMessage = error.data;
      if (error.code) {
        this.errorMessage = get(strings, error.code) as string;
      }
      this.showErrorBanner();
    }
  }

  previousTask() {
    this.router.navigate(internalUrls.dashboard);
  }

  cancelTask() {
    this.router.navigate(internalUrls.dashboard);
  }

  triggerTaskUpdate() {
    this.taskIsUpdating = true;
    this.hideErrorBanner();
    if (this.currentTask && this.currentTask.update) {
      this.currentTask.update();
    } else {
      this.log.warn('could not update task because no reference to current task exists');
    }
  }

  goToNextTask() {
    // no-op
  }

  jumpToContent(jumpToElement: string): void {
    return super.jumpToContent(jumpToElement);
  }
}
