<uc-form-template
  [formGroup]="onlineStudyLocationPage"
  class="uconline-profile"
>
  <uc-form-header title="Your details">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section [formGroup]="onlineStudyLocationPage.get('citizenship')">
    <uc-section-header [title]="strings.citizenshipSection.header"></uc-section-header>

    <uc-subsection>
      <uc-task-hint
        [task]="task"
        path="/application/study_in_nz"
      ></uc-task-hint>
      <uc-subsection-question
        >{{ strings.citizenshipSection.liveInNzQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-minor-text>
        {{ strings.citizenshipSection.liveInNzDescription }}
      </uc-subsection-minor-text>

      <uc-form-input>
        <uc-radio-input-group
          formControlName="studyInNz"
          [options]="radioOptions"
          label=""
          [displayHorizontally]="true"
          groupName="study-nz"
        ></uc-radio-input-group>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section>
    <uc-section-header [title]="termsAndConditionsStrings.header"></uc-section-header>
    <uc-online-terms-and-conditions
      [task]="task"
      [termsAndConditionsForm]="onlineStudyLocationPage.get('termsAndConditionsGroup')"
      [showOptionalItem]="false"
    >
    </uc-online-terms-and-conditions>
  </uc-section>
</uc-form-template>
