<div
  class="sidenav-trigger"
  *ngIf="hasSideNav"
>
  <uc-icon
    (click)="hamburgerClick()"
    iconType="hamburger"
    iconWidth="24"
    iconHeight="24"
    iconFill="$colorRollingStone"
  ></uc-icon>
</div>
<div class="header-bar-left">
  <a
    href="javascript:void(0)"
    (click)="goBackHandler()"
    class="back-link"
    *ngIf="showBackLink"
  >
    <uc-icon
      iconType="back-arrow"
      iconWidth="22"
      iconHeight="22"
    ></uc-icon>
    <span class="back-link-text">{{ goBackLabel }}</span>
  </a>
</div>
<div class="toolbar">
  <div
    class="toolbar-item"
    *ngIf="showFeedbackIcon"
  >
    <a
      class="header-widget"
      href="{{ feedbackUrl }}"
      target="_blank"
    >
      <span class="icon-label hidden-xs">{{ strings.headerBar.feedback }}</span>
      <div class="header-widget__icon--wrapper">
        <uc-icon
          iconType="feedback"
          iconWidth="34"
          iconHeight="34"
          iconFill="$colorWhite"
          lineStyle="stroke:#727779; stroke-width:40; stroke-linecap:round"
        ></uc-icon>
      </div>
    </a>
  </div>
  <div
    *ngIf="showDocLibrary"
    class="toolbar-item"
  >
    <a
      class="header-widget"
      [routerLink]="internalUrls.documents.join('/')"
    >
      <span class="icon-label document-library-label hidden-xs">{{ strings.documentLibrary.name }}</span>
      <div class="header-widget__icon--wrapper">
        <span
          class="document-library__counter"
          *ngIf="importantDocumentCount > 0"
          >{{ importantDocumentCount }}</span
        >
        <uc-icon
          iconType="doc-alt"
          iconWidth="44"
          iconHeight="44"
          iconFill="$colorRollingStone"
        ></uc-icon>
      </div>
    </a>
  </div>
  <div
    *ngIf="impersonated"
    class="toolbar-item impersonated"
  >
    <span class="impersonated__title">{{ strings.headerBar.impersonating }}</span>
    <a
      class="impersonated__title_stop"
      href="javascript:void(0)"
      (click)="unimpersonate()"
      >{{ strings.headerBar.stop }}</a
    >
  </div>
  <uc-header-nav-dropdown></uc-header-nav-dropdown>
</div>
