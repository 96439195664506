<uc-form-template>
  <uc-section>
    <uc-section-header [title]="strings.sectionHeader"> </uc-section-header>
    <uc-subsection>
      <span [innerHTML]="strings.content"></span>
      <uc-spinner
        size="40"
        [useStaticPositioning]="true"
      ></uc-spinner>
    </uc-subsection>
  </uc-section>
</uc-form-template>
