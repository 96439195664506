<uc-base-modal
  [closable]="true"
  [dismissable]="false"
  [loading]="loading"
>
  <span
    title
    *ngIf="title"
    [innerHTML]="title"
  ></span>
  <p
    body
    [innerHTML]="message"
  ></p>
  <button
    footer
    [class]="acceptType"
    [innerHTML]="acceptText"
    (click)="accept()"
  ></button>
  <button
    footer
    *ngIf="declineText"
    [class]="declineType"
    [innerHTML]="declineText"
    (click)="decline()"
  ></button>
</uc-base-modal>
