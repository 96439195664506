/* eslint-disable complexity */
import { cloneDeep, snakeCase } from 'lodash-es';

import { environment } from '@environment';

type Not<T, R> = R extends T ? never : R;

type CamelToSnakeCaseRecursive<P extends string, S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> | number
      ? P extends Capitalize<P> & Not<P, number>
        ? ''
        : '_'
      : ''}${Lowercase<T>}${CamelToSnakeCaseRecursive<T, U>}`
  : S;

type CamelToSnakeCase<S extends string> = S extends `${infer P}${infer R}`
  ? `${P}${CamelToSnakeCaseRecursive<P, R>}`
  : S;

export type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K] extends Record<string, unknown> ? KeysToSnakeCase<T[K]> : T[K];
};

type RecordListOrObjectWithRecordList = Record<string, unknown>[] | Record<string, Record<string, unknown>[]>;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type SerializerMode = 'PUT' | 'PATCH';

export class Model<T> {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  static createFrom: (data: Record<string, unknown>) => Model<any>;
  static deserialize: (payload: Record<string, unknown>) => Model<any>;
  static deserializeAll?: (payload: RecordListOrObjectWithRecordList) => Model<any>[];
  static serialize: (obj: Model<any>) => Record<string, unknown>;
  static serializeAll?: (objs: Model<any>[]) => RecordListOrObjectWithRecordList;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  get _(): T {
    return this as unknown as T;
  }
}

abstract class SerializerDeserializer {
  abstract modelProcessSingleObjectMethodName: string;
  abstract modelProcessMultipleObjectsMethodName: string;
  abstract get ensureDefaultValue(): boolean;
  abstract sourceKey(schemaKey: string): string;
  abstract destinationKey(schemaKey: string): string;

  abstract process(data, schema, model?);

  protected processObject<S>(data: Model<unknown> | Record<string, unknown>, schema: S): Record<string, unknown> {
    return Object.entries(schema).reduce((result, [key, type]) => {
      const sourceKey = this.sourceKey(key);
      const destinationKey = this.destinationKey(key);
      const value = data?.[sourceKey];
      if (value !== undefined || this.ensureDefaultValue) {
        result[destinationKey] = this.processValue(value, type);
      }
      return result;
    }, {});
  }

  protected processValue<T extends (new () => T) | [unknown] | unknown>(data: unknown, schema: T): T {
    if (schema instanceof Array) {
      return this.processArray(data as Array<unknown>, schema as [T]) as unknown as T;
    } else if (schema[this.modelProcessSingleObjectMethodName] instanceof Function) {
      return this.processModel(data as Record<string, unknown>, schema as unknown as typeof Model) as unknown as T;
    } else if (schema === Map) {
      return this.processMap(data as Record<string, unknown>, schema as typeof Map<string, unknown>) as T;
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } else if ([String, Number, Boolean].includes(schema as any)) {
      return this.processScalar(data as string | number | boolean, schema) as T;
    } else if ((schema as new (value) => T).prototype?.constructor instanceof Function) {
      return this.processClass(data, schema);
    } else if (schema instanceof Object) {
      return this.processObject(data as Record<string, unknown>, schema) as T;
    } else {
      return data as T;
    }
  }

  protected processModel<T extends typeof Model>(data: Record<string, unknown>, model: T): T {
    if (!data) {
      return null;
    }

    return model[this.modelProcessSingleObjectMethodName](data) as T;
  }

  protected processArray<T>(data: Array<unknown>, schema: [T]): T[] {
    if (!data) {
      return [];
    }

    const type = schema[0];
    const typeAsModel = type as unknown as typeof Model;
    if (typeAsModel.prototype instanceof Model) {
      if (typeAsModel[this.modelProcessMultipleObjectsMethodName]) {
        return typeAsModel[this.modelProcessMultipleObjectsMethodName](data as Record<string, unknown>[]) as T[];
      } else {
        return data.map(
          (item: Record<string, unknown>) => typeAsModel[this.modelProcessSingleObjectMethodName](item) as T,
        );
      }
    } else {
      return data.map((item) => this.processValue(item, type) as unknown as T);
    }
  }

  protected processMap<T extends new (entries: [string, unknown][]) => T>(
    data: Record<string, unknown>,
    map: typeof Map<string, unknown>,
  ): InstanceType<T> {
    if (!data) {
      return null;
    }

    const processedData = Object.keys(data).reduce((result, key) => {
      const sourceKey = this.sourceKey(key);
      const destinationKey = this.destinationKey(key);
      result[destinationKey] = data[sourceKey];
      return result;
    }, cloneDeep(data));
    return new map(Object.entries(processedData)) as InstanceType<T>;
  }

  /* eslint-disable-next-line class-methods-use-this */
  protected processScalar<S>(data: string | number | boolean, schema: S): S {
    if ([null, undefined].includes(data)) {
      return null;
    }

    return (schema as (value) => typeof schema)(data);
  }

  /* eslint-disable-next-line class-methods-use-this */
  protected processClass<C>(data: unknown, schema: C): C {
    if ([null, undefined].includes(data)) {
      return null;
    }

    return new (schema as new (value) => typeof schema)(data);
  }
}

export class Initializer extends SerializerDeserializer {
  modelProcessSingleObjectMethodName = 'create';
  modelProcessMultipleObjectsMethodName = null;

  get ensureDefaultValue() {
    return true;
  }

  /* eslint-disable-next-line class-methods-use-this */
  sourceKey(schemaKey: string): string {
    return schemaKey;
  }

  /* eslint-disable-next-line class-methods-use-this */
  destinationKey(schemaKey: string): string {
    return schemaKey;
  }

  process<S, T extends typeof Model<unknown>>(data: Record<string, unknown>, schema: S, model: T): InstanceType<T> {
    const processed = this.processObject(data, schema);
    const result = Object.assign(new model(), processed) as InstanceType<T>;
    return result;
  }
}

export class Deserializer extends SerializerDeserializer {
  modelProcessSingleObjectMethodName = 'deserialize';
  modelProcessMultipleObjectsMethodName = 'deserializeAll';

  get ensureDefaultValue() {
    return true;
  }

  /* eslint-disable-next-line class-methods-use-this */
  sourceKey(schemaKey: string): string {
    return snakeCase(schemaKey);
  }

  /* eslint-disable-next-line class-methods-use-this */
  destinationKey(schemaKey: string): string {
    return schemaKey;
  }

  process<S, T extends typeof Model<unknown>>(data: Record<string, unknown>, schema: S, model: T): InstanceType<T> {
    const processed = this.processObject(data, schema);
    const result = Object.assign(new model(), processed) as InstanceType<T>;
    return result;
  }
}

export class Serializer extends SerializerDeserializer {
  modelProcessSingleObjectMethodName = 'serialize';
  modelProcessMultipleObjectsMethodName = 'serializeAll';

  get ensureDefaultValue() {
    return environment.sharedModelSerializerMode !== 'PATCH';
  }

  /* eslint-disable-next-line class-methods-use-this */
  sourceKey(schemaKey: string): string {
    return schemaKey;
  }

  /* eslint-disable-next-line class-methods-use-this */
  destinationKey(schemaKey: string): string {
    return snakeCase(schemaKey);
  }

  process<S, T>(obj: Model<T>, schema: S): Record<string, unknown> {
    const result = this.processObject(obj, schema) as Record<string, unknown>;
    return result;
  }
}

export type SchemaToModel<T> = {
  [K in keyof T]: PrimitiveOrTypeOrArrayOfTypes<T[K]>;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PrimitiveOrTypeOrArrayOfTypes<T> = T extends (...args: any) => infer V
  ? V
  : /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    T extends new (...args: any) => infer V
    ? V
    : T extends Array<infer V>
      ? PrimitiveOrTypeOrArrayOfTypes<V>[]
      : T extends Record<string, unknown>
        ? SchemaToModel<T>
        : T;
