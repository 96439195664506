<div [formGroup]="genderForm">
  <uc-task-hint [task]="task" path="/applicant/gender"></uc-task-hint>
  <uc-radio-input-group
    groupName="gender"
    class="gender"
    formControlName="gender"
    [options]="gender"
    [displayHorizontally]="true"
    label="{{ strings.genderLabel }}"
    required="yes"
  >
  </uc-radio-input-group>
</div>
