<div [formGroup]="secondaryEducationForm">
  <uc-section>
    <uc-section-header title="Secondary or high school education">
      <p>{{ strings.description[0] }}</p>
      <p>{{ strings.description[1] }}</p>
    </uc-section-header>
    <uc-form-input formArrayName="educations">
      <div [ngClass]="{ subsection: !isMicroCredentialProcess }">
        <div
          *ngFor="let education of educations.controls; let last = last; let first = first; let i = index"
          [formGroupName]="i"
        >
          <div>
            <div
              class="item-label"
              *ngIf="!isMicroCredentialProcess"
            >
              <div
                class="item-tab"
                [innerHtml]="strings.qualLabel[i]"
              ></div>
            </div>
            <div [ngClass]="{ 'qualification-wrapper': !isMicroCredentialProcess }">
              <button
                [attr.data-cy]="'remove-sec-qual-' + i"
                class="delete-btn"
                *ngIf="i > 0"
                (click)="removeQualification(i)"
              >
                <uc-icon
                  iconType="trash"
                  iconWidth="16"
                  iconHeight="16"
                  iconFill="$colorFireEngineRed"
                ></uc-icon>
              </button>

              <uc-subsection>
                <uc-task-hint
                  [task]="task"
                  path="/applicant/secondary_qualification/{{ i }}/country/code"
                ></uc-task-hint>
                <uc-radio-input-group
                  [formControl]="secondaryLocationForm.at(i)"
                  [options]="countryOptions"
                  [label]="strings.highSchoolInNewZealand(i)"
                  [displayHorizontally]="true"
                  required="true"
                  [groupName]="'school-nz-' + i"
                ></uc-radio-input-group>
              </uc-subsection>

              <uc-subsection *ngIf="isDomestic(i) && first">
                <uc-task-hint
                  [task]="task"
                  path="/applicant/identity/nsn_number"
                ></uc-task-hint>
                <uc-subsection-question>
                  {{ strings.nsnLabel }}
                </uc-subsection-question>
                <uc-subsection-minor-text>{{ strings.nsnDescription }}</uc-subsection-minor-text>
                <uc-string-input
                  [testSelector]="'secondary-' + i + '-nsn'"
                  [formControl]="secondaryEducationForm.get('nsnNumber')"
                  [isNumeric]="true"
                  label=""
                  min="0"
                ></uc-string-input>
              </uc-subsection>

              <uc-subsection *ngIf="hasSelectedLocation(i)">
                <uc-subsection-question *ngIf="first">
                  {{ strings.highestSecondaryQual }}
                </uc-subsection-question>

                <uc-subsection-question *ngIf="!first">
                  {{ strings.additionalSecondaryQual }}
                </uc-subsection-question>

                <ng-container *ngIf="isDomestic(i)">
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/secondary_qualification/{{ i }}/type"
                  ></uc-task-hint>
                  <uc-form-field-label
                    [labelText]="strings.qualification"
                    required="true"
                  >
                    <uc-reference-data-selector
                      formControlName="type"
                      type="highest_school_qual"
                    ></uc-reference-data-selector>
                  </uc-form-field-label>
                </ng-container>

                <ng-container *ngIf="showOtherQualInput(i) || !isDomestic(i)">
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/secondary_qualification/{{ i }}/type_other"
                  ></uc-task-hint>
                  <uc-form-field-label
                    *ngIf="showOtherQualInput(i) || !isDomestic(i)"
                    [labelText]="strings.otherQualification"
                    required="true"
                  >
                    <uc-string-input
                      [testSelector]="'secondary-' + i + '-type-other'"
                      formControlName="typeOther"
                    ></uc-string-input>
                  </uc-form-field-label>
                </ng-container>

                <ng-container *ngIf="!isDomestic(i)">
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/secondary_qualification/{{ i }}/country/code"
                  ></uc-task-hint>
                  <uc-form-field-label
                    [labelText]="strings.otherCountry"
                    required="yes"
                  >
                    <uc-reference-data-selector
                      [formControl]="education.get('country')"
                      type="country"
                    ></uc-reference-data-selector>
                  </uc-form-field-label>
                </ng-container>

                <div
                  class="message-banner-wrapper"
                  *ngIf="showNCEA2Banner(i) && isDomestic(i)"
                >
                  <uc-message-banner type="info">
                    {{ strings.NCEA3MessageBanner }}
                  </uc-message-banner>
                </div>

                <ng-container *ngIf="showSecondaryDateAttained(i) || !isDomestic(i)">
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/secondary_qualification/{{ i }}/date_attained"
                  ></uc-task-hint>
                  <uc-form-field-label
                    [labelText]="strings.yearCompletedQualification"
                    required="true"
                  >
                    <uc-year-selector
                      [testSelector]="'app-year-sec-' + i"
                      [baseYear]="applicationYear"
                      [range]="71"
                      [control]="education.get('dateAttained')"
                    >
                    </uc-year-selector>
                  </uc-form-field-label>
                </ng-container>

                <ng-container *ngIf="showAttainedField(i) && isDomestic(i)">
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/secondary_qualification/{{ i }}/attained"
                  ></uc-task-hint>
                  <uc-form-input>
                    <uc-radio-input-group
                      [formControl]="education.get('attained')"
                      [options]="waitingForResultsOptions"
                      label="{{ strings.waitingForResults }}"
                      [displayHorizontally]="true"
                      required="true"
                      [groupName]="'waiting-results-' + i"
                    ></uc-radio-input-group>
                  </uc-form-input>
                </ng-container>

                <ng-container *ngIf="first">
                  <ng-container *ngIf="hasDateAttained(i)">
                    <uc-task-hint
                      [task]="task"
                      path="/application/was_last_year_at_high_school"
                    ></uc-task-hint>
                    <uc-form-input>
                      <uc-radio-input-group
                        [formControl]="secondaryEducationForm.get('wasLastYearAtHighSchool')"
                        [options]="radioOptions"
                        [label]="strings.wasXLastYearAttended(education.get('dateAttained').value)"
                        [displayHorizontally]="true"
                        required="true"
                        groupName="last-year-high-school"
                      ></uc-radio-input-group>
                    </uc-form-input>
                  </ng-container>

                  <ng-container *ngIf="showOtherLastYearAttended()">
                    <uc-task-hint
                      [task]="task"
                      path="/applicant/demographic/moe_year_last_attended_school"
                    ></uc-task-hint>
                    <uc-form-field-label
                      [labelText]="strings.lastYearAttended"
                      required="true"
                    >
                      <uc-year-selector
                        testSelector="app-year-sec-last"
                        [baseYear]="applicationYear"
                        [range]="71"
                        [control]="secondaryEducationForm.get('lastYearAttended')"
                      >
                      </uc-year-selector>
                    </uc-form-field-label>
                  </ng-container>
                </ng-container>

                <uc-form-field-label
                  *ngIf="isDomestic(i)"
                  [labelText]="strings.whichSchoolAttended"
                  required="true"
                >
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/secondary_qualification/{{ i }}/source/code"
                  ></uc-task-hint>
                  <uc-reference-data-selector
                    formControlName="source"
                    type="secondary_school"
                  ></uc-reference-data-selector>
                </uc-form-field-label>

                <uc-message-banner
                  type="info"
                  *ngIf="showOverseasSchoolWarning(i)"
                >
                  {{ strings.studiedOutsideNZGuidance }}
                </uc-message-banner>

                <uc-form-field-label
                  *ngIf="isDomestic(i) && isSchoolNotOnList(i)"
                  [labelText]="strings.schoolNotOnList"
                  required="true"
                >
                  <uc-task-hint
                    [task]="task"
                    path="/applicant/secondary_qualification/{{ i }}/source_other"
                  ></uc-task-hint>
                  <uc-string-input
                    [testSelector]="'secondary-' + i + '-source'"
                    formControlName="sourceOther"
                  ></uc-string-input>
                </uc-form-field-label>
              </uc-subsection>
            </div>

            <div
              *ngIf="!isMicroCredentialProcess && last && secondaryLocationForm.at(i)?.value && educations.length < 2"
            >
              <uc-subsection-question>{{ strings.addQualQuestion }}</uc-subsection-question>
              <uc-add-new-button
                [buttonHeading]="strings.addQualButtonLabel"
                [buttonText]="strings.addQualButtonText"
                (click)="addQualification()"
              >
              </uc-add-new-button>
            </div>
          </div>
        </div>
      </div>

      <uc-subsection *ngIf="showUploadResults()">
        <uc-task-hint
          [task]="task"
          path="/documents/results_sec"
        ></uc-task-hint>
        <uc-subsection-question [required]="hasInternationalStudy">
          {{ strings.uploadResults }}
        </uc-subsection-question>
        <uc-subsection-minor-text [innerHTML]="strings.uploadResultsHelp[0]"></uc-subsection-minor-text>
        <uc-subsection-minor-text [innerHTML]="strings.uploadResultsHelp[1]"></uc-subsection-minor-text>
        <uc-file-upload
          [year]="applicationYear"
          [title]="strings.uploadResultsTitle"
          categoryCode="RESULTS_SEC"
          usedInTask="true"
        >
        </uc-file-upload>
      </uc-subsection>
    </uc-form-input>
  </uc-section>
</div>
