import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'uc-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
})
export class FormTemplateComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

@Component({
  selector: 'uc-form-header',
  template: `
    <h1 class="form-title accent-font" *ngIf="title">{{ title }}</h1>
    <ng-content></ng-content>
  `,
  styleUrls: ['./form-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormHeaderComponent {
  @Input() title;

  constructor() {}
}

@Component({
  selector: 'uc-form-action',
  template: `
    <div>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./form-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormActionComponent {
  constructor() {}
}
