<uc-form-template>
  <uc-form-header title="{{task?.title}}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header class="financial-info" title="{{strings.sectionHeader}}">
      <p [innerHTML]="strings.formDescription"></p>
    </uc-section-header>
  </uc-section>
</uc-form-template>

<uc-form-template [formGroup]="financialPage">
  <uc-section>
    <uc-section-header title="{{strings.sslRebate.header}}">
      <p class="heading-para"><span innerHTML="{{strings.sslRebate.description}}"></span></p>
    </uc-section-header>
    <uc-ssl-rebate [task]="task" [applicationYear]="applicationYear" [sslForm]="financialPage.get('sslGroup')"></uc-ssl-rebate>
  </uc-section>
</uc-form-template>
