<div class="page-footer">
  <div class="page-footer-content">
    <div class="page-footer-top">
      <div class="row">
        <div class="col-sm-3">
          <a
            href="{{ footerStrings.homepageLink }}"
            target="_blank"
            rel="noopener"
            class="ft-logo"
          >
            <img
              src="assets/img/uc-logo.png"
              alt="University of Canterbury"
            />
          </a>
        </div>
        <div class="col-sm-3">
          <h4 class="ft-heading">{{ footerStrings.generalEnquiry }}</h4>
          <div class="ft-text-block">
            <a
              href="tel:{{ footerStrings.infoPhoneLink }}"
              class="ft-tel-link"
              >{{ footerStrings.infoPhone }}</a
            ><br />
            <a
              href="mailto:{{ footerStrings.infoEmail }}"
              class="ft-link"
              >{{ footerStrings.infoEmail }}</a
            >
          </div>
        </div>
        <div class="col-sm-3">
          <h4 class="ft-heading">{{ footerStrings.enrolmentEnquiries }}</h4>
          <div class="ft-text-block">
            <a
              href="tel:{{ footerStrings.enrolmentPhoneLink }}"
              class="ft-tel-link"
              >{{ footerStrings.enrolmentPhone }}</a
            ><br />
            <a
              href="mailto:{{ footerStrings.enrolEmail }}"
              class="ft-link"
              >{{ footerStrings.enrolEmail }}</a
            >
          </div>
        </div>
        <div class="col-sm-3">
          <h4 class="ft-heading">{{ footerStrings.internationalEnquiries }}</h4>
          <div class="ft-text-block">
            <a
              href="tel:{{ footerStrings.internationalEnquiriesPhoneLink }}"
              class="ft-tel-link"
              >{{ footerStrings.internationalEnquiriesPhone }}</a
            ><br />
            <a
              href="{{ footerStrings.askAQuestionLink }}"
              class="ft-link ask"
              target="_blank"
              rel="noopener"
              >{{ footerStrings.askAQuestion }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer-btm">
      <ul class="page-footer-links">
        <li class="page-footer-link-item">
          <a
            class="page-footer-link"
            href="{{ footerStrings.copyrightLink }}"
            target="_blank"
            rel="noopener"
            >{{ footerStrings.copyright }}</a
          >
        </li>
        <li class="page-footer-link-item">
          <a
            class="page-footer-link"
            href="{{ footerStrings.privacyLink }}"
            target="_blank"
            rel="noopener"
            >{{ footerStrings.privacy }}</a
          >
        </li>
        <li class="page-footer-link-item">
          <a
            class="page-footer-link"
            href="{{ footerStrings.cookiesLink }}"
            target="_blank"
            rel="noopener"
            >{{ footerStrings.cookies }}</a
          >
        </li>
      </ul>
      <div class="clearfix">
        <uc-button
          testSelector="info"
          class="red page-footer-btn"
          externalLink="{{ footerStrings.emergencyInfoLink }}"
          >{{ footerStrings.emergencyInfo }}</uc-button
        >
        <div class="page-footer-copyright">&copy; University of Canterbury</div>
      </div>
    </div>
  </div>
</div>
