<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">Skip to main content</a>
<uc-header-bar (openSidenav)="navBar.isOpen = true" ></uc-header-bar>
<uc-side-nav #navBar></uc-side-nav>
<div id="main-content" tabindex="-1" class="document-content">
  <div class="content-inner">
    <uc-form-template>
      <uc-go-back-link></uc-go-back-link>
      <uc-form-header title="{{strings.header}}">
        <span [innerHTML]=strings.description></span>
        <uc-message-banner type="info">
          <span [innerHTML]="helpText"></span>
        </uc-message-banner>
      </uc-form-header>
      <uc-category-file-upload *ngIf="hasStudentId"></uc-category-file-upload>
      <uc-file-list-container
        *ngFor="let category of categories"
        [category]="category">
      </uc-file-list-container>
    </uc-form-template>
  </div>
  <uc-footer></uc-footer>
</div>
