import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { camelCase, get } from 'lodash-es';
import { map, Observable, zip } from 'rxjs';

import strings from '@constants/strings.constants';
import { CONDITION_STATE } from '@shared/constants/states.constants';
import { Condition } from '@shared/models/condition';
import { CourseOccurrence } from '@shared/models/course';
import { Qualification } from '@shared/models/qualification';
import { ReferenceData, REFDATA_TYPES } from '@shared/models/reference-data';
import { Category } from '@shared/models/uc-file';
import { CourseService } from '@shared/services/course/course.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

const INCOMPLETE = 'incomplete';

@Component({
  selector: 'uc-condition-item',
  templateUrl: './condition-item.component.html',
  styleUrls: ['./condition-item.component.scss'],
})
export class ConditionItemComponent implements OnChanges {
  @Input() condition: Condition;
  @Input() year: string;
  @Input() documentCategories?: Category[];
  @Output() informationProvided = new EventEmitter();

  public strings = strings.components.organisms.conditionItem;
  public conditionItem: ReferenceData;
  public conditionStateStudent: ReferenceData;
  public conditionReason: ReferenceData;
  public qualification: Observable<Qualification>;
  public course: Observable<CourseOccurrence>;

  constructor(
    private referenceDataService: ReferenceDataService,
    private qualificationService: QualificationService,
    private courseService: CourseService,
  ) {}

  get status() {
    return {
      colour: get(this.conditionStateStudent, 'code') === CONDITION_STATE.APPLIED ? 'red' : 'green',
      text: get(this.conditionStateStudent, 'description'),
    };
  }

  get title() {
    return get(this.conditionItem, 'metadata.communicationTitle');
  }

  get description() {
    return this.condition.additionalText;
  }

  get reason() {
    return get(this.conditionReason, 'description');
  }

  get nextSteps() {
    return get(this.conditionItem, 'metadata.communicationNextSteps');
  }

  get documentCategory() {
    return get(this.conditionItem, 'metadata.documentTypeCode');
  }

  get documentCategoryDescription() {
    const category =
      this.documentCategories && this.documentCategories.find((cat) => cat.code === this.documentCategory);
    return !!category ? category.description : this.documentCategory;
  }

  // eslint-disable-next-line complexity
  get subHeadingContextHandler() {
    if (this.condition.item.style === INCOMPLETE) {
      if (this.condition.course?.code) {
        return this.courseWrangler();
      }
      if (this.condition.error?.code && this.condition.qualification?.code) {
        return this.qualificationWrangler();
      }
    }
  }

  qualificationWrangler() {
    if (!this.qualification) {
      this.qualification = this.qualificationService.getQualification(this.year, this.condition.qualification.code);
    }
    return { code: this.condition.qualification?.code };
  }

  courseWrangler() {
    if (!this.course) {
      this.course = this.courseService
        .getCoursesByURIs(this.year, [this.condition.course.code])
        .pipe(map((courses) => courses[0]));
    }
    return { code: this.condition.course?.code };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.condition && !!changes.condition.currentValue) {
      this.getRefData();
    }
  }

  getRefData(): void {
    const refDataToGet = [
      { type: REFDATA_TYPES.CONDITION_ITEM, code: get(this.condition, 'item.code') },
      { type: REFDATA_TYPES.CONDITION_STATE_STUDENT, code: get(this.condition, 'state.code') },
      { type: REFDATA_TYPES.CONDITION_REASON, code: get(this.condition, 'reason.code') },
    ].filter((dataDef) => !!dataDef.code);

    const requests = refDataToGet.map((dataDef) => this.referenceDataService.getByCode(dataDef.type, dataDef.code));

    zip(...requests).subscribe((items: ReferenceData[]) => {
      refDataToGet.forEach((dataDef, index) => {
        this[camelCase(dataDef.type)] = items[index];
      });
    });
  }

  fileUploaded(fileId: string) {
    const updateEvent = {
      condition: this.condition,
      fileId,
    };
    this.informationProvided.emit(updateEvent);
  }
}
