import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-overseas-police-check',
  templateUrl: './overseas-police-check.component.html',
})
export class OverseasPoliceCheckComponent {
  @Input() applicationYear: string;
  @Input() overseasCheck: UntypedFormGroup;
  @Input() task: Task;
  @Input() hintPath: string;
  @Output() newCheck = new EventEmitter<any>();
  @Output() checkRemove = new EventEmitter<number>();

  strings = strings.components.tasks.policeCheck.overseasCheck;
  tabHeadings = strings.components.tasks.policeCheck.countryTabHeadings;

  get checks(): UntypedFormArray {
    return this.overseasCheck.get('checks') as UntypedFormArray;
  }

  addCheck() {
    this.newCheck.emit(null);
  }

  removeCheck(indexToBeRemoved) {
    this.checkRemove.emit(indexToBeRemoved);
  }
}
