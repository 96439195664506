import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@shared/constants/strings.constants';

@Component({
  selector: 'uc-personal-pronouns-selector',
  templateUrl: './personal-pronouns-selector.component.html',
  styleUrls: ['./personal-pronouns-selector.component.scss'],
})
export class PersonalPronounsSelectorComponent implements OnInit {
  @Input() pronounsGroup: UntypedFormGroup;

  showCustomPronounField = false;
  strings = strings.components.molecules.personalPronouns;

  get pronounsFormValue() {
    return this.pronounsGroup.controls.pronouns.value;
  }

  get shouldCustomPronounsFieldDisplay() {
    return this.pronounsFormValue?.code === 'other';
  }

  ngOnInit() {
    this.pronounsGroup.valueChanges.subscribe(() => {
      if (this.pronounsFormValue) {
        this.pronounsGroup.controls.selected.patchValue(true, { emitEvent: false });
      }
    });
  }
}
