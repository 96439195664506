import { Injectable } from '@angular/core';

@Injectable()
export class PositionService {
  constructor() {}
  getBoundingRect(el: HTMLElement) {
    return el.getBoundingClientRect();
  }
  getOffsets(el: HTMLElement) {
    return {
      height: el.offsetHeight,
      width: el.offsetWidth,
    };
  }
}

export class MockPositionService {
  public _mockRect;
  public _mockOffsets;
  private realService = new PositionService();
  constructor() {}

  getBoundingRect(el: HTMLElement) {
    if (!this._mockRect) {
      return this.realService.getBoundingRect(el);
    }
    return this._mockRect;
  }
  getOffsets(el: HTMLElement) {
    if (!this._mockOffsets) {
      return this.realService.getOffsets(el);
    }
    return this._mockOffsets;
  }
}
