import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { get } from 'lodash-es';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { UCError } from '@shared/models/errors';
import { Category, UCDocumentType } from '@shared/models/uc-file';
import { DocumentService } from '@shared/services/document/document.service';
import { FileUploadServiceEvent } from '@shared/services/file-upload/file-upload.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['../organisms.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @ViewChild('uploadEl') uploadEl: ElementRef;

  @Input() usedInTask: boolean;
  @Input() required: boolean;
  @Input() disableRemove: boolean;
  @Input() title: string;
  @Input() disableAddFiles: boolean;
  @Input() year: string;
  @Input() hideUploadedFiles = false;
  @Output() uploadSuccess = new EventEmitter();

  private _categoryCode: string;
  public logger: Logger;
  public files: FileUploadServiceEvent[] = [];
  public errors: UCError[] = [];
  displaySpinner = false;
  strings = strings.components.organisms.fileUpload;
  showError = false;
  private subs: Subscription[] = [];
  error = this.strings.error;
  category: Category;
  isYearScoped: boolean;

  constructor(public docService: DocumentService, private logService: LoggingService, private zone: NgZone) {
    this.logger = logService.createLogger(this);
  }

  @Input()
  get categoryCode(): string {
    return this._categoryCode;
  }

  set categoryCode(value) {
    if (!value) {
      throw new Error('FileUpload component must have a category input');
    }
    this._categoryCode = value;
  }

  get hasUploadedFiles() {
    return this.files && this.files.length > 0;
  }

  get showUploadedFiles() {
    return this.hasUploadedFiles && !this.hideUploadedFiles;
  }

  get showUploadFileButton() {
    return !this.hasUploadedFiles || this.hideUploadedFiles;
  }

  ngOnInit() {
    this.subs.push(this.docService.errorsForCategory(this.categoryCode).subscribe((errs) => this.errors.push(errs)));

    this.getCategoryAndFiles();
  }

  getCategoryAndFiles() {
    this.subs.push(
      this.docService
        .getCategories()
        .pipe(
          switchMap((categories) => {
            // Find the category object for this code
            this.category = categories.find((category) => category.code === this.categoryCode);
            if (!this.category) {
              throw new Error(
                `no matching category in API response for UI file upload category code ${this.categoryCode}`,
              );
            } else {
              this.isYearScoped = this.category.documentType === UCDocumentType.application;
              if (this.isYearScoped && !this.year) {
                throw new Error('document type application must have a year');
              }
            }

            this.year = this.year || String(new Date().getFullYear());

            this.title = this.title || this.category.description;

            if (!this.title) {
              this.title = this.strings[this.categoryCode] || this.categoryCode;
            }

            return this.docService.getDocumentsForCategory(this.categoryCode);
          }),
          tap(() =>
            this.zone.run(() => {
              // no-op; probably not needed at all but confidence in specs is not enough to remove it
            }),
          ),
        )
        .subscribe((docs) => {
          if (this.usedInTask && this.isYearScoped && docs) {
            // Filter files to display to only this years docs
            return (this.files = docs.filter((doc) => doc.file.academicYear === this.year));
          }

          this.files = docs;
        }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub && sub.unsubscribe());
  }

  uploadFile(file: File) {
    this.subs.push(
      this.docService.uploadFileForCategory(file, this.categoryCode, this.year).subscribe(
        (uploadEvent) => {
          this.showError = false;
          if (uploadEvent.progress === 100) {
            this.uploadSuccess.emit(uploadEvent.file.id.toString());
          }
        },
        (err) => {
          this.showError = true;
          this.error = get(strings, err.code, this.strings.error);
          this.logger.error('File upload error:', err);
        },
      ),
    );
  }

  abortUpload(doc: FileUploadServiceEvent) {
    this.docService.abortUpload(doc);
  }

  removeFile(doc: FileUploadServiceEvent) {
    this.displaySpinner = true;
    this.docService.deleteFile(doc.file, this.year).subscribe(
      () => {
        this.displaySpinner = false;
      },
      (err) => {
        this.logger.error('TODO display an error!', err);
      },
    );
  }

  onFileChange() {
    const input: HTMLInputElement = this.uploadEl.nativeElement;
    if (!input.files[0]) {
      this.logger.warn('onFileChange called without file');
    }
    this.uploadFile(input.files[0]);
  }

  setNull() {
    const input: HTMLInputElement = this.uploadEl.nativeElement;
    input.value = null;
  }

  addFile() {
    this.uploadEl.nativeElement.click();
  }
}
