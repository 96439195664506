import { Component, OnInit } from '@angular/core';
import { DeclarationBase } from '@shared/classes/declaration-base';
import { LoggingService } from '@shared/services/logging/logging.service';
import { ApplicationService } from '@shared/services/application/application.service';

@Component({
  selector: 'uc-micro-credentials-declaration',
  templateUrl: '../student-declaration/student-declaration.component.html',
  styleUrls: ['../student-declaration/student-declaration.component.scss'],
})
export class MicroCredentialsDeclarationComponent extends DeclarationBase implements OnInit {
  declarationStrings = this.baseStrings.microCredentialsDeclaration;
  showApplicationHints = false;

  constructor(loggingService: LoggingService, private applicationService: ApplicationService) {
    super(loggingService);
    this.setupData(this.applicationService.application, 'declarationAgree');
    this.hintPath = '/application/declaration_agree';
  }

  ngOnInit() {}

  update() {
    this.updateDeclarationTask(this.applicationService, 'updateApplication');
  }
}
