<a href="javascript:void(0)" class="skip-to-content-link">Skip to main content</a>
<uc-header-bar hasSideNav="true"></uc-header-bar>
<uc-side-nav ></uc-side-nav>
<div class="dashboard-sidenav"></div>
<div id="main-content" tabindex="-1" class="dashboard-content">
  <uc-responsive-grid [breakpoints]="breakpoints">
    <mat-grid-list cols="4" rowHeight="206px">
      <mat-grid-tile colspan="2" rowspan="2">
        <uc-tile></uc-tile>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1"><uc-tile></uc-tile></mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1"><uc-tile></uc-tile></mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1"><uc-tile></uc-tile></mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1"><uc-tile></uc-tile></mat-grid-tile>
    </mat-grid-list>
  </uc-responsive-grid>
  <uc-footer></uc-footer>
</div>

<div class="screen-overlay">
  <div class="screen-overlay-inner clearfix">
    <div class="screen-message-img">
      <uc-icon iconType="cloudload" iconWidth="96" iconHeight="96" iconFill="$colorFireEngineRed"></uc-icon>
    </div>
    <div class="screen-message">
      <h1 class="screen-message-title">{{strings.header}}</h1>
      <div class="screen-message-body">
        <p>{{strings.body}}</p>
        <p><strong>{{strings.emphasizedText}}</strong></p>
      </div>
    </div>
  </div>
</div>
