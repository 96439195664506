import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { get, set } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { CourseType, OnlineCourseService } from '@app/services/online-course/online-course.service';
import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import strings from '@constants/strings.constants';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { PROCESS_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import {
  PREFERRED_CONTACT_METHOD_OPTIONS,
  PREFERRED_CONTACT_METHOD_MAP,
} from '@shared/constants/preferred-contact-method.constants';
import { ETHNICITIES } from '@shared/constants/reference.constants';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { UCError } from '@shared/models/errors';
import { Name } from '@shared/models/name';
import { Phone } from '@shared/models/phone';
import { REFDATA_TYPES, ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  group,
  control,
  array,
  UCElementGroup,
  UCElementArray,
  refDataToValue,
  valueToRefData,
  refDatasToCodeArray,
  controlToRefDataArray,
  booleanToYesNo,
  yesNoToBoolean,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-online-review-micro-credential-details',
  templateUrl: './online-review-micro-credential-details.component.html',
  styleUrls: ['./online-review-micro-credential-details.component.scss'],
})
export class OnlineReviewMicroCredentialDetailsComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() processName: string;

  onlineReviewMicroCredentialDetailsPage: UntypedFormGroup;
  onlineReviewMicroCredentialDetailsForm: UCElementGroup;
  currentApplicant: Applicant;
  currentApplication: Application;
  dateOfBirthYear: number;
  isDisabled: boolean;
  isSameProcessExistInPreviousApplication: boolean;

  legalName: Name;
  name: Name[];

  genderRefdata: ReferenceData[] = [];
  iwiRefdata: ReferenceData[] = [];
  ethnicityRefdata: ReferenceData[] = [];
  citizenshipCategoryRefdata: ReferenceData[] = [];
  countryRefdata: ReferenceData[] = [];

  reviewYourProfileStageString = strings.components.tasks.onlineReviewMicroCredentialDetails.reviewYourProfile;
  yourDetailsTaskString = strings.components.tasks.onlineReviewMicroCredentialDetails.yourDetails;
  bannerString = strings.components.organisms.profilePage.profileTemplate.mandatoryBanner;
  strings = strings.components.tasks.onlineReviewMicroCredentialDetails;
  phoneStrings = strings.components.molecules.onlinePhoneSelector;
  editProfileString = strings.components.tasks.onlineReviewMicroCredentialDetails.editYourProfile;
  learningNeedsStrings = strings.components.organisms.learningNeeds;
  termsAndConditionsStrings = strings.components.molecules.onlineTermsAndConditions;
  permanentAddressStrings = strings.components.tasks.onlineAdditionalDetails.contactDetailsSection;

  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  preferredContactMethodOptions = PREFERRED_CONTACT_METHOD_OPTIONS;

  preferredContactMethodMap = PREFERRED_CONTACT_METHOD_MAP;

  processRequiringId = [PROCESS_NAMES.STAR, PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL];

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private onlineProcessService: OnlineProcessService,
    private onlineCourseService: OnlineCourseService,
    private refDataService: ReferenceDataService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get isStarProcess(): boolean {
    return this.processName === PROCESS_NAMES.STAR;
  }

  get isMCEDProcess(): boolean {
    return [PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL].includes(this.processName);
  }

  get otherNames(): UCElementArray {
    return this.onlineReviewMicroCredentialDetailsForm.controls.names.controls.name;
  }

  get otherNamesLength(): number {
    return this.otherNames?.control.length;
  }

  get citizenship() {
    return this.onlineReviewMicroCredentialDetailsForm.controls.citizenship.controls;
  }

  get demographicElements(): UCElementArray {
    return this.onlineReviewMicroCredentialDetailsForm.controls.citizenship;
  }

  get isNZCitizen(): boolean {
    return this.citizenship.citizenCategory?.control.value.code === 'NZCZ';
  }

  get isInternational(): boolean {
    return this.citizenship.citizenCategory?.control.value.code === 'OTHER';
  }

  get fitnessToTeachGroup() {
    return this.onlineReviewMicroCredentialDetailsForm.controls.fitnessToTeachGroup.controls;
  }

  get hasDisability(): boolean {
    return this.fitnessToTeachGroup.hasDisability?.control?.value?.code === 'true';
  }

  get showStudentLoan(): boolean {
    const isNotInternational = this.citizenship.citizenCategory?.control?.value?.code && !this.isInternational;
    return isNotInternational && !this.isStarOrMicrocredentialProcss;
  }

  get identityDocumentRequired(): boolean {
    return this.processRequiringId.includes(this.processName);
  }

  get isStarOrMicrocredentialProcss(): boolean {
    return this.isStarProcess || this.isMCEDProcess;
  }

  get firstName() {
    return this.currentApplicant?.legalName.firstName || '-';
  }

  get middleName() {
    return this.currentApplicant?.legalName.middleName || '-';
  }

  get surname() {
    return this.currentApplicant?.legalName.surname || '-';
  }

  get preferredName() {
    const result = `${this.preferredFirstName} ${this.preferredMiddleName} ${this.preferredSurName}`;
    return result.trim() !== '' ? result : '-';
  }

  get preferredFirstName() {
    return (
      this.currentApplicant?.name.filter((name: Name) => name.type?.code === Name.PREFERRED_CODE).pop()?.firstName || ''
    );
  }

  get preferredMiddleName() {
    return (
      this.currentApplicant?.name.filter((name: Name) => name.type?.code === Name.PREFERRED_CODE).pop()?.middleName ||
      ''
    );
  }

  get preferredSurName() {
    return (
      this.currentApplicant?.name.filter((name: Name) => name.type?.code === Name.PREFERRED_CODE).pop()?.surname || ''
    );
  }

  get dateOfBirth() {
    return this.currentApplicant?.birthDate || '-';
  }

  get gender() {
    const genderStr = this.currentApplicant?.gender?.code || '-';
    const targetGender = this.genderRefdata.find((el) => el.code === genderStr);
    return this.getReferenceDataDescription(targetGender);
  }

  get email() {
    return this.currentApplicant?.contactDetail.emailAddress || '-';
  }

  get mobileNum() {
    return this.currentApplicant?.contactDetail.mobileNumber.fullNumber || '-';
  }

  get preferredContactMethod() {
    return this.preferredContactMethodMap[get(this.currentApplicant, 'preferredContactMethod')] || '-';
  }

  get ethnicity() {
    const ethnicityStr = this.currentApplicant?.demographic.ethnicity.map((item) => {
      const targetEthnicity = this.ethnicityRefdata.find((el) => el.code === item.code?.toString());
      return this.getReferenceDataDescription(targetEthnicity);
    });
    return ethnicityStr?.length === 1 ? ethnicityStr.toString() : ethnicityStr?.join(', ');
  }

  get iwi() {
    const iwiDescriptions = this.currentApplicant?.demographic.iwi.map((item) => {
      const targetIwi = this.iwiRefdata.find((el) => el.code === item.code?.toString());
      return this.getReferenceDataDescription(targetIwi);
    });
    return iwiDescriptions?.length === 1 ? iwiDescriptions.toString() : iwiDescriptions?.join(', ');
  }

  get countryOfCitizenship() {
    const countryStr = this.currentApplicant?.demographic.passportCountry?.code || '-';
    const targetCountry = this.countryRefdata.find((el) => el.code === countryStr);
    return this.getReferenceDataDescription(targetCountry);
  }

  get citizenshipCategory() {
    const citizenshipCategoryStr = this.currentApplicant?.demographic.citizenship?.code || '-';
    const targetCitizenshipCategory = this.citizenshipCategoryRefdata.find((el) => el.code === citizenshipCategoryStr);
    return this.getReferenceDataDescription(targetCitizenshipCategory);
  }

  get declarationDisability() {
    return this.currentApplicant?.declarationDisability ? 'Yes' : 'No';
  }

  // Permanent Address
  get permanentAddress() {
    const result = `${this.permanentAddressLine1} ${this.permanentAddressLine2}
    ${this.permanentAddressCityTownPostcode} ${this.permanentAddressRegion} ${this.permanentAddressCountry}`;
    return result.trim() !== '' ? result : '-';
  }

  get permanentAddressLine1() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.line1 || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get permanentAddressLine2() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.line2 || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get permanentAddressCityTownPostcode() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = [address?.city, address?.town, address?.postcode].filter((a) => a).join(' ');
    return result.trim() !== '' ? result : '-';
  }

  get permanentAddressRegion() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.region || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get permanentAddressCountry() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.country?.description || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  // Study Address
  get studyAddress() {
    const result = `${this.studyAddressLine1} ${this.studyAddressLine2}
    ${this.studyAddressCityTownPostcode} ${this.studyAddressRegion} ${this.studyAddressCountry}`;
    return result.trim() !== '' ? result : '-';
  }

  get studyAddressLine1() {
    const address = this.currentApplicant?.studyContactDetail.currentAddress;
    const result = `${address?.line1 || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get studyAddressLine2() {
    const address = this.currentApplicant?.studyContactDetail.currentAddress;
    const result = `${address?.line2 || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get studyAddressCityTownPostcode() {
    const address = this.currentApplicant?.studyContactDetail.currentAddress;
    const result = [address?.city, address?.town, address?.postcode].filter((a) => a).join(' ');
    return result.trim() !== '' ? result : '-';
  }

  get studyAddressRegion() {
    const address = this.currentApplicant?.studyContactDetail.currentAddress;
    const result = `${address?.region || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get studyAddressCountry() {
    const address = this.currentApplicant?.studyContactDetail.currentAddress;
    const result = `${address?.country?.description || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  private createForm(): UCElementGroup {
    return group({
      birthDate: control({
        defaultState: '',
        validators: [UCValidators.validateDate],
        model: 'applicant',
        path: '/birthDate',
      }),
      names: group({
        legalName: control({
          defaultState: new Name({}),
          validators: [Validators.required],
          model: 'applicant',
          path: '/legalName',
        }),
        // assumption is made that array objects have all the same type
        name: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/name',
        }),
      }),
      genderGroup: group({
        gender: control({ model: 'applicant', path: '/gender', inMap: refDataToValue, outMap: valueToRefData }),
      }),
      citizenship: group({
        citizenCategory: control({
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/citizenship',
        }),
        citizenCountry: control({ model: 'applicant', path: '/demographic/passportCountry' }),
        studyInNz: control({ model: 'application', path: '/studyInNz', validators: [Validators.required] }),
        ethnicities: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/ethnicity',
        }),
        iwi: array({
          defaultState: [],
          validators: [],
          model: 'applicant',
          path: '/demographic/iwi',
        }),
      }),
      contactDetails: group({
        email: control({
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/contactDetail/emailAddress',
        }),
        mobileNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [
            UCValidators.phonePresenceValidator,
            UCValidators.completePhoneValidator,
            UCValidators.nzMobileValidator,
          ],
          model: 'applicant',
          path: '/contactDetail/mobileNumber',
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressWithoutWhitespaceValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
        preferredContactMethod: control({
          validators: [Validators.required],
          model: 'applicant',
          path: '/preferredContactMethod',
        }),
      }),
      fitnessToTeachGroup: group({
        disability: control({
          defaultState: [],
          model: 'applicant',
          path: '/disability',
          inMap: refDatasToCodeArray,
          outMap: controlToRefDataArray,
          validators: [],
        }),
        hasDisability: control({
          model: 'applicant',
          path: '/declarationDisability',
          inMap: booleanToYesNo(),
          outMap: yesNoToBoolean(),
          validators: [Validators.required],
        }),
        disabilityDetail: control({
          model: 'applicant',
          path: '/disabilityDetail',
        }),
      }),
      termsAndConditionsGroup: group({
        acceptedTermsAndPrivacy: control({
          model: 'application',
          path: '/acceptedTermsAndPrivacy',
          validators: [Validators.requiredTrue],
        }),
        declaredMetRequirements: control({
          model: 'application',
          path: '/declaredMetRequirements',
          validators: [Validators.requiredTrue],
        }),
        acceptedProvideIdentity: control({
          model: 'application',
          path: '/acceptedProvideIdentity',
          validators: [Validators.requiredTrue],
        }),
        uconlineMarketingEmails: control({
          model: 'applicant',
          path: '/uconlineMarketingEmails',
        }),
      }),
    });
  }

  private setProfileControlsStatus() {
    if (this.isDisabled) {
      this.onlineReviewMicroCredentialDetailsPage.disable();
    } else {
      this.onlineReviewMicroCredentialDetailsPage.enable();
      setTimeout(() => {
        this.disableVerifiedControls(this.currentApplicant);
      }, 0);
    }
  }

  private disableVerifiedControls(applicant?: Applicant) {
    this.disableBirthDate(applicant);
    this.disableCitizenship(applicant);
    this.disableLegalName(applicant);
    this.disableEmail();
  }

  private disableEmail() {
    this.onlineReviewMicroCredentialDetailsPage.get('contactDetails.email').disable();
  }

  private disableLegalName(applicant: Applicant) {
    if (get(applicant, 'legalName.validated')) {
      this.onlineReviewMicroCredentialDetailsPage.get('names.legalName').disable();
    }
  }

  private disableCitizenship(applicant: Applicant) {
    if (get(applicant, 'demographic.validatedCitizenship')) {
      this.onlineReviewMicroCredentialDetailsPage.get('citizenship.citizenCategory').disable();
      this.onlineReviewMicroCredentialDetailsPage.get('citizenship.citizenCountry').disable();
    }
  }

  private disableBirthDate(applicant: Applicant) {
    if (get(applicant, 'validatedBirthDate')) {
      this.onlineReviewMicroCredentialDetailsPage.get('birthDate').disable();
    }
  }

  public editProfile() {
    this.isDisabled = false;
    this.setProfileControlsStatus();
  }

  // eslint-disable-next-line class-methods-use-this
  isNzOrAus(val, citizenType?: string): boolean {
    if (!val) {
      return false;
    }

    if (citizenType) {
      return OnlineReviewMicroCredentialDetailsComponent.checkOnWhenHasCitizenType(val, citizenType);
    } else {
      return OnlineReviewMicroCredentialDetailsComponent.checkOnWhenNotHaveCitizenType(val, citizenType);
    }
  }

  static checkOnWhenHasCitizenType(val, citizenType) {
    if (this.shouldCheckNZOrAusPR(val, citizenType)) {
      return ['NZPR', 'AUSPR'].includes(val.code);
    }

    if (OnlineReviewMicroCredentialDetailsComponent.shouldCheckNZAusPROrAusCitizen(val, citizenType)) {
      return ['NZPR', 'AUSPR', 'AUS'].includes(val.code);
    }

    return false;
  }

  static checkOnWhenNotHaveCitizenType(val, citizenType) {
    if (this.shouldCheckNZOrAusCitizen(val, citizenType)) {
      return ['NZCZ', 'AUS'].includes(val.code);
    }
    return false;
  }

  static shouldCheckNZOrAusCitizen(val, citizenType) {
    return val.code && !citizenType;
  }

  static shouldCheckNZOrAusPR(val, citizenType) {
    return val.code && citizenType === 'pr';
  }

  static shouldCheckNZAusPROrAusCitizen(val, citizenType) {
    return val.code && citizenType && citizenType !== 'pr';
  }

  ngOnInit() {
    this.getReferenceData();
    this.onlineReviewMicroCredentialDetailsForm = this.createForm();
    this.onlineReviewMicroCredentialDetailsPage =
      this.onlineReviewMicroCredentialDetailsForm.asControl() as UntypedFormGroup;
    this.dateOfBirthYear = new Date().getFullYear() - 10;

    this.setValidatorForCitizenCountry();

    this.setValidatorForDisabilityDetail();

    combineLatest(
      this.applicantService.getApplicant(),
      this.applicationService.application,
      this.applicationService.applicationEnrolments,
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(this.subscribeHandler);
  }

  private subscribeHandler = ([applicant, application, applicationEnrolments]: [
    Applicant,
    Application,
    ApplicationEnrolment[],
  ]) => {
    this.currentApplicant = applicant;
    this.currentApplication = application;

    this.isDisabled = this.isReview();
    this.log.info(applicationEnrolments);

    this.formModel.updateFormFromModel(this.onlineReviewMicroCredentialDetailsForm, applicant, application);
    if (applicant && !applicant.name.length) {
      this.otherNames.removeAt(0);
    }

    this.setValidatorForIwi();
  };

  private isReview() {
    return (
      !this.onlineCourseService.onlineCourseIsFirstTimeToThisCourseType ||
      this.onlineCourseService.onlineCourseIsMCEnrolledBefore
    );
  }

  private setValidatorForIwi() {
    this.onlineReviewMicroCredentialDetailsPage
      .get('citizenship.ethnicities')
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        const iwiControl = this.onlineReviewMicroCredentialDetailsPage.get('citizenship.iwi');
        if (val[0]?.code === ETHNICITIES.NEW_ZEALAND_MAORI) {
          iwiControl.setValidators([Validators.required, UCValidators.iwiValidator]);
          iwiControl.updateValueAndValidity();
        } else {
          iwiControl.setValidators([]);
          iwiControl.updateValueAndValidity();
        }
        iwiControl.updateValueAndValidity();
      });
  }

  private setValidatorForDisabilityDetail() {
    this.fitnessToTeachGroup.hasDisability.control.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        if (val === 'show') {
          this.fitnessToTeachGroup.disabilityDetail.control.setValidators([
            Validators.required,
            Validators.minLength(1),
          ]);
        } else {
          this.fitnessToTeachGroup.disabilityDetail.control.setValidators([]);
        }
        this.fitnessToTeachGroup.disabilityDetail.control.updateValueAndValidity();
      });
  }

  private setValidatorForCitizenCountry() {
    this.citizenship.citizenCategory.control.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        if (val.code && !this.isNzOrAus(val)) {
          this.citizenship.citizenCountry.control.setValidators([Validators.required]);
        } else {
          this.citizenship.citizenCountry.control.setValidators([]);
        }
        this.citizenship.citizenCountry.control.updateValueAndValidity();
      });
  }

  private getReferenceData() {
    combineLatest([
      this.refDataService.getByType(REFDATA_TYPES.GENDER),
      this.refDataService.getByType(REFDATA_TYPES.IWI),
      this.refDataService.getByType(REFDATA_TYPES.ETHNICITY),
      this.refDataService.getByType(REFDATA_TYPES.CITIZENSHIP_CATEGORY),
      this.refDataService.getByType(REFDATA_TYPES.COUNTRY),
    ]).subscribe(([genderRefdata, iwiRefdata, ethnicityRefdata, citizenshipCategoryRefdata, countryRefdata]) => {
      this.genderRefdata = genderRefdata;
      this.iwiRefdata = iwiRefdata;
      this.ethnicityRefdata = ethnicityRefdata;
      this.citizenshipCategoryRefdata = citizenshipCategoryRefdata;
      this.countryRefdata = countryRefdata;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private getReferenceDataDescription(target: ReferenceData) {
    return target?.description !== undefined && target?.description.trim() !== '' ? target?.description.trim() : '-';
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.onlineReviewMicroCredentialDetailsForm);
  }

  public checkFormValidity(): string[] {
    this.onlineReviewMicroCredentialDetailsPage.updateValueAndValidity();
    if (this.onlineReviewMicroCredentialDetailsPage.invalid) {
      this.log.info(this.onlineReviewMicroCredentialDetailsPage);
      return this.formModel.getInvalidPathesInElementGroup(this.onlineReviewMicroCredentialDetailsForm);
    } else {
      return [];
    }
  }

  submitEnrolmentsIfRequired(): Observable<unknown> {
    return this.onlineProcessService.submitEnrolment(this.onlineCourseService.getCourseYear());
  }

  submitStageAndApplicationIfRequired() {
    return this.onlineProcessService
      .submitStage(
        this.onlineCourseService.onlineCourseType === CourseType.MC
          ? PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL
          : PROCESS_NAMES.UCONLINE_SHORT_COURSE,
        this.onlineCourseService.getCourseYear(),
        STAGE_NAMES.UCONLINE_TO_ENROL,
      )
      .pipe(switchMap(() => this.applicationService.getApplication(this.onlineCourseService.getCourseYear())))
      .subscribe({
        next: (app) => {
          this.onSubmitSuccess(app);
        },
        error: (err) => {
          this.onSubmitFailed(err);
        },
      });
  }

  private onSubmitSuccess = (app) => {
    if (app) {
      app.declarationAgree = null;
      this.applicationService.application$.next(app);
    }
    this.next.emit();
  };

  private onSubmitFailed = (err) => {
    this.log.error(new Error('Error submitting stage'));
    this.errors.emit(err);
  };

  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    }

    this.formModel.updateModelFromForm(
      this.onlineReviewMicroCredentialDetailsForm,
      this.currentApplicant,
      this.currentApplication,
    );

    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);
    this.currentApplicant.demographic.iwi = this.currentApplicant.demographic.iwi.filter((n) => !!n);
    if (get(this.currentApplicant, 'uconlineMarketingEmails') === null) {
      set(this.currentApplicant, 'uconlineMarketingEmails', false);
    }
    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    combineLatest(updateApplicant, updateApplication).subscribe(this.onUpdateSuccess, this.onUpdateFailed);
  }

  private onUpdateSuccess = ([applicant, application]: [Applicant, Application]) => {
    if (applicant && application) {
      this.log.info('>>> Review Micro Credential: updated applicant and application successfully');
    }
    this.submitStageAndApplicationIfRequired();
  };

  private onUpdateFailed = (err: DSHttpError) => {
    this.errors.emit();
    this.log.error('error thrown while updating applica(nt|tion):', err);
  };
}
