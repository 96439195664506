import { PROCESS_NAMES } from './app-names.constants';

export const deepInternalLinkWrapper = (internalUrl, isImpersonating) => {
  const url = internalUrl.join('/');
  if (isImpersonating) {
    return `/ang${url}`;
  } else {
    return url;
  }
};

export const internalUrls = {
  apply: ['/apply'],
  preProcessPage: (processCode: string): string[] => {
    return ['/apply', processCode];
  },
  processPage: (processCode: string, year: string): string[] => {
    return ['/apply', processCode, year];
  },
  processPageStage: (processCode: string, year: string, stageCode: string): string[] => {
    return ['/apply', processCode, year, stageCode];
  },
  processPageTask: (processCode: string, year: string, stageCode: string, taskCode: string): string[] => {
    return ['/apply', processCode, year, stageCode, taskCode];
  },
  onlineApply: ['/online-apply'],
  onlinePreProcessPage: (processCode: string): string[] => {
    return ['/online-apply', processCode];
  },
  onlineProcessPage: (processCode: string, year: string): string[] => {
    return ['/online-apply', processCode, year];
  },
  onlineProcessPageStage: (processCode: string, year: string, stageCode: string): string[] => {
    return ['/online-apply', processCode, year, stageCode];
  },
  onlineProcessPageTask: (processCode: string, year: string, stageCode: string, taskCode: string): string[] => {
    return ['/online-apply', processCode, year, stageCode, taskCode];
  },
  courseSelector: (year: string, enrolmentPriority: string, qualificationPriority: string | number): string[] => {
    return ['/courses', year, enrolmentPriority, qualificationPriority.toString()];
  },
  courseSelectionOverview: (processName: string, year: string) => {
    const stage = processName === PROCESS_NAMES.COE ? 'change-of-enrolment' : 'select-courses';
    return internalUrls.processPageTask(processName, year, stage, 'course-selection-overview');
  },
  informationRequired: (year: string) => {
    return ['/information-required', year];
  },
  studentSummary: (cid: string) => {
    return ['/student-management', cid];
  },

  adminViewUserManagement: ['/user'],
  fourOhFour: ['/404'],
  dashboard: ['/'],
  login: ['/login'],
  profile: ['/profile'],
  manageMyStudy: ['/manage-my-study'],
  documents: ['/documents'],
  agencyManagement: ['/agencies'],
  agencyCreation: ['/create-agency'],
  hydrateOverlay: ['/hydrate'],
  accountSettings: ['/account-settings'],
  sso: ['/sso'],
  users: ['/users'],
  notifications: ['/notifications'],
  outage: ['/outage'],
  auditEvents: ['/audit-events'],
  courseResults: ['/course-results'],
  qualOccurrences: ['/qualification-occurrences'],
  studentManagement: (cid: string) => `/student-management/${cid}`,
  tasks: ['/task-management'],
};
