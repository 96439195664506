import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';
import { UCElementGroup, UCElementArray } from '@shared/services/form-model-mapper/form';

export const MAX_COUNT_FOR_ETHNICITY_OR_IWI = 6;

@Component({
  selector: 'uc-ethnicity-iwi-selectors',
  templateUrl: './ethnicity-iwi-selectors.component.html',
  styleUrls: ['./ethnicity-iwi-selectors.component.scss'],
})
export class EthnicityIwiSelectorsComponent implements OnInit {
  @Input() demographicElements: UCElementGroup;
  @Input() task: Task;
  readonly maxValueForEthnicityOrIwi = MAX_COUNT_FOR_ETHNICITY_OR_IWI;
  showIwi = false;
  strings = strings.components.molecules.ethnicityIwiSelector;

  // ToDo: Kelvin ADO-110649 has eslint rule adjusted `@typescript-eslint/no-useless-constructor` to be `warn` instead.
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}

  get iwiControls(): UntypedFormArray {
    return this.demographicForm.get('iwi') as UntypedFormArray;
  }

  get ethnicityControls(): UntypedFormArray {
    return this.demographicForm.get('ethnicities') as UntypedFormArray;
  }

  get ethnicityElements(): UCElementArray {
    return this.demographicElements.controls.ethnicities as UCElementArray;
  }

  get iwiElements(): UCElementArray {
    return this.demographicElements.controls.iwi as UCElementArray;
  }

  get demographicForm(): UntypedFormGroup {
    return this.demographicElements.control;
  }

  get ethnicityFilter(): string[] {
    return this.ethnicityControls.value.map((v) => v.code);
  }
  get iwiFilter(): string[] {
    return this.iwiControls.value.map((v) => v.code);
  }

  ngOnInit() {
    this.ethnicityControls.valueChanges.subscribe((val) => {
      // 2 is the code for new zealand maori
      const eths = val.filter((ref) => ref.code === '2');
      this.showIwi = eths && eths.length;
    });
  }

  addEthnicity() {
    this.ethnicityElements.push();
  }

  removeEthnicity(indexToBeRemoved) {
    this.ethnicityElements.removeAt(indexToBeRemoved);
  }

  addIwi() {
    this.iwiElements.push();
  }

  removeIwi(indexToBeRemoved) {
    this.iwiElements.removeAt(indexToBeRemoved);
  }
}
