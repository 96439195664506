<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section *ngIf="isLoading">
    <div class="loading-placeholder">
      <uc-spinner></uc-spinner>
    </div>
  </uc-section>

  <div class="award__enrolment-banner" *ngIf="continuingEnrolmentError">
    <uc-message-banner type="error"><span [innerHTML]="strings.awardEnrolmentError"></span></uc-message-banner>
  </div>

  <uc-section *ngIf="!continuingEnrolments.length">
    <uc-section-header title="{{ strings.header }}">
      <p>{{ strings.description }}</p>

      <p class="qual-help-text" *ngIf="helpText">
        <span [innerHTML]="helpText"></span>
      </p>
    </uc-section-header>

    <uc-enrolment-selector
      *ngIf="!isLoading"
      [process]="processFilter"
      [year]="applicationYear"
      [qualificationEnrolments]="enrolments"
      [qualificationOptions]="qualificationData"
      (qualificationAnswered)="onNewQualificationAnswer($event)"
    >
    </uc-enrolment-selector>
    <uc-subsection class="alternative-choice-wrapper">
      <uc-add-new-button
        (click)="addOne()"
        *ngIf="showAlternateQualBtn"
        [buttonHeading]="altQualString.altChoiceButtonTitle"
        [buttonText]="altQualString.altChoiceButtonDescription"
      >
      </uc-add-new-button>

      <div class="enrolment-choice alternative-choice" *ngIf="showAlternateQualSelector">
        <div class="choice-controls">
          <div class="choice-tab body-font-semi">
            <span>{{ altQualString.altChoiceTitle }}</span>
          </div>
          <button [attr.data-cy]="'delete-btn'" class="delete-btn" (click)="deleteAlternativeQual()">
            <uc-icon
              class="delete-btn-icon"
              iconType="trash"
              iconWidth="16"
              iconHeight="16"
              iconFill="$colorWhite"
            ></uc-icon>
          </button>
        </div>
        <div class="enrolment-choice-wrapper">
          <uc-subsection class="alt-guidance-text">
            <p [innerHTML]="altQualString.altChoiceGuidance"></p>
          </uc-subsection>
          <uc-subsection>
            <uc-qualification-selector
              [qualificationOptions]="qualificationData"
              [selectedQualification]="currentApplication?.alternateQualification?.code"
              (changedQualification)="alternateQualUpdated($event)"
            >
            </uc-qualification-selector>
          </uc-subsection>
        </div>
      </div>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="continuingEnrolments.length" [formGroup]="taskForm">
    <uc-section-header [title]="headerText"></uc-section-header>
    <uc-subsection class="award__subsection" *ngIf="!continuingEnrolmentError && !maxEnrolmentCountError">
      <p *ngIf="!forceContinue; else forceContinueDetails" class="award__description">
        <uc-message-banner type="info" *ngIf="deaggregated"
          ><span [innerHTML]="strings.deaggregatedInfo"></span
        ></uc-message-banner>
        {{ strings.continueQualification(applicationYear) }}
      </p>
      <ng-template #forceContinueDetails>
        <p class="award__description">
          {{ strings.forceContinueDescription }}
        </p>
      </ng-template>

      <div class="award__container" *ngFor="let qualification of fullAwardEnrolments">
        <h3 class="qualification-heading">
          {{ qualification.qualificationAnswer.title }} ({{ qualification.qualificationAnswer.code }})
        </h3>

        <div class="award__qual-tag--wrapper">
          <uc-tag-list
            *ngIf="qualification.subjectAnswer"
            [subjectAnswer]="qualification.subjectAnswer"
            [cropLongText]="false"
            [subjectOptions]="qualification.qualificationAnswer?.subjectOptions"
          ></uc-tag-list>
        </div>
      </div>

      <uc-radio-input-group
        *ngIf="!forceContinue"
        [label]=""
        formControlName="didContinueAward"
        [options]="continueAwardOptions"
        [displayHorizontally]="true"
        groupName="did-continue"
        ngDefaultControl
      >
      </uc-radio-input-group>
    </uc-subsection>

    <uc-enrolment-selector
      *ngIf="taskForm.get('didContinueAward').value === false || forceContinue || maxEnrolmentCountError"
      [process]="processFilter"
      [year]="applicationYear"
      [qualificationEnrolments]="enrolments"
      [qualificationOptions]="qualificationData"
      (qualificationAnswered)="onNewQualificationAnswer($event)"
    >
    </uc-enrolment-selector>
  </uc-section>

  <uc-section [formGroup]="formGroup">
    <uc-yes-no
      [required]="true"
      [task]="task"
      path="/application/will_complete_award"
      [questionString]="strings.willCompleteAward"
      formControlName="willCompleteAward"
      testSelector="will-complete"
    >
    </uc-yes-no>
  </uc-section>
</uc-form-template>
