<uc-form-template>
  <uc-form-header title="{{ task?.title }}"> </uc-form-header>

  <uc-section>
    <uc-section-header title="{{ strings.sectionHeader }}"> </uc-section-header>
    <uc-subsection>
      <span innerHTML="{{ strings.content }}"></span>

      <uc-file-upload
        *ngIf="offerAvailable"
        [title]="strings.enrolmentAgreement"
        categoryCode="OFFER_LETTER"
        [year]="applicationYear"
        [disableRemove]="true"
        [disableAddFiles]="true"
        [usedInTask]="true"
      >
      </uc-file-upload>

      <uc-message-banner *ngIf="noOfferYet" type="info">{{ strings.messageBanner }}</uc-message-banner>

      <uc-button testSelector="go-ucsw" *ngIf="strings.buttonText" (click)="navigateToUCSW()">{{
        strings.buttonText
      }}</uc-button>
    </uc-subsection>
  </uc-section>
</uc-form-template>
