<uc-spinner *ngIf="qualsLoading" [useStaticPositioning]="true"></uc-spinner>
<div class="qualification__card-wrapper" *ngIf="!qualsLoading">
  <div class="qualification__container" *ngFor="let qualification of qualList">
    <h3 class="qualification__heading">{{ qualification.qualTitle }} ({{ qualification.qualCode }})</h3>
    <div *ngIf="qualification.startDate && displayStartDate" class="study-start-detail">
      <span class="study-start-label">Study start </span>
      <span>{{ qualification.startDate | date: 'dd/MM/yy' }}</span>
    </div>
    <div class="qualification__tag--wrapper" *ngIf="(qualification.subjectAnswer | keyvalue)?.length">
      <uc-tag-list
        [subjectAnswer]="qualification.subjectAnswer"
        [cropLongText]="false"
        [subjectOptions]="qualification.subjectOptions"
      ></uc-tag-list>
    </div>
  </div>
</div>
