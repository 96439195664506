import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'uc-add-new-button',
  templateUrl: './add-new-button.component.html',
  styleUrls: ['./add-new-button.component.scss'],
})
export class AddNewButtonComponent implements OnInit {
  @Input() buttonHeading: string;
  @Input() buttonText: string;
  @Input() required?;

  constructor() {}

  ngOnInit() {}
}
