import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { uniqBy, get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { CourseOccurrence } from '@shared/models/course';
import { CourseService } from '@shared/services/course/course.service';

export interface FilterOptions {
  displayLevel: SelectOption[];
  teachingPeriodCode: SelectOption[];
  site: SelectOption[];
}

export interface SelectOption {
  value: string;
  labelText: string;
}

@Component({
  selector: 'uc-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit, AfterContentChecked {
  @Input() filterOrder: Record<string, { labelText: string; value: string }[]> = {};

  strings = strings.components.organisms.searchFilters;

  selectedOptions: FilterOptions = {
    displayLevel: [{ labelText: this.strings.defaultLabels.level, value: '' }],
    teachingPeriodCode: [{ labelText: this.strings.defaultLabels.teachingPeriodCode, value: '' }],
    site: [],
  };
  options: FilterOptions = this.initialOptions as FilterOptions;
  showFilters = false;

  private initialFormValue = {
    displayLevel: '',
    teachingPeriodCode: '',
    site: [],
  };

  searchFiltersForm = this.fb.group(this.preventArrayFormValuesFromBeingReadAsConfiguration(this.initialFormValue), {});
  constructor(
    private fb: UntypedFormBuilder,
    private courseService: CourseService,
  ) {}

  get filterNames(): string[] {
    return Object.keys(this.options);
  }

  private get initialOptions() {
    return Object.entries(this.selectedOptions).reduce((output, [key, value]) => {
      output[key] = [...value];
      return output as FilterOptions;
    }, {});
  }

  ngOnInit() {
    this.searchFiltersForm.valueChanges.subscribe((value) => {
      value.site = value.site.map((site) => site.value);
      this.courseService.filterCourses(value);
    });
    this.courseService.searchResult.subscribe((searchResults) => this.createFilterOptions(searchResults));
  }

  ngAfterContentChecked() {
    this.searchFiltersForm.updateValueAndValidity({});
  }

  private createFilterOptions(searchResults) {
    if (searchResults) {
      this.hideOrShowFilters(searchResults);
    }
  }

  private hideOrShowFilters(searchResults) {
    if (searchResults.meta.resultCount > 0 && !searchResults.course.length) {
      this.hideFilters();
    } else {
      this.createOptions(searchResults.course);
      this.setDefaultSites();
    }
  }

  private setDefaultSites() {
    this.selectedOptions.site = this.options.site.filter((option) => option.value !== 'UC Online');
    this.searchFiltersForm.controls.site.setValue(this.selectedOptions.site);
  }

  private addOptionIfNotExist(filterName: string, option: SelectOption) {
    const currentOptions = this.options[filterName] as SelectOption[];
    if (!currentOptions.some((opt) => opt.value === option.value)) {
      const labelText = option.labelText || option.value;
      currentOptions.push({ value: option.value, labelText });
    }
  }

  private addOrderedOptions(filter, courses) {
    const uniqueValueLookup = {};
    uniqBy(courses, filter).forEach((c) => (uniqueValueLookup[c[filter]] = true));
    this.filterOrder[filter].forEach((option: { labelText: string; value: string }) => {
      if (uniqueValueLookup[option.value]) {
        this.addOptionIfNotExist(filter, option);
      }
    });
  }

  private addAlphaSortedOptions(filter, courses) {
    const sortedUniqueValues = uniqBy(courses, filter)
      .map((c) => c[filter])
      .sort();
    sortedUniqueValues.forEach((value) => this.addOptionIfNotExist(filter, { labelText: '', value }));
  }

  private preventArrayFormValuesFromBeingReadAsConfiguration<T>(formValues: T) {
    return Object.entries(formValues).reduce((output, [key, value]) => {
      output[key] = Array.isArray(value) ? [value] : value;
      return output;
    }, {});
  }

  createOptions(courses: CourseOccurrence[]) {
    this.resetFilters();
    this.showFilters = !!get(courses, 'length');
    if (!this.showFilters) {
      return;
    }

    Object.keys(this.initialFormValue).forEach((key) => {
      const hasDefinedOrder = Array.isArray(this.filterOrder[key]);
      if (hasDefinedOrder) {
        this.addOrderedOptions(key, courses);
      } else {
        this.addAlphaSortedOptions(key, courses);
      }
    });
  }

  resetFilters() {
    this.selectedOptions.site = [];
    this.options = this.initialOptions as FilterOptions;
    this.searchFiltersForm.setValue(this.initialFormValue);
  }

  hideFilters() {
    this.resetFilters();
    this.showFilters = false;
  }
}
