import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import {
  QUALIFICATION_LEVELS,
  COUNTRY_OPTIONS,
  SECONDARY_QUAL_CODES,
  SCHOOLS,
} from '@shared/constants/academic-history';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { AttainedQualification } from '@shared/models/attained-qualification';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
  array,
  UCElementArray,
  numberToString,
  UCFormElement,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-online-study-history',
  templateUrl: './online-study-history.component.html',
  styleUrls: ['./online-study-history.component.scss'],
})
export class OnlineStudyHistoryComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  isFirstYear = new UntypedFormControl('');

  strings = strings.components.tasks.onlineStudyHistory;
  log: Logger;

  onlineStudyHistoryPage: UntypedFormGroup;
  onlineStudyHistoryForm: UCElementGroup;
  secondaryLocationForm = new UntypedFormArray([]);
  highestStudyLevelAdditionalText = [];

  tertiaryStudyLevels = [
    QUALIFICATION_LEVELS.FOUNDATION,
    QUALIFICATION_LEVELS.POST_GRADUATE,
    QUALIFICATION_LEVELS.UNDER_GRADUATE,
  ];

  private currentApplicant: Applicant;
  currentApplication: Application;

  constructor(
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private formModel: FormModelMapperService,
    ls: LoggingService,
  ) {
    super();
    this.log = ls.createLogger(this);
  }

  get highestStudyLevel() {
    return get(this.onlineStudyHistoryPage.get('highestStudyLevel'), 'value');
  }

  get highestStudyLevelTertiary() {
    return this.highestStudyLevel && this.tertiaryStudyLevels.indexOf(this.highestStudyLevel) !== -1;
  }

  get secondaryEducations() {
    return this.onlineStudyHistoryForm.get('secondaryEducation').get('educations') as UCElementArray;
  }

  get secondaryEducationsSource() {
    return this.onlineStudyHistoryForm.controls.secondaryEducation.controls.educations.controls[0].controls
      .source as UCFormElement;
  }

  get secondaryEducationsSourceOther() {
    return this.onlineStudyHistoryForm.controls.secondaryEducation.controls.educations.controls[0].controls
      .sourceOther as UCFormElement;
  }

  get tertiaryEducations() {
    return this.onlineStudyHistoryForm.get('tertiaryEducation').get('educations') as UCElementArray;
  }

  ngOnInit() {
    this.setHighestStudyLevelAdditionalText();

    this.onlineStudyHistoryForm = this.createUcElementGroup();
    this.onlineStudyHistoryPage = this.onlineStudyHistoryForm.asControl() as UntypedFormGroup;

    combineLatest(
      this.applicantService.applicant.pipe(filter((a) => !!a)),
      this.applicationService.application.pipe(filter((a) => !!a)),
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.setIsFirstYear(application);
        OnlineStudyHistoryComponent.setupAttainedQualifications(applicant);
        this.setupSecondaryStudyLocations(applicant.secondaryQualification);
        this.formModel.updateFormFromModel(this.onlineStudyHistoryForm, this.currentApplicant, this.currentApplication);

        this.setValidatorForSecondaryEducationsSourceOther();
      });
  }

  private addSecondaryLocation(): void {
    this.secondaryLocationForm.controls.push(new UntypedFormControl('', Validators.required));
  }

  private setupSecondaryStudyLocations(secondaryQuals: AttainedQualification[]): void {
    const controls = secondaryQuals.map((qual) => {
      let location = qual.country?.code;

      if (location && location !== COUNTRY_OPTIONS.NZL) {
        location = COUNTRY_OPTIONS.OTHER;
      }

      return new UntypedFormControl(location, Validators.required);
    });
    this.secondaryLocationForm = new UntypedFormArray(controls);

    if (!controls.length) {
      this.addSecondaryLocation();
    }
  }

  addSecondaryQual() {
    this.addSecondaryLocation();
    this.secondaryEducations.push();
  }

  removeSecondaryQual(index) {
    this.secondaryLocationForm.removeAt(index);
    this.secondaryEducations.removeAt(index);
  }

  static setupAttainedQualifications(applicant: Applicant) {
    OnlineStudyHistoryComponent.setupSecondaryQualification(applicant);
    OnlineStudyHistoryComponent.setupTertiaryQualification(applicant);
    OnlineStudyHistoryComponent.setupUeQualification(applicant);
  }

  static setupUeQualification(applicant: Applicant) {
    if (!applicant.ueQualification) {
      applicant.ueQualification = new AttainedQualification();
    }
  }

  static setupTertiaryQualification(applicant: Applicant) {
    if (!applicant.tertiaryQualification.length) {
      applicant.tertiaryQualification.push(new AttainedQualification());
    }
  }

  static setupSecondaryQualification(applicant: Applicant) {
    if (!applicant.secondaryQualification.length) {
      applicant.secondaryQualification.push(new AttainedQualification());
    }
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  private createUcElementGroup(): UCElementGroup {
    return group({
      highestStudyLevel: control({
        model: 'applicant',
        path: '/ueQualification/type',
        inMap: refDataToValue,
        outMap: valueToRefData,
      }),
      secondaryEducation: group({
        educations: array({
          model: 'applicant',
          path: '/secondaryQualification',
          group: group({
            type: control({ validators: [Validators.required], model: 'applicant', path: '/type' }),
            source: control({
              validators: [Validators.required],
              model: 'applicant',
              path: '/source',
            }),
            dateAttained: control({ validators: [Validators.required], model: 'applicant', path: '/dateAttained' }),
            sourceOther: control({ validators: [], model: 'applicant', path: '/sourceOther' }),
          }),
        }),
      }),
      moreAboutYourStudies: group({
        firstYearTertiary: control({
          model: 'applicant',
          path: '/firstYearTertiary',
          validators: [Validators.required],
          inMap: numberToString,
        }),
        occupation: control({
          model: 'applicant',
          path: '/demographic/moeMainActivity',
          validators: [Validators.required],
        }),
      }),
    });
  }

  private setHighestStudyLevelAdditionalText() {
    Object.entries(QUALIFICATION_LEVELS).forEach(([, code]) => {
      const inlineGuidance = this.strings.studyLevelAdditionalText[code];
      this.highestStudyLevelAdditionalText.push({ code, inlineGuidance });
    });
  }

  private setIsFirstYear(application: Application) {
    if (this.currentApplicant.firstYearHere === Number(application.academicYear.code)) {
      this.isFirstYear.setValue('show');
    } else if (this.shouldFirstYearHide(application)) {
      this.isFirstYear.setValue('hide');
    }
  }

  private shouldFirstYearHide(application) {
    return (
      this.currentApplicant.firstYearHere &&
      this.currentApplicant.firstYearHere !== Number(application.academicYear.code)
    );
  }

  private setValidatorForSecondaryEducationsSourceOther() {
    const educationSourceControl = this.secondaryEducationsSource.control;
    const educationSourceOtherControl = this.secondaryEducationsSourceOther.control;
    educationSourceControl.valueChanges.subscribe((val) => {
      if (val?.code === SCHOOLS.NZ_SCHOOL_NOT_ON_LIST) {
        educationSourceOtherControl.setValidators([Validators.required]);
      } else {
        educationSourceOtherControl.setValidators([]);
      }
      educationSourceOtherControl.updateValueAndValidity();
    });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.onlineStudyHistoryForm);
  }

  public checkFormValidity(): string[] {
    this.onlineStudyHistoryPage.updateValueAndValidity();
    if (this.onlineStudyHistoryPage.invalid) {
      return this.formModel.getInvalidPathesInElementGroup(this.onlineStudyHistoryForm);
    } else {
      return [];
    }
  }

  static resetTypeField(tertiaryEds: UntypedFormArray) {
    tertiaryEds.controls.forEach((education: UntypedFormArray) => {
      education.get('type').reset();
    });
  }

  update() {
    const getLocation = (index) => this.secondaryLocationForm.at(index).value;
    const secondaryEducations = this.onlineStudyHistoryPage
      .get('secondaryEducation')
      .get('educations') as UntypedFormArray;
    this.secondaryEducationResetOnLocation(secondaryEducations, getLocation);

    this.formModel.updateModelFromForm(this.onlineStudyHistoryForm, this.currentApplicant, this.currentApplication);

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    combineLatest(updateApplicant, updateApplication).subscribe({
      next: this.onUpdateSuccess,
      error: this.onUpdateFailed,
    });
  }

  private onUpdateSuccess = ([applicant, application]: [Applicant, Application]) => {
    if (applicant && application) {
      this.log.info('updated applicant and application successfully');
      this.next.emit();
    }
  };

  private onUpdateFailed = (err: DSHttpError) => {
    this.errors.emit();
    this.log.error('error thrown while updating applica(nt|tion):', err);
  };

  private secondaryEducationResetOnLocation(
    secondaryEducations: UntypedFormArray,
    getLocation: (index: unknown) => unknown,
  ) {
    secondaryEducations.controls.forEach((secondary: UntypedFormArray, index) => {
      const location = getLocation(index);
      this.resetWhenOther(location, secondary);
      this.resetWhenNZLAndOversea(location, secondary);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private resetWhenNZLAndOversea(location: unknown, secondary: UntypedFormArray) {
    if (
      location === COUNTRY_OPTIONS.NZL &&
      get(secondary.get('type').value, 'code') === SECONDARY_QUAL_CODES.OVERSEAS
    ) {
      secondary.get('type').reset();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private resetWhenOther(location: unknown, secondary: UntypedFormArray) {
    if (location === COUNTRY_OPTIONS.OTHER) {
      secondary.get('source').reset();
      secondary.get('type').setValue({ code: SECONDARY_QUAL_CODES.OVERSEAS });
    }
  }
}
