import { ReferenceData } from '@shared/models/reference-data';

import {
  Deserializer,
  Initializer,
  KeysToSnakeCase,
  Model,
  RecursivePartial,
  SchemaToModel,
  Serializer,
} from './base/model.base';

export type Address = SchemaToModel<typeof AddressSchema> & AddressModel;

export type SerializedAddress = KeysToSnakeCase<SchemaToModel<typeof AddressSchema>>;

const AddressSchema = {
  line1: String,
  line2: String,
  country: ReferenceData,
  city: String,
  town: String,
  region: String,
  postcode: String,
  latitude: Number,
  longitude: Number,
};

export class AddressModel extends Model<Address> {
  static createFrom(data: RecursivePartial<Address> = {}): Address {
    return new Initializer().process(data, AddressSchema, AddressModel) as Address;
  }

  static deserialize(data: RecursivePartial<SerializedAddress>): Address {
    return new Deserializer().process(data, AddressSchema, AddressModel) as Address;
  }

  static serialize(obj: Address): SerializedAddress {
    return new Serializer().process(obj, AddressSchema) as SerializedAddress;
  }
}
