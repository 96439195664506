import { get } from 'lodash-es';

import { camelizeKeys } from '@shared/helpers/serialization';
import { Applicant } from '@shared/models/applicant';
import { Contact } from '@shared/models/contact';
import { Name } from '@shared/models/name';

class EmptyApplicant {
  contactDetail: Contact;
  legalName: Name;
  userId: string;

  static deserialize(payload: any) {
    if (payload === null) {
      return null;
    }

    payload = camelizeKeys(payload);
    payload.contactDetail = Contact.deserialize(payload.contactDetail);
    payload.legalName = Name.deserialize(payload.legalName);
    return new EmptyApplicant(payload);
  }

  constructor(obj) {
    Object.assign(this, obj);
  }
}

export class AdminViewUser {
  user: {
    cid: string;
    studentId: string;
    fbid: string[];
    username: string;
    needsHydrating: boolean;
  };
  applicant: Applicant | EmptyApplicant;
  aliases: string[];
  errors: any[];

  get firebaseID() {
    return get(this.user, 'fbid[0]');
  }

  get canonicalID() {
    return get(this.user, 'cid');
  }

  get loginType() {
    return !!this.firebaseID ? 'Firebase' : 'LDAP';
  }

  static deserialize(payload: { results: any[] }): AdminViewUser[] {
    if (payload === null || !Array.isArray(payload.results)) {
      return null;
    }

    const users = payload.results.map((user) => {
      user.user = camelizeKeys(user.user);
      user.applicant = user.user.cid ? Applicant.deserialize(user) : EmptyApplicant.deserialize(user.applicant);
      return new AdminViewUser(user);
    });

    return users;
  }

  constructor(obj) {
    Object.assign(this, obj);
  }
}
