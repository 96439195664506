import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CourseOccurrence } from '@shared/models/course';
import strings from '@constants/strings.constants';
@Component({
  selector: 'uc-course-search-table-view',
  templateUrl: './course-search-table-view.component.html',
  styleUrls: ['./course-search-table-view.component.scss'],
})
export class CourseSearchTableViewComponent implements OnChanges {
  customStrings = strings.components.molecules.courseSearchTableView;
  @Input() courses: CourseOccurrence[];
  @Input() compact: boolean;
  @Input() errorTriggered: boolean;
  @Input() selectedCourses: {};
  cancelSpinner = false;

  @Output() courseAdd = new EventEmitter();

  defaultSort = [{ prop: 'courseOccurrenceCode', dir: 'asc' }];

  getRowHeight(row) {
    return row.height;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cancelSpinner = false;
    if (changes.errorTriggered && changes.errorTriggered.currentValue) {
      this.cancelSpinner = true;
    }
  }

  addACourse(course) {
    course.activeCourse = true;
    this.courseAdd.emit(course);
  }
}
