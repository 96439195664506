import { BehaviorSubject } from 'rxjs';

import { Application } from '@shared/models/application';
import { UCProcess } from '@shared/models/process';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { mockData as mockApplicationResponse } from '@shared/services/application/application.data.mock';
import {
  IPreProcessRouteData,
  IPreProcessRouteParams,
} from '@shared/services/resolvers/pre-process-resolver/pre-process-resolver';
import {
  IProcessRouteData,
  IProcessRouteParams,
} from '@shared/services/resolvers/process-resolver/process-resolver.service';

export const expectedProcessResponse = {
  processes: [
    {
      code: 'change_of_application',
      displayOnSplash: true,
      title: 'Change Application',
      href: '/apply/change_of_application/2018/change',
      description: 'Change your application',
      stages: [
        {
          title: 'Change Qualification',
          code: 'change-of-application',
          percentComplete: 0,
          tasks: [
            {
              title: 'Change Qualification / Subjects',
              code: 'qualification-coa',
              decorator: 'cap-alt',
              hints: [],
            },
            {
              title: 'Submit Change of Application',
              code: 'confirm-coa',
              percentComplete: 0,
              action_label: 'save_and_submit',
            },
          ],
        },
        {
          title: 'Resolve Process',
          code: 'resolve-coa-process',
          percentComplete: 0,
          tasks: [
            {
              title: 'Course Selection Overview',
              code: 'resolve-coa',
              decorator: 'cap-alt',
              hints: [],
            },
          ],
        },
      ],
    },
    {
      code: 'change_of_enrolment',
      displayOnSplash: true,
      title: 'Change Enrolment',
      href: '/apply/change_of_enrolment/2018/change',
      description: 'Change your courses',
      stages: [
        {
          title: 'Change Enrolment',
          code: 'change',
          percentComplete: 0,
          tasks: [
            {
              title: 'Change Qualification / Subjects',
              code: 'coe-qualification',
              percentComplete: 0,
              hints: [
                {
                  detail: 'mustHaveChanges',
                  source: { pointer: '/change_of_enrolment/0' },
                },
              ],
            },
            {
              title: 'Course Selection Overview',
              code: 'course-selection-overview',
              percentComplete: 0,
              hints: [
                {
                  detail: 'mustHaveChanges',
                  source: { pointer: '/change_of_enrolment/0' },
                },
              ],
            },
            {
              title: 'Police Check',
              code: 'police-check',
              percentComplete: 20,
              hints: [
                {
                  detail: 'Police charge declaration is required',
                  source: { pointer: '/change_of_enrolment/declaration_has_police_charges' },
                },
                {
                  detail: 'Police conviction declaration is required',
                  source: { pointer: '/change_of_enrolment/has_convictions' },
                },

                {
                  detail: 'Detail of police charges required',
                  source: { pointer: '/change_of_enrolment/has_charges' },
                },
                {
                  detail: 'Detail of convictions is required',
                  source: { pointer: '/change_of_enrolment/has_convictions' },
                },
              ],
            },
            {
              title: 'English Proficiency',
              code: 'english-proficiency',
              percentComplete: 10,
              hints: [
                {
                  detail: 'English Qualification type is required',
                  source: { pointer: '/applicant/english_qualification/source' },
                },
                {
                  detail: 'English Qualification test type is required',
                  source: { pointer: '/applicant/english_qualification/type' },
                },
                {
                  detail: 'Other type of english proficiency is required',
                  source: { pointer: '/applicant/english_qualification/type_other' },
                },
                {
                  detail: 'Country of study is required',
                  source: { pointer: '/applicant/english_qualification/country' },
                },
              ],
            },
            {
              title: 'CUP',
              code: 'cup',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Plan after CUP is required',
                  source: { pointer: '/change_of_enrolment/cup_study_plan_after' },
                },
                {
                  detail: 'How did you hear about CUP is required',
                  source: { pointer: '/change_of_enrolment/cup_how_did_you_hear_about' },
                },
                {
                  detail: 'Personal Statement is required',
                  source: { pointer: '/change_of_enrolment/rationale' },
                },
              ],
            },
            {
              title: 'Study Options',
              code: 'study-options',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Study location is required',
                  source: { pointer: '/change_of_enrolment/study_location' },
                },
                {
                  detail: 'Studying full time question is required',
                  source: { pointer: '/change_of_enrolment/study_full_time' },
                },
              ],
            },
            {
              title: 'Teaching Application',
              code: 'teaching-application',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Disability question is required',
                  source: { pointer: '/applicant/disability' },
                },
                {
                  detail: 'Disability declaration is required',
                  source: { pointer: '/applicant/declaration_disability' },
                },
                {
                  detail: 'Disability detail question is required',
                  source: { pointer: '/applicant/disability_detail' },
                },
                {
                  detail: 'Careeer knowledge question is required',
                  source: { pointer: '/change_of_enrolment/career_knowledge' },
                },
                {
                  detail: 'Rationale question is required',
                  source: { pointer: '/change_of_enrolment/rationale' },
                },
                {
                  detail: 'Experience question is required',
                  source: { pointer: '/change_of_enrolment/experience' },
                },
                {
                  detail: 'Other qualification question is required',
                  source: { pointer: '/change_of_enrolment/other_qualification' },
                },
              ],
            },
            {
              title: 'Additional Information',
              code: 'additional-information-cac',
              percentComplete: 0,
              hints: [
                {
                  detail: 'Upload additional information document',
                  source: { pointer: '/documents/ADDITIONAL_INFO_CAC' },
                },
              ],
            },
            {
              title: 'Confirm Changes',
              code: 'confirm-coe',
              percentComplete: 0,
            },
            {
              title: 'Declaration',
              code: 'coe-declaration',
              hints: [
                {
                  detail: 'Declaration checkbox is required',
                  source: { pointer: '/change_of_enrolment/0/coe_declaration_agree' },
                },
              ],
              percentComplete: 0,
            },
            {
              title: 'Complete change of enrolment',
              code: 'complete-stage',
              percentComplete: 100,
              action_label: 'save_and_submit',
            },
          ],
        },
      ],
    },
    {
      code: 'new_student',
      displayOnSplash: true,
      title: 'New Student',
      description: 'Take a tour of the enrolment process, without the burden of submission',
      stages: [
        {
          title: 'Apply to Enrol',
          code: 'to-enrol',
          percentComplete: 90,
          tasks: [
            {
              title: 'Start my Application',
              code: 'start-my-application',
              percentComplete: 94,
              hints: [
                {
                  detail: 'First name is required',
                  source: { pointer: '/applicant/legal_name' },
                },
                {
                  detail: 'Demographic is required',
                  source: { pointer: '/applicant/demographic/citizenship' },
                },
                {
                  detail: 'Gender is required',
                  source: { pointer: '/applicant/gender' },
                },
                {
                  detail: 'At least one number is required',
                  source: { pointer: '/applicant/contact_detail/contact_number' },
                },
              ],
            },
            {
              title: 'Better Start Literacy Approach',
              code: 'mced-specific',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Advancing Successful Literacy Achievement',
              code: 'mced-asla-specific',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'University Admission',
              code: 'university-admission',
              percentComplete: 0,
            },
            {
              title: 'Offer Decision',
              code: 'offer-decision',
              percentComplete: 100,
              hints: [],
            },
            {
              title: 'CUP',
              code: 'cup',
              percentComplete: 100,
            },
            {
              title: 'Application Year',
              code: 'future-year-apply',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Evidence of Funding',
              code: 'aes-evidence-of-funds',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Review My Details',
              code: 'review-my-details',
              percentComplete: 100,
              hints: [
                {
                  detail: 'Birth date is required',
                  source: { pointer: '/applicant/birth_date' },
                },
                {
                  detail: 'Legal name is required',
                  source: { pointer: '/applicant/legal_name' },
                },
                {
                  detail: 'Gender is required',
                  source: { pointer: '/applicant/gender' },
                },
                {
                  detail: 'Citizenship category is required',
                  source: { pointer: '/applicant/demographic/citizenship' },
                },
                {
                  detail: 'Passport country is required',
                  source: { pointer: '/applicant/demographic/passport_country' },
                },
                {
                  detail: 'Will you be living in New Zealand is required',
                  source: { pointer: '/application/study_in_nz' },
                },
                {
                  detail: 'Email address is required',
                  source: { pointer: '/applicant/contact_detail/email_address' },
                },
                {
                  detail: 'Permanent address is required',
                  source: { pointer: '/applicant/contact_detail/current_address' },
                },
                {
                  detail: 'Study address is required',
                  source: { pointer: '/applicant/study_contact_detail/current_address' },
                },
                {
                  detail: "Emergency contact's email address is required",
                  source: { pointer: '/applicant/emergency_contact_detail/email_address' },
                },
                {
                  detail: "Emergency contact's first name is required",
                  source: { pointer: '/applicant/emergency_contact_detail/first_name' },
                },
                {
                  detail: "Emergency contact's last name is required",
                  source: { pointer: '/applicant/emergency_contact_detail/surname' },
                },
                {
                  detail: 'Please provide a contact number',
                  source: { pointer: '/applicant/emergency_contact_detail/mobile_number' },
                },
                {
                  detail: 'Please provide a contact number',
                  source: { pointer: '/applicant/emergency_contact_detail/landline_number' },
                },
                {
                  detail: "Emergency contact's relationship to you is required",
                  source: { pointer: '/applicant/emergency_contact_relationship' },
                },
                {
                  detail: "Emergency conatct's address is required",
                  source: { pointer: '/applicant/emergency_contact_detail/current_address' },
                },
                {
                  detail: 'Disability declaration is required',
                  source: { pointer: '/applicant/declaration_disability' },
                },
              ],
            },
            {
              title: 'Police Check',
              code: 'police-check',
              percentComplete: 20,
              hints: [
                {
                  detail: 'Police charge declaration is required',
                  source: { pointer: '/application/declaration_has_police_charges' },
                },
                {
                  detail: 'Police conviction declaration is required',
                  source: { pointer: '/application/declaration_has_police_convictions' },
                },
                {
                  detail: 'Detail of police charges required',
                  source: { pointer: '/application/declaration_police_charges' },
                },
                {
                  detail: 'Detail of convictions is required',
                  source: { pointer: '/application/declaration_police_convictions' },
                },
                {
                  detail: 'Detail of city of birth is required',
                  source: { pointer: '/applicant/identity/country_of_birth' },
                },
                {
                  detail: 'Detail of country of birth is required',
                  source: { pointer: '/applicant/identity/city_of_birth' },
                },
              ],
            },
            {
              title: 'CA Compliance',
              code: 'ca-compliance',
              percentComplete: 20,
              hints: [
                {
                  detail: 'Police charge declaration is required',
                  source: { pointer: '/application/associatedPerson/0/type' },
                },
                {
                  detail: 'Police conviction declaration is required',
                  source: { pointer: '/application/associatedPerson/0/relationship' },
                },
                {
                  detail: 'Detail of misconduct is required',
                  source: { pointer: '/application/associatedPerson/1/type' },
                },
                {
                  detail: 'Detail of police charges required',
                  source: { pointer: '/application/associatedPerson/1/relationship' },
                },
                {
                  detail: 'Detail of convictions is required',
                  source: { pointer: '/applicant/caCompliance/hasChildRemoved' },
                },
              ],
            },
            {
              title: 'Additional Information',
              code: 'additional-information-cac',
              percentComplete: 0,
              hints: [
                {
                  detail: 'Upload additional information document',
                  source: { pointer: '/documents/ADDITIONAL_INFO_CAC' },
                },
              ],
            },
            {
              title: 'Qualification Task',
              code: 'qualification',
              percentComplete: 66,
              hints: [],
            },
            {
              title: 'Study Options',
              code: 'study-options',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Study location is required',
                  source: { pointer: '/application/study_location' },
                },
                {
                  detail: 'Studying full time question is required',
                  source: { pointer: '/application/study_full_time' },
                },
              ],
            },
            {
              title: 'CIE Admission',
              code: 'cie-admission',
              percentComplete: 66,
              hints: [
                {
                  detail: 'cie qualification is required',
                  source: { pointer: '/application/cie_qualification' },
                },
                {
                  detail: 'Candidate number is required',
                  source: { pointer: '/application/cie_candidate_number' },
                },
                {
                  detail: 'Center number  is required',
                  source: { pointer: '/application/cie_center_number' },
                },
              ],
            },
            {
              title: 'IB Admission',
              code: 'ib-admission',
              percentComplete: 66,
              hints: [
                {
                  detail: 'IB qualification question is required',
                  source: { pointer: '/application/ib_qualification' },
                },
                {
                  detail: 'IB results available question is required',
                  source: { pointer: '/application/ib_results_available' },
                },
              ],
            },
            {
              title: 'University Entrance',
              code: 'ue-admission',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Type of qualification is required ',
                  source: { pointer: '/applicant/ue_qualification/type' },
                },
                {
                  detail: 'Date of qualification is required ',
                  source: { pointer: '/applicant/ue_qualification/date_attained' },
                },
              ],
            },
            {
              title: 'AES Admission',
              code: 'aes-admission',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Payment method is required',
                  source: { pointer: '/application/payment_method' },
                },
                {
                  detail: 'Scholorship provider is required ',
                  source: { pointer: '/application/scholarship_provider' },
                },
                {
                  detail: 'Scholorship Payment method is required ',
                  source: { pointer: '/application/payment_method_other' },
                },
              ],
            },
            {
              title: 'English Proficiency',
              code: 'english-proficiency',
              percentComplete: 10,
              hints: [
                {
                  detail: 'English Qualification type is required',
                  source: { pointer: '/applicant/english_qualification/source' },
                },
                {
                  detail: 'English Qualification test type is required',
                  source: { pointer: '/applicant/english_qualification/type' },
                },
                {
                  detail: 'Other type of english proficiency is required',
                  source: { pointer: '/applicant/english_qualification/type_other' },
                },
                {
                  detail: 'Country of study is required',
                  source: { pointer: '/applicant/english_qualification/country' },
                },
              ],
            },
            {
              title: 'Academic History',
              code: 'academic-history',
              percentComplete: 50,
              hints: [
                {
                  detail: 'NSN number question is required',
                  source: { pointer: '/applicant/identity/nsn_number' },
                },
                {
                  detail: 'Last school attended question is required',
                  source: { pointer: '/applicant/secondary_education_last_school_attended' },
                },
                {
                  detail: 'Last year at school question is required',
                  source: { pointer: '/applicant/secondary_education_last_year_attended' },
                },
                {
                  detail: 'Tertiary study question is required',
                  source: { pointer: '/application/declaration_tertiary_study' },
                },
                {
                  detail: 'Tertiary study exclusion question is required',
                  source: { pointer: '/application/declaration_been_excluded' },
                },
              ],
            },
            {
              title: 'Teaching Application',
              code: 'teaching-application',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Disability question is required',
                  source: { pointer: '/applicant/disability' },
                },
                {
                  detail: 'Disability declaration is required',
                  source: { pointer: '/applicant/declaration_disability' },
                },
                {
                  detail: 'Disability detail question is required',
                  source: { pointer: '/applicant/disability_detail' },
                },
                {
                  detail: 'Careeer knowledge question is required',
                  source: { pointer: '/application/career_knowledge' },
                },
                {
                  detail: 'Rationale question is required',
                  source: { pointer: '/application/rationale' },
                },
                {
                  detail: 'Experience question is required',
                  source: { pointer: '/application/experience' },
                },
                {
                  detail: 'Other qualification question is required',
                  source: { pointer: '/application/other_qualification' },
                },
              ],
            },
            {
              title: 'Financial Information',
              code: 'financial',
              percentComplete: 66,
              hints: [
                {
                  detail: 'SSL Rebate category is required',
                  source: { pointer: '/application/rebate_category' },
                },
                {
                  detail: 'Distance Student Declaration is required',
                  source: { pointer: '/application/declaration_rebate_category_distance' },
                },
                {
                  detail: 'Other rebate category is required',
                  source: { pointer: '/application/rebate_category_other' },
                },
              ],
            },
            {
              title: 'Study Intentions',
              code: 'study-intentions',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Study start date is required',
                  source: { pointer: '/application/study_start' },
                },
                {
                  detail: 'Study start date is required',
                  source: { pointer: '/application/study_start_other' },
                },
              ],
            },
            {
              title: 'Study Abroad / Incoming Exchange',
              code: 'exchange-study-abroad',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Postgrad Intentions',
              code: 'postgrad-intentions',
              percentComplete: 66,
              hints: [],
            },
            {
              title: 'CUP',
              code: 'cup',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Plan after CUP is required',
                  source: { pointer: '/application/cup_study_plan_after' },
                },
                {
                  detail: 'How did you hear about CUP is required',
                  source: { pointer: '/application/cup_how_did_you_hear_about' },
                },
                {
                  detail: 'Personal Statement is required',
                  source: { pointer: '/application/rationale' },
                },
              ],
            },
            {
              title: 'Finalise Enrolment',
              code: 'finalise',
              percentComplete: 66,
              hints: [
                {
                  detail: 'Permanent Address is required',
                  source: { pointer: '/applicant/contact_detail/current_address' },
                },
                {
                  detail: "Emergency contact's email address is required",
                  source: { pointer: '/applicant/emergency_contact_detail/email_address' },
                },
                {
                  detail: "Emergency contact's first name is required",
                  source: { pointer: '/applicant/emergency_contact_detail/first_name' },
                },
                {
                  detail: "Emergency contact's last name is required",
                  source: { pointer: '/applicant/emergency_contact_detail/surname' },
                },
                {
                  detail: 'Please provide a contact number',
                  source: { pointer: '/applicant/emergency_contact_detail/mobile_number' },
                },
                {
                  detail: 'Please provide a contact number',
                  source: { pointer: '/applicant/emergency_contact_detail/landline_number' },
                },
                {
                  detail: "Emergency contact's relationship to you is required",
                  source: { pointer: '/applicant/emergency_contact_relationship' },
                },
                {
                  detail: "Emergency contact's current address is required",
                  source: { pointer: '/applicant/emergency_contact_detail/current_address' },
                },
                {
                  detail: 'Ethnicity is required',
                  source: { pointer: '/applicant/demographic/ethnicity' },
                },
                {
                  detail: 'Iwi is required',
                  source: { pointer: '/applicant/demographic/iwi' },
                },
                {
                  detail: 'Disability declaration is required',
                  source: { pointer: '/applicant/declaration_disability' },
                },
                {
                  detail: 'Language is required',
                  source: { pointer: '/applicant/demographic/language' },
                },
                {
                  detail: 'Other language is required',
                  source: { pointer: '/applicant/demographic/language_other' },
                },
                {
                  detail: 'First in family question is required',
                  source: { pointer: '/applicant/declaration_first_in_family' },
                },
                {
                  detail: 'Did your family member graduate is required',
                  source: { pointer: '/applicant/declaration_did_family_member_graduate' },
                },
                {
                  detail: 'First year here is required',
                  source: { pointer: '/applicant/first_year_here' },
                },
                {
                  detail: 'First year tertiary is required',
                  source: { pointer: '/applicant/first_year_tertiary' },
                },
                {
                  detail: 'Occpation is required',
                  source: { pointer: '/applicant/demographic/moeMainActivity' },
                },
              ],
            },
            {
              title: 'International Student Insurance',
              code: 'int-student-insurance',
              percentComplete: 0,
              hints: [
                {
                  detail: 'Insurance provider is required',
                  source: { pointer: '/application/insurance_provider' },
                },
              ],
            },
            {
              title: 'Doctoral Study',
              code: 'doctoral-study',
              percentComplete: 0,
              hints: [
                {
                  detail: 'You must have confirmation of a supervisor before you can submit this application',
                  source: { pointer: '/application/postgrad_intentions/has_contacted_supervisor' },
                },
                {
                  detail: 'You must agree to the declaration',
                  source: { pointer: '/application/declaration_uds_information_read' },
                },
              ],
            },
            {
              title: 'Applicant Declaration',
              code: 'declaration',
              percentComplete: 0,
              hints: [
                {
                  detail: 'Declaration is required',
                  source: { pointer: '/application/declaration_agree' },
                },
              ],
            },
            {
              title: 'Complete stage one',
              code: 'complete-stage',
              percentComplete: 0,
              decorator: 'flag',
              action_label: 'save_and_submit',
            },
            {
              title: 'Course Selection',
              code: 'course-selection',
              percentComplete: 100,
              decorator: 'cap-alt',
              hints: [],
            },
            {
              title: 'Course Selection Overview',
              code: 'course-selection-overview',
              percentComplete: 100,
              decorator: 'cap-alt',
              hints: [],
            },
            {
              title: 'Declaration',
              code: 'course-declaration',
              percentComplete: 0,
            },
            {
              title: 'Courses Closed',
              code: 'courses-closed',
              descorator: 'hourglass',
              percentComplete: 0,
            },
            {
              title: 'Resolve Account',
              code: 'resolve-account',
              decorator: 'hourglass',
              percentComplete: 0,
              hints: [],
              autoResolve: true,
            },
            {
              title: 'Resolve Change of Enrolment',
              code: 'resolve-change-of-enrolment',
              decorator: 'hourglass',
              percentComplete: 0,
              hints: [],
              autoResolve: true,
            },
            {
              title: 'Preparing Application',
              code: 'resolve-application-deferred',
              decorator: 'hourglass',
              percentComplete: 0,
              hints: [],
              autoResolve: true,
            },
            {
              title: 'Accept or Decline Offer',
              code: 'accept-or-decline',
              decorator: 'hourglass',
              percentComplete: 0,
              hints: [],
              autoResolve: true,
            },
            {
              title: 'PhD Enrolment Offer',
              code: 'phd-accept-or-decline',
              percentComplete: 0,
              hints: [],
            },
          ],
        },
        {
          title: 'Other Stage',
          code: 'other',
          tasks: [
            {
              title: 'Other Stuff',
              code: 'other-stuff',
              percentComplete: 100,
              hints: [],
            },
          ],
        },
      ],
    },
    {
      code: 'star',
      displayOnSplash: true,
      title: 'STAR',
      description: 'Mock STAR application process',
      stages: [
        {
          title: 'Apply to Enrol',
          code: 'to-enrol',
          percentComplete: 90,
          tasks: [
            {
              title: 'Start my Application',
              code: 'start-my-application',
              percentComplete: 94,
              hints: [
                {
                  detail: 'First name is required',
                  source: { pointer: '/applicant/legal_name' },
                },
                {
                  detail: 'Demographic is required',
                  source: { pointer: '/applicant/demographic/citizenship' },
                },
                {
                  detail: 'Gender is required',
                  source: { pointer: '/applicant/gender' },
                },
              ],
            },
            {
              title: 'STAR',
              code: 'star-specific',
              percentComplete: 0,
              hints: [
                {
                  detail: 'Please fill in this required field.',
                  source: { pointer: '/applicant/secondary_education_last_school_attended' },
                },
                {
                  detail: 'Please fill in this required field.',
                  source: { pointer: '/application/star_coordinator/name' },
                },
                {
                  detail: 'Please fill in this required field.',
                  source: { pointer: '/application/star_coordinator/email' },
                },
                {
                  detail: 'Please fill in this required field.',
                  source: { pointer: '/application/study_location' },
                },
                {
                  detail: 'Please fill in this required field.',
                  source: { pointer: '/application/star_course_support/name' },
                },
                {
                  detail: 'Please fill in this required field.',
                  source: { pointer: '/application/star_course_support/email' },
                },
                {
                  detail: 'Please fill in this required field.',
                  source: { pointer: '/application/study_start' },
                },
                {
                  detail: 'Please upload the required file.',
                  source: { pointer: '/documents/star_consent' },
                },
              ],
            },
            {
              title: 'STAR Declaration',
              code: 'star-declaration',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Enrolment Offer',
              code: 'star-accept-or-decline',
              percentComplete: 0,
              hints: [],
            },
          ],
        },
      ],
    },
    {
      code: 'incoming_student_exchange_study_abroad',
      displayOnSplash: true,
      title: 'Incoming student exchange / study abroad',
      description: 'Mock incoming student exchange / study abroad application process',
      stages: [
        {
          title: 'Apply to Enrol',
          code: 'to-enrol',
          percentComplete: 90,
          tasks: [
            {
              title: 'Start my Application',
              code: 'start-my-application',
              percentComplete: 94,
              hints: [
                {
                  detail: 'First name is required',
                  source: { pointer: '/applicant/legal_name' },
                },
                {
                  detail: 'Demographic is required',
                  source: { pointer: '/applicant/demographic/citizenship' },
                },
                {
                  detail: 'Gender is required',
                  source: { pointer: '/applicant/gender' },
                },
              ],
            },
            {
              title: 'Incoming exchange / study abroad',
              code: 'incoming-student-exchange-study-abroad-details',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Qualification Task',
              code: 'qualification',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Applicant Declaration',
              code: 'declaration',
              percentComplete: 0,
              hints: [
                {
                  detail: 'Declaration is required',
                  source: { pointer: '/application/declaration_agree' },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      code: 'micro-credentials',
      displayOnSplash: true,
      title: 'Micro-credentials',
      description: 'Apply here to enrol in a Micro-credential',
      stages: [
        {
          title: 'Apply to Enrol',
          code: 'to-enrol',
          percentComplete: 90,
          tasks: [
            {
              title: 'Start my Application',
              code: 'start-my-application',
              percentComplete: 94,
              hints: [
                {
                  detail: 'First name is required',
                  source: { pointer: '/applicant/legal_name' },
                },
                {
                  detail: 'Demographic is required',
                  source: { pointer: '/applicant/demographic/citizenship' },
                },
                {
                  detail: 'Gender is required',
                  source: { pointer: '/applicant/gender' },
                },
              ],
            },
            {
              title: 'Better Start Literacy Approach',
              code: 'mced-specific',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Advancing Successful Literacy Achievement',
              code: 'mced-asla-specific',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Qualification',
              code: 'qualification-mc',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'University Entrance',
              code: 'academic-history-mc',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Course Selection Overview',
              code: 'course-selection-overview',
              percentComplete: 0,
              decorator: 'cap-alt',
              hints: [],
            },
            {
              title: 'Finalise Enrolment',
              code: 'finalise',
              percentComplete: 66,
              hints: [],
            },
            {
              title: 'Applicant Declaration',
              code: 'micro-credentials-declaration',
              percentComplete: 0,
              hints: [
                {
                  detail: 'Declaration is required',
                  source: { pointer: '/application/declaration_agree' },
                },
              ],
            },
          ],
        },
        {
          title: 'Review details',
          code: 'review-details',
          percentComplete: 0,
          tasks: [
            {
              title: 'Review My Details',
              code: 'review-my-details',
              percentComplete: 0,
              hints: [],
            },
          ],
        },
        {
          title: 'Courses',
          code: 'courses',
          percentComplete: 0,
          tasks: [
            {
              title: 'Course Selection Overview',
              code: 'course-selection-overview',
              percentComplete: 0,
              decorator: 'cap-alt',
              hints: [],
            },
            {
              title: 'Course Selection',
              code: 'course-selection',
              percentComplete: 0,
              decorator: 'cap-alt',
              hints: [],
            },
          ],
        },
      ],
    },
    {
      code: 'offer-decision',
      displayOnSplash: true,
      title: 'Offer decision',
      description: 'Off',
      fundingType: 'International',
      stages: [
        {
          title: 'Offer decision',
          code: 'offer-decision',
          percentComplete: 0,
          tasks: [
            {
              title: 'Offer decision',
              code: 'offer-decision',
              percentComplete: 100,
              action_label: 'save_and_continue',
            },
            {
              title: 'Confirm offer decision',
              code: 'complete-stage',
              percentComplete: 100,
              action_label: 'save_and_submit',
            },
          ],
        },
      ],
    },
    {
      code: 'defer-offer',
      displayOnSplash: true,
      title: 'Defer offer',
      description: 'Test tile for defer offer process',
      stages: [
        {
          title: 'Defer offer',
          code: 'defer-offer',
          percentComplete: 90,
          tasks: [
            {
              title: 'Defer offer',
              code: 'defer-offer',
              percentComplete: 0,
              hints: [],
            },
            {
              title: 'Complete deferral',
              code: 'complete-stage',
              percentComplete: 100,
              action_label: 'save_and_submit',
            },
          ],
        },
      ],
    },
  ],
};

export const mockProcess = new UCProcess({
  title: 'The mock process',
  code: 'mock-proc-broc',
  description: 'a mock process',
  stages: [
    new Stage({
      title: 'initial stage',
      code: '01',
      percentComplete: 50,
      path: '/mock-prock-brock/01',
      description: 'a description of the first stage',
      tasks: [
        new Task({
          title: 'first task',
          code: 'start-my-application',
          path: 'mock-prock-brock/01/01/',
          stop: false,
        }),
        new Task({
          title: 'last task',
          action_label: 'Save and Exit',
          code: 'finish',
          path: 'mock-prock-brock/01/02',
          stop: true,
        }),
      ],
    }),
    new Stage({
      title: 'second stage',
      code: '02',
      percentComplete: 0.5,
      path: '/mock-prock-brock/02',
      description: 'a description of the second stage',
      tasks: [
        new Task({
          title: 'first task',
          code: 'start-my-application',
          path: 'mock-prock-brock/01/01/',
          stop: false,
        }),
      ],
    }),
    new Stage({
      title: 'third stage',
      code: '03',
      percentComplete: 0.5,
      path: '/mock-prock-brock/03',
      description: 'a description of the third stage',
      tasks: [
        new Task({
          title: 'first task',
          code: 'start-my-application',
          path: 'mock-prock-brock/01/01/',
          stop: false,
        }),
      ],
    }),
  ],
});

export const mockPreProcess = new UCProcess({
  title: 'The mock pre-process',
  code: 'mock-preproc-broc',
  description: 'a mock process',
  stages: [
    new Stage({
      title: 'initial stage',
      code: 'get-started',
      percentComplete: 0,
      path: '/mock-preproc-broc/get-started',
      description: 'a description of the first stage',
      tasks: [
        new Task({
          title: 'Select Application Year',
          code: 'application-year',
          path: 'mock-preproc-broc/get-started/application-year',
          percentComplete: 0,
          stop: false,
        }),
      ],
    }),
  ],
});

export const mockProcessRouteFactory = () => {
  const params = new BehaviorSubject<IProcessRouteParams>({
    process: 'mock-proc-broc',
    year: '2018',
    stage: '01',
    task: 'start-my-application',
  });
  const data = new BehaviorSubject<IProcessRouteData>({
    processContext: {
      process: mockProcess,
      application: Application.deserialize(mockApplicationResponse()),
    },
  });

  const aR = {
    mock: { params, data },
    data: data.asObservable(),
    params: params.asObservable(),
  };

  return aR;
};

export const mockPreProcessRouteFactory = () => {
  const params = new BehaviorSubject<IPreProcessRouteParams>({
    process: 'mock-preproc-broc',
  });
  const data = new BehaviorSubject<IPreProcessRouteData>({
    preProcessContext: {
      process: mockPreProcess,
      availableApplicationYears: ['2018', '2019'],
    },
  });

  const aR = {
    mock: { params, data },
    data: data.asObservable(),
    params: params.asObservable(),
  };

  return aR;
};
