import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { QualificationSelection } from '@shared/components/organisms/qualification-detail-card/qualification-detail-card.component';
import { QUAL_CATEGORIES } from '@shared/constants/categories';
import strings from '@shared/constants/strings.constants';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { OfferDecision } from '@shared/models/offer-decision';
import { Qualification, QualificationCategory } from '@shared/models/qualification';
import { Task } from '@shared/models/task';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import { UCElementGroup } from '@shared/services/form-model-mapper/form';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

@Component({
  selector: 'uc-offer-decision',
  templateUrl: './offer-decision.component.html',
  styleUrls: ['./offer-decision.component.scss'],
})
export class OfferDecisionComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  private log: Logger;

  offerDecisionApplication: UCElementGroup;
  strings = strings.components.tasks.offerDecision;
  application: Application;
  applicationEnrolment: ApplicationEnrolment[];
  declarationOptions = [{ labelText: this.strings.declarationLabel, value: 'declarationFundingAcceptance' }];
  qualList: QualificationSelection[] = [];
  showAcceptSection = false;
  showDeclineSection = false;
  priorityApplicationEnrolment: ApplicationEnrolment;
  checkQualApplicationEnrolment: ApplicationEnrolment;
  qualsLoading = true;
  hasDoctoralCategory = false;

  private qualificationCategory$ = new BehaviorSubject<QualificationCategory[]>([]);

  offerDecisionForm = new UntypedFormGroup({
    acceptOffer: new UntypedFormControl(null, Validators.required),
    declineReason: new UntypedFormControl(null, Validators.required),
    declineReasonOther: new UntypedFormControl(null, Validators.required),
    declarationFundingAcceptance: new UntypedFormControl(null, Validators.required),
  });

  acceptDeclineOptions = [
    {
      labelText: this.strings.acceptText,
      value: true,
    },
    {
      labelText: this.strings.declineText,
      value: false,
    },
  ];

  constructor(
    private applicationService: ApplicationService,
    private qualificationService: QualificationService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get isDeclineReasonOther() {
    const declineReasonCode = get(this.offerDecisionForm.controls.declineReason.value, 'code');
    return declineReasonCode === 'other';
  }

  public updateFormValidity(): void {
    // no-op
  }

  ngOnInit(): void {
    this.qualificationCategory$.pipe(takeUntil(this.componentDestroyed)).subscribe((qualCategory) => {
      this.hasDoctoralCategory = qualCategory.some((category) => category.category === QUAL_CATEGORIES.DOCTORAL);
    });

    this.getOfferDecision();
    this.checkForDoctoralCategory();
    this.getApplicationEnrolmentPriority();
  }

  getOfferDecision() {
    this.offerDecisionForm.valueChanges.subscribe((res) => {
      if (res.acceptOffer !== null) {
        if (res.acceptOffer) {
          this.showAcceptSection = true;
          this.showDeclineSection = false;
        } else {
          this.showAcceptSection = false;
          this.showDeclineSection = true;
        }
      }
    });
  }

  checkForDoctoralCategory() {
    this.qualificationService.qualification
      .pipe(
        filter((qualification) => !!qualification),
        takeUntil(this.componentDestroyed),
      )
      .subscribe((qualification: Qualification) => {
        this.qualificationCategory$.next([...this.qualificationCategory$.value, ...qualification.categories]);
      });
  }

  getApplicationEnrolmentPriority() {
    // Fetch application to set application year in cache
    this.applicationService.application.subscribe();
    // Fetch highest priority active application enrolment
    this.applicationService
      .getApplicationEnrolment(this.applicationYear)
      .subscribe((appEnrolments: ApplicationEnrolment[]) => {
        if (appEnrolments) {
          const activeEnrolments = appEnrolments.filter((ae) => ae.active);
          this.priorityApplicationEnrolment = activeEnrolments.sort((a, z) => a.priority - z.priority)[0];
          this.mapFormValues();
        }
      });
  }

  mapFormValues() {
    const { acceptOffer, declarationFundingAcceptance, declineReason, declineReasonOther } =
      this.priorityApplicationEnrolment.offerDecision;
    this.offerDecisionForm.setValue({
      acceptOffer,
      declarationFundingAcceptance: !!declarationFundingAcceptance ? ['declarationFundingAcceptance'] : [],
      declineReason,
      declineReasonOther,
    });
  }

  updateApplication(year: string, internalReference: string, data: OfferDecision) {
    this.applicationService.updateOfferDecision(year, internalReference, data).subscribe(
      () => {
        this.next.emit();
      },
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating application enrolment:', err);
      },
    );
  }

  updateDeclarationFundingAcceptance(data: OfferDecision) {
    data.declarationFundingAcceptance = this.showAcceptSection ? data.declarationFundingAcceptance : null;
    data.declineReason = this.showDeclineSection ? this.offerDecisionForm.get('declineReason').value : { code: '' };
    data.declineReasonOther = this.showDeclineSection ? this.offerDecisionForm.get('declineReasonOther').value : null;
  }

  updateOfferData(fundingBoolean: boolean) {
    const offerData: OfferDecision = {
      declarationFundingAcceptance: fundingBoolean,
      acceptOffer: this.offerDecisionForm.get('acceptOffer').value,
      declineReason: this.offerDecisionForm.get('declineReason').value,
      declineReasonOther: this.offerDecisionForm.get('declineReasonOther').value,
    };
    return offerData;
  }

  update() {
    const declarationFundingVal = this.offerDecisionForm.get('declarationFundingAcceptance').value;
    const declarationFundingBoolean = !!declarationFundingVal?.[0];

    this.updateDeclarationFundingAcceptance(this.updateOfferData(declarationFundingBoolean));

    this.updateApplication(
      this.applicationYear,
      this.priorityApplicationEnrolment.internalReference,
      this.updateOfferData(declarationFundingBoolean),
    );
  }
}
