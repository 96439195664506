<uc-form-template [formGroup]="onlineAddressDetailsPage">
  <uc-form-header [title]="task?.title">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section [formGroup]="onlineAddressDetailsPage.get('contactDetails')">
    <uc-section-header [title]="strings.contactDetailsSection.header"></uc-section-header>

    <uc-subsection>
      <uc-subsection-question
        >{{ strings.contactDetailsSection.addressQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-minor-text>
        {{ strings.contactDetailsSection.addressPredictionHelp }}
      </uc-subsection-minor-text>
      <uc-form-input>
        <uc-address-selector
          testSelector="contact"
          [task]="task"
          formControlName="address"
        ></uc-address-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
  <uc-section [formGroup]="onlineAddressDetailsPage.get('contactDetails')">
    <uc-section-header [title]="strings.contactDetailsSection.studyAddressHeader">
      <p>
        {{ strings.contactDetailsSection.studyAddressQuestion }}
      </p>
      <uc-button
        testSelector="copy-address"
        class="copy-address"
        (click)="copyPermanentToStudyAddress()"
      >
        {{ strings.contactDetailsSection.copyAddressButton }}
      </uc-button>
    </uc-section-header>
    <uc-subsection>
      <uc-form-input>
        <uc-address-selector
          testSelector="study"
          [required]="false"
          formControlName="studyAddress"
        ></uc-address-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>
