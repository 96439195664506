import { Component, Input } from '@angular/core';

@Component({
  selector: 'uc-online-tab',
  templateUrl: './online-tab.component.html',
  styleUrls: ['./online-tab.component.scss'],
})
export class OnlineTabComponent {
  @Input() title: string;
  @Input() active = false;
}
