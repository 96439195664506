import { Injectable } from '@angular/core';
import Cookie from 'js-cookie';
import { filter, firstValueFrom, map, ReplaySubject } from 'rxjs';

import { Logger, LoggingService } from '../logging/logging.service';
import { IProcessRouteParams } from '../resolvers/process-resolver/process-resolver.service';
import { UserService } from '../user/user.service';
import { UserActivityStrategyBase } from './user-activity-strategy-base.service';
import { UserActivity } from './user-activity.service';

const COOKIE_PREFIX = '_uc_activity_';
// extracted by checking out the fixtures repo and running:
// $ cat <(jq '.stages[]?.code' rules/rules/{*/*,*}.json) <(jq '.code' rules/rules/stages/*.json) | sort | grep '"' | uniq
// Only needs to be accurate at time of release
const KNOWN_STAGES = [
  'accept-or-decline',
  'additional-application-details',
  'change-of-application',
  'change-of-enrolment',
  'create-application',
  'defer-offer',
  'initialise-defer-offer',
  'initialise-offer-decision',
  'offer-decision',
  'prepare-deferred-application',
  'resolve-account',
  'select-courses',
  'select-quals-courses',
  'to-enrol',
];

@Injectable()
export class CookieUserActivityStrategyService extends UserActivityStrategyBase {
  private log: Logger;
  private cookieName = new ReplaySubject<string>(1);

  constructor(logger: LoggingService, private userService: UserService) {
    super(userService);

    this.log = logger.createLogger(this);
    this.log.info('Initializing CookieUserActivityStrategyService');

    this.clearCookiesWhenInstructedTo();
    this.generateCookieNames();
    this.deserializeCookieForCurrentUser();
  }

  private clearCookiesWhenInstructedTo() {
    this.userService.cookieIdToClear
      .pipe(filter((cid) => !!cid))
      .subscribe((cid) => Cookie.remove(`${COOKIE_PREFIX}${cid}`));
  }

  private generateCookieNames() {
    this.userIdentifier.subscribe((identifier) =>
      this.cookieName.next(identifier ? `${COOKIE_PREFIX}${identifier}` : null),
    );
  }

  private deserializeCookieForCurrentUser() {
    this.cookieName
      .pipe(
        map((cookieName) => {
          if (!cookieName) {
            return null;
          }
          const cookieData = JSON.parse(Cookie.get(cookieName) || '{}');
          return CookieUserActivityStrategyService.deserialize(cookieData);
        }),
      )
      .subscribe((activity) => this.userActivity.next(activity));
  }

  private static deserialize(activity: Record<string, string[]>): UserActivity {
    const result: UserActivity = {
      taskSubmissions: [],
      viewedDocuments: activity.viewedDocuments || [],
    };
    const data = { ...activity };
    delete data.viewedDocuments;
    result.taskSubmissions = CookieUserActivityStrategyService.deserializeAllTaskSubmissions(data);
    return result;
  }

  private static deserializeAllTaskSubmissions(data: Record<string, string[]>): IProcessRouteParams[] {
    const results = [];
    for (const [processAndYear, stagesAndTasks] of Object.entries(data)) {
      const [, process, year] = processAndYear.match(/^(.*)(\d{4})$/);
      results.push(CookieUserActivityStrategyService.deserializeTaskSubmissionSet(process, year, stagesAndTasks));
    }
    return results.flat();
  }

  private static deserializeTaskSubmissionSet(
    process: string,
    year: string,
    stagesAndTasks: string[],
  ): IProcessRouteParams[] {
    const result = [];
    for (const value of stagesAndTasks) {
      const stage = KNOWN_STAGES.find((s) => value.startsWith(s));
      const task = value.slice(stage?.length);
      if ([process, year, stage, task].every((v) => !!v)) {
        result.push({ process, year, stage, task });
      }
    }
    return result;
  }

  private static serialize(activity: UserActivity): Record<string, string[]> {
    const result: Record<string, string[]> = { viewedDocuments: activity.viewedDocuments };

    for (const { process, year, stage, task } of activity.taskSubmissions) {
      const key = [process, year].join('');
      result[key] = result[key] || [];
      result[key].push([stage, task].join(''));
    }

    return result;
  }

  protected async store(data: UserActivity): Promise<void> {
    Object.keys(Cookie.get())
      .filter((cookie) => !!cookie.match(COOKIE_PREFIX))
      .forEach((match) => Cookie.remove(match));

    const cookieName = await firstValueFrom(this.cookieName);
    Cookie.set(cookieName, JSON.stringify(CookieUserActivityStrategyService.serialize(data)), { expires: 1 });

    this.userActivity.next(data);
  }
}
