/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/naming-convention */
import { camelizeKeys } from '@shared/helpers/serialization';

export enum UserTypes {
  student = 'student',
  agent = 'agent',
  staff = 'staff',
}

export enum UserSubTypes {
  ucOnlineStudent = 'ucOnline',
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum Roles {
  Student = 'student',
  Staff = 'staff',
  StaffAdmin = 'is_ouruc_admin',
  OurUCAdmin = 'is_ouruc_admin',
  ContactCentre = 'is_contact_centre',
  AgencyManager = 'can_manage_agencies',
  RunHydrate = 'can_run_hydrate',
  CanImpersonate = 'can_impersonate_users',
  CanViewApplications = 'can_read_application_standard',
  CanViewQualOccurrences = 'can_read_qualification_occurrences',
  CanMaintainQualOccurrences = 'can_maintain_qualification_occurrences',
  CanViewStudent = 'can_view_student',
  CanViewDisabilities = 'can_view_disabilities',
  CanUpdatePoliceCheck = 'can_update_police_check',
  CanReadPoliceCheck = 'can_read_police_check',
  CanViewChildrensAct = 'can_view_childrens_act_application',
  CanReadDocumentStandard = 'can_read_document_standard',
  CanUpdateApplicantStandard = 'can_update_applicant_standard',
  CanApproveQualificationAdmission = 'can_approve_qualification_admission',
  CanReadSanctionsStandard = 'can_read_sanctions_standard',
  CanUpdateDisabilities = 'can_update_disabilities',
  CanViewTeachingApplication = 'can_view_teaching_application',
  CanUpdateOnlineAssessment = 'can_update_online_assessment',
  CanMaintainUniversityAdmission = 'can_maintain_university_admission',
  CanViewChildrensActApplication = 'can_view_childrens_act_application',
  CanManageCacApplication = 'can_manage_cac_application',
  CanUpdateCacApplication = 'can_update_cac_application',
  CanUpdateAssociatedPersons = 'can_update_associated_persons',
  CanUpdateInterviewQuestions = 'can_update_interview_questions',
  CanManageConvictionsReview = 'can_manage_convictions_review',
  CanReadCoeStandard = 'can_read_coe_standard',
  CanUpdateAcademicHistory = 'can_update_academic_history',
  CanUpdateApplicationConditions = 'can_update_application_conditions',
  CanApproveCourseAdmission = 'can_approve_course_admission',
  CanApproveProgrammeOfStudy = 'can_approve_programme_of_study',
  CanManageUCOApplications = 'can_manage_uco_applications',
  CanUpdateQualificationSelections = 'can_update_qualification_selections',
  CanApproveUniversityAdmission = 'can_approve_university_admission',
  CanReadApplicationLabelsStandards = 'can_read_application_labels_standard',
  CanEditApplicationLabels = 'can_edit_application_labels',
  CanViewBusinessEvents = 'can_view_businessevents',
  CanPrepareOfferOfPlace = 'can_prepare_offer_of_place',
  CanPrepareOfferOfPlaceReturning = 'can_prepare_offer_of_place_returning',
  CanAssessQualificationEquivalence = 'can_assess_qualification_equivalence',
}

export class User {
  static MIN_PASSWORD_LENGTH = 6;

  public firstName: string;
  public lastName: string;
  public email: string;
  public imageURL?: string;
  public username?: string;
  public partiallyHydrated?: boolean;
  public studentId?: string;
  public canonicalId?: string;
  public claims?: string[];
  public preferredName?: string;

  constructor(attrs) {
    Object.assign(this, attrs);
  }

  get identifier(): string {
    // this should cover the Facebook user with no email case
    // social login is only for students who will have canonicalId
    return this.canonicalId || this.email;
  }

  get displayName(): string {
    // an agent LDAP record also includes a preferredNAme field
    if (this.preferredName) {
      return this.preferredName;
    }
    return [this.firstName, this.lastName].join(' ').trim();
  }

  static deserialize(payload): User {
    if (payload === null) {
      return null;
    }
    return new User(camelizeKeys(payload));
  }

  initials(userType: UserTypes): string {
    let initials = 'ZZ';
    if (userType === UserTypes.agent) {
      const capitalLeters = /[A-Z]/g;
      let matches = this.displayName.match(capitalLeters);
      if (matches && matches.length >= 2) {
        initials = matches[0] + matches[1];
      } else {
        const allLetters = /[a-zA-Z]/g;
        matches = this.displayName.match(allLetters);
        initials = matches && matches.length >= 2 ? matches[0] + matches[1] : initials;
      }
    } else {
      const firstInitial = (this.firstName && this.firstName.substring(0, 1)) || '';
      const lastInitial = (this.lastName && this.lastName.substring(0, 1)) || '';
      initials = firstInitial + lastInitial;
    }
    return initials;
  }
}

export class UserDetail {
  timeout: number;
  student: User;
  staff: User;
  agent: User;

  constructor(attrs) {
    Object.assign(this, attrs);
  }

  get mainUser() {
    return this.staff || this.agent || this.student;
  }

  get impersonated(): User | null {
    return (this.staff || this.agent) && this.student ? this.student : null;
  }

  static deserialize(payload) {
    if (payload === null) {
      return null;
    }

    payload.student = payload.student && User.deserialize(payload.student);
    payload.staff = payload.staff && User.deserialize(payload.staff);
    payload.agent = payload.agent && User.deserialize(payload.agent);

    return new UserDetail(payload);
  }
}
export class UserAlias {
  aliases: string[];

  constructor(attrs) {
    Object.assign(this, attrs);
  }

  static deserialize(payload): UserAlias {
    if (payload === null) {
      return null;
    }
    return new UserAlias(camelizeKeys(payload));
  }
}
