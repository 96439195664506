/* eslint-disable complexity */
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { Contact, EmergencyContact, SerializedContact, EmergencySerializedContact } from '@shared/models/contact';
import { Demographic, SerializedDemographic } from '@shared/models/demographic';
import { Identity, SerializedIdentity } from '@shared/models/identity';
import { Name, SerializedName } from '@shared/models/name';
import { ReferenceData, SerializedReferenceData } from '@shared/models/reference-data';

import {
  AttainedQualification,
  EnglishQualification,
  SerializedAttainedQualification,
  SerializedEnglishQualification,
} from './attained-qualification';
import { CACompliance, SerializedCACompliance } from './ca-compliance';
import { PersonalIdentifier, PersonalIdentifiers, SerializedPersonalIdentifier } from './personal-identifier';

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedApplicant {
  legal_name: SerializedName;
  name: SerializedName[];
  birth_date: string;
  gender: SerializedReferenceData;
  pronouns: Partial<SerializedPersonalIdentifier>[];
  contact_detail: SerializedContact;
  study_contact_detail: SerializedContact;
  emergency_contact_detail: EmergencySerializedContact;
  emergency_contact_name: SerializedName;
  emergency_contact_relationship: SerializedReferenceData;
  demographic: SerializedDemographic;
  identity: SerializedIdentity;
  declaration_disability: boolean;
  disability: SerializedReferenceData[];
  disability_detail: string;
  first_year_here: number;
  first_year_tertiary: number;
  validated_birth_date: boolean;
  declaration_first_in_family: boolean;
  declaration_did_family_member_graduate: boolean;
  date_created: string;
  ca_compliance: SerializedCACompliance;
  nz_drivers_license_number: SerializedReferenceData;
  nz_passport_number: SerializedReferenceData;
  city_of_birth: SerializedReferenceData;
  country_of_birth: SerializedReferenceData;
  validated_residency: boolean;
  tertiary_qualification: SerializedAttainedQualification[];
  english_qualification: SerializedEnglishQualification;
  secondary_qualification: SerializedAttainedQualification[];
  ue_qualification: SerializedAttainedQualification;
  attained_qualification: SerializedAttainedQualification[];
  personal_identifiers: SerializedPersonalIdentifier[];
  preferred_contact_method: string;
  uconline_marketing_emails: boolean;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class Applicant {
  public legalName: Name;
  public name: Name[];
  public birthDate: string;
  public gender: ReferenceData;
  public pronouns: Partial<PersonalIdentifier>[];
  public contactDetail: Contact;
  public studyContactDetail: Contact;
  public emergencyContactDetail: EmergencyContact;
  public emergencyContactName: Name;
  public emergencyContactRelationship: ReferenceData;
  public demographic: Demographic;
  public identity: Identity;
  public declarationDisability: boolean;
  public disability: ReferenceData[];
  public disabilityDetail: string;
  public firstYearHere: number;
  public firstYearTertiary: number;
  public validatedBirthDate: boolean;
  public declarationFirstInFamily: boolean;
  public declarationDidFamilyMemberGraduate: boolean;
  public dateCreated: string;
  public caCompliance: CACompliance;
  public nzDriversLicenseNumber: ReferenceData;
  public nzPassportNumber: ReferenceData;
  public cityOfBirth: ReferenceData;
  public countryOfBirth: ReferenceData;
  public validatedResidency: boolean;
  public tertiaryQualification: AttainedQualification[];
  public englishQualification: EnglishQualification;
  public secondaryQualification: AttainedQualification[];
  public ueQualification: AttainedQualification;
  public attainedQualification: AttainedQualification[];
  public personalIdentifiers: PersonalIdentifiers;
  public preferredContactMethod: string;
  public uconlineMarketingEmails: boolean;

  constructor(data: Partial<Applicant>) {
    Object.assign(this, data);
  }

  /* eslint-disable-next-line max-lines-per-function */
  static deserialize(payload: { applicant: Partial<SerializedApplicant> }): Applicant {
    if (payload === null) {
      return null;
    }

    const data = payload.applicant;
    const applicant: Applicant = camelizeKeys(data);

    applicant.legalName = Name.deserialize(data.legal_name);
    applicant.name = data.name.map((n) => Name.deserialize(n));
    applicant.gender = ReferenceData.deserialize(data.gender);
    applicant.pronouns = data.pronouns?.map((p) => PersonalIdentifier.deserialize(p));
    applicant.contactDetail = Contact.deserialize(data.contact_detail);
    applicant.studyContactDetail = Contact.deserialize(data.study_contact_detail);
    applicant.emergencyContactDetail = EmergencyContact.deserialize(data.emergency_contact_detail);
    applicant.emergencyContactName = Name.deserialize(data.emergency_contact_name);
    applicant.emergencyContactRelationship = ReferenceData.deserialize(data.emergency_contact_relationship);
    applicant.demographic = Demographic.deserialize(data.demographic);
    applicant.identity = Identity.deserialize(data.identity);
    applicant.caCompliance = CACompliance.deserialize(data.ca_compliance);
    applicant.disability = data.disability
      ? data.disability.map((disability) => ReferenceData.deserialize(disability))
      : [];
    applicant.ueQualification = AttainedQualification.deserialize(data.ue_qualification);
    applicant.secondaryQualification = data.secondary_qualification?.map((sq) => AttainedQualification.deserialize(sq));
    applicant.tertiaryQualification = data.tertiary_qualification?.map((sq) => AttainedQualification.deserialize(sq));
    applicant.attainedQualification = data.attained_qualification?.map((sq) => AttainedQualification.deserialize(sq));
    applicant.englishQualification = data.english_qualification
      ? EnglishQualification.deserialize(data.english_qualification)
      : new EnglishQualification();
    applicant.personalIdentifiers = new PersonalIdentifiers(
      PersonalIdentifiers.deserializeAll(data.personal_identifiers),
    );
    applicant.preferredContactMethod = data.preferred_contact_method;
    applicant.uconlineMarketingEmails = data.uconline_marketing_emails;
    return new Applicant(applicant);
  }

  /* eslint-disable-next-line max-lines-per-function */
  static serialize(instance: Applicant): { applicant: SerializedApplicant } {
    const serialized = snakeifyKeys(instance) as SerializedApplicant;

    /* eslint-disable @typescript-eslint/naming-convention */
    const serializers = {
      legal_name: () => Name.serialize(instance.legalName),
      name: () => (serialized.name ? instance.name.map((n) => Name.serialize(n)) : []),
      gender: () => ReferenceData.serialize(instance.gender),
      pronouns: () => (serialized.pronouns ? instance.pronouns.map((p) => PersonalIdentifier.serialize(p)) : []),
      contact_detail: () => Contact.serialize(instance.contactDetail),
      study_contact_detail: () => Contact.serialize(instance.studyContactDetail),
      emergency_contact_detail: () => EmergencyContact.serialize(instance.emergencyContactDetail),
      emergency_contact_name: () => Name.serialize(instance.emergencyContactName),
      emergency_contact_relationship: () => ReferenceData.serialize(instance.emergencyContactRelationship),
      demographic: () => (serialized.demographic ? Demographic.serialize(instance.demographic) : null),
      identity: () => Identity.serialize(instance.identity),
      ca_compliance: () => CACompliance.serialize(instance.caCompliance),
      disability: () => {
        return instance.disability ? instance.disability.map((disability) => ReferenceData.serialize(disability)) : [];
      },
      ue_qualification: () => AttainedQualification.serialize(instance.ueQualification),
      english_qualification: () => EnglishQualification.serialize(instance.englishQualification),
      secondary_qualification: () =>
        instance.secondaryQualification.map((sq) => AttainedQualification.serialize(sq)).filter((sq) => !!sq),
      tertiary_qualification: () =>
        instance.tertiaryQualification.map((tq) => AttainedQualification.serialize(tq)).filter((tq) => !!tq),
      attained_qualification: () =>
        instance.attainedQualification.map((tq) => AttainedQualification.serialize(tq)).filter((tq) => !!tq),
      personal_identifiers: () => PersonalIdentifiers.serializeAll(instance.personalIdentifiers),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    for (const [key, serializer] of Object.entries(serializers)) {
      if (serialized[key] !== undefined) {
        serialized[key] = serializer();
      }
    }
    return { applicant: serialized };
  }
}
