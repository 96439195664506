<uc-form-template [formGroup]="taskForm">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-task-hint class="sub-section" [task]="task" path="/application/cie_qualification"></uc-task-hint>
    <uc-yes-no
      yesOptionLabel="Yes"
      noOptionLabel="No"
      formControlName="yesNo"
      required="true"
      [questionString]="strings.sittingQuestion"
      testSelector="sitting"
    >
      <uc-yes-content>
        <uc-subsection *ngIf="taskForm.get('yesNo').value === 'show'">
          <uc-task-hint [task]="task" path="/application/cie_center_number"></uc-task-hint>
          <uc-subsection-question>{{ strings.centre }} <uc-required-field></uc-required-field> </uc-subsection-question>
          <uc-string-input testSelector="center" formControlName="centre" required="yes"> </uc-string-input>
        </uc-subsection>
        <uc-subsection *ngIf="taskForm.get('yesNo').value === 'show'">
          <uc-task-hint [task]="task" path="/application/cie_candidate_number"></uc-task-hint>
          <uc-subsection-question
            >{{ strings.candidate }}<uc-required-field></uc-required-field
          ></uc-subsection-question>
          <uc-subsection-minor-text>{{ strings.fourDigit }}</uc-subsection-minor-text>
          <uc-string-input
            testSelector="candidate"
            [isNumeric]="true"
            [maxLength]="4"
            [min]="0"
            [max]="9999"
            formControlName="candidate"
            required="yes"
          >
          </uc-string-input>
        </uc-subsection>
      </uc-yes-content>
    </uc-yes-no>
  </uc-section>
</uc-form-template>
