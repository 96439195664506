import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash-es';

import strings from '@constants/strings.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { Qualification, EnrolledQualification } from '@shared/models/qualification';

@Component({
  selector: 'uc-course-planner-header',
  templateUrl: './course-planner-header.component.html',
  styleUrls: ['./course-planner-header.component.scss'],
})
export class CoursePlannerHeaderComponent implements OnChanges {
  @Input() routeParams: { applicationYear: string; enrolmentPriority: string; qualPriority: string };
  @Input() qualification: Qualification;
  @Input() enrolledQualifications: EnrolledQualification[];
  @Input() otherQualCode: string;
  @Input() otherQualUrl: string | boolean;
  @Input() processName: string;
  @Output() resetSearchResults = new EventEmitter();

  strings = strings.components.organisms.coursePlannerHeader;
  triedNavigating = false;

  constructor(private router: Router) {}

  get applicationYear() {
    return this.routeParams.applicationYear;
  }

  get plannerHeading() {
    if (!this.qualification) {
      return null;
    }
    return `${this.qualification.title} (${this.qualification.code})`;
  }

  ngOnChanges(changes: SimpleChanges) {
    const hasChangedQual = get(changes, 'otherQualUrl.currentValue') !== get(changes, 'otherQualUrl.previousValue');
    if (hasChangedQual) {
      this.resetSearch();
    }
    const hasProcessNameChange = get(changes, 'processName.currentValue');
    if (hasProcessNameChange && this.triedNavigating) {
      this.goBack();
    }
  }

  resetSearch() {
    this.resetSearchResults.emit();
  }

  goBack() {
    if (this.processName) {
      return this.router.navigate(
        internalUrls.courseSelectionOverview(this.processName, this.routeParams.applicationYear),
      );
    }
    this.triedNavigating = true;
  }
}
