import { DateTime } from 'luxon';

import { environment } from '@environment';
import { IMPORTANT_CATEGORIES } from '@shared/constants/states.constants';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

export interface UCFileOptions {
  id: string;
  name: string;
  academicYear: string;
  type: string;
  link?: string;
  size: number;
  category: string;
  studentViewable?: boolean;
  created: Date;
}

export class UCFile {
  public id: string;
  public name: string;
  public academicYear: string;
  public type: string;
  public link?: string;
  public size: number;
  public category: string;
  public created: Date;
  public studentViewable = false;
  public deleted = false;
  public valid = false;
  public viewed?: boolean;

  static deserialize(payload: any): UCFile {
    if (payload === null) {
      return null;
    }
    const file: any = camelizeKeys(payload);

    file.created = DateTime.fromISO(file.created).toJSDate();
    file.valid = true;
    return new UCFile(file);
  }

  static serialize(instance: any): UCFile {
    const clone = { ...instance };

    clone.studentViewable = false;
    return snakeifyKeys(clone);
  }

  constructor(creationObject: UCFileOptions) {
    Object.assign(this, creationObject);
  }

  get externalUrl(): string {
    return `${environment.apiRoot}/document/${this.academicYear}/${this.category}/${this.id}`;
  }

  get staffDocumentLink(): string {
    return `${environment.apiRoot}/document/staff/detail/${this.id}`;
  }

  get stringSize() {
    if (this.size > 1000000000) {
      return `${Math.round(this.size / 1000000000)}GB`;
    } else if (this.size > 1000000) {
      return `${Math.round(this.size / 1000000)}MB`;
    } else if (this.size > 1000) {
      return `${Math.round(this.size / 1000)}KB`;
    } else {
      return '<1KB';
    }
  }
}

export enum UCDocumentType {
  applicant = 'applicant',
  application = 'application',
}

export class Category {
  public code: string;
  public description: string;
  public documentType: string;
  public studentUploadable?: boolean;
  public studentViewable? = false;

  static deserialize(payload: any): Category {
    if (payload === null) {
      return null;
    }
    const category: any = camelizeKeys(payload);

    return new Category(category);
  }

  static serialize(instance: any): any {
    const clone = { ...instance };

    clone.studentViewable = false;
    return snakeifyKeys(clone);
  }

  constructor(obj) {
    Object.assign(this, obj);
  }
}

export const importantCategories = [
  IMPORTANT_CATEGORIES.OFFER_PLACE_LETTER,
  IMPORTANT_CATEGORIES.OFFER_PLACE_COND,
  IMPORTANT_CATEGORIES.OFFER_LETTER,
  IMPORTANT_CATEGORIES.NOC_LETTER,
  IMPORTANT_CATEGORIES.UC_OFFER_PLACE,
  IMPORTANT_CATEGORIES.UC_OFFER_PLACE_COND,
  IMPORTANT_CATEGORIES.UC_INCOMPLETE,
  IMPORTANT_CATEGORIES.APP_UPDATE,
  IMPORTANT_CATEGORIES.WELCOME_TO_UC_LETTER,
];
