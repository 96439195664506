<div *ngIf="showProcessInfo" class="panel">
  <div class="coe-info-wrapper">
    <h2>{{ panelContent.title }}</h2>
    <div class="text-wrapper" [innerHTML]="panelContent.body"></div>
    <div class="button-wrapper" *ngIf="enrolmentChangeInProgress || hasOfferDecisionInProgress">
      <button
        [attr.data-cy]="'resume'"
        uc-button
        class="inline-icon-btn action-btn"
        [isLoading]="isLoading"
        (click)="resumeHandler()"
      >
        {{ panelContent.resume }}
      </button>
      <div class="cancel-btn" (click)="showCancelModal()">
        {{ panelContent.delete }}
      </div>
    </div>
  </div>
  <div *ngIf="showDiff" class="coe-diff-wrapper">
    <uc-coe-diff [enrolmentChange]="fullEnrolmentChange" [showErrorMsg]="false" [minimalView]="true"></uc-coe-diff>
  </div>
</div>
