import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { get, uniqBy } from 'lodash-es';

import strings from '@constants/strings.constants';
import { Category } from '@shared/models/uc-file';
import { DocumentService } from '@shared/services/document/document.service';
import { FileUploadServiceEvent } from '@shared/services/file-upload/file-upload.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-online-category-file-upload',
  templateUrl: './online-category-file-upload.component.html',
  styleUrls: ['./online-category-file-upload.component.scss'],
})
export class OnlineCategoryFileUploadComponent implements OnInit {
  @ViewChild('uploadEl') uploadEl: ElementRef;

  categoryOptions: { labelText: string; value: string }[] = [{ labelText: 'Identity document', value: 'IDENTITY' }];
  uploadedFiles: { id: string; categoryCode: string }[] = [];
  categories: Category[];
  uploadForm: UntypedFormGroup;
  currentYear = String(new Date().getFullYear());

  showUploadButton = true;
  required = true;

  strings = strings.components.organisms.categoryFileUpload;
  fileUploadStrings = strings.components.organisms.fileUpload;

  showMessage = false;
  messageType: string;
  message: string;

  displaySpinner = false;

  log: Logger;

  constructor(
    private documentService: DocumentService,
    private formBuilder: UntypedFormBuilder,
    private processService: ProcessService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get categoryCodeControl(): UntypedFormControl {
    return this.uploadForm.get('categoryCode') as UntypedFormControl;
  }

  get uniqueUploadedCategories(): { id: string; categoryCode: string }[] {
    return uniqBy(this.uploadedFiles, 'categoryCode');
  }

  ngOnInit() {
    this.createForm();
    this.categoryCodeControl.valueChanges.subscribe((categoryCode: string) => {
      if (categoryCode === 'null') {
        this.categoryCodeControl.setValue('IDENTITY');
        this.categoryCodeControl.updateValueAndValidity();
      }
    });

    this.documentService.getCategories().subscribe((categories) => {
      this.categories = categories;
    });
  }

  private createForm() {
    this.uploadForm = this.formBuilder.group({
      categoryCode: ['IDENTITY', Validators.required],
    });
  }

  uploadFile(file: File) {
    let fileId: string;

    return this.documentService.uploadFileForCategory(file, this.categoryCodeControl.value, this.currentYear).subscribe(
      (uploadEvent) => {
        fileId = uploadEvent.id;
        this.showMessage = false;
        this.maintainUploadedFiles(uploadEvent);
        this.setSuccessMessageAndNotification(uploadEvent);
      },
      (err) => {
        this.uploadFileErrorHandler(err, fileId);
      },
    );
  }

  private uploadFileErrorHandler(err, fileId) {
    const message = err && get(strings, err.code, this.fileUploadStrings.otherError);
    this.setMessage(message, 'error');
    this.log.error('File upload error:', err);
    if (fileId) {
      this.uploadedFiles = this.uploadedFiles.filter((el) => el.id !== fileId);
    }
  }

  private setSuccessMessageAndNotification(uploadEvent: FileUploadServiceEvent) {
    if (uploadEvent.progress === 100) {
      this.setMessage(this.strings.successfulUpload, 'success');
      this.processService.notifyDocumentUpload(uploadEvent.file.id.toString(), this.currentYear).subscribe();
    }
  }

  private maintainUploadedFiles(uploadEvent: FileUploadServiceEvent) {
    if (!this.uploadedFiles.find((uf) => uf.id === uploadEvent.file.id)) {
      this.uploadedFiles = this.uploadedFiles.filter((el) => el.id !== uploadEvent.id);
      this.uploadedFiles.push({ id: uploadEvent.file.id, categoryCode: uploadEvent.file.category });
    }
  }

  abortUpload(doc: FileUploadServiceEvent) {
    this.documentService.abortUpload(doc);
  }

  onFileChange() {
    const input: HTMLInputElement = this.uploadEl.nativeElement;
    if (!input.files[0]) {
      this.log.warn('onFileChange called without file');
    }
    this.uploadFile(input.files[0]);
  }

  setNull() {
    const input: HTMLInputElement = this.uploadEl.nativeElement;
    input.value = null;
  }

  addFile() {
    this.uploadEl.nativeElement.click();
  }

  setMessage(message: string, type: string) {
    this.messageType = type;
    this.message = message;
    this.showMessage = true;
    if (type === 'success') {
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  uploadedFileUniqueProperty(_, item) {
    return item.id;
  }
}
