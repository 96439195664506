import { UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms';
import { mapValues } from 'lodash-es';
import { Observable, of, throwError } from 'rxjs';
import { retryWhen, tap, delay, map } from 'rxjs/operators';

/**
 * Performs a deep clone of an object, removing any attributes
 * that have functions as values. Useful when trying to prevent modification
 * of the original object.
 *
 * Use this over `Object.assign({}, obj)` when the original object has a long lifecycle
 * and you don't need functions on the object.
 *
 * @param obj
 */
export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const touchAll = (formGroup: UntypedFormGroup | UntypedFormArray): void => {
  mapValues(formGroup.controls, (c: AbstractControl) => {
    if (c instanceof UntypedFormGroup || c instanceof UntypedFormArray) {
      touchAll(c);
    } else {
      c.markAsDirty({ onlySelf: false });
    }
  });
};

export const retryCall = () => {
  let i = 2;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (ob: Observable<any>) =>
    ob.pipe(
      retryWhen((errors) => {
        return errors.pipe(
          map((e) => {
            if (e === 'Retry') {
              return throwError(e);
            } else {
              return of(e);
            }
          }),
          tap(() => (i = i++)),
          delay(i * 1.5 * 1000),
        );
      }),
    );
};

export const humanize = (s: string, ifNull = 'None'): string => {
  const result = s?.replace(new RegExp('_', 'g'), ' ') || ifNull;
  return result?.[0].toUpperCase() + result?.slice(1).toLowerCase();
};
