<uc-form-template [formGroup]="doctoralStudyPage">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header [title]="postgradStrings.goalsAspirationsTitle">
      <p>
        {{ postgradStrings.goalsAspirationsDescription }}
      </p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        path="/postgrad_intentions/why_qualification_chosen"
      ></uc-task-hint>
      <uc-subsection-question required="true">{{ postgradStrings.whyIsItInteresting }}</uc-subsection-question>
      <uc-form-input>
        <uc-text-area testSelector="doc-why" formControlName="whyThisQualification"></uc-text-area>
      </uc-form-input>
    </uc-subsection>
    <uc-subsection>
      <uc-task-hint [task]="task" [model]="hintPath" path="/postgrad_intentions/what_are_your_goals"></uc-task-hint>
      <uc-subsection-question required="true">{{ postgradStrings.whatWillYouDoNext }}</uc-subsection-question>
      <uc-form-input>
        <uc-text-area testSelector="doc-next" formControlName="whatNext"></uc-text-area>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section>
    <uc-section-header [title]="postgradStrings.cvTitle">
      <p>
        {{ postgradStrings.cvRequestText }}
      </p>
      <p>
        {{ postgradStrings.cvProofExplain }}
      </p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint [task]="task" path="/documents/cv"></uc-task-hint>
      <uc-file-upload
        [title]="postgradStrings.uploadQuestion"
        categoryCode="CV"
        [year]="applicationYear"
        usedInTask="true"
        required="true"
      ></uc-file-upload>
    </uc-subsection>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ strings.topicAndSupervisorHeader }}"> </uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" [model]="hintPath" path="/postgrad_intentions/area_of_research"></uc-task-hint>
      <uc-subsection-question required="true">
        {{ strings.proposedTopic }}
      </uc-subsection-question>
      <uc-string-input testSelector="research-area" formControlName="areaOfResearch"></uc-string-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint [task]="task" [model]="hintPath" path="/postgrad_intentions/study_department"></uc-task-hint>
      <uc-subsection-question required="true">
        {{ strings.departmentSchoolOrResearchCentre }}
      </uc-subsection-question>
      <uc-string-input testSelector="study-department" formControlName="studyDepartment"></uc-string-input>
    </uc-subsection>

    <uc-yes-no
      yesOptionLabel="Yes"
      noOptionLabel="No"
      formControlName="hasContactedSupervisor"
      required="true"
      questionString="{{ strings.contactWithSupervisor }}"
      [extraDescription]="strings.hasSupervisorGuidance"
      conditionalDescription="Yes"
      [task]="task"
      [model]="hintPath"
      path="/postgrad_intentions/has_contacted_supervisor"
      testSelector="contacted-supervisor"
    >
      <uc-yes-content>
        <uc-subsection>
          <uc-task-hint [task]="task" [model]="hintPath" path="/postgrad_intentions/supervisor_name"></uc-task-hint>
          <uc-subsection-question required="yes">
            {{ strings.supervisorName }}
          </uc-subsection-question>
          <uc-string-input testSelector="supervisor" formControlName="supervisorName"></uc-string-input>
        </uc-subsection>
      </uc-yes-content>
      <uc-no-content>
        <uc-subsection>
          <uc-subsection-minor-text [innerHTML]="strings.noSupervisorGuidance"> </uc-subsection-minor-text>
        </uc-subsection>
      </uc-no-content>
    </uc-yes-no>
  </uc-section>

  <uc-section>
    <uc-section-header [title]="strings.evidenceOfSupervisionTitle">
      <p>
        {{ strings.evidenceOfSupervisionDescription }}
      </p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint [task]="task" path="/documents/supervision evidence"></uc-task-hint>
      <uc-file-upload
        [title]="strings.evidenceOfSupervisionQuestion"
        categoryCode="SUPERVISION_EVIDENCE"
        [year]="applicationYear"
        usedInTask="true"
        required="true"
      ></uc-file-upload>
    </uc-subsection>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ strings.transferringHeader }}"> </uc-section-header>
    <uc-yes-no
      yesOptionLabel="Yes"
      noOptionLabel="No"
      required="true"
      questionString="{{ strings.transferringFromMasters }}"
      [task]="task"
      testSelector="transferring"
    >
      <uc-yes-content>
        <uc-subsection>
          <uc-subsection-minor-text [innerHTML]="strings.transferringGuidance"> </uc-subsection-minor-text>
        </uc-subsection>
      </uc-yes-content>
    </uc-yes-no>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ strings.studyOptionsHeader }}"> </uc-section-header>

    <uc-yes-no
      yesOptionLabel="Yes"
      noOptionLabel="No"
      formControlName="studyLocation"
      required="true"
      questionString="{{ strings.resideAwayFromChristchurch }}"
      [task]="task"
      [model]="hintPath"
      path="/study_location"
      testSelector="location"
    >
      <uc-yes-content>
        <uc-yes-no
          yesOptionLabel="Yes"
          noOptionLabel="No"
          formControlName="beginStudyAway"
          required="true"
          [questionString]="strings.beginResideAway"
          [task]="task"
          [model]="hintPath"
          path="/postgrad_intentions/begin_study_away"
          testSelector="study-away"
        >
          <uc-yes-content>
            <uc-subsection>
              <uc-task-hint
                [task]="task"
                [model]="hintPath"
                path="/postgrad_intentions/move_to_christchurch_after"
              ></uc-task-hint>
              <uc-subsection-question required="true">
                {{ strings.whenMoveAway }}
              </uc-subsection-question>
              <uc-string-input testSelector="move-chch" formControlName="moveToChristchurchAfter"></uc-string-input>
            </uc-subsection>
          </uc-yes-content>
        </uc-yes-no>
      </uc-yes-content>
    </uc-yes-no>

    <uc-yes-no
      yesOptionLabel="Yes"
      noOptionLabel="No"
      formControlName="requireStudyAway"
      required="true"
      [questionString]="strings.timeAway"
      [task]="task"
      [model]="hintPath"
      path="/postgrad_intentions/require_study_away"
      testSelector="time-away"
    >
      <uc-yes-content>
        <uc-subsection>
          <uc-subsection-question [innerHTML]="strings.timeAwayGuidance"> </uc-subsection-question>
        </uc-subsection>
      </uc-yes-content>
    </uc-yes-no>
  </uc-section>

  <uc-section>
    <uc-section-header title="{{ strings.fundingHeader }}"> </uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" [model]="hintPath" path="/payment_method"></uc-task-hint>
      <uc-subsection-question required="true">
        {{ strings.fundingMethod }}
      </uc-subsection-question>
      <uc-radio-input-group formControlName="fundingMethod" groupName="phd-funding" type="payment_method">
      </uc-radio-input-group>
    </uc-subsection>

    <uc-subsection *ngIf="applyingForScholarship">
      <uc-subsection-minor-text class="declaration" [innerHTML]="strings.scholarshipLink"> </uc-subsection-minor-text>
      <uc-task-hint [task]="task" [model]="hintPath" path="/declaration_uds_information_read"></uc-task-hint>
      <uc-form-field-label>{{ strings.declarationAgree }}<uc-required-field></uc-required-field></uc-form-field-label>
      <uc-checkbox-input-group
        groupName="dec-check"
        [options]="udsDeclarationOptions"
        [formControl]="doctoralStudyPage.get('udsDeclarationCheck')"
      >
      </uc-checkbox-input-group>
    </uc-subsection>

    <uc-subsection *ngIf="scholarshipOffered">
      <uc-task-hint [task]="task" [model]="hintPath" path="/scholarship_name"></uc-task-hint>
      <uc-form-input>
        <uc-subsection-question required="true">
          {{ strings.scholarshipName }}
        </uc-subsection-question>
        <uc-string-input
          testSelector="scholarship-name"
          class="input-medium"
          formControlName="scholarshipName"
          required="yes"
        >
        </uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection *ngIf="scholarshipOffered">
      <uc-task-hint [task]="task" [model]="hintPath" path="/scholarship_provider"></uc-task-hint>
      <uc-form-input>
        <uc-subsection-question required="true">
          {{ strings.scholarshipProvider }}
        </uc-subsection-question>
        <uc-string-input
          testSelector="scholarship-provider"
          class="input-medium"
          formControlName="scholarshipProvider"
          required="yes"
        >
        </uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection *ngIf="isPaymentMethodOther">
      <uc-task-hint [task]="task" [model]="hintPath" path="/payment_method_other"></uc-task-hint>
      <uc-form-input>
        <uc-form-field-label>
          <uc-text-area testSelector="doc-pay-other" formControlName="paymentMethodOther"></uc-text-area>
        </uc-form-field-label>
      </uc-form-input>
    </uc-subsection>
  </uc-section>

  <uc-section *ngIf="isInternational">
    <uc-section-header [title]="strings.thesisPaperTitle">
      <p>
        {{ strings.thesisPaperDescription }}
      </p>
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint [task]="task" path="/documents/thesis researchpaper"></uc-task-hint>
      <uc-file-upload
        [title]="strings.thesisPaperQuestion"
        categoryCode="THESIS_RESEARCHPAPER"
        [year]="applicationYear"
        usedInTask="true"
        required="true"
      ></uc-file-upload>
    </uc-subsection>
  </uc-section>
</uc-form-template>
