<div class="validation-message" *ngIf="message && shouldDisplay" [attr.id]="fragment">
  <uc-icon
    class="validation-message__icon"
    iconType="alert"
    iconWidth="16"
    iconHeight="16"
    iconFill="$colorAmber">
  </uc-icon>
  <span class="validation-message__text">{{message}}</span>
</div>
