import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData } from './reference-data';

export class InterviewAssessment {
  interviewState: ReferenceData;
  interviewLocation: ReferenceData;
  interviewDate: string;
  interviewer: string;
  specialistTeachingSubjectWorkshopS1: ReferenceData;
  specialistTeachingSubjectWorkshopS2: ReferenceData;
  additionalTeachingSubjectWorkshopS1: ReferenceData;
  additionalTeachingSubjectWorkshopS2: ReferenceData;
  electiveCurriculumSubjectWorkshopS1: ReferenceData;
  electiveCurriculumSubjectWorkshopS2: ReferenceData;
  electiveCurriculumSubjectOther: ReferenceData;
  teachingSubjectUpdate: string;
  interviewComments: string;
  recommendation: ReferenceData;
  conditionOFOffer: string;
  declineReasons: string;

  constructor(data) {
    Object.assign(this, data);
  }

  static deserialize(payload) {
    if (payload === null) {
      return {};
    }

    const interviewAssessment = camelizeKeys(payload);
    interviewAssessment.interviewState = ReferenceData.deserialize(interviewAssessment.interviewState);
    interviewAssessment.interviewLocation = ReferenceData.deserialize(interviewAssessment.interviewLocation);
    interviewAssessment.specialistTeachingSubjectWorkshopS1 = ReferenceData.deserialize(
      interviewAssessment.specialistTeachingSubjectWorkshopS1,
    );
    interviewAssessment.specialistTeachingSubjectWorkshopS2 = ReferenceData.deserialize(
      interviewAssessment.specialistTeachingSubjectWorkshopS2,
    );
    interviewAssessment.additionalTeachingSubjectWorkshopS1 = ReferenceData.deserialize(
      interviewAssessment.additionalTeachingSubjectWorkshopS1,
    );
    interviewAssessment.additionalTeachingSubjectWorkshopS2 = ReferenceData.deserialize(
      interviewAssessment.additionalTeachingSubjectWorkshopS2,
    );
    interviewAssessment.electiveCurriculumSubjectWorkshopS1 = ReferenceData.deserialize(
      interviewAssessment.electiveCurriculumSubjectWorkshopS1,
    );
    interviewAssessment.electiveCurriculumSubjectWorkshopS2 = ReferenceData.deserialize(
      interviewAssessment.electiveCurriculumSubjectWorkshopS2,
    );
    interviewAssessment.electiveCurriculumSubjectOther = ReferenceData.deserialize(
      interviewAssessment.electiveCurriculumSubjectOther,
    );
    interviewAssessment.recommendation = ReferenceData.deserialize(interviewAssessment.recommendation);

    return new InterviewAssessment(interviewAssessment);
  }

  static serialize(instance: any) {
    const clone = Object.assign({}, snakeifyKeys(instance));
    Object.keys(clone).forEach((key) => {
      if (!!key.match(/workshop_s_/)) {
        const newKey = key.replace('workshop_s_', 'workshop_s');
        clone[newKey] = clone[key];
        delete clone[key];
      }
    });
    return clone;
  }
}
