import { Component, HostListener, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-online-task',
  templateUrl: './online-task.component.html',
  styleUrls: ['./online-task.component.scss'],
})
export class OnlineTaskComponent implements OnInit {
  @Input() task: Task;
  @Input() stage: Stage;
  @Output() triggerClosePanel: EventEmitter<unknown> = new EventEmitter();

  queryParams;
  fragment;

  constructor(private route: ActivatedRoute) {}

  get isActive() {
    return this.task && location.pathname.endsWith(this.task.path);
  }

  get isPrevTaskComplete() {
    const taskCodes = this.stage?.tasks.map((t) => t.code);
    const taskIndex = taskCodes?.indexOf(this.task.code);
    const firstIncompleteTaskIndex = taskCodes?.indexOf(this.stage.firstIncompleteTask?.code);
    return this.stage?.firstIncompleteTask === null ? true : taskIndex <= firstIncompleteTaskIndex;
  }

  ngOnInit() {
    combineLatest([this.route.queryParams, this.route.fragment]).subscribe(([queryParams, fragment]) => {
      this.queryParams = queryParams;
      this.fragment = fragment;
    });
  }

  @HostListener('click', ['$event'])
  preventClickThrough(e: MouseEvent) {
    e.stopPropagation();
    this.triggerClosePanel.emit();
  }
}
