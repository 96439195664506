import { Component, EventEmitter, OnDestroy, OnInit, Output, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Modal } from 'ngx-modialog-11/plugins/bootstrap';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { environment } from '@environment';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';
import { UCError } from '@shared/models/errors';
import { User } from '@shared/models/user';
import { DSHttpError } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

const ucEmailRegex = /@uclive\.ac\.nz$/i;
const isEmailRegex = /@.+\..+$/;

@Component({
  selector: 'uc-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Input() isRestricted = false;
  @Input() appName: string;
  @Input() ldapOnly = false;
  @Input() duplicateEmail: string;

  @Output() goToCreate = new EventEmitter();

  @ViewChild('firebaseRestrictedModal') firebaseRestrictedModal: ConfirmationModalComponent;
  @ViewChild('passwordResetCompleteModal') passwordResetCompleteModal: ConfirmationModalComponent;

  environment = environment;
  log: Logger;

  externalUrls = externalUrls;
  loginForm: UntypedFormGroup;
  displaySpinner = false;
  private userSubscription: Subscription;

  loginFormStrings = strings.components.organisms.loginForm;
  labelStrings = strings.labels.user;

  errorMessages: UCError[] = [];

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private modal: Modal,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
    this.createForm();
  }

  get bannerMessage(): string {
    return environment.loginMessage?.[this.appName] || environment.systemMessage?.[this.appName];
  }

  private isUCEmailOrUsername(email: string) {
    return this.isUCEmail(email) || !email.match(isEmailRegex);
  }

  private isUCEmail(email: string) {
    return email.match(ucEmailRegex);
  }

  ngOnInit() {
    this.log.info('ngOnInit');

    this.userSubscription = this.userService.currentUser.subscribe((user) => {
      if (user) {
        this.router.navigate(internalUrls.dashboard);
      }
    });
    const ignoreCodes = [
      // If user not verified, we display a modal to resend the confirmation email
      'auth.emailUnverified',
      // Occurs when the user opens multiple social login popups
      'auth.popupCancelled',
      'auth.popupClosedByUser',
    ];
    this.userService.validationError
      .pipe(
        tap(() => (this.displaySpinner = false)),
        filter((err) => ignoreCodes.indexOf(err.code) === -1),
      )
      .subscribe((err) => {
        this.errorMessages = [err];
      });

    if (this.duplicateEmail) {
      this.loginForm.get('email').setValue(this.duplicateEmail);
    }
  }

  stripInvalidCharacters() {
    setTimeout(() => {
      const val = this.loginForm.get('email').value;
      if (val && val.match(/[^ a-zA-Z0-9]/gi) && this.ldapOnly) {
        this.loginForm.get('email').setValue(val.replace(/[^ a-zA-Z0-9]/gi, ''));
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    this.displaySpinner = false;
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(User.MIN_PASSWORD_LENGTH)]],
    });
  }

  goToAccountCreate() {
    this.goToCreate.emit();
  }

  loginByEmail() {
    this.errorMessages = [];
    if (this.loginForm.valid && !this.displaySpinner) {
      this.displaySpinner = true;
      const { email, password } = this.loginForm.value;

      let loginPromise: Promise<unknown>;
      if (this.isUCEmailOrUsername(email)) {
        loginPromise = this.userService.loginUC(email.split('@')[0], password).catch((err: DSHttpError) => {
          // Only try firebase if email login, not username login
          if (this.isUCEmail(email) && err.status === 403) {
            // Whatever the ldap code is, which may be different from a failed login.
            return this.userService.loginFirebaseEmail(email, password);
          } else {
            this.userService.userError$.next(err);
          }
        });
      } else {
        loginPromise = this.userService.loginFirebaseEmail(email, password);
      }
      loginPromise.catch((err) => this.log.debug('login failed:', err));
    }
  }

  showFirebaseRestrictedModal() {
    return this.firebaseRestrictedModal.open();
  }

  async triggerForgotPassword() {
    if (this.isRestricted) {
      return this.showFirebaseRestrictedModal();
    }

    const modal = this.modal
      .prompt()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .title(this.loginFormStrings.resetPasswordForm.title)
      .body(this.loginFormStrings.resetPasswordForm.body)
      .placeholder(this.loginFormStrings.resetPasswordForm.placeholder)
      .okBtn(this.loginFormStrings.resetPasswordForm.ok)
      .cancelBtn(this.loginFormStrings.resetPasswordForm.cancel)
      .cancelBtnClass('cancel-btn')
      .open();

    try {
      const email = await modal.result;
      const resetResult = await this.userService.resetEmail(email);
      this.passwordResetCompleteModal.message = this.loginFormStrings.confirmReset[resetResult.state];
      this.passwordResetCompleteModal.open();
    } catch (error) {
      this.log.error('could not send reset password link', error);
    } finally {
      document.body.classList.remove('modal-open');
    }
  }
}
