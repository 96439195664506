/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, map, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { APPLICATION_ACTIONS } from '@shared/constants/actions.constants';
import { APPLICATION_CREATION_SOURCE } from '@shared/constants/states.constants';
import { Application, ApplicationSummary } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { BackgroundCheck } from '@shared/models/background-check';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { Condition, ConditionUpdate } from '@shared/models/condition';
import { EnrolledCourse } from '@shared/models/course';
import { SummaryAction } from '@shared/models/enrolment-summary';
import { UCError } from '@shared/models/errors';
import { OfferDecision } from '@shared/models/offer-decision';
import { ReferenceData } from '@shared/models/reference-data';
import { mockData as mockApplicationEnrolmentResponse } from '@shared/services/application/application-enrolment.data.mock';
import { mockData as mockApplicationResponse } from '@shared/services/application/application.data.mock';
import { mockData as mockSingularApplicationEnrolmentResponse } from '@shared/services/application/singular-application-enrolment.data.mock';
import { CacheManagementService, CacheObjects } from '@shared/services/cache-management/cache-management.service';
import { DataService, IDSRequestOpts, DSHttpError, UCErrorCodes } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

import { mockData as mockApplicationSummaryStaffResponse } from './application-summary-staff.data.mock';
import { mockData as conditionsMockData } from '../condition/condition.data.mock';

export abstract class AbstractService {
  abstract get application(): Observable<Application | ChangeOfEnrolment>;
  abstract get staffConditionsForStudent(): Observable<Condition[]>;
  public abstract update(application: Application | ChangeOfEnrolment): Observable<Application | ChangeOfEnrolment>;
  abstract getApplicationEnrolment(year?: string): Observable<ApplicationEnrolment[] | ChangeOfEnrolment>;
  abstract getApplicationForStaff(
    canonicalId: string,
    year: string,
    coeInternalReference?: string,
  ): Observable<Application | ChangeOfEnrolment>;
  abstract getApplicationEnrolmentForStaff(
    canonicalId: string,
    year: string,
  ): Observable<ApplicationEnrolment[] | ChangeOfEnrolment>;
  abstract getConditionsForStaff(
    canonicalId: string,
    year: string,
    coeInternalReference?: string,
  ): Observable<Condition[]>;
  abstract updateConditionForStaff(
    canonicalId: string,
    condition: Condition,
    coeInternalReference?: string,
  ): Observable<unknown>;
}

export abstract class AbstractApplicationService extends AbstractService {
  protected serviceUrl = `${environment.apiRoot}/application/`;
  protected staffUrl = `${this.serviceUrl}staff/`;

  public readonly application$ = new BehaviorSubject<Application>(null);
  public readonly applications$ = new BehaviorSubject<ApplicationSummary[]>([]);
  public readonly applicationEnrolments$ = new BehaviorSubject<ApplicationEnrolment[]>([]);
  public readonly activeApplicationEnrolment$ = new BehaviorSubject<ApplicationEnrolment>(null);
  protected error$ = new Subject<UCError>();
  public conditions$ = new BehaviorSubject<Condition[]>([]);

  abstract get currentApplicationYear(): string;

  get application(): Observable<Application | ChangeOfEnrolment> {
    return this.application$.asObservable();
  }

  get currentApplication(): Application {
    return this.application$.value;
  }

  get applicationSummaries(): Observable<ApplicationSummary[]> {
    return this.applications$.asObservable();
  }

  get applicationEnrolments(): Observable<ApplicationEnrolment[]> {
    return this.applicationEnrolments$.asObservable();
  }

  get activeApplicationEnrolment(): Observable<ApplicationEnrolment> {
    return this.activeApplicationEnrolment$.asObservable();
  }

  get staffConditionsForStudent(): Observable<Condition[]> {
    return this.conditions$.asObservable();
  }

  get applicationError(): Observable<UCError> {
    return this.error$.asObservable();
  }

  protected clearCache(): void {
    this.application$.next(null);
    this.applications$.next([]);
  }

  public abstract getApplication(year: string): Observable<Application>;
  public abstract getApplications(): Observable<ApplicationSummary[]>;
  public abstract createApplication(application: Application): Observable<Application>;
  public abstract updateApplication(application: Application): Observable<Application>;
  public abstract deleteApplication(year: string): Observable<null>;

  protected getRequestOptions(options?: Record<string, unknown>): IDSRequestOpts {
    return {
      success$: this.application$,
      error$: this.error$,
      deserialize: Application.deserialize,
      ...options,
    };
  }
}

@Injectable()
export class ApplicationService extends AbstractApplicationService {
  log: Logger;

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
    private cacheService: CacheManagementService,
  ) {
    super();
    this.log = loggingService.createLogger(this);

    this.cacheService.shouldClearCache
      .pipe(filter((options) => options.target === CacheObjects.ALL))
      .subscribe(() => this.clearCache());
  }

  /**
   * return the current application year, otherwise return the default '2018' year
   */
  get currentApplicationYear(): string {
    const academicYear = get(this.application$.value, 'academicYear.code') as string;
    if (!academicYear) {
      throw new Error('No current application found');
    }
    return academicYear;
  }

  update(application: Application | ChangeOfEnrolment): Observable<Application | ChangeOfEnrolment> {
    return this.updateApplication(application as Application);
  }

  /**
   * Makes a request to the application microservice to retrieve an application
   * The request requires the currentUser's token to be in the Auth header
   * of the request. The Application microservice will use that token to retrieve
   * the associated application from a user.
   *
   * @memberOf ApplicationService
   */
  getApplication(academicYear: string, processName?: string): Observable<Application> {
    const url = `${this.serviceUrl + academicYear}/`;
    return this.dataService.fetch(url, this.getRequestOptions({ ignoredErrorStatuses: [404] })).pipe(
      switchMap((app: Application) => {
        if (app?.transient) {
          if (!processName) {
            this.log.error('transient application returned, no process name provided');
          } else {
            app.processName = new ReferenceData({ code: processName });
          }

          // transient application should get immediately persisted
          return this.createApplication(app);
        }

        return of(app);
      }),
    );
  }

  /**
   * Makes a request to the application microservice to retrieve all applications.
   * The request requires the currentUser's token to be in the Auth header
   * of the request. The Application microservice will use that token to retrieve
   * the associated applications for a user.
   *
   * @memberOf ApplicationService
   */
  // eslint-disable-next-line max-lines-per-function
  getApplications(): Observable<ApplicationSummary[]> {
    return this.dataService
      .fetch(
        this.serviceUrl,
        this.getRequestOptions({
          success$: this.applications$,
          deserialize: ApplicationSummary.deserialize,
        }),
      )
      .pipe(
        map((apps: ApplicationSummary[]) => {
          return (
            apps &&
            apps
              .filter((a) => !a.archived || a.state === Application.STATE.FULLY_ENROLLED)
              .sort((a, b) => {
                return parseInt(b.academicYear, 10) - parseInt(a.academicYear, 10);
              })
          );
        }),
        tap((apps) => {
          this.applications$.next(apps);
        }),
      );
  }

  /**
   * Makes a request to the application microservice to update an application
   * The request requires the currentUser's token to be in the Auth header
   * of the request. The Application microservice will use the application object
   * in the body of the request to update it.
   *
   * @memberOf ApplicationService
   */
  updateApplication(application: Application): Observable<Application> {
    const url = `${this.serviceUrl + application.academicYear.code}/`;
    const body = Application.serialize(application);
    return this.dataService.put(url, body, this.getRequestOptions({ fallbackUrl: this.serviceUrl }));
  }

  /**
   * Makes a request to the application microservice to update an application for a staff member.
   * The Application microservice will use the application object
   * in the body of the request to update it.
   *
   * @memberOf ApplicationService
   */
  updateApplicationForStaff(
    application: Application,
    canonicalId: string,
    academicYear?: string,
  ): Observable<Application> {
    const year = application?.academicYear?.code || academicYear;
    const url = `${this.serviceUrl}staff/${canonicalId}/${year}/`;
    const body = Application.serialize(application);
    return this.dataService.patch(url, body, {});
  }

  /**
   * Makes a request to the application microservice to create an application
   * The request requires the currentUser's token to be in the Auth header
   * of the request. The Application microservice will use the application object
   * in the body of the request to persist.
   *
   * @memberOf ApplicationService
   */
  createApplication(application: Application): Observable<Application> {
    if (application.creationSource !== APPLICATION_CREATION_SOURCE.CREATION_SOURCE_UC_ONLINE) {
      application.creationSource = APPLICATION_CREATION_SOURCE.CREATION_SOURCE_STUDENT_FIRST;
    }
    const body = Application.serialize(application).application;
    const noNulls = { ...body };

    return this.dataService.post(this.serviceUrl, { application: noNulls }, this.getRequestOptions());
  }

  findOrCreateApplication(application: Application): Observable<Application> {
    return this.getApplication(application.academicYear.code, application.processName.code).pipe(
      catchError((err: DSHttpError) => {
        if (err.status === 404) {
          return this.createApplication(application);
        }

        return throwError(err);
      }),
    );
  }

  deleteApplication(year: string): Observable<null> {
    const url = `${this.serviceUrl + year}/`;
    return this.dataService.del(url, this.getRequestOptions({ deserialize: () => null })).pipe(
      tap(() => {
        // Clean up any cache relating to this application
        this.cacheService.clearCache({ target: CacheObjects.PROCESS });
        if (get(this.application$.value, 'academicYear.code') === year) {
          this.application$.next(null);
        }
      }),
    );
  }

  getApplicationEnrolment(year: string): Observable<ApplicationEnrolment[] | ChangeOfEnrolment> {
    const url = `${this.serviceUrl + year}/enrolments`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        success$: this.applicationEnrolments$,
        ignoredErrorStatuses: [404],
        deserialize: ApplicationEnrolment.deserializeAll,
      }),
    );
  }

  getActiveApplicationEnrolment(year: string): Observable<ApplicationEnrolment | ChangeOfEnrolment> {
    const url = `${this.serviceUrl + year}/enrolments/active`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        success$: this.activeApplicationEnrolment$,
        error: this.error$,
        deserialize: ApplicationEnrolment.deserialize,
      }),
    );
  }

  deleteApplicationEnrolment(year: string, internalReference: string) {
    const url = `${this.serviceUrl + year}/enrolments/${internalReference}`;
    return this.dataService.del(
      url,
      this.getRequestOptions({
        deserialize: ApplicationEnrolment.deserializeAll,
        error: this.error$,
      }),
    );
  }

  createApplicationEnrolment(year: string, applicationEnrolment: ApplicationEnrolment) {
    const body = ApplicationEnrolment.serialize(applicationEnrolment);
    const url = `${this.serviceUrl}${year}/enrolments`;
    return this.dataService.post(url, body, {
      success$: this.applicationEnrolments$,
      deserialize: ApplicationEnrolment.deserializeAll,
      error$: this.error$,
    });
  }

  updateApplicationEnrolment(year: string, internalReference: string, applicationEnrolment: ApplicationEnrolment) {
    const body = ApplicationEnrolment.serialize(applicationEnrolment);
    const url = `${this.serviceUrl}${year}/enrolments/${internalReference}`;
    return this.dataService.put(url, body, {
      success$: this.applicationEnrolments$,
      deserialize: ApplicationEnrolment.deserializeAll,
      error$: this.error$,
    });
  }

  addCourseToApplicationEnrolment(
    year: string,
    internalReference: string,
    qualPriority: string,
    course: EnrolledCourse,
  ): Observable<EnrolledCourse> {
    const url = `${this.serviceUrl}${year}/enrolments/${internalReference}/${qualPriority}`;
    return this.dataService.post(url, EnrolledCourse.serialize(course), {
      deserialize: EnrolledCourse.deserialize,
      error$: this.error$,
    });
  }

  removeCourseFromApplicationEnrolment(
    year: string,
    internalReference: string,
    qualPriority: string,
    courseReference: string,
  ) {
    const url = `${this.serviceUrl}${year}/enrolments/${internalReference}/${qualPriority}/${courseReference}`;
    return this.dataService.del(url, {
      error$: this.error$,
    });
  }

  updateOfferDecision(year: string, internalReference: string, offerDecision: OfferDecision) {
    const url = `${this.serviceUrl}${year}/enrolments/${internalReference}/offer-decision`;
    const body = OfferDecision.serialize(offerDecision);
    return this.dataService.put(url, body, {
      error$: this.error$,
    });
  }

  getApplicationForStaff(canonicalId: string, year: string): Observable<Application | ChangeOfEnrolment> {
    const url = `${this.staffUrl + canonicalId}/${year}`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        success$: this.applications$,
      }),
    );
  }

  getApplicationsForStaff(canonicalId: string): Observable<ApplicationSummary[]> {
    const url = this.staffUrl + canonicalId;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        success$: this.applications$,
        deserialize: ApplicationSummary.deserialize,
      }),
    );
  }

  getApplicationEnrolmentForStaff(
    canonicalId: string,
    year: string,
  ): Observable<ApplicationEnrolment[] | ChangeOfEnrolment> {
    const url = `${this.staffUrl + canonicalId}/${year}/enrolments`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        success$: this.applicationEnrolments$,
        ignoredErrorStatuses: [404],
        deserialize: ApplicationEnrolment.deserializeAll,
      }),
    );
  }

  updateApplicationEnrolmentForStaff(year: string, canonicalId: string, applicationEnrolment: ApplicationEnrolment) {
    const body = ApplicationEnrolment.serialize(applicationEnrolment);
    const url = `${this.staffUrl + canonicalId}/${year}/enrolments`;
    return this.dataService.post(url, body, {
      success$: this.applicationEnrolments$,
      deserialize: ApplicationEnrolment.deserializeAll,
      error$: this.error$,
    });
  }

  getConditionsForStaff(canonicalId: string, year: string): Observable<Condition[]> {
    const url = `${this.staffUrl}${canonicalId}/${year}/conditions`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        ignoredErrorStatuses: [404],
        deserialize: Condition.deserializeAll,
        success$: this.conditions$,
      }),
    );
  }

  updateConditionForStaff(canonicalId: string, condition: Condition) {
    const baseUrl = `${this.staffUrl}${canonicalId}/conditions/${condition.internalReference}`;
    const url = condition.internalReference ? `${baseUrl}/${condition.internalReference}` : baseUrl;
    const conditionUpdate = ConditionUpdate.createFromCondition(condition);
    const conditionPayload = ConditionUpdate.serialize(conditionUpdate);

    if (condition.internalReference) {
      return this.dataService.put(url, conditionPayload, this.getRequestOptions({}));
    } else {
      return this.dataService.post(url, conditionPayload, this.getRequestOptions({}));
    }
  }

  validateApplication(application: Application, canonicalId: string, year: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/${year}/validate`;
    return this.dataService.post(
      url,
      Application.serialize(application),
      this.getRequestOptions({ ignoredErrorStatuses: [422], emitErrors: false }),
    );
  }

  // eslint-disable-next-line max-lines-per-function
  updateBackgroundChecks(
    backgroundCheck: BackgroundCheck,
    canonicalId: string,
    academicYear: string,
    internalReference?: string,
  ) {
    let url = `${this.serviceUrl}staff/${canonicalId}/${academicYear}/background-checks`;
    const body = { background_checks: BackgroundCheck.serialize(backgroundCheck) };
    if (internalReference) {
      url = `${url}/${internalReference}`;
      return this.dataService.put(
        url,
        body,
        this.getRequestOptions({
          deserialize: BackgroundCheck.deserializeArray,
        }),
      );
    } else {
      return this.dataService.post(
        url,
        body,
        this.getRequestOptions({ deserialize: BackgroundCheck.deserializeArray }),
      );
    }
  }

  deleteBackgroundChecks(canonicalId: string, academicYear: string, internalReference: string) {
    const url = `${this.serviceUrl}staff/${canonicalId}/${academicYear}/background-checks/${internalReference}`;
    return this.dataService.del(
      url,
      this.getRequestOptions({
        error: this.error$,
      }),
    );
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars,class-methods-use-this */
export class MockApplicationService extends AbstractApplicationService {
  public currentApplicationYear = String(new Date().getFullYear());
  public mockState: { [year: string]: Application } = {};
  public errorData = {
    data: [
      {
        detail: 'Error!',
        source: {
          pointer: '/model/attribute',
        },
      },
    ],
  };
  public application$ = new BehaviorSubject<Application>(null);

  mockSummaries: ApplicationSummary[];

  constructor(
    mockState?: Application,
    public cacheService?: CacheManagementService,
  ) {
    super();

    const mock = mockState || Application.deserialize(mockApplicationResponse());

    this.setDefaultCurrentYearApplicationIfMockStateNotExist(mockState);
    this.mockState[mock.academicYear.code] = mock;
    this.application$.next(mock);

    if (cacheService) {
      this.cacheService.shouldClearCache
        .pipe(filter((options) => options.target === CacheObjects.ALL))
        .subscribe(() => this.clearCache());
    }
  }

  private setDefaultCurrentYearApplicationIfMockStateNotExist(mockState: Application) {
    if (!mockState) {
      const currentYearApplication = Application.deserialize(mockApplicationResponse());
      currentYearApplication.academicYear.code = this.currentApplicationYear;
      this.mockState[currentYearApplication.academicYear.code] = currentYearApplication;
    }
  }

  // These functions do exactly the same thing in Mock Land
  updateApplication(application: Application): Observable<Application> {
    return this.createApplication(application);
  }

  public update(application: Application | ChangeOfEnrolment): Observable<Application | ChangeOfEnrolment> {
    return this.createApplication(application as Application);
  }

  updateApplicationForStaff(
    application: Application,
    canonicalId: string,
    coeInternalReference?: string,
  ): Observable<Application | ChangeOfEnrolment> {
    return of(null);
  }

  getApplication(year: string): Observable<Application> {
    const application = this.mockState[year];
    if (!application) {
      this.error$.next({ code: UCErrorCodes.E404, data: application });
      return throwError({ status: 404 });
    }
    this.application$.next(application);
    return of(application);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  getApplications(): Observable<ApplicationSummary[]> {
    if (this.applications$.value && this.applications$.value.length) {
      const applicationSummaries = this.applications$.value.filter((a: ApplicationSummary) => {
        return !a.archived || a.state === Application.STATE.FULLY_ENROLLED;
      });
      this.applications$.next(applicationSummaries);
      return of(this.applications$.value);
    }

    // eslint-disable-next-line max-lines-per-function
    const summaries = Object.keys(this.mockState).map((year) => {
      const { academicYear, processName, state } = this.mockState[year];
      return new ApplicationSummary({
        academicYear: academicYear.code,
        processName: processName.code,
        state: state.code,
        primaryAction: new SummaryAction({
          label: 'Resume Application',
          action: 'RESUME',
          href: null,
        }),
        secondaryAction: [
          {
            label: 'Change qualification / subjects',
            action: APPLICATION_ACTIONS.CHANGE_QUALIFICATION,
            href: 'UCSW.COEHOME',
          },
          {
            label: 'Add / change courses',
            action: APPLICATION_ACTIONS.CHANGE_COURSE,
          },
          {
            label: 'Withdraw application',
            action: APPLICATION_ACTIONS.WITHDRAW,
          },
          {
            cannot_change_course_coa: {
              label: 'Add / change courses',
              action: 'COA_CANNOT_START',
            },
          },
          {
            cannot_change_qual_coa: {
              label: 'Change qualification / subjects',
              action: 'COA_CANNOT_START',
            },
          },
        ],
      });
    });

    summaries.push(
      new ApplicationSummary({
        academicYear: '2016',
        processName: 'new_student',
        state: Application.STATE.FULLY_ENROLLED,
        primaryAction: {
          label: 'Resume Application',
          action: 'RESUME',
          href: null,
        },
        secondaryAction: [
          {
            label: 'Change qualification / subjects',
            action: APPLICATION_ACTIONS.CHANGE_QUALIFICATION,
            href: '/ucsms/ee_login.aspx?PAGE=COEHOME',
          },
          {
            label: 'Add / change courses',
            action: APPLICATION_ACTIONS.CHANGE_COURSE,
          },
        ],
      }),
    );

    if (summaries.length === 0) {
      this.error$.next({ code: UCErrorCodes.E404, data: summaries });
      return throwError({ status: 404 });
    }
    summaries.sort((a, b) => (a.academicYear >= b.academicYear ? -1 : 1));
    this.applications$.next(summaries);
    this.mockSummaries = summaries;
    return of(summaries);
  }

  createApplication(application: Application): Observable<Application> {
    Object.assign(this.mockState['2018'], application);
    this.mockState[application.academicYear.code] = application;
    this.application$.next(application);
    return of(application);
  }

  findOrCreateApplication(application: Application): Observable<Application> {
    return this.getApplication(application.academicYear.code).pipe(
      catchError((err: DSHttpError) => {
        if (err.status === 404) {
          return this.createApplication(application);
        }

        return throwError(err);
      }),
    );
  }

  deleteApplication(year: string): Observable<null> {
    if (this.mockState[year]) {
      delete this.mockState[year];
      this.mockSummaries = this.mockSummaries
        ? this.mockSummaries.filter((summary) => summary.academicYear !== year)
        : [];
      this.applications$.next(this.mockSummaries);
      return of(null);
    }

    const error = { errors: [{ source: { pointer: '/application/withdraw_failed' } }] };
    const errorRes = new HttpErrorResponse({ status: 422, error });
    return throwError(new DSHttpError(errorRes, { code: UCErrorCodes.E422 }));
  }

  getApplicationEnrolment(year: string) {
    return of(ApplicationEnrolment.deserializeAll(mockApplicationEnrolmentResponse()));
  }

  getActiveApplicationEnrolment(year: string): Observable<ApplicationEnrolment> {
    return of(ApplicationEnrolment.deserialize(mockSingularApplicationEnrolmentResponse()));
  }
  deleteApplicationEnrolment(year: string, internalReference: string) {
    return of({});
  }

  withdrawApplicationEnrolment(year: string, internalReference: string) {
    return of({});
  }

  createApplicationEnrolment(year: string, applicationEnrolment: ApplicationEnrolment) {
    return of({});
  }

  updateApplicationEnrolment(year: string, internalReference: string, applicationEnrolment: ApplicationEnrolment) {
    return of({});
  }

  addCourseToApplicationEnrolment(
    year: string,
    internalReference: string,
    qualPriority: string,
    course: EnrolledCourse,
  ) {
    if (course.code === 'unknown-code') {
      return throwError(this.errorData);
    }
    return of({});
  }

  removeCourseFromApplicationEnrolment(
    year: string,
    internalReference: string,
    qualPriority: string,
    courseReference: string,
  ) {
    if (courseReference === 'unknown-code') {
      return throwError(this.errorData);
    }
    return of({});
  }

  updateOfferDecision() {
    return of({});
  }
  getApplicationForStaff(canonicalId: string, year: string) {
    let application = this.mockState[year];
    if (!application) {
      application = this.mockState['2018'];
    }
    this.application$.next(application);
    return of(application);
  }

  getConditionsForStaff(canonicalId: string, year: string, coeInternalReference: string): Observable<Condition[]> {
    const conditions = Condition.deserializeAll(conditionsMockData());
    this.conditions$.next(conditions);
    return of(conditions);
  }

  updateConditionForStaff(canonicalId, conditionUpdate) {
    return of(new Condition({ item: { style: 'decline_qa' }, state: { code: 'pending' } }));
  }

  getApplicationEnrolmentForStaff(canonicalId: string, year: string) {
    return of(ApplicationEnrolment.deserializeAll(mockApplicationEnrolmentResponse()));
  }

  getApplicationsForStaff(canonicalId: string): Observable<ApplicationSummary[]> {
    const mockSummary: ApplicationSummary[] = ApplicationSummary.deserialize(mockApplicationSummaryStaffResponse());
    return of(mockSummary);
  }

  validateApplication(application: Application, canonicalId: string, year: string) {
    return this.application$.asObservable();
  }

  updateBackgroundChecks(
    application: ChangeOfEnrolment,
    canonicalId: string,
    academicYear: string,
    internalReference: string,
  ) {
    return of({});
  }

  deleteBackgroundChecks(canonicalId: string, academicYear: string, coeInternalReference: string) {
    return of(mockApplicationEnrolmentResponse());
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars,class-methods-use-this */

// provides the mock reference data when useFakeBackend is true
export const applicationServiceFactory = (
  dataService,
  loggingService,
  cacheService,
): ApplicationService | MockApplicationService => {
  if (environment.useFakeBackend.application) {
    return new MockApplicationService(null, cacheService);
  } else {
    return new ApplicationService(dataService, loggingService, cacheService);
  }
};

export const applicationServiceProvider = {
  provide: ApplicationService,
  useFactory: applicationServiceFactory,
  deps: [DataService, LoggingService, CacheManagementService],
};
