/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable complexity */
import { camelizeKeys, deepSnakeify, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData, SerializedReferenceData } from '@shared/models/reference-data';

import { Applicant, SerializedApplicant } from './applicant';

interface SerializedUniversityAdmission {
  year: SerializedReferenceData;
  reason: SerializedReferenceData;
  status: SerializedReferenceData;
  reason_year: SerializedReferenceData;
  notes: string;
  assessment: string;
  gpa_value: string;
  english_proficiency?: string;
}

export class UniversityAdmission {
  year: ReferenceData;
  reason: ReferenceData;
  status: ReferenceData;
  reasonYear: ReferenceData;
  notes: string;
  assessment: string;
  gpaValue: string;
  englishProficiency?: string;
  constructor(options: Partial<UniversityAdmission>) {
    Object.assign(this, options);
  }

  static deserialize(payload: Partial<SerializedUniversityAdmission>): UniversityAdmission {
    if (payload === null) {
      return null;
    }
    const universityAdmission: UniversityAdmission = camelizeKeys(payload);
    universityAdmission.year = ReferenceData.deserialize(payload.year);
    universityAdmission.reason = ReferenceData.deserialize(payload.reason);
    universityAdmission.status = ReferenceData.deserialize(payload.status);
    universityAdmission.reasonYear = ReferenceData.deserialize(payload.reason_year);
    return new UniversityAdmission(universityAdmission);
  }

  static serialize(instance: UniversityAdmission): SerializedUniversityAdmission {
    const clone: SerializedUniversityAdmission = snakeifyKeys(instance);

    if (instance.reason) {
      clone.reason = ReferenceData.serialize(instance.reason);
    }

    if (instance.status) {
      clone.status = ReferenceData.serialize(instance.status);
    }

    if (instance.year) {
      clone.year = instance.year?.code ? ReferenceData.serialize(instance.year) : null;
    }

    if (instance.reasonYear) {
      clone.reason_year = instance.reasonYear?.code ? ReferenceData.serialize(instance.reasonYear) : null;
    }

    return clone;
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedApplicantStaff extends SerializedApplicant {
  university_admission: SerializedUniversityAdmission;
  user_id: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class ApplicantStaff extends Applicant {
  universityAdmission: UniversityAdmission;
  userId: string;

  // This is not actually useless; it's important to redeclare the incoming type
  /* eslint-disable-next-line @typescript-eslint/no-useless-constructor */
  constructor(data: Partial<ApplicantStaff>) {
    super(data);
  }

  get hasCacFields() {
    for (const prop in this.caCompliance) {
      if (this.caCompliance[prop] !== null && prop !== 'hasHistoricalCaData') {
        return true;
      }
    }
    if (this.caCompliance?.hasHistoricalCaData) {
      return true;
    }
    return false;
  }

  static deserialize(payload: { applicant: Partial<SerializedApplicantStaff> }): ApplicantStaff {
    if (payload === null) {
      return null;
    }

    const data = payload.applicant;
    const applicant = {
      ...Applicant.deserialize({ applicant: data }),
      universityAdmission: UniversityAdmission.deserialize(data.university_admission),
    };

    return new ApplicantStaff(applicant);
  }

  static serialize(instance: ApplicantStaff): { applicant: SerializedApplicantStaff } {
    const { applicant: serialized } = super.serialize(instance) as { applicant: SerializedApplicantStaff };
    if (instance.universityAdmission) {
      serialized.university_admission = UniversityAdmission.serialize(instance.universityAdmission);
    }
    return { applicant: serialized };
  }

  static serializeApplicantStaff(applicant) {
    if (applicant.universityAdmission) {
      applicant.universityAdmission = UniversityAdmission.serialize(applicant.universityAdmission);
    }
    return { applicant: deepSnakeify(applicant) };
  }
}
