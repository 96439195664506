import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-confidential-school-report',
  templateUrl: './confidential-school-report.component.html',
})
export class ConfidentialSchoolReportComponent implements OnInit {
  @Input() schoolReport: UntypedFormGroup;
  @Input() hintPath: string;
  strings = strings.components.tasks.teachingApplication;

  constructor() {}
  ngOnInit() {}
}
