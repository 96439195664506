<uc-form-template>
  <div class="content-container">
    <img
      src="assets/img/payment_success.svg"
      alt="payment-success"
      class="success-icon"
    />
    <div class="title-container">
      <h4>{{ strings.title }}</h4>
    </div>

    <div class="description-container">
      <p>
        {{ strings.description[0] }}
      </p>
      <p>
        {{ strings.description[1] }}
      </p>
    </div>
  </div>
</uc-form-template>
