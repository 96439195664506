import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { AccountDetailsCardComponent } from '@shared/components/molecules/account-details-card/account-details-card.component';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { ErrorMessagePipe } from '@shared/pipes/error-message.pipe';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { UcMaterialModule } from '@shared/uc-material/uc-material.module';

import { AgentDetailsComponent } from './agent-details/agent-details.component';
import { ApplicationOverviewComponent } from './application-overview/application-overview.component';
import { CategoryFileUploadComponent } from './category-file-upload/category-file-upload.component';
import { ConditionItemComponent } from './condition-item/condition-item.component';
import { ConfidentialRefereeReportComponent } from './confidential-referee-report/confidential-referee-report.component';
import { ConfidentialSchoolReportComponent } from './confidential-school-report/confidential-school-report.component';
import { CoursePlannerHeaderComponent } from './course-planner-header/course-planner-header.component';
import { CoursePlannerReviewPanelComponent } from './course-planner-review-panel/course-planner-review-panel.component';
import { CreateAccountFormComponent } from './create-account-form/create-account-form.component';
import { CurrentContactDetailsComponent } from './current-contact-details/current-contact-details.component';
import { DashboardTilesComponent } from './dashboard-tiles/dashboard-tiles.component';
import { EmergencyContactComponent } from './emergency-contact/emergency-contact.component';
import { EnglishProficiencyDetailComponent } from './english-proficiency-detail/english-proficiency-detail.component';
import { EnglishProficiencyTestTypeComponent } from './english-proficiency-test-type/english-proficiency-test-type.component';
import { EnrolmentViewComponent } from './enrolment-view/enrolment-view.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadListComponent } from './file-upload-list/file-upload-list.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { IdentityGroupsComponent } from './identity-groups/identity-groups.component';
import { InsuranceProviderComponent } from './insurance-provider/insurance-provider.component';
import { LearningNeedsComponent } from './learning-needs/learning-needs.component';
import { LegalNameDeclarationComponent } from './legal-name-declaration/legal-name-declaration.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ManageEnrolmentViewComponent } from './manage-enrolment-view/manage-enrolment-view.component';
import { ModalComponent } from './modal/modal.component';
import { MoreAboutYourStudiesComponent } from './more-about-your-studies/more-about-your-studies.component';
import { OverseasPoliceCheckComponent } from './overseas-police-check/overseas-police-check.component';
import { OverseasPoliceCheckCountryComponent } from './overseas-police-check-country/overseas-police-check-country.component';
import { PermanentContactComponent } from './permanent-contact/permanent-contact.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { FilteredTableComponent } from './pipeline-filtered-table/pipeline-filtered-table.component';
import { PoliceRecordComponent } from './police-record/police-record.component';
import { QualificationDetailCardComponent } from './qualification-detail-card/qualification-detail-card.component';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { SecondaryEducationComponent } from './secondary-education/secondary-education.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SslRebateComponent } from './ssl-rebate/ssl-rebate.component';
import { StagesSidebarComponent } from './stages-sidebar/stages-sidebar.component';
import { TertiaryEducationComponent } from './tertiary-education/tertiary-education.component';
import { TileComponent } from './tile/tile.component';
import { UniversityEntranceComponent } from './university-entrance/university-entrance.component';
import { YearSelectorComponent } from './year-selector/year-selector.component';

// components that are declared and exported
const organisms = [
  AccountDetailsCardComponent,
  AgentDetailsComponent,
  ApplicationOverviewComponent,
  CategoryFileUploadComponent,
  ConditionItemComponent,
  ConfidentialRefereeReportComponent,
  ConfidentialSchoolReportComponent,
  CoursePlannerHeaderComponent,
  CoursePlannerReviewPanelComponent,
  CreateAccountFormComponent,
  CurrentContactDetailsComponent,
  DashboardTilesComponent,
  EmergencyContactComponent,
  EnglishProficiencyDetailComponent,
  EnglishProficiencyTestTypeComponent,
  EnrolmentViewComponent,
  ErrorMessagePipe,
  FileUploadComponent,
  FileUploadListComponent,
  FilteredTableComponent,
  HeaderBarComponent,
  IdentityGroupsComponent,
  InsuranceProviderComponent,
  LearningNeedsComponent,
  LegalNameDeclarationComponent,
  LoginFormComponent,
  ManageEnrolmentViewComponent,
  ModalComponent,
  MoreAboutYourStudiesComponent,
  OverseasPoliceCheckComponent,
  OverseasPoliceCheckCountryComponent,
  PermanentContactComponent,
  PersonalDetailsComponent,
  PoliceRecordComponent,
  QualificationDetailCardComponent,
  SearchFiltersComponent,
  SecondaryEducationComponent,
  SidebarComponent,
  SslRebateComponent,
  StagesSidebarComponent,
  TertiaryEducationComponent,
  TileComponent,
  TimeAgoPipe,
  UniversityEntranceComponent,
  YearSelectorComponent,
];

@NgModule({
  declarations: organisms,
  imports: [
    CommonModule,
    UcMaterialModule,
    AtomsModule,
    MoleculesModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSmartModalModule.forRoot(),
    NgxDatatableModule,
  ],
  exports: organisms,
})
export class OrganismsModule {}
