import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { switchMap, filter, map, startWith, distinctUntilChanged } from 'rxjs/operators';

import { ExtraOption } from '@shared/models/extra-option';
import { ReferenceData } from '@shared/models/reference-data';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-reference-data-selector',
  templateUrl: './reference-data-selector.component.html',
  styleUrls: ['./reference-data-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferenceDataSelectorComponent),
      multi: true,
    },
  ],
})
export class ReferenceDataSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() labelName: string;
  @Input() noIcon = false;
  @Input() required = false;
  @Input() showDisabledExplanation = true;
  @Input() testSelector = '';
  @Input() selectPlaceHolder: string;
  @Input() showBlankOption = true;
  @Input() useNgSelect: boolean;
  @Input() enableClearFieldButton = false;
  @Input() filterOutNonValidOptions = false;
  @Input() ensureCurrentValueInOptions = false;
  @Input() ngSelectAttributes: { placeholder: boolean } = {
    placeholder: false,
  };

  processedOptions$: Observable<{ labelText: string; value: string }[]>;
  private options$ = new ReplaySubject<{ labelText: string; value: string }[]>(1);
  protected extraOptions$ = new BehaviorSubject<ExtraOption[]>([]);
  private type$ = new ReplaySubject<string>(1);
  private valueKey$ = new BehaviorSubject<string>('description');
  private filter$ = new BehaviorSubject<string[]>([]);
  private filterFn$ = new BehaviorSubject<(ReferenceData) => boolean>(() => true);

  private internalValueChanges: Observable<unknown>;
  private externalValueChanges$: Subject<unknown>;
  private allValueChanges: Observable<unknown>;

  log: Logger;
  innerControl = new UntypedFormControl();
  hasValue: boolean;
  currentValuePopulated: boolean;

  isDisabled: boolean;

  constructor(
    loggingService: LoggingService,
    private referenceData: ReferenceDataService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  @Input() set type(value: string) {
    this.type$.next(value);
  }

  @Input() set valueKey(value: string) {
    this.valueKey$.next(value);
  }

  @Input() set options(value: { labelText: string; value: string }[]) {
    this.options$.next(value);
  }

  @Input() set filter(value: string[]) {
    this.filter$.next(value);
  }

  @Input() set filterFn(value: (ReferenceData) => boolean) {
    this.filterFn$.next(value);
  }

  @Input() set extraOptions(value: ExtraOption[]) {
    this.extraOptions$.next(value);
  }

  get stringValue(): Observable<string> {
    return combineLatest({
      options: this.options$,
      currentValue: this.innerControl.valueChanges.pipe(startWith(this.innerControl.value)),
    }).pipe(
      map(({ options, currentValue }) => {
        if (options.length) {
          const stringVal = options.find((x) => currentValue === x.value);
          return stringVal?.labelText;
        } else {
          return currentValue;
        }
      }),
    );
  }

  get testSelectorId(): Observable<string> {
    if (this.testSelector) {
      return of(`${this.testSelector}-refdata`);
    } else {
      return this.type$.pipe(map((type) => `${type.toLowerCase().replace(/_/g, '-')}-refdata`));
    }
  }

  ngOnInit() {
    this.log.info('ngOnInit');

    this.setUpChangesObservables();
    this.generateOptionsForReferenceDataTypeIfPresent();
    this.propagateChangesWhenOptionsAreManuallyProvided();
    this.propagateChangesWhenOptionsAreReferenceData();
    this.filterOutExclusionsExceptForCurrentValue();
    this.addCurrentValueToOptionsIfMissing();
  }

  setUpChangesObservables() {
    this.internalValueChanges = this.innerControl.valueChanges;
    this.externalValueChanges$ = new Subject<unknown>();
    this.allValueChanges = merge(this.internalValueChanges, this.externalValueChanges$.asObservable());
  }

  generateOptionsForReferenceDataTypeIfPresent() {
    combineLatest({ type: this.type$ })
      .pipe(
        switchMap(({ type }) => this.combinedDataForType(type)),
        map(({ data, valueKey, filterFn, extraOptions }) =>
          ReferenceDataSelectorComponent.convertReferenceDataToOptions(data, valueKey, filterFn, extraOptions),
        ),
      )
      .subscribe((data) => this.options$.next(data));
  }

  combinedDataForType(type: string) {
    return combineLatest({
      data: this.referenceData.getByType(type, {}, this.filterOutNonValidOptions),
      valueKey: this.valueKey$,
      filterFn: this.filterFn$,
      extraOptions: this.extraOptions$,
    });
  }

  addCurrentValueToOptionsIfMissing() {
    combineLatest([this.options$, this.allValueChanges])
      .pipe(
        distinctUntilChanged(),
        filter(() => this.ensureCurrentValueInOptions),
      )
      .subscribe(([options, currentValue]) => {
        const optionsMissingCurrentValue = !options.some((option) => option.value === currentValue);
        if (currentValue && optionsMissingCurrentValue) {
          this.options$.next([...options, { labelText: String(currentValue), value: String(currentValue) }]);
        }
      });
  }

  private static convertReferenceDataToOptions(
    data: ReferenceData[],
    valueKey: string,
    filterFunction: (ReferenceData) => boolean,
    extraOptions: ExtraOption[],
  ) {
    const filtered = data.filter(filterFunction);
    const formatted = ReferenceDataSelectorComponent.referenceDataToOptionsData(filtered, valueKey);
    return ReferenceDataSelectorComponent.insertExtraOptions(formatted, extraOptions);
  }

  private static referenceDataToOptionsData(data: ReferenceData[], valueKey: string) {
    return data.map((referenceData) => ({
      labelText: referenceData[valueKey],
      value: referenceData.code,
    }));
  }

  private static insertExtraOptions(currentOptions: { labelText: string; value: string }[], overrides: ExtraOption[]) {
    const allOptions = [...currentOptions];
    overrides.forEach(({ position, labelText, value }) => allOptions.splice(position, 0, { labelText, value }));
    return allOptions;
  }

  propagateChangesWhenOptionsAreManuallyProvided() {
    combineLatest({
      value: this.innerControl.valueChanges,
      options: this.options$,
      type: this.type$.pipe(startWith(null)),
    })
      .pipe(filter(({ type }) => !type))
      .subscribe(({ value }) => {
        this.hasValue = !!(value && value.length);
        this.propagateChange(value);
      });
  }

  propagateChangesWhenOptionsAreReferenceData() {
    combineLatest({ value: this.innerControl.valueChanges, type: this.type$ })
      .pipe(
        filter(({ type }) => !!type),
        switchMap(({ value, type }) => {
          if (value == null || value === '') {
            this.propagateChange('');
            this.hasValue = false;
            return EMPTY;
          } else {
            return this.referenceData.getByCode(type, value);
          }
        }),
      )
      .subscribe((refData) => {
        this.hasValue = !!refData?.code;
        this.propagateChange(refData);
      });
  }

  filterOutExclusionsExceptForCurrentValue() {
    this.processedOptions$ = combineLatest({
      currentValue: this.innerControl.valueChanges.pipe(startWith(null)),
      options: this.options$,
      exclusions: this.filter$,
    }).pipe(
      map(({ options, exclusions }) =>
        // A race condition between ngOnInit that calls this method, and templates subscribing to it, means that we
        // miss the initial value set to this.innerControl. However we can use the current value which works just
        // as well and also picks up missed value changes when something else triggers the observable pipe.
        ReferenceDataSelectorComponent.filterOutExcludedItems(options, exclusions, this.innerControl.value),
      ),
    );
  }

  private static filterOutExcludedItems(
    currentOptions: { labelText: string; value: string }[],
    excludedItems: string[],
    currentValue: string,
  ) {
    return currentOptions.filter((opt) => {
      if (opt.value === currentValue) {
        return true;
      } else {
        return !excludedItems.some((filterCode) => filterCode === opt.value);
      }
    });
  }

  writeValue(value: unknown): void {
    if (typeof value === 'object') {
      this.hasValue = !!((value as ReferenceData)?.code || (value as unknown[])?.length);
    } else {
      this.hasValue = !!value;
    }
    this.setControlValue(value);
  }

  private setControlValue(value: unknown) {
    if (value && (value as ReferenceData).code) {
      this.innerControl.setValue((value as ReferenceData).code, { emitEvent: false });
      this.externalValueChanges$.next((value as ReferenceData).code);
    } else {
      this.innerControl.setValue(value, { emitEvent: false });
      this.externalValueChanges$.next(value);
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars,class-methods-use-this */
  private propagateChange = (fn: unknown) => {
    // No-op
  };

  registerOnChange(fn: (unknown) => void): void {
    this.propagateChange = fn;
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars,class-methods-use-this */
  registerOnTouched(fn: unknown): void {
    // No-op
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  resetFormControlValue() {
    this.innerControl.setValue(null);
  }
}
