import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-online-more-about-your-studies',
  templateUrl: './online-more-about-your-studies.component.html',
})
export class OnlineMoreAboutYourStudiesComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() year: string;
  @Input() task: Task;
  @Input() maysForm: UntypedFormGroup;
  @Input() isFirstYear: UntypedFormControl;

  strings = strings.components.organisms.onlineMoreAboutYourStudies;

  get getTaskIfFirstYear() {
    return this.isFirstYear.value !== 'hide' ? this.task : null;
  }

  get firstYear() {
    return Number(this.year);
  }

  ngOnInit() {
    this.synchroniseMaysFormWithFirstYearField();
  }

  private synchroniseMaysFormWithFirstYearField() {
    this.isFirstYear.valueChanges.pipe(takeUntil(this.componentDestroyed)).subscribe((value) => {
      if (value === 'show') {
        this.maysForm.get('firstYearHere').setValue(this.year);
      }
      if (value === 'hide') {
        this.maysForm.get('firstYearHere').reset();
      }
    });
  }
}
