<uc-form-template [formGroup]="siPage">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-section-header title="{{ strings.header }}"> </uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/application/study_start"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.question }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-subsection-minor-text>
        {{ strings.studyDescription }}
      </uc-subsection-minor-text>
      <uc-radio-input-group
        [additionalText]="extraDescriptions"
        type="begin_studies"
        groupName="study-intentions"
        formControlName="studyStart"
      >
      </uc-radio-input-group>

      <div *ngIf="shouldShowDateSelector">
        <uc-task-hint [task]="task" path="/application/study_start_other"></uc-task-hint>
        <uc-subsection-question>
          {{ strings.dateQuestion }} <uc-required-field></uc-required-field>
        </uc-subsection-question>
        <uc-form-input>
          <uc-date-selector
            formControlName="studyStartOther"
            order="ascending"
            range="4"
            testSelector="study-start-other"
          >
          </uc-date-selector>
        </uc-form-input>
      </div>
    </uc-subsection>
  </uc-section>
</uc-form-template>
