<uc-subsection-minor-text *ngIf="usedInTask"><span [innerHTML]="strings.helpText"></span></uc-subsection-minor-text>
<uc-message-banner *ngIf="showError" [showCloseButton]="true" type="error">{{ error }}</uc-message-banner>
<div class="file-upload-wrapper" [class.in-task]="usedInTask">
  <div class="file-upload-container">
    <div class="file-upload-header clearfix">
      <h3 class="header-text">
        <div class="notification-oval" *ngIf="!usedInTask && required"></div>
        {{ title }}
        <div class="required-indicator" *ngIf="usedInTask && required">*</div>
      </h3>
      <uc-button
        [testSelector]="'upload-' + categoryCode.toLowerCase()"
        class="rounded has-icon upload-button"
        *ngIf="showUploadFileButton"
        (click)="addFile()"
      >
        <uc-icon iconType="upload" iconWidth="22" iconHeight="22"></uc-icon>
        {{ strings.upload }}
      </uc-button>
    </div>
    <div class="file-upload-content" *ngIf="showUploadedFiles">
      <div class="file-upload-list">
        <div class="file-upload-item clearfix" *ngFor="let doc of files">
          <div class="file-item-detail" [class.is-uploading]="!doc.complete">
            <uc-icon iconType="doc" iconWidth="14" iconHeight="14" iconFill="$colorAbbey"> </uc-icon>
            <a
              *ngIf="doc.file.studentViewable"
              class="name-text"
              target="_blank"
              [href]="doc.file.externalUrl"
              [attr.title]="doc.file?.name"
            >
              {{ doc.file?.name }}
            </a>
            <div *ngIf="!doc.file.studentViewable" class="name-text" [attr.title]="doc.file?.name">
              {{ doc.file?.name }}
            </div>
            <div class="date-section">
              {{ doc.file.created | timeAgo }}
            </div>
          </div>

          <div class="file-item-actions">
            <span *ngIf="!doc.complete">
              <span class="progress-binding"> {{ strings.uploading }} {{ doc.progress | percent: '1.0-0' }} </span>
              <a class="cancel-link" (click)="abortUpload(doc)">Cancel</a>
            </span>

            <button
              [attr.data-cy]="'remove-file' + categoryCode.toLowerCase()"
              class="remove-link"
              *ngIf="doc.complete && !disableRemove"
              (click)="removeFile(doc)"
            >
              <span *ngIf="!displaySpinner">
                <uc-icon iconType="close" iconWidth="8" iconHeight="8" iconFill="$colorFireEngineRed"></uc-icon>
                <span class="remove-link-text"> Remove </span>
              </span>
              <uc-spinner *ngIf="displaySpinner" [size]="19"></uc-spinner>
            </button>
          </div>
        </div>
      </div>
      <button [attr.data-cy]="'add-file'" *ngIf="!disableAddFiles" class="add-file-link" (click)="addFile()">
        <uc-icon iconType="add" iconWidth="24" iconHeight="25"></uc-icon>
        {{ strings.add }}
      </button>
    </div>
  </div>
  <uc-icon
    *ngIf="usedInTask && !disableAddFiles"
    class="validator-indicator"
    [class.has-files]="files?.length"
    iconType="tick"
    iconTitle="Valid"
    iconWidth="11"
    iconHeight="11"
    iconFill="$colorWhite"
  ></uc-icon>
</div>
<input
  [attr.data-cy]="'upload-hidden-' + categoryCode.toLowerCase()"
  class="upload-hidden"
  #uploadEl
  type="file"
  (click)="setNull()"
  (change)="onFileChange()"
/>
