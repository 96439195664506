import { camelizeKeys } from '@shared/helpers/serialization';

export class Page {
  // The number of elements in the page
  pageSize = 0;
  // The total number of elements
  totalItems = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  number = 0;

  get pageNumber() {
    // The table component is expecting a 0-indexed page number
    // The api sets the first page of results as 1 so this is to compensate
    return this.number - 1;
  }

  get itemsPerPage() {
    // Sets a default value of 40 but allows the itemsPerPage to be overwritten by the api
    return this.pageSize || 40;
  }

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload: any): Page {
    if (payload === null) {
      return null;
    }

    payload = payload.page || payload;

    const page: any = camelizeKeys(payload);

    return new Page(page);
  }
}
