import { DateTime } from 'luxon';

import { CONDITION_ITEM, CONDITION_STATE } from '@shared/constants/states.constants';
import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

import { ReferenceData } from './reference-data';

export const INACTIVE_CONDITION_STATES = [CONDITION_STATE.MET, CONDITION_STATE.REMOVED, CONDITION_STATE.PENDING];

const convertToDateIfValid = (date) => {
  const dateTime = DateTime.fromISO(date);
  return dateTime.isValid ? dateTime.toJSDate() : date;
};

export class Condition {
  public state: ReferenceData;
  public reason: ReferenceData;
  public additionalText: string;
  public dateMetRemoved: Date;
  public dateSupplied: Date;
  public dateApplied: Date;
  public internalReference: string;
  public qualification: ReferenceData;
  public course: ReferenceData;
  public error: { code: string };
  public item: {
    code: string;
    style: string;
  };

  public documentTypeCode: string;

  constructor(rawCondition) {
    Object.assign(this, rawCondition);
  }

  get isDocumentUploadable() {
    return !!this.documentTypeCode;
  }

  get shouldTriggerNotification() {
    return this.state.code === CONDITION_STATE.APPLIED;
  }

  get shouldDisplay() {
    const isActiveAndUploadable =
      !INACTIVE_CONDITION_STATES.find((state) => state === this.state.code) && this.isDocumentUploadable;
    const isIncompleteAndApplied =
      this.item.code.startsWith(CONDITION_ITEM.INCOMPLETE) && this.state.code === CONDITION_STATE.APPLIED;
    return isActiveAndUploadable || isIncompleteAndApplied;
  }

  static deserialize(payload: any): Condition {
    if (payload === null) {
      return null;
    }

    const condition: any = camelizeKeys(payload);
    condition.state = ReferenceData.deserialize(condition.state);
    condition.reason = ReferenceData.deserialize(condition.reason);
    condition.dateMetRemoved = convertToDateIfValid(condition.dateMetRemoved);
    condition.dateSupplied = convertToDateIfValid(condition.dateSupplied);
    condition.dateApplied = convertToDateIfValid(condition.dateApplied);

    return new Condition(condition);
  }

  static deserializeAll(payload: any): Condition[] {
    if (payload === null) {
      return null;
    }

    const rawConditions = payload.conditions;
    return !!rawConditions ? rawConditions.map(Condition.deserialize) : [];
  }

  static serialize(instance: Condition): any {
    const clone = { ...instance };
    clone.state = ReferenceData.serialize(clone.state);
    clone.reason = ReferenceData.serialize(clone.reason);

    return snakeifyKeys(clone);
  }
}

export class ConditionUpdate {
  additionalText: string;
  reason: {
    code: string;
  };
  item: {
    code: string;
  };
  qualification: {
    code: string;
  };
  state?: {
    code: string;
  };

  constructor(rawCondition) {
    Object.assign(this, rawCondition);
  }

  static createFromCondition(condition: Condition) {
    return new ConditionUpdate({
      additionalText: condition.additionalText,
      reason: condition.reason,
      item: condition.item,
      qualification: condition.qualification,
      state: condition.state,
    });
  }

  static serialize(instance: ConditionUpdate): any {
    const clone = { ...instance };
    clone.item = ReferenceData.serialize(clone.item);
    clone.reason = ReferenceData.serialize(clone.reason);
    clone.qualification = ReferenceData.serialize(clone.qualification);
    if (clone.state) {
      clone.state = ReferenceData.serialize(clone.state);
    }

    return snakeifyKeys(clone);
  }

  static deserialize(payload: any): ConditionUpdate {
    if (payload === null) {
      return null;
    }

    const condition: any = camelizeKeys(payload);
    condition.state = ReferenceData.deserialize(condition.state);
    condition.reason = ReferenceData.deserialize(condition.reason);
    condition.item = ReferenceData.deserialize(condition.item);
    condition.qualification = ReferenceData.deserialize(condition.qualification);

    return new ConditionUpdate(condition);
  }
}
