import { camelizeKeys } from '@shared/helpers/serialization';

export class SubjectOption {
  code: string;
  title: string;
  description: string;
  type: string;
  effectiveLevel: number;
  effectiveFromDate: string;
  effectiveToDate: string;

  static deserialize(payload: any): SubjectOption {
    return new SubjectOption(camelizeKeys(payload));
  }
  constructor(data: any) {
    Object.assign(this, data);
  }
}
