export const APPLICATION_STATE = {
  REVIEW: 'in_review',
  PROGRESS: 'in_progress',
  INT_HOLD: 'international_hold',
  SUBMITTED: 'submitted',
  REJECTED: 'rejected',
  UNREADY: 'unready',
  NOT_SUBMITTED: 'not_submitted',
  DRAFT: 'draft',
  BEING_ASSESSED: 'being_assessed',
  INCOMPLETE: 'incomplete',
  CONDITIONAL_OFFER: 'conditional_offer_of_place',
  OFFER_OF_PLACE: 'full_offer_of_place',
  OFFER_ACCEPTED: 'offer_of_place_accepted',
  OFFER_DECLINED: 'offer_of_place_declined',
  OFFER_DEFERRED: 'offer_of_place_deferred',
  AGREEMENT_AVAILABLE: 'has_offer',
  AGREEMENT_ACCEPTED: 'accepted_offer',
  AGREEMENT_DECLINED: 'declined_offer',
  FULLY_ENROLLED: 'fully_enrolled',
  WITHDRAWN: 'all_withdrawn',
  DEFERRAL_REQUESTED: 'deferral_requested',
  NCEA_EMBARGO: 'ncea_embargo',
  OFFER_IN_PROGRESS: 'offer_in_progress',
  DEFER_OFFER_IN_PROGRESS: 'defer_offer_in_progress',
  OFFER_OF_PLACE_ACCEPT_DECLINE: 'full_offer_of_place_can_process',
};

export const APPLICATION_CHANGE_STATE = {
  CHANGE_IN_PROGRESS: 'change_in_progress',
  SHADOW: 'shadow',
  STUDENT_PENDING: 'student_pending',
};

export const COE_STATES = {
  SUBMITTED: 'coe_submitted',
  COMPLETE: 'coe_complete',
  APPROVED: 'coe_approved',
  IN_PROGRESS: 'coe_in_progress',
  WITHDRAWN: 'coe_withdrawn',
  UNREADY: 'coe_unready',
  UNCERTAIN: 'coe_uncertain',
  DRAFT: 'coe_draft',
  DELETED: 'coe_deleted',
  DECLINED: 'coe_declined',
  FULLY_ENROLLED: 'coe_fully_enrolled',
};

export const OFFER_DECISION_STATE = {
  DRAFT_DEFER: 'draft_defer',
  SUBMITTED: 'submitted',
  DRAFT_ACCEPT: 'draft_accept',
};

export const ENROLMENT_STATE = {
  IN_PROGRESS: 'in_progress',
  IN_REVIEW: 'in_review',
  HAS_OFFER: 'has_offer',
  ACCEPTED_OFFER: 'accepted_offer',
  DECLINED_OFFER: 'declined_offer',
  FULLY_ENROLLED: 'fully_enrolled',
  SUBMITTED: 'submitted',
  ENROLLED: 'enrolled',
  WITHDRAWN: 'withdrawn',
  UNREADY: 'unready',
  NOT_SUBMITTED: 'not_submitted',
  UNCERTAIN: 'uncertain',
  DECLINED: 'declined',
};

export const CONDITION_STATE = {
  APPLIED: 'applied',
  MET: 'met',
  REMOVED: 'removed',
  RECEIVED: 'received',
  PENDING: 'pending',
};

export const CONDITION_ITEM = {
  INCOMPLETE: 'incomplete',
};

export const FLASH_MESSAGE_REF = {
  IMPORTANT_DOCS: 'important_docs',
  REQUIRED_INFO: 'required_info',
};

export const IMPORTANT_CATEGORIES = {
  OFFER_PLACE_LETTER: 'OFFER_PLACE_LETTER',
  OFFER_PLACE_COND: 'OFFER_PLACE_COND',
  OFFER_LETTER: 'OFFER_LETTER',
  NOC_LETTER: 'NOC_LETTER',
  UC_OFFER_PLACE: 'UC_OFFER_PLACE',
  UC_OFFER_PLACE_COND: 'UC_OFFER_PLACE_COND',
  UC_INCOMPLETE: 'UC_INCOMPLETE',
  APP_UPDATE: 'APP_UPDATE',
  WELCOME_TO_UC_LETTER: 'WELCOME_TO_UC_LETTER',
};

export const SUMMARY_TYPES = {
  APPLICATION_ENROLMENTS: 'applicationEnrolments',
  APPLICATION: 'application',
  ENROLMENT: 'enrolment',
  CHANGE_OF_ENROLMENT: 'changeOfEnrolment',
  CHANGE_OF_QUALIFICATION: 'changeOfQualification',
};

export const CHANGE_ACTIONS = {
  DROPPED: 'drop',
  ADDED: 'add',
  NONE: 'none',
  UNDO: 'undo',
  CHANGED: 'change',
};

export const COURSE_STATE = {
  DRAFT: 'draft',
  SELECTED: 'selected',
  NOT_SELECTED: 'not_selected',
  CLOSED: 'closed',
  WITHDRAWN: 'withdrawn',
  FULLY_ENROLLED: 'fully_enrolled',
  DECLINED: 'declined',
};

export const COURSE_STATE_COLOURS = {
  [COURSE_STATE.DRAFT]: 'yellow',
  [COURSE_STATE.NOT_SELECTED]: 'orange',
  [COURSE_STATE.SELECTED]: 'green',
  [COURSE_STATE.CLOSED]: 'grey',
  [COURSE_STATE.WITHDRAWN]: 'grey',
};

export const COURSE_STATUS = {
  APPROVED: 'approved',
  BEING_ASSESSED: 'being_assessed',
  DECLINED: 'declined',
  FULLY_ENROLLED: 'fully_enrolled',
  PENDING: 'pending',
  REMOVED: 'removed',
  WAITLISTED: 'jsms_waitlist',
  WITHDRAWN: 'withdrawn',
  LATE_ADD: 'late_add',
};

export const INFO_MODAL_STATES = {
  ADD: 'Late add',
  DROP: 'Late drop',
  SELF_PACED: 'Self-paced date check',
  THESIS: 'Thesis date check',
};

export const COURSE_STATUS_COLOURS = {
  [COURSE_STATUS.APPROVED]: 'green',
  [COURSE_STATUS.FULLY_ENROLLED]: 'green',
  [COURSE_STATUS.BEING_ASSESSED]: 'blue',
  [COURSE_STATUS.PENDING]: 'blue',
  [COURSE_STATUS.DECLINED]: 'grey',
  [COURSE_STATUS.WITHDRAWN]: 'grey',
  [COURSE_STATUS.WAITLISTED]: 'blue',
};

export const COURSE_RESULT_STATE = {
  ENTERED: 'entered',
  RELEASED_TO_RECORDS: 'released_to_registry',
  RELEASED_TO_STUDENT: 'released_to_student',
};

export const ASSOCIATED_PERSON_TYPE = {
  REFEREE_ONE: 'referee1',
  REFEREE_TWO: 'referee2',
  REFEREE_THREE: 'referee3',
  SCHOOL: 'school',
  STAR_SUPPORT: 'star_course_support',
  STAR_COORDINATOR: 'star_coordinator',
  MCED_FACILITATOR: 'MCED FACILITATOR',
  MCED_TEACHER: 'MCED TEACHER',
  MCED_TEACHER_AIDE: 'MCED TEACHER AIDE',
  MCED: 'MCED',
};

export const MCED_QUAL_TYPES = {
  MCED: 'MCED',
  ASLA: 'ASLA',
};

export const INDEPENDENT_ENROLMENT_STATES = {
  ENROLMENT_STATE_CREATED: 'created',
  ENROLMENT_STATE_CANCELLED: 'cancelled',
  ENROLMENT_STATE_SUBMITTED: 'submitted',
  ENROLMENT_STATE_PROVISIONING: 'provisioning',
  ENROLMENT_STATE_ID_VERIFICATION: 'identity_verification',
  ENROLMENT_STATE_ENROLLED: 'enrolled',
  ENROLMENT_STATE_WITHDRAWN: 'withdrawn',
};

export const PAYMENT_STATES = {
  PAYMENT_STATE_NONE: 'no_payment_attempted',
  PAYMENT_STATE_WAITING: 'waiting_for_payment',
  PAYMENT_STATE_DECLINED: 'declined',
  PAYMENT_STATE_PAID: 'paid',
  PAYMENT_STATE_REFUNDED: 'refunded',
};

export const APPLICATION_CREATION_SOURCE = {
  CREATION_SOURCE_STUDENT_FIRST: 'SFAPP',
  CREATION_SOURCE_UC_ONLINE: 'UCOAPP',
};

export const STUDENT_VIEWABLE_STATES = {
  YES: 'Yes',
  NO: 'No',
};

export const CATEGORY_OPTION_APPENDATION = {
  VISIBLE_TO_STUDENT: '(Visible to student)',
  HIDDEN_FROM_STUDENT: '(Hidden from student)',
};

export enum CardContext {
  ENROLMENT = 'ENROLMENT',
  CHANGE_OF_ENROLMENT = 'CHANGE_OF_ENROLMENT',
  APPLICATION = 'APPLICATION',
  TASK = 'TASK',
  DECISION = 'DECISION',
}
