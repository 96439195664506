<div *ngIf="showList" class="file-list-container">
    <div class="file-list-header clearfix">
      <h3 class="header-text" [id]="category.code">{{category.description}}</h3>
    </div>
    <div class="file-list-content">
      <div *ngIf="category.documentType === 'application'">
        <div *ngFor="let yearGroup of documents">
          <div class="file-group-year">{{yearGroup.year}}</div>
          <div class="file-group-list">
            <uc-file-list [files]="yearGroup.files">
            </uc-file-list>
          </div>
        </div>
      </div>
      <div *ngIf="category.documentType === 'applicant'">
        <uc-file-list [files]="documents">
        </uc-file-list>
      </div>
    </div>
  </div>
