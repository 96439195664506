import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import strings from '@constants/strings.constants';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import { group, control, UCElementGroup } from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-online-address-details',
  templateUrl: './online-address-details.component.html',
  styleUrls: ['./online-address-details.component.scss'],
})
export class OnlineAddressDetailsComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() processName: string;

  onlineAddressDetailsPage: UntypedFormGroup;
  onlineAddressDetailsForm: UCElementGroup;
  currentApplicant: Applicant;
  currentApplication: Application;

  strings = strings.components.tasks.onlineAdditionalDetails;

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private onlineProcessService: OnlineProcessService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  private createForm(): UCElementGroup {
    return group({
      contactDetails: group({
        studyAddress: control({
          defaultState: AddressModel.createFrom({}),
          validators: [],
          model: 'applicant',
          path: '/studyContactDetail/currentAddress',
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressWithoutWhitespaceValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
      }),
    });
  }

  ngOnInit() {
    this.onlineAddressDetailsForm = this.createForm();
    this.onlineAddressDetailsPage = this.onlineAddressDetailsForm.asControl() as UntypedFormGroup;

    combineLatest(this.applicantService.getApplicant(), this.applicationService.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.formModel.updateFormFromModel(this.onlineAddressDetailsForm, applicant, application);
      });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.onlineAddressDetailsForm);
  }

  public checkFormValidity(): string[] {
    this.onlineAddressDetailsPage.updateValueAndValidity();
    if (this.onlineAddressDetailsPage.invalid) {
      this.log.info(this.onlineAddressDetailsPage);
      return this.formModel.getInvalidPathesInElementGroup(this.onlineAddressDetailsForm);
    } else {
      return [];
    }
  }

  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    }

    this.prepareCurrentApplicantAndApplication();

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    combineLatest(updateApplicant, updateApplication).subscribe({
      next: this.onUpdateSuccess,
      error: this.onUpdateFailed,
    });
  }

  private onUpdateSuccess = ([applicant, application]: [Applicant, Application]) => {
    if (applicant && application) {
      this.log.info('updated applicant and application successfully');
      this.next.emit();
    }
  };

  private onUpdateFailed = (err: DSHttpError) => {
    this.errors.emit();
    this.log.error('error thrown while updating applica(nt|tion):', err);
  };

  private prepareCurrentApplicantAndApplication() {
    this.formModel.updateModelFromForm(this.onlineAddressDetailsForm, this.currentApplicant, this.currentApplication);
  }
  public copyPermanentToStudyAddress() {
    const permanentAddress = this.onlineAddressDetailsPage.get('contactDetails.address').value;
    const addressCopy = cloneDeep(permanentAddress);
    this.onlineAddressDetailsPage.get('contactDetails.studyAddress').patchValue(addressCopy);
  }
}
