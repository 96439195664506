import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss'],
})
export class EmergencyContactComponent {
  @Input() emergencyDetails: UntypedFormGroup = new UntypedFormGroup({});
  @Input() copyAddressButtonText = '';
  @Input() task: Task;
  @Input() includeUpToDateDeclaration: boolean;

  @Output() copyAddress = new EventEmitter();

  strings = strings.components.organisms.profilePage.emergencyContactDetails;
  phoneStrings = strings.components.molecules.phoneSelector;
  declarationOptions = [
    {
      labelText: strings.components.organisms.profilePage.emergencyContactDetails.declaration.label,
      value: 'declarationEmergencyContactInformationCorrect',
    },
  ];

  triggerCopyAddress() {
    this.copyAddress.emit();
  }
}
