import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, ContentChild, forwardRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-no-content',
  template: `<ng-content *ngIf="isDisplayed"></ng-content>`,
})
export class YesNoOtherOptionComponent {
  isDisplayed: boolean;
}

@Component({
  selector: 'uc-yes-content',
  template: `<ng-content *ngIf="isDisplayed"></ng-content>`,
  styles: [
    `
      ::ng-deep uc-subsection:last-of-type {
        border-bottom: 1px solid #eee;
      }
    `,
  ],
})
export class YesNoContentComponent {
  isDisplayed: boolean;
}

@Component({
  selector: 'uc-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoComponent),
      multi: true,
    },
  ],
})
export class YesNoComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() questionString: string;
  @Input() required: string;
  @Input() yesOptionLabel = 'Yes';
  @Input() noOptionLabel = 'No';
  @Input() task: Task;
  @Input() path: string;
  @Input() model = null;
  @Input() questionDescription: string;
  @Input() extraDescription: string;
  @Input() conditionalDescription: string;
  @Input() testSelector = '';
  @ContentChild(YesNoOtherOptionComponent, { static: true }) otherOption: YesNoOtherOptionComponent;
  @ContentChild(YesNoContentComponent, { static: true }) contentOption: YesNoOtherOptionComponent;

  hasValue = false;
  yesNoState = new UntypedFormControl('');
  yesNoGroupName = 'yesNoGroup-' + Math.random().toString(36).substring(5);

  constructor() {}

  private propagateChange = (_: any) => {};

  get showConditionalDescription() {
    if (this.conditionalDescription) {
      const showIfYesSelected = this.yesOptionLabel === this.conditionalDescription && this.contentOption.isDisplayed;
      const showIfNoSelected = this.noOptionLabel === this.conditionalDescription && this.otherOption.isDisplayed;
      return showIfYesSelected || showIfNoSelected;
    }
    return true;
  }

  get testSelectorId() {
    return `${this.testSelector}-yes-no`;
  }

  ngOnInit() {
    this.yesNoState.valueChanges.pipe(filter((x) => !!x.match(/^(hide|show)$/))).subscribe((v) => {
      this.hasValue = true;
      const isYes = v === 'show';
      if (this.contentOption) {
        this.contentOption.isDisplayed = isYes;
      }
      if (this.otherOption) {
        this.otherOption.isDisplayed = !isYes;
      }

      this.propagateChange(v);
    });
  }

  ngAfterViewInit() {
    if (this.task && this.path) {
      document.getElementById('yesnohint').setAttribute('path', this.path);
    }
  }

  /**
   * Write a new value to the element.
   */
  writeValue(obj: any): void {
    if (obj == null || obj === '') {
      return;
    }
    this.hasValue = true;
    this.yesNoState.setValue(obj);
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Set the function to be called'
   * when the control receives a touch event.
   */
  registerOnTouched(fn: any): void {}
}
