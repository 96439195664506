import { Component, OnInit, Input } from '@angular/core';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UCError } from '@shared/models/errors';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ApplicationService } from '@shared/services/application/application.service';
import { Application } from '@shared/models/application';
import { Router } from '@angular/router';
import { internalUrls } from '@constants/internalUrls';

@Component({
  selector: 'uc-future-year-apply',
  templateUrl: './future-year-apply.component.html',
})
export class FutureYearApplyComponent extends AbstractBaseTask implements OnInit {
  @Input() processName: string;

  public applicationYear = new UntypedFormControl('', Validators.required);
  existingApplicationYears = [];

  strings = {
    header: 'What year are you applying for?',
  };

  constructor(private router: Router, private applicationService: ApplicationService) {
    super();
  }

  ngOnInit() {
    // get application years and filter from ref data selector
    this.applicationService.getApplications().subscribe((summs) => {
      this.existingApplicationYears = summs.map((summary) => summary.academicYear);
    });
  }

  updateFormValidity(err: UCError): void {}
  update() {
    // create application
    // route to process url
    if (!this.applicationYear.value) {
      this.errors.emit({ code: null, data: 'You must supply an application year' });
      throw new Error('You must supply an application year');
    }

    if (this.applicationYear.valid && this.applicationYear.value && this.processName) {
      const newApplication = new Application({
        academicYear: this.applicationYear.value,
        processName: { code: this.processName },
      });
      this.applicationService.findOrCreateApplication(newApplication).subscribe(
        (result) => {
          this.router.navigate(internalUrls.processPage(this.processName, this.applicationYear.value.code));
        },
        (err) => {
          if (err.status === 409) {
            // Duplicate application created since the user visited this page
            // Navigate to that one anyways
            this.router.navigate(internalUrls.dashboard);
            return;
          } else {
            this.errors.emit(err);
          }
        },
      );
    }
  }
}
