<div [formGroup]="namesModel">
  <uc-subsection>
    <uc-subsection-question>
      {{ strings.legalNameQuestion }}<uc-required-field></uc-required-field>
    </uc-subsection-question>

    <uc-full-name
      [task]="task"
      formControlName="legalName"
      nameLabel="{{ strings.legalNameTabHeading }}"
      [shouldShowPreferred]="showPreferredOption"
      (setPreferred)="setPreferred(0)"
      testSelector="legal-0"
    >
    </uc-full-name>

    <div *ngIf="name.value.length">
      <div formArrayName="name">
        <div
          *ngFor="let otherName of name.controls; let last = last; let first = first; let i = index"
          [formGroupName]="i"
        >
          <uc-subsection-question *ngIf="first">{{ strings.otherNamesHeader }}</uc-subsection-question>

          <uc-full-name
            [task]="task"
            [formControl]="otherName"
            nameLabel="{{ tabHeadings[i] }}"
            (setPreferred)="setPreferred(i + 1)"
            (delete)="removeOtherName(i)"
            [testSelector]="'legal-' + i"
            alternatePath="name/{{ i }}"
          >
          </uc-full-name>

          <uc-add-new-button
            *ngIf="last && name.controls.length < 3"
            buttonHeading="Add {{ tabHeadings[i + 1] }}"
            buttonText="{{ strings.addNameButtonText }}"
            (click)="addName()"
          >
          </uc-add-new-button>
        </div>
      </div>
    </div>

    <uc-add-new-button
      *ngIf="!name.value.length"
      buttonHeading="{{ strings.addNameButtonHeading }}"
      buttonText="{{ strings.addNameButtonText }}"
      (click)="addName()"
    >
    </uc-add-new-button>
  </uc-subsection>
  <div *ngIf="name.value.length && !hideDocUpload">
    <uc-subsection>
      <uc-subsection-question>{{ strings.uploadEvidence }}</uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.uploadDescription }}</uc-subsection-minor-text>
      <uc-file-upload
        [year]="applicationYear"
        title="Name change document"
        categoryCode="NAMECHANGE"
        usedInTask="true"
      ></uc-file-upload>
    </uc-subsection>
  </div>
</div>
