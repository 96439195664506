<!-- Mobile sidebar/stages summary: -->
<div class="review-panel-summary">
  <button [attr.data-cy]="'nav'" class="review-panel-btn" (click)="toggleNav()">
    <uc-icon iconType="chevron-right" iconWidth="26" iconHeight="26"></uc-icon>
  </button>
</div>

<div class="review-panel" [class.show]="showSidebar">
  <button [attr.data-cy]="'review'" class="review-panel-btn" (click)="toggleNav()">
    <uc-icon iconType="chevron-left" iconWidth="26" iconHeight="26"></uc-icon>
  </button>
  <div class="review-panel__container">
    <div class="review-panel__content">
      <uc-study-plan-summary-bar [summary]="summary" [buckets]="buckets"></uc-study-plan-summary-bar>
      <uc-message-banner *ngIf="submissionError" type="error">{{ submissionError }}</uc-message-banner>
      <div *ngFor="let bucket of buckets" class="review-panel__content-section review-panel__bucket">
        <h4 class="review-panel__section-heading">
          <span class="review-panel__semester-title">{{ bucket.title }} &nbsp;&nbsp;&bull;</span>
          <span class="review-panel__section-tally">{{ bucket.points }}&nbsp;{{ strings.points }}</span>
        </h4>
        <uc-course-summary-card
          *ngFor="let course of bucket.courses"
          [compact]="true"
          [otherQualCode]="otherQualCode"
          [course]="course"
          [errorTriggered]="errorTriggered"
          (removeCourse)="removeACourse($event)"
          (undoChangeAction)="undoChangeAction($event)"
        >
        </uc-course-summary-card>
      </div>
    </div>
  </div>
</div>

<div class="review-panel__footer clearfix">
  <uc-button testSelector="review" class="review-panel__submit-btn" (click)="submitForReview()">{{
    strings.save
  }}</uc-button>
</div>
