import { Injectable } from '@angular/core';
import { snakeCase } from 'lodash-es';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@environment';
import { mockData as qualOccurrencesMockData } from '@shared/applicant/qualification/qual-occurrences.data.mock';
import { mockData as qualSummaryMockData } from '@shared/applicant/qualification/qual-summary.data.mock';
import { mockData as qualMockData } from '@shared/applicant/qualification/qual.data.mock';
import { snakeifyKeys } from '@shared/helpers/serialization';
import { Qualification, QualificationSummary } from '@shared/models/qualification';
import { PagedQualificationOccurrenceList, QualificationOccurrence } from '@shared/models/qualification-occurrence';
import { QualificationResult } from '@shared/models/qualification-result';
import { DataService, DSHttpError } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { qualificationList } from '@shared/services/qualification/mock-qualification-response';

@Injectable()
export class QualificationService {
  private serviceUrl = `${environment.apiRoot}/qualification`;
  private log: Logger;
  private qualification$ = new BehaviorSubject<Qualification>(null);
  private qualificationError$ = new Subject<DSHttpError>();
  private qualificationResult$ = new BehaviorSubject<QualificationResult>(null);
  private qualificationResultError$ = new Subject<DSHttpError>();
  private concurrentQualificationSummary$ = new BehaviorSubject<QualificationSummary[]>(null);
  private concurrentQualificationSummaryError$ = new Subject<DSHttpError>();

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get qualification(): Observable<Qualification> {
    return this.qualification$.asObservable();
  }

  get qualificationError(): Observable<DSHttpError> {
    return this.qualificationError$.asObservable();
  }

  get qualificationResult(): Observable<QualificationResult> {
    return this.qualificationResult$.asObservable();
  }

  get qualificationResultError(): Observable<DSHttpError> {
    return this.qualificationResultError$.asObservable();
  }

  get concurrentQualificationSummary(): Observable<QualificationSummary[]> {
    return this.concurrentQualificationSummary$.asObservable();
  }

  get concurrentQualificationSummaryError(): Observable<DSHttpError> {
    return this.concurrentQualificationSummaryError$.asObservable();
  }

  /**
   * Makes a request to the Qualification microservice to retrieve a
   * list of Qualifications for the given academic year.
   * The request requires the currentUser's token to be in the Auth header
   * of the request.
   *
   * @memberOf QualificationService
   */
  getQualifications(year: string, processName?: string): Observable<QualificationSummary[]> {
    const url = [this.serviceUrl, year].join('/');
    return this.dataService
      .fetch(url, {
        success$: this.qualificationResult$,
        error$: this.qualificationResultError$,
        deserialize: (payload) => payload.qualification.map(QualificationSummary.deserialize),
        requestOptions: {
          params: processName ? { process: processName } : null,
        },
      })
      .pipe(catchError(() => EMPTY));
  }

  /**
   * Makes a request to the Qualification microservice to retrieve a
   * specific Qualification with the given code in the given academic year.
   * The request requires the currentUser's token to be in the Auth header
   * of the request.
   *
   * @memberOf QualificationService
   */
  getQualification(year: string, code: string): Observable<Qualification> {
    const encodedCode = encodeURIComponent(code).replace(/%2F/g, '/');
    const url = [this.serviceUrl, year, encodedCode].join('/');
    return this.dataService
      .fetch(url, {
        success$: this.qualification$,
        error$: this.qualificationError$,
        deserialize: Qualification.deserialize,
      })
      .pipe(catchError(() => EMPTY));
  }

  /**
   * Makes a request to the Qualification microservice to retrieve Qualifications
   * with the given URIs in the given academic year.
   * The request requires the currentUser's token to be in the Auth header
   * of the request.
   * qualification/<year>/<code>;
   *
   * @memberOf QualificationService
   */
  getQualificationsByURIs(year: string, uris: string[]): Observable<QualificationResult> {
    const url = [this.serviceUrl, year].join('/');
    const body = { uris };
    return this.dataService.post(url, body, {
      success$: this.qualificationResult$,
      error$: this.qualificationResultError$,
      deserialize: QualificationResult.deserialize,
      ignoredErrorStatuses: [404, 422],
    });
  }

  getConcurrentQualifications(year: string, processName: string, qualificationCode: string) {
    const encodedCode = encodeURIComponent(qualificationCode);
    const url = `${this.serviceUrl}/${year}?process=${processName}&concurrent_with=${encodedCode}`;
    return this.dataService
      .fetch(url, {
        success$: this.concurrentQualificationSummary$,
        error$: this.concurrentQualificationSummaryError$,
        deserialize: (payload) => payload.qualification.map(QualificationSummary.deserialize),
      })
      .pipe(catchError(() => EMPTY));
  }

  getQualificationOccurrences(
    year: string,
    qualificationCode: string,
    params = {},
  ): Observable<QualificationOccurrence[]> {
    const url = `${this.serviceUrl}/${qualificationCode}/occurrence/${year}`;
    return this.dataService.fetch(url, {
      deserialize: QualificationOccurrence.deserialize,
      requestOptions: {
        params: snakeifyKeys(params),
      },
      ignoredErrorStatuses: [404],
    });
  }

  getQualificationOccurrence(qualOccurrenceReference: string): Observable<QualificationOccurrence> {
    const url = `${this.serviceUrl}/occurrence/${qualOccurrenceReference}`;
    return this.dataService.fetch(url, {
      deserialize: QualificationOccurrence.deserialize,
      ignoredErrorStatuses: [404],
    });
  }

  createQualificationOccurrence(qualOccurrence: QualificationOccurrence[]) {
    const url = `${this.serviceUrl}/staff/occurrence/`;
    const body = QualificationOccurrence.serialize(qualOccurrence);
    return this.dataService.put(url, body, {
      deserialize: QualificationOccurrence.deserialize,
    });
  }

  getAllQualifications(): Observable<QualificationResult> {
    const url = `${this.serviceUrl}/all`;
    return this.dataService.fetch(url, {
      deserialize: QualificationResult.deserialize,
    });
  }

  static buildUrlWithQueryString(url: string, queryParams: QualOccurrenceQueryParams) {
    if (!queryParams || Object.keys(queryParams).length === 0) {
      return url;
    }
    const queryString = Object.keys(queryParams)
      .map((key) => {
        const value = queryParams[key];
        const arrOfValues = Array.isArray(value) ? (queryParams[key] as string[]) : [value];
        return arrOfValues.map((val) => `${snakeCase(key)}=${encodeURIComponent(val)}`).join('&');
      })
      .join('&');
    return `${url}?${queryString}`;
  }

  getQualificationStaffOccurrences(
    queryParams: QualOccurrenceQueryParams,
  ): Observable<PagedQualificationOccurrenceList> {
    const url = `${this.serviceUrl}/staff/occurrence/`;
    const fullUrl = QualificationService.buildUrlWithQueryString(url, queryParams);
    return this.dataService.fetch(fullUrl, {
      deserialize: PagedQualificationOccurrenceList.deserialize,
      ignoredErrorStatuses: [404],
    });
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
// Due to the way mocks services are currently built, we need to ignore unused parameters */
export class MockQualificationService {
  mockQualification = Qualification.deserialize(qualMockData());
  mockQualifications = qualificationList.qualification.map(Qualification.deserialize);
  mockSummary = qualSummaryMockData().qualification.map(QualificationSummary.deserialize);
  mockQualificationOccurrences = QualificationOccurrence.deserialize(qualOccurrencesMockData());
  mockpagedQualificationOccurrence = {
    qualificationOccurrence: this.mockQualificationOccurrences,
    page: {
      pageNumber: 1,
      pageSize: 1,
      itemsPerPage: 40,
      totalItems: 3,
      totalPages: 1,
      /* eslint-disable-next-line id-blacklist */
      number: 1,
    },
  };
  constructor(mockQualification?: Qualification, mockSummary?: QualificationSummary[]) {
    if (mockQualification) {
      this.mockQualification = mockQualification;
    }
    if (mockSummary) {
      this.mockSummary = mockSummary;
    }
  }

  getQualifications(year: string, processName?: string): Observable<QualificationSummary[]> {
    return of(this.mockSummary);
  }

  getQualification(year: string, code: string): Observable<Qualification> {
    return of(
      Qualification.deserialize(qualificationList.qualification.find((q) => q.code === code)) || this.mockQualification,
    );
  }

  getConcurrentQualifications(
    year: string,
    processName: string,
    qualificationCode: string,
  ): Observable<QualificationSummary[]> {
    return this.getQualifications('');
  }

  getQualificationsByURIs(year: string, uris: string[]): Observable<QualificationResult> {
    const codes = uris.map((uri) => {
      const parts = uri.split('/');
      return parts[parts.length - 1];
    });
    const viewModel = this.mockQualifications.filter((qual) => {
      return codes.find((code) => qual.code === code);
    });

    return of({ qualification: viewModel });
  }

  getQualificationOccurrences(
    year: string,
    qualificationCode: string,
    params = {},
  ): Observable<QualificationOccurrence[]> {
    return of(this.mockQualificationOccurrences);
  }

  getQualificationOccurrence(qualOccurrenceReference: string) {
    return of(this.mockQualificationOccurrences[0]);
  }

  createQualificationOccurrence(qualOccurrence: QualificationOccurrence[]) {
    return of(this.mockQualificationOccurrences);
  }

  getAllQualifications() {
    return of({ qualification: this.mockQualifications });
  }

  getQualificationStaffOccurrences(
    queryParams: QualOccurrenceQueryParams,
  ): Observable<PagedQualificationOccurrenceList> {
    return of(this.mockpagedQualificationOccurrence);
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */

export const qualificationFactory = (dataService, loggingService) => {
  if (environment.useFakeBackend.qualification) {
    return new MockQualificationService();
  }
  return new QualificationService(dataService, loggingService);
};

export const qualificationServiceProvider = {
  provide: QualificationService,
  useFactory: qualificationFactory,
  deps: [DataService, LoggingService],
};

export interface QualOccurrenceQueryParams {
  qualification?: string;
  citizenship?: string;
  intakeName?: string;
  academicYear?: string;
  startDate?: string;
  applicationsCloseDate?: string;
  site?: string;
  source?: string;
  state?: string;
  page?: number;
  orderBy?: string;
  orderDirection?: string;
  pageSize?: number;
}
