import { deepClone } from '@shared/helpers/general';

// TODO deprecate this
export const qualificationList = {
  qualification: [
    {
      code: 'MTCHGLN',
      description: 'Description',
      guidance: 'BA students specialise in two areas; either a major and minor subject, or two majors.',
      includes_thesis: false,
      default_curriculum_group: '2',
      is_postgraduate: false,
      courseSelectionGuidanceText: '',
      categories: [
        {
          category: 'referee_three',
        },
        {
          category: 'additional_information_cac',
        },
        {
          category: 'our_uc_manual_approve',
        },
      ],
      level: {
        code: '7',
      },
      organisation_unit: {
        code: 'Arts, College of',
      },
      paper_form_url: '',
      subject_options: [
        {
          label: 'Major',
          level: 1,
          option_lists: [
            {
              required: true,
              order: 1,
              options: [
                {
                  code: 'Art History and Theory',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Art History and Theory',
                  type: 'MAJOR',
                },
                {
                  code: 'Accounting',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Accounting',
                  type: 'MINOR',
                },
                {
                  code: 'Art History and Theory',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Art History and Theory',
                  type: 'MAJOR',
                },
                {
                  code: 'Accounting',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Accounting',
                  type: 'MINOR',
                },
              ],
            },
            {
              required: true,
              order: 2,
              options: [
                {
                  code: 'Art History and Theory',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Art History and Theory',
                  type: 'MAJOR',
                },
                {
                  code: 'Accounting',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Accounting',
                  type: 'MINOR',
                },
                {
                  code: 'Art History and Theory',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Art History and Theory',
                  type: 'MAJOR',
                },
                {
                  code: 'Accounting',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Accounting',
                  type: 'MINOR',
                },
              ],
            },
          ],
        },
        {
          label: 'Minor',
          level: 2,
          option_lists: [
            {
              required: true,
              order: 1,
              options: [
                {
                  code: 'Economics1',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 1',
                  type: 'MAJOR',
                },
                {
                  code: 'Economics2',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 2',
                  type: 'MINOR',
                },
              ],
            },
            {
              required: true,
              order: 2,
              options: [
                {
                  code: 'Economics1',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 1',
                  type: 'MAJOR',
                },
                {
                  code: 'Economics2',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 2',
                  type: 'MINOR',
                },
              ],
            },
          ],
        },
      ],

      title: 'Master of Teaching and Learning',
      qualificationOccurrences: [
        {
          code: 'MTCHGLN - 22/01/2020 FT DOMESTIC (N)',
          intake_name: 'Nelson - full time',
          type: {
            code: 'INTAKE',
          },
          source: {
            code: 'JSMS',
          },
          academic_year: 2021,
          order: 1,
          commencement_date: '2019-01-01',
          applications_open_date: '2018-06-01',
          applications_close_date: '2019-06-01',
          citizenship_category: [{ code: 'domestic' }],
          site: {
            code: 'Nelson',
          },
          full_time: true,
          delivery_mode: '',
          external_reference: '1234.5678',
          internal_reference: 'foo',
          qualification: {
            code: 'DIPTCHLN (EARLY CHILDHOOD)',
          },
        },
      ],
    },
    {
      code: 'BA',
      description: '',
      guidance: 'BA students specialise in two areas; either a major and minor subject, or two majors.',
      includes_thesis: false,
      is_postgraduate: false,
      default_curriculum_group: '1',
      categories: [
        {
          category: 'ca_compliance',
        },
      ],
      level: {
        code: '7',
      },
      organisation_unit: {
        code: 'Arts, College of',
      },
      paper_form_url: '',
      subject_options: [
        {
          label: 'Major',
          level: 1,
          option_lists: [
            {
              required: true,
              order: 1,
              options: [
                {
                  code: 'Art History and Theory',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Art History and Theory',
                  type: 'MAJOR',
                },
                {
                  code: 'Accounting',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Accounting',
                  type: 'MINOR',
                },
              ],
            },
          ],
        },
      ],

      title: 'Bachelor of Arts',
      qualificationOccurrences: [],
    },
    {
      code: 'BCOM',
      order: 100,
      title: 'Bachelor of Commerce',
      organisation_unit: {
        code: 'Business and Law, College of',
      },
      level: {
        code: '7',
      },
      description: '',
      paper_form_url: '',
      guidance: 'You must specialise in one major and may also choose a minor.',
      is_postgraduate: false,
      includes_thesis: false,
      subject_options: [
        {
          label: 'Major',
          level: 1,
          option_lists: [
            {
              required: true,
              order: 1,
              options: [
                {
                  code: 'Art History and Theory',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Art History and Theory',
                  type: 'MAJOR',
                },
                {
                  code: 'Accounting',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Accounting',
                  type: 'MINOR',
                },
              ],
            },
            {
              required: true,
              order: 2,
              options: [
                {
                  code: 'Art History and Theory',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Art History and Theory',
                  type: 'MAJOR',
                },
                {
                  code: 'Accounting',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Accounting',
                  type: 'MINOR',
                },
              ],
            },
          ],
        },
        {
          label: 'Minor',
          level: 2,
          option_lists: [
            {
              required: true,
              order: 1,
              options: [
                {
                  code: 'Economics1',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 1',
                  type: 'MAJOR',
                },
                {
                  code: 'Economics2',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 2',
                  type: 'MINOR',
                },
              ],
            },
            {
              required: true,
              order: 2,
              options: [
                {
                  code: 'Economics1',
                  description: '',
                  effective_from_date: '1873-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 1',
                  type: 'MAJOR',
                },
                {
                  code: 'Economics2',
                  description: '',
                  effective_from_date: '2017-01-01',
                  effective_level: 1,
                  effective_to_date: '2999-01-01',
                  title: 'Economics 2',
                  type: 'MINOR',
                },
              ],
            },
          ],
        },
      ],
      primary_options: {
        label: 'Major',
        option_lists: [
          {
            required: true,
            order: 1,
            options: [
              {
                code: 'Accounting',
                title: 'Accounting',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Business Economics',
                title: 'Business Economics',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Economics',
                title: 'Economics',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '1873-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Finance',
                title: 'Finance',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '1980-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Human Resource Management',
                title: 'Human Resource Management',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Information Systems',
                title: 'Information Systems',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'International Business',
                title: 'International Business',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Management',
                title: 'Management',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '1873-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Marketing',
                title: 'Marketing',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Operations and Supply Chain Management',
                title: 'Operations and Supply Chain Management',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2014-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Strategy and Entrepreneurship',
                title: 'Strategy and Entrepreneurship',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Taxation and Accounting',
                title: 'Taxation and Accounting',
                description: '',
                type: 'MAJOR',
                effective_level: 1,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
            ],
          },
          {
            required: false,
            order: 2,
            options: [
              {
                code: 'Accounting',
                title: 'Accounting',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Business Economics',
                title: 'Business Economics',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Economics',
                title: 'Economics',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '1873-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Finance',
                title: 'Finance',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '1980-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Human Resource Management',
                title: 'Human Resource Management',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Information Systems',
                title: 'Information Systems',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'International Business',
                title: 'International Business',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Management',
                title: 'Management',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '1873-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Marketing',
                title: 'Marketing',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Operations and Supply Chain Management',
                title: 'Operations and Supply Chain Management',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2014-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Strategy and Entrepreneurship',
                title: 'Strategy and Entrepreneurship',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Taxation and Accounting',
                title: 'Taxation and Accounting',
                description: '',
                type: 'SECOND MAJOR',
                effective_level: 2,
                effective_from_date: '2000-01-01',
                effective_to_date: '2999-01-01',
              },
            ],
          },
          {
            required: false,
            order: 3,
            options: [
              {
                code: 'Accounting',
                title: 'Accounting',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Business Economics',
                title: 'Business Economics',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Economics',
                title: 'Economics',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Finance',
                title: 'Finance',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Human Resource Management',
                title: 'Human Resource Management',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Information Systems',
                title: 'Information Systems',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'International Business',
                title: 'International Business',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Management',
                title: 'Management',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '1980-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Marketing',
                title: 'Marketing',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Operations and Supply Chain Management',
                title: 'Operations and Supply Chain Management',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Strategy and Entrepreneurship',
                title: 'Strategy and Entrepreneurship',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Taxation and Accounting',
                title: 'Taxation and Accounting',
                description: '',
                type: 'THIRD MAJOR',
                effective_level: 3,
                effective_from_date: '2005-01-01',
                effective_to_date: '2999-01-01',
              },
            ],
          },
        ],
      },
      secondary_options: {
        label: 'Minor',
        option_lists: [
          {
            required: false,
            order: 1,
            options: [
              {
                code: 'Accounting',
                title: 'Accounting',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Anthropology',
                title: 'Anthropology',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Art History and Theory',
                title: 'Art History and Theory',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Business and Sustainability',
                title: 'Business and Sustainability',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Chinese',
                title: 'Chinese',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Cinema Studies',
                title: 'Cinema Studies',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Classics',
                title: 'Classics',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Cultural Studies',
                title: 'Cultural Studies',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Digital Arts, Social Sciences and Humanities',
                title: 'Digital Arts, Social Sciences and Humanities',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Economics',
                title: 'Economics',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Education.',
                title: 'Education',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'English',
                title: 'English',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'English Language',
                title: 'English Language',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Entrepreneurship',
                title: 'Entrepreneurship',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'European and European Union Studies',
                title: 'European and European Union Studies',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Finance',
                title: 'Finance',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'French',
                title: 'French',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Geography',
                title: 'Geography',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'German',
                title: 'German',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'History',
                title: 'History',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Human Resource Management',
                title: 'Human Resource Management',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Human Services',
                title: 'Human Services',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Information Systems',
                title: 'Information Systems',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'International Business',
                title: 'International Business',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Japanese',
                title: 'Japanese',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Linguistics',
                title: 'Linguistics',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Marketing',
                title: 'Marketing',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Mathematics',
                title: 'Mathematics',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Media and Communication',
                title: 'Media and Communication',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Music',
                title: 'Music',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Maori and Indigenous Studies',
                title: 'Māori and Indigenous Studies',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Operations and Supply Chain Management',
                title: 'Operations and Supply Chain Management',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Philosophy',
                title: 'Philosophy',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Political Science and International Relations',
                title: 'Political Science and International Relations',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Professional and Community Engagement',
                title: 'Professional and Community Engagement',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Psychology',
                title: 'Psychology',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Russian',
                title: 'Russian',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Sociology',
                title: 'Sociology',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Spanish',
                title: 'Spanish',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Statistics',
                title: 'Statistics',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Taxation',
                title: 'Taxation',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Te Reo Maori',
                title: 'Te Reo Māori',
                description: '',
                type: 'MINOR',
                effective_level: 1,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
            ],
          },
          {
            required: false,
            order: 2,
            options: [
              {
                code: 'Accounting',
                title: 'Accounting',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Anthropology',
                title: 'Anthropology',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Art History and Theory',
                title: 'Art History and Theory',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Business and Sustainability',
                title: 'Business and Sustainability',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Chinese',
                title: 'Chinese',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Cinema Studies',
                title: 'Cinema Studies',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Classics',
                title: 'Classics',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Cultural Studies',
                title: 'Cultural Studies',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Digital Arts, Social Sciences and Humanities',
                title: 'Digital Arts, Social Sciences and Humanities',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Economics',
                title: 'Economics',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Education.',
                title: 'Education',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'English',
                title: 'English',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'English Language',
                title: 'English Language',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Entrepreneurship',
                title: 'Entrepreneurship',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'European and European Union Studies',
                title: 'European and European Union Studies',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Finance',
                title: 'Finance',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'French',
                title: 'French',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Geography',
                title: 'Geography',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'German',
                title: 'German ',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'History',
                title: 'History',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Human Resource Management',
                title: 'Human Resource Management',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Human Services ',
                title: 'Human Services ',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Information Systems',
                title: 'Information Systems',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'International Business',
                title: 'International Business',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Japanese',
                title: 'Japanese',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Linguistics',
                title: 'Linguistics',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Marketing',
                title: 'Marketing',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Mathematics',
                title: 'Mathematics',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Media and Communication',
                title: 'Media and Communication',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Music',
                title: 'Music',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Maori and Indigenous Studies',
                title: 'Māori and Indigenous Studies',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Operations and Supply Chain Management',
                title: 'Operations and Supply Chain Management',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Philosophy',
                title: 'Philosophy',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Political Science and International Relations',
                title: 'Political Science and International Relations',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Professional and Community Engagement',
                title: 'Professional and Community Engagement',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Psychology',
                title: 'Psychology',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Russian',
                title: 'Russian',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Sociology',
                title: 'Sociology',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Spanish',
                title: 'Spanish',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Statistics',
                title: 'Statistics',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Taxation',
                title: 'Taxation',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
              {
                code: 'Te Reo Maori',
                title: 'Te Reo Māori',
                description: '',
                type: 'SECOND MINOR',
                effective_level: 2,
                effective_from_date: '2017-01-01',
                effective_to_date: '2999-01-01',
              },
            ],
          },
        ],
      },
      tertiary_options: null,
      use_myuc_course_selector: true,
      course_selection_guidance_text: '',
      requires_program_entry: false,
      categories: [
        {
          category: 'new_student',
        },
        {
          category: 'our_uc_manual_approve',
        },
        {
          category: 'ca_compliance',
        },
      ],
      default_curriculum_group: '2288.891537',
      qualificationOccurrences: [],
    },
  ],
};

export const baQualificationFull = {
  qualification: {
    code: 'BA',
    title: 'Bachelor of Arts',
    description: 'Bachelor of Arts',
    organisation_unit: null,
    level: null,
    subject_options: [
      {
        '1': [
          {
            code: 'Art History and Theory',
          },
          {
            code: 'Accounting',
          },
        ],
      },
    ],
    primary_options: [
      {
        code: 'psychology-2018',
        title: 'Psychology',
      },
      {
        code: 'anth2k17',
        title: 'Anthropology',
      },
      {
        code: 'eng2k17',
        title: 'English',
      },
      {
        code: 'ger2k17',
        title: 'German',
      },
    ],
    secondary_options: [
      {
        code: 'psychology-2018',
        title: 'Finance',
      },
      {
        code: 'anth2k17',
        title: 'Entrepreneurship',
      },
      {
        code: 'eng2k17',
        title: 'English',
      },
      {
        code: 'ger2k17',
        title: 'German',
      },
    ],
  },
};

export const getByURIs = (uris) => {
  return {
    summary: {},
    qualification: uris.map((uri) => {
      const code = uri.split('/')[2];
      const qual = deepClone(qualificationList.qualification[0]);
      qual.code = code;
      return qual;
    }),
  };
};

export const bsQualificationFull = {
  qualification: {
    code: 'BS',
    title: 'Bachelor of Science',
    description: 'Bachelor of Science',
    organisation_unit: null,
    level: null,
    primary_options: [
      { code: 'ast17', title: 'Astronomy' },
      { code: 'biochem17', title: 'Biochemistry' },
      { code: 'bio17', title: 'Biological Sciences' },
      { code: 'chem17', title: 'Chemistry' },
      { code: 'cosc17', title: 'Computer Science' },
      { code: 'eco17', title: 'Economics' },
      { code: 'env17', title: 'Environmental Science' },
      { code: 'finance17', title: 'Finance' },
      { code: 'fineng17', title: 'Financial Engineering' },
      { code: 'geo17', title: 'Geography' },
      { code: 'rocks17', title: 'Geology' },
      { code: 'ling17', title: 'Linguistics' },
      { code: 'math17', title: 'Mathematics' },
      { code: 'phil17', title: 'Philosophy' },
      { code: 'phy17', title: 'Physics' },
      { code: 'psych17', title: 'Psychology' },
      { code: 'stats17', title: 'Statistics' },
    ],
    secondary_options: [],
  },
};

export const gradipFull = {
  qualification: {
    code: 'MTCHGLN',
    title: 'Masters in Teaching and Learning',
    description: 'Masters in Teaching and Learning',
    organisation_unit: null,
    level: null,
    primary_options: [],
    secondary_options: [],
  },
};
