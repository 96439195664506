<div class="toolbar-item">
  <div class="dropdown">
    <button
      [attr.data-cy]="'open-menu'"
      class="dropdown-trigger"
      (click)="open = !open"
    >
      <span class="dropdown-trigger-avatar">{{ userInitials }}</span>
      <span class="dropdown-trigger-label hidden-xs">
        {{ currentUser?.displayName }}<br />
        <span
          *ngIf="currentUser?.studentId"
          class="student-id"
          >ID: {{ currentUser?.studentId }}</span
        >
      </span>
      <uc-icon
        *ngIf="open"
        class="dropdown-trigger-icon"
        iconType="chevron-up"
        iconWidth="14"
        iconHeight="14"
        iconFill="$colorRollingStone"
      ></uc-icon>
      <uc-icon
        *ngIf="!open"
        class="dropdown-trigger-icon"
        iconType="chevron-down"
        iconWidth="14"
        iconHeight="14"
        iconFill="$colorRollingStone"
      ></uc-icon>
    </button>
    <nav
      class="dropdown-content"
      [class.open]="open"
    >
      <ul>
        <li
          class="profile"
          *ngIf="env?.profile && currentUser?.studentId"
        >
          <a
            class="dropdown-link"
            [routerLink]="internalUrls.profile"
          >
            {{ strings.headerBar.profile }}
          </a>
        </li>
        <li
          class="account-settings"
          *ngIf="currentUser?.studentId && !env?.accountSettings"
        >
          <a
            class="dropdown-link"
            [href]="profilePageLink"
          >
            {{ strings.headerBar.account }}
          </a>
        </li>
        <li
          class="notification-settings"
          *ngIf="env.notifications"
        >
          <button
            [attr.data-cy]="'notifications'"
            class="dropdown-link"
          >
            {{ strings.headerBar.notification }}
          </button>
        </li>
        <li>
          <button
            [attr.data-cy]="'logout'"
            class="dropdown-link"
            (click)="logOut()"
          >
            <uc-icon
              iconType="logout"
              iconWidth="22"
              iconHeight="22"
              iconFill="$colorRollingStone"
            ></uc-icon>
            {{ strings.headerBar.logout }}
          </button>
        </li>
      </ul>
    </nav>
  </div>
</div>
