import { Directive } from '@angular/core';
import { flatten } from 'lodash-es';
import { EMPTY, timer, range, zip, of, Observable } from 'rxjs';
import { switchMap, retryWhen, map, mergeMap, catchError } from 'rxjs/operators';

import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { INDEPENDENT_ENROLMENT_STATES } from '@shared/constants/states.constants';
import { IndependentCourseEnrolment } from '@shared/models/enrolment';
import { Payment } from '@shared/models/payment';
import { Task } from '@shared/models/task';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { ProcessService } from '@shared/services/process/process.service';
import { WindowService } from '@shared/services/window/window.service';

@Directive()
export class AutoResolveTask extends AbstractBaseTask {
  log: Logger;
  private retryTaskErrorMessage = 'Task is not able to resolve.';
  private breakRetryLoopMessage = 'Break retry loop';
  public id: number;

  // this exposes state that we can use for testing how many times the poll has run
  public pollIterationCount = 0;

  constructor(
    public processService: ProcessService,
    public enrolmentService: EnrolmentService,
    public paymentService: PaymentService,
    private windowService: WindowService,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
    this.id = Math.round(Math.random() * 100);
  }

  // eslint-disable-next-line max-lines-per-function, @typescript-eslint/no-explicit-any
  pollProcess(taskCode: string, processCode: string, applicationYear: string): Observable<any> {
    this.pollIterationCount = 0;
    return of(true).pipe(
      switchMap(() => this.processService.evaluateProcess(processCode, applicationYear)),
      // eslint-disable-next-line complexity
      map((process) => {
        this.pollIterationCount++;
        if (!process) {
          throw new Error('Tried to resolve task but no process has been loaded yet');
        }

        let tasks = [];
        process.stages.map((x) => tasks.push(x.tasks));
        tasks = flatten(tasks);
        const currentTask: Task = tasks.find((task) => task.code === taskCode);

        if (!currentTask) {
          throw new Error('Current task was not found.');
        }
        if (!currentTask.autoResolve) {
          throw new Error('Tried to resolve task but is not an auto resolve task.');
        }

        return currentTask;
      }),
      map((task) => {
        if (task.autoResolve && task.percentComplete === 100) {
          this.next.emit({ autoResolve: true });
        } else {
          this.errors.emit();
          throw new Error(this.retryTaskErrorMessage);
        }
      }),
      retryWhen(this.retryWhenHasRetryTaskErrorMessage),
      catchError((err) => {
        this.errors.emit();
        if (err.message === this.breakRetryLoopMessage) {
          // this will complete the subscription, sending it down neither the happy nor sad path.
          return EMPTY;
        }
        // pass through
        return err;
      }),
    );
  }

  // eslint-disable-next-line max-lines-per-function
  pollCheckoutUrl(
    process: string,
    applicationYear: string,
    courseCode: string,
    occurrence: string,
  ): Observable<unknown> {
    this.pollIterationCount = 0;
    return of(true).pipe(
      switchMap(() => this.paymentService.getPaymentLink(process, applicationYear, courseCode, occurrence)),
      map((payment) => {
        this.hasCheckoutUrlReadyToStopRetry(payment);
      }),
      retryWhen(this.retryWhenHasRetryTaskErrorMessage),
      catchError((err) => {
        this.errors.emit();
        if (err.message === this.breakRetryLoopMessage) {
          return EMPTY;
        }
        return err;
      }),
    );
  }

  private hasCheckoutUrlReadyToStopRetry(payment: Payment) {
    if (payment.link) {
      this.windowService.nativeWindow.open(payment.link, '_self');
    } else {
      this.errors.emit();
      throw new Error(this.retryTaskErrorMessage);
    }
  }

  // eslint-disable-next-line max-lines-per-function
  pollIndependentEnrolmentStatus(applicationYear: string, courseCode: string, occurrence: string): Observable<unknown> {
    this.pollIterationCount = 0;
    const expectCheckoutUrl = true;
    const isRefresh = true;
    return of(true).pipe(
      switchMap(() =>
        this.enrolmentService.getIndependentEnrolment(
          applicationYear,
          courseCode,
          occurrence,
          expectCheckoutUrl,
          isRefresh,
        ),
      ),
      map((independentEnrolment) => {
        this.hasEnrolmentEnrolledToStopRetry(independentEnrolment);
      }),
      retryWhen(this.retryWhenHasRetryTaskErrorMessage),
      catchError((err) => {
        this.errors.emit();
        if (err.message === this.breakRetryLoopMessage) {
          return EMPTY;
        }
        return err;
      }),
    );
  }

  private hasEnrolmentEnrolledToStopRetry(independentEnrolment: IndependentCourseEnrolment) {
    if (independentEnrolment.state.code === INDEPENDENT_ENROLMENT_STATES.ENROLMENT_STATE_ENROLLED) {
      this.next.emit({ autoResolve: true });
    } else {
      this.errors.emit();
      throw new Error(this.retryTaskErrorMessage);
    }
  }

  private retryWhenHasRetryTaskErrorMessage = (subject: Observable<Error>) => {
    return zip(range(1, 10), subject).pipe(
      map(([iteration, err]) => {
        if (err.message === this.retryTaskErrorMessage) {
          return 2 ** iteration;
        } else {
          throw err;
        }
      }),
      mergeMap((i) => {
        this.log.info(`delay retry by ${i * 1.5} second(s) for component no:`, this.id);
        return timer(i * 1500);
      }),
      map(() => {
        if (this.isAlreadyDestroyed) {
          throw new Error(this.breakRetryLoopMessage);
        }
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function, class-methods-use-this
  updateFormValidity() {}
}
