import { takeUntil, filter } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import strings from '@constants/strings.constants';
import { ApplicationService } from '@shared/services/application/application.service';
import { Application } from '@shared/models/application';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';

const candidateValidators = [Validators.required, Validators.min(0), Validators.max(9999), Validators.maxLength(4)];

@Component({
  selector: 'uc-cie-admission',
  templateUrl: './cie-admission.component.html',
  styleUrls: ['./cie-admission.component.scss'],
})
export class CieAdmissionComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  taskForm: UntypedFormGroup;
  currentApplication: Application;
  log: Logger;

  strings = strings.components.tasks.cieAdmission;

  constructor(
    private fb: UntypedFormBuilder,
    private applicationService: ApplicationService,
    private loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  public updateFormValidity(err: UCError): void {}

  ngOnInit() {
    this.taskForm = this.fb.group({
      yesNo: ['', Validators.required],
    });

    this.taskForm
      .get('yesNo')
      .valueChanges.pipe(
        filter((v) => !!v),
        takeUntil(this.componentDestroyed),
      )
      .subscribe((val) => {
        if (val === 'show') {
          this.taskForm.addControl('centre', this.fb.control('', Validators.required));
          this.taskForm.addControl('candidate', this.fb.control('', candidateValidators));
        } else {
          this.taskForm.removeControl('centre');
          this.taskForm.removeControl('candidate');
        }
      });

    this.applicationService.application
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((application: Application) => {
        this.currentApplication = application;
        this.updateFormFromApplication(application);
      });
  }

  private updateFormFromApplication(application: Application) {
    const { cieCandidateNumber, cieCenterNumber, cieQualification } = application;
    this.taskForm.patchValue({
      yesNo: cieCandidateNumber || cieCenterNumber || cieQualification ? 'show' : 'hide',
      centre: cieCenterNumber ? cieCenterNumber : null,
      candidate: cieCandidateNumber ? cieCandidateNumber : null,
    });
  }

  public update() {
    if (!this.currentApplication) {
      this.errors.emit();
      this.log.error('tried to update application but no application has been loaded yet');
    }
    const { yesNo, centre, candidate } = this.taskForm.value;

    this.currentApplication.cieQualification = yesNo === 'show' || centre || candidate ? true : false;
    this.currentApplication.cieCandidateNumber = candidate || null;
    this.currentApplication.cieCenterNumber = centre || null;

    this.applicationService.updateApplication(this.currentApplication).subscribe(
      (app) => {
        this.log.info('updated application successfully', app);
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('could not update application', err);
      },
    );
  }
}
