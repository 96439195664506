import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ICacheOptions {
  target: CacheObjects;
}

export enum CacheObjects {
  ALL,
  PROCESS,
  APPLICATION,
  APPLICANT,
  DOCUMENT,
}

@Injectable()
export class CacheManagementService {
  private clearCache$ = new Subject<ICacheOptions>();

  constructor() {}

  get shouldClearCache(): Observable<ICacheOptions> {
    return this.clearCache$.asObservable();
  }

  clearCache(options: ICacheOptions = { target: CacheObjects.ALL }) {
    this.clearCache$.next(options);
  }
}
