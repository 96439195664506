import { Component, Input, OnInit } from '@angular/core';
import strings from '@constants/strings.constants';
import { UntypedFormGroup } from '@angular/forms';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-insurance-provider',
  templateUrl: './insurance-provider.component.html',
})
export class InsuranceProviderComponent implements OnInit {
  strings = strings.components.organisms.insuranceProvider;

  extraDescriptions: { code: string; description: string }[] = Object.keys(
    this.strings.insuranceSelector.extraDescriptions,
  ).map((code) => {
    return {
      code,
      description: this.strings.insuranceSelector.extraDescriptions[code],
    };
  });

  @Input() insuranceProviderFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() task: Task;

  constructor() {}

  ngOnInit() {}

  get selectedInsuranceProviderCode(): string | null {
    return this.insuranceProviderFormGroup.get('insuranceProvider').value;
  }
}
