import { externalUrls } from '@constants/urls.constants';

const html = String.raw;

export const informationHtml = html`
  <p>
    I understand that the University of Canterbury will collect, store, use and disclose personal information about me
    in the course, and for the purpose, of conducting its normal and proper business and for the purpose of
    marketing/public relations, fundraising and maintaining donor records, and that a unique identifier will be assigned
    to me to facilitate this. I have read and understand how such information will be managed and disclosed in
    accordance with the Privacy Act 2020, and as outlined on the
    <a
      href="${externalUrls.ucPrivacyAndCopyright}"
      target="_blank"
      rel="noopener"
      >UC Privacy Statements</a
    >. This privacy statement operates in conjunction with the University of Canterbury’s Privacy Policy. Where there is
    any conflict with the Privacy Policy, the terms of the Privacy Policy will prevail. I acknowledge that I have the
    right to access and seek correction of personal information held about me and understand that if I withhold
    information or provide false or misleading information my enrolment may be terminated.
  </p>

  <p>
    The personal information collected will be controlled by the University, while you remain enrolled, and for such
    time thereafter as is necessary for the University to fulfil its administrative obligations, or for the purposes of
    providing alternative enrolment pathways. While you remain enrolled at the University you will be asked regularly to
    update that information. As well as for the purposes of conducting its proper business, the University uses personal
    information for a variety of statistical and research purposes, but in so doing it always ensures that no individual
    can be identified.
  </p>

  <p>
    You should be aware that examination results and results for in-term assessment constitute personal information
    under the Privacy Act and you also have the right to request that your work be returned to you privately. Graduation
    information, including your name and award, will be made public information.
  </p>
  <ul class="red-list">
    <li>
      For domestic students, upon request, your graduation information may be provided to your secondary school for use
      in honours boards, school publications and for the purposes of improving teaching practice.
    </li>
    <li>
      For international students, information concerning your academic progress or withdrawal, including grade and
      wellbeing information, may be provided to or shared with your agent, homestay host, sponsor or home university
      upon request under agreements that facilitate your enrolment into UC, for the purposes of providing you academic
      and pastoral support or for the provision of Study Abroad transfer of credit and evidence relating to compliance
      with conditions of entitlement to study in New Zealand.
    </li>
  </ul>

  <p>
    If you have any questions about the privacy of your personal information please contact the University’s Privacy
    Officer, the Registrar.
  </p>

  <h5>What do I agree to?</h5>

  <p>By agreeing to this declaration:</p>
  <ul class="red-list">
    <li>
      I declare that all the information submitted in this application form and in the attached documents is correct and
      complete, and that I accept the terms of the
      <a
        href="${externalUrls.ucCodeOfConduct}"
        target="_blank"
        rel="noopener"
        >Student Code of Conduct (pdf, 338KB)</a
      >
    </li>
    <li>
      I acknowledge that if I provide incorrect or incomplete information this may result in the cancellation of my
      enrolment.
    </li>
    <li>
      I acknowledge that the submission of fraudulent, forged, or otherwise dishonest documentation in support of this
      application will automatically disqualify me from enrolment. I understand that in such a case the University of
      Canterbury reserves the right to inform all other New Zealand universities of the fact along with my name and date
      of birth and that the Police and/or Immigration may also be informed.
    </li>
    <li>I consent to the collection and use of my personal information as outlined above.</li>
  </ul>
`;

export const cacHtml = html`
  <p>
    I understand that if I am enrolled in a programme of study that is subject to compliance with the Children’s Act
    2014 I will be expected to act professionally at all times particularly in relationships with children, parents, UC
    staff and those encountered on placements and internships. I will abide by relevant codes of ethics and acknowledge
    that failure to act professionally may result in disciplinary action.
  </p>

  <p>
    I acknowledge that in the event of information becoming available that may preclude my eventual registration, the
    University may take disciplinary action up to, and including, termination.
  </p>
`;

export const policeRecordHtml = html`
  <p>
    I also understand that if I am enrolled in a programme of study that is subject to compliance with the Children’s
    Act 2014, I will be expected to disclose any criminal convictions against me that are made at any point from the
    time of application until completion of my study by notifying the relevant academic head, dean, course or programme
    co-ordinator as soon as charges are laid.
  </p>

  <p>
    I declare that the information provided regarding any previous convictions, or lack thereof, in both New Zealand and
    any overseas countries that I have resided in, is true and correct.
  </p>

  <p>
    I acknowledge that the purpose for this disclosure is to ensure the university and its clients (e.g. children,
    parents, peers, staff) are safe.
  </p>
`;

export const enrolmentChangeHtml = html`
  <p>
    Change of Enrolment requests are subject to approval and the University Policy. Please see
    <a
      href="${externalUrls.ucKeyCourseDates}"
      target="_blank"
      >Key course dates</a
    >
    for full information on application to enrol, official course start and end dates, refund and withdrawal dates.
  </p>

  <p><strong>Please read and agree to the following declaration</strong></p>

  <p>
    I declare the information provided on my Change of Enrolment request and in any attached documentation upon which a
    Notification of Change is based to be true and correct in every particular and that no information which would have
    a material bearing on this Change of Enrolment Application has been withheld.
  </p>

  <p>
    I understand that on submitting the Change of Enrolment, subject to academic approval, the change will be official
    and the additions or deletions recorded on my academic record.
  </p>

  <p>
    The University of Canterbury reserves the right to review this Change of Enrolment request should the information
    provided not be correct.
  </p>

  <p>
    <strong>I do solemnly promise</strong> that I will faithfully obey the statutes and regulations of the University of
    Canterbury so far as they apply to me. University of Canterbury statutes and regulations can be found in the
    Enrolment Handbook and the Calendar.
  </p>

  <p>
    In agreeing to abide by this declaration I undertake to pay all fees associated with my Change of Enrolment and my
    status as a student, and any collection charges associated with debt recovery. The University of Canterbury policy
    on withdrawal and refund of fees may be obtained from the Enrolment Handbook.
  </p>

  <p>
    I also understand that I have the right to have access to the information about me held by the University of
    Canterbury and to request correction of that information, in the terms provided for under the Privacy Act 2020.
  </p>
`;

export const starEnrolmentChangeHtml = html`
  <p>Change of Enrolment requests are subject to approval and the University Policy.</p>

  <p><strong>Please read and agree to the following declaration</strong></p>

  <p>
    I declare the information provided on my Change of Enrolment request and in any attached documentation upon which a
    Notification of Change is based to be true and correct in every particular and that no information which would have
    a material bearing on this Change of Enrolment Application has been withheld.
  </p>

  <p>
    I understand that on submitting the Change of Enrolment, subject to academic approval, the change will be official
    and the additions or deletions recorded on my academic record.
  </p>

  <p>
    The University of Canterbury reserves the right to review this Change of Enrolment request should the information
    provided not be correct.
  </p>

  <p>
    <strong>I do solemnly promise</strong> that I will faithfully obey the statutes and regulations of the University of
    Canterbury so far as they apply to me. University of Canterbury statutes and regulations can be found in the
    Enrolment Handbook and the Calendar.
  </p>

  <p>
    I also understand that I have the right to have access to the information about me held by the University of
    Canterbury and to request correction of that information, in the terms provided for under the Privacy Act 2020.
  </p>
`;

export const starDeclarationHtml = html`
  <p>
    I understand that although successful completion of this course entitles me to be awarded credit towards a
    University of Canterbury degree when I have qualified for University Entrance, it does not constitute admission as a
    University of Canterbury student. I also understand that my school will be advised of my results.
  </p>

  <p>
    International student: I hereby authorise University of Canterbury to act as an agent on my behalf for the purpose
    of liaising with Immigration New Zealand for the application and decision of any Variation of Conditions of my
    current secondary school student visa.
  </p>

  <p>
    I agree as a condition of my enrolment to read information on my UC Student email and web portal at least once per
    week.
  </p>

  <p>
    I understand that the University of Canterbury will collect, store, use and disclose personal information about me
    in the course and for the purpose of, conducting its normal and proper business and for the purpose of
    marketing/public relations, fundraising and maintaining donor records, and that a unique identifier will be assigned
    to me to facilitate this. I have read and understand how such information will be managed and disclosed in
    accordance with the Privacy Act 2020, and as outlined on the
    <a
      href="${externalUrls.ucPrivacyAndCopyright}"
      target="_blank"
      rel="noopener"
      >UC Privacy Statements</a
    >. This privacy statement operates in conjunction with the University of Canterbury’s Privacy Policy. Where there is
    any conflict with the Privacy Policy, the terms of the Privacy Policy will prevail. I acknowledge that I have the
    right to access and seek correction of personal information held about me and understand that if I withhold
    information or provide false or misleading information my enrolment may be terminated.
  </p>

  <p>
    The personal information collected will be controlled by the University, while you remain enrolled, and for such
    time thereafter as is necessary for the University to fulfil its administrative obligations, or for the purposes of
    providing alternative enrolment pathways. As well as for the purposes of conducting its proper business, the
    University uses personal information for a variety of statistical and research purposes, but in so doing it always
    ensures that no individual can be identified.
  </p>

  <h5>What do I agree to?</h5>

  <p>By agreeing to this declaration:</p>
  <ul class="red-list">
    <li>
      I declare that all the information submitted in this application form and in the attached documents is correct and
      complete, and that I accept the terms of the
      <a
        href="${externalUrls.ucCodeOfConduct}"
        target="_blank"
        rel="noopener"
        >Student Code of Conduct</a
      >.
    </li>

    <li>
      I acknowledge that if I provide incorrect or incomplete information this may result in the cancellation of my
      enrolment.
    </li>

    <li>
      I acknowledge that the submission of fraudulent, forged, or otherwise dishonest documentation in support of this
      application will automatically disqualify me from enrolment. I understand that in such a case the University of
      Canterbury reserves the right to inform all other New Zealand universities of the fact along with my name and date
      of birth and that the Police and/or Immigration may also be informed.
    </li>

    <li>I consent to the collection and use of my personal information as outlined above.</li>
  </ul>
`;

export const microCredentialsDeclarationHtml = html`
  <p>
    I understand that the University of Canterbury will collect, store, use and disclose personal information about me
    in the course, and for the purpose, of conducting its normal and proper business and for the purpose of
    marketing/public relations, fundraising and maintaining donor records, and that a unique identifier will be assigned
    to me to facilitate this. I have read and understand how such information will be managed and disclosed in
    accordance with the Privacy Act 2020, and as outlined on the
    <a
      href="${externalUrls.ucPrivacyAndCopyright}"
      target="_blank"
      rel="noopener"
      >UC Privacy Statements</a
    >. This privacy statement operates in conjunction with the University of Canterbury’s Privacy Policy. Where there is
    any conflict with the Privacy Policy, the terms of the Privacy Policy will prevail. I acknowledge that I have the
    right to access and seek correction of personal information held about me and understand that if I withhold
    information or provide false or misleading information my enrolment may be terminated.
  </p>

  <p>
    The personal information collected will be controlled by the University, while you remain enrolled, and for such
    time thereafter as is necessary for the University to fulfil its administrative obligations, or for the purposes of
    providing alternative enrolment pathways. While you remain enrolled at the University you will be asked regularly to
    update that information. As well as for the purposes of conducting its proper business, the University uses personal
    information for a variety of statistical and research purposes, but in so doing it always ensures that no individual
    can be identified.
  </p>

  <p>
    You should be aware that examination results and results for in-term assessment constitute personal information
    under the Privacy Act and you also have the right to request that your work be returned to you privately. Graduation
    information, including your name and award, will be made public information.
  </p>
  <ul class="red-list">
    <li>
      For domestic students, upon request, your graduation information may be provided to your secondary school for use
      in honours boards, school publications and for the purposes of improving teaching practice.
    </li>
    <li>
      For international students, information concerning your academic progress or withdrawal, including grade and
      wellbeing information, may be provided to or shared with your agent, homestay host, sponsor or home university
      upon request under agreements that facilitate your enrolment into UC, for the purposes of providing you academic
      and pastoral support or for the provision of Study Abroad transfer of credit and evidence relating to compliance
      with conditions of entitlement to study in New Zealand.
    </li>
  </ul>

  <p>
    If you have any questions about the privacy of your personal information please contact the University’s Privacy
    Officer, the Registrar.
  </p>

  <h5>What do I agree to?</h5>

  <p>By agreeing to this declaration:</p>
  <ul class="red-list">
    <li>
      I declare that all the information submitted in this application form and in the attached documents is correct and
      complete, and that I accept the terms of the
      <a
        href="${externalUrls.ucCodeOfConduct}"
        target="_blank"
        rel="noopener"
        >Student Code of Conduct (pdf, 338KB)</a
      >
    </li>
    <li>
      I acknowledge that if I provide incorrect or incomplete information this may result in the cancellation of my
      enrolment.
    </li>
    <li>
      I acknowledge that the submission of fraudulent, forged, or otherwise dishonest documentation in support of this
      application will automatically disqualify me from enrolment. I understand that in such a case the University of
      Canterbury reserves the right to inform all other New Zealand universities of the fact along with my name and date
      of birth and that the Police and/or Immigration may also be informed.
    </li>
    <li>I consent to the collection and use of my personal information as outlined above.</li>
  </ul>

  <h5>Micro-credentials consent</h5>
  <p>
    I declare that I have reviewed the micro-credentials
    <a
      href="${externalUrls.microCredentialConsent}"
      target="__blank"
      >consent information</a
    >. I understand the content of the webpage and agree to any implications it may have on my application.
  </p>
`;
