<uc-section-header title="{{ strings.title }}">
  <p [innerHTML]="strings.bodyText"></p>

  <uc-form-input>
    <div class="item-label">
      <div class="item-tab">{{ strings.tellUsAboutYourself.title }}</div>
      <div class="item-content-wrapper">
        <div class="item-content-section">
          <p [innerHTML]="strings.tellUsAboutYourself.bodyText"></p>
          <p>{{ strings.tellUsAboutYourself.doYouIdentify }}</p>
          <uc-checkbox-input
            [formControl]="identityGroups.controls.rainbowCommunityCheckbox"
            [label]="strings.tellUsAboutYourself.groups.rainbow"
          ></uc-checkbox-input>
          <uc-checkbox-input
            [formControl]="identityGroups.controls.refugeeBackgroundCheckbox"
            [label]="strings.tellUsAboutYourself.groups.refugee"
          ></uc-checkbox-input>
          <uc-checkbox-input
            [formControl]="identityGroups.controls.religionCheckbox"
            [label]="strings.tellUsAboutYourself.groups.religionFaith"
          ></uc-checkbox-input>
          <uc-reference-data-selector
            *ngIf="religionIsSelected"
            [labelName]="strings.tellUsAboutYourself.selectAnOption"
            type="religion_or_faith"
            [formControl]="identityGroups.controls.religionDropdown"
          ></uc-reference-data-selector>
          <uc-task-hint
            *ngIf="task"
            [task]="task"
            path="/applicant/personal_identifiers/religion/custom"
          ></uc-task-hint>
          <uc-string-input
            *ngIf="showReligionTextField && religionIsSelected"
            [label]="strings.tellUsAboutYourself.enterAnswer"
            [formControl]="identityGroups.controls.religionTextField"
            [maxLength]="200"
          ></uc-string-input>
        </div>
      </div>
    </div>
  </uc-form-input>
</uc-section-header>
