import { get } from 'lodash-es';

import hintStrings from '@constants/hint-strings.constants';
import { camelizeKeys } from '@shared/helpers/serialization';

export class TaskHint {
  detail: string;
  source: { pointer: string };
  // not currently used, but included in the spec
  status: string;

  constructor(context: { detail: string; source: { pointer: string } }) {
    Object.assign(this, context);
  }

  // fragment creates a unique id for locating this taskhint on the page.
  get fragment(): string {
    return this.source.pointer.split('/').join('-');
  }

  get hintLabel() {
    const hintLabel = get(hintStrings.hints, this.detail);
    return hintLabel || this.detail;
  }
}

export class Task {
  public title: string;
  public decorator?: string;
  public code: string;
  public percentComplete: number;
  public path: string;
  public stop: boolean;
  public autoResolve = false;
  public actionLabel = 'save_and_continue';
  public hints: TaskHint[];

  constructor(creationObject: any) {
    Object.assign(this, creationObject);
    if (!this.decorator) {
      this.decorator = 'tick';
    }
  }

  get isDefaultDecorator(): boolean {
    return this.decorator === 'tick';
  }

  get shouldBeHidden(): boolean {
    return this.autoResolve && this.percentComplete === 100;
  }

  static deserialize(payload: any, stagePath: string): Task {
    if (payload === null) {
      return null;
    }

    const task: any = camelizeKeys(payload);
    task.path = `${stagePath}/${task.code}`;

    if (task.hints) {
      task.hints = task.hints.map((h) => new TaskHint(h));
    }

    return new Task(task);
  }
}
