import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, NavigationEnd } from '@angular/router';
import { throwError, of, EMPTY, Observable } from 'rxjs';
import { filter, switchMap, catchError, tap } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCProcess } from '@shared/models/process';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { IApplicationProcess, ProcessService } from '@shared/services/process/process.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { UserService } from '@shared/services/user/user.service';

/**
 * The full route data object
 * e.e. this.route.data
 */
export interface IProcessRouteData {
  processContext: IProcessRouteContext;
}

/**
 * The object resolved by the ProcessResolver
 */
export interface IProcessRouteContext {
  process: UCProcess;
  application: Application | ChangeOfEnrolment;
}

/**
 * The parameters supplied to the Process resolver/page from the url config
 */
export interface IProcessRouteParams {
  process: string;
  year: string;
  stage: string;
  task: string;
}

@Injectable()
export class ProcessResolver implements Resolve<IProcessRouteContext> {
  public isImpersonated = false;
  private log: Logger;
  private lastLocation = '';
  private forceProcessUpdate = true;

  constructor(
    private router: Router,
    private processService: ProcessService,
    private flashMessageService: FlashMessageService,
    private loggingService: LoggingService,
    private referenceDataService: ReferenceDataService,
    private userService: UserService,
  ) {
    this.log = this.loggingService.createLogger(this);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.lastLocation = event.urlAfterRedirects;
    });

    this.userService.userDetail.subscribe((detail) => {
      this.isImpersonated = detail && !!detail.impersonated;
    });
  }

  get navigatedFromOutsideProcess(): boolean {
    return !this.lastLocation || (this.lastLocation && !this.lastLocation.match(/^\/apply/));
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProcessRouteContext> {
    const { process: processCode, year, stage: stageCode, task: taskCode } = route.params as IProcessRouteParams;
    if (!processCode || !year) {
      // I guess this happens if you go to /apply/// or something weird like that
      this.router.navigate(internalUrls.apply);
      return EMPTY;
    }

    if (isNaN(parseInt(year, 10))) {
      // likely attempting to access an old url, like /apply/process/stage/task
      this.router.navigate(internalUrls.dashboard);
      return EMPTY;
    }

    const forceUpdate = this.navigatedFromOutsideProcess && this.forceProcessUpdate;

    return this.referenceDataService.getByType('application_year').pipe(
      switchMap((referenceData) => {
        const validYears = referenceData.map((refData) => refData.code);
        if (!validYears.find((validYear) => validYear === year || Number(validYear) - 1 === Number(year))) {
          this.flashMessageService.pushError(strings.errors.invalidApplicationYear);
          this.router.navigate(internalUrls.dashboard);
          return EMPTY;
        }
        if (processCode === PROCESS_NAMES.COE) {
          return this.processService.fetchCOEProcess(processCode, year, forceUpdate).pipe(
            tap(() => {
              /**
               * Fetches and caches the current years' application data as there are components used in
               * the coe process that require this.applicationService.$application to have a value
               *  */
              this.processService.fetchCurrentApplicationProcess(processCode, year, forceUpdate).subscribe();
            }),
          );
        } else {
          return this.processService.fetchCurrentApplicationProcess(processCode, year, forceUpdate);
        }
      }),
      switchMap(({ process, application }: IApplicationProcess) => {
        const redirectPath = this.validatePath(process, year, stageCode, taskCode);
        if (redirectPath) {
          this.forceProcessUpdate = false;
          this.log.info('Redirecting to', redirectPath.join('/'));
          this.router.navigate(redirectPath);
          return EMPTY;
        }
        this.forceProcessUpdate = true;
        this.processService.process$.next(process);
        return of({ process, application });
      }),
      catchError((err) => {
        if (err.status === 404) {
          // don't know if 404 means the processCode is invalid or if they just don't have
          // an application for that process and year combo, so safest bet is to nav to /apply
          this.router.navigate(internalUrls.apply);
          // return EmptyObservable<UCProcess>();
          return EMPTY;
        } else if (err.status === 409) {
          this.router.navigate(internalUrls.dashboard);
          return EMPTY;
        } else {
          return throwError(err);
        }
      }),
    );
  }

  /**
   * @returns a path to redirect to, or nothing if the path is valid
   */
  validatePath(process: UCProcess, year: string, stageCode: string, taskCode: string): string[] | null {
    // 1. check process
    if (!process) {
      return internalUrls.apply;
    }

    // 2. check stage
    if (!stageCode) {
      // This block should only ever affect dev environments with environment.features.unlockStages === true
      // Redirect to the first incomplete stage
      const defaultStage = process.firstIncompleteStage || process.stages[0];
      return internalUrls.processPageTask(process.code, year, defaultStage.code, defaultStage.tasks[0].code);
    }

    const stage = process.getStage(stageCode);
    if (!stage) {
      // Invalid stage for the current process
      return internalUrls.fourOhFour;
    }

    // 2.1 The user is only allowed to view the first incomplete stage on a process.
    //     If stage is otherwise valid but not the first incomplete stage, then redirect to it
    //     except for dev mode where we may visit any stage indiscriminately
    if (!environment.features.unlockStages) {
      const { firstIncompleteStage } = process;

      // Check if the application is complete
      if (!firstIncompleteStage) {
        this.flashMessageService.pushInfo('This application is complete!');
        return internalUrls.dashboard;
      }

      // Check that the stage they are requesting is the stage they are allowed to view
      if (stage.code !== firstIncompleteStage.code && !this.isImpersonated) {
        // Redirect to the stage they are allowed to view
        return internalUrls.processPageTask(
          process.code,
          year,
          firstIncompleteStage.code,
          firstIncompleteStage.tasks[0].code,
        );
      }
    }

    // 3. check task
    if (!taskCode) {
      // Redirect to the first task on the stage by default
      return internalUrls.processPageTask(process.code, year, stage.code, stage.tasks[0].code);
    }
    let task = stage.getTask(taskCode);
    if (!task) {
      // Invalid task for the current process and stage
      return internalUrls.fourOhFour;
    }

    // Check if the task is auto resolve and complete -- if it is, navigate to the next task
    if (task.autoResolve && task.percentComplete === 100) {
      const autoResolveIndex = stage.tasks.map((x) => x.code).indexOf(taskCode);
      task = stage.tasks[autoResolveIndex + 1] || null;
      if (task) {
        return internalUrls.processPageTask(process.code, year, stage.code, task.code);
      }
      this.flashMessageService.pushInfo('This application is complete!');
      return internalUrls.dashboard;
    }

    // No validation errors. Don't redirect
    return null;
  }
}
