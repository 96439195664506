/* eslint-disable id-blacklist */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { Contact } from '@shared/models/contact';
import { UCError } from '@shared/models/errors';
import { Name } from '@shared/models/name';
import { Phone } from '@shared/models/phone';
import { ReferenceData } from '@shared/models/reference-data';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  group,
  control,
  array,
  UCElementGroup,
  UCElementArray,
  booleanToYesNo,
  yesNoToBoolean,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-application-to-enrol',
  templateUrl: './application-to-enrol.component.html',
  styleUrls: ['./application-to-enrol.component.scss'],
})
export class ApplicationToEnrolComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() processName: string;

  enrolPage: UntypedFormGroup;
  enrolForm: UCElementGroup;
  currentApplicant: Applicant;
  currentApplication: Application;
  dateOfBirthYear: number;
  showAgentSection = true;
  showAgentReminder = environment.scope === 'agent';

  legalName: Name;
  name: Name[];

  bannerString = strings.components.organisms.profilePage.profileTemplate.mandatoryBanner;
  agentReminderString = strings.components.template.processPage.agentReminder;
  strings = strings.components.tasks.applicationToEnrol;
  phoneStrings = strings.components.molecules.phoneSelector;

  showStudyAwayFromNZInfo = false;
  studyAwayFromNZInfo = this.strings.citizenshipSection.rulingDesc;

  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  processRequiringId = [PROCESS_NAMES.STAR, PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL];

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private processService: ProcessService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get isStarProcess(): boolean {
    return this.processName === PROCESS_NAMES.STAR;
  }

  get isMCEDProcess(): boolean {
    return this.processName === PROCESS_NAMES.MCED || this.processName === PROCESS_NAMES.MICRO_CREDENTIAL;
  }

  get otherNames(): UCElementArray {
    return this.enrolForm.controls.names.controls.name;
  }

  get otherNamesLength(): number {
    return get(this.otherNames, 'control.length');
  }

  get citizenship() {
    return this.enrolForm.controls.citizenship.controls;
  }

  get isNZCitizen(): boolean {
    return get(this.citizenship.citizenCategory, 'control.value.code') === 'NZCZ';
  }

  get isInternational(): boolean {
    return get(this.citizenship.citizenCategory, 'control.value.code') === 'OTHER';
  }

  get showStudentLoan(): boolean {
    const isNotInternational = get(this.citizenship.citizenCategory, 'control.value.code') && !this.isInternational;
    return isNotInternational && !this.isStarOrMicrocredentialProcss;
  }

  get identityDocumentRequired(): boolean {
    return this.processRequiringId.indexOf(this.processName) !== -1;
  }

  get isStarOrMicrocredentialProcss(): boolean {
    return this.isStarProcess || this.isMCEDProcess;
  }

  private createForm(): UCElementGroup {
    return group({
      birthDate: control({
        defaultState: '',
        validators: [UCValidators.validateDate],
        model: 'applicant',
        path: '/birthDate',
      }),
      names: group({
        legalName: control({
          defaultState: new Name({}),
          validators: [Validators.required],
          model: 'applicant',
          path: '/legalName',
        }),
        // assumption is made that array objects have all the same type
        name: array({
          defaultState: [],
          validators: [Validators.required],
          model: 'applicant',
          path: '/name',
        }),
      }),
      agentDetails: group({
        hasAgent: control({
          model: 'application',
          path: '/declarationHasAgent',
          inMap: booleanToYesNo(),
          outMap: yesNoToBoolean(),
        }),
        agency: control({ model: 'application', path: '/agentAgency' }),
        agent: control({ model: 'application', path: '/agentName' }),
        email: control({
          model: 'application',
          path: '/agentContactDetail/emailAddress',
          validators: [Validators.email],
        }),
      }),
      genderGroup: group({
        gender: control({ model: 'applicant', path: '/gender', inMap: refDataToValue, outMap: valueToRefData }),
      }),
      pronounsGroup: group({
        pronouns: control({
          model: 'applicant',
          path: '/pronouns/0/source',
          defaultState: null,
        }),
        customPronouns: control({
          model: 'applicant',
          path: '/pronouns/0/custom',
          defaultState: null,
          validators: [Validators.maxLength(200)],
        }),
        selected: control({ model: 'applicant', path: '/pronouns/0/selected', defaultState: false }),
      }),
      citizenship: group({
        citizenCategory: control({
          validators: [Validators.required],
          model: 'applicant',
          path: '/demographic/citizenship',
        }),
        citizenCountry: control({ model: 'applicant', path: '/demographic/passportCountry' }),
        studyInNz: control({ model: 'application', path: '/studyInNz', validators: [Validators.required] }),
      }),
      contactDetails: group({
        email: control({
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/contactDetail/emailAddress',
        }),
        mobileNum: control({
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzMobileValidator],
          model: 'applicant',
          path: '/contactDetail/mobileNumber',
        }),
        landlineNum: control({
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzLandlineValidator],
          model: 'applicant',
          path: '/contactDetail/landlineNumber',
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
      }),
    });
  }

  private disableVerifiedControls(applicant?: Applicant) {
    if (get(applicant, 'validatedBirthDate')) {
      this.enrolPage.get('birthDate').disable();
    }

    if (get(applicant, 'demographic.validatedCitizenship')) {
      this.enrolPage.get('citizenship.citizenCategory').disable();
      this.enrolPage.get('citizenship.citizenCountry').disable();
    }

    if (get(applicant, 'legalName.validated')) {
      this.enrolPage.get('names.legalName').disable();
    }
  }

  isNzOrAus(val, citizenType?: string): boolean {
    if (!val) {
      return false;
    }

    if (val.code && !citizenType) {
      return !!val.code.match(/^(NZCZ|AUS)$/);
    }

    if (val.code && citizenType === 'pr') {
      return !!val.code.match(/^(NZPR|AUSPR|)$/);
    }

    if (val.code && citizenType && citizenType !== 'pr') {
      return !!val.code.match(/^(NZPR|AUSPR|AUS)$/);
    }
  }

  ngOnInit() {
    this.enrolForm = this.createForm();
    this.enrolPage = this.enrolForm.asControl() as UntypedFormGroup;
    this.dateOfBirthYear = new Date().getFullYear() - 10;

    this.citizenship.citizenCategory.control.valueChanges
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((a) => !!a),
      )
      .subscribe((val) => {
        if (val.code && !this.isNzOrAus(val)) {
          this.citizenship.citizenCountry.control.setValidators([Validators.required]);
        } else {
          this.citizenship.citizenCountry.control.setValidators([]);
          this.citizenship.citizenCountry.control.setValue({ code: null });
        }
        this.citizenship.citizenCountry.control.updateValueAndValidity();
      });

    this.enrolPage
      .get('citizenship')
      .valueChanges.pipe(takeUntil(this.componentDestroyed))
      .subscribe((val) => {
        this.studyAwayFromNZInfo = this.isInternational
          ? this.strings.citizenshipSection.internationalRulingDesc
          : this.strings.citizenshipSection.rulingDesc;

        this.showStudyAwayFromNZInfo = val.studyInNz === false && !this.isNZCitizen;
      });

    observableCombineLatest(this.applicantService.getApplicant(), this.applicationService.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.formModel.updateFormFromModel(this.enrolForm, applicant, application);
        if (applicant && !applicant.name.length) {
          this.otherNames.removeAt(0);
        }
        this.showAgentSection = application && !application.agentApplyOnBehalf && !this.isStarOrMicrocredentialProcss;

        this.disableVerifiedControls(applicant);
      });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.enrolForm);
  }

  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    }

    let firstCode;
    if (this.currentApplicant.demographic && this.currentApplicant.demographic.citizenship) {
      firstCode = this.currentApplicant.demographic.citizenship.code;
    }
    const studyFirst = this.currentApplication.studyInNz;

    if (!this.currentApplication.agentContactDetail) {
      if (this.enrolPage.get('agentDetails.email').value) {
        this.currentApplication.agentContactDetail = new Contact({
          mobileNumber: null,
          landlineNumber: null,
          currentAddress: null,
        });
      } else {
        delete this.enrolForm.controls.agentDetails.controls.email;
      }
    }

    this.formModel.updateModelFromForm(this.enrolForm, this.currentApplicant, this.currentApplication);
    // Hack to prevent nulls in the name array
    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);

    if (
      (firstCode && this.currentApplicant.demographic.citizenship.code !== firstCode) ||
      studyFirst !== this.currentApplication.studyInNz
    ) {
      this.currentApplication.studyFullTime = null;
      this.currentApplication.studyLocation = null;
    }

    if (!this.currentApplication.declarationHasAgent) {
      this.currentApplication.agentName = null;
      this.currentApplication.agentContactDetail = null;
      this.currentApplication.agentAgency = null;
    }

    if (this.currentApplicant.demographic.citizenship) {
      if (this.currentApplicant.demographic.citizenship.code === 'NZCZ') {
        this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'NZL' });
      } else if (this.currentApplicant.demographic.citizenship.code === 'AUS') {
        this.currentApplicant.demographic.passportCountry = new ReferenceData({ code: 'AUS' });
      }
    }
    if (!this.currentApplicant.demographic.passportCountry || !this.currentApplicant.demographic.passportCountry.code) {
      this.currentApplicant.demographic.passportCountry = null;
    }

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    observableCombineLatest(updateApplicant, updateApplication).subscribe(
      // Success
      ([applicant, application]: [Applicant, Application]) => {
        if (applicant && application) {
          this.log.info('updated applicant and application successfully');
          this.next.emit();
        }
      },
      // Error
      (err: DSHttpError) => {
        this.errors.emit();
        this.log.error('error thrown while updating applica(nt|tion):', err);
      },
    );
  }
}
