<uc-content-search-bar
  [defaultCurriculumGroup]="defaultCurriculumGroup"
  [defaultSubjectGroup]="defaultSubjectGroup"
  [options]="options"
  [showBlips]="showBlips"
  (searchEvent)="onSearch($event)"
  (resetResults)="resetSearch()"
  (courseGroupGuidanceText)="courseGroupGuidanceText.emit($event)"
></uc-content-search-bar>

