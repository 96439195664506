<uc-online-header-bar
  [hasSideNav]="false"
  [hideNavItems]="true"
  [hideToolbarItems]="true"
  class="online-header-bar"
></uc-online-header-bar>
<div
  id="main-content"
  tabindex="-1"
  class="profile-content"
>
  <uc-modal
    identifier="validationWarningModal"
    [closable]="true"
  >
    <h1 class="modal-title">Validation Warning</h1>
    <div class="modal-body">
      <span>Please double check below section(s):</span>
      <div
        *ngFor="let err of invalidSections"
        class="error"
      >
        > {{ err }}
      </div>
    </div>
    <div class="modal-footer">
      <uc-button
        testSelector="cancel-process"
        [isLoading]="isLoading"
        class="online-ui-button"
        (click)="closeValidationWarningModal()"
      >
        Close
      </uc-button>
    </div>
  </uc-modal>
  <div class="content-inner">
    <uc-form-template>
      <uc-go-back-link></uc-go-back-link>
      <uc-form-header [title]="profileStrings.myAccount">
        <uc-message-banner class="heading-banners margin-btm-20">
          {{ profileStrings.mandatoryBanner }}
          <uc-required-field></uc-required-field>
        </uc-message-banner>
      </uc-form-header>
      <uc-online-tabs #profileTabs>
        <uc-online-tab title="Personal">
          <div
            class="profile-review-section"
            *ngIf="!isEditProfile"
          >
            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="First name">
                  <uc-online-disabled-atom-state [value]="firstName"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Middle name">
                  <uc-online-disabled-atom-state [value]="middleName"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
            </div>

            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Last name">
                  <uc-online-disabled-atom-state [value]="surname"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Preferred name">
                  <uc-online-disabled-atom-state [value]="preferredName"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
            </div>

            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Date of birth">
                  <uc-online-disabled-atom-state [value]="dateOfBirth"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Gender">
                  <uc-online-disabled-atom-state [value]="gender"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
            </div>

            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Ethnicity">
                  <uc-online-disabled-atom-state [value]="ethnicity"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Iwi">
                  <uc-online-disabled-atom-state [value]="iwi"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
            </div>

            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Country of citizenship">
                  <uc-online-disabled-atom-state [value]="countryOfCitizenship"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Citizenship or residency category">
                  <uc-online-disabled-atom-state [value]="citizenshipCategory"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
            </div>
            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label
                  labelText="Do you have an impairment, disability or long term medical condition(s)?"
                >
                  <uc-online-disabled-atom-state [value]="declarationDisability"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item"></div>
            </div>
          </div>
          <uc-online-personal-details
            *ngIf="isEditProfile"
            [onlinePersonalDetails]="onlineProfilePage.get('personalDetails')"
            [onlinePersonalDetailsEl]="onlineProfileForm.controls['personalDetails']"
          >
          </uc-online-personal-details>
        </uc-online-tab>

        <uc-online-tab title="Contact">
          <div
            class="profile-review-section"
            *ngIf="!isEditProfile"
          >
            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Email">
                  <uc-online-disabled-atom-state [value]="email"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Mobile">
                  <uc-online-disabled-atom-state [value]="mobileNum"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
            </div>
            <div class="profile-form-row">
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Preferred method of contact">
                  <uc-online-disabled-atom-state [value]="preferredContactMethod"></uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
              <div class="profile-form-row-item">
                <uc-form-field-label labelText="Address">
                  <uc-online-disabled-atom-state>
                    <div *ngIf="!!currentAddressLine1">
                      {{ currentAddressLine1 }}<br />
                      {{ currentAddressLine2 || '-' }}<br />
                      {{ currentAddressCityTownPostcode || '-' }}<br />
                      {{ currentAddressRegion || '-' }}<br />
                      {{ currentAddressCountry || '-' }}<br />
                    </div>
                    <div *ngIf="!currentAddressLine1">-</div>
                  </uc-online-disabled-atom-state>
                </uc-form-field-label>
              </div>
            </div>
          </div>

          <uc-section
            [formGroup]="onlineProfilePage.get('contactDetails')"
            *ngIf="isEditProfile"
          >
            <uc-section-header [title]="profileStrings.contactDetails"></uc-section-header>

            <uc-subsection>
              <uc-form-input>
                <uc-string-input
                  testSelector="contact-email"
                  class="input-medium"
                  [label]="profileStrings.emailLabel"
                  formControlName="email"
                  required="yes"
                ></uc-string-input>
              </uc-form-input>
            </uc-subsection>

            <uc-subsection>
              <uc-form-input>
                <uc-subsection-question>{{ phoneStrings.phoneNumberQuestion }}</uc-subsection-question>
                <uc-subsection-minor-text>{{ phoneStrings.mobileHint }}</uc-subsection-minor-text>
                <uc-phone-selector
                  [required]="true"
                  testSelector="profile-mobile"
                  formControlName="mobileNum"
                  [labelText]="phoneStrings.mobileLabel"
                >
                </uc-phone-selector>
              </uc-form-input>
            </uc-subsection>
            <uc-subsection>
              <uc-form-field-label [labelText]="profileStrings.preferredContactMethodLabel">
                <uc-form-input>
                  <uc-radio-input-group
                    groupName="preferred-contact-method"
                    class="preferredContactMethod"
                    formControlName="preferredContactMethod"
                    ngDefaultControl
                    [options]="preferredContactMethodOptions"
                    [displayHorizontally]="true"
                    label=""
                  >
                  </uc-radio-input-group>
                </uc-form-input>
              </uc-form-field-label>
            </uc-subsection>

            <uc-subsection>
              <uc-form-input>
                <uc-subsection-question>
                  {{ profileStrings.homeAddressQuestion }}
                  <uc-required-field></uc-required-field>
                </uc-subsection-question>
                <uc-subsection-minor-text>
                  {{ profileStrings.addressPredictionHelp }}
                </uc-subsection-minor-text>
                <uc-address-selector
                  testSelector="home"
                  formControlName="address"
                ></uc-address-selector>
              </uc-form-input>
            </uc-subsection>
          </uc-section>
        </uc-online-tab>

        <uc-online-tab
          title="Documents"
          *ngIf="hasStudentId"
        >
          <uc-form-template>
            <uc-form-header [title]="documentStrings.header">
              <span [innerHTML]="documentStrings.description"></span>
              <uc-message-banner type="info">
                <span [innerHTML]="helpText"></span>
              </uc-message-banner>
            </uc-form-header>
            <uc-online-category-file-upload *ngIf="hasStudentId"></uc-online-category-file-upload>
            <uc-file-list-container
              *ngFor="let category of categories"
              [category]="category"
            >
            </uc-file-list-container>
          </uc-form-template>
        </uc-online-tab>
      </uc-online-tabs>

      <div class="page-actions">
        <uc-button
          *ngIf="!isEditProfile && !isDocumentsTabActive()"
          testSelector="edit"
          class="edit-btn online-ui-button"
          (click)="switchToEditProfile()"
          [isLoading]="isLoading"
        >
          {{ profileStrings.editButton }}
        </uc-button>
        <uc-button
          *ngIf="isEditProfile && !isDocumentsTabActive()"
          testSelector="update"
          class="submit-btn online-ui-button"
          (click)="validateAndUpdate()"
          [isLoading]="isLoading"
        >
          {{ profileStrings.updateButton }}</uc-button
        >
      </div>
    </uc-form-template>
  </div>
</div>
