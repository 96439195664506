import { Injectable } from '@angular/core';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';

import { environment } from '@environment';
import { UCError } from '@shared/models/errors';
import { CustomerId, PAYMENT_SERVICE_PATH, Payment } from '@shared/models/payment';
import { DataService, IDSRequestOpts, UCErrorCodes } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { mockData as mockPaymentResponse } from '@shared/services/payment/payment.data.mock';

@Injectable()
export class PaymentService {
  private serviceUrl: string = environment.apiRoot + PAYMENT_SERVICE_PATH;
  private error$ = new Subject<UCError>();
  public payment$ = new BehaviorSubject<Payment>(null);
  private log: Logger;

  constructor(
    private dataService: DataService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  get payment(): Payment {
    return this.payment$.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getRequestOptions(options?: { [key: string]: any }): IDSRequestOpts {
    return {
      error$: this.error$,
      deserialize: Payment.deserialize,
      ...options,
    };
  }

  getPaymentLink(process: string, academicYear: string, courseCode: string, occurrence: string): Observable<Payment> {
    const url = `${this.serviceUrl}/${academicYear}/${process}/${courseCode}/${occurrence}/payment_link`;
    return this.dataService.fetch(
      url,
      this.getRequestOptions({
        error$: this.error$,
        success$: this.payment$,
      }),
    );
  }

  getCustomerIdAsStaffForUser(user: string): Observable<CustomerId> {
    const url = `${this.serviceUrl}/staff/${user}/stripe-customer-id/`;
    return this.dataService.fetch(url, {
      error$: this.error$,
      deserialize: CustomerId.deserialize,
      errorCodes: {
        '417': UCErrorCodes.E417,
        '404': UCErrorCodes.E404,
      },
    });
  }
}

export class MockPaymentService {
  public error$ = new Subject<UCError>();
  public payment$ = new BehaviorSubject<Payment>(null);

  get error(): Observable<UCError> {
    return this.error$.asObservable();
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  getPaymentLink(process: string, academicYear: string, courseCode: string, occurrence: string): Observable<Payment> {
    const payment = Payment.deserialize(mockPaymentResponse());
    this.payment$.next(payment);

    if (payment.manifest.find((data) => data.courseCode === courseCode)) {
      return of(payment);
    } else {
      payment.link = undefined;
      return of(payment);
    }
  }
}
