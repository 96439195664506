import { Component, Input } from '@angular/core';

@Component({
  selector: 'uc-online-status-pill',
  templateUrl: './online-status-pill.component.html',
  styleUrls: ['./online-status-pill.component.scss'],
})
export class OnlineStatusPillComponent {
  @Input() color;
  @Input() value;
  @Input() action;
  @Input() clickable;
}
