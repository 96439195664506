import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';

import { ModalCoordinatorService } from '@shared/services/modal/modal-coordinator.service';

@Component({
  selector: 'uc-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent {
  @Input() closable = true;
  @Input() dismissable = true;
  @Input() @HostBinding('class.loading') loading = false;
  @Input() @HostBinding('style.z-index') zIndex: number;
  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() dismissed = new EventEmitter();

  @ViewChild('title', { static: true }) titleContent: ElementRef;
  @ViewChild('footer', { static: true }) footerContent: ElementRef;

  @HostBinding('class.open') private isOpen = false;

  constructor(private coordinator: ModalCoordinatorService) {}

  get shouldShowTitle() {
    return this.titleContent?.nativeElement.childNodes.length > 0;
  }

  get shouldShowFooter() {
    return this.footerContent?.nativeElement.childNodes.length > 0;
  }

  open() {
    this.isOpen = true;
    this.coordinator.push(this);
    this.opened.emit();
  }

  close() {
    this.isOpen = false;
    this.coordinator.remove(this);
    this.closed.emit();
  }

  dismiss() {
    this.isOpen = false;
    this.coordinator.remove(this);
    this.dismissed.emit();
  }
}
