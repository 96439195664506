<div class="auth-action-wrapper">
  <uc-spinner *ngIf="isLoading"></uc-spinner>

  <div *ngIf="!isLoading">
    <div *ngIf="showPasswordPage">
      <form
        *ngIf="!showFailure && !showSuccess"
        (submit)="resetPassword()"
        [formGroup]="passwordForm"
      >
        <h3>{{ strings.password.title }}</h3>
        <uc-password-input
          testSelector="new-auth"
          required="yes"
          [label]="strings.password.newPassword"
          controlName="newPassword"
          [form]="passwordForm"
        >
        </uc-password-input>

        <uc-password-input
          testSelector="confirm-auth"
          required="yes"
          [label]="strings.password.confirmPassword"
          controlName="confirmPassword"
          [form]="passwordForm"
        >
        </uc-password-input>
        <uc-message-banner
          type="warning"
          *ngIf="!!failedPasswordUpdateString"
          >{{ failedPasswordUpdateString }}</uc-message-banner
        >
        <button
          [attr.data-cy]="'reset-password'"
          uc-button
          (click)="resetPassword($event)"
        >
          reset
        </button>
      </form>

      <div *ngIf="showSuccess">
        <h3>{{ strings.password.success.title }}</h3>
        <p>{{ strings.password.success.body }}</p>
        <button
          [attr.data-cy]="'password-success'"
          uc-button
          (click)="goToLogin()"
        >
          {{ strings.continue }}
        </button>
      </div>

      <div *ngIf="showFailure">
        <h3>{{ strings.password.failure.title }}</h3>
        <p>{{ strings.password.failure.body }}</p>
        <button
          [attr.data-cy]="'password-failure'"
          uc-button
          (click)="goToLogin()"
        >
          {{ strings.continue }}
        </button>
      </div>
    </div>

    <div *ngIf="showVerifyEmailPage">
      <div *ngIf="showSuccess">
        <h3>{{ strings.verifyEmail.success.title }}</h3>
        <p>{{ strings.verifyEmail.success.body }}</p>
        <button
          [attr.data-cy]="'verify-success'"
          uc-button
          (click)="goToLogin()"
        >
          {{ strings.continue }}
        </button>
      </div>

      <div *ngIf="showFailure">
        <h3>{{ strings.verifyEmail.failure.title }}</h3>
        <p>{{ strings.verifyEmail.failure.body }}</p>
        <button
          [attr.data-cy]="'verify-failure'"
          uc-button
          (click)="goToLogin()"
        >
          {{ strings.continue }}
        </button>
      </div>
    </div>

    <div *ngIf="showRecoverEmailPage">
      <div *ngIf="showSuccess">
        <h3>{{ strings.recoverEmail.success.title }}</h3>
        <p>{{ strings.recoverEmail.success.body(email) }}</p>
        <button
          [attr.data-cy]="'recovery-success'"
          uc-button
          (click)="goToLogin()"
        >
          {{ strings.continue }}
        </button>
      </div>

      <div *ngIf="showFailure">
        <h3>{{ strings.recoverEmail.failure.title }}</h3>
        <p [innerHTML]="strings.recoverEmail.failure.body"></p>
        <button
          [attr.data-cy]="'recovery-failure'"
          uc-button
          (click)="goToLogin()"
        >
          {{ strings.continue }}
        </button>
      </div>
    </div>
  </div>
</div>
