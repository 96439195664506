import { Component, OnInit, Input } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { of, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { EnrolledQualification, Qualification, SubjectOptions } from '@shared/models/qualification';
import { QualificationOccurrence } from '@shared/models/qualification-occurrence';
import { ReferenceData } from '@shared/models/reference-data';
import { QualificationService } from '@shared/services/qualification/qualification.service';

export interface QualificationSelection {
  qualTitle: string;
  qualCode: string;
  subjectAnswer: { [key: string]: ReferenceData[] };
  startDate: string;
  subjectOptions: SubjectOptions[];
}

@Component({
  selector: 'uc-qualification-detail-card',
  templateUrl: './qualification-detail-card.component.html',
  styleUrls: ['./qualification-detail-card.component.scss'],
})
export class QualificationDetailCardComponent implements OnInit {
  @Input() enrolledQuals: EnrolledQualification[] = [];
  @Input() applicationYear: string;
  @Input() displayStartDate = true;
  strings = strings.components.tasks.qualificationDetail;
  qualList: QualificationSelection[] = [];
  qualsLoading = true;

  constructor(private qualificationService: QualificationService) {}

  ngOnInit() {
    const obs = [];
    let ob;
    if (!this.enrolledQuals) {
      return;
    }
    this.enrolledQuals.forEach((eq) => {
      {
        ob = zip(
          this.qualificationService
            .getQualificationOccurrence(eq.qualificationOccurrence)
            .pipe(catchError(() => of(null))),
          this.qualificationService.getQualification(this.applicationYear, eq.code),
          of(eq.subjectOptions),
        );
        obs.push(ob);
      }
    });

    zip(...obs).subscribe((response: [QualificationOccurrence, Qualification, SubjectOptions][]) => {
      // Hydrate qual, occurrence and subject option data
      let subjectOptions;
      let qualSubjectOptions;
      response.forEach((res) => {
        subjectOptions = {};
        const [occurrence, qual, subjectOptionsList] = res;
        if (!isEmpty(subjectOptionsList)) {
          Object.keys(subjectOptionsList).forEach((key) => {
            qualSubjectOptions = qual.subjectOptions.filter((subject) => subject.level === Number(key));
            if (qualSubjectOptions.length !== 0) {
              subjectOptions[qualSubjectOptions[0].level] = subjectOptionsList[key];
            }
          });
        }
        this.qualList.push({
          qualTitle: qual.title,
          qualCode: qual.code,
          subjectAnswer: subjectOptions,
          startDate: occurrence?.startDate,
          subjectOptions: qual.subjectOptions,
        });
        this.qualsLoading = false;
      });
    });
  }
}
