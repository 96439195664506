import { Component, OnInit, Input, ViewChild } from '@angular/core';

import strings from '@constants/strings.constants';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'uc-disabled-atom-state',
  templateUrl: './disabled-atom-state.component.html',
  styleUrls: ['./disabled-atom-state.component.scss'],
})
export class DisabledAtomStateComponent implements OnInit {
  @Input() value: string;
  @Input() enableModal = true;

  @ViewChild(ConfirmationModalComponent) explanationModal;

  strings = strings.components.atoms.disabledStateComponent;

  ngOnInit() {}

  openModal() {
    this.explanationModal.open();
  }
}
