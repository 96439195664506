<p
  class="guidance"
  [innerHTML]="strings.changeQual"
></p>
<uc-button
  testSelector="change-qual"
  class="rounded secondary inline-icon-btn view-button qual-change"
  (click)="changeQualModal()"
>
  <uc-icon
    iconType="pencil"
    iconWidth="22"
    iconHeight="22"
    iconFill="$colorWhite"
  ></uc-icon>
  {{ strings.buttonText }}
</uc-button>

<uc-confirmation-modal
  #confirmationModal
  [title]="strings.header"
  [message]="strings.body"
  [acceptText]="strings.ok"
  [onAcceptedBeforeClose]="startChangeOfApplication"
  declineText="Cancel"
  declineType="diminished"
>
</uc-confirmation-modal>

<uc-confirmation-modal
  #embargoModal
  [title]="nceaNotAllowedStrings.header"
  [message]="nceaNotAllowedStrings.body"
  [acceptText]="nceaNotAllowedStrings.ok"
></uc-confirmation-modal>

<uc-confirmation-modal
  #coaInProgressModal
  [title]="coaNotAllowedStrings.header"
  [message]="coaNotAllowedStrings.body"
  [acceptText]="coaNotAllowedStrings.ok"
></uc-confirmation-modal>
