<uc-form-template>
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header title="{{ strings.recordDeclaration }}">
      <p>{{ strings.declarationDescription[0] }}</p>
      <p>{{ strings.declarationDescription[1] }}</p>
      <strong
        ><p>{{ strings.declarationDescription[2] }}</p></strong
      >
    </uc-section-header>

    <uc-police-record
      [task]="task"
      [hintPath]="hintPath"
      [policeRecord]="policePage.get('policeRecord')"
    ></uc-police-record>
  </uc-section>
</uc-form-template>

<uc-form-template [formGroup]="policePage">
  <uc-section>
    <uc-section-header title="{{ strings.nzCheck.header }}">
      <p>{{ strings.nzCheck.description[0] }}</p>
      <p>{{ strings.nzCheck.description[1] }}</p>
      <br />
      <h5>{{ strings.identityValidation.header }}</h5>
      <p>{{ strings.identityValidation.description }}</p>
    </uc-section-header>
    <uc-subsection>
      <uc-subsection-question>{{ strings.identityValidation.questions[0] }}</uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.identityValidation.driversLicenseHint }}</uc-subsection-minor-text>
      <uc-form-input>
        <uc-string-input testSelector="nz-drivers-license-number" formControlName="nzDriversLicense"> </uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-subsection-question>{{ strings.identityValidation.questions[1] }}</uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.identityValidation.passportNumberHint }}</uc-subsection-minor-text>
      <uc-form-input>
        <uc-string-input testSelector="nz-passport-number" formControlName="nzPassportNumber"> </uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/demographic/city_of_birth"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.identityValidation.questions[2] }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-string-input testSelector="city-of-birth" formControlName="cityOfBirth" required="yes"> </uc-string-input>
      </uc-form-input>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/demographic/country_of_birth/code"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.identityValidation.questions[3] }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-form-input>
        <uc-reference-data-selector
          type="country"
          testSelector="country-of-birth"
          formControlName="countryOfBirth"
          required="yes"
        >
        </uc-reference-data-selector>
      </uc-form-input>
    </uc-subsection>
  </uc-section>
</uc-form-template>

<uc-form-template>
  <uc-section>
    <uc-section-header title="{{ strings.overseasCheck.header }}">
      <p>{{ strings.overseasCheck.description }}</p>
    </uc-section-header>

    <uc-overseas-police-check
      [applicationYear]="applicationYear"
      [task]="task"
      [hintPath]="hintPath"
      (checkRemove)="this.checks.removeAt($event)"
      (newCheck)="this.checks.push()"
      [overseasCheck]="policePage.get('overseasCheck')"
    >
    </uc-overseas-police-check>
  </uc-section>
</uc-form-template>

<h4>{{ declarationStrings.policeDeclaration.header }}</h4>
<div class="declaration text-content" [innerHTML]="declarationStrings.policeDeclaration.html"></div>

<uc-task-hint [task]="task" [model]="hintPath" path="/police_check/declaration_police_agree"></uc-task-hint>
<uc-form-field-label>
  {{ declarationStrings.agreeToDeclaration }}<uc-required-field></uc-required-field>
</uc-form-field-label>
<uc-checkbox-input-group
  groupName="dec-check"
  [options]="declarationOptions"
  [formControl]="policePage.get('declarationCheck')"
>
</uc-checkbox-input-group>
