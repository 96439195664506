<div *ngIf="task?.hints?.length > 0 && firstHint && shouldDisplay" class="task-hints-container">
  <h4 class="banner__heading">{{strings.stillToComplete}}</h4>
  <div class="validation-message">
    <uc-icon
      class="validation-message__icon"
      iconType="alert"
      iconWidth="18"
      iconHeight="18"
      iconFill="$colorAmber">
    </uc-icon>
    <span class="validation-message__text">{{strings.questionsToComplete(orderedHints.length)}}</span>
    <span class="validation-message__action" (click)="jumpToHint(firstHint)">{{strings.firstOne}}</span>
  </div>
</div>
