import { Component, Input } from '@angular/core';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-phd-accept-decline',
  templateUrl: './phd-accept-decline.component.html',
})
export class PhdAcceptDeclineComponent extends AbstractBaseTask {
  @Input() task: Task;
  strings = strings.components.tasks.phdAcceptDecline;

  updateFormValidity() {}
}
