<uc-subsection>
  <uc-form-input>
    <uc-associated-person
      testSelector="school-report"
      [hintPath]="hintPath"
      [associatedPersonForm]="schoolReport"
      [allRequired]="false"
      [strings]="strings.associatedPerson"
    ></uc-associated-person>
  </uc-form-input>
</uc-subsection>
