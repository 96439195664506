<div [formGroup]="addressModel">
  <uc-address-prediction
    [task]="task"
    [basePath]="basePath"
    [testSelector]="testSelector"
    [addressModel]="addressModel"
    [required]="required"
  >
  </uc-address-prediction>
  <uc-string-input [testSelector]="testSelectorId + '-line-2'" formControlName="line2" [label]="strings.addressLine2">
  </uc-string-input>
  <uc-task-hint [task]="task" [path]="getHintPath('town')"></uc-task-hint>
  <uc-string-input
    [testSelector]="testSelectorId + '-town'"
    class="input-medium"
    [label]="strings.town"
    [required]="required"
    formControlName="town"
  >
  </uc-string-input>
  <uc-string-input
    [testSelector]="testSelectorId + '-region'"
    class="input-medium"
    [label]="strings.region"
    formControlName="region"
  >
  </uc-string-input>
  <uc-task-hint [task]="task" [path]="getHintPath('country')"></uc-task-hint>
  <uc-form-field-label [labelText]="strings.country" [required]="required">
    <uc-reference-data-selector formControlName="country" type="country"></uc-reference-data-selector>
  </uc-form-field-label>
  <uc-task-hint [task]="task" [path]="getHintPath('postcode')"></uc-task-hint>
  <uc-string-input
    [testSelector]="testSelectorId + '-postcode'"
    [label]="strings.postcode"
    [required]="required"
    class="input-small"
    formControlName="postcode"
  >
  </uc-string-input>
  <div class="attributions">
    <img
      ucTooltip
      [tooltipContent]="strings.attributions"
      class="label-tooltip tooltip-btn"
      src="assets/img/powered-by-google.png"
      alt=""
    />
  </div>
</div>
