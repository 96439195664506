<div
  class="search-filters__wrapper"
  [formGroup]="searchFiltersForm"
  *ngIf="showFilters"
>
  <div class="search-filters__show-text">{{ strings.show }}:</div>
  <ng-container *ngFor="let filterName of filterNames">
    <ng-container *ngIf="filterName !== 'site'">
      <select
        class="search-filters__select-content"
        [attr.data-cy]="'filter-' + filterName"
        [formControlName]="filterName"
      >
        <option
          *ngFor="let option of options[filterName]"
          [value]="option.value"
        >
          {{ option.labelText }}
        </option>
      </select>
    </ng-container>
    <ng-container *ngIf="filterName === 'site'">
      <uc-reference-data-multi-selector
        class="search-filters__select-content multi-select"
        formControlName="site"
        placeholder="Select a site"
        [noIcon]="true"
        [options]="options[filterName]"
        [filter]="selectedOptions[filterName]"
        [searchFilter]="false"
      >
      </uc-reference-data-multi-selector>
    </ng-container>
  </ng-container>
</div>
