<uc-form-template>
  <uc-form-header [title]="task?.title"> </uc-form-header>
  <uc-message-banner
    *ngIf="stageComplete"
    type="info"
  >
    <span [innerHTML]="messageBannerText"></span>
  </uc-message-banner>

  <uc-application-hints
    *ngIf="!stageComplete"
    [stage]="stage"
    [task]="task"
  ></uc-application-hints>

  <div
    class="info-text"
    [innerHTML]="infoTextBody"
  ></div>
</uc-form-template>
