import { camelizeKeys } from '@shared/helpers/serialization';

export class SanctionType {
  code: string;
  description: string;
  mandatoryActions: {
    code: string;
    description: string;
    validFrom: string;
    validTo: string;
  }[];
  displayRemovedOnTranscript: boolean;
  validFrom: string;
  validTo: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload: any): Sanction[] {
    if (payload === null) {
      return null;
    }

    payload = payload.sanction_types || payload;
    return payload.map((sanction) => {
      sanction = camelizeKeys(sanction);
      sanction.mandatoryActions = sanction.mandatoryActions.map((action) => camelizeKeys(action));
      return new SanctionType(sanction);
    });
  }
}

export class Sanction {
  internalReference: string;
  externalReference: string;
  type: string;
  state: {
    code: string;
  };
  comments: string;
  validFrom: string;
  validTo: string;
  actions: [
    {
      code: string;
      description: string;
      validFrom: string;
      validTo: string;
    },
  ];
  restrictions: [
    {
      type: 'points' | 'qualification';
      points: string;
      qualification: string;
      code: string;
    },
  ];
  actionString?: string;
  restrictionString?: string;
  stateString?: string;
  validToString?: string;
  validFromString?: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload: any): Sanction[] {
    if (payload === null) {
      return null;
    }

    payload = payload.sanctions || payload.sanction || payload;
    return payload.map((sanction) => {
      sanction = camelizeKeys(sanction);
      sanction.restrictions = sanction.restrictions.map((restiction) => camelizeKeys(restiction));
      return new Sanction(sanction);
    });
  }
}
