import { Component, Input } from '@angular/core';

import strings from '@shared/constants/strings.constants';

@Component({
  selector: 'uc-online-footer-minimal',
  templateUrl: './online-footer-minimal.component.html',
  styleUrls: ['./online-footer-minimal.component.scss'],
})
export class OnlineFooterMinimalComponent {
  @Input() fullPageView = false;
  @Input() showHelp = false;
  footerStrings = strings.components.molecules.onlineFooter;
}
