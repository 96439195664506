import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms';
import { get } from 'lodash-es';

import { SECONDARY_QUAL_CODES, COUNTRY_OPTIONS, QUALIFICATION_LEVELS } from '@constants/academic-history';
import strings from '@constants/strings.constants';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { Task } from '@shared/models/task';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-secondary-education',
  templateUrl: './secondary-education.component.html',
  styleUrls: ['./secondary-education.component.scss'],
})
export class SecondaryEducationComponent implements OnInit {
  @Input() task: Task;
  @Input() secondaryEducationForm: UntypedFormGroup;
  @Input() secondaryLocationForm: UntypedFormArray;
  @Input() applicationYear: string;
  @Input() highestStudyLevel: string;
  @Input() process: string;
  @Output() addQual = new EventEmitter();
  @Output() removeQual = new EventEmitter();

  log: Logger;
  strings = strings.components.organisms.secondaryEducation;
  hasInternationalStudy = false;
  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  waitingForResultsOptions = [
    {
      labelText: 'Yes',
      value: false,
    },
    {
      labelText: 'No',
      value: true,
    },
  ];

  countryOptions = [
    {
      labelText: 'Yes',
      value: COUNTRY_OPTIONS.NZL,
    },
    {
      labelText: 'No',
      value: COUNTRY_OPTIONS.OTHER,
    },
  ];

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get educations(): UntypedFormArray {
    return this.secondaryEducationForm.get('educations') as UntypedFormArray;
  }

  get nsnNumber(): AbstractControl {
    return this.secondaryEducationForm.get('nsnNumber');
  }

  get isMicroCredentialProcess(): boolean {
    return this.process === PROCESS_NAMES.MCED || this.process === PROCESS_NAMES.MICRO_CREDENTIAL;
  }

  ngOnInit() {
    this.secondaryLocationForm.controls.forEach((control) => {
      control.valueChanges.subscribe((data) => {
        if (data !== COUNTRY_OPTIONS.NZL) {
          const index = this.secondaryLocationForm.controls.indexOf(control);
          this.educations.at(index).get('country').setValue({ code: undefined });
        }
      });
    });
  }

  hasSelectedLocation(index): boolean {
    return !!get(this.secondaryLocationForm.at(index), 'value');
  }

  showOtherQualInput(qualNum) {
    const type = this.educations.controls[qualNum].get('type');
    const qualType = get(type, 'value.code');
    return qualType === SECONDARY_QUAL_CODES.OTHER;
  }

  showNCEA2Banner(qualNum) {
    const type = this.educations.controls[qualNum].get('type');
    const qualType = get(type, 'value.code');
    return qualType === SECONDARY_QUAL_CODES.NCEA2;
  }

  showOtherLastYearAttended() {
    const lastYearHighSchoolValue = this.secondaryEducationForm.get('wasLastYearAtHighSchool');
    return get(lastYearHighSchoolValue, 'value') === false;
  }

  showSecondaryDateAttained(qualNum) {
    // return false if secondary qualification type 'no formal qualification' or 'not known'
    const type = this.educations.controls[qualNum].get('type');
    const qualType = get(type, 'value.code');
    return qualType !== SECONDARY_QUAL_CODES.NO_FORMAL && qualType !== SECONDARY_QUAL_CODES.UNKNOWN;
  }

  showUploadResults() {
    if (this.isMicroCredentialProcess) {
      return false;
    }
    this.hasInternationalStudy = false;
    const highestStudySecondary = this.highestStudyLevel === QUALIFICATION_LEVELS.SECONDARY;
    if (!highestStudySecondary) {
      return false;
    }
    let domesticNotNCEA3 = false;
    this.educations.controls.forEach((formGroup, index) => {
      const type = get(formGroup.get('type').value, 'code');
      const notNCEA3 = type && type !== SECONDARY_QUAL_CODES.NCEA3;
      const domesticStudy = this.isDomestic(index);
      domesticNotNCEA3 = domesticNotNCEA3 ? true : domesticStudy && notNCEA3;
      this.hasInternationalStudy = this.hasInternationalStudy ? true : !domesticStudy;
    });

    return domesticNotNCEA3 || this.hasInternationalStudy;
  }

  hasDateAttained(qualNum) {
    const dateAttained = this.educations.controls[qualNum].get('dateAttained');
    return get(dateAttained, 'value');
  }

  showAttainedField(qualNum) {
    const attainedDateQuals = [SECONDARY_QUAL_CODES.NCEA3, SECONDARY_QUAL_CODES.IB, SECONDARY_QUAL_CODES.CIE];
    const type = this.educations.controls[qualNum].get('type');
    const qualType = get(type, 'value.code');
    return attainedDateQuals.indexOf(qualType) !== -1;
  }

  showOverseasSchoolWarning(qualNum) {
    const studiedInNZ = this.isDomestic(qualNum);
    const school = this.educations.controls[qualNum].get('source').value;
    const overseasSchool = get(school, 'code') === '999';
    return studiedInNZ && school && overseasSchool;
  }

  addQualification() {
    this.addQual.emit({ type: 'secondaryEducation' });
  }

  removeQualification(indexToBeRemoved) {
    this.removeQual.emit(indexToBeRemoved);
  }

  isDomestic(qualNum) {
    const country = get(this.secondaryLocationForm.at(qualNum), 'value');
    return country === COUNTRY_OPTIONS.NZL;
  }

  isSchoolNotOnList(qualNum) {
    const school = this.educations.controls[qualNum].get('source').value;
    return get(school, 'code') === '998';
  }
}
