import { Component, OnInit, Input } from '@angular/core';
import strings from '@constants/strings.constants';
import { UCElementGroup, group, control } from '@shared/services/form-model-mapper/form';
import { UntypedFormGroup } from '@angular/forms';
import { UCValidators } from '@shared/models/validators/validators';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { UCError } from '@shared/models/errors';
import { Application } from '@shared/models/application';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Task } from '@shared/models/task';
import { takeUntil, filter } from 'rxjs/operators';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { PostgradIntentions } from '@shared/models/postgrad-intentions';

@Component({
  selector: 'uc-postgrad-intentions',
  templateUrl: './postgrad-intentions.component.html',
})
export class PostgradIntentionsComponent extends AbstractBaseTask implements OnInit {
  strings = strings.components.tasks.postgradIntentions;

  questionGroup: UCElementGroup;
  questionForm: UntypedFormGroup;

  @Input() task: Task;
  @Input() processComplete = false;
  @Input() applicationYear: string;
  @Input() process: string;
  @Input() stage: string;

  private log: Logger;

  constructor(
    loggingService: LoggingService,
    private formModel: FormModelMapperService,
    private applicationService: ApplicationService,
    private coeService: ChangeOfEnrolmentService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);
    this.questionGroup = this.createForm();
    this.questionForm = this.questionGroup.asControl() as UntypedFormGroup;
    this.service.application
      .pipe(
        takeUntil(this.componentDestroyed),
        filter((v) => !!v),
      )
      .subscribe((application) => {
        this.currentApplication = application;
        if (!application.postgradIntentions) {
          application.postgradIntentions = new PostgradIntentions();
        }
        this.formModel.updateFormFromModel(this.questionGroup, null, application);
      });
  }

  private createForm(): UCElementGroup {
    return group({
      whyThisQualification: control({
        defaultState: '',
        model: this.dataModel,
        path: '/postgradIntentions/whyQualificationChosen',
        validators: [UCValidators.createWordCountValidator(500)],
      }),
      whatNext: control({
        defaultState: '',
        model: this.dataModel,
        path: '/postgradIntentions/whatAreYourGoals',
        validators: [UCValidators.createWordCountValidator(500)],
      }),
      supervisorName: control({
        model: this.dataModel,
        path: '/postgradIntentions/supervisorName',
        defaultState: '',
      }),
    });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.questionGroup);
  }

  updateChangeOfEnrolmentModel(): ChangeOfEnrolment {
    return new ChangeOfEnrolment({
      postgradIntentions: new PostgradIntentions({
        whyQualificationChosen: this.currentApplication.postgradIntentions.whyQualificationChosen,
        whatAreYourGoals: this.currentApplication.postgradIntentions.whatAreYourGoals,
        supervisorName: this.currentApplication.postgradIntentions.supervisorName,
      }),
    });
  }

  public update() {
    this.formModel.updateModelFromForm(this.questionGroup, null, this.currentApplication);
    const application = this.isCOE ? this.updateChangeOfEnrolmentModel() : this.currentApplication;

    this.service.update(application).subscribe(
      (applicant: Application) => {
        this.log.info('updated applicant model');
        this.next.emit();
      },
      (err) => {
        this.errors.emit();
        this.log.error('failed to update applicant', err);
      },
    );
  }
}
