import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-more-about-your-studies',
  templateUrl: './more-about-your-studies.component.html',
})
export class MoreAboutYourStudiesComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() year: string;
  @Input() task: Task;
  @Input() maysForm: UntypedFormGroup;
  @Input() isFirstYear: UntypedFormControl;

  strings = strings.components.organisms.moreAboutYourStudies;

  constructor() {
    super();
  }

  get getTaskOrNull() {
    return this.isFirstYear.value !== 'hide' ? this.task : null;
  }

  ngOnInit() {
    this.firstYearValue();
  }

  get firstYear() {
    return Number(this.year);
  }

  private firstYearValue() {
    this.isFirstYear.valueChanges.pipe(takeUntil(this.componentDestroyed)).subscribe((value) => {
      if (value === 'show') {
        this.maysForm.get('firstYearHere').setValue(this.year);
      }
      if (value === 'hide') {
        this.maysForm.get('firstYearHere').reset();
      }
    });
  }
}
