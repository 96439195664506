import { Component, Input, EventEmitter, SimpleChanges, OnChanges, Output } from '@angular/core';
import strings from '@constants/strings.constants';
import { CHANGE_ACTIONS } from '@shared/constants/states.constants';

@Component({
  selector: 'uc-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.scss'],
})
export class AddCourseComponent implements OnChanges {
  @Input() courseCode: string;
  @Input() selectedCourses = {};
  @Input() cancelSpinner: boolean;
  @Input() changeAction: string;
  strings = strings.components.molecules.courseSummaryCard;

  tooltipMessage = this.strings.courseAdded;
  selectedCourse = false;
  actionComplete = false;
  showSpinner = false;
  showTooltip = false;
  tooltipTimeout;

  @Output() addCourse = new EventEmitter();

  constructor() {}

  get testSelector() {
    return this.courseCode.toLowerCase().replace(/ /g, '_');
  }

  get hasChangeAction() {
    return !!this.changeAction && this.changeAction !== CHANGE_ACTIONS.NONE;
  }

  get changeActionLabel() {
    return this.strings.states[this.changeAction];
  }

  get changeActionClass() {
    if (!this.hasChangeAction) {
      return '';
    }
    const changeClass = this.changeAction === CHANGE_ACTIONS.ADDED ? ' added-course' : ' dropped-course';
    return `change-label${changeClass}`;
  }

  addACourse() {
    if (this.showSpinner) {
      return;
    }
    this.showSpinner = true;
    this.addCourse.emit(this.courseCode);
    this.actionComplete = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cancelSpinner && changes.cancelSpinner.currentValue) {
      this.showSpinner = false;
    }

    if (changes.selectedCourses && changes.selectedCourses.currentValue) {
      this.selectedCourse = changes.selectedCourses.currentValue[this.courseCode];
      this.showSpinner = false;

      if (this.actionComplete && this.selectedCourse && !this.hasChangeAction) {
        this.actionComplete = false;
        this.showTooltip = true;
        this.tooltipTimeout = setTimeout(() => {
          this.showTooltip = false;
        }, 2000);
      }
    }

    if (changes.courseCode && changes.courseCode.currentValue && this.selectedCourses) {
      this.selectedCourse = this.selectedCourses[changes.courseCode.currentValue];
    }
  }
}
