/**
 * This module holds the classes used for the form model mapper service
 */
import { ValidatorFn, AbstractControl, UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import * as pointer from 'json-pointer';
import { isNumber, identity, get } from 'lodash-es';

import { ReferenceData } from '@shared/models/reference-data';

import { IUpdateFormOptions } from './form-model-mapper.service';

export const refDataToValue = (data: ReferenceData): string => ({ ...data }.code || null);
export const valueToRefData = ({ value }) => new ReferenceData({ code: value });

export const booleanToYesNo = (truthyOption = 'show') => {
  return (bool) => {
    if (bool == null) {
      return '';
    }
    const falsyOption = truthyOption === 'show' ? 'hide' : 'show';

    return bool ? truthyOption : falsyOption;
  };
};

export const yesNoToBoolean = (truthyOption = 'show') => {
  return ({ value }) => {
    if (!value) {
      return null;
    }
    return value === truthyOption;
  };
};

export const controlToRefDataArray = (c: AbstractControl) => {
  if (!c.value) {
    return [];
  }
  return c.value.filter((x) => !!x).map((val) => new ReferenceData({ code: val }));
};
export const refDatasToCodeArray = (v: ReferenceData[]) => {
  if (!v.length) {
    return [];
  }
  return v.map((val) => val.code);
};

export const booleanToSingleCheckboxValue = (checkboxValue) => {
  return (modelValue) => (modelValue ? [checkboxValue] : []);
};

export const singleCheckboxValueToBoolean = (checkboxValue) => {
  return ({ value }) => !!(value.indexOf(checkboxValue) >= 0);
};

export const numberToString = (v: number) => v && v.toString();

/**
 * Common interface for types which can return an abstractControl
 */
export interface Controllable {
  asControl(): AbstractControl;
}

export class UCFormElement implements Controllable {
  defaultState: any;
  path: string;
  model: string;
  validators: ValidatorFn[];
  control: UntypedFormControl;

  inMap?: (_) => any;

  constructor(
    options: {
      defaultState?: any;
      model?: string;
      path?: string;
      validators?: ValidatorFn[];
      inMap?: (_) => any;
      outMap?: (c: UntypedFormControl) => any;
    } = {},
  ) {
    if (!options.path) {
      throw new Error('FormElements must have paths');
    }
    this.inMap = options.inMap || identity;
    this.outMap = options.outMap || this.outMap;
    Object.assign(this, options);
  }

  get value(): any {
    return this.outMap(this.control);
  }

  outMap = (c: UntypedFormControl) => c.value;

  setValue(data: any, options?: any) {
    this.control.setValue(this.inMap(data), options);
  }

  public asControl(): AbstractControl {
    if (!this.control) {
      this.control = new UntypedFormControl(this.inMap(this.defaultState) || '', this.validators);
    }
    return this.control;
  }

  public copy(): UCFormElement {
    return new UCFormElement({
      defaultState: this.defaultState,
      path: this.path,
      model: this.model,
      validators: this.validators,
      inMap: this.inMap,
      outMap: this.outMap,
    });
  }

  public updateValidators(validators: ValidatorFn[]) {
    this.validators = validators;
    this.control.setValidators(validators);
    this.control.updateValueAndValidity({ emitEvent: false });
  }
}

export class UCElementGroup<
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  } = any,
> implements Controllable
{
  /**
   * the map of form elements under this control
   *
   * @param controls
   */
  public controls: TControl;
  public control: UntypedFormGroup;

  constructor(controls: TControl) {
    this.controls = controls;
    this.control = this.asControl() as UntypedFormGroup;
  }
  public asControl(): AbstractControl {
    if (this.control) {
      return this.control;
    }
    const formObject = {};
    Object.keys(this.controls).forEach((key) => {
      const el = this.controls[key];
      formObject[key] = el.asControl();
    });
    return new UntypedFormGroup(formObject);
  }

  /**
   * iterate through each control in the tree
   *
   * @param fn
   */
  eachControl(fn: (c: UCFormElement, index: number) => any) {
    Object.keys(this.controls).forEach((key, index) => {
      const c = this.controls[key];
      if (c instanceof UCElementGroup) {
        return c.eachControl(fn);
      } else if (c instanceof UCElementArray) {
        return c.eachControl(fn);
      } else if (c instanceof UCFormElement) {
        fn(c, index);
      }
    });
  }

  /**
   * iterate through each array
   *
   * @param fn
   */
  eachArray(fn) {
    Object.keys(this.controls).forEach((key) => {
      const c = this.controls[key];
      if (c instanceof UCElementArray) {
        fn(c);
      } else if (c instanceof UCElementGroup) {
        c.eachArray(fn);
      }
    });
  }

  copy(): UCElementGroup {
    const newGroup = {};
    Object.keys(this.controls).forEach((key) => {
      newGroup[key] = this.controls[key].copy() as UCFormElement;
    });
    return new UCElementGroup(newGroup);
  }

  updateSubGroupOn(hostPath: string, index: number): UCElementGroup {
    const hostParts = pointer.parse(hostPath);
    this.eachControl((c: UCFormElement) => {
      const controlParts = pointer.parse(c.path);
      controlParts[hostParts.length] = index;
      c.path = pointer.compile(controlParts);
    });
    return this;
  }

  makeSubGroupOn(hostPath: string, index: number): UCElementGroup {
    this.eachControl((c: UCFormElement) => {
      c.path = `${hostPath}/${index}${c.path}`;
    });
    return this;
  }

  addControl(name: string, c: Controllable) {
    this.controls[name] = c;
    this.control.addControl(name, c.asControl());
  }

  removeControl(name: string) {
    delete this.controls[name];
    this.control.removeControl(name);
  }

  get(name: string) {
    const ctrl = get(this.controls, name);
    if (!ctrl) {
      throw new Error('Form control not found');
    }
    return ctrl;
  }
}

export class UCElementArray implements Controllable {
  defaultState: any[];
  validators: ValidatorFn[];
  model: string;
  path: string;
  controls: any[];
  control: UntypedFormArray;
  group: UCElementGroup;

  constructor(
    options: {
      defaultState?: any[];
      validators?: ValidatorFn[];
      model?: string;
      path?: string;
      group?: UCElementGroup;
    } = {},
  ) {
    this.defaultState = options.defaultState || [''];
    this.validators = options.validators || [];
    this.model = options.model || '';
    this.path = options.path || '';
    this.group = options.group;

    if (this.group) {
      this.controls = [this.group.copy().makeSubGroupOn(this.path, 0)];
    } else {
      this.controls = this.defaultState.map((stateElement, index) => {
        return new UCFormElement({
          defaultState: stateElement,
          validators: this.validators,
          model: this.model,
          path: `${this.path}/${index}`,
        });
      });
    }
  }

  /**
   * Add a new element to a formArray
   */
  public push() {
    let newElement;
    if (this.group) {
      newElement = this.group.copy().makeSubGroupOn(this.path, this.controls.length);
    } else {
      const initialState = this.defaultState[0];
      newElement = new UCFormElement({
        defaultState: initialState,
        validators: this.validators,
        model: this.model,
        path: `${this.path}/${this.controls.length}`,
      });
    }
    this.controls.push(newElement);
    this.control.push(newElement.asControl());
  }

  public removeAt(index) {
    if (!isNumber(index)) {
      throw new Error('removeAt expects a number index');
    }

    this.controls.splice(index, 1);
    this.control.removeAt(index);

    this.controls.forEach((c, i) => {
      if (this.group) {
        c.updateSubGroupOn(this.path, i);
      } else {
        const newPath = `${this.path}/${i}`;
        c.path = newPath;
      }
    });
  }

  public eachControl(fn) {
    if (this.group) {
      return this.controls.forEach((c) => c.eachControl(fn));
    } else {
      return this.controls.forEach((c) => fn(c));
    }
  }

  /**
   * Use this for populating a formArray containing only formControls
   *
   * @param model[] any
   */
  public populateFormArray(model: any[], options?: IUpdateFormOptions) {
    this.control.controls.splice(0);
    this.controls.splice(0);
    const blankGroup = (index) => this.group.copy().makeSubGroupOn(this.path, index);
    const blankElement = (state, index) =>
      new UCFormElement({
        defaultState: state,
        validators: this.validators,
        model: this.model,
        path: `${this.path}/${index}`,
      });

    // create blanks if the model is empty
    if (model.length === 0) {
      if (options?.clearEmptyArrays) {
        return;
      }
      this.control.controls.splice(0);
      this.controls.splice(0);
      const newElement = this.group ? blankGroup(0) : blankElement('', 0);
      this.controls.push(newElement);
      this.control.push(newElement.asControl());
      return;
    }

    // iterate through the model and create
    model.forEach((m, index) => {
      const newElement = this.group ? blankGroup(index) : blankElement(m, index);
      this.controls.push(newElement);
      this.control.push(newElement.asControl());
    });
  }

  public asControl(): AbstractControl {
    if (!this.control) {
      const formControls = this.controls.map((c) => c.asControl());
      this.control = new UntypedFormArray(formControls);
    }
    return this.control;
  }
}

/**
 * Helpers for building the form model
 */
export const control = (a) => {
  return new UCFormElement(a);
};

export const group = (controls) => {
  return new UCElementGroup(controls);
};

export const array = (a) => {
  return new UCElementArray(a);
};
