<div *ngFor="let file of files">
  <div *ngIf="file.valid" class="file-list-item clearfix">
    <div class="file-item-detail">
      <uc-icon
        iconType="doc"
        iconWidth=14
        iconHeight=14
        iconFill="$colorAbbey">
      </uc-icon>
      <a *ngIf="file.studentViewable" class="name-text"
        target="_blank"
        [href]="file.externalUrl"
        [attr.title]="file.name"
        (click)="newDocumentDownloaded(file.id)">
        {{file.name}}
      </a>
      <div *ngIf="!file.studentViewable" class="name-text"
        [attr.title]="file.name">
        {{file.name}}
      </div>
      <div class="date-section">
        {{file.created | date:'mediumDate'}}
      </div>
      <uc-tag *ngIf="file.viewed === false" class="red">{{strings.new}}</uc-tag>
    </div>
  </div>
</div>
