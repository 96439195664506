<div class="planner-header">
  <div class="planner-header__block">
    <a class="exit-link exit-link--back" (click)="goBack()">
      <uc-icon class="exit-link__icon" iconType="back-arrow" iconWidth="21" iconHeight="21"></uc-icon>
      <span class="exit-link__text">{{ strings.backToOverview }}</span>
    </a>
  </div>
  <div class="planner-header__block planner-header__block--title">
    <div class="planner-header__summary-wrapper" [title]="plannerHeading">
      <div class="planner-header__qual-title">{{ applicationYear }} {{ plannerHeading }}</div>
      <uc-qualification-link
        *ngIf="qualification"
        class="planner-header__qual-link"
        [qualCode]="qualification.code"
      ></uc-qualification-link>
    </div>
  </div>
  <div class="planner-header__block" *ngIf="!!otherQualUrl">
    <a class="exit-link exit-link--forwards" [routerLink]="otherQualUrl">
      <span class="exit-link__text">{{ strings.nextQual }}</span>
      <uc-icon class="exit-link__icon" iconType="forward-arrow" iconWidth="21" iconHeight="21"></uc-icon>
    </a>
  </div>
  <!-- Account menu to be broken out into separate component and used here... -->
</div>
