import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import Cookie from 'js-cookie';
import { get } from 'lodash-es';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';

import { environment } from '@environment';
import { DataService } from '@shared/services/data-service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

export interface LocationAPIResponse {
  countryCode: string;
}

const LOCATION_COOKIE = '_uc_location';

@Injectable({
  providedIn: 'root',
})
export class IPLocationService {
  public isFirebaseRestricted$ = new BehaviorSubject<boolean>(false);
  private serviceUrl = `${environment.apiPrefix || ''}/firebase-api/ip-location`;
  private log: Logger;

  constructor(private dataService: DataService, loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get isFirebaseRestricted() {
    return this.isFirebaseRestricted$.asObservable();
  }

  getLocation(): Observable<boolean> {
    if (!environment.features.ipLocation) {
      return of(false);
    }
    return this.getCountryCode().pipe(
      switchMap((locationInfo) => this.isRestrictedCountry(locationInfo)),
      tap((isRestrictedCountry) => this.isFirebaseRestricted$.next(isRestrictedCountry)),
    );
  }

  private getCountryCode(): Observable<LocationAPIResponse> {
    const locationCookie: LocationAPIResponse = JSON.parse(Cookie.get(LOCATION_COOKIE) || null);
    const hasCountryCode = !!get(locationCookie, 'countryCode');
    if (hasCountryCode) {
      return of(locationCookie);
    } else {
      return this.callLocationAPI();
    }
  }

  public currentCountryCode(): string {
    const locationCookie: LocationAPIResponse = JSON.parse(Cookie.get(LOCATION_COOKIE) || null);
    return get(locationCookie, 'countryCode');
  }

  private callLocationAPI(): Observable<LocationAPIResponse> {
    return this.dataService.fetch(this.serviceUrl, { emitErrors: false }).pipe(
      catchError((error) => {
        Sentry.captureException(`Error returned from IPLocation API: ${JSON.stringify(error)}`);
        return of({});
      }),
      map((body) => {
        const countryCode: string = get(body, 'country.code');
        Cookie.set(LOCATION_COOKIE, JSON.stringify({ countryCode }), { expires: 2 });
        return { countryCode };
      }),
    );
  }

  private isRestrictedCountry(locationInfo: LocationAPIResponse): Observable<boolean> {
    return of(!!environment.firebaseRestrictedCountryCodes.find((code) => code === locationInfo.countryCode));
  }
}
