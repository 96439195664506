/* eslint-disable @typescript-eslint/naming-convention */
export const LEAD_ACTIONS = {
  RESUME: 'RESUME',
  WITHDRAW: 'WITHDRAW',
  UPLOAD_DOCS: 'UPLOAD_DOCS',
  ARCHIVE: 'ARCHIVE',
  DOCUMENTS_AVAILABLE: 'DOCUMENTS_AVAILABLE',
  HAS_APPLIED_CONDITIONS: 'HAS_APPLIED_CONDITIONS',
};

export const APPLICATION_ACTIONS = {
  RESUME: 'RESUME', // no href
  ACCEPT_DECLINE: 'ACCEPT_DECLINE', // no href
  PAY: 'PAY',
  CHANGE: 'CHANGE',
  AMEND: 'AMEND',
  OFFER_AVAILABLE: 'OFFER_AVAILABLE',
  OFFER_IN_PROGRESS: 'OFFER_IN_PROGRESS',

  // these are the only possible secondary actions currently.
  CHANGE_COURSE: 'CHANGE_COURSE', // no href -
  CHANGE_QUALIFICATION: 'CHANGE_QUALIFICATION', // has href -
  CHANGE_COURSE_NOT_ALLOWED: 'CHANGE_COURSE_NOT_ALLOWED',
  CHANGE_QUALIFICATION_NOT_ALLOWED: 'CHANGE_QUALIFICATION_NOT_ALLOWED',
  CHANGE_QUALIFICATION_SUBMITTED: 'CHANGE_QUALIFICATION_SUBMITTED',
  CHANGE_COURSE_SUBMITTED: 'CHANGE_COURSE_SUBMITTED',
  WITHDRAW: 'WITHDRAW',
  WITHDRAW_NOT_ALLOWED: 'WITHDRAW_NOT_ALLOWED',
  COA_CANNOT_START: 'COA_CANNOT_START',
  QUALIFICATION_NO_STUDENT_ID: 'QUALIFICATION_NO_STUDENT_ID',
  CHANGE_QUALIFICATION_NCEA_EMBARGO: 'CHANGE_QUALIFICATION_NCEA_EMBARGO',
  CHANGE_COURSE_NCEA_EMBARGO: 'CHANGE_COURSE_NCEA_EMBARGO',
  COE_CAN_START: 'COE_CAN_START',
  COE_CANNOT_START: 'COE_CANNOT_START',
  DEFER_OFFER: 'DEFER_OFFER',
};

export const ENROLMENT_ACTIONS = {
  WITHDRAW: 'WITHDRAW_ENROLMENT',
};

export const ACTION_LABELS = {
  SAVE_AND_SUBMIT: 'save_and_submit',
  SAVE_AND_EXIT: 'save_and_exit',
  SAVE_AND_EXIT_ALWAYS: 'save_and_exit_always',
};
