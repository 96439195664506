<div class="file-upload-item clearfix" *ngFor="let doc of currentFiles; let index = index">
  <div class="file-item-detail-limit file-item-detail" [class.is-uploading]="!doc.complete">
    <uc-icon iconType="doc" iconWidth="14" iconHeight="14" iconFill="$colorAbbey"> </uc-icon>
    <a
      *ngIf="doc.file.studentViewable"
      class="name-text-limit name-text"
      target="_blank"
      [href]="doc.file.externalUrl"
      [attr.title]="doc.file?.name"
    >
      {{ doc.file?.name }}
    </a>
    <div *ngIf="!doc.file.studentViewable" class="name-text-limit name-text" [attr.title]="doc.file?.name">
      {{ doc.file?.name }}
    </div>
    <div class="date-section">
      {{ showYear(doc.file) }}
      {{ category?.description }}
    </div>
  </div>

  <div class="file-item-actions">
    <span *ngIf="!doc.complete">
      <span class="progress-binding"> {{ strings.uploading }} {{ doc.progress | percent: '1.0-0' }} </span>
      <a class="cancel-link" (click)="abortUpload(doc)">Cancel</a>
    </span>

    <button
      [attr.data-cy]="'remove-doc'"
      class="remove-link"
      *ngIf="doc.complete && !disableRemove"
      (click)="removeFile(doc, index)"
    >
      <span *ngIf="!showSpinner[index]">
        <uc-icon iconType="close" iconWidth="8" iconHeight="8" iconFill="$colorFireEngineRed"></uc-icon>
        <span class="remove-link-text"> Remove </span>
      </span>
      <uc-spinner *ngIf="showSpinner[index]" [size]="19"></uc-spinner>
    </button>
  </div>
</div>
