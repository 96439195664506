import { Component, OnInit, Input } from '@angular/core';

import { ENROLMENT_ACTIONS } from '@shared/constants/actions.constants';
import { SummaryAction } from '@shared/models/enrolment-summary';

import { EnrolmentViewComponent } from '../enrolment-view/enrolment-view.component';

@Component({
  selector: 'uc-manage-enrolment-view',
  templateUrl: './manage-enrolment-view.component.html',
  styleUrls: ['./manage-enrolment-view.component.scss'],
})
export class ManageEnrolmentViewComponent extends EnrolmentViewComponent implements OnInit {
  @Input() secondaryActions: SummaryAction[] = [];
  @Input() enrolmentNumber = 0;

  get withdrawEnrolmentAction() {
    return this.secondaryActions.find((sa) => sa.action === ENROLMENT_ACTIONS.WITHDRAW);
  }

  get showTab() {
    return this.isConcurrentQual() || this.enrolmentNumber > 0;
  }

  get canWithdraw() {
    return this.enrolment.canWithdraw;
  }

  title(index: number) {
    return this.enrolment.qualifications[index].qualificationAnswer.title.replace(/\s\(PhD\)$/, '');
  }
}
