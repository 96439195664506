<div class="dec-wrap">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-spinner
    *ngIf="loading"
    size="40"
    [useStaticPositioning]="true"
  ></uc-spinner>
  <div *ngIf="!loading">
    <uc-message-banner
      type="info"
      *ngIf="declarationStrings.banner"
    >
      <span [innerHTML]="declarationStrings.banner"></span>
    </uc-message-banner>

    <div
      class="first-dec declaration text-content"
      [innerHTML]="declarationStrings.html"
    ></div>

    <uc-task-hint
      [task]="task"
      [path]="hintPath"
    ></uc-task-hint>
    <uc-checkbox-input-group
      groupName="dec-check"
      [options]="declarationOptions"
      label=""
      [formControl]="declarationCheck"
      [disableCheckbox]="disableCheckbox"
      innerInputRequired="true"
    >
    </uc-checkbox-input-group>
  </div>
</div>
