import { Injectable } from '@angular/core';
import * as pointer from 'json-pointer';

import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { TaskHint } from '@shared/models/task';
import { TaskDetailsResponse } from '@shared/models/task-details-response';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

import { UCFormElement, UCElementGroup, UCElementArray } from './form';

export interface UCFourTwoTwoError {
  detail: string;
  original: string;
  source: { pointer: string };
  status: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  original_msg: string;
}

interface IUpdateModelOptions {
  filterCleanInputs?: boolean;
}

export interface IUpdateFormOptions {
  // Stop form.ts from returning an array with one modal instance if the clearEmptyArrays flag is true
  clearEmptyArrays?: boolean;
}

@Injectable()
export class FormModelMapperService {
  log: Logger;

  constructor(private loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  // eslint-disable-next-line max-lines-per-function
  public updateFormFromModel(
    group: UCElementGroup,
    applicant?: Applicant,
    application?: Application | ChangeOfEnrolment,
    taskDetails?: TaskDetailsResponse,
    updateFormOptions?: IUpdateFormOptions,
  ) {
    // where path matches model iterate over values and create controls
    // arrayControl.updates

    group.eachArray((c) => {
      const modelObject = this.getModelInstance(c.model, applicant, application, taskDetails);
      let modelValue = [];
      try {
        modelValue = pointer.get(modelObject, c.path);
      } catch (e) {
        // Do nothing, modelValue will default to an empty array if the pointer does not exist on the object
      }
      c.populateFormArray(modelValue, updateFormOptions);
    });

    group.eachControl((c) => {
      const modelObject = this.getModelInstance(c.model, applicant, application, taskDetails);
      let modelValue;

      try {
        modelValue = pointer.get(modelObject, c.path);
      } catch (e) {
        modelValue = c.defaultState;
      }
      modelValue = modelValue == null ? c.defaultState : modelValue;
      c.setValue(modelValue);
    });
  }

  /**
   * Mutate a applicant/application model using the values currently stored in the controls of a UCElementGroup
   *
   * @param group
   * @param applicant
   * @param application
   */
  // eslint-disable-next-line max-lines-per-function
  public updateModelFromForm(
    group: UCElementGroup,
    applicant?: Applicant,
    application?: Application | ChangeOfEnrolment,
    taskDetails?: TaskDetailsResponse,
    options: IUpdateModelOptions = {},
  ) {
    group.eachArray((c: UCElementArray) => {
      if (options.filterCleanInputs && !c.control.dirty) {
        return;
      }
      const modelObject = this.getModelInstance(c.model, applicant, application, taskDetails);
      // Reset the array to be empty[] the eachControl callback will populate the array
      pointer.set(modelObject, c.path, []);
    });

    // eslint-disable-next-line complexity
    group.eachControl((c) => {
      const modelObject = this.getModelInstance(c.model, applicant, application, taskDetails);
      if (c instanceof UCFormElement) {
        if (options.filterCleanInputs && !c.control.dirty) {
          return;
        }
        const val = c.value != null ? c.value : null;
        pointer.set(modelObject, c.path, val);
      } else {
        throw new Error('fatal error, eachControl callback should only be called on UCElementArray or UCFormElement');
      }
    });
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  private getModelInstance(
    model: string,
    applicant?: Applicant,
    application?: Application | ChangeOfEnrolment,
    taskDetails?: TaskDetailsResponse,
  ) {
    let currentModel;
    switch (model) {
      case 'applicant':
        currentModel = applicant;
        break;
      case 'application':
      case 'changeOfEnrolments':
        currentModel = application;
        break;
      case 'taskDetails':
        currentModel = taskDetails;
        break;
      default:
        this.log.error(`Could not lookup the model name: ${model.toString()}`);
    }
    if (currentModel) {
      return currentModel;
    } else {
      // throw new Error(`model ${model.toString()} was not set when getModelInstance was called`);
    }
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  public updateFormValidity(errors: UCFourTwoTwoError[], group: UCElementGroup) {
    // eslint-disable-next-line max-lines-per-function, complexity
    group.eachControl((c) => {
      // TODO: maybe we should be matching partial errors, I.E if /applicant/englishQual
      // is returned error should we set an error on /applicant/englishQual/type field in the form?
      const hasError = errors.find((e) => {
        const parts = pointer.parse(e.source.pointer);
        const model = parts[0];
        const modelPointer = pointer.compile(parts.slice(1));
        // TODO, on the client side maybe we should be storing the model in the pointer?
        return model === c.model && modelPointer === c.path;
      });
      if (hasError && !!hasError.original_msg) {
        c.control.setErrors({
          fourTwoTwo: hasError.detail,
          originalMessage: hasError.original_msg,
        });
      } else if (hasError) {
        c.control.setErrors({
          fourTwoTwo: hasError.detail,
          originalMessage: hasError.original_msg,
        });
      }
    });
  }

  /**
   *
   * @param group Mutate the element group to update the state of all hints
   * @param hints
   * @param TaskHint
   */
  // eslint-disable-next-line class-methods-use-this
  public updateFormWithHints(group: UCElementGroup, hints: TaskHint[]) {
    group.eachControl((control) => {
      const hasError = hints.find((hint) => {
        const parts = pointer.parse(hint.source.pointer);
        const [model, ...rest] = parts;
        const modelPointer = pointer.compile(rest);
        return model === control.model && modelPointer === control.path;
      });
      if (hasError) {
        control.control.setErrors({ hasHint: hasError.detail });
      } else {
      }
    });
  }

  public getInvalidPathesInElementGroup(group: UCElementGroup): string[] {
    const invalidPaths = [];
    this.eachControlWithExpandedUCElementArray(group, (c) => {
      if (c.control.invalid) {
        invalidPaths.push(c.path);
      }
    });

    return invalidPaths;
  }

  private eachControlWithExpandedUCElementArray(
    group: UCElementGroup,
    fn: (c: UCFormElement | UCElementArray, index: number) => void,
  ) {
    // eslint-disable-next-line complexity
    Object.entries(group.controls).forEach(([, control], index) => {
      if (control instanceof UCElementGroup) {
        return this.eachControlWithExpandedUCElementArray(control, fn);
      } else if (control instanceof UCElementArray) {
        fn(control, index);
      } else if (control instanceof UCFormElement) {
        fn(control, index);
      }
    });
  }
}
