import { Component, Input, OnChanges } from '@angular/core';
import { flatten } from 'lodash-es';

import strings from '@constants/strings.constants';
import { CourseBucket, totalCoursePoints } from '@shared/models/course';
import { CourseSummary } from '@shared/models/course-summary';

@Component({
  selector: 'uc-study-plan-summary-bar',
  templateUrl: './study-plan-summary-bar.component.html',
  styleUrls: ['./study-plan-summary-bar.component.scss'],
})
export class StudyPlanSummaryBarComponent implements OnChanges {
  @Input() buckets: CourseBucket[];
  @Input() summary: CourseSummary;
  points: number;
  numberOfCourses: number;
  strings = strings.components.molecules.studyPlanSummaryBar;

  ngOnChanges() {
    const courses = flatten((this.buckets || []).map((bucket) => bucket.courses));
    this.numberOfCourses = courses.length;
    this.points = totalCoursePoints(courses);
    this.summary = this.summary || CourseSummary.default();
  }
}
