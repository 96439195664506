import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import strings from '@constants/strings.constants';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { APPLICATION_STATE } from '@shared/constants/states.constants';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-change-qualification',
  templateUrl: './change-qualification.component.html',
  styleUrls: ['./change-qualification.component.scss'],
})
export class ChangeQualificationComponent {
  @Input() applicationYear: string;
  @Input() coursesClosed = false;

  @ViewChild('confirmationModal') confirmationModal: ConfirmationModalComponent;
  @ViewChild('embargoModal') embargoModal: ConfirmationModalComponent;
  @ViewChild('coaInProgressModal') coaInProgressModal: ConfirmationModalComponent;

  strings = strings.components.molecules.changeQualification;
  coaNotAllowedStrings = strings.components.molecules.applicationYearListItemBaseStrings.coaChangeNotAllowed;
  nceaNotAllowedStrings =
    strings.components.molecules.applicationYearListItemBaseStrings.nceaNotAllowed('qualification');

  constructor(
    private processService: ProcessService,
    private router: Router,
    private enrolmentSummaryService: EnrolmentSummaryService,
  ) {
    this.startChangeOfApplication = this.startChangeOfApplication.bind(this);
  }

  async startChangeOfApplication(): Promise<void> {
    this.confirmationModal.loading = true;
    await firstValueFrom(this.processService.createChangeOfApplication(this.applicationYear));
    this.confirmationModal.closed.subscribe(() => {
      this.router.navigate(internalUrls.processPage(PROCESS_NAMES.COA, this.applicationYear));
    });
  }

  changeQualModal() {
    this.enrolmentSummaryService.getSummary().subscribe((summary) => {
      const hasCoaInProgress = summary.academicYears.some((s) => !!s.changeOfApplication);
      const isEmbargoed = summary.academicYears.some(
        (s) => s.academicYear === this.applicationYear && s.state === APPLICATION_STATE.NCEA_EMBARGO,
      );
      if (isEmbargoed) {
        this.embargoModal.open();
      } else if (hasCoaInProgress) {
        this.coaInProgressModal.open();
      } else {
        this.confirmationModal.loading = false;
        this.confirmationModal.open();
      }
    });
  }
}
