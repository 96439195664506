import { HttpErrorResponse } from '@angular/common/http';
import * as pointer from 'json-pointer';
import { snakeCase, camelCase, startCase } from 'lodash-es';

/**
 * { my_attr: '' } -> { myAttr: '' }
 * Note that this is only performed on the top-level keys
 *
 * @param obj
 */
export const camelizeKeys = (obj): any => {
  if (!obj) {
    return {};
  }
  return Object.keys(obj)
    .map((key) => ({ [camelCase(key)]: obj[key] }))
    .reduce((acc, next) => Object.assign(acc, next), {});
};

/**
 * { myAttr: '' } -> { my_attr: '' }
 * Note that this is only performed on the top-level keys
 *
 * @param obj
 */
export const snakeifyKeys = (obj): any => {
  if (!obj) {
    return {};
  }
  if (Object.keys(obj).length === 0) {
    return null;
  }
  return Object.keys(obj)
    .map((key) => ({ [snakeCase(key)]: obj[key] === '' ? null : obj[key] }))
    .reduce((acc, next) => Object.assign(acc, next), {});
};

const enforceSlashPrefix = (p: string) => {
  return !p.match(/^\//) ? `/${p}` : p;
};

const removeTrailingSlash = (p: string) => {
  return p.replace(/\/$/, '');
};

export const deepSnakeify = (obj): any => {
  const isEmptyArray = Array.isArray(obj) && obj.length === 0;
  const isEmptyObject = !!obj && typeof obj === 'object' && Object.keys(obj).length === 0;
  const isEmptyValue = obj == null || obj === '';

  if (isEmptyArray) {
    return [];
  }

  if (isEmptyValue || isEmptyObject) {
    return null;
  }

  if (Array.isArray(obj)) {
    // forces each empty element in an array to be an element of type undefined
    const arr = [...obj];
    let data = {};
    data = arr.map((el, i) => {
      if (arr[i] == null) {
        return {};
      }
      return deepSnakeify(el);
    });
    return data;
  } else if (typeof obj === 'object') {
    const data = {};
    Object.keys(obj).forEach((key) => (data[snakeCase(key)] = deepSnakeify(obj[key])));

    return data;
  }
  return obj;
};

/**
  Structure of 422 error:
  error: {
    errors: [
      source: {
        pointer: '/applicant/name/'
      }
    ]
  }
 */
export const fourTwentyTwoDeserializer = (err: HttpErrorResponse) => {
  return err.error.errors.map((e) => {
    if (e.source) {
      e.source.pointer = enforceSlashPrefix(e.source.pointer);
      e.source.pointer = pointer.compile(pointer.parse(e.source.pointer).map(camelCase));
      e.source.pointer = removeTrailingSlash(e.source.pointer);
    }
    return e;
  });
};

export const fourHundredDeserializer = (err: HttpErrorResponse) => {
  return err.error.errors.map((e) => e);
};

export const pascalCaseKeys = (obj): any => {
  if (!obj) {
    return {};
  }
  if (Object.keys(obj).length === 0) {
    return null;
  }
  return Object.keys(obj)
    .map((key) => ({ [startCase(camelCase(key)).replace(/ /g, '')]: obj[key] === '' ? null : obj[key] }))
    .reduce((acc, next) => Object.assign(acc, next), {});
};
