import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { get } from 'lodash-es';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Applicant } from '@shared/models/applicant';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { UCError } from '@shared/models/errors';
import { PoliceCheck } from '@shared/models/policeCheck';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
  booleanToYesNo,
  yesNoToBoolean,
  array,
  UCElementArray,
  booleanToSingleCheckboxValue,
  singleCheckboxValueToBoolean,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-police-check',
  templateUrl: './police-check.component.html',
  styleUrls: ['./police-check.component.scss'],
})
export class PoliceCheckComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() process: string;
  @Input() stage: string;

  policePage: UntypedFormGroup;
  policeForm: UCElementGroup;
  policeDeclarationPath: string;

  log: Logger;
  strings = strings.components.tasks.policeCheck;

  declarationStrings = strings.components.tasks.declaration;
  declarationOptions = [
    { labelText: this.declarationStrings.policeDeclaration.header, value: 'declarationPoliceAgree' },
  ];

  checkboxGroup;
  currentApplicant: Applicant;

  constructor(
    private applicationService: ApplicationService,
    private formModel: FormModelMapperService,
    private loggingService: LoggingService,
    private coeService: ChangeOfEnrolmentService,
    private applicantService: ApplicantService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  get checks(): UCElementArray {
    return this.policeForm.controls.overseasCheck.controls.checks as UCElementArray;
  }

  private createForm(): UCElementGroup {
    return group({
      policeRecord: group({
        chargesControl: control({
          model: this.dataModel,
          path: '/policeCheck/hasChargesPending',
          inMap: booleanToYesNo(),
          outMap: yesNoToBoolean(),
        }),
        convictionControl: control({
          model: this.dataModel,
          path: '/policeCheck/hasConvictions',
          inMap: booleanToYesNo(),
          outMap: yesNoToBoolean(),
        }),
        declarationPoliceCharges: control({
          model: this.dataModel,
          path: '/policeCheck/chargesPendingDescription',
          validators: [UCValidators.createWordCountValidator(500)],
        }),
        declarationPoliceConvictions: control({
          model: this.dataModel,
          path: '/policeCheck/convictionsDescription',
          validators: [UCValidators.createWordCountValidator(500)],
        }),
      }),
      nzDriversLicense: control({
        model: 'applicant',
        path: '/identity/nzDriversLicense',
        validators: [UCValidators.alphanumericString, UCValidators.nzDriversLicenseValidator],
      }),
      nzPassportNumber: control({
        model: 'applicant',
        path: '/identity/nzPassportNumber',
        validators: [UCValidators.alphanumericString, UCValidators.nzPassportValidator],
      }),
      cityOfBirth: control({
        model: 'applicant',
        path: '/demographic/cityOfBirth',
        validators: [Validators.required],
      }),
      countryOfBirth: control({
        model: 'applicant',
        path: '/demographic/countryOfBirth',
        validators: [Validators.required],
      }),
      overseasCheck: group({
        checks: array({
          model: this.dataModel,
          path: '/declarationBackgroundCheck',
          group: group({
            country: control({
              model: this.dataModel,
              path: '/country',
              defaultState: null,
              validators: [Validators.required],
            }),
            applied: control({
              model: this.dataModel,
              path: '/applied',
              inMap: refDataToValue,
              outMap: valueToRefData,
              validators: [Validators.required],
            }),
          }),
        }),
        hasBackgroundCheck: control({
          model: this.dataModel,
          path: '/policeCheck/hasBackgroundCheck',
          inMap: booleanToYesNo(),
          outMap: yesNoToBoolean(),
        }),
      }),
      declarationCheck: control({
        model: this.dataModel,
        path: '/policeCheck/declarationPoliceAgree',
        defaultState: false,
        inMap: booleanToSingleCheckboxValue('declarationPoliceAgree'),
        outMap: singleCheckboxValueToBoolean('declarationPoliceAgree'),
        validators: [UCValidators.validateAllChecked(this.declarationOptions)],
      }),
    });
  }

  updateCOEModel() {
    return new ChangeOfEnrolment({
      policeCheck: this.currentApplication.policeCheck,
      declarationBackgroundCheck: this.currentApplication.declarationBackgroundCheck,
    });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.policeForm);
  }

  ngOnInit() {
    this.setProcessCode(this.stage);
    this.getService(this.applicationService, this.coeService);
    this.policeForm = this.createForm();
    this.policePage = this.policeForm.asControl() as UntypedFormGroup;

    observableCombineLatest(this.applicantService.applicant, this.service.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]) => {
        this.currentApplication = application;
        this.currentApplicant = applicant;

        if (application && !application.policeCheck) {
          application.policeCheck = new PoliceCheck();
        }
        this.formModel.updateFormFromModel(this.policeForm, applicant, application);
      });
  }

  public update() {
    if (!this.currentApplication) {
      this.errors.emit();
      return this.log.error('tried to update the application, but no application has been loaded yet');
    }

    const resetTextArea = (c: AbstractControl, element: AbstractControl) => {
      const val = c.value;
      if (val === 'hide') {
        element.setValue('');
      }
    };

    resetTextArea(
      this.policePage.get('policeRecord.chargesControl'),
      this.policePage.get('policeRecord.declarationPoliceCharges'),
    );
    resetTextArea(
      this.policePage.get('policeRecord.convictionControl'),
      this.policePage.get('policeRecord.declarationPoliceConvictions'),
    );

    this.formModel.updateModelFromForm(this.policeForm, this.currentApplicant, this.currentApplication);
    if (get(this.currentApplication, 'policeCheck.hasBackgroundCheck') === false) {
      this.currentApplication.declarationBackgroundCheck = [];
    }

    const application = this.isCOE ? this.updateCOEModel() : this.currentApplication;
    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.service.update(application);

    observableCombineLatest(updateApplicant, updateApplication).subscribe(
      (app) => {
        if (app) {
          this.log.info('updated the application successfully');
          this.next.emit();
        }
      },
      (err) => {
        this.errors.emit();
        this.log.error('failed to update the application', err);
      },
    );
  }
}
