import { Component, Input, AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { flattenDeep } from 'lodash-es';
import { of as observableOf, BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { Task, TaskHint } from '@shared/models/task';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';
import { UserActivityService } from '@shared/services/user-activity/user-activity.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-task-hints',
  templateUrl: './task-hints.component.html',
  styleUrls: ['./task-hints.component.scss'],
})
export class TaskHintsComponent extends UnsubscribeOnDestroy implements AfterViewInit, OnInit {
  @Input() task: Task;

  strings = strings.components.molecules.taskHints;
  shouldDisplay: boolean;
  orderedHints;
  firstHint: TaskHint;
  updateHints$ = new BehaviorSubject<string>(null);

  constructor(
    private windowRef: WindowService,
    private activity: UserActivityService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    super();
  }

  get updateHints(): Observable<string> {
    return this.updateHints$.asObservable();
  }

  ngOnInit() {
    this.activity
      .hasTask(this.route.snapshot.params as IProcessRouteParams)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((hasVisited) => {
        this.shouldDisplay = hasVisited;
      });
  }
  ngAfterViewInit() {
    combineLatest(observableOf(true).pipe(debounceTime(1000)), this.updateHints.pipe(debounceTime(100))).subscribe(
      () => {
        this.orderHints();
      },
    );
  }

  orderHints() {
    const allHints = this.task.hints;

    const { document } = this.windowRef.nativeWindow;
    const domHints = document.querySelectorAll('uc-task-hint');

    const orderedDOMHints: string[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < domHints.length; i++) {
      if (domHints[i].firstElementChild && domHints[i].firstElementChild.classList.contains('validation-message')) {
        orderedDOMHints.push(domHints[i].getAttribute('hint-path'));
      }
    }

    this.orderedHints = flattenDeep(
      orderedDOMHints.map((domHint) => allHints.filter((hint) => hint.source.pointer === domHint)),
    );
    this.firstHint = this.orderedHints[0];
    this.changeDetectorRef.detectChanges();
  }

  jumpToHint(hint: TaskHint) {
    const window = this.windowRef.nativeWindow;
    const { document } = this.windowRef.nativeWindow;
    const hintEl = document.getElementById(hint.fragment);
    if (hintEl) {
      const rect = hintEl.getBoundingClientRect();
      window.scrollTo(0, rect.top - 200);
    }
  }
}
