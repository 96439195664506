import { camelizeKeys } from '@shared/helpers/serialization';
import { CourseOccurrence } from '@shared/models/course';

export class CourseMetadata {
  resultCount: number;

  static deserialize(meta) {
    return camelizeKeys(meta);
  }
}

export class CourseSearchResult {
  public meta: CourseMetadata;
  public course: CourseOccurrence[];

  constructor(data: any) {
    Object.assign(this, data);
  }

  static deserialize(payload: any): CourseSearchResult {
    if (payload === null) {
      return null;
    }

    if (payload.course) {
      payload.course = CourseOccurrence.deserializeAll(payload.course);
    }

    if (payload.meta) {
      payload.meta = CourseMetadata.deserialize(payload.meta);
    }

    return new CourseSearchResult(payload);
  }
}
