<uc-form-template>
  <uc-form-header title="Evidence of Funding">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>
  <uc-section>
    <uc-section-header [title]="strings.title">
    </uc-section-header>
    <uc-subsection>
      <uc-task-hint [task]="task" path="/documents/evidence_of_funds"></uc-task-hint>
      <uc-subsection-question required="true">
        {{strings.uploadGuidanceTitle}}
      </uc-subsection-question>
      <uc-subsection-minor-text [innerHTML]="strings.uploadGuidance">
      </uc-subsection-minor-text>
      <uc-file-upload
        [year]="applicationYear"
        [title]="strings.uploadLabel"
        categoryCode="EVIDENCE_OF_FUNDS"
        usedInTask="true"
        required="true">
    </uc-file-upload>
    </uc-subsection>
  </uc-section>
</uc-form-template>
