export const QUAL_CATEGORIES = {
  HAS_QUAL_OCCURRENCES: 'has_qualification_occurrences',
  REFEREE_THREE: 'referee_three',
  ADDITIONAL_INFORMATION_CAC: 'additional_information_cac',
  OFFER_DECISION_MYUC: 'offer_decision_myuc',
  CA_COMPLIANCE: 'ca_compliance',
  DOCTORAL: 'doctoral',
};

export const OURUC_QUAL_CATEGORIES = {
  CUP: 'cup',
  OURUC_MANUAL_APPROVAL: 'our_uc_manual_approve',
  OURUC_AUTOMATIC_APPROVAL: 'our_uc_auto_approve',
  POSTGRAD: 'postgrad',
  TEACHING: 'teaching',
  CAC: 'ca_compliance',
  POLICE_CHECK: 'police_check',
};
