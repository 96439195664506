import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedCACompliance {
  has_child_removed: boolean;
  child_removed: string;
  has_disciplinary_proceeding: boolean;
  disciplinary_proceeding: string;
  has_investigation_protection_order: boolean;
  investigation_protection_order: string;
  has_denied_entry: boolean;
  denied_entry: string;
  has_historical_ca_data?: boolean;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class CACompliance {
  hasChildRemoved: boolean = null;
  childRemoved: string = null;
  hasDisciplinaryProceeding: boolean = null;
  disciplinaryProceeding: string = null;
  hasInvestigationProtectionOrder: boolean = null;
  investigationProtectionOrder: string = null;
  hasDeniedEntry: boolean = null;
  deniedEntry: string = null;
  hasHistoricalCaData?: boolean = null;

  constructor(attrs = {}) {
    Object.assign(this, attrs);
  }

  static deserialize(payload: SerializedCACompliance): CACompliance {
    if (payload === null) {
      return null;
    }

    return new CACompliance(camelizeKeys(payload));
  }

  static serialize(instance: CACompliance): SerializedCACompliance {
    return snakeifyKeys(instance);
  }
}
