import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, NavigationEnd } from '@angular/router';
import { throwError, of, EMPTY, Observable, firstValueFrom } from 'rxjs';
import { filter, switchMap, catchError, tap } from 'rxjs/operators';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { IApplicationProcess, OnlineProcessService } from '@app/services/online-process/online-process.service';
import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { PROCESS_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import { PAYMENT_STATES } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { IndependentCourseEnrolment } from '@shared/models/enrolment';
import { UCProcess } from '@shared/models/process';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { UserService } from '@shared/services/user/user.service';

export interface IProcessRouteData {
  processContext: IProcessRouteContext;
}

/**
 * The object resolved by the ProcessResolver
 */
export interface IProcessRouteContext {
  process: UCProcess;
  application: Application | ChangeOfEnrolment;
}

/**
 * The parameters supplied to the Process resolver/page from the url config
 */
export interface IProcessRouteParams {
  process: string;
  year: string;
  stage: string;
  task: string;
}

@Injectable()
export class OnlineProcessResolver implements Resolve<IProcessRouteContext> {
  public queryParams: unknown;
  public fragment: unknown;
  public isImpersonated = false;
  private log: Logger;
  private lastLocation = undefined;
  private forceProcessUpdate = true;

  constructor(
    private router: Router,
    private onlineProcessService: OnlineProcessService,
    private flashMessageService: FlashMessageService,
    private loggingService: LoggingService,
    private referenceDataService: ReferenceDataService,
    private userService: UserService,
    private onlineCourseService: OnlineCourseService,
    private enrolmentService: EnrolmentService,
  ) {
    this.log = this.loggingService.createLogger(this);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.lastLocation = event.urlAfterRedirects;
    });

    this.userService.userDetail.subscribe((detail) => {
      this.isImpersonated = detail && !!detail.impersonated;
    });
  }

  get navigatedFromOutsideProcess(): boolean {
    return !this.lastLocation || !this.lastLocation?.match(/^\/apply/i);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProcessRouteContext> {
    this.log.info(state?.url);
    const { process: processCode, year, stage: stageCode, task: taskCode } = route.params as IProcessRouteParams;
    this.cacheQueryParamsAndFragment(route);
    this.onlineCourseService.buildOnlineCourseEnrolmentContext(this.queryParams);

    const preCheckResult = this.resolvePreCheck(processCode, year);
    if (preCheckResult === EMPTY) {
      return preCheckResult;
    }

    const forceUpdate = this.navigatedFromOutsideProcess && this.forceProcessUpdate;

    return this.referenceDataService.getByType('application_year').pipe(
      switchMap((referenceData) => {
        return this.getProcessAndApplication(referenceData, year, processCode, forceUpdate);
      }),
      switchMap(({ process, application }: IApplicationProcess) => {
        return this.validatePathAndRedirect(process, application, year, stageCode, taskCode);
      }),
      catchError((err) => {
        return this.errorHandler(err);
      }),
    );
  }

  private cacheQueryParamsAndFragment(route: ActivatedRouteSnapshot) {
    this.queryParams = route.queryParams;
    this.fragment = route.fragment;
  }

  private resolvePreCheck(processCode, year) {
    let preCheckResult;
    preCheckResult = this.processCodeAndYearCheck(processCode, year);
    preCheckResult = this.isYearNaNCheck(year);

    return preCheckResult;
  }

  private processCodeAndYearCheck(processCode, year) {
    if (!(processCode && year)) {
      this.router.navigate(internalUrls.onlineApply, { queryParams: this.queryParams });
      return EMPTY;
    } else {
      return undefined;
    }
  }

  private isYearNaNCheck(year) {
    if (isNaN(parseInt(year, 10))) {
      this.router.navigate(internalUrls.dashboard, { queryParams: this.queryParams });
      return EMPTY;
    } else {
      return undefined;
    }
  }

  private getProcessAndApplication(referenceData, year, processCode, forceUpdate) {
    const validYears = referenceData.map((refData) => refData.code);
    if (!validYears.find((validYear) => validYear === year || Number(validYear) - 1 === Number(year))) {
      this.flashMessageService.pushError(strings.errors.invalidApplicationYear);
      this.router.navigate(internalUrls.dashboard, { queryParams: this.queryParams });
      return EMPTY;
    }
    if (processCode === PROCESS_NAMES.COE) {
      return this.onlineProcessService.fetchCOEProcess(processCode, year, forceUpdate).pipe(
        tap(() => {
          this.onlineProcessService.fetchCurrentApplicationProcess(processCode, year, forceUpdate).subscribe();
        }),
      );
    } else {
      return this.onlineProcessService.fetchCurrentApplicationProcess(processCode, year, forceUpdate);
    }
  }

  validatePathAndRedirect(process, application, year, stageCode, taskCode) {
    const redirectPath = this.validatePath(process, year, stageCode, taskCode);
    this.log.info(redirectPath);
    if (redirectPath) {
      this.forceProcessUpdate = false;
      this.log.info('Redirecting to', redirectPath.join('/'));
      this.router.navigate(redirectPath, { queryParams: this.queryParams });
      return EMPTY;
    }
    this.forceProcessUpdate = true;
    this.onlineProcessService.process$.next(process);
    return of({ process, application });
  }

  private errorHandler(err) {
    if (err.status === 404) {
      this.router.navigate(internalUrls.onlineApply, { queryParams: this.queryParams });
      return EMPTY;
    } else if (err.status === 409) {
      this.router.navigate(internalUrls.dashboard, { queryParams: this.queryParams });
      return EMPTY;
    } else {
      return throwError(err);
    }
  }

  // eslint-disable-next-line complexity
  validatePath(process: UCProcess, year: string, stageCode: string, taskCode: string): string[] | null {
    if (!process) {
      return internalUrls.onlineApply;
    }

    const stage = process.getStage(stageCode);
    const stageCheckResult = this.stageCheck(process, stageCode, year, stage);
    if (!stageCheckResult.isFurtherCheckNeeded) {
      return stageCheckResult.url;
    }

    const taskCheckResult = this.taskCheck(process, stage, taskCode, year);
    if (!taskCheckResult.isFurtherCheckNeeded) {
      return taskCheckResult.url;
    }

    return null;
  }

  private checkOnDefaultStageIfHave(process: UCProcess, year: string) {
    let defaultStage = process.firstIncompleteStage || process.stages[0];
    if (this.onlineCourseService.onlineCourseIsFirstTimeToThisCourseType) {
      defaultStage = process.stages[0];
    }
    return internalUrls.onlineProcessPageTask(process.code, year, defaultStage.code, defaultStage.tasks[0].code);
  }

  private stageCheck(process, stageCode, year, stage) {
    let stageCheckResult = { isFurtherCheckNeeded: true, url: undefined };

    stageCheckResult = this.whenStageExist(stageCode, process, year);
    if (!stageCheckResult.isFurtherCheckNeeded) {
      return stageCheckResult;
    }

    if (!stage) {
      stageCheckResult.isFurtherCheckNeeded = false;
      stageCheckResult.url = internalUrls.fourOhFour;
      return stageCheckResult;
    }

    return stageCheckResult;
  }

  private whenStageExist(stageCode, process, year) {
    const stageCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    if (!stageCode) {
      stageCheckResult.isFurtherCheckNeeded = false;
      stageCheckResult.url = this.checkOnDefaultStageIfHave(process, year);
      return stageCheckResult;
    }
    if (stageCode === STAGE_NAMES.CHECKOUT) {
      this.checkoutStageValidate(
        process,
        year,
        this.onlineCourseService.onlineCourseCode,
        this.onlineCourseService.onlineCourseOccur,
      );

      stageCheckResult.isFurtherCheckNeeded = false;
      stageCheckResult.url = null;
      return stageCheckResult;
    }
    return stageCheckResult;
  }

  private taskCheck(process, stage, taskCode, year) {
    let taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };

    taskCheckResult = this.whenNotTaskCode(process, stage, taskCode, year);
    if (!taskCheckResult.isFurtherCheckNeeded) {
      return taskCheckResult;
    }

    const task = stage.getTask(taskCode);
    if (!task) {
      taskCheckResult.isFurtherCheckNeeded = false;
      taskCheckResult.url = internalUrls.fourOhFour;
      return taskCheckResult;
    }

    taskCheckResult = this.whenAutoResolveAndTaskCompleted(process, stage, task, taskCode, year);

    return taskCheckResult;
  }

  private whenNotTaskCode(process, stage, taskCode, year) {
    this.log.info(`Task code ${taskCode}`);
    const taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    if (!taskCode) {
      taskCheckResult.isFurtherCheckNeeded = false;
      taskCheckResult.url = internalUrls.onlineProcessPageTask(process.code, year, stage.code, stage.tasks[0].code);
      return taskCheckResult;
    }
    return taskCheckResult;
  }

  private whenAutoResolveAndTaskCompleted(process, stage, task, taskCode, year) {
    let taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    if (this.isAutoResolveAndTaskComplete(task)) {
      taskCheckResult = this.whenHasNextTask(process, stage, task, taskCode, year);
      if (!taskCheckResult.isFurtherCheckNeeded) {
        return taskCheckResult;
      } else {
        this.flashMessageService.pushInfo('This application is complete!');
        taskCheckResult.isFurtherCheckNeeded = false;
        taskCheckResult.url = internalUrls.dashboard;
        return taskCheckResult;
      }
    }
    return taskCheckResult;
  }

  private isAutoResolveAndTaskComplete(task) {
    this.log.info(task);
    return task.autoResolve && task.percentComplete === 100;
  }

  private whenHasNextTask(process, stage, task, taskCode, year) {
    this.log.info(stage);
    const taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    const autoResolveIndex = stage.tasks.map((x) => x.code).indexOf(taskCode);
    const nextTask = stage.tasks[autoResolveIndex + 1] || null;
    if (nextTask) {
      taskCheckResult.isFurtherCheckNeeded = false;
      taskCheckResult.url = internalUrls.onlineProcessPageTask(process.code, year, stage.code, nextTask.code);
      return taskCheckResult;
    }
    return taskCheckResult;
  }

  async checkoutStageValidate(process: UCProcess, academicYear: string, courseCode: string, occurrence: string) {
    const targetIndependentEnrolment = await firstValueFrom(
      this.enrolmentService.getIndependentEnrolment(academicYear, courseCode, occurrence),
    );

    if (this.fragment === 'cancel') {
      this.cancelStripePaymentIfShould(process, academicYear);
    } else {
      this.redirectToStripePaymentIfShould(targetIndependentEnrolment, process, academicYear);
      this.navigateToPaymentResultIfShould(targetIndependentEnrolment, process, academicYear);
    }

    return EMPTY;
  }

  private cancelStripePaymentIfShould(process: UCProcess, academicYear: string) {
    const checkoutStageIndex = process.stages.findIndex((stage) => stage.code === STAGE_NAMES.CHECKOUT);
    const stageBeforeCheckout = process.stages[checkoutStageIndex - 1];
    const tasksOfStageBeforeCheckout = stageBeforeCheckout.tasks;
    const lastTaskOfStageBeforeCheckout = tasksOfStageBeforeCheckout[tasksOfStageBeforeCheckout.length - 1];

    const redirectPath = internalUrls.onlineProcessPageTask(
      process.code,
      academicYear,
      stageBeforeCheckout.code,
      lastTaskOfStageBeforeCheckout.code,
    );
    this.router.navigate(redirectPath, { queryParams: this.queryParams });
  }

  private redirectToStripePaymentIfShould(
    targetIndependentEnrolment: IndependentCourseEnrolment,
    process: UCProcess,
    academicYear: string,
  ) {
    if (
      targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_NONE ||
      targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_WAITING
    ) {
      const redirectPath = internalUrls.onlineProcessPageTask(
        process.code,
        academicYear,
        STAGE_NAMES.CHECKOUT,
        'resolve-checkout-session',
      );

      this.router.navigate(redirectPath, { queryParams: this.queryParams });
    }
  }

  private navigateToPaymentResultIfShould(
    targetIndependentEnrolment: IndependentCourseEnrolment,
    process: UCProcess,
    academicYear: string,
  ) {
    if (
      [PAYMENT_STATES.PAYMENT_STATE_DECLINED, PAYMENT_STATES.PAYMENT_STATE_PAID].includes(
        targetIndependentEnrolment.paymentState.code,
      )
    ) {
      const redirectPath = internalUrls.onlineProcessPageTask(
        process.code,
        academicYear,
        STAGE_NAMES.CHECKOUT,
        'uconline-checkout',
      );

      this.appendFragmentAndNavigate(targetIndependentEnrolment, redirectPath);
    }
  }

  private appendFragmentAndNavigate(targetIndependentEnrolment: IndependentCourseEnrolment, redirectPath: string[]) {
    if (targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_DECLINED) {
      this.router.navigate(redirectPath, { queryParams: this.queryParams, fragment: 'cancel' });
    }
    if (targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_PAID) {
      this.router.navigate(redirectPath, { queryParams: this.queryParams, fragment: 'success' });
    }
  }
}
