import { camelizeKeys, snakeifyKeys } from '@shared/helpers/serialization';
import { ReferenceData, SerializedReferenceData } from '@shared/models/reference-data';

/* eslint-disable @typescript-eslint/naming-convention */
export interface SerializedName {
  title: SerializedReferenceData;
  type?: SerializedReferenceData;
  first_name: string;
  surname: string;
  middle_name: string;
  validated: boolean;
}
/* eslint-enable @typescript-eslint/naming-convention */

export class Name {
  static PREFERRED_CODE = 'PREF';

  public title: ReferenceData;
  public type?: ReferenceData;
  public firstName: string;
  public surname: string;
  public middleName: string;
  public validated: boolean;

  constructor(data: Partial<Name>) {
    Object.assign(this, data);
  }

  static deserialize(payload: Partial<SerializedName>): Name {
    if (payload === null) {
      return null;
    }

    const name: Name = camelizeKeys(payload);
    if (name.title) {
      name.title = ReferenceData.deserialize(name.title);
    }
    if (name.type) {
      name.type = ReferenceData.deserialize(name.type);
    }
    // It's possible that there are existing mononymous names in the API...
    // If they do exist, they will return a '~' as the firstName
    if (name.firstName === '~') {
      name.firstName = null;
    }
    return new Name(name);
  }

  static serialize(instance: Name): SerializedName {
    const clone = { ...instance };
    if (clone.title) {
      clone.title = ReferenceData.serialize(clone.title);
    }
    if (clone.type) {
      clone.type = ReferenceData.serialize(clone.type);
    }
    return snakeifyKeys(clone);
  }
}
