import { Component, Input } from '@angular/core';

@Component({
  selector: 'uc-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() identifier: string;
  @Input() closeHandler: Function = () => {};
  @Input() dismissHandler: Function = () => {};
  @Input() closable = true;
  @Input() dismissable = false;
  @Input() largeModal = false;

  constructor() {}
}
