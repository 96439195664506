import { Component, Input } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';

import { DeclarationBase } from '@shared/classes/declaration-base';
import { ApplicationService } from '@shared/services/application/application.service';
import { LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-student-declaration',
  templateUrl: './student-declaration.component.html',
  styleUrls: ['./student-declaration.component.scss'],
})
export class StudentDeclarationComponent extends DeclarationBase {
  @Input() applicationYear: string;

  constructor(
    loggingService: LoggingService,
    private applicationService: ApplicationService,
  ) {
    super(loggingService);
    this.setupData(this.applicationService.application, 'declarationAgree');
    this.hintPath = '/application/declaration_agree';
  }

  update() {
    if (!this.applicationYear) {
      this.log.error('Application year not set');
      return observableThrowError({ code: 'errors.unknown' });
    }

    this.updateDeclarationTask(this.applicationService, 'updateApplication');
  }
}
