import { Injectable } from '@angular/core';
import { flatten, flattenDeep, difference, get } from 'lodash-es';
import { of, zip as observableZip, Observable, combineLatest, BehaviorSubject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { CourseOccurrence } from '@shared/models/course';
import { Enrolment, FullEnrolment, ValidationMessage } from '@shared/models/enrolment';
import { EnrolledQualification } from '@shared/models/qualification';
import { QualificationResult } from '@shared/models/qualification-result';
import { ReferenceData } from '@shared/models/reference-data';
import { TeachingPeriod } from '@shared/models/teaching-period';
import { CourseService } from '@shared/services/course/course.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';
import { QualificationService } from '@shared/services/qualification/qualification.service';

export interface FullEnrolmentData {
  enrolments?: ApplicationEnrolment[];
  applicationEnrolments?: ApplicationEnrolment[];
  qualifications: QualificationResult;
  courseOccurrences: CourseOccurrence[];
  fullEnrolments: FullEnrolment[];
  teachingPeriods: TeachingPeriod[];
  validationMessages?: ValidationMessage[];
}

export const FullEnrolmentErrorCodes = {
  noEnrolments: 'enrolment.none',
  noCoe: 'enrolmentChange.none',
  taintedCoe: 'enrolmentChange.tainted',
  noCoeQuals: 'enrolmentChangeQuals.none',
};

export interface FullEnrolmentError {
  code: string;
  enrolmentChange?: ChangeOfEnrolment;
}

@Injectable()
export class FullEnrolmentService {
  private log: Logger;
  missingCourses$ = new BehaviorSubject('');
  hasEnrolmentChangeYear: string | null;

  constructor(
    private qualificationService: QualificationService,
    private courseService: CourseService,
    private processService: ProcessService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get missingCourses() {
    return this.missingCourses$.value;
  }

  public getEnrolledQualData(
    year: string,
    enrolledQuals: EnrolledQualification[],
  ): Observable<[QualificationResult, CourseOccurrence[], TeachingPeriod[]]> {
    if (!enrolledQuals || enrolledQuals.length === 0) {
      return throwError({ code: FullEnrolmentErrorCodes.noEnrolments });
    }
    return combineLatest([
      this.getQuals(year, enrolledQuals),
      this.getCourseOccurrences(year, enrolledQuals),
      this.getTeachingPeriods(year),
    ]);
  }

  getArchivedQualData(year, enrolledQualifications: ReferenceData[]) {
    const uris = flatten(enrolledQualifications.map((qual) => `qualification/${year}/${qual.code}`));
    return this.qualificationService.getQualificationsByURIs(year, uris);
  }

  /**
   * Looks up all data about each of the user's enrolled quals
   */
  getQuals(year: string, enrolledQuals: EnrolledQualification[]) {
    const qualURIs = flatten(enrolledQuals.map((eq) => `qualification/${year}/${eq.code}`));
    return this.qualificationService.getQualificationsByURIs(year, qualURIs);
  }

  /**
   * Looks up all data about each of the user's enrolled course occurrence codes
   */
  getCourseOccurrences(year: string, enrolledQuals: EnrolledQualification[]): Observable<CourseOccurrence[]> {
    this.missingCourses$.next('');
    const courses = flattenDeep(
      enrolledQuals.map((eq) => {
        const enrolmentIsWithdrawn = get(eq, 'state.code') === Enrolment.STATE.WITHDRAWN;
        return enrolmentIsWithdrawn ? [] : eq.enrolledCourses;
      }),
    );
    const courseCodes = courses.map((course) => course.code);

    if (courseCodes.length) {
      return this.courseService.getCoursesByURIs(year, courseCodes).pipe(
        tap(this.handleCouldntFindCourseData(courseCodes)),
        map((co: CourseOccurrence[]) => {
          return co;
        }),
      );
    } else {
      return of([]);
    }
  }

  /**
   * Looks up all teaching period data for a year
   */
  getTeachingPeriods(year) {
    return this.courseService.teachingPeriods(year);
  }

  private handleCouldntFindCourseData(courseCodes) {
    return (courseOccurrences) => {
      if (courseOccurrences.length !== courseCodes.length) {
        const missingCourses = difference(
          courseCodes,
          courseOccurrences.map((el) => el.courseOccurrenceCode),
        ).join(', ');
        this.missingCourses$.next(missingCourses);
        this.log.error(`Courses API did not return course information for ${missingCourses}`);
      }
    };
  }

  /**
   * If required, will validate each enrolment for a year. Returns ValidationMessage[]
   */
  public validateApplicationEnrolments(
    applicationEnrolments: ApplicationEnrolment[],
    year: string,
  ): Observable<ValidationMessage[]> {
    const courses = flattenDeep(
      applicationEnrolments.map((e) => e.enrolledQualifications.map((q) => q.enrolledCourses)),
    );

    let validateQualObservables = [];
    if (this.hasEnrolmentChangeYear !== year) {
      if (!courses.length) {
        return of([]);
      }

      applicationEnrolments.forEach((enrolment) => {
        const { priority } = enrolment;
        validateQualObservables.push(this.processService.validateApplicationEnrolment(year, priority));
      });
    } else {
      validateQualObservables = [this.processService.validateCoe()];
    }

    return observableZip(...validateQualObservables).pipe(
      map((validationMsgs) => validationMsgs.map((msg: any) => msg)),
      map((validationMsgs) => validationMsgs),
    );
  }
}
