import { Pipe, PipeTransform } from '@angular/core';
import { getUIMessage } from '@shared/models/errors';

@Pipe({
  name: 'errorMessage',
})
export class ErrorMessagePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    try {
      return getUIMessage(value);
    } catch (e) {
      console.error('error in pipe when trying to deserialize an error', e);
      return getUIMessage({ code: 'generic', data: null });
    }
  }
}
