<uc-form-template [formGroup]="starPage">
  <uc-form-header title="{{ task?.title }}">
    <uc-task-hints [task]="task"></uc-task-hints>
  </uc-form-header>

  <uc-section>
    <uc-section-header [title]="strings.secondarySchoolHeader"> </uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/secondary_education_last_school_attended"></uc-task-hint>
      <uc-subsection-question [required]="true">
        {{ strings.secondarySchoolQuestion }}
      </uc-subsection-question>
      <uc-reference-data-selector
        formControlName="lastSchoolAttended"
        type="secondary_school"
      ></uc-reference-data-selector>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/applicant/identity/nsn_number"></uc-task-hint>
      <uc-subsection-question>
        {{ strings.nsnLabel }}
      </uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.nsnDescription }}</uc-subsection-minor-text>
      <uc-string-input
        testSelector="nsn-number"
        formControlName="nsnNumber"
        [isNumeric]="true"
        label=""
        min="0"
      ></uc-string-input>
    </uc-subsection>

    <uc-subsection>
      <uc-subsection-question>
        {{ strings.coordinatorQuestion }}
      </uc-subsection-question>
      <uc-subsection-minor-text>{{ strings.coordinatorGuidance }}</uc-subsection-minor-text>
      <uc-form-input>
        <uc-associated-person
          basePath="/application/associated_person/0"
          [task]="task"
          [associatedPersonForm]="starPage.get('starCoordinator')"
          [strings]="strings.personLabels"
          testSelector="star-coordinator"
        ></uc-associated-person>
      </uc-form-input>
    </uc-subsection>

    <uc-yes-no
      yesOptionLabel="Yes"
      noOptionLabel="No"
      formControlName="studyLocation"
      required="true"
      questionString="{{ strings.distanceQuestion }}"
      [task]="task"
      path="/application/study_location"
      testSelector="star-location"
    >
      <uc-yes-content>
        <uc-subsection>
          <uc-subsection-question>
            {{ strings.supportQuestion }}
          </uc-subsection-question>
          <uc-subsection-minor-text>{{ strings.supportGuidance }}</uc-subsection-minor-text>
          <uc-form-input>
            <uc-associated-person
              basePath="/application/associated_person/1"
              [task]="task"
              [associatedPersonForm]="starPage.get('starSupport')"
              [strings]="strings.personLabels"
              testSelector="star-support"
            ></uc-associated-person>
          </uc-form-input>
        </uc-subsection>
      </uc-yes-content>
    </uc-yes-no>
  </uc-section>

  <uc-section>
    <uc-section-header [title]="strings.yourStudyHeader"> </uc-section-header>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/application/study_start"></uc-task-hint>
      <uc-subsection-question required="true">
        {{ strings.beginStudiesQuestion }}
      </uc-subsection-question>
      <uc-radio-input-group type="star_begin_studies" groupName="study-intentions" formControlName="studyStart">
      </uc-radio-input-group>
    </uc-subsection>

    <uc-subsection>
      <uc-task-hint [task]="task" path="/documents/star_consent"></uc-task-hint>
      <uc-subsection-question [required]="true">
        {{ strings.consentFormQuestion }}
      </uc-subsection-question>
      <p class="consent-guidance" [innerHTML]="strings.consentFormGuidance"></p>
      <uc-file-upload
        [year]="applicationYear"
        title="{{ strings.consentFormLabel }}"
        categoryCode="STAR_CONSENT"
        usedInTask="true"
      >
      </uc-file-upload>
    </uc-subsection>
  </uc-section>
</uc-form-template>
