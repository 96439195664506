/* eslint-disable @typescript-eslint/naming-convention */
import { OURUC_OS_TASK_DECISION } from './ourUC-constants';

export const TASK_TYPES = {
  ASSESS_QUAL_ADMISSION: 'assess_qualification_admission',
  ASSESS_DOCTORAL_ADMISSION: 'assess_doctoral_admission',
  ASSESS_DOCTORAL_QUALIFICATION_ADMISSION: 'assess_doctoral_qualification_admission',
  FINANCE_SANCTION: 'finance_sanction',
  REFUSE_ENROLMENT_SANCTION: 'refuse_enrolment_sanction',
  QUALIFICATION_REFUSE_ENROLMENT_SANCTION: 'qualification_refuse_enrolment_sanction',
  REEVALUATE_QUALIFICATION_ADMISSION_CONDITIONS: 'reevaluate_qualification_admission_conditions',
  REVIEW_REFEREE_DETAILS: 'review_referee_details',
  ASSESS_FOR_INTERVIEW: 'assess_for_interview',
  PREPARE_FOR_INTERVIEW: 'prepare_for_interview',
  INTERVIEW_APPLICANT: 'interview_applicant',
  RECORD_QUAL_DECISION: 'record_qualification_decision',
  CONVICTIONS_REVIEW: 'convictions_review',
  COURSE_APPROVALS_CHECK: 'course_approvals_check',
  COURSE_APPROVALS_RECHECK: 'course_approvals_recheck',
  QUALIFICATION_COURSE_CHECK: 'qualification_course_check',
  RESTRICTED_POINTS_SANCTION: 'restricted_points_sanction',
  PROGRAMME_OF_STUDY_CHECK_REQUIRED: 'programme_of_study_check_required',
  WORKLOAD_CHECK_FAILED: 'workload_check_failed',
  REVIEW_REQUIRED: 'review_required',
  PREPARE_OFFER_OF_PLACE: 'prepare_offer_of_place',
  PREPARE_OFFER_OF_PLACE_RETURNING: 'prepare_offer_of_place_returning',
  LATE_COURSE_ADD_CHECK: 'late_course_add_check',
  LATE_COURSE_DROP_CHECK: 'late_course_drop_check',
  ASSESS_QUALIFICATION_EQUIVALENCE: 'assess_qualification_equivalence',
  SELF_PACED_COURSE_CHECK: 'self_paced_course_check',
  THESIS_COURSE_CHECK: 'thesis_course_check',
  REVIEW_ALL_COURSES_DECLINED: 'review_all_courses_declined',
  WAITLISTED_COURSE: 'waitlisted_course',
};

export const TASK_ACTION_TYPES = {
  APPROVE: 'approveCourse',
  APPROVE_QUALIFICATION: 'approveCourseForQualification',
  APPROVE_REENTRY: 'approveCourseForReentry',
  APPROVE_MANUAL_COURSE_RECHECK: 'approveManualCourseRecheck',
  DECLINE_COURSE: 'declineCourse',
  PENDING_COURSE: 'pendingCourse',
  PROGRAMME_OF_STUDY_ACTION: 'approveProgrammeOfStudy',
  WORKLOAD_ACTION: 'approveWorkload',
  REMOVE_COURSE: 'removeCourse',
  REMOVE_COURSE_ADD: 'removeCourseAdd',
  REMOVE_COURSE_DROP: 'removeCourseDrop',
  REMOVE_WAIVER: 'removeWaiver',
  UNDO_COURSE_CHANGE: 'undoCourseChange',
  CONFIRM_DATES_CHECKED_THESIS: 'confirmDatesCheckedThesis',
  CONFIRM_DATES_CHECKED_SELF_PACED: 'confirmDatesCheckedSelfPaced',
};

export const TASK_PRIMARY_ACTIONS = {
  [TASK_TYPES.ASSESS_QUAL_ADMISSION]: OURUC_OS_TASK_DECISION.APPROVED,
  [TASK_TYPES.ASSESS_DOCTORAL_QUALIFICATION_ADMISSION]: OURUC_OS_TASK_DECISION.APPROVED,
  [TASK_TYPES.ASSESS_FOR_INTERVIEW]: OURUC_OS_TASK_DECISION.INVITE_TO_INTERVIEW,
  [TASK_TYPES.PREPARE_FOR_INTERVIEW]: OURUC_OS_TASK_DECISION.READY_FOR_INTERVIEW,
  [TASK_TYPES.INTERVIEW_APPLICANT]: OURUC_OS_TASK_DECISION.INTERVIEW_COMPLETE,
  [TASK_TYPES.RECORD_QUAL_DECISION]: OURUC_OS_TASK_DECISION.APPROVED,
  [TASK_TYPES.REEVALUATE_QUALIFICATION_ADMISSION_CONDITIONS]: OURUC_OS_TASK_DECISION.APPROVED,
  [TASK_TYPES.REVIEW_REFEREE_DETAILS]: OURUC_OS_TASK_DECISION.COMPLETE,
  [TASK_TYPES.CONVICTIONS_REVIEW]: OURUC_OS_TASK_DECISION.COMPLETE,
};
