<div [formGroup]="overseasCheck">
  <uc-yes-no
    [task]="task"
    [model]="hintPath"
    path="/police_check/has_background_check"
    questionString="{{ strings.livedAnywhereElse }}"
    required="yes"
    formControlName="hasBackgroundCheck"
    testSelector="background-check"
  >
    <uc-yes-content>
      <uc-subsection>
        <div formArrayName="checks">
          <div
            *ngFor="let check of checks.controls; let last = last; let first = first; index as i"
            [formGroupName]="i"
          >
            <uc-overseas-police-check-country
              countryLabel="{{ tabHeadings[i] }}"
              [checksFormGroup]="check"
              [numOfChecks]="checks.length"
              (delete)="removeCheck(i)"
              [index]="i"
              [task]="task"
              [hintPath]="hintPath"
            >
            </uc-overseas-police-check-country>

            <uc-add-new-button
              *ngIf="last && checks.length < 4"
              buttonHeading="{{ strings.addCountry }}"
              buttonText="{{ strings.addCountryText }}"
              (click)="addCheck()"
            >
            </uc-add-new-button>
          </div>
        </div>
      </uc-subsection>
    </uc-yes-content>
  </uc-yes-no>
</div>
