import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { Modal } from 'ngx-modialog-11/plugins/bootstrap';
import { filter, switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { APPLICATION_CHANGE_STATE, APPLICATION_STATE, OFFER_DECISION_STATE } from '@shared/constants/states.constants';
import { externalUrls } from '@shared/constants/urls.constants';
import {
  ChangeOfEnrolment,
  FullEnrolmentChange,
  ChangedEnrolledQualification,
  COE_SUBMITTED_STATES,
} from '@shared/models/change-of-enrolment';
import { AcademicYearEnrolmentSummary } from '@shared/models/enrolment-summary';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { FullEnrolmentService } from '@shared/services/full-enrolment/full-enrolment.service';
import { showModalLoadingState } from '@shared/services/modal/modal.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-process-info-panel',
  templateUrl: './process-info-panel.component.html',
  styleUrls: ['./process-info-panel.component.scss'],
})
export class ProcessInfoPanelComponent implements OnInit {
  @Input() applicationSummary: AcademicYearEnrolmentSummary = new AcademicYearEnrolmentSummary({});
  @Output() refreshAndNavigate = new EventEmitter<string>();
  @Output() cancelInProgressProcess = new EventEmitter();

  enrolmentChange: ChangeOfEnrolment;
  fullEnrolmentChange: FullEnrolmentChange;
  strings = strings.components.molecules.processInfoPanel;
  panelContent: typeof strings.components.molecules.processInfoPanel.statusText[string] = {};
  cancelInProgress = false;
  isLoading = false;
  stringsLoaded = false;
  hasApplicationChange = false;
  hasOfferDecision = false;
  isEmbargoed = false;

  constructor(
    private enrolmentSummary: EnrolmentSummaryService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    private fullEnrolmentService: FullEnrolmentService,
    private processService: ProcessService,
    private router: Router,
    private modal: Modal,
  ) {}

  get showProcessInfo() {
    const validState = this.enrolmentChangeSubmitted || this.enrolmentChangeInProgress || this.hasOfferDecision;
    return validState && !this.isEmbargoed && this.stringsLoaded;
  }

  get enrolmentChangeInProgress() {
    const changeYearCurrent = this.enrolmentChange?.academicYear?.code === this.applicationSummary?.academicYear;
    const changeStatusProgress = !COE_SUBMITTED_STATES.includes(this.enrolmentChange?.state?.code);
    return this.hasApplicationChange || (changeYearCurrent && changeStatusProgress);
  }

  get enrolmentChangeSubmitted() {
    const changeYearCurrent = this.enrolmentChange?.academicYear?.code === this.applicationSummary?.academicYear;
    const changeStatusSubmitted = COE_SUBMITTED_STATES.includes(this.enrolmentChange?.state?.code);
    return changeYearCurrent && changeStatusSubmitted;
  }

  get hasOfferDecisionInProgress() {
    const isDraftDefer = this.applicationSummary?.offerDecision?.state === OFFER_DECISION_STATE.DRAFT_DEFER;
    const isDraftAccept = this.applicationSummary?.offerDecision?.state === OFFER_DECISION_STATE.DRAFT_ACCEPT;
    return this.hasOfferDecision && (isDraftDefer || isDraftAccept);
  }

  get showDiff() {
    const isTainted = this.enrolmentChange && this.enrolmentChange.tainted;
    return !this.hasApplicationChange && !this.enrolmentChangeInProgress && !isTainted;
  }

  get offerProcessCode() {
    if (this.hasOfferDecision) {
      const offerProcess = this.applicationSummary.state === APPLICATION_STATE.OFFER_IN_PROGRESS;
      const processCode = offerProcess ? PROCESS_NAMES.OFFER_DECISION : PROCESS_NAMES.DEFER_OFFER;
      return processCode;
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.enrolmentSummary.enrolmentSummary.subscribe((es) => {
      const embargoInProgress = es.academicYears.find((ay) => ay.state === APPLICATION_STATE.NCEA_EMBARGO);
      this.isEmbargoed = embargoInProgress && embargoInProgress.academicYear === this.applicationSummary?.academicYear;
    });
    this.hasApplicationChange = !!get(this.applicationSummary, 'changeOfApplication');
    this.hasOfferDecision = !!get(this.applicationSummary, 'offerDecision');

    if (this.hasOfferDecision) {
      let offerState = this.applicationSummary?.state;
      if (offerState === APPLICATION_STATE.DEFER_OFFER_IN_PROGRESS) {
        // if a deferral is in progress, check the draft / submitted status of the deferral process
        offerState = this.applicationSummary.offerDecision.state;
      }
      this.panelContent = this.strings.statusText[offerState];
      this.stringsLoaded = true;
    } else if (this.hasApplicationChange) {
      this.panelContent = this.strings.statusText[APPLICATION_CHANGE_STATE.CHANGE_IN_PROGRESS];
      this.stringsLoaded = true;
    } else {
      this.fetchChangeEnrolmentData();
    }
  }

  fetchChangeEnrolmentData() {
    this.changeOfEnrolmentService.enrolmentChange
      .pipe(
        filter((coe) => !!coe),
        switchMap((coe) => {
          this.enrolmentChange = coe;
          return this.fullEnrolmentService.getEnrolledQualData(
            this.applicationSummary.academicYear,
            coe.enrolledQualifications,
          );
        }),
      )
      .subscribe(([quals, courses]) => {
        const qualifications = this.enrolmentChange.enrolledQualifications.map((eq) => {
          const qualificationData = quals.qualification.find((q) => q.code === eq.code);
          return new ChangedEnrolledQualification({ enrolledQualification: eq, qualificationData, courses });
        });
        this.fullEnrolmentChange = new FullEnrolmentChange({
          hasChanges: this.enrolmentChange.isDirty,
          qualifications,
          courses,
          year: this.applicationSummary.academicYear,
        });
        this.panelContent = this.strings.statusText[this.enrolmentChange.state.code];
        this.stringsLoaded = true;
      });
  }

  resumeHandler() {
    this.isLoading = true;
    const { academicYear } = this.applicationSummary;
    if (this.hasOfferDecision) {
      this.router.navigate(internalUrls.processPage(this.offerProcessCode, academicYear));
    } else if (this.hasApplicationChange) {
      this.router.navigate(internalUrls.processPage(PROCESS_NAMES.COA, academicYear));
    } else if (this.enrolmentChange.tainted) {
      this.refreshAndNavigate.emit(externalUrls.UCSW.COEHOME(academicYear));
    } else {
      this.router.navigate(internalUrls.processPage(PROCESS_NAMES.COE, academicYear));
    }
  }

  showCancelModal() {
    if (this.hasOfferDecision) {
      this.cancelProcess(this.offerProcessCode);
    } else {
      const modalStrings = this.hasApplicationChange
        ? this.strings.deleteModals.changeOfApplication
        : this.strings.deleteModals.changeOfEnrolment;

      const alert = this.modal
        .confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(false)
        .title(modalStrings.header)
        .body(modalStrings.body)
        .okBtn(modalStrings.ok)
        .cancelBtn('Cancel')
        .cancelBtnClass('cancel-btn');

      const dialog = alert.open();

      dialog.setCloseGuard({
        beforeClose: () => {
          showModalLoadingState();
          return false;
        },
      });

      dialog.result
        .then(() => {
          this.cancelInProgress = true;
          this.cancelProcess();
        })
        .catch(() => {
          // ensures error is caught here
        })
        .then(() => {
          document.body.classList.remove('modal-open');
        });
    }
  }

  cancelProcess(processName?: string) {
    let processNameToUse = processName;
    if (!processNameToUse) {
      if (this.hasApplicationChange) {
        processNameToUse = this.strings.coa;
      } else {
        processNameToUse = PROCESS_NAMES.COE;
      }
    }

    this.processService.cancelProcess(processNameToUse, this.applicationSummary.academicYear).subscribe(() => {
      this.cancelInProgressProcess.emit();
      this.cancelInProgress = false;
    });
  }
}
