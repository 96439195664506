import { Component, OnInit, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';

import strings from '@constants/strings.constants';
import { RestrictedDateValues } from '@shared/components/atoms/date-selector/date-selector.component';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-english-proficiency-test-type',
  templateUrl: './english-proficiency-test-type.component.html',
  styleUrls: ['./english-proficiency-test-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnglishProficiencyTestTypeComponent),
      multi: true,
    },
  ],
})
export class EnglishProficiencyTestTypeComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() applicationYear: string;
  @Input() condition: string;
  @Input() verified: boolean;
  @Input() task: Task;

  englishTest: UntypedFormGroup;
  shouldShowScoreList: boolean;
  baseYear: number;
  strings = strings.components.organisms.englishProficiencyTestType;
  validTestYearValues: RestrictedDateValues = {};
  englishTestType: { labelText: string; value: string }[];

  private propagateChange: (_: Record<string, unknown>) => void;

  constructor(
    private fb: UntypedFormBuilder,
    private refDataService: ReferenceDataService,
  ) {}

  getValidTestYears() {
    this.refDataService
      .getByType('english_language_proficiency_test_year')
      .subscribe(
        (testYears) => (this.validTestYearValues = { year: testYears.map((yearRefData) => yearRefData.code) }),
      );
  }

  ngOnInit() {
    this.getValidTestYears();
    this.englishTest = this.fb.group({
      testType: ['', Validators.required],
      date: ['', UCValidators.validateDate],
    });

    this.addOrRemoveOverallField();

    this.refDataService.getByType('english_language_test_type').subscribe((refs) => {
      this.englishTestType = refs?.map((ref) => {
        return {
          labelText: ref?.description,
          value: ref?.code,
        };
      });
    });

    this.englishTest.get('testType').valueChanges.subscribe((val) => {
      this.shouldShowScoreList = !!val.match(/^(IELTS|TOEFL|Pearsons)/) && this.condition === 'Have Sat Test';
      if (this.shouldShowScoreList) {
        this.englishTest.removeControl('overall');
        this.englishTest.removeControl('other');
        this.englishTest.setControl(
          'scores',
          this.fb.group({
            LST: '',
            RED: '',
            WRT: '',
            SPK: '',
            TOT: '',
          }),
        );
      } else if (val === 'Other') {
        this.englishTest.removeControl('scores');
        this.englishTest.setControl('other', this.fb.control(''));
        this.englishTest.setControl('overall', this.fb.control(''));
      } else {
        this.englishTest.removeControl('scores');
        this.englishTest.removeControl('other');
        this.englishTest.setControl('overall', this.fb.control(''));
      }
      this.disableFieldsWhenVerified();
      this.propagateChange?.(this.englishTest.value);
    });

    this.englishTest.valueChanges.subscribe((val) => this.propagateChange?.(val));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.condition) {
      this.addOrRemoveOverallField();
    }
    if (changes.verified) {
      this.disableFieldsWhenVerified();
    }
  }

  addOrRemoveOverallField() {
    if (this.condition === 'Have Sat Test') {
      this.englishTest.setControl('overall', this.fb.control(''));
    } else {
      this.englishTest.removeControl('overall');
    }
    this.disableFieldsWhenVerified();
  }

  disableFieldsWhenVerified() {
    let action: (field: UntypedFormControl) => void;
    if (this.verified) {
      action = (field) => field.enabled && field.disable();
    } else {
      action = (field) => field.disabled && field.enable();
    }

    Object.values(this.englishTest.controls).forEach(action);
  }

  writeValue(obj: Record<string, unknown>): void {
    if (obj) {
      this.englishTest.patchValue(obj);
    }
  }

  registerOnChange(fn: (_: Record<string, unknown>) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {
    // No-op
  }
}
