import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { get, set } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { Applicant } from '@shared/models/applicant';
import { AttainedQualification } from '@shared/models/attained-qualification';
import { UCError } from '@shared/models/errors';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import {
  group,
  control,
  UCElementGroup,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-university-admission',
  templateUrl: './university-admission.component.html',
})
export class UniversityAdmissionComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;

  currentApplicant: Applicant;
  universityAdmissionPage: UntypedFormGroup;
  universityAdmissionForm: UCElementGroup;

  strings = strings.components.tasks.universityAdmission;

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get ueQualification() {
    return this.universityAdmissionForm.controls.ueQualification.controls;
  }

  private createForm(): UCElementGroup {
    return group({
      ueQualification: group({
        type: control({
          model: 'applicant',
          path: '/ueQualification/type',
          validators: [Validators.required],
          inMap: refDataToValue,
          outMap: valueToRefData,
        }),
        dateAttained: control({ model: 'applicant', path: '/ueQualification/dateAttained' }),
      }),
    });
  }

  ngOnInit() {
    this.universityAdmissionForm = this.createForm();
    this.universityAdmissionPage = this.universityAdmissionForm.asControl() as UntypedFormGroup;

    this.applicantService.applicant.pipe(takeUntil(this.componentDestroyed)).subscribe((applicant: Applicant) => {
      this.currentApplicant = applicant;
      this.formModel.updateFormFromModel(this.universityAdmissionForm, applicant);
    });
  }

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.universityAdmissionForm);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      this.log.error('tried to update applicant but no applicant has been loaded yet');
    }

    if (!this.currentApplicant.ueQualification) {
      this.currentApplicant.ueQualification = new AttainedQualification();
    }

    if (get(this.currentApplicant, 'ueQualification.type.code') === 'NONE') {
      set(this.currentApplicant, 'ueQualification.dateAttained', null);
    }

    this.formModel.updateModelFromForm(this.universityAdmissionForm, this.currentApplicant);
    this.applicantService.updateApplicant(this.currentApplicant).subscribe({
      next: (applicant) => {
        if (applicant) {
          this.log.info('updated the applicant successfully');
          this.next.emit();
        }
      },
      error: (error) => {
        this.errors.emit();
        this.log.error('failed to update the applicant', error);
      },
    });
  }
}
