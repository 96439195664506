import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { get } from 'lodash-es';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';
import { ENROLMENT_ACTIONS } from '@shared/constants/actions.constants';
import { APPLICATION_STATE, CHANGE_ACTIONS } from '@shared/constants/states.constants';
import { CourseBucket } from '@shared/models/course';
import { FullEnrolment, QualificationWithCourses } from '@shared/models/enrolment';
import { Qualification } from '@shared/models/qualification';
import { Task } from '@shared/models/task';
import { TeachingPeriod } from '@shared/models/teaching-period';
import { CourseService } from '@shared/services/course/course.service';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';

@Component({
  selector: 'uc-enrolment-view',
  templateUrl: './enrolment-view.component.html',
  styleUrls: ['./enrolment-view.component.scss'],
})
export class EnrolmentViewComponent implements OnInit, OnChanges {
  @Input() enrolment: FullEnrolment;
  @Input() teachingPeriods: TeachingPeriod[];
  @Input() applicationYear: string;
  @Input() showAppSummaryTab: boolean;
  @Input() exemptionReasonControl: UntypedFormControl;
  @Input() minimalView = false;
  @Input() task: Task;
  @Input() hasEnrolmentChange = false;
  @Input() enrolmentNumber: number;
  @Input() enrolmentPriority: number;
  @Input() isArchived = false;
  @Input() archivedQuals: Qualification[] = [];

  @Output() enrolmentWithdrawn = new EventEmitter();

  @ViewChild('withdrawConfirmationModal') withdrawConfirmationModal: ConfirmationModalComponent;
  @ViewChild('withdrawErrorModal') withdrawErrorModal: ConfirmationModalComponent;
  @ViewChild('embargoedInfoModal') embargoedInfoModal: ConfirmationModalComponent;

  validationOrder = [
    'withdraw_warnings',
    'enrolment_warning',
    'enrolment_error',
    'workload_error',
    'international_efts_warning',
  ];

  strings = strings.components.organisms.enrolmentView;
  courseSelectorButtonLabel = this.strings.selectCourses;
  choiceStrings = strings.components.organisms.enrolmentSelector.choices;
  isYearEmbargoed = false;
  log: Logger;

  constructor(
    private router: Router,
    private flashMessageService: FlashMessageService,
    private processService: ProcessService,
    private summaryService: EnrolmentSummaryService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get showExemptionReasonsSection() {
    return (
      get(this.enrolment, 'validationMessage.msg.enrolment_error.length') ||
      get(this.enrolment, 'validationMessage.msg.workload_error.length')
    );
  }

  get showValidationMessagesSection() {
    return get(this.createEnrolmentValidationMessages(this.enrolment), 'length');
  }

  get withdrawEnrolmentAction() {
    return this.enrolment.secondaryAction.find((sa) => sa.action === ENROLMENT_ACTIONS.WITHDRAW);
  }

  get embargoedInfoModalStrings() {
    return strings.components.molecules.applicationYearListItemBaseStrings.nceaNotAllowed('courses');
  }

  ngOnInit() {
    if (this.enrolment) {
      this.enrolment.qualifications.forEach((qual) => {
        qual.courseBuckets = this.getCourseBuckets(qual);
      });
    }
    this.summaryService.embargoedyears.subscribe((ey) => {
      this.isYearEmbargoed = ey && !!ey.find((year) => year === this.applicationYear);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasEnrolmentChange) {
      this.courseSelectorButtonLabel = changes.hasEnrolmentChange.currentValue
        ? this.strings.addChangeCourses
        : this.strings.selectCourses;
    }
  }

  isConcurrentQual() {
    const isConcurrentArchived = this.isArchived && this.archivedQuals && this.archivedQuals.length > 1;
    const isConcurrent = get(this.enrolment, 'qualifications', []).length > 1;
    return isConcurrentArchived || isConcurrent;
  }

  tabLabel() {
    if (this.enrolmentNumber > 0 && this.isConcurrentQual()) {
      return `${this.strings.altChoice} ${this.enrolmentNumber} (${this.strings.concurrent})`;
    } else if (this.enrolmentNumber > 0) {
      return `${this.strings.altChoice} ${this.enrolmentNumber}`;
    } else if (this.enrolmentNumber === 0 && this.isConcurrentQual()) {
      return this.strings.concurrent;
    }
  }

  hasActiveAndInactiveCourses(qualification) {
    return qualification.inactiveCourses?.length > 0 && qualification.courseBuckets?.length > 0;
  }

  // Work out percentage of points added based on maximum points shown in bar:

  getNumberOfCoursesForQual(qualification) {
    const currentQualification = this.enrolment.qualifications.find(
      (q) => q.qualificationAnswer.code === qualification.qualificationAnswer.code,
    );
    if (currentQualification && currentQualification.courses) {
      return currentQualification.courses.filter((co) => {
        if (!co.changeAction) {
          return co;
        }
        return co.changeAction && co.changeAction !== CHANGE_ACTIONS.DROPPED;
      }).length;
    }
    return 0;
  }

  getCourseBuckets(qualification: QualificationWithCourses): CourseBucket[] {
    return CourseService.bucketize(qualification.courses, this.teachingPeriods);
  }

  goToCourseSelector(qualificationIndex) {
    if (this.enrolment.state?.code === APPLICATION_STATE.NCEA_EMBARGO) {
      this.openEmbargoedInfoModal();
    } else {
      const courseSelectorUrl = internalUrls.courseSelector(
        this.applicationYear,
        this.enrolmentPriority.toString(),
        qualificationIndex.toString(),
      );
      return this.router.navigate(courseSelectorUrl);
    }
  }

  createEnrolmentValidationMessages(enrolment: FullEnrolment) {
    if (enrolment.validationMessage) {
      const messages = Object.keys(enrolment.validationMessage.msg)
        .map((key) => {
          if (get(enrolment.validationMessage, `msg.${key}.length`)) {
            return {
              title: this.strings.validationMessages[key].title,
              guidanceText: this.strings.validationMessages[key].guidanceText,
              messages: enrolment.validationMessage.msg[key],
              code: key,
            };
          }
        })
        .filter((x) => !!x);
      return messages.sort((a, b) => this.validationOrder.indexOf(a.code) - this.validationOrder.indexOf(b.code));
    }
  }

  withdrawApplicationEnrolment() {
    const { priority, internalReference } = this.enrolment;
    this.processService.withdrawApplicationEnrolment(this.applicationYear, priority, internalReference).subscribe(
      () => {
        this.enrolmentWithdrawn.emit(this.enrolment.priority);
        this.flashMessageService.pushSuccess(this.strings.withdrawSuccessMessage, { countdown: 10 });
      },
      () => this.openWithdrawErrorModal(),
    );
  }

  openWithdrawErrorModal() {
    this.withdrawErrorModal.open();
  }

  openWithdrawModal() {
    this.withdrawConfirmationModal.open();
  }

  openEmbargoedInfoModal() {
    this.embargoedInfoModal.open();
  }

  hasCourses(qualification: QualificationWithCourses) {
    return qualification.changeAction === CHANGE_ACTIONS.ADDED && !qualification.courses?.length;
  }
}
