import { Component, OnInit, Input } from '@angular/core';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  log: Logger;
  @Input() hideText: boolean;
  @Input() excessLevel;
  @Input() progressPercentage = 0;

  constructor(private loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get isOverMaximum(): boolean {
    return this.excessLevel && this.progressPercentage > this.excessLevel;
  }

  get barWidth(): string {
    return this.progressPercentage ? this.progressPercentage + '%' : '0';
  }

  get barMaxWidth(): string {
    return this.excessLevel ? this.excessLevel + '%' : '100%';
  }

  get excessWidth(): string {
    return this.isOverMaximum && this.progressPercentage ? this.progressPercentage - this.excessLevel + '%' : '0';
  }

  ngOnInit() {
    this.log.info('ngOnInit');
  }
}

// Example usage within the course selector;

// summary = CourseSummary.default();
// get pointsPercentage () {
//   return this.enrolment.totalPoints / this.totalPointsShownInBar * 100;
// }
// // If maximum number of points is 90% of bar width, work out 100%:
// get totalPointsShownInBar () {
//   return this.summary.maximumPoints / 0.9;
// }

// <div class="points-indicator">
//   <div class="points-indicator__full-bar">
//     <uc-progress-bar
//       [hideText]="true"
//       [excessLevel]="90"
//       [progressPercentage]="pointsPercentage">
//     </uc-progress-bar>
// eslint-disable-next-line max-len
//     <div class="points-indicator__min-points-bar" [style.left]="summary.minimumPoints ? (summary.minimumPoints / totalPointsShownInBar * 100) + '%' : '0'">
//       <span class="points-indicator__points-bar-text">{{summary.minimumPoints}}</span>
//     </div>
// eslint-disable-next-line max-len
//     <div class="points-indicator__max-points-bar" [style.left]="summary.maximumPoints ? (summary.maximumPoints / totalPointsShownInBar * 100) + '%' : '0'">
//       <span class="points-indicator__points-bar-text">{{summary.maximumPoints}}</span>
//     </div>
//   </div>
// </div>
