import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';

import { environment } from '@environment';
import { UserTypes } from '@shared/models/user';

export interface FlashMessage {
  type: string;
  text: string;
  showClose?: boolean;
  countdown?: number;
  linkUrl?: string | string[];
  ref?: string;
  position?: FlashMessagePosition;
}

export interface FlashMessageOptions {
  showClose?: boolean;
  countdown?: number;
  linkUrl?: string | string[];
  ref?: string;
  position?: FlashMessagePosition;
}

export enum FlashMessagePosition {
  bottomLeft = 'bottomLeft',
  top = 'top',
}

@Injectable()
export class FlashMessageService {
  private messages$ = new BehaviorSubject<FlashMessage[]>([]);
  private _messages: { [key: string]: FlashMessage };

  private defaultOptions: FlashMessageOptions = {
    showClose: true,
    position: FlashMessagePosition.bottomLeft,
  };

  private defaultOptionsStaff: FlashMessageOptions = {
    showClose: true,
    position: FlashMessagePosition.top,
    countdown: 5,
  };

  constructor() {
    this._messages = {};
  }

  get baseOptions() {
    const isStaff = environment.scope === UserTypes.staff;
    return isStaff ? this.defaultOptionsStaff : this.defaultOptions;
  }

  get messages(): Observable<FlashMessage[]> {
    return this.messages$.asObservable();
  }

  private keyFor(message) {
    return JSON.stringify(message);
  }

  private getMessages() {
    return Object.keys(this._messages).map((key) => this._messages[key]);
  }

  pushMessage(message: FlashMessage): void {
    this._messages[this.keyFor(message)] = message;
    if (message.countdown) {
      this.setTimer(message);
    }
    this.messages$.next(this.getMessages());
  }

  setTimer(message: FlashMessage): void {
    const countdown = message.countdown * 1000;
    timer(countdown).subscribe(null, null, () => {
      this.removeMessage(message);
    });
  }

  setOptions(options: FlashMessageOptions) {
    if (!!options.linkUrl && !!options.linkUrl.length) {
      options.showClose = false;
    }
    return { ...this.baseOptions, ...options };
  }

  pushSubtle(text: string, options: FlashMessageOptions = {}): void {
    this.pushMessage({ text, type: 'subtle', ...this.setOptions(options) });
  }

  pushError(text: string, options: FlashMessageOptions = {}): void {
    this.pushMessage({ text, type: 'error', ...this.setOptions(options) });
  }

  pushInfo(text: string, options: FlashMessageOptions = {}): void {
    this.pushMessage({ text, type: 'info', ...this.setOptions(options) });
  }

  pushWarning(text: string, options: FlashMessageOptions = {}): void {
    this.pushMessage({ text, type: 'warning', ...this.setOptions(options) });
  }

  pushSuccess(text: string, options: FlashMessageOptions = {}): void {
    this.pushMessage({ text, type: 'success', ...this.setOptions(options) });
  }

  pushSystem(text: string, options: FlashMessageOptions = {}): void {
    this.pushMessage({ text, type: 'system', ...this.setOptions(options) });
  }

  removeMessage(message) {
    delete this._messages[this.keyFor(message)];
    this.messages$.next(this.getMessages());
  }

  messagesByRef(ref) {
    return this.getMessages().filter((el) => el.ref === ref);
  }

  clear() {
    this._messages = {};
    this.messages$.next(this.getMessages());
  }
}
