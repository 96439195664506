import { Component } from '@angular/core';

import onlineStrings from '@constants/online-strings.constants';

@Component({
  selector: 'uc-online-outage-template',
  templateUrl: './online-outage-template.component.html',
  styleUrls: ['./online-outage-template.component.scss'],
})
export class OnlineOutageTemplateComponent {
  templateStrings = onlineStrings.components.template.outage;
}
