import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';

import { UcStringWidgetComponent } from './string-widget/string.widget';
import { UcReferenceDataWidgetComponent } from './reference-data-widget/reference-data.widget';
import { UcRadioGroupWidgetComponent } from './radio-group-widget/radio-group.widget';

@NgModule({
    declarations: [UcStringWidgetComponent, UcReferenceDataWidgetComponent, UcRadioGroupWidgetComponent],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, AtomsModule, MoleculesModule],
    exports: []
})
export class WidgetsModule {}
