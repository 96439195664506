<div class="uc-tooltip-container">
  <div *ngFor="let tooltip of tooltipService.components">
  <uc-tooltip-content
  [id]="tooltip.id"
  [tooltipContent]="tooltip.title"
  [tooltipRef]="tooltip.ref"
  [tooltipTime]="tooltip.time"
  >
  </uc-tooltip-content>
  </div>
</div>
