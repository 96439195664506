import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { combineLatest, firstValueFrom } from 'rxjs';
import { filter, first, debounceTime, take } from 'rxjs/operators';

import { OnlineTabsComponent } from '@app/components/atoms/online-tabs/online-tabs.component';
import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import { internalUrls } from '@constants/internalUrls';
import { ETHNICITIES } from '@constants/reference.constants';
import strings from '@constants/strings.constants';
import {
  PREFERRED_CONTACT_METHOD_MAP,
  PREFERRED_CONTACT_METHOD_OPTIONS,
} from '@shared/constants/preferred-contact-method.constants';
import { PATH_TO_SECTION } from '@shared/constants/section-path.constants';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Name } from '@shared/models/name';
import { Phone } from '@shared/models/phone';
import { REFDATA_TYPES, ReferenceData } from '@shared/models/reference-data';
import { Category } from '@shared/models/uc-file';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { DocumentService } from '@shared/services/document/document.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import {
  control,
  group,
  array,
  UCElementArray,
  UCElementGroup,
  refDataToValue,
  valueToRefData,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-online-profile-template',
  templateUrl: './online-profile-template.component.html',
  styleUrls: ['./online-profile-template.component.scss'],
})
export class OnlineProfileTemplateComponent implements OnInit {
  @ViewChild('profileTabs') profileTabs: ElementRef<OnlineTabsComponent>;

  profileStrings = strings.components.organisms.onlineProfilePage.profileTemplate;
  documentStrings = strings.components.template.documentPage;
  helpText = strings.components.organisms.fileUpload.helpText;
  phoneStrings = strings.components.molecules.onlinePhoneSelector;
  onlineProfilePage: UntypedFormGroup;
  onlineProfileForm: UCElementGroup;
  currentApplicant: Applicant;
  log: Logger;
  hasStudentId = true;
  categories: Category[];
  isLoading = false;
  originalFormState: string;

  isEditProfile = false;
  isDocumentTab = false;

  genderRefdata: ReferenceData[] = [];
  iwiRefdata: ReferenceData[] = [];
  ethnicityRefdata: ReferenceData[] = [];
  citizenshipCategoryRefdata: ReferenceData[] = [];
  countryRefdata: ReferenceData[] = [];

  invalidSections: string[] = [];

  preferredContactMethodOptions = PREFERRED_CONTACT_METHOD_OPTIONS;

  preferredContactMethodMap = PREFERRED_CONTACT_METHOD_MAP;

  constructor(
    private applicantService: ApplicantService,
    private onlineProcessService: OnlineProcessService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private flashMessageService: FlashMessageService,
    private formMapper: FormModelMapperService,
    private modalService: ModalService,
    private userService: UserService,
    private refDataService: ReferenceDataService,
    private documentService: DocumentService,
    private formModel: FormModelMapperService,
    private ngxSmartModalService: NgxSmartModalService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
    this.validUserGuard();
  }

  get otherNames(): UCElementArray {
    return this.onlineProfileForm.controls.personalDetails.controls.names.controls.name;
  }

  get firstName() {
    return this.currentApplicant?.legalName.firstName || '-';
  }

  get middleName() {
    return this.currentApplicant?.legalName.middleName || '-';
  }

  get surname() {
    return this.currentApplicant?.legalName.surname || '-';
  }

  get preferredName() {
    const result = `${this.preferredFirstName} ${this.preferredMiddleName} ${this.preferredSurName}`;
    return result.trim() !== '' ? result : '-';
  }

  get preferredFirstName() {
    return (
      this.currentApplicant?.name.filter((name: Name) => name.type?.code === Name.PREFERRED_CODE).pop()?.firstName || ''
    );
  }

  get preferredMiddleName() {
    return (
      this.currentApplicant?.name.filter((name: Name) => name.type?.code === Name.PREFERRED_CODE).pop()?.middleName ||
      ''
    );
  }

  get preferredSurName() {
    return (
      this.currentApplicant?.name.filter((name: Name) => name.type?.code === Name.PREFERRED_CODE).pop()?.surname || ''
    );
  }

  get dateOfBirth() {
    return this.currentApplicant?.birthDate || '-';
  }

  get gender() {
    const genderStr = this.currentApplicant?.gender?.code || '-';
    const targetGender = this.genderRefdata.find((el) => el.code === genderStr);
    return this.getReferenceDataDescription(targetGender);
  }

  get email() {
    return this.currentApplicant?.contactDetail.emailAddress || '-';
  }

  get mobileNum() {
    return this.currentApplicant?.contactDetail.mobileNumber.fullNumber || '-';
  }

  get preferredContactMethod() {
    return this.preferredContactMethodMap[get(this.currentApplicant, 'preferredContactMethod')] || '-';
  }

  get ethnicity() {
    const ethnicityStr = this.currentApplicant?.demographic?.ethnicity.map((item) => {
      const targetEthnicity = this.ethnicityRefdata.find((el) => el.code === item.code?.toString());
      return this.getReferenceDataDescription(targetEthnicity);
    });
    return ethnicityStr?.length === 1 ? ethnicityStr?.toString() : ethnicityStr?.join(', ');
  }

  get iwi() {
    const iwiDescriptions = this.currentApplicant?.demographic.iwi.map((item) => {
      const targetIwi = this.iwiRefdata.find((el) => el.code === item.code.toString());
      return this.getReferenceDataDescription(targetIwi);
    });
    return iwiDescriptions?.length === 1 ? iwiDescriptions?.toString() : iwiDescriptions?.join(', ');
  }

  get countryOfCitizenship() {
    const countryStr = this.currentApplicant?.demographic.passportCountry?.code || '-';
    const targetCountry = this.countryRefdata.find((el) => el.code === countryStr);
    return this.getReferenceDataDescription(targetCountry);
  }

  get citizenshipCategory() {
    const citizenshipCategoryStr = this.currentApplicant?.demographic.citizenship?.code || '-';
    const targetCitizenshipCategory = this.citizenshipCategoryRefdata.find((el) => el.code === citizenshipCategoryStr);
    return this.getReferenceDataDescription(targetCitizenshipCategory);
  }

  get declarationDisability() {
    return this.currentApplicant?.declarationDisability ? 'Yes' : 'No';
  }

  get currentAddress() {
    const result = `${this.currentAddressLine1} ${this.currentAddressLine2}
    ${this.currentAddressCityTownPostcode} ${this.currentAddressRegion} ${this.currentAddressCountry}`;
    return result.trim() !== '' ? result : '-';
  }

  get currentAddressLine1() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.line1 || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get currentAddressLine2() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.line2 || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get currentAddressCityTownPostcode() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = [address?.city, address?.town, address?.postcode].filter((a) => a).join(' ');
    return result.trim() !== '' ? result : '-';
  }

  get currentAddressRegion() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.region || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  get currentAddressCountry() {
    const address = this.currentApplicant?.contactDetail.currentAddress;
    const result = `${address?.country?.description || ''}`;
    return result.trim() !== '' ? result : '-';
  }

  ngOnInit() {
    this.getReferenceData();
    this.onlineProfileForm = this.createForm();
    this.onlineProfilePage = this.onlineProfileForm.asControl() as UntypedFormGroup;

    this.modalService.saveHandler = this.updateApplicant.bind(this);
    this.modalService.customStrings = this.modalService.defaultStrings;

    this.documentService.getCategories().subscribe((cats) => {
      this.categories = cats;
    });

    this.onFormValueOrStateChange();

    this.updateFormFromApplicant();

    this.onEthnicitiesChange();

    this.onContactDetailsChange();
  }

  private onContactDetailsChange() {
    this.onlineProfilePage.get('contactDetails').valueChanges.subscribe((val) => {
      this.setContactValidation(val, 'contactDetails');
    });
  }

  private onEthnicitiesChange() {
    this.onlineProfilePage.get('personalDetails.citizenship.ethnicities').valueChanges.subscribe((val) => {
      if (val.find((x) => x === ETHNICITIES.NEW_ZEALAND_MAORI)) {
        this.onlineProfilePage.get('personalDetails.citizenship.ethnicities').setValidators([Validators.required]);
      } else {
        this.onlineProfilePage.get('personalDetails.citizenship.ethnicities').setValidators([]);
      }
    });
  }

  private onFormValueOrStateChange() {
    // Waits for the form value to stabilise when inserting model data into it
    this.onlineProfilePage.valueChanges.pipe(debounceTime(1000), first()).subscribe((formValue) => {
      this.originalFormState = JSON.stringify(formValue);
      this.setContactValidation(formValue.contactDetails, 'contactDetails');
    });

    this.onlineProfilePage.valueChanges.subscribe((formValue) => {
      this.modalService.shouldNavigate = !this.isFormChanged(formValue);
    });

    this.onlineProfilePage.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.modalService.showThirdButton = true;
        this.modalService.customStrings = { question: this.profileStrings.unsavedModal.validFormQuestion };
      } else {
        this.modalService.showThirdButton = false;
        this.modalService.customStrings = { question: this.profileStrings.unsavedModal.invalidFormQuestion };
      }
    });
  }

  private updateFormFromApplicant() {
    this.applicantService.applicant
      .pipe(
        filter((a) => !!a),
        take(1),
      )
      .subscribe((a) => {
        this.currentApplicant = a;
        this.formMapper.updateFormFromModel(this.onlineProfileForm, a, null);
        if (a && !a.name.length) {
          this.otherNames.removeAt(0);
        }

        setTimeout(() => {
          this.disableVerifiedControls(a);
        }, 0);
      });
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  private createForm() {
    return group({
      personalDetails: group({
        birthDate: control({
          defaultState: '',
          validators: [UCValidators.validateDate],
          model: 'applicant',
          path: '/birthDate',
        }),
        names: group({
          legalName: control({
            defaultState: new Name({}),
            validators: [Validators.required],
            model: 'applicant',
            path: '/legalName',
          }),
          name: array({
            defaultState: [],
            validators: [],
            model: 'applicant',
            path: '/name',
          }),
        }),
        genderGroup: group({
          gender: control({
            model: 'applicant',
            path: '/gender',
            inMap: refDataToValue,
            outMap: valueToRefData,
            validators: [Validators.required],
          }),
        }),
        citizenship: group({
          ethnicities: array({
            defaultState: [],
            validators: [Validators.required],
            model: 'applicant',
            path: '/demographic/ethnicity',
          }),
          iwi: array({
            defaultState: [],
            validators: [],
            model: 'applicant',
            path: '/demographic/iwi',
          }),
        }),
      }),
      contactDetails: group({
        email: control({
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/contactDetail/emailAddress',
        }),
        mobileNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [
            UCValidators.phonePresenceValidator,
            UCValidators.completePhoneValidator,
            UCValidators.nzMobileValidator,
          ],
          model: 'applicant',
          path: '/contactDetail/mobileNumber',
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressWithoutWhitespaceValidator],
          model: 'applicant',
          path: '/contactDetail/currentAddress',
        }),
        preferredContactMethod: control({
          validators: [Validators.required],
          model: 'applicant',
          path: '/preferredContactMethod',
        }),
      }),
    });
  }

  private isFormChanged(formValue) {
    return this.originalFormState && this.originalFormState !== JSON.stringify(formValue);
  }

  private getReferenceData() {
    combineLatest([
      this.refDataService.getByType(REFDATA_TYPES.GENDER),
      this.refDataService.getByType(REFDATA_TYPES.IWI),
      this.refDataService.getByType(REFDATA_TYPES.ETHNICITY),
      this.refDataService.getByType(REFDATA_TYPES.CITIZENSHIP_CATEGORY),
      this.refDataService.getByType(REFDATA_TYPES.COUNTRY),
    ]).subscribe(([genderRefdata, iwiRefdata, ethnicityRefdata, citizenshipCategoryRefdata, countryRefdata]) => {
      this.genderRefdata = genderRefdata;
      this.iwiRefdata = iwiRefdata;
      this.ethnicityRefdata = ethnicityRefdata;
      this.citizenshipCategoryRefdata = citizenshipCategoryRefdata;
      this.countryRefdata = countryRefdata;
    });
  }

  private getReferenceDataDescription(target: ReferenceData) {
    this.log.info(target);
    return target?.description !== undefined && target?.description.trim() !== '' ? target?.description.trim() : '-';
  }

  partiallyComplete(numberGroup) {
    this.log.info(numberGroup);
    return this.isInputEitherNumberOrCountry && !this.isInputBothNumberOrCountry;
  }

  isInputEitherNumberOrCountry(mobileNumber, country) {
    this.log.info(mobileNumber, country);
    return mobileNumber || country;
  }

  isInputBothNumberOrCountry(mobileNumber, country) {
    this.log.info(mobileNumber, country);
    return mobileNumber && country;
  }

  setContactValidation(formValues, formGroupKey: string) {
    const mobileIncomplete = this.partiallyComplete(formValues.mobileNum);
    const mobileNotPresent = !this.isInputBothNumberOrCountry(
      formValues.mobileNum.number,
      formValues.mobileNum.country,
    );

    if (mobileIncomplete || mobileNotPresent) {
      this.onlineProfilePage.get(`${formGroupKey}.mobileNum`).setErrors({ invalid: true });
    }
  }

  validUserGuard() {
    if (!get(this.userService.user, 'studentId')) {
      this.router.navigate(internalUrls.fourOhFour);
    }
  }

  private disableVerifiedControls(applicant?: Applicant) {
    if (get(applicant, 'validatedBirthDate')) {
      this.onlineProfilePage.get('personalDetails.birthDate').disable();
    }

    if (get(applicant, 'legalName.validated')) {
      this.onlineProfilePage.get('personalDetails.names.legalName').disable();
    }

    // TODO: Check on Validated filed on Email when it is added to applicant
    this.onlineProfilePage.get('contactDetails.email').disable();
  }

  validateAndUpdate() {
    // Call update applicant only when pass validation
    if (!this.showValidationWarnings()) {
      this.updateApplicant();
    }
  }

  async updateApplicant(): Promise<void> {
    if (!this.currentApplicant) {
      this.log.error('tried to update applicant, but no applicant has been loaded yet');
      Promise.reject('tried to update applicant, but no applicant has been loaded yet');
    }

    this.formMapper.updateModelFromForm(this.onlineProfileForm, this.currentApplicant);

    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);

    // Hack to prevent nulls in what should be an array
    this.currentApplicant.demographic.ethnicity = this.currentApplicant.demographic.ethnicity.filter((n) => !!n);
    this.currentApplicant.demographic.iwi = this.currentApplicant.demographic.iwi.filter((n) => !!n);

    const isMaori = this.currentApplicant.demographic.ethnicity.find((e) => e.code === ETHNICITIES.NEW_ZEALAND_MAORI);
    if (!isMaori) {
      this.currentApplicant.demographic.iwi = [];
    }

    this.isLoading = true;
    await this.updateApplicantAndSyncProfile();
  }

  private async updateApplicantAndSyncProfile(): Promise<void> {
    try {
      await firstValueFrom(this.applicantService.updateApplicant(this.currentApplicant));
      await firstValueFrom(this.onlineProcessService.syncProfile());
      this.modalService.shouldNavigate = true;
      this.log.info('Updated applicant successfully');
      this.flashMessageService.pushSuccess(strings.components.template.dashboard.applicantUpdate, { countdown: 10 });
      this.router.navigate(internalUrls.dashboard);
    } catch (error) {
      this.log.error('Error updating applicant', error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  switchToEditProfile(): void {
    this.isEditProfile = true;
  }

  isDocumentsTabActive() {
    return (this.profileTabs as unknown as OnlineTabsComponent)?.currentTab?.title === 'Documents';
  }

  showValidationWarnings() {
    const invalidPathArr = this.checkFormValidity();
    this.invalidSections = this.pathToSectionSet(invalidPathArr);
    if (invalidPathArr.length > 0) {
      this.ngxSmartModalService.open('validationWarningModal');
      return true;
    } else {
      return false;
    }
  }

  checkFormValidity(): string[] {
    this.onlineProfilePage.updateValueAndValidity();
    if (this.onlineProfilePage.invalid) {
      this.log.info(this.onlineProfilePage);
      return this.formModel.getInvalidPathesInElementGroup(this.onlineProfileForm);
    } else {
      return [];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  pathToSectionSet(pathArr: string[]): string[] {
    const sectionArr = new Set<string>();
    pathArr.map((path) => {
      sectionArr.add(PATH_TO_SECTION.uconlineProfile[path] || '');
    });

    return Array.from(sectionArr);
  }

  closeValidationWarningModal() {
    this.ngxSmartModalService.close('validationWarningModal');
  }
}
