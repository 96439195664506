import { SubjectOption } from './subject-option';
import { camelizeKeys } from '@shared/helpers/serialization';

export interface iDataSelectorOption {
  labelText: string;
  value: string;
}

export class SubjectQuestion {
  required: string;
  order: number;
  options: SubjectOption[];

  static deserialize(payload: any): SubjectQuestion {
    if (payload === null) {
      return null;
    }
    const subjQuestion = camelizeKeys(payload);
    subjQuestion.options = subjQuestion.options.map(SubjectOption.deserialize);
    return new SubjectQuestion(subjQuestion);
  }
  constructor(data: any) {
    Object.assign(this, data);
  }

  /**
   * Build a list of options for a data selector
   */
  asDataSelectorOptions(): iDataSelectorOption[] {
    return this.options.map((r) => ({ labelText: r.title, value: r.code }));
  }
}
