import { Component, EventEmitter, Input, Output, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'lodash-es';
import { Subscription } from 'rxjs';

import strings from '@constants/strings.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { CourseBucket, CourseOccurrence } from '@shared/models/course';
import { CourseSummary } from '@shared/models/course-summary';
import { TeachingPeriod } from '@shared/models/teaching-period';
import { CourseService } from '@shared/services/course/course.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-course-planner-review-panel',
  templateUrl: './course-planner-review-panel.component.html',
  styleUrls: ['./course-planner-review-panel.component.scss'],
})
export class CoursePlannerReviewPanelComponent implements OnChanges, OnDestroy {
  @Input() routeParams;
  @Input() otherQualCode;
  @Input() processName: string;
  @Input() teachingPeriods: TeachingPeriod[];
  @Input() errorTriggered = false;

  @Output() addCourse = new EventEmitter();
  @Output() removeCourse = new EventEmitter();
  @Output() undoChange = new EventEmitter();

  selectedCoursesSub: Subscription;
  public buckets: CourseBucket[];
  public submissionError: string;
  private log: Logger;

  courses: CourseOccurrence[];
  summary = CourseSummary.default();

  showSidebar = false;
  updateCourses = false;
  strings = strings.components.organisms.coursePlannerReviewPanel;
  triedNavigating = false;

  constructor(private courseService: CourseService, private router: Router, private loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  toggleNav() {
    this.showSidebar = !this.showSidebar;
  }

  onTaskCardClick() {
    if (window.outerWidth < 960) {
      this.toggleNav();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teachingPeriods && changes.teachingPeriods.currentValue) {
      this.selectedCoursesSub = this.courseService.selectedCourses.subscribe((courses: CourseOccurrence[]) => {
        const activeCourses = courses && courses.filter((c) => c.active);
        if (activeCourses) {
          this.buckets = CourseService.bucketize(activeCourses, changes.teachingPeriods.currentValue);
        }
      });
    }

    const hasProcessNameChange = get(changes, 'processName.currentValue');
    if (hasProcessNameChange && this.triedNavigating) {
      this.submitForReview();
    }
  }

  ngOnDestroy() {
    if (this.selectedCoursesSub) {
      this.selectedCoursesSub.unsubscribe();
    }
  }

  addACourse(course) {
    this.addCourse.emit(course);
  }

  removeACourse(course) {
    this.removeCourse.emit(course);
  }

  undoChangeAction(course) {
    this.undoChange.emit(course);
  }

  submitForReview() {
    this.submissionError = '';
    if (this.processName) {
      return this.router.navigate(
        internalUrls.courseSelectionOverview(this.processName, this.routeParams.applicationYear),
      );
    }
    this.triedNavigating = true;
  }
}
