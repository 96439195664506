import { zip as observableZip, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { UCProcess } from '@shared/models/process';
import { ProcessService } from '@shared/services/process/process.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';

import { Task } from '@shared/models/task';
import { Stage } from '@shared/models/stage';
import { internalUrls } from '@constants/internalUrls';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { ApplicationSummary } from '@shared/models/application';
import { ReferenceData } from '@shared/models/reference-data';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';

/**
 * At time of writing, we need to manually construct the stages and tasks for the preprocess page
 *
 * @param {UCProcess} process An unevaluated process with no stages from `processService.getProcesses`
 * @returns {UCProcess}
 */
export function hydratePreProcess(process: UCProcess): UCProcess {
  const processPath = internalUrls.preProcessPage(process.code).join('/');

  const task = new Task({
    title: 'Select Application Year',
    code: 'future-year-apply',
    percentComplete: 0,
    path: processPath,
    stop: false,
  });
  const stage = new Stage({
    title: 'Get Started',
    code: 'get-started',
    percentComplete: 0,
    path: processPath,
    description: 'Get Started',
    tasks: [task],
  });
  process.stages = [stage];
  return process;
}

/**
 * The full route data object
 * e.g. this.route.data
 */
export interface IPreProcessRouteData {
  preProcessContext: IPreProcessRouteContext;
}

/**
 * The object resolved by the PreProcessResolver
 */
export interface IPreProcessRouteContext {
  process: UCProcess;
  availableApplicationYears: string[];
}

/**
 * The parameters supplied to the PreProcess resolver/page from the url config
 */
export interface IPreProcessRouteParams {
  process: string;
}

@Injectable()
export class PreProcessResolver implements Resolve<IPreProcessRouteContext> {
  private log: Logger;

  constructor(
    private router: Router,
    private processService: ProcessService,
    private loggingService: LoggingService,
    private refDataService: ReferenceDataService,
    private applicationService: ApplicationService,
    private flashMessageService: FlashMessageService,
  ) {
    this.log = this.loggingService.createLogger(this);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPreProcessRouteContext> {
    const { process: processCode } = route.params as IPreProcessRouteParams;

    if (!processCode) {
      // probably a weird url like /apply//
      this.router.navigate(internalUrls.apply);
      return;
    }

    return observableZip(
      this.processService.getProcesses(),
      this.applicationService.getApplications(),
      this.refDataService.getByType('application_year'),
    ).pipe(
      map(
        ([processes, applicationSummaries, applicationYears]: [
          UCProcess[],
          ApplicationSummary[],
          ReferenceData[],
        ]): IPreProcessRouteContext => {
          // P.W. Sorry for the shoddy formatting, was getting line length errors and other weird formatting bugs.
          const bareProcess = processes.find((p: UCProcess) => p.code === processCode);

          if (!bareProcess) {
            // Not a valid processCode
            this.router.navigate(internalUrls.apply);
            return;
          }
          const takenYears = applicationSummaries.map((a) => a.academicYear).filter((y) => y);

          const availableYears = applicationYears.map((y) => y.code).filter((y) => takenYears.indexOf(y) === -1);

          if (!availableYears.length) {
            this.flashMessageService.pushError('No applications available!');
            this.router.navigate(internalUrls.dashboard);
            return;
          }
          this.processService.process$.next(hydratePreProcess(bareProcess));

          return {
            process: hydratePreProcess(bareProcess),
            availableApplicationYears: availableYears,
          };
        },
      ),
    );
  }
}
