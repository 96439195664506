import { UserSubTypes, UserTypes } from '@shared/models/user';

export const APP_NAMES = {
  [UserTypes.student]: 'myUC',
  [UserTypes.agent]: 'connectUC',
  [UserTypes.staff]: 'ourUC',
  [UserSubTypes.ucOnlineStudent]: 'ucOnline',
};

export const PROCESS_NAMES = {
  RETURNING: 'returning',
  NEW_STUDENT: 'new_student',
  COE: 'change_of_enrolment',
  COA: 'change_of_application',
  STAR: 'star',
  STUDY_ABROAD: 'incoming_student_exchange_study_abroad',
  MCED: 'mced',
  MICRO_CREDENTIAL: 'micro-credentials',
  OFFER_DECISION: 'offer-decision',
  DEFER_OFFER: 'defer-offer',
  UCONLINE_SHORT_COURSE: 'uconline_short_course',
  UCONLINE_MICRO_CREDENTIAL: 'uconline_microcredential',
};

export const ONLINE_PROCESS_PREFIX = 'uconline_';

export const TASK_PROCESS = {
  ASSESS_COE: 'Assess Change of Enrolment',
};

export const STAGE_NAMES = {
  SELECT_COURSES: 'select-courses',
  CHANGE_OF_APPLICATION: 'change-of-application',
  CHANGE_OF_ENROLMENT: 'change-of-enrolment',
  CHECKOUT: 'checkout',
  UCONLINE_TO_ENROL: 'to-enrol',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum MODEL_NAMES {
  CHANGEOFENROLMENT = 'changeOfEnrolments',
  APPLICATION = 'application',
}

export const ENV_NAMES = {
  PROD: 'prod',
  TEST: 'test',
};
