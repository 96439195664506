import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { STAGE_NAMES } from '@shared/constants/app-names.constants';
import strings from '@shared/constants/strings.constants';
import { IndependentCourseEnrolment } from '@shared/models/enrolment';
import { Stage } from '@shared/models/stage';
import { Task } from '@shared/models/task';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { IProcessRouteParams } from '@shared/services/resolvers/process-resolver/process-resolver.service';

@Component({
  selector: 'uc-online-payment-success',
  templateUrl: './online-payment-success.component.html',
  styleUrls: ['./online-payment-success.component.scss'],
})
export class OnlinePaymentSuccessComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() stage: Stage;
  @Input() isInternational = false;
  @Input() params: IProcessRouteParams;
  @Input() processName: string;
  @Input() stageNumber: number;

  strings = strings.components.tasks.onlinePaymentSuccess;
  messageBanner: string;
  log: Logger;
  courseName = undefined;

  public enrolmentList$ = new BehaviorSubject<IndependentCourseEnrolment[]>([]);
  public enrolmentCourseTypes$ = new BehaviorSubject<string[]>([]);
  existingApplicationYears = [];
  enrolmentList = [];
  enrolledCourseCodes = [];
  enrolledCourseTypes = [];

  constructor(
    private onlineProcessService: OnlineProcessService,
    private onlineCourseService: OnlineCourseService,
    private route: ActivatedRoute,
    loggingService: LoggingService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    this.route.queryParams.subscribe((queryParams: unknown) => {
      this.onlineCourseService.buildOnlineCourseEnrolmentContext(queryParams);
    });
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  updateFormValidity() {}

  // eslint-disable-next-line class-methods-use-this
  public checkFormValidity(): string[] {
    return [];
  }

  submitStageAndApplicationIfRequired() {
    this.onlineProcessService
      .submitStage(this.processName, this.onlineCourseService.getCourseYear(), STAGE_NAMES.CHECKOUT)
      .pipe()
      .subscribe({
        next: () => {
          console.log('Submit Checkout stage');
        },
        error: (err) => {
          this.log.error(new Error('Error submitting stage'));
          this.errors.emit(err);
        },
      });
  }

  public update() {
    this.submitStageAndApplicationIfRequired();
  }
}
