import { Component, ViewChild } from '@angular/core';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { BaseModalComponent } from '@shared/components/atoms/base-modal/base-modal.component';
import { UserTypes } from '@shared/models/user';

@Component({
  selector: 'uc-process-help-modal-button',
  templateUrl: './process-help-modal-button.component.html',
  styleUrls: ['./process-help-modal-button.component.scss'],
})
export class ProcessHelpModalButtonComponent {
  @ViewChild(BaseModalComponent) modal: BaseModalComponent;

  strings = strings.components.tasks.usingMyUc;

  openModal() {
    this.modal.open();
  }

  shouldShowStudentHelp() {
    return environment.scope !== UserTypes.agent;
  }
}
