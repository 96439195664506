import { uniqBy } from 'lodash-es';

import { CourseOccurrence } from '@shared/models/course';
import { TeachingPeriod } from '@shared/models/teaching-period';

export const courseOccurrences = () =>
  CourseOccurrence.deserializeAll([
    {
      courseOccurrenceCode: 'COSC101_17S2 (C)',
      courseCode: 'COSC101',
      courseType: 'Course',
      occurrenceCode: '17S2 (C)',
      teachingPeriodCode: 'Semester Two',
      site: 'Christchurch',
      academicYear: '2018',
      status: 'LIVE',
      substatus: 'Running',
      startDate: '2017-02-19',
      endDate: '2017-06-24',
      href: 'http://www.canterbury.ac.nz/courseinfo/GetCourseDetails.aspx?course=ACCT102&occurrence=17S1(C)&year=2017',
      grossLength: 52,
      isSelfPaced: true,
      efts: '0.4500',
      courseTitle: 'Computer Science',
      coursePoints: 15.0,
      courseLevel: 0,
      courseStudentSelectable: true,
      calendarStatements: {
        coRequisitesCalendarStatement: null,
        preRequisitesCalendarStatement: null,
        equivalentsCalendarStatement: null,
        restrictionsCalendarStatement: null,
      },
    },
    {
      courseOccurrenceCode: 'ACCT102_17S1 (C)',
      courseCode: 'ACCT102',
      courseType: 'Course',
      occurrenceCode: '17S1 (C)',
      teachingPeriodCode: 'Semester One',
      site: 'Christchurch',
      academicYear: '2018',
      status: 'LIVE',
      substatus: 'Running',
      startDate: '2017-02-19',
      endDate: '2017-06-24',
      href: 'http://www.canterbury.ac.nz/courseinfo/GetCourseDetails.aspx?course=ACCT102&occurrence=17S1(C)&year=2017',
      grossLength: 52,
      isSelfPaced: true,
      efts: '0.4500',
      courseTitle: 'Accounting and Financial Information',
      coursePoints: 15.0,
      courseLevel: 1,
      courseStudentSelectable: true,
      calendarStatements: {
        coRequisitesCalendarStatement: null,
        preRequisitesCalendarStatement: null,
        equivalentsCalendarStatement: null,
        restrictionsCalendarStatement: null,
      },
    },
    {
      courseOccurrenceCode: 'COSC101_17S1 (C)',
      courseCode: 'COSC101',
      courseType: 'Course',
      occurrenceCode: '17S1 (C)',
      teachingPeriodCode: 'Semester One',
      site: 'Christchurch',
      academicYear: '2018',
      status: 'LIVE',
      substatus: 'Running',
      startDate: '2017-02-19',
      endDate: '2017-06-24',
      href: 'http://www.canterbury.ac.nz/courseinfo/GetCourseDetails.aspx?course=ACCT102&occurrence=17S1(C)&year=2017',
      grossLength: 52,
      isSelfPaced: true,
      efts: '0.4500',
      courseTitle: 'Computer Science',
      coursePoints: 7.5,
      courseLevel: 1,
      courseStudentSelectable: true,
      calendarStatements: {
        coRequisitesCalendarStatement: null,
        preRequisitesCalendarStatement: null,
        equivalentsCalendarStatement: null,
        restrictionsCalendarStatement: null,
      },
    },
    {
      courseOccurrenceCode: 'ACCT102_17S1 (C)',
      courseCode: 'ACCT102',
      courseType: 'Course',
      occurrenceCode: '17S1 (C)',
      teachingPeriodCode: 'Semester One',
      site: 'UC Online',
      academicYear: '2018',
      status: 'LIVE',
      substatus: 'Running',
      startDate: '2017-02-19',
      endDate: '2017-06-24',
      href: 'http://www.canterbury.ac.nz/courseinfo/GetCourseDetails.aspx?course=ACCT102&occurrence=17S1(C)&year=2017',
      grossLength: 52,
      isSelfPaced: true,
      efts: '0.4500',
      courseTitle: 'Accounting and Financial Information',
      coursePoints: 15.0,
      courseLevel: 1,
      courseStudentSelectable: true,
      calendarStatements: {
        coRequisitesCalendarStatement: null,
        preRequisitesCalendarStatement: null,
        equivalentsCalendarStatement: null,
        restrictionsCalendarStatement: null,
      },
    },
  ]);

export const mockTeachingPeriods = () => {
  const uniqueCourses = uniqBy(courseOccurrences(), 'teachingPeriodCode');
  return uniqueCourses.map((co) => {
    return new TeachingPeriod({
      code: co.teachingPeriodCode,
      year: co.academicYear,
      semesterIndicatorCode: co.occurrenceCode,
      description: co.teachingPeriodCode,
      teachingStartDate: co.startDate,
      teachingEndDate: co.endDate,
    });
  });
};

const getCourseResult = (filterFn) => {
  const course = courseOccurrences().filter(filterFn);

  return { course };
};

// Api has not decided how this summary object will work
// const summary = {
//   current_points: 0,
//   maximum_points: 180,
//   minimum_points: 120,
//   total_items: course.length,
//   error: ['Workload too heavy, man.'],
//   total_indicative_cost: 0
// };

// course.forEach(c => {
//   summary.current_points += c.points;
// });

export const courseSearch = (queryString) => {
  const filter = (c) => {
    return c.courseOccurrenceCode.toLowerCase().match(new RegExp(queryString, 'i'));
  };
  const results = getCourseResult(filter);
  return {
    course: results.course,
    meta: {
      resultCount: results.course.length,
    },
  };
};

export const courseByCode = (code) => {
  const course = courseOccurrences().find((c) => c.courseOccurrenceCode === code);
  return { course };
};

export const coursesByURI = (codes: string[]) => {
  const filter = (c) => {
    return codes.find((code) => code === c.courseOccurrenceCode);
  };
  return getCourseResult(filter);
};
