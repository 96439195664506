import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { DefaultLandingUrlService } from '@shared/services/default-landing-url/default-landing-url.service';
import { JadeHydrateService } from '@shared/services/jade-hydrate/jade-hydrate.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-process-hydrate-overlay',
  templateUrl: './process-hydrate-overlay.component.html',
  styleUrls: ['./process-hydrate-overlay.component.scss'],
})
export class ProcessHydrateOverlayComponent extends UnsubscribeOnDestroy implements OnInit {
  log: Logger;
  strings = strings.components.atoms.jadeHydrateOverlay;
  breakpoints = [
    [550, 1],
    [1024, 2],
    [1920, 4],
  ];

  public params: unknown;
  public queryParams: unknown;
  private currentUserSub;

  constructor(
    loggingService: LoggingService,
    public jadeHydrateService: JadeHydrateService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private defaultLandingUrlService: DefaultLandingUrlService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    this.currentUserSub = combineLatest([this.route.queryParams, this.userService.currentUser])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([queryParams, currentUser]) => {
        if (currentUser && !currentUser.partiallyHydrated) {
          this.router.navigate(this.defaultLandingUrlService.landingUrl, { queryParams: this.queryParams });
        }
        this.hydrateProcess(queryParams);
      });
  }

  private hydrateProcess(queryParams) {
    this.jadeHydrateService.hydrateStarted.subscribe(() => {
      document.body.classList.add('no-scroll');
    });
    this.jadeHydrateService.didHydrate.subscribe(
      () => {
        document.body.classList.remove('no-scroll');
        this.unsubscribeCurrentUser();
        this.router.navigate(this.defaultLandingUrlService.landingUrl, { queryParams });
      },
      (err) => {
        document.body.classList.remove('no-scroll');
        throw err;
      },
    );
  }

  private unsubscribeCurrentUser() {
    if (this.currentUserSub) {
      this.currentUserSub.unsubscribe();
    }
  }
}
