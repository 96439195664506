import { Component, Input, OnChanges } from '@angular/core';

import { Task } from '@shared/models/task';
import { Stage } from '@shared/models/stage';
import strings from '@constants/strings.constants';

@Component({
  selector: 'uc-application-hints',
  templateUrl: './application-hints.component.html',
  styleUrls: ['./application-hints.component.scss'],
})
export class ApplicationHintsComponent implements OnChanges {
  @Input() stage: Stage;
  @Input() task: Task;
  strings = strings.components.molecules.applicationHints;

  applicationHints: Task[] = [];

  ngOnChanges(): void {
    if (this.stage && this.task) {
      this.applicationHints = this.stage.tasks.filter((t) => t.percentComplete !== 100 && t.code !== this.task.code);
    }
  }
}
