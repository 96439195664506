<div [formGroup]="proficiencyForm">
  <uc-form-input>
    <uc-subsection>
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        path="/english_qualification/source"
      ></uc-task-hint>
      <uc-subsection-question>
        {{ strings.skillDescription }}
        <uc-required-field></uc-required-field>
        <button
          [attr.data-cy]="'tooltip'"
          class="tooltip-btn"
          ucTooltip
          tooltipContent="{{ strings.tooltipContent }}"
        >
          <uc-icon
            iconTitle="More info"
            iconType="info"
            iconWidth="16"
            iconHeight="16"
            iconFill="$colorMineShaft"
          ></uc-icon>
        </button>
      </uc-subsection-question>
      <uc-radio-input-group
        groupName="eng-skills"
        formControlName="englishSkills"
        type="english_language_skill"
        [filterOutNonValidOptions]="true"
        [showDisabledExplanation]="!currentApplicant.englishQualification.verified"
      >
      </uc-radio-input-group>
    </uc-subsection>

    <uc-subsection *ngIf="proficiencyForm.value.englishSkills === 'Studied in English'">
      <uc-task-hint
        [task]="task"
        [model]="hintPath"
        path="/english_qualification/country"
      ></uc-task-hint>
      <uc-subsection-question>
        {{ strings.countryQuestion }}
        <uc-required-field></uc-required-field>
      </uc-subsection-question>
      <uc-reference-data-selector
        formControlName="country"
        type="country"
        [showDisabledExplanation]="!currentApplicant.englishQualification.verified"
      ></uc-reference-data-selector>
    </uc-subsection>

    <div
      *ngIf="proficiencyForm.value.englishSkills === 'Have Sat Test'"
      required="yes"
    >
      <uc-english-proficiency-test-type
        [task]="task"
        formControlName="otherControl"
        [condition]="proficiencyForm.value.englishSkills"
        [verified]="currentApplicant.englishQualification.verified"
      >
      </uc-english-proficiency-test-type>
    </div>

    <div *ngIf="showTestType">
      <uc-subsection>
        <uc-subsection-text-block>
          {{ strings.teacherIelts }}
        </uc-subsection-text-block>
        <uc-subsection-text-block>
          <p [innerHTML]="strings.moreDetail"></p>
        </uc-subsection-text-block>
      </uc-subsection>
      <uc-english-proficiency-test-type
        [task]="task"
        formControlName="otherControl"
        [condition]="proficiencyForm.value.englishSkills"
        [verified]="currentApplicant.englishQualification.verified"
      >
      </uc-english-proficiency-test-type>
    </div>
  </uc-form-input>
</div>
