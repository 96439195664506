import { compact, get } from 'lodash-es';

import { QualificationEnrolment } from '@shared/applicant/qualification/model';
import { ENROLMENT_STATE } from '@shared/constants/states.constants';
import { deepClone } from '@shared/helpers/general';
import { snakeifyKeys, camelizeKeys } from '@shared/helpers/serialization';
import { CourseOccurrence, totalCoursePoints, CourseBucket } from '@shared/models/course';
import { Qualification, EnrolledQualification, SubjectOptionsList } from '@shared/models/qualification';
import { ReferenceData } from '@shared/models/reference-data';

import { ChangeOfEnrolment } from './change-of-enrolment';
import { SummaryAction } from './enrolment-summary';
import { Waiver } from './waiver';

export const ENROLMENT_SERVICE_PATH = '/enrolment';

export class Enrolment {
  static STATE = ENROLMENT_STATE;
  academicYear: string;
  internalReference: string;
  state: ReferenceData;
  enrolledQualifications: EnrolledQualification[];
  priority?: number;
  waivers?: Waiver[];

  constructor(obj) {
    Object.assign(this, obj);
  }

  // eslint-disable-next-line complexity
  public static deserialize(payload): Enrolment {
    const camelized = camelizeKeys(payload.enrolments || payload);
    camelized.state = ReferenceData.deserialize(camelized.state);
    camelized.enrolledQualifications = camelized.enrolledQualifications
      ? camelized.enrolledQualifications.map(EnrolledQualification.deserialize)
      : [];
    camelized.waivers = camelized.waivers ? camelized.waivers.map(Waiver.deserialize) : [];
    // api gives this as an int so we're coercing it to string for consistency and to avoid a tonne of rework right now
    camelized.academicYear = camelized.academicYear?.toString();

    return new Enrolment(camelized);
  }

  public static deserializeAll(payload): Enrolment[] {
    if (payload === null) {
      return null;
    }

    const data = Enrolment.getEnrolmentDeserializeTarget(payload);
    return !!data.length ? data.map(Enrolment.deserialize) : [];
  }

  static getEnrolmentDeserializeTarget(payload) {
    return payload.enrolments || payload;
  }

  public static serialize(instance: Enrolment) {
    const cloned = deepClone(instance);
    cloned.state = ReferenceData.deserialize(cloned.state);
    cloned.enrolledQualifications = cloned.enrolledQualifications.map(EnrolledQualification.serialize);
    cloned.waivers = cloned.waivers?.map(Waiver.serialize);
    return snakeifyKeys(cloned);
  }
}

export class ContinuingEnrolledQualification {
  code: string;
  organisationUnit: ReferenceData;
  state: ReferenceData;
  internalReference: string;
  externalReference: string;
  firstYearInAward: string;
  subjectOptions?: SubjectOptionsList;
  forceContinue?: boolean;
  displayOrder?: number;
  priority?: number;
  changeAction?: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): ContinuingEnrolledQualification[] {
    const payload = obj.enrolled_qualifications || obj;
    return payload.map((e) => {
      return new ContinuingEnrolledQualification(camelizeKeys(e));
    });
  }
}

export class QualificationWithCourses extends QualificationEnrolment {
  courses: CourseOccurrence[];
  courseBuckets?: CourseBucket[];
  inactiveCourses?: CourseOccurrence[];

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }

  get totalPoints() {
    return totalCoursePoints(this.courses);
  }

  static createQualWithCourse(qualificationEnrolment: QualificationEnrolment, courses: CourseOccurrence[]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const qualCourses: any = { ...qualificationEnrolment };
    qualCourses.courses = courses.filter((c) => c.active);
    qualCourses.inactiveCourses = courses.filter((c) => !c.active);
    return new QualificationWithCourses(qualCourses);
  }
}

export class FullEnrolment {
  priority?: number;
  displayOrder: number;
  qualifications: QualificationWithCourses[];
  validationMessage?: ValidationMessage;
  state?: ReferenceData;
  primaryAction?: SummaryAction;
  secondaryAction?: SummaryAction[] = [];
  internalReference: string;
  canWithdraw = false;

  constructor(obj: unknown) {
    Object.assign(this, obj);
  }

  get totalPoints() {
    return this.qualifications.reduce((total, qual) => total + qual.totalPoints, 0);
  }

  // eslint-disable-next-line max-lines-per-function
  static createFrom(
    enrolments,
    qualifications: Qualification[],
    courses: CourseOccurrence[],
    validationMessages?: ValidationMessage[],
  ): FullEnrolment[] {
    // eslint-disable-next-line complexity
    const fullEnrolments: FullEnrolment[] = enrolments.map((enrolment) => {
      const priority = enrolment.priority || 1;
      const displayOrder = enrolment.displayOrder || '1';
      const canWithdraw = enrolment.canWithdraw || false;
      const validationMessage = validationMessages && validationMessages[0];
      const quals = this.buildQualsWithCourses(enrolment.enrolledQualifications, qualifications, courses);
      const enrolmentData = {
        state: enrolment.state,
        internalReference: enrolment.internalReference,
        priority,
        displayOrder,
        canWithdraw,
      };

      return new FullEnrolment({ qualifications: quals, validationMessage, ...enrolmentData });
    });
    return fullEnrolments;
  }

  static createFromCoe(
    coe: ChangeOfEnrolment,
    qualifications: Qualification[],
    courses: CourseOccurrence[],
    validationMessage?: ValidationMessage,
  ): FullEnrolment {
    const priority = 1;
    const displayOrder = 1.0;
    const quals = this.buildQualsWithCourses(coe.enrolledQualifications, qualifications, courses);
    const enrolmentData = {
      state: coe.state,
      internalReference: coe.internalReference,
      priority,
      displayOrder,
    };
    return new FullEnrolment({ qualifications: quals, validationMessage, ...enrolmentData });
  }

  // eslint-disable-next-line max-lines-per-function
  static buildQualsWithCourses(
    enrolledQuals: EnrolledQualification[],
    qualifications: Qualification[],
    courses: CourseOccurrence[],
  ) {
    // eslint-disable-next-line max-lines-per-function
    return enrolledQuals.map((enroledQual) => {
      const qualification: Qualification = qualifications.find((qual) => qual.code === enroledQual.code);
      const qualEnrolment = QualificationEnrolment.createFrom(enroledQual, qualification);
      const courseOccurrences = compact(
        // eslint-disable-next-line complexity
        enroledQual.enrolledCourses.map((ec) => {
          const courseData = courses.find((c) => c.courseOccurrenceCode === ec.code);
          if (!courseData) {
            // Handle mismatches between the enrolment course data and course occurrence data
            return new CourseOccurrence({});
          }
          const completeCourseData = new CourseOccurrence({ ...courseData });
          // Enroled course model now has a start/end date, which we should be using over the default course dates
          completeCourseData.startDate = ec.startDate || courseData.startDate;
          completeCourseData.endDate = ec.endDate || courseData.endDate;
          completeCourseData.active = ec.active;
          completeCourseData.state = get(ec.state, 'code');
          completeCourseData.declineReason = ec.declineReason || '';
          // We also need to make sure the coe course occurrences has the change action copied from the enrolledQual course
          // This is so the course overview page will not display the users dropped courses and they'll have an accurate picture
          completeCourseData.changeAction = ec.changeAction;
          return completeCourseData;
        }),
      );

      return QualificationWithCourses.createQualWithCourse(qualEnrolment, courseOccurrences);
    });
  }
}

export class IndependentCourseEnrolment {
  internalReference: string;
  courseCode: string;
  occurrence: string;
  state: { code: string };
  paymentState: { code: string };
  checkoutUrl: string;
  dateCreated: Date;
  dateModified: Date;
  paymentIntentId: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): IndependentCourseEnrolment[] {
    const payload = obj.enrolled_courses || obj;
    return payload.map((e) => {
      return new IndependentCourseEnrolment(camelizeKeys(e));
    });
  }

  public static deserializePost(obj): IndependentCourseEnrolment {
    const payload = obj.independent_enrolment || obj;
    return new IndependentCourseEnrolment(camelizeKeys(payload));
  }
}

export class IndependentCourseEnrolmentList {
  internalReference: string;
  academicYear: string;
  userId: string;
  enrolledCourses: IndependentCourseEnrolment[];

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserializeAll(obj): IndependentCourseEnrolmentList {
    const camelized = camelizeKeys(IndependentCourseEnrolmentList.getCamelizeTarget(obj));

    if (!IndependentCourseEnrolmentList.isEnrolledCourseExist(camelized)) {
      return null;
    }

    camelized.enrolledCourses = camelized?.enrolledCourses
      ? IndependentCourseEnrolment.deserialize(camelized.enrolledCourses)
      : [];
    return new IndependentCourseEnrolmentList(camelized);
  }

  static getCamelizeTarget(obj) {
    return obj?.independent_enrolments || obj;
  }

  static isEnrolledCourseExist(camelized) {
    return camelized?.enrolledCourses !== null && camelized?.enrolledCourses !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public flatten(): IndependentCourseEnrolmentListFlattenedItem[] {
    return this.enrolledCourses.map((e) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enrolledCourses, ...result } = { ...this, ...e };
      return result as IndependentCourseEnrolmentListFlattenedItem;
    });
  }
}

type IndependentCourseEnrolmentListFlattenedItem = Omit<IndependentCourseEnrolmentList, 'enrolledCourses'> &
  IndependentCourseEnrolment;

export class ValidationMessage {
  /* eslint-disable @typescript-eslint/naming-convention */
  msg: {
    enrolment_error?: { message: string }[];
    international_efts_warning?: { message: string }[];
    workload_error?: { message: string }[];
    enrolment_warning?: { message: string }[];
    withdraw_warnings?: { message: string }[];
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  constructor(obj) {
    Object.assign(this, obj);
  }

  static deserialize(payload): ValidationMessage {
    return new ValidationMessage({ msg: payload.validation });
  }
}
