<uc-form-template>
  <uc-form-header title="{{ task?.title }}"> </uc-form-header>

  <uc-section>
    <uc-section-header title="{{ strings.sectionHeader }}"> </uc-section-header>
    <uc-subsection>
      <span innerHTML="{{ strings.content }}"></span>
    </uc-subsection>
  </uc-section>
</uc-form-template>
