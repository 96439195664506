import { Component, OnInit, Input } from '@angular/core';
import { Validators, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import { zip as observableZip, combineLatest as observableCombineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import stringConsts from '@constants/strings.constants';
import { AbstractBaseTask } from '@shared/classes/abstract-base-task';
import { AddressModel } from '@shared/models/address';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { Phone } from '@shared/models/phone';
import { Task } from '@shared/models/task';
import { UCValidators } from '@shared/models/validators/validators';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import {
  UCElementGroup,
  group,
  control,
  refDataToValue,
  valueToRefData,
  UCElementArray,
  singleCheckboxValueToBoolean,
  booleanToSingleCheckboxValue,
} from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-online-emergency-contact',
  templateUrl: './online-emergency-contact.component.html',
})
export class OnlineEmergencyContactComponent extends AbstractBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;

  strings = stringConsts.components.tasks.reviewDetails;
  phoneStrings = stringConsts.components.molecules.phoneSelector;

  a2eStrings = stringConsts.components.tasks.applicationToEnrol;

  showDesc = false;
  reviewForm: UntypedFormGroup;
  ucReviewForm: UCElementGroup;
  dateOfBirthYear: number;
  currentApplicant: Applicant;
  currentApplication: Application;

  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  private log: Logger;

  constructor(
    logService: LoggingService,
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
  ) {
    super();
    this.log = logService.createLogger(this);
  }

  get otherNames(): UCElementArray {
    return this.ucReviewForm.controls.names.controls.name;
  }

  get otherNamesLength(): number {
    return get(this.otherNames, 'control.length');
  }

  public updateFormValidity(): void {}

  private createForm(): UCElementGroup {
    return group({
      emergencyDetails: group({
        email: control({
          defaultState: '',
          validators: [Validators.required, Validators.email],
          model: 'applicant',
          path: '/emergencyContactDetail/emailAddress',
        }),
        mobileNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzMobileValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/mobileNumber',
        }),
        alternatePhoneNum: control({
          /* eslint-disable-next-line id-blacklist */
          defaultState: new Phone({ country: '', number: '' }),
          validators: [UCValidators.completePhoneValidator, UCValidators.nzAlternatePhoneValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/alternatePhone',
        }),
        firstName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/firstName',
        }),
        lastName: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactName/surname',
        }),
        relationship: control({
          defaultState: '',
          validators: [Validators.required],
          model: 'applicant',
          path: '/emergencyContactRelationship',
          inMap: refDataToValue,
          outMap: valueToRefData,
        }),
        address: control({
          defaultState: AddressModel.createFrom({}),
          validators: [UCValidators.addressValidator],
          model: 'applicant',
          path: '/emergencyContactDetail/currentAddress',
        }),
        declarationEmergencyContactInformationCorrect: control({
          model: 'application',
          path: '/declarationEmergencyContactInformationCorrect',
          defaultState: false,
          inMap: booleanToSingleCheckboxValue('declarationEmergencyContactInformationCorrect'),
          outMap: singleCheckboxValueToBoolean('declarationEmergencyContactInformationCorrect'),
          validators: [
            UCValidators.validateAllChecked([
              { labelText: 'test', value: 'declarationEmergencyContactInformationCorrect' },
            ]),
          ],
        }),
      }),
    });
  }

  ngOnInit() {
    this.ucReviewForm = this.createForm();
    this.reviewForm = this.ucReviewForm.asControl() as UntypedFormGroup;

    observableCombineLatest(this.applicantService.applicant, this.applicationService.application)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([applicant, application]: [Applicant, Application]) => {
        this.currentApplicant = applicant;
        this.currentApplication = application;
        this.formModel.updateFormFromModel(this.ucReviewForm, applicant, application);
      });
  }

  copyPermanentToEmergencyAddress() {
    const permanentAddress = this.currentApplicant.contactDetail.currentAddress;
    const addressCopy = AddressModel.deserialize(AddressModel.serialize(permanentAddress));
    this.reviewForm.get('emergencyDetails.address').patchValue(addressCopy);
  }

  update() {
    if (!this.currentApplicant || !this.currentApplication) {
      this.errors.emit();
      return this.log.error('no current applicant/application');
    }

    this.formModel.updateModelFromForm(this.ucReviewForm, this.currentApplicant, this.currentApplication);

    // Prevent nulls in the name array
    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);

    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    observableZip(updateApplicant, updateApplication).subscribe({
      next: ([applicant, application]) => {
        if (applicant && application) {
          this.log.info('updated applicant and application successfully');
          this.next.emit();
        }
      },
      error: (err: DSHttpError) => {
        this.errors.emit(err);
        this.log.error('error thrown while updating applica(nt|tion):', err);
      },
    });
  }
}
