import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import strings from '@constants/strings.constants';
import { Task } from '@shared/models/task';

@Component({
  selector: 'uc-gender-selector',
  templateUrl: './gender-selector.component.html',
  styleUrls: ['./gender-selector.component.scss'],
})
export class GenderSelectorComponent implements OnChanges {
  @Input() genderForm: UntypedFormGroup;
  @Input() task: Task;
  strings = strings.components.molecules.genderSelector;

  gender = [
    {
      labelText: 'Male',
      value: 'M',
    },
    {
      labelText: 'Female',
      value: 'F',
    },
    {
      labelText: 'Diverse',
      value: 'X',
    },
  ];

  constructor() {}

  ngOnChanges(changes) {}
}
