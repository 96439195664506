export const firebaseErrorMap = {
  'auth/invalid-email': 'auth.invalidEmail',
  'auth/user-not-found': 'auth.userNotFound',
  'auth/wrong-password': 'auth.userNotFound',
  'auth/network-request-failed': 'auth.networkError',
  'auth/account-exists-with-different-credential': 'auth.socialAccountExists',
  'auth/email-already-in-use': 'createAccount.duplicateEmail',
  'auth/weak-password': 'createAccount.weakPassword',
  'auth/user-disabled': 'auth.userDisabled',
  'auth/popup-closed-by-user': 'auth.popupClosedByUser',
  'auth/cancelled-popup-request': 'auth.popupCancelled',
};
