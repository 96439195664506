/* eslint-disable max-len */
const appName = 'connectUC';

const intakeLabels = {
  'Semester 1': 'Feb',
  'Semester 2': 'Jul',
  Block: 'Block',
  Other: 'Other',
  Thesis: 'Thesis',
  'Summer school': 'Summer',
};

const contactAdmissions = 'email <a href="mailto:admission@canterbury.ac.nz">admission@canterbury.ac.nz</a>.';

export default {
  labels: {
    user: {
      emailOrUsername: 'Agent Username',
    },
    appName,
  },
  components: {
    organisms: {
      loginForm: {
        header: appName,
        loginButton: 'Login',
        aboutText: `Welcome to the Agent Portal – you can submit your students' applications here.`,
        helpText: `If you are unable to login, or you are interested in working with UC, ${contactAdmissions}`,
      },
      headerBar: {
        agencyDashboard: 'connectUC Dashboard',
      },
      sidenav: {
        title: 'connectUC',
      },
    },
    template: {
      dashboard: {
        newApplicationButton: 'New Application',
        withdrawSuccess: 'Application was successfully withdrawn',
        applicationYearLabel: 'Application year',
        emailAddressLabel: 'Email address',
        allYears: 'All years',
        filterBtn: 'Filter',
        resetBtn: 'Reset',
        withdrawApplication: {
          header: 'Withdraw Application',
          body: `
            <p>By selecting this option the application will no longer be available to amend or submit.</p>
          `,
          ok: `WITHDRAW`,
        },
        withdrawApplicationProblem: {
          header: `Withdraw Application`,
          body: `
            <p>There was an error withdrawing your application.</p>
            <p>Please try again and if the error continues ${contactAdmissions} for help with your application.</p>
          `,
          ok: `CLOSE`,
        },
        changeCoursesLink: 'Change qualification / courses',
        documentsAvailable: 'Documents',
        infoRequired: 'Info required',
        actions: {
          resume: 'Resume Application',
          withdraw: 'Withdraw Application',
          uploadDocs: 'Upload Additional Documents',
          archive: 'Archive Application',
          docsAvailable: 'Documents Available',
          infoRequired: 'Information Required',
        },
        intakeLabel: (code) => intakeLabels[code] || '',
        columns: {
          appId: 'App #',
          studentId: 'Student ID',
          givenName: 'Given Name',
          familyName: 'Family Name',
          dob: 'D.O.B.',
          email: 'Email',
          year: 'Year',
          intake: 'Intake',
          qual: 'Qual',
          status: 'Status',
          created: 'Created',
          submitted: 'Submitted',
          agent: 'Agent',
          action: 'Action',
        },
        intakeUnavailable: 'Intake not available',
      },
      documentPage: {
        description: `
          <p>You can upload additional documents to support your application.</p>
        `,
      },
    },
    tasks: {
      agentFutureApply: {
        yearQuestion: 'What year are you applying for?',
        dobQuestion: 'What is your birth date?',
        emailQuestion: 'What is your email address?',
        legalNameQuestion: 'What is your legal name?',
        formIncomplete: 'Fill in all required fields.',
        invalidEmail: 'Enter valid email address',
        processTitle: 'Application Type',
        processQuestion:
          'Select one of the following options. Please read the options carefully, and ensure you select the correct application type.',
        processQuestionLabel: 'What type of application do you want to complete?',
        processGuidance: {
          newStudent: `Select this option if:
          <ul>
            <li>Student wishes to enrol in a UC qualification</li>
          </ul>`,
          studyAbroad: `Select this option if:
          <ul>
            <li>Student wishes to enrol in UC courses to credit towards an overseas qualification</li>
          </ul>`,
        },
        processLabels: {
          newStudent: 'New student',
          studyAbroad: 'Incoming student exchange / study abroad',
        },
      },
    },
  },
};
