<div [formGroup]="secondaryEducationForm">
  <uc-section>
    <uc-section-header title="Secondary or high school education">
      <p>{{ strings.description[0] }}</p>
    </uc-section-header>
    <uc-form-input formArrayName="educations">
      <div [ngClass]="{ subsection: !isMicroCredentialProcess }">
        <div
          *ngFor="let education of educations.controls; let last = last; let first = first; let i = index"
          [formGroupName]="i"
        >
          <div>
            <uc-subsection *ngIf="hasSelectedLocation(i)">
              <uc-subsection-question *ngIf="first">
                {{ strings.highestSecondaryQual }}
              </uc-subsection-question>

              <ng-container *ngIf="isDomestic(i)">
                <uc-task-hint
                  [task]="task"
                  path="/applicant/secondary_qualification/{{ i }}/type"
                ></uc-task-hint>
                <uc-form-field-label
                  [labelText]="strings.qualification"
                  required="true"
                >
                  <uc-reference-data-selector
                    formControlName="type"
                    type="highest_school_qual"
                  ></uc-reference-data-selector>
                </uc-form-field-label>
              </ng-container>

              <div
                class="message-banner-wrapper"
                *ngIf="isNCEA2(i) && isDomestic(i)"
              >
                <uc-message-banner type="info">
                  {{ strings.NCEA3MessageBanner }}
                </uc-message-banner>
              </div>

              <ng-container *ngIf="shouldSecondaryDateAttained(i) || !isDomestic(i)">
                <uc-task-hint
                  [task]="task"
                  path="/applicant/secondary_qualification/{{ i }}/date_attained"
                ></uc-task-hint>
                <uc-form-field-label
                  [labelText]="strings.yearCompletedQualification"
                  required="true"
                >
                  <uc-year-selector
                    [testSelector]="'app-year-sec-' + i"
                    [baseYear]="applicationYear"
                    [range]="71"
                    [control]="education.get('dateAttained')"
                  >
                  </uc-year-selector>
                </uc-form-field-label>
              </ng-container>

              <ng-container *ngIf="first"> </ng-container>

              <uc-form-field-label
                *ngIf="isDomestic(i)"
                [labelText]="strings.whichSchoolAttended"
                required="true"
              >
                <uc-task-hint
                  [task]="task"
                  path="/applicant/secondary_qualification/{{ i }}/source"
                ></uc-task-hint>
                <uc-reference-data-selector
                  formControlName="source"
                  type="secondary_school"
                ></uc-reference-data-selector>
              </uc-form-field-label>

              <uc-form-field-label
                *ngIf="isDomestic(i) && !isSchoolOnList(i)"
                [labelText]="strings.schoolNotOnList"
                required="true"
              >
                <uc-task-hint
                  [task]="task"
                  path="/applicant/secondary_qualification/{{ i }}/source_other"
                ></uc-task-hint>
                <uc-string-input
                  [testSelector]="'secondary-' + i + '-source'"
                  formControlName="sourceOther"
                ></uc-string-input>
              </uc-form-field-label>
            </uc-subsection>
          </div>
        </div>
      </div>
    </uc-form-input>
  </uc-section>
</div>
